import {InfoCircleOutlined, LogoutOutlined, TeamOutlined, ToolOutlined, UserOutlined, WarningOutlined} from "@ant-design/icons";
import {Layout, Space, Typography} from "antd";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import {Auth} from "aws-amplify";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useMemo} from "react";
import {Outlet, useNavigate} from "react-router-dom";

import * as Models from "../core/models";
import {AuthenticationDataStore} from "../core/stores/AuthenticationDataStore";
import styles from "./AppLayout.module.scss";
import {AboutUs} from "./components/about-us/AboutUs";
import {AccountSettings} from "./components/account-settings/AccountSettings";
import {Header} from "./components/common/header/Header";
import {AppStore, SegmentKey} from "./stores/AppStore";
import {WebHelper} from "./utils/WebHelper";

const {Content} = Layout;

type AppLayoutProps = {
  currentUser: Models.User | undefined;
  deactivatedUser: boolean;
  messageContainerRef: React.RefObject<HTMLDivElement>;
  onSignOut: () => void;
};

export const AppLayout: FunctionComponent<AppLayoutProps> = observer(({currentUser, deactivatedUser, messageContainerRef, onSignOut}) => {
  const appStore = AppStore.getInstance();
  const {isUserAdmin, isUserGlobal} = AuthenticationDataStore.getInstance();
  const {stateByComponentType} = appStore.state;

  const navigate = useNavigate();

  const accountSettingsState = stateByComponentType.get("AccountSettings");

  const handleAccountSettingsClick = useCallback(() => {
    appStore.sendAnalyticTrack(SegmentKey.AccountSettingsOpened);
    appStore.showComponent("AccountSettings", {
      onClose: () => appStore.hideComponent("AccountSettings"),
      visible: true,
    });
  }, [appStore]);

  const aboutUsState = stateByComponentType.get("AboutUs");

  const handleAboutUsClick = useCallback(() => {
    appStore.showComponent("AboutUs", {
      onClose: () => appStore.hideComponent("AboutUs"),
      visible: true,
    });
  }, [appStore]);

  const handleSignOut = useCallback(async () => {
    onSignOut();
    appStore.sendAnalyticTrack(SegmentKey.SignOut);
    await appStore.reset();
    await Auth.signOut();
    navigate("/");
  }, [appStore, navigate, onSignOut]);

  const handleReportIssueClick = () => {
    appStore.sendAnalyticTrack(SegmentKey.ReportIssueOpened);
    const URL = "https://vervemotion.zendesk.com/hc/en-us/requests/new?ticket_form_id=17390787604891";
    window.open(URL, "_blank", "noopener,noreferrer");
  };

  const accountMenuItems: ItemType[] = useMemo(
    () => [
      !deactivatedUser
        ? {
            key: "settings",
            onClick: handleAccountSettingsClick,
            label: WebHelper.formatMessage("App-accountSettings"),
            icon: <UserOutlined className={styles.userProfileIcon} data-cy="accountSettingsIcon" />,
          }
        : null,
      !deactivatedUser && isUserAdmin
        ? {
            key: "permissions",
            onClick: () => {
              navigate("/user_permissions");
            },
            label: WebHelper.formatMessage("App-userPermissions"),
            icon: <TeamOutlined className={styles.userProfileIcon} data-cy="permissionsIcon" />,
          }
        : null,
      !deactivatedUser && isUserGlobal
        ? {
            key: "deviceManagement",
            onClick: () => {
              navigate("/device_management/devices");
            },
            label: WebHelper.formatMessage("App-deviceManagement"),
            icon: <ToolOutlined className={styles.userProfileIcon} data-cy="deviceManagementIcon" />,
          }
        : null,
      {
        key: "reportIssue",
        onClick: handleReportIssueClick,
        label: WebHelper.formatMessage("App-menuItemReportIssue"),
        icon: <WarningOutlined />,
      },
      {
        key: "aboutUs",
        onClick: handleAboutUsClick,
        label: WebHelper.formatMessage("App-menuItemAboutUs"),
        icon: <InfoCircleOutlined data-cy="aboutUsIcon" />,
      },
      {
        type: "divider",
      },
      {
        key: "logOut",
        onClick: handleSignOut,
        label: WebHelper.formatMessage("App-logOut"),
        icon: <LogoutOutlined className={styles.logoutIcon} data-cy="logOutIcon" />,
        danger: true,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isUserAdmin, isUserGlobal, deactivatedUser, handleAboutUsClick, handleAccountSettingsClick, handleSignOut, navigate]
  );

  return (
    <Layout className={styles.wrapper}>
      <Header currentUser={currentUser} deactivatedUser accountMenuItems={accountMenuItems} />
      <Layout className={styles.layoutSider} hasSider>
        <Content className={styles.content}>
          {currentUser && currentUser.status === Models.UserStatus.Active && <Outlet />}
          {deactivatedUser && (
            <Space size="large" className={styles.fullSpaceWrapper}>
              <Typography.Title level={3} data-cy="deactivatedUserTitle">
                {WebHelper.formatMessage("App-deactivatedUserTitle")}
              </Typography.Title>
              <Typography.Text className={styles.deactivatedUserSubtitle}>
                {WebHelper.formatMessage("App-deactivatedUserSubtitle1")}
              </Typography.Text>
              <Typography.Text className={styles.deactivatedUserSubtitle}>
                {WebHelper.formatMessage("App-deactivatedUserSubtitle2")}
              </Typography.Text>
            </Space>
          )}
          {currentUser && currentUser.status === Models.UserStatus.PendingApproval && (
            <Space size="large" className={styles.fullSpaceWrapper}>
              <Typography.Title level={4} data-cy="userPendingApprovalTitle">
                {WebHelper.formatMessage("App-userPendingApprovalMessage1")}
              </Typography.Title>
              <Typography.Title level={4}>{WebHelper.formatMessage("App-userPendingApprovalMessage2")}</Typography.Title>
            </Space>
          )}

          <div ref={messageContainerRef} />
          <AccountSettings onClose={accountSettingsState?.props.onClose} visible={accountSettingsState?.visible ?? false} />
          <AboutUs onClose={aboutUsState?.props.onClose} visible={aboutUsState?.visible ?? false} />
        </Content>
      </Layout>
    </Layout>
  );
});
