import {Button, Tooltip, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useState, useEffect} from "react";
import Highlighter from "react-highlight-words";

import deviceInServiceStatusIcon from "../../../../../assets/images/common/Device_InService_Status.svg";
import deviceOutOfServiceStatusIcon from "../../../../../assets/images/common/Device_OutOfService_Status.svg";
import * as Models from "../../../../../core/models";
import {DepartmentDevicesTabTableSettings} from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import {LinkButton} from "../../../common/link-button/LinkButton";
import {Table} from "../../../common/table/Table";
import {ConnectedColumnRender} from "../../../devices-table/connected-column-render/ConnectedColumnRender";
import {ChangeWorkerModal} from "../change-worker-modal/ChangeWorkerModal";
import {EditableWorker} from "../editable-worker/EditableWorker";
import {WorkerNoDevice} from "../worker-no-device/WorkerNoDevice";
import styles from "./DepartmentDevicesTable.module.scss";

const defaultSettings: DepartmentDevicesTabTableSettings = {
  pageSize: 10,
  sortColumn: "device_tag",
  sortOrder: "ascend",
};

export type DepartmentDevicesTableProps = {
  devices: Models.DeviceShort[];
  selectDevice: (device: Models.DeviceShort) => void;
  onOpenChangeWorkerStatusModal: (device: Models.DeviceShort) => void;
  loading: boolean;
  filterValue?: string;
  refetchDevices: () => void;
  department: Models.Department;
  setTableSettings: (settings: TableSettings) => void;
  onTableChange: (action: string, newSettings?: {quickFilter?: Models.DevicesTagsKey; tableSettings?: TableSettings}) => void;
};

export const DepartmentDevicesTable: FunctionComponent<DepartmentDevicesTableProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [showChangeWorkerModal, setShowChangeWorkerModal] = useState(false);
  const [editingDevice, setEditingDevice] = useState<Models.DeviceShort | null>(null);
  const [newWorker, setNewWorker] = useState<Models.WorkerShort | undefined>(undefined);
  const [showWorkerNoLongerDevice, setShowWorkerNoLongerDevice] = useState(false);

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const departmentDevicesTabTableSettings: Models.DepartmentDevicesTabTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.departmentDevicesTab ?? {})}),
    [userPreferences]
  );

  const initialTableSettings = {
    pagination: {current: 1, position: ["bottomCenter"], pagination: departmentDevicesTabTableSettings.pageSize, showSizeChanger: true},
    sorter: {columnKey: departmentDevicesTabTableSettings.sortColumn, order: departmentDevicesTabTableSettings.sortOrder},
    filters: {},
  };

  useEffect(() => {
    props.setTableSettings(initialTableSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeWorker = (device: Models.DeviceShort, worker?: Models.WorkerShort) => {
    setShowChangeWorkerModal(true);
    setEditingDevice(device);
    setNewWorker(worker);
  };

  const {pageSize, sortColumn, sortOrder} = departmentDevicesTabTableSettings;

  const columns: ColumnsType<Models.DeviceShort> = [
    {
      title: WebHelper.formatMessage("DepartmentDevicesTable-deviceTagColumnTitle"),
      key: "device_tag",
      sorter: (a, b) => a.device_tag.localeCompare(b.device_tag),
      sortOrder: sortColumn === "device_tag" ? sortOrder : undefined,
      render: (_, record) => (
        <LinkButton onClick={() => props.selectDevice(record)}>
          <Highlighter
            highlightClassName={styles.highlightText}
            searchWords={[props.filterValue ?? ""]}
            autoEscape
            textToHighlight={record.device_tag}
          />
        </LinkButton>
      ),
      width: "16%",
    },
    {
      title: WebHelper.formatMessage("DepartmentDevicesTable-workerColumnTitle"),
      key: "assigned_worker",
      render: (_, record) =>
        authenticationStore.permissionLevel(Models.UserScope.Department, record.department_id!) === Models.UserAccessLevel.Admin ||
        authenticationStore.permissionLevel(Models.UserScope.Department, record.department_id!) === Models.UserAccessLevel.Manager ? (
          record.assignable ? (
            <EditableWorker device={record} onSave={handleChangeWorker} />
          ) : (
            <Tooltip title={WebHelper.formatMessage("DepartmentDevicesTable-disabledWorkerColumnValue")}>
              <Typography.Text disabled>{renderAssignedWorker(record)}</Typography.Text>
            </Tooltip>
          )
        ) : (
          renderAssignedWorker(record)
        ),
      sorter: (a, b) => {
        const aValue = renderAssignedWorker(a);
        const bValue = renderAssignedWorker(b);
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortColumn === "assigned_worker" ? sortOrder : undefined,
      width: "37%",
    },
    {
      title: WebHelper.formatMessage("DepartmentDevicesTable-daysSinceLastUsedColumnTitle"),
      key: "most_recent_gateway_event_at",
      sortOrder: sortColumn === "most_recent_gateway_event_at" ? sortOrder : undefined,
      sorter: (a: Models.DeviceShort, b: Models.DeviceShort) => {
        const connectionState = (device: Models.DeviceShort) =>
          device.status === Models.DeviceStatus.Connected ? 3 : device.status === Models.DeviceStatus.Disconnected ? 2 : 1;

        const statusComparison = connectionState(a) - connectionState(b);
        if (statusComparison !== 0) {
          return statusComparison;
        }

        if (a.status === Models.DeviceStatus.Disconnected && b.status === Models.DeviceStatus.Disconnected) {
          const aDate = a.most_recent_gateway_event_at ? new Date(a.most_recent_gateway_event_at) : new Date(0);
          const bDate = b.most_recent_gateway_event_at ? new Date(b.most_recent_gateway_event_at) : new Date(0);

          return bDate.getTime() - aDate.getTime();
        }

        return 0;
      },
      render: (_, record) => (
        <ConnectedColumnRender
          mostRecentGatewayEventAt={record.most_recent_gateway_event_at}
          siteTimezone={record.site_tz_location}
          status={record.status}
        />
      ),
      width: "37%",
    },
    {
      title: WebHelper.formatMessage("DepartmentDevicesTable-statusColumnTitle"),
      key: "status",
      width: "10%",
      render: (_, record) => (
        <Button
          className={record.assignable ? styles.inServiceIcon : styles.outOfServiceIcon}
          type="text"
          onClick={() => props.onOpenChangeWorkerStatusModal(record)}
          disabled={
            !(authenticationStore.permissionLevel(Models.UserScope.Department, record.department_id!) === Models.UserAccessLevel.Admin) &&
            !(authenticationStore.permissionLevel(Models.UserScope.Department, record.department_id!) === Models.UserAccessLevel.Manager)
          }
          icon={
            record.assignable ? (
              <Tooltip title={WebHelper.formatMessage("DepartmentDevicesTable-inServiceTooltip")}>
                <img src={deviceInServiceStatusIcon} alt={WebHelper.formatMessage("DepartmentDevicesTable-inServiceTooltip")} />
              </Tooltip>
            ) : (
              <Tooltip title={WebHelper.formatMessage("DepartmentDevicesTable-outOfServiceTooltip")}>
                <img src={deviceOutOfServiceStatusIcon} alt={WebHelper.formatMessage("DepartmentDevicesTable-outOfServiceTooltip")} />
              </Tooltip>
            )
          }
        />
      ),
    },
  ];

  const handleSettingsChange = (updatedSettings: Partial<Models.DepartmentDevicesTableSettings>) =>
    setUserPreferences({tableSettings: {departmentDevicesTab: {...departmentDevicesTabTableSettings, ...updatedSettings}}});

  return (
    <>
      <Table
        columns={columns}
        loading={props.loading}
        dataSource={props.devices}
        onChange={(pagination, filters, sorter, extra) => {
          props.setTableSettings({pagination: pagination, sorter: sorter, filters: filters});
          props.onTableChange(extra.action, {tableSettings: {pagination: pagination, sorter: sorter, filters: filters}});
          if (extra.action === "sort" && !Array.isArray(sorter)) {
            handleSettingsChange({
              sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
              sortOrder: sorter.order,
            });
          }
        }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (_, size) =>
            handleSettingsChange({
              pageSize: size,
            }),
        }}
      />
      {editingDevice && (
        <ChangeWorkerModal
          device={editingDevice}
          open={showChangeWorkerModal}
          onClose={() => setShowChangeWorkerModal(false)}
          newWorker={newWorker}
          onChangeWorker={props.refetchDevices}
          setShowWorkerNoLongerDevice={setShowWorkerNoLongerDevice}
        />
      )}
      {showWorkerNoLongerDevice && editingDevice && editingDevice.worker_assignable && editingDevice.assigned_worker && (
        <WorkerNoDevice
          worker={editingDevice.assigned_worker}
          departmentId={editingDevice.department_id!}
          onOk={props.refetchDevices}
          onClose={() => setShowWorkerNoLongerDevice(false)}
          open={showWorkerNoLongerDevice}
          entryPoint={SegmentEntryPoint.DEVICE_TABLE}
        />
      )}
    </>
  );
});

function renderAssignedWorker(record: Models.DeviceShort) {
  return record.worker_first_name || record.worker_last_name
    ? `${record.worker_first_name} ${record.worker_last_name}`
    : record.worker_employee_id ?? WebHelper.formatMessage("Common-unassignedLabel");
}
