import {CloseOutlined} from "@ant-design/icons";
import {Drawer, Typography} from "antd";
import Sider from "antd/lib/layout/Sider";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import {DepartmentResponse} from "../../../core/apiclient/department/DepartmentAPIClient.types";
import {GatewaysResponse} from "../../../core/apiclient/gateway/GatewayAPIClient.types";
import {OrganizationResponse} from "../../../core/apiclient/organization/OrganizationAPIClient.types";
import {SiteResponse} from "../../../core/apiclient/site/SiteAPIClient.types";
import {AppStore} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {EntityDetailsSider} from "../entity-details-sider/EntityDetailsSider";
import styles from "./NavigationMenu.module.scss";

type NavigationMenuProps = {
  onGatewayDrawerOpen: () => void;
  onSiderCollapse: () => void;
  open: boolean;
  onDepartmentCreated: () => void;
  onSiteCreated: () => void;
  organizationQuery: {
    data?: OrganizationResponse;
    isFetching: boolean;
  };
  siteQuery: {
    data?: SiteResponse;
    isFetching: boolean;
  };
  departmentQuery: {
    data?: DepartmentResponse;
    isFetching: boolean;
  };
  gatewaysQuery: {
    data?: GatewaysResponse;
  };
};

export const NavigationMenu: FunctionComponent<NavigationMenuProps> = observer(
  ({
    onGatewayDrawerOpen,
    open,
    onSiderCollapse,
    organizationQuery,
    siteQuery,
    departmentQuery,
    gatewaysQuery,
    onSiteCreated,
    onDepartmentCreated,
  }) => {
    const appStore = AppStore.getInstance();
    const isMobile = appStore.state.mode === "mobileXs";

    return isMobile ? (
      <Drawer className={styles.drawer} open={open} placement="left" width={WebHelper.drawerWidth} closable={false} destroyOnClose>
        <>
          <div className={styles.drawerHeader}>
            <Typography.Title level={3} className={styles.title}>
              {WebHelper.formatMessage("EntityDetails-navigationMenu")}
            </Typography.Title>
            <CloseOutlined className={styles.closeIcon} onClick={() => onSiderCollapse()} />
          </div>
          <EntityDetailsSider
            onDepartmentCreated={onDepartmentCreated}
            onSiteCreated={onSiteCreated}
            organizationQuery={{
              data: organizationQuery.data,
              isFetching: organizationQuery.isFetching,
            }}
            siteQuery={{
              data: siteQuery.data,
              isFetching: siteQuery.isFetching,
            }}
            departmentQuery={{
              data: departmentQuery.data,
              isFetching: departmentQuery.isFetching,
            }}
            gatewaysQuery={{data: gatewaysQuery.data}}
            setIsGatewaysDetailDrawerVisible={onGatewayDrawerOpen}
          />
        </>
      </Drawer>
    ) : (
      <Sider className={open ? "" : styles.sider} width={298} theme="light" collapsible collapsed={open} collapsedWidth={0} trigger={null}>
        {(organizationQuery.data?.organization || organizationQuery.isFetching) && (
          <EntityDetailsSider
            onDepartmentCreated={onDepartmentCreated}
            onSiteCreated={onSiteCreated}
            organizationQuery={{
              data: organizationQuery.data,
              isFetching: organizationQuery.isFetching,
            }}
            siteQuery={{
              data: siteQuery.data,
              isFetching: siteQuery.isFetching,
            }}
            departmentQuery={{
              data: departmentQuery.data,
              isFetching: departmentQuery.isFetching,
            }}
            gatewaysQuery={{data: gatewaysQuery.data}}
            setIsGatewaysDetailDrawerVisible={onGatewayDrawerOpen}
          />
        )}
      </Sider>
    );
  }
);
