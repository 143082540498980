import {InfoCircleFilled} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Typography} from "antd";
import {AuthenticationDataStore} from "core/stores/AuthenticationDataStore";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {WebHelper} from "web/utils/WebHelper";

import * as Models from "../../../core/models";
import {GatewayModule} from "../../../core/modules/GatewayModule";
import styles from "./GatewayAlertBlock.module.scss";

interface GatewayAlertBlockProps {}

export const GatewayAlertBlock: FunctionComponent<GatewayAlertBlockProps> = () => {
  const {orgId, departmentId, siteId} = useParams();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const [alertMessage, setAlertMessage] = useState("");

  const gatewaysQuery = useQuery({
    queryKey: ["gateway", orgId, siteId, departmentId],
    queryFn: () => {
      return GatewayModule.gateways({
        accessToken: authenticationStore.state.accessToken!,
        ...(departmentId ? {department_id: departmentId} : siteId ? {site_id: siteId} : {organization_id: orgId}),
      });
    },
  });

  useEffect(() => {
    if (gatewaysQuery.isSuccess) gatewaysQuery.data.gateways && setAlertMessage(gatewayAlertsText(gatewaysQuery.data.gateways));
  }, [gatewaysQuery.isSuccess, gatewaysQuery.data]);

  const gatewayAlertsText = (gateways: Models.Gateway[]) => {
    let message = "";
    const presentStatuses = {
      [Models.GatewayStatus.Down]: [] as Models.Gateway[],
      [Models.GatewayStatus.Intermittent]: [] as Models.Gateway[],
    };

    const presentStatusesByDepartment: {[department: string]: Models.Gateway[]} = {};

    gateways.forEach((gateway) => {
      if (gateway.status == Models.GatewayStatus.Down) {
        presentStatuses[Models.GatewayStatus.Down].push(gateway);
        gateway.department &&
          (presentStatusesByDepartment[gateway.department.name]
            ? presentStatusesByDepartment[gateway.department.name].push(gateway)
            : (presentStatusesByDepartment[gateway.department.name] = [gateway]));
      }

      if (gateway.status == Models.GatewayStatus.Intermittent) {
        presentStatuses[Models.GatewayStatus.Intermittent].push(gateway);
        gateway.department &&
          (presentStatusesByDepartment[gateway.department.name]
            ? presentStatusesByDepartment[gateway.department.name].push(gateway)
            : (presentStatusesByDepartment[gateway.department.name] = [gateway]));
      }
    });

    const gatewaysWithIssuesCount =
      presentStatuses[Models.GatewayStatus.Down].length + presentStatuses[Models.GatewayStatus.Intermittent].length;

    if (gatewaysWithIssuesCount == 1) {
      if (presentStatuses[Models.GatewayStatus.Down].length == 1) {
        const gateway = presentStatuses[Models.GatewayStatus.Down][0];
        message = WebHelper.formatMessage("GatewayAlertBlock-offlineGateway", {
          serialNumber: gateway.serial_number,
          siteName: gateway.site?.name || "",
          departmentName: gateway.department?.name || `department[${gateway.department_id}]`,
          lastEventDate: gateway.most_recent_gateway_event_at
            ? `${new Date(gateway.most_recent_gateway_event_at).toLocaleDateString("en-US", {
                year: "numeric",
                month: "short",
                day: "numeric",
              })} ${WebHelper.formatMessage("Common-at")} ${WebHelper.formatTimeOnly(
                new Date(gateway.most_recent_gateway_event_at),
                gateway.site?.tz_location
              )}`
            : "",
        });
      }
      if (presentStatuses[Models.GatewayStatus.Intermittent].length == 1) {
        const gateway = presentStatuses[Models.GatewayStatus.Intermittent][0];
        message = WebHelper.formatMessage("GatewayAlertBlock-intermittentGateway", {
          serialNumber: gateway.serial_number,
          siteName: gateway.site?.name || "",
          departmentName: gateway.department?.name || `department[${gateway.department_id}]`,
        });
      }
    } else if (gatewaysWithIssuesCount > 1) {
      const listsText = Object.keys(presentStatusesByDepartment).map((key) => {
        const serialNumbers = presentStatusesByDepartment[key].map((gateway) => gateway.serial_number);
        const lastSerialNumber = serialNumbers.pop();
        const serialNumberList = serialNumbers.length
          ? `${serialNumbers.join(", ")} ${WebHelper.formatMessage("Common-and")} ${lastSerialNumber}`
          : lastSerialNumber;

        return WebHelper.formatMessage("GatewayAlertBlock-offlineIntermitentGatewaysList", {
          serialNumberList: serialNumberList ?? "",
          departmentName: presentStatusesByDepartment[key][0].department?.name || "",
          siteName: presentStatusesByDepartment[key][0].site?.name || "",
        });
      });

      message = WebHelper.formatMessage("GatewayAlertBlock-offlineIntermitentGateways", {listsText: listsText.join(", ")});
    }

    return message;
  };

  if (gatewaysQuery.isPending || alertMessage == "") return <></>;
  else
    return (
      <div className={styles.alertBlockWrapper}>
        <InfoCircleFilled />
        <Typography.Text>{alertMessage}</Typography.Text>
      </div>
    );
};
