import {DeleteOutlined} from "@ant-design/icons";
import {Space, Typography, Card} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../../utils/WebHelper";
import {Button} from "../../common/button/Button";
import styles from "./GatewayItem.module.scss";

type GatewayItemProps = {
  gateway: Models.Gateway;
  onDeallocate: () => void;
};

export const GatewayItem: FunctionComponent<GatewayItemProps> = observer(({gateway, onDeallocate}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  return (
    <Card className={styles.gatewayWrapper}>
      <Space className={styles.detailSpace}>
        <Typography.Text strong>{`Gateway ${gateway.serial_number}`}</Typography.Text>
      </Space>
      <Space className={`${styles.detailSpace} ${styles.statusItem}`}>
        <Typography.Text type="secondary">{WebHelper.formatMessage("DepartmentGatewaysDetail-gatewayItemStatus")}</Typography.Text>
        <Typography.Text>{mapGatewayStatus(gateway.status)}</Typography.Text>
      </Space>
      <Space className={styles.detailSpace}>
        <Typography.Text type="secondary">
          {WebHelper.formatMessage("DepartmentGatewaysDetail-gatewayItemLastTimeConnected")}
        </Typography.Text>
        {gateway.most_recent_gateway_event_at && (
          <Typography.Text>{WebHelper.formatDate(gateway.most_recent_gateway_event_at, gateway.site?.tz_location)}</Typography.Text>
        )}
      </Space>
      <Space className={styles.detailSpace}>
        <Typography.Text type="secondary">{WebHelper.formatMessage("DepartmentGatewaysDetail-gatewayItemFirmware")}</Typography.Text>
        <Typography.Text>{gateway.current_firmware_version}</Typography.Text>
      </Space>
      <Space className={styles.detailSpace}>
        {gateway.department?.id &&
          authenticationStore.permissionLevel(Models.UserScope.Department, gateway.department.id) === Models.UserAccessLevel.Admin &&
          authenticationStore.isUserGlobal && (
            <Button onClick={onDeallocate} shape="round" icon={<DeleteOutlined />} data-cy="deallocateGatewayButton">
              {WebHelper.formatMessage("GatewayItem-deallocateGateway")}
            </Button>
          )}
      </Space>
    </Card>
  );
});

function mapGatewayStatus(status: Models.GatewayStatus) {
  switch (status) {
    case Models.GatewayStatus.Up:
      return (
        <>
          <span className={styles.statusText}>{WebHelper.formatMessage("Common-gatewayStatusUp")}</span>
          <span className={`${styles.circleSolid} ${styles.up}`} />
        </>
      );
    case Models.GatewayStatus.Intermittent:
      return (
        <>
          <span className={styles.statusText}>{WebHelper.formatMessage("Common-gatewayStatusIntermittent")}</span>
          <span className={`${styles.circleSolid} ${styles.intermittent}`} />
        </>
      );
    case Models.GatewayStatus.Down:
      return (
        <>
          <span className={styles.statusText}>{WebHelper.formatMessage("Common-gatewayStatusDown")}</span>
          <span className={`${styles.circleSolid} ${styles.down}`} />
        </>
      );
    case Models.GatewayStatus.NeverSeen:
      return (
        <>
          <span className={styles.statusText}>{WebHelper.formatMessage("Common-gatewayStatusNeverSeen")}</span>
          <span className={`${styles.circleSolid} ${styles.neverSeen}`} />
        </>
      );
    default:
      return <>{WebHelper.formatMessage("DepartmentGatewaysDetail-gatewayStatusDefault")}</>;
  }
}
