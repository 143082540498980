import {Button, Space, Typography} from "antd";
import {ArcElement, Chart as ChartJS} from "chart.js";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo} from "react";

import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";
import styles from "./StatusChart.module.scss";

const constants = require("assets/stylesheets/constants");

ChartJS.register(ArcElement);

enum StatusEntityType {
  Organization,
  Site,
  Department,
  WorkerReportGroup,
}

type StatusData = {
  key: string;
  value?: number;
  label: string;
  color: string;
};

type DashboardDeviceStatus = {
  assigned_devices: number;
  available_devices: number;
  out_of_service_devices: number;
};

type DashboardWorkerStatus = {
  active_workers?: number;
  inactive_workers?: number;
  waitlist_workers: number;
};

type StatusChartProps = {
  deviceStatus?: DashboardDeviceStatus;
  workerStatus: DashboardWorkerStatus;
  entityType: Models.EntityType;
  entityID: string;
  timezone: string | undefined;
  onListsDrawerOpen?: (
    key: string,
    drawerType: "devices" | "workers",
    entityType: Models.EntityType,
    id: string,
    timezone: string | undefined
  ) => void;
  childrenCard: boolean;
};

export const StatusChart: FunctionComponent<StatusChartProps> = observer(
  ({deviceStatus, workerStatus, entityType, entityID, timezone, onListsDrawerOpen, childrenCard}) => {
    const localeEntityType: StatusEntityType = useMemo(() => {
      switch (entityType) {
        case Models.EntityType.Organization:
          return StatusEntityType.Organization;
        case Models.EntityType.Site:
          return StatusEntityType.Site;
        case Models.EntityType.Department:
          return StatusEntityType.Department;
        case Models.EntityType.WorkerReportGroup:
          return StatusEntityType.WorkerReportGroup;
        default:
          return StatusEntityType.Organization;
      }
    }, [entityType]);

    const orderedDeviceStatusKeys: (keyof DashboardDeviceStatus)[] = useMemo(() => {
      if (entityType !== Models.EntityType.WorkerReportGroup) {
        return ["assigned_devices", "available_devices", "out_of_service_devices"];
      } else return [];
    }, [entityType]);

    const orderedWorkerStatusKeys: (keyof DashboardWorkerStatus)[] = useMemo(() => {
      if (entityType === Models.EntityType.Department && !childrenCard) {
        return ["waitlist_workers"];
      } else return ["active_workers", "inactive_workers", "waitlist_workers"];
    }, [childrenCard, entityType]);

    const deviceStatusData: StatusData[] = useMemo(() => {
      if (deviceStatus) {
        return orderedDeviceStatusKeys.map((key) => {
          return {
            key,
            value: deviceStatus[key],
            label: mapDeviceStatusLabel(key),
            color: mapDeviceStatusColor(key),
          };
        });
      } else return [];
    }, [deviceStatus, orderedDeviceStatusKeys]);

    const workerStatusData: StatusData[] = useMemo(
      () =>
        orderedWorkerStatusKeys.map((key) => {
          return {
            key,
            value: workerStatus[key],
            label: mapWorkerStatusLabel(key),
            color: mapWorkerStatusColor(key),
          };
        }),
      [workerStatus, orderedWorkerStatusKeys]
    );

    return (
      <>
        <Space className={styles.wrapper} direction="vertical" size={8}>
          <Typography.Text strong>
            {WebHelper.formatMessage("StatusChart-statusChartTitle", {entityType: localeEntityType})}
          </Typography.Text>
          {deviceStatusData.length > 0 && (
            <>
              <Typography.Text>{WebHelper.formatMessage("StatusChart-deviceStatusTitle")}</Typography.Text>
              <Space className={styles.bars} direction="vertical" size={4}>
                {deviceStatusData.map((status) =>
                  onListsDrawerOpen ? (
                    <Button
                      type="text"
                      key={status.key}
                      className={styles.bar}
                      style={{backgroundColor: status.color}}
                      onClick={() => onListsDrawerOpen(status.key, "devices", entityType, entityID, timezone)}>
                      <Typography.Text strong className={styles.devicesText}>
                        {status.value}
                      </Typography.Text>
                      <div className={styles.devicesText}>{status.label}</div>
                    </Button>
                  ) : (
                    <Typography.Text key={status.key} className={styles.bar} style={{backgroundColor: status.color}}>
                      <Typography.Text strong className={styles.devicesText}>
                        {status.value}
                      </Typography.Text>
                      <div className={styles.devicesText}>{status.label}</div>
                    </Typography.Text>
                  )
                )}
              </Space>
            </>
          )}
          <Typography.Text>{WebHelper.formatMessage("StatusChart-workerStatusTitle")}</Typography.Text>
          <Space className={styles.bars} direction="vertical" size={4}>
            {workerStatusData.map((status) =>
              onListsDrawerOpen ? (
                <Button
                  type="text"
                  key={status.key}
                  className={styles.bar}
                  style={{backgroundColor: status.color}}
                  onClick={() => onListsDrawerOpen(status.key, "workers", entityType, entityID, timezone)}>
                  <Typography.Text strong className={styles.workersText}>
                    {status.value}
                  </Typography.Text>
                  <div className={styles.workersText}>{status.label}</div>
                </Button>
              ) : (
                <Typography.Text key={status.key} className={styles.bar} style={{backgroundColor: status.color}}>
                  <Typography.Text strong className={styles.workersText}>
                    {status.value}
                  </Typography.Text>
                  <div className={styles.workersText}>{status.label}</div>
                </Typography.Text>
              )
            )}
          </Space>
          {onListsDrawerOpen && (
            <div className={styles.moreInfoButton}>
              <Typography.Text className={styles.moreInfoButtonText}>
                {" "}
                {WebHelper.formatMessage("StatusChart-moreInformationText")}
              </Typography.Text>
            </div>
          )}
        </Space>
      </>
    );
  }
);

function mapDeviceStatusLabel(key: keyof DashboardDeviceStatus) {
  switch (key) {
    case "assigned_devices":
      return WebHelper.formatMessage("StatusChart-assignedDevices");
    case "available_devices":
      return WebHelper.formatMessage("StatusChart-availableDevices");
    case "out_of_service_devices":
      return WebHelper.formatMessage("StatusChart-outOfServiceDevices");
    default:
      return "";
  }
}

function mapDeviceStatusColor(key: keyof DashboardDeviceStatus): string {
  switch (key) {
    case "assigned_devices":
      return constants.colors.chart[3];
    case "available_devices":
      return constants.colors.chart[6];
    case "out_of_service_devices":
      return constants.colors.chart[5];
    default:
      return "";
  }
}

function mapWorkerStatusLabel(key: keyof DashboardWorkerStatus) {
  switch (key) {
    case "active_workers":
      return WebHelper.formatMessage("StatusChart-activeWorkers");
    case "inactive_workers":
      return WebHelper.formatMessage("StatusChart-inactiveWorkers");
    case "waitlist_workers":
      return WebHelper.formatMessage("StatusChart-waitlistWorkers");
    default:
      return "";
  }
}

function mapWorkerStatusColor(key: keyof DashboardWorkerStatus): string {
  switch (key) {
    case "active_workers":
      return constants.colors.chart[7];
    case "inactive_workers":
      return constants.colors.chart[8];
    case "waitlist_workers":
      return constants.colors.chart[9];
    default:
      return "";
  }
}
