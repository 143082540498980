import {GatewayAPIClient} from "../apiclient/gateway/GatewayAPIClient";
import * as GatewayTypes from "../apiclient/gateway/GatewayAPIClient.types";

async function gateways(request: GatewayTypes.GatewaysRequest, shouldUpdateState = true): Promise<GatewayTypes.GatewaysResponse> {
  return await GatewayAPIClient.gateways(request);
}

async function gateway(request: GatewayTypes.GatewayRequest): Promise<GatewayTypes.GatewayResponse> {
  return await GatewayAPIClient.gateway(request);
}

async function createGateway(request: GatewayTypes.CreateGatewayRequest): Promise<GatewayTypes.CreateGatewayResponse> {
  return await GatewayAPIClient.createGateway(request);
}

async function deleteGateway(request: GatewayTypes.DeleteGatewayRequest): Promise<GatewayTypes.DeleteGatewayResponse> {
  return await GatewayAPIClient.deleteGateway(request);
}

async function updateGateway(request: GatewayTypes.UpdateGatewayRequest): Promise<GatewayTypes.UpdateGatewayResponse> {
  return await GatewayAPIClient.updateGateway(request);
}

async function allocateGatewayToDepartment(
  request: GatewayTypes.AllocateGatewayToDepartmentRequest
): Promise<GatewayTypes.AllocateGatewayToDepartmentResponse> {
  return await GatewayAPIClient.allocateGatewayToDepartment(request);
}

export const GatewayModule = {
  gateways,
  gateway,
  createGateway,
  deleteGateway,
  updateGateway,
  allocateGatewayToDepartment,
};
