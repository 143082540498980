import {EditOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Card, Space, Typography} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../core/stores/DeviceDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {LinkButton} from "../common/link-button/LinkButton";
import {ConfirmationModal} from "../confirmation-modal/ConfirmationModal";
import {Loading} from "../loading/Loading";
import {DeviceEditModal} from "./device-edit-modal/DeviceEditModal";
import styles from "./DeviceDetail.module.scss";

export type DeviceDetailProps = {
  deviceId: string;
  onClose?: () => void;
  onActionPerformed: () => void;
};

export const DeviceDetail: FunctionComponent<DeviceDetailProps> = ({deviceId, onClose, onActionPerformed}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const [showEditModal, setShowEditModal] = useState(false);
  const [device, setDevice] = useState<Models.Device>();
  const [currentDevice, setCurrentDevice] = useState<Models.Device>();
  const [showDeallocateDeviceModal, setShowDeallocateDeviceModal] = useState(false);

  const deviceQuery = useQuery({
    queryKey: ["DepartmentDetail-fetchDeviceData"],
    queryFn: () => {
      return deviceStore.device({
        accessToken: authenticationStore.state.accessToken!,
        id: deviceId,
      });
    },
  });

  useEffect(() => {
    if (!deviceQuery.data) return;

    if (!deviceQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDeviceManagementDeviceDetailPageData-errorMessage"),
        deviceQuery.data.correlationId
      );
      return;
    }
    setCurrentDevice(deviceQuery.data.device);
    setDevice(deviceQuery.data.device);

    appStore.sendAnalyticTrack(SegmentKey.DeviceDetailOpened, {
      deviceID: deviceQuery.data.device.id,
      orgID: deviceQuery.data.device.organization?.id,
      siteID: deviceQuery.data.device.site?.id,
      departmentID: deviceQuery.data.device.department?.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceQuery.data]);

  const deallocateMutation = useMutation({
    mutationFn: () =>
      deviceStore.allocateDeviceToDepartment({
        accessToken: authenticationStore.state.accessToken!,
        id: device!.id,
        department_id: null,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceDetail-deallocateError"), response.correlationId);
      } else {
        appStore.showMessage(
          WebHelper.formatMessage("DeviceDetail-deallocateSuccessMessage", {
            deviceTag: device?.device_tag,
            departmentName: device?.department?.name,
          }),
          "success"
        );
        onActionPerformed();
        setShowDeallocateDeviceModal(false);
        if (onClose) onClose();
      }
    },
  });

  const handleDeallocateCancel = () => {
    if (deallocateMutation.isPending) return;

    setShowDeallocateDeviceModal(false);
  };

  if (deviceQuery.isFetching) return <Loading />;

  return (
    <>
      <Space className={styles.cardsWrapper} direction="vertical" size={16}>
        <Card className={styles.card}>
          <Space className={styles.cardContent} direction="vertical" size={16}>
            <div className={styles.cardTitle}>
              <Typography.Text strong>{WebHelper.formatMessage("DeviceDetail-deviceProfile")}</Typography.Text>
              {currentDevice?.department?.id &&
                (authenticationStore.permissionLevel(Models.UserScope.Department, currentDevice?.department.id) ===
                  Models.UserAccessLevel.Admin ||
                  authenticationStore.permissionLevel(Models.UserScope.Department, currentDevice?.department.id) ===
                    Models.UserAccessLevel.Manager) && (
                  <EditOutlined className={styles.editIcon} onClick={() => setShowEditModal(true)} data-cy="editButton" />
                )}
            </div>
            <Space size={8}>
              <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
                <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-systemSerialNumber")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-rev")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-firmware")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-tag")}</Typography.Text>
                <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-assignedWorker")}</Typography.Text>
              </Space>
              <Space direction="vertical" size={16} align="start">
                <Typography.Text data-cy="systemSerialNumberText">
                  {currentDevice?.system_serial_number.length === 0 ? (
                    <div className={styles.emptyValue} />
                  ) : (
                    currentDevice?.system_serial_number
                  )}
                </Typography.Text>
                <Typography.Text data-cy="revText">
                  {currentDevice?.item_number_revision.length === 0 ? (
                    <div className={styles.emptyValue} />
                  ) : (
                    currentDevice?.item_number_revision
                  )}
                </Typography.Text>
                <Typography.Text data-cy="firmwareText">
                  {currentDevice?.current_firmware_revision.length === 0 ? (
                    <div className={styles.emptyValue} />
                  ) : (
                    currentDevice?.current_firmware_revision
                  )}
                </Typography.Text>
                <Typography.Text data-cy="deviceTagText">
                  {currentDevice?.device_tag.length === 0 ? <div className={styles.emptyValue} /> : currentDevice?.device_tag}
                </Typography.Text>
                <Typography.Text data-cy="workerText">
                  {currentDevice?.assigned_worker &&
                  (currentDevice?.assigned_worker.employee_id || currentDevice?.assigned_worker.displayName) ? (
                    currentDevice?.assigned_worker.displayName ? (
                      currentDevice?.assigned_worker.displayName
                    ) : (
                      currentDevice?.assigned_worker.employee_id
                    )
                  ) : (
                    <div className={styles.emptyValue} />
                  )}
                </Typography.Text>
              </Space>
            </Space>
          </Space>
        </Card>

        {currentDevice && (
          <Card className={styles.card}>
            <Space direction="vertical" size={16}>
              <Typography.Text strong>{WebHelper.formatMessage("DeviceDetail-deviceStatistics")}</Typography.Text>
              <Space size={8}>
                <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
                  <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-dateFirstUsed")}</Typography.Text>
                  <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-dateLastUsed")}</Typography.Text>
                  <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-dateLastConnected")}</Typography.Text>
                  <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-totalHours")}</Typography.Text>
                  <Typography.Text type="secondary">{WebHelper.formatMessage("DeviceDetail-totalLifts")}</Typography.Text>
                </Space>
                <Space direction="vertical" size={16}>
                  {currentDevice.first_session_start_at ? (
                    <Typography.Text>
                      {WebHelper.formatDate(currentDevice.first_session_start_at, currentDevice.site?.tz_location)}
                    </Typography.Text>
                  ) : (
                    <div className={styles.emptyValue} />
                  )}
                  {currentDevice.most_recent_session_start_at ? (
                    <Typography.Text>
                      {WebHelper.formatDate(currentDevice.most_recent_session_start_at, currentDevice.site?.tz_location)}
                    </Typography.Text>
                  ) : (
                    <div className={styles.emptyValue} />
                  )}
                  {currentDevice?.most_recent_gateway_event_at ? (
                    <Typography.Text>
                      {WebHelper.formatDate(currentDevice.most_recent_gateway_event_at, currentDevice.site?.tz_location)}
                    </Typography.Text>
                  ) : (
                    <div className={styles.emptyValue} />
                  )}
                  <Typography.Text>{WebHelper.secondsToHours(currentDevice.total_time_used_seconds)}</Typography.Text>
                  <Typography.Text>{currentDevice?.total_lifts}</Typography.Text>
                </Space>
              </Space>
            </Space>
          </Card>
        )}
        {device &&
          device.department &&
          (authenticationStore.permissionLevel(Models.UserScope.Department, device.department.id) === Models.UserAccessLevel.Admin ||
            authenticationStore.permissionLevel(Models.UserScope.Department, device.department.id) === Models.UserAccessLevel.Manager) &&
          authenticationStore.isUserGlobal && (
            <LinkButton onClick={() => setShowDeallocateDeviceModal(true)}>
              {WebHelper.formatMessage("DeviceDetail-deallocateDevice")}
            </LinkButton>
          )}
      </Space>

      {currentDevice && (
        <DeviceEditModal
          device={currentDevice}
          open={showEditModal}
          onClose={() => setShowEditModal(false)}
          onUpdate={(updatedDevice: Models.Device) => {
            onActionPerformed();
            setCurrentDevice(updatedDevice);
            setShowEditModal(false);
          }}
        />
      )}
      {device && device.department && (
        <ConfirmationModal
          showConfirmationModal={showDeallocateDeviceModal}
          loading={deallocateMutation.isPending}
          title={WebHelper.formatMessage("DeviceDetail-deallocateDevice")}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage("DeviceDetail-deallocateDeviceMessage", {
                    deviceTag: device.device_tag,
                    systemSerialNumber: device.system_serial_number,
                    departmentName: device.department?.name,
                  })
                ),
              }}
            />
          }
          onOk={() => deallocateMutation.mutate()}
          onCancel={handleDeallocateCancel}
          referenceConfirmationValue={WebHelper.formatMessage("Common-deallocateConfirmationValue")}
          okButtonText={WebHelper.formatMessage("DeviceDetail-deallocate")}
        />
      )}
    </>
  );
};
