import {Form, FormInstance, Input, Typography} from "antd";
import {NamePath} from "antd/lib/form/interface";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback} from "react";

import * as Models from "../../../../../../core/models";
import {AppConfig} from "../../../../../AppConfig";
import {WebHelper} from "../../../../../utils/WebHelper";

export type WorkersReportGroupFormFields = {
  name: string;
};

type WorkersReportGroupFormProps = {
  form: FormInstance<WorkersReportGroupFormFields>;
  initialValues?: {name: string};
  loading: boolean;
  onFieldsChange: () => void;
  workersReportGroups: Models.WorkerReportGroup[];
};

export const WorkersReportGroupForm: FunctionComponent<WorkersReportGroupFormProps> = observer(
  ({form, initialValues, loading, onFieldsChange, workersReportGroups}) => {
    const {workersReportGroupForm: WorkersReportGroupInputValidation} = AppConfig.Modules.InputValidation;

    const nameValidator = useCallback(
      (getFieldValue: (name: NamePath) => any, name: string | undefined) => {
        const formName = name?.trim().toLowerCase();

        const existingGroup = workersReportGroups.find(
          (workersReportGroup) => formName && formName === workersReportGroup.name.trim().toLowerCase()
        );

        if (existingGroup) {
          return Promise.reject(
            new Error(
              WebHelper.formatMessage("CreateWorkerReportGroupModal-nameAlreadyExistsValidation", {
                siteName: existingGroup.site.name,
              })
            )
          );
        }
        return Promise.resolve();
      },
      [workersReportGroups]
    );

    return (
      <Form
        form={form}
        labelWrap={true}
        initialValues={initialValues}
        disabled={loading}
        onFieldsChange={onFieldsChange}
        unselectable="off">
        <Form.Item
          colon={false}
          label={<Typography.Text>{WebHelper.formatMessage("CreateWorkerReportGroupModal-groupNameLabel")}</Typography.Text>}
          name="name"
          rules={[
            ...WebHelper.formatInputValidationRules(WorkersReportGroupInputValidation.name.rules),
            {
              validator: (_, value) => {
                if (value && value.length > 40) {
                  return Promise.reject(WebHelper.formatMessage("CreateWorkerReportGroupModal-nameLengthValidator"));
                }
                return Promise.resolve();
              },
            },
            ({getFieldValue}) => ({
              validator: (_, name) => nameValidator(getFieldValue, name),
            }),
          ]}>
          <Input />
        </Form.Item>
      </Form>
    );
  }
);
