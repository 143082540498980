import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {BasicWorker} from "./BasicWorker";

export enum SessionStatus {
  Processed = "processed",
  Uploaded = "uploaded",
  Created = "created",
}

export type Segment = {
  name: string;
  count: number;
};

export type SessionError = {
  code: string;
  segments: Segment[] | null;
  count: number;
};

export type ErrorGroup = {
  session_errors: SessionError[];
};

export class Session extends BaseModel {
  id: string;
  session_uuid: string;
  device_id: string;
  worker_id: string;
  status: SessionStatus;
  assigned_worker: BasicWorker | null;
  total_lifts: number;
  duration_sec: number;
  error_count: number;
  errors_group: ErrorGroup | null;
  log_lines: number;
  start_time: Date;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      session_uuid: observable,
      device_id: observable,
      worker_id: observable,
      status: observable,
      assigned_worker: observable,
      total_lifts: observable,
      duration_sec: observable,
      error_count: observable,
      errors_group: observable,
      log_lines: observable,
      start_time: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: Session, json: any) {
    if (json.start_time) object.start_time = new Date(json.start_time);
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);

    if (json.errors_group) {
      object.errors_group = {
        session_errors: json.errors_group.session_errors.map((error: any) => ({
          code: error.code,
          segments: error.segments,
          count: error.count,
        })),
      };
    }

    if (json.assigned_worker) object.assigned_worker = BasicWorker.fromJSON(json.assigned_worker);
  }
}
