import {useMutation, useQuery} from "@tanstack/react-query";
import {Divider, Empty, Form, Input, Modal, Select, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../core/stores/DeviceDataStore";
import {DeviceTypeDataStore} from "../../../core/stores/DeviceTypeDataStore";
import {FirmwareVersionsDataStore} from "../../../core/stores/FirmwareVersionsDataStore";
import {AppConfig} from "../../AppConfig";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";
import styles from "./CreateDeviceModal.module.scss";

type CreateDeviceFormFields = {
  system_serial_number: string;
  mcu_id: string;
  device_tag: string;
  device_type_id: string;
  firmware_version_id: string;
  button_1_controller_setting_id: string;
  button_2_controller_setting_id: string;
  button_3_controller_setting_id: string;
};

type CreateDeviceModalProps = {
  onClose: () => void;
  onDeviceCreated: () => void;
  open: boolean;
};

export const CreateDeviceModal: FunctionComponent<CreateDeviceModalProps> = observer(({onClose, onDeviceCreated, open}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceTypeStore = DeviceTypeDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();
  const firmwareVersionsStore = FirmwareVersionsDataStore.getInstance();

  const {deviceCreationForm: deviceCreationFormInputValidation} = AppConfig.Modules.InputValidation;
  const [form] = Form.useForm<CreateDeviceFormFields>();
  const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
  const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);
  const [formFirmwareVersionID, setFormFirmwareVersionID] = useState("");
  const [deviceTypeID, setDeviceTypeID] = useState("");

  const fetchDeviceTypesQuery = useQuery({
    queryKey: ["CreateDeviceModal-fetchDeviceTypes"],
    queryFn: () => deviceTypeStore.deviceTypes({accessToken: authenticationStore.state.accessToken!}),
    enabled: false,
  });

  useEffect(() => {
    const data = fetchDeviceTypesQuery?.data;
    if (!data) return;
    !data.success && WebHelper.showErrorMessage(WebHelper.formatMessage("CreateDeviceModal-fetchDeviceTypesError"), data.correlationId);
  }, [fetchDeviceTypesQuery?.data]);

  const deviceTypeQuery = useQuery({
    queryKey: ["CreateDeviceModal-fetchDeviceTypeData", deviceTypeID],
    queryFn: () => {
      return deviceTypeStore.deviceType({accessToken: authenticationStore.state.accessToken!, id: deviceTypeID});
    },
    enabled: !!deviceTypeID,
  });

  const {isFetching: firmwareVersionQueryIsFetching, data: firmwareVersionQueryData} = useQuery({
    queryKey: ["CreateDeviceModal-fetchFirmwareVersionData", formFirmwareVersionID],
    queryFn: () => {
      return firmwareVersionsStore.firmwareVersion({
        accessToken: authenticationStore.state.accessToken!,
        id: formFirmwareVersionID,
      });
    },
    enabled: !!formFirmwareVersionID,
  });

  const createDeviceMutation = useMutation({
    mutationFn: (mutationParams: {formValues: CreateDeviceFormFields}) =>
      deviceStore.bulkCreateDevices({
        accessToken: authenticationStore.state.accessToken!,
        device_info: [{...mutationParams.formValues}],
      }),
    onSuccess: (response) => {
      if (!response.success) {
        if (Array.isArray(response.error) && response.error[0].message.includes("duplicate device")) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("CreateDeviceModal-duplicateDeviceError"), response.correlationId);
          return;
        }

        WebHelper.showErrorMessage(WebHelper.formatMessage("CreateDeviceModal-createDeviceError"), response.correlationId);
        return;
      }

      appStore.showMessage(WebHelper.formatMessage("CreateDeviceModal-createDeviceSuccess"), "success");
      appStore.sendAnalyticTrack(SegmentKey.DeviceCreated, {deviceID: response.devices[0].id});
      onDeviceCreated();
      resetForm();
    },
  });

  const deviceTypeOptions = useMemo(
    () =>
      fetchDeviceTypesQuery.data?.deviceTypes?.map(({id, name}) => ({
        key: id,
        label: name,
        value: id,
      })) ?? [],
    [fetchDeviceTypesQuery.data?.deviceTypes]
  );

  const firmwareVersionOptions = useMemo(() => {
    if (!deviceTypeQuery.data?.deviceType?.firmware_versions) return [];

    const options = deviceTypeQuery.data.deviceType.firmware_versions.map(({id, version}) => ({
      key: id,
      label: version,
      value: id,
    }));

    options.unshift({
      key: "",
      label: WebHelper.formatMessage("Common-unassignedLabel"),
      value: "",
    });

    return options;
  }, [deviceTypeQuery.data?.deviceType]);

  const buttonSettingsOptions = useMemo(() => {
    if (!firmwareVersionQueryData?.firmwareVersion?.controller_settings) return [];

    const options = firmwareVersionQueryData.firmwareVersion.controller_settings.map(
      (controllerSettings: Models.ControllerSettingsType) => ({
        key: controllerSettings.id,
        label: WebHelper.getControllerSettingsName(controllerSettings.name),
        value: controllerSettings.id,
      })
    );

    options.unshift({
      key: "",
      label: WebHelper.formatMessage("Common-unassignedLabel"),
      value: "",
    });
    options.sort((a, b) => a.label.localeCompare(b.label, undefined, {numeric: true}));

    return options;
  }, [firmwareVersionQueryData?.firmwareVersion?.controller_settings]);

  const handleOk = async () => {
    form
      .validateFields()
      .then((formValues) => createDeviceMutation.mutate({formValues}))
      .catch(() => {
        // This catch prevents AntD from throwing an error to the console when form validations fail
      });
  };

  const resetForm = () => {
    form.resetFields();
    setIsCreateButtonDisabled(true);
  };

  const handleCancel = () => {
    if (form.isFieldsTouched() && !isDiscardChangesModalVisible) {
      setIsDiscardChangesModalVisible(true);
    } else {
      setIsDiscardChangesModalVisible(false);
      onClose();
      resetForm();
    }
  };

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsCreateButtonDisabled(true);
    } else {
      setIsCreateButtonDisabled(false);
    }
  };

  return (
    <>
      <Modal
        open={open}
        centered
        destroyOnClose
        title={WebHelper.formatMessage("CreateDeviceModal-modalTitle")}
        okText={WebHelper.formatMessage("CreateDeviceModal-okButtonText")}
        okButtonProps={{shape: "round", loading: createDeviceMutation.isPending, disabled: isCreateButtonDisabled}}
        cancelButtonProps={{shape: "round", disabled: createDeviceMutation.isPending}}
        onCancel={handleCancel}
        onOk={handleOk}>
        <Form
          form={form}
          labelCol={{span: 7}}
          wrapperCol={{span: 17}}
          disabled={createDeviceMutation.isPending}
          onFieldsChange={handleOnFieldsChange}>
          <Form.Item
            label={WebHelper.formatMessage("CreateDeviceModal-systemSerialNumberLabel")}
            name="system_serial_number"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.system_serial_number.rules)}>
            <Input />
          </Form.Item>

          <Form.Item
            label={WebHelper.formatMessage("CreateDeviceModal-mcuIDLabel")}
            name="mcu_id"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.mcu_id.rules)}>
            <Input />
          </Form.Item>

          <Form.Item
            label={WebHelper.formatMessage("CreateDeviceModal-deviceTagLabel")}
            name="device_tag"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.device_tag.rules)}>
            <Input />
          </Form.Item>

          <Form.Item
            label={WebHelper.formatMessage("CreateDeviceModal-deviceTypeLabel")}
            name="device_type_id"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.device_type.rules)}>
            <Select
              allowClear
              showSearch
              options={deviceTypeOptions}
              optionFilterProp="label"
              optionLabelProp="label"
              value={deviceTypeID}
              onChange={(value) => {
                setDeviceTypeID(value);
                setFormFirmwareVersionID("");
                form.setFieldsValue({
                  firmware_version_id: "",
                  button_1_controller_setting_id: "",
                  button_2_controller_setting_id: "",
                  button_3_controller_setting_id: "",
                });
              }}
              loading={fetchDeviceTypesQuery.isFetching}
              dropdownRender={(node) => (fetchDeviceTypesQuery.isFetching ? WebHelper.LoadingSpin : node)}
              onDropdownVisibleChange={(open) => {
                if (open) fetchDeviceTypesQuery.refetch();
              }}
            />
          </Form.Item>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-firmwareVersionLabel")}</Typography.Text>}
            name="firmware_version_id"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.firmware_version_id.rules)}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              options={firmwareVersionOptions}
              value={formFirmwareVersionID}
              disabled={!deviceTypeID || deviceTypeQuery.isFetching}
              loading={deviceTypeQuery.isFetching}
              onChange={(value) => {
                setFormFirmwareVersionID(value);
                form.setFieldsValue({
                  button_1_controller_setting_id: "",
                  button_2_controller_setting_id: "",
                  button_3_controller_setting_id: "",
                });
              }}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noFirmwareVersions")}
                />
              }
            />
          </Form.Item>
          <Divider />
          <div className={styles.buttonSettingsTitle}>
            <Typography.Text strong>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonSettingsSubtitle")}</Typography.Text>
          </div>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonLabel", {number: "1"})}</Typography.Text>}
            name="button_1_controller_setting_id"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.button_1_controller_setting_id.rules)}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              options={buttonSettingsOptions}
              disabled={!formFirmwareVersionID || firmwareVersionQueryIsFetching}
              loading={firmwareVersionQueryIsFetching}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noButtonSettings")}
                />
              }
            />
          </Form.Item>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonLabel", {number: "2"})}</Typography.Text>}
            name="button_2_controller_setting_id"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.button_2_controller_setting_id.rules)}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              options={buttonSettingsOptions}
              disabled={!formFirmwareVersionID || firmwareVersionQueryIsFetching}
              loading={firmwareVersionQueryIsFetching}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noButtonSettings")}
                />
              }
            />
          </Form.Item>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonLabel", {number: "3"})}</Typography.Text>}
            name="button_3_controller_setting_id"
            rules={WebHelper.formatInputValidationRules(deviceCreationFormInputValidation.button_3_controller_setting_id.rules)}>
            <Select
              showSearch
              optionFilterProp="label"
              optionLabelProp="label"
              options={buttonSettingsOptions}
              disabled={!formFirmwareVersionID || firmwareVersionQueryIsFetching}
              loading={firmwareVersionQueryIsFetching}
              notFoundContent={
                <Empty
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                  description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noButtonSettings")}
                />
              }
            />
          </Form.Item>
        </Form>
      </Modal>
      <DiscardChangesModal
        onDiscard={handleCancel}
        onClose={() => setIsDiscardChangesModalVisible(false)}
        open={isDiscardChangesModalVisible}
      />
    </>
  );
});
