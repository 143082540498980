import {observable, makeObservable, computed} from "mobx";

import {BaseModel} from "./BaseModel";
import {BasicWorker} from "./BasicWorker";
import {DepartmentNested} from "./DepartmentNested";
import {FirmwareVersionNested} from "./FirmwareVersionNested";
import {OrganizationNested} from "./OrganizationNested";
import {SiteNested} from "./SiteNested";

export enum DeviceStatus {
  NeverSeen = "never_seen",
  Connected = "connected",
  Disconnected = "disconnected",
}

export enum ControllerSettings {
  HeavyLiftHigh = "heavy_lift_high",
  HeavyLiftLow = "heavy_lift_low",
  HeavyLiftMedium = "heavy_lift_medium",
  SquatLiftHigh = "squat_lift_high",
  SquatLiftMedium = "squat_lift_medium",
  SquatLiftTransparent = "squat_lift_transparent",
  UniversalLiftHigh = "universal_lift_high",
  UniversalLiftMedium = "universal_lift_medium",
  UniversalLiftTransparent = "universal_lift_transparent",
  UniversalLowerHigh = "universal_lower_high",
  UniversalLowerMedium = "universal_lower_medium",
  UniversalLowerTransparent = "universal_lower_transparent",
  FloorAssembly70 = "floor_assembly_70",
  FloorAssembly90 = "floor_assembly_90",
  FloorAssembly110 = "floor_assembly_110",
  FloorAssembly130 = "floor_assembly_130",
  FloorAssembly150 = "floor_assembly_150",
  FloorAssembly170 = "floor_assembly_170",
  FloorAssembly190 = "floor_assembly_190",
  FloorAssemblyTransparent = "floor_assembly_transparent",
}

export type ButtonSetting = {
  id: string;
  name: ControllerSettings;
  controller: string;
  version: string;
};

export type DeviceConfig = {
  id: string;
  pending: boolean;
  firmware_version?: FirmwareVersionNested;
  button_1_controller_setting?: ButtonSetting;
  button_2_controller_setting?: ButtonSetting;
  button_3_controller_setting?: ButtonSetting;
};

export enum UpdateBulkDevicesSteps {
  zero = "zero",
  first = "first",
  second = "second",
  three = "three",
}

export enum UpdateAllocateDataSteps {
  zero = "zero",
  first = "first",
  second = "second",
}

export enum CreateBulkDevicesSteps {
  zero = "zero",
  first = "first",
  second = "second",
  three = "three",
}

export class Device extends BaseModel {
  id: string;
  assignable: boolean;
  current_firmware_revision: string;
  item_number_revision: string;
  mainboard_revision: string;
  device_type_id: string;
  department_id: string | null;
  mcu_id: string;
  system_serial_number: string;
  device_tag: string;
  assigned_worker: BasicWorker | null;
  organization: OrganizationNested | null;
  site: SiteNested | null;
  department: DepartmentNested | null;
  status: DeviceStatus;
  config: DeviceConfig | null;
  total_time_used_seconds: number;
  total_lifts: number;
  first_session_start_at: Date | null;
  most_recent_gateway_event_at: Date | null;
  most_recent_session_start_at: Date | null;
  archived_at: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  sessions_with_errors: number;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      assignable: observable,
      current_firmware_revision: observable,
      item_number_revision: observable,
      mainboard_revision: observable,
      device_type_id: observable,
      department_id: observable,
      mcu_id: observable,
      system_serial_number: observable,
      device_tag: observable,
      assigned_worker: observable,
      status: observable,
      total_time_used_seconds: observable,
      total_lifts: observable,
      first_session_start_at: observable,
      most_recent_gateway_event_at: observable,
      most_recent_session_start_at: observable,
      archived_at: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
      sessions_with_errors: observable,
      formatted_config_firmware_version: computed,
    });
  }

  get formatted_config_firmware_version() {
    return this.config?.firmware_version ? `${this.config.firmware_version.version} (${this.config.firmware_version.short_hash})` : "";
  }

  static fixObjectFromJSON(object: Device, json: any) {
    if (json.archived_at) object.archived_at = new Date(json.archived_at);
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.first_session_start_at) object.first_session_start_at = new Date(json.first_session_start_at);
    if (json.most_recent_gateway_event_at) object.most_recent_gateway_event_at = new Date(json.most_recent_gateway_event_at);
    if (json.most_recent_session_start_at) object.most_recent_session_start_at = new Date(json.most_recent_session_start_at);

    if (json.assigned_worker) object.assigned_worker = BasicWorker.fromJSON(json.assigned_worker);
    if (json.organization) object.organization = OrganizationNested.fromJSON(json.organization);
    if (json.site) object.site = SiteNested.fromJSON(json.site);
    if (json.department) object.department = DepartmentNested.fromJSON(json.department);
  }
}
