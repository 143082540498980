import {useMutation, useQuery} from "@tanstack/react-query";
import {Space, Typography, Select, Modal} from "antd";
import _ from "lodash";
import React, {FunctionComponent, useCallback, useMemo, useState} from "react";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {SessionDataStore} from "../../../core/stores/SessionDataStore";
import {WorkerDataStore} from "../../../core/stores/WorkerDataStore";
import {WebHelper} from "../../utils/WebHelper";
import styles from "./EditSessionAssignment.module.scss";

export type EditSessionAssignmentProps = {
  device: Models.Device;
  session: Models.Session;
  onClose: () => void;
  onUpdate: () => void;
  open: boolean;
};

export const EditSessionAssignment: FunctionComponent<EditSessionAssignmentProps> = (props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const workerStore = WorkerDataStore.getInstance();
  const sessionStore = SessionDataStore.getInstance();

  const [availableWorkers, setAvailableWorkers] = useState<Models.WorkerShort[]>([]);
  const [selectedWorkerID, setSelectedWorkerID] = useState<string>();

  const {isFetching: workersQueryIsFetching, refetch: workersQueryRefetch} = useQuery({
    queryKey: ["EditSessionAssignment-fetchWorkersData"],
    queryFn: () =>
      workerStore.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: props.device.department_id!,
      }),
    enabled: false,
  });

  const fetchAvailableWorkers = useCallback(async () => {
    if (!props.device.department_id) return;

    const workerResponse = await workersQueryRefetch();
    if (workerResponse.data?.success) {
      const availableWorkersResponse = workerResponse.data.workers.filter((w) => w.id !== props.session.worker_id);
      setAvailableWorkers(availableWorkersResponse);
      if (_.isNil(availableWorkersResponse.find((worker) => worker.id === selectedWorkerID))) setSelectedWorkerID(undefined);
    }
  }, [props.device.department_id, props.session.worker_id, workersQueryRefetch, selectedWorkerID]);

  const updateSessionMutation = useMutation({
    mutationFn: () =>
      sessionStore.updateSessionAssignment({
        accessToken: authenticationStore.state.accessToken!,
        session_id: props.session.id,
        worker_id: selectedWorkerID && selectedWorkerID.length > 0 ? selectedWorkerID : null,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("EditSessionAssignment-updateError"), response.correlationId);
        return;
      }
      props.onUpdate();
      props.onClose();
    },
  });

  const handleSave = async () => {
    updateSessionMutation.mutate();
  };

  const availableWorkersOptions = useMemo(() => {
    const defaultValue = props.session.worker_id
      ? [{key: "none", label: WebHelper.formatMessage("EditSessionAssignment-none"), value: ""}]
      : [];
    return defaultValue.concat(
      availableWorkers.map(({id, displayName}) => ({
        key: id,
        label: displayName,
        value: id,
      }))
    );
  }, [availableWorkers, props.session.worker_id]);

  return (
    <Modal
      open={props.open}
      title={WebHelper.formatMessage("EditSessionAssignment-title")}
      destroyOnClose
      onCancel={props.onClose}
      onOk={handleSave}
      cancelButtonProps={{shape: "round", disabled: updateSessionMutation.isPending}}
      okText={WebHelper.formatMessage("Common-save")}
      okButtonProps={{shape: "round", loading: updateSessionMutation.isPending, disabled: _.isNil(selectedWorkerID)}}>
      <Typography.Text className={styles.subtitle}>{WebHelper.formatMessage("EditSessionAssignment-subtitle")}</Typography.Text>

      <div className={styles.formWrapper}>
        <Space className={styles.labelSpace}>
          <Typography.Text>{WebHelper.formatMessage("EditSessionAssignment-systemSerialNumber")}</Typography.Text>
          <Typography.Text data-cy="systemSerialNumberText">{props.device.system_serial_number}</Typography.Text>
        </Space>
        <Space className={styles.labelSpace}>
          <Typography.Text>{WebHelper.formatMessage("EditSessionAssignment-sessionID")}</Typography.Text>
          <Typography.Text>{props.session.id}</Typography.Text>
        </Space>
        <Space className={styles.labelSpace}>
          <Typography.Text>{WebHelper.formatMessage("EditSessionAssignment-startTime")}</Typography.Text>
          <Typography.Text>
            {props.session.created_at ? WebHelper.formatDate(props.session.created_at, props.device.site?.tz_location) : null}
          </Typography.Text>
        </Space>
        <Space className={styles.labelSpace}>
          <Typography.Text>{WebHelper.formatMessage("EditSessionAssignment-previousWorker")}</Typography.Text>
          <Typography.Text>
            {props.session.assigned_worker
              ? props.session.assigned_worker.displayName
              : WebHelper.formatMessage("EditSessionAssignment-none")}
          </Typography.Text>
        </Space>
        <Space className={styles.labelSpace}>
          <Typography.Text>{WebHelper.formatMessage("EditSessionAssignment-newWorker")}</Typography.Text>
          <Select
            value={selectedWorkerID}
            disabled={updateSessionMutation.isPending}
            className={styles.workerSelector}
            optionFilterProp="label"
            optionLabelProp="label"
            onSelect={setSelectedWorkerID}
            showSearch
            dropdownRender={(node) => (workersQueryIsFetching ? WebHelper.LoadingSpin : node)}
            onDropdownVisibleChange={(open) => {
              if (open) fetchAvailableWorkers();
            }}
            options={availableWorkersOptions}
            data-cy="workerSelector"
          />
        </Space>
      </div>
    </Modal>
  );
};
