import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {OrganizationNested} from "./OrganizationNested";
import {SiteNested} from "./SiteNested";

export type TargetUtilization = {
  target_utilization: number[];
};

export enum TargetType {
  None = "none",
  Constant = "constant",
  Week = "week",
}

export class Department extends BaseModel {
  id: string;
  name: string;
  target_device_utilization: TargetUtilization;
  target_type: TargetType;
  site: SiteNested;
  organization: OrganizationNested;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  device_count: number;
  archived_device_count: number;
  gateway_count: number;
  worker_count: number;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
      target_device_utilization: observable,
      target_type: observable,
      site: observable,
      organization: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
      device_count: observable,
      archived_device_count: observable,
      gateway_count: observable,
      worker_count: observable,
    });
  }

  static fixObjectFromJSON(object: Department, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.site) object.site = SiteNested.fromJSON(json.site);
    if (json.organization) object.organization = OrganizationNested.fromJSON(json.organization);
    if (object.target_device_utilization === null || object.target_device_utilization.target_utilization === null) {
      object.target_type = TargetType.None;
    } else {
      const utilizationLength = object.target_device_utilization.target_utilization.length;
      if (utilizationLength === 1) {
        object.target_type = TargetType.Constant;
      } else if (utilizationLength === 5) {
        object.target_type = TargetType.Week;
      }
    }
  }
}
