import {Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {Collapse} from "../../../common/collapse/Collapse";
import styles from "./DashboardWorkersMetric.module.scss";
import {Leaderboard, WorkerData} from "./leaderboard/Leaderboard";
import LeaderboardDownloadDropdown from "./leaderboard/leaderboard-download-dropdown/LeaderboardDownloadDropdown";

export type DashboardWorkersMetricProps = {
  data: Models.AnalyticsDashboardWorker[] | null;
  onWorkerUpdate: () => void;
  department: Models.Department;
};

export const DashboardWorkersMetric: FunctionComponent<DashboardWorkersMetricProps> = observer(({data, onWorkerUpdate, department}) => {
  const appStore = AppStore.getInstance();

  const [workersByWeight, setWorkersByWeight] = useState<WorkerData[]>([]);
  const [workersByScore, setWorkersByScore] = useState<WorkerData[]>([]);

  const [scoreAverage, setScoreAverage] = useState(0);
  const [weightAverage, setWeightAverage] = useState(0);

  useEffect(() => {
    if (!data || data.length === 0) return;
    const dataWithScores = data.map((worker_data) => {
      const totalWeightOffloaded = worker_data.time_series_data.reduce((sum, data) => sum + data.weight_offloaded_lbs, 0);
      const score = calculateSafeLiftScore(worker_data.time_series_data);

      return {...worker_data, score, weightOffloaded: Math.round(totalWeightOffloaded)};
    });

    const dataOrderedByWeight = dataWithScores
      .sort((a, b) => a.worker.displayName.localeCompare(b.worker.displayName))
      .sort((a, b) => b.weightOffloaded - a.weightOffloaded);
    const dataOrderedByScore = dataWithScores
      .filter(
        (
          data
        ): data is {
          // https://stackoverflow.com/a/51577579/15510462
          score: number;
          weightOffloaded: number;
          worker: Models.WorkerShort;
          time_series_data: Models.AnalyticsTimeSeries[];
        } => data.score !== null
      )
      .sort((a, b) => a.worker.displayName.localeCompare(b.worker.displayName))
      .sort((a, b) => b.score - a.score);

    const weightWithPosition = dataOrderedByWeight
      .filter((data) => data.weightOffloaded > 0)
      .map((data, index) => {
        return {...data, position: index + 1};
      });

    const scoreWithPosition = dataOrderedByScore.map((data, index) => {
      return {...data, position: index + 1};
    });

    const scoreAverage = scoreWithPosition.length > 0 ? scoreWithPosition.reduce((a, b) => a + b.score, 0) / scoreWithPosition.length : 0;
    const weightAverage =
      weightWithPosition.length > 0 ? weightWithPosition.reduce((a, b) => a + b.weightOffloaded, 0) / weightWithPosition.length : 0;

    setWorkersByScore(scoreWithPosition);
    setWorkersByWeight(weightWithPosition);
    setScoreAverage(Math.round(scoreAverage));
    setWeightAverage(Math.round(weightAverage));
  }, [data]);

  return (
    <Collapse
      onChange={(keys) => {
        if (keys.includes("header_panel")) {
          appStore.sendAnalyticTrack(SegmentKey.DashboardWorkerMetricsView, {
            departmentID: department.id,
            siteID: department.site.id,
            orgID: department.organization.id,
          });
        }
      }}
      header={
        <Space className={styles.collapseHeader}>
          <Typography.Title level={5} className={styles.panelHeaderText}>
            {WebHelper.formatMessage("DashboardWorkersMetric-workerMetrics")}
          </Typography.Title>
          <LeaderboardDownloadDropdown
            workersByScore={workersByScore}
            workersByWeight={workersByWeight}
            scoreAverage={scoreAverage}
            weightAverage={weightAverage}
            onWorkerEdit={onWorkerUpdate}
            department={department}
          />
        </Space>
      }>
      <Leaderboard
        workersByScore={workersByScore}
        workersByWeight={workersByWeight}
        scoreAverage={scoreAverage}
        weightAverage={weightAverage}
        onWorkerEdit={onWorkerUpdate}
        department={department}
      />
    </Collapse>
  );
});

export const calculateSafeLiftScore = (timeSeriesData: Models.AnalyticsTimeSeries[]) => {
  let totalSafeLifts = 0;
  let totalLifts = 0;

  timeSeriesData.forEach((data) => {
    totalSafeLifts += data.safe_lifts;
    totalLifts += data.total_lifts;
  });

  return totalLifts > 0 ? Math.round((totalSafeLifts / totalLifts) * 100) : null;
};
