import {ArrowRightOutlined} from "@ant-design/icons";
import {Button, Space, Typography} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";

import {DepartmentResponse} from "../../../core/apiclient/department/DepartmentAPIClient.types";
import {GatewaysResponse} from "../../../core/apiclient/gateway/GatewayAPIClient.types";
import {OrganizationResponse} from "../../../core/apiclient/organization/OrganizationAPIClient.types";
import {SiteResponse} from "../../../core/apiclient/site/SiteAPIClient.types";
import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {calculateSitesDepartmentsAndDevices, WebHelper} from "../../utils/WebHelper";
import {LinkButton} from "../common/link-button/LinkButton";
import {DepartmentModal} from "../department-modal/DepartmentModal";
import {EntityDetailsSiderCard} from "../entity-details-sider-card/EntityDetailsSiderCard";
import {Loading} from "../loading/Loading";
import {SiteModal} from "../site-modal/SiteModal";
import styles from "./EntityDetailsSider.module.scss";

type EntityDetailsSiderProps = {
  onDepartmentCreated: () => void;
  onSiteCreated: () => void;
  organizationQuery: {
    data?: OrganizationResponse;
    isFetching: boolean;
  };
  siteQuery: {
    data?: SiteResponse;
    isFetching: boolean;
  };
  departmentQuery: {
    data?: DepartmentResponse;
    isFetching: boolean;
  };
  gatewaysQuery: {
    data?: GatewaysResponse;
  };
  setIsGatewaysDetailDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const EntityDetailsSider: FunctionComponent<EntityDetailsSiderProps> = ({
  organizationQuery,
  siteQuery,
  departmentQuery,
  gatewaysQuery,
  setIsGatewaysDetailDrawerVisible,
  onDepartmentCreated,
  onSiteCreated,
}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const navigate = useNavigate();

  const [organization, setOrganization] = useState<Models.Organization | undefined>(undefined);
  const [site, setSite] = useState<Models.Site | undefined>(undefined);
  const [isCreateSiteOpen, setIsCreateSiteOpen] = useState(false);
  const [isCreateDepartmentOpen, setIsCreateDepartmentOpen] = useState(false);
  const [allSitesHaveOneDepartment, setAllSitesHaveOneDepartment] = useState(false);
  const [oneSite, setOneSite] = useState(false);

  useEffect(() => {
    if (!organizationQuery.data?.organization) return;

    const {resultOrg, resultSite} = calculateSitesDepartmentsAndDevices(organizationQuery.data.organization, siteQuery.data?.site);
    setOrganization(resultOrg);
    setSite(resultSite);
    const allSitesHaveDepartments =
      !!(resultOrg && resultOrg.sites && resultOrg.sites.length) &&
      resultOrg.sites.every((site) => site.departments && site.departments.length === 1);
    setAllSitesHaveOneDepartment(allSitesHaveDepartments);
    setOneSite(!allSitesHaveDepartments && resultOrg?.numberOfSites === 1);
  }, [organizationQuery.data?.organization, siteQuery.data?.site]);

  return (
    <>
      <Space className={styles.content} size={24} direction={"vertical"}>
        {organizationQuery.isFetching && <Loading />}
        {!organizationQuery.isFetching &&
          organization &&
          !(organization.numberOfSites === 1 && organization.numberOfDepartments === 1) &&
          (organization.numberOfSites === 1 ? (
            <EntityDetailsSiderCard
              entity={organization}
              oneSite={true}
              siteName={organization.sites ? organization.sites[0].name : ""}
              path={`/organizations/${organization?.id}/sites/${organization.sites![0].id}`}
              active={!!site}
            />
          ) : (
            <EntityDetailsSiderCard
              entity={organization}
              path={allSitesHaveOneDepartment ? undefined : `/organizations/${organization?.id}`}
              active={!site}
            />
          ))}

        {(organizationQuery.isFetching || siteQuery.isFetching) && <Loading />}
        {!organizationQuery.isFetching && !siteQuery.isFetching && (
          <>
            {!oneSite && (
              <div>
                {!allSitesHaveOneDepartment && (
                  <Typography.Title level={5} className={styles.title}>
                    {WebHelper.formatMessage("EntityDetailsSider-sitesTitle")}
                  </Typography.Title>
                )}
                {organization?.sites
                  ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                  .map((item) => {
                    if (site && item.id === site.id) {
                      if (!allSitesHaveOneDepartment) {
                        return (
                          <EntityDetailsSiderCard
                            key={item.id}
                            entity={site}
                            path={`/organizations/${organization?.id}/sites/${site?.id}`}
                            active={!departmentQuery.data?.department}
                          />
                        );
                      } else {
                        if (departmentQuery.data?.department && item.departments) {
                          return (
                            <EntityDetailsSiderCard
                              key={item.id}
                              entity={site}
                              departmentInSite={departmentQuery.data.department}
                              oneSite={organization.numberOfSites === 1}
                              oneSiteOneDepartment={organization.numberOfSites === 1 && organization.numberOfDepartments === 1}
                              path={
                                organization.numberOfSites === 1
                                  ? organization.numberOfDepartments === 1
                                    ? `/organizations/${organization?.id}/sites/${site?.id}/departments/${site.departments![0].id}`
                                    : `/organizations/${organization?.id}/sites/${site?.id}`
                                  : undefined
                              }
                              active={!departmentQuery.data?.department}
                              gateways={gatewaysQuery.data?.gateways}
                              setIsGatewaysDetailDrawerVisible={setIsGatewaysDetailDrawerVisible}
                            />
                          );
                        }
                      }
                    }
                    return (
                      <Button
                        key={item.id}
                        className={styles.listItem}
                        onClick={() =>
                          navigate(
                            item.departments && allSitesHaveOneDepartment
                              ? `/organizations/${organization?.id}/sites/${item?.id}/departments/${item?.departments[0].id}`
                              : `/organizations/${organization?.id}/sites/${item?.id}`
                          )
                        }>
                        <Typography.Text className={styles.entityName}>
                          {item.name} {allSitesHaveOneDepartment && item.departments && ` - ${item.departments[0].name}`}
                        </Typography.Text>
                        <ArrowRightOutlined className={styles.arrowIcon} />
                      </Button>
                    );
                  })}
              </div>
            )}
            {authenticationStore.permissionLevel(Models.UserScope.Organization, organization?.id) === Models.UserAccessLevel.Admin && (
              <LinkButton darkBackground onClick={() => setIsCreateSiteOpen(true)}>
                {WebHelper.formatMessage("EntityDetailsSider-createSiteButton")}
              </LinkButton>
            )}
          </>
        )}
        {!allSitesHaveOneDepartment && (
          <div>
            {(site || siteQuery.isFetching) && (organization?.numberOfDepartments !== 1 || organization?.numberOfSites !== 1) && (
              <Typography.Title level={5} className={styles.title}>
                {WebHelper.formatMessage("EntityDetailsSider-departmentsTitle")}
              </Typography.Title>
            )}
            {(siteQuery.isFetching || departmentQuery.isFetching) && <Loading />}
            {!siteQuery.isFetching && !departmentQuery.isFetching && (
              <>
                {organization &&
                  site?.departments
                    ?.sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((item) => {
                      if (departmentQuery.data?.department && item.id === departmentQuery.data.department.id) {
                        return (
                          <EntityDetailsSiderCard
                            key={item.id}
                            entity={departmentQuery.data.department}
                            active
                            gateways={gatewaysQuery.data?.gateways}
                            setIsGatewaysDetailDrawerVisible={setIsGatewaysDetailDrawerVisible}
                          />
                        );
                      }
                      return (
                        <Button
                          key={item.id}
                          className={styles.listItem}
                          onClick={() => navigate(`/organizations/${organization.id}/sites/${site.id}/departments/${item.id}`)}>
                          <Typography.Text className={styles.entityName}>{item.name}</Typography.Text>
                          <ArrowRightOutlined className={styles.arrowIcon} />
                        </Button>
                      );
                    })}
              </>
            )}
          </div>
        )}

        {site &&
          !siteQuery.isFetching &&
          !departmentQuery.isFetching &&
          authenticationStore.permissionLevel(Models.UserScope.Site, site.id) === Models.UserAccessLevel.Admin && (
            <LinkButton darkBackground onClick={() => setIsCreateDepartmentOpen(true)}>
              {WebHelper.formatMessage("EntityDetailsSider-createDepartmentButton")}
            </LinkButton>
          )}
      </Space>
      {organization && (
        <SiteModal
          data={{mode: "create", orgID: organization.id}}
          onClose={() => setIsCreateSiteOpen(false)}
          onSave={async () => {
            onSiteCreated();
            setIsCreateSiteOpen(false);
          }}
          open={isCreateSiteOpen}
        />
      )}
      {organization && site && (
        <DepartmentModal
          data={{mode: "create", siteId: site.id}}
          onClose={() => setIsCreateDepartmentOpen(false)}
          onSave={async () => {
            onDepartmentCreated();
            setIsCreateDepartmentOpen(false);
          }}
          open={isCreateDepartmentOpen}
        />
      )}
    </>
  );
};
