import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import * as Types from "./FirmwareVersionsAPIClient.types";

export class FirmwareVersionsAPIClient extends BaseAPIClient {
  static async firmwareVersions(
    request: Types.FirmwareVersionsRequest,
    options: RequestOptions = {}
  ): Promise<Types.FirmwareVersionsResponse> {
    const {token} = request.accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `firmware_versions/`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          firmwareVersions: data.map((firmwareVersion: Models.FirmwareVersion) => Models.FirmwareVersion.fromJSON(firmwareVersion)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async firmwareVersion(
    request: Types.FirmwareVersionRequest,
    options: RequestOptions = {}
  ): Promise<Types.FirmwareVersionResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `firmware_versions/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          firmwareVersion: Models.FirmwareVersion.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }
}
