import {Spin, Typography} from "antd";
import {
  addDays,
  addMonths,
  addQuarters,
  addWeeks,
  differenceInDays,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  format,
  intervalToDuration,
  isToday,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";
import html2canvas from "html2canvas";
import {RuleObject} from "rc-field-form/lib/interface";
import * as React from "react";
import {useCallback, useState} from "react";
import {Location} from "react-router-dom";

import * as Models from "../../core/models";
import {DataStore} from "../../core/stores/DataStore";
import {CoreHelper} from "../../core/utils/CoreHelper";
import {LocaleValidationRule} from "../AppConfig";
import {LocaleKey, LocaleParams} from "../locales/Locale";
import {AppStore} from "../stores/AppStore";
import styles from "./WebHelper.module.scss";

export type ElementSize = "xs" | "sm" | "md" | "lg" | "xl";

export type ImageSource = string | {avif?: string; jpg?: string; png?: string; webp?: string} | undefined;

export abstract class WebHelper {
  static Router: any = require("react-router-dom").BrowserRouter;

  static get LoadingSpin() {
    return (
      <div className={styles.spinContainer}>
        <Spin size="small" />
      </div>
    );
  }

  static formatMessage<TLocaleKey extends LocaleKey>(
    messageId: TLocaleKey,
    variables: LocaleParams[TLocaleKey] | undefined = undefined,
    defaultMessage: string | undefined = undefined,
    parseLineBreaks = false
  ): string {
    // eslint-disable-next-line
    // @ts-ignore
    const {currentLocale} = DataStore.getInstance();

    return CoreHelper.formatMessage(messageId as any, variables, defaultMessage, parseLineBreaks);
  }

  static formatInputValidationRules(rules: LocaleValidationRule[]): RuleObject[] {
    return rules.map((rule) => ({
      ...rule,
      message: this.formatMessage(rule.message.localeKey, rule.message.localeVariables),
    }));
  }

  static parseMarkdown(text: string): string {
    const rules = [
      {regex: /(\*\*|__)(.*?)\1/g, replacement: `<strong>$2</strong>`}, // bold
      {regex: /(\*|__)(.*?)\1/g, replacement: `<i>$2</i>`}, // italic
      {regex: /(\+\+|__)(.*?)\1/g, replacement: `<u>$2</u>`}, // underline
    ];

    let result = `<span>${text}</span>`;

    rules.forEach((rule) => {
      result = result.replace(rule.regex, rule.replacement);
    });

    return result;
  }

  static parseLocationSearch(search: string): any {
    const result: any = {};

    if (search.length === 0) return result;

    const args = search.substring(1).split("&");
    let i, arg, kvp, key, value;

    for (i = 0; i < args.length; i++) {
      arg = args[i];

      if (-1 === arg.indexOf("=")) {
        result[decodeURIComponent(arg).trim()] = true;
      } else {
        kvp = arg.split("=");

        key = decodeURIComponent(kvp[0]).trim();
        value = decodeURIComponent(kvp[1]).trim();

        result[key] = value;
      }
    }

    return result;
  }

  static openLinkInNewTab(url: string) {
    const link: any = document.createElement("a");
    link.href = url;
    link.target = "_blank";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
  }

  static loadScriptAsync(src: string, callback?: (script: any) => void) {
    const script: any = document.createElement("script");
    let loaded = false;

    script.setAttribute("src", src);
    if (callback) {
      script.onreadystatechange = script.onload = () => {
        if (!loaded) {
          callback(script);
        }
        loaded = true;
      };
    }
    document.getElementsByTagName("head")[0].appendChild(script);
  }

  static scrollElementTo(element: any, to: number, duration: number) {
    const start = element.scrollTop,
      change = to - start,
      increment = 20;
    let currentTime = 0;

    const easeInOutQuad = (t: number, b: number, c: number, d: number) => {
      t /= d / 2;
      if (t < 1) return (c / 2) * t * t + b;
      t--;
      return (-c / 2) * (t * (t - 2) - 1) + b;
    };

    const animateScroll = () => {
      currentTime += increment;

      const value = easeInOutQuad(currentTime, start, change, duration);
      element.scrollTop = value;

      if (currentTime < duration) setTimeout(animateScroll, increment);
    };

    animateScroll();
  }

  static downloadUrl(url: string) {
    const element = document.createElement("a");
    element.href = url;
    element.download = url;
    element.target = "_blank";
    document.body.appendChild(element);

    element.click();

    setTimeout(() => {
      document.body.removeChild(element);
    }, 0);
  }

  static get drawerWidth(): string | number {
    const appStore = AppStore.getInstance();

    return appStore.state.mode == "mobileSm" || appStore.state.mode == "mobileXs" ? "100%" : 378;
  }

  static getOrdinal(number: number) {
    let ord = "th";

    if (number % 10 == 1 && number % 100 != 11) {
      ord = "st";
    } else if (number % 10 == 2 && number % 100 != 12) {
      ord = "nd";
    } else if (number % 10 == 3 && number % 100 != 13) {
      ord = "rd";
    }

    return ord;
  }

  static getUnenrollWorkerMainReasonLabel(reason: Models.UnenrollWorkerMainReason): string {
    switch (reason) {
      case Models.UnenrollWorkerMainReason.Unknown:
        return this.formatMessage("UnenrollWorkerMainReason-unknown");
      case Models.UnenrollWorkerMainReason.Terminated:
        return this.formatMessage("UnenrollWorkerMainReason-terminated");
      case Models.UnenrollWorkerMainReason.Other:
        return this.formatMessage("UnenrollWorkerMainReason-other");
      default:
        return "";
    }
  }

  static getUnenrollWorkerOtherReasonLabel(reason: Models.UnenrollWorkerOtherReason): string {
    switch (reason) {
      case Models.UnenrollWorkerOtherReason.Back:
        return this.formatMessage("UnenrollWorkerOtherReason-back");
      case Models.UnenrollWorkerOtherReason.Heat:
        return this.formatMessage("UnenrollWorkerOtherReason-heat");
      case Models.UnenrollWorkerOtherReason.Constriction:
        return this.formatMessage("UnenrollWorkerOtherReason-constriction");
      case Models.UnenrollWorkerOtherReason.General:
        return this.formatMessage("UnenrollWorkerOtherReason-general");
      case Models.UnenrollWorkerOtherReason.NoNeed:
        return this.formatMessage("UnenrollWorkerOtherReason-no_need");
      case Models.UnenrollWorkerOtherReason.TimeHabit:
        return this.formatMessage("UnenrollWorkerOtherReason-time_habit");
      case Models.UnenrollWorkerOtherReason.LimitsOverheadReaching:
        return this.formatMessage("UnenrollWorkerOtherReason-limits_overhead_reaching");
      case Models.UnenrollWorkerOtherReason.ThighWrapsDropping:
        return this.formatMessage("UnenrollWorkerOtherReason-thigh_wraps_dropping");
      case Models.UnenrollWorkerOtherReason.ExtraHeightWidth:
        return this.formatMessage("UnenrollWorkerOtherReason-extra_height_width");
      case Models.UnenrollWorkerOtherReason.AssistanceTimeWrong:
        return this.formatMessage("UnenrollWorkerOtherReason-assistance_time_wrong");
      case Models.UnenrollWorkerOtherReason.JobFunctionChange:
        return this.formatMessage("UnenrollWorkerOtherReason-job_function_change");
      case Models.UnenrollWorkerOtherReason.PerformanceConcern:
        return this.formatMessage("UnenrollWorkerOtherReason-performance_concern");
      case Models.UnenrollWorkerOtherReason.OnLeave:
        return this.formatMessage("UnenrollWorkerOtherReason-on_leave");
      case Models.UnenrollWorkerOtherReason.PreExistingCondition:
        return this.formatMessage("UnenrollWorkerOtherReason-preexisting_condition");
      case Models.UnenrollWorkerOtherReason.BadUseCase:
        return this.formatMessage("UnenrollWorkerOtherReason-use_case");
      case Models.UnenrollWorkerOtherReason.HeavyShoulderPressure:
        return this.formatMessage("UnenrollWorkerOtherReason-heavy_shoulder_pressure");
      case Models.UnenrollWorkerOtherReason.ShoulderPointPressure:
        return this.formatMessage("UnenrollWorkerOtherReason-shoulder_point_pressure");
      default:
        return "";
    }
  }

  static getAccessLevelText = (value: Models.UserAccessLevel) => {
    switch (value) {
      case Models.UserAccessLevel.ReadOnly:
        return WebHelper.formatMessage("UserPermissions-readOnly");
      case Models.UserAccessLevel.Admin:
        return WebHelper.formatMessage("UserPermissions-admin");
      case Models.UserAccessLevel.Manager:
        return WebHelper.formatMessage("UserPermissions-manager");
      case Models.UserAccessLevel.Custom:
        return WebHelper.formatMessage("UserPermissions-custom");
      default:
        return WebHelper.formatMessage("UserPermissions-none");
    }
  };

  static getControllerSettingsName(name: Models.ControllerSettings): string {
    switch (name) {
      case Models.ControllerSettings.HeavyLiftHigh:
        return this.formatMessage("ControllerSettings-heavyLiftHigh");
      case Models.ControllerSettings.HeavyLiftLow:
        return this.formatMessage("ControllerSettings-heavyLiftLow");
      case Models.ControllerSettings.HeavyLiftMedium:
        return this.formatMessage("ControllerSettings-heavyLiftMedium");
      case Models.ControllerSettings.SquatLiftHigh:
        return this.formatMessage("ControllerSettings-squatLiftHigh");
      case Models.ControllerSettings.SquatLiftMedium:
        return this.formatMessage("ControllerSettings-squatLiftMedium");
      case Models.ControllerSettings.SquatLiftTransparent:
        return this.formatMessage("ControllerSettings-squatLiftTransparent");
      case Models.ControllerSettings.UniversalLiftHigh:
        return this.formatMessage("ControllerSettings-universalLiftHigh");
      case Models.ControllerSettings.UniversalLiftMedium:
        return this.formatMessage("ControllerSettings-universalLiftMedium");
      case Models.ControllerSettings.UniversalLiftTransparent:
        return this.formatMessage("ControllerSettings-universalLiftTransparent");
      case Models.ControllerSettings.UniversalLowerHigh:
        return this.formatMessage("ControllerSettings-universalLowerHigh");
      case Models.ControllerSettings.UniversalLowerMedium:
        return this.formatMessage("ControllerSettings-universalLowerMedium");
      case Models.ControllerSettings.UniversalLowerTransparent:
        return this.formatMessage("ControllerSettings-universalLowerTransparent");
      case Models.ControllerSettings.FloorAssembly70:
        return this.formatMessage("ControllerSettings-floorAssembly70");
      case Models.ControllerSettings.FloorAssembly90:
        return this.formatMessage("ControllerSettings-floorAssembly90");
      case Models.ControllerSettings.FloorAssembly110:
        return this.formatMessage("ControllerSettings-floorAssembly110");
      case Models.ControllerSettings.FloorAssembly130:
        return this.formatMessage("ControllerSettings-floorAssembly130");
      case Models.ControllerSettings.FloorAssembly150:
        return this.formatMessage("ControllerSettings-floorAssembly150");
      case Models.ControllerSettings.FloorAssembly170:
        return this.formatMessage("ControllerSettings-floorAssembly170");
      case Models.ControllerSettings.FloorAssembly190:
        return this.formatMessage("ControllerSettings-floorAssembly190");
      case Models.ControllerSettings.FloorAssemblyTransparent:
        return this.formatMessage("ControllerSettings-floorAssemblyTransparent");
      default:
        return name;
    }
  }

  static getLanguageText = (value: Models.WorkerPreferredLanguage) => {
    switch (value) {
      case Models.WorkerPreferredLanguage.en_US:
        return WebHelper.formatMessage("WorkerPreferredLanguage-enUS");
      case Models.WorkerPreferredLanguage.pt_BR:
        return WebHelper.formatMessage("WorkerPreferredLanguage-ptBR");
      case Models.WorkerPreferredLanguage.fr_CA:
        return WebHelper.formatMessage("WorkerPreferredLanguage-frCA");
      case Models.WorkerPreferredLanguage.es_MX:
        return WebHelper.formatMessage("WorkerPreferredLanguage-esMX");
      case Models.WorkerPreferredLanguage.ht_HT:
        return WebHelper.formatMessage("WorkerPreferredLanguage-htHT");
      default:
        return WebHelper.formatMessage("WorkerPreferredLanguage-none");
    }
  };

  static getFormatStringByDate = (date: Date) => {
    if (isToday(date)) return WebHelper.formatMessage("Common-dateFormatTimeOnly");
    else if (Math.abs(differenceInDays(date, addDays(new Date(), -30))) < 30)
      return WebHelper.formatMessage("Common-dateTimeFormatWithoutYear");
    else return WebHelper.formatMessage("Common-dateFormatMonthDayOnly");
  };

  static getDurationFormattedBySeconds = (duration: number) => {
    const {days, hours, minutes, seconds} = intervalToDuration({start: 0, end: duration * 1000});
    return (days && days > 0 ? `${days}${WebHelper.formatMessage("Common-dayAbbreviation")}` : "")
      .concat(hours && hours > 0 ? `${hours}${WebHelper.formatMessage("Common-hourAbbreviation")}` : "")
      .concat(minutes && minutes > 0 ? `${minutes}${WebHelper.formatMessage("Common-minuteAbbreviation")}` : "")
      .concat(seconds && seconds > 0 ? `${seconds}${WebHelper.formatMessage("Common-secondAbbreviation")}` : "");
  };

  static secondsToHours = (seconds: number) => {
    return Math.round(seconds / 3600);
  };

  static downloadFile = (content: string, fileName: string, fileType: string) => {
    const a = document.createElement("a");
    const file = new Blob([content], {type: fileType});
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  };

  static formatDate = (date: Date, timezone?: string) => {
    return timezone
      ? formatInTimeZone(date, timezone, WebHelper.formatMessage("Common-dateFormatWithTimezone"))
      : format(date, WebHelper.formatMessage("Common-dateFormatWithTimezone"));
  };

  static formatDateNoTime = (date: Date, timezone?: string) => {
    return timezone
      ? formatInTimeZone(date, timezone, WebHelper.formatMessage("Common-dateFormatWithTimezoneNoTime"))
      : format(date, WebHelper.formatMessage("Common-dateFormatWithTimezoneNoTime"));
  };

  static formatTimeOnly = (date: Date, timezone?: string) => {
    return timezone
      ? formatInTimeZone(date, timezone, WebHelper.formatMessage("Common-dateFormatTimeOnly"))
      : format(date, WebHelper.formatMessage("Common-dateFormatTimeOnly"));
  };

  /**
   * Retrieves the client's timezone as a string in IANA format
   */
  static getLocalTimezoneFromBrowser = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  static homePagePath = (): string => {
    return "/organizations";
  };

  static showErrorMessage = (message: string, errorCode?: string) => {
    const appStore = AppStore.getInstance();

    appStore.showMessage(
      <>
        {message}
        {errorCode && (
          <Typography.Paragraph copyable={{text: errorCode}}>{WebHelper.formatMessage("Common-copyErrorCode")}</Typography.Paragraph>
        )}
      </>,
      "error"
    );
  };

  static orderOrganizationsByFavorites = (organizations: Models.Organization[], favoritesOrgsIds: string[]) => {
    const favoritesOrganizations: Models.Organization[] = [];
    const noFavoritesOrganizations: Models.Organization[] = [];

    const sortedOrganizations = organizations.sort((a, b) => a.name.localeCompare(b.name));

    sortedOrganizations.forEach((org) => {
      if (favoritesOrgsIds.includes(org.id)) favoritesOrganizations.push(org);
      else noFavoritesOrganizations.push(org);
    }, []);

    return [...favoritesOrganizations, ...noFavoritesOrganizations];
  };

  static getBreadcrumbItemUrl = (location: Location, param: string): string => {
    return `${location.pathname.substring(0, location.pathname.indexOf(param) + param.length)}`;
  };

  static updateFavoriteOrganizations = (
    orgId: string,
    currentFavoritesOrgsIds: string[],
    setUserPreferences: (updatedFavorites: {organizationIds: string[]}) => void
  ) => {
    const isFavoriteOrg = orgId ? currentFavoritesOrgsIds.includes(orgId) : false;

    let newOrgFavoriteIds: string[];

    if (!isFavoriteOrg) {
      newOrgFavoriteIds = [...currentFavoritesOrgsIds, orgId];
    } else {
      newOrgFavoriteIds = currentFavoritesOrgsIds.filter((organizationId: string) => organizationId !== orgId);
    }

    setUserPreferences({organizationIds: newOrgFavoriteIds});
  };
}

export function useForceUpdate() {
  const [, setTick] = useState(0);
  const update = useCallback(() => {
    setTick((tick) => tick + 1);
  }, []);
  return update;
}

export function calculateSitesDepartmentsAndDevices(
  org: Models.Organization,
  site?: Models.Site
): {resultOrg?: Models.Organization; resultSite?: Models.Site} {
  org.numberOfSites = org.sites?.length ?? 0;

  let departments = 0;
  let devices = 0;

  org.sites?.forEach((orgSite) => {
    if (site?.id === orgSite.id) {
      site.numberOfDepartments = orgSite.departments?.length ?? 0;
    }
    departments += orgSite.departments?.length ?? 0;

    let devicesPerSite = 0;
    orgSite.departments?.forEach((department) => {
      devicesPerSite += department.device_count - department.archived_device_count;
    });

    if (site?.id === orgSite.id) {
      site.numberOfDevices = devicesPerSite;
    }
    devices += devicesPerSite;
  });

  org.numberOfDepartments = departments;
  org.numberOfDevices = devices;

  return {resultOrg: org, resultSite: site};
}

export const lbsToKgs = (lbs: number) => {
  const weight = lbs * 0.45359237;
  const decimalPlaces = lbs.toString().includes(".") ? lbs.toString().split(".")[1].length : 0;

  return parseFloat(weight.toFixed(decimalPlaces));
};

export const calculateLastCompletedPeriod = (intervalSelected: Models.AnalyticsInterval) => {
  switch (intervalSelected) {
    case Models.AnalyticsInterval.Day:
      return endOfDay(addDays(new Date(), -1));
      break;
    case Models.AnalyticsInterval.Week:
      return endOfWeek(addWeeks(new Date(), -1));
      break;
    case Models.AnalyticsInterval.Month:
      return endOfMonth(addMonths(new Date(), -1));
      break;
    case Models.AnalyticsInterval.Quarter:
      return endOfQuarter(addQuarters(new Date(), -1));
      break;
  }
};

export const calculateBegginingOfPeriod = (intervalSelected: Models.AnalyticsInterval, date: Date) => {
  switch (intervalSelected) {
    case Models.AnalyticsInterval.Day:
      return startOfDay(date);
      break;
    case Models.AnalyticsInterval.Week:
      return startOfWeek(date);
      break;
    case Models.AnalyticsInterval.Month:
      return startOfMonth(date);
      break;
    case Models.AnalyticsInterval.Quarter:
      return startOfQuarter(date);
      break;
  }
};

export const convertDivToBase64 = async (element: HTMLElement, sheetSize: number[], grayscale = false): Promise<[string, number]> => {
  const canvas = await html2canvas(element, {scale: 2});
  const aspectRatio = canvas.width / canvas.height;
  const scaledHeight = sheetSize[0] / aspectRatio;

  if (grayscale) {
    const context = canvas.getContext("2d");
    if (context) {
      const imageData = context!.getImageData(0, 0, canvas.width, canvas.height);
      const data = imageData.data;

      for (let i = 0; i < data.length; i += 4) {
        const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
        data[i] = avg; // R
        data[i + 1] = avg; // G
        data[i + 2] = avg; // B
      }

      context!.putImageData(imageData, 0, 0);
    }
  }

  return [canvas.toDataURL("image/jpeg", 2), scaledHeight];
};
