import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import * as Types from "./AnalyticsAPIClient.types";

export class AnalyticsAPIClient extends BaseAPIClient {
  static async analyticsHistory(
    request: Types.AnalyticsHistoryRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsHistoryResponse> {
    const {accessToken, start_time, end_time, interval, resource_type, resource_id} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/history`,
      headers: {token},
      request,
      options,
      data: {
        start_time,
        end_time,
        interval,
        resource_type,
        resource_id,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: data.map((analyticHistory: Models.AnalyticsHistory) => Models.AnalyticsHistory.fromJSON(analyticHistory)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsDashboard(
    request: Types.AnalyticsDashboardRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDashboardResponse> {
    const {accessToken, resource_type, resource_id} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/dashboard`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDashboard.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsDashboardCard(
    request: Types.AnalyticsDashboardCardRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDashboardCardResponse> {
    const {accessToken, resource_type, resource_id, time_series_config, status_interval_bounds} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/dashboard_card`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
        time_series_config,
        status_interval_bounds,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDashboardCard.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsTimeSeriesData(
    request: Types.AnalyticsTimeSeriesDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsTimeSeriesDataResponse> {
    const {
      accessToken,
      organization_id,
      site_id,
      department_id,
      group_id,
      worker_id,
      end_time,
      interval_type,
      interval_count,
      offset_minutes,
    } = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/time_series_data`,
      headers: {token},
      request,
      options,
      data: {
        organization_id,
        site_id,
        department_id,
        group_id,
        worker_id,
        end_time,
        interval_type,
        interval_count,
        offset_minutes,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsTimeSeriesData.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsMultiTimeSeriesData(
    request: Types.AnalyticsMultiTimeSeriesDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsMultiTimeSeriesDataResponse> {
    const {accessToken, resource_type, end_time, interval_type, interval_count} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/multi_time_series_data`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        end_time,
        interval_type,
        interval_count,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse.data;
      if (data) {
        return {
          success: true,
          data: data,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async analyticsDashboardWorkerData(
    request: Types.AnalyticsDashboardWorkerDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDashboardWorkerDataResponse> {
    const {accessToken, end_time, interval_type, interval_count, resource_type, resource_id} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/dashboard_worker_data_v2`,
      headers: {token},
      request,
      options,
      data: {
        resource_type,
        resource_id,
        time_series_config: {
          offset_minutes: -new Date().getTimezoneOffset(),
          end_time,
          interval_type,
          interval_count,
        },
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDashboardWorkerData.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async WorkerSafeliftReport(
    request: Types.WorkerSafeliftReportRequest,
    options: RequestOptions = {}
  ): Promise<Types.WorkerSafeliftReportResponse> {
    const {accessToken, resource_id, resource_type, include_all_enrolled_workers, report_bounds} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/worker_report`,
      headers: {token},
      request,
      options,
      data: {
        resource_id,
        resource_type,
        include_all_enrolled_workers,
        report_bounds: {
          end_time: new Date(report_bounds.end_time),
          offset_minutes: report_bounds.offset_minutes,
        },
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          workerReportData: await data.worker_report_data.map((reportData: Models.WorkerSafeliftReport) =>
            Models.WorkerSafeliftReport.fromJSON(reportData)
          ),
        };
      }
    }
    return {
      success: false,
      error: this.genericError(),
    };
  }

  static async analyticsDevicesData(
    request: Types.AnalyticsDevicesDataRequest,
    options: RequestOptions = {}
  ): Promise<Types.AnalyticsDevicesDataResponse> {
    const {accessToken, end_time, interval_type, interval_count, resource_id} = request;

    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `analytics/device_data`,
      headers: {token},
      request,
      options,
      data: {
        resource_id,
        time_series_config: {
          offset_minutes: -new Date().getTimezoneOffset(),
          end_time,
          interval_type,
          interval_count,
        },
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          data: Models.AnalyticsDevicesData.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }
}
