import {SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Col, Divider, Drawer, Input, Row, Skeleton} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import * as _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {OrganizationDataStore} from "../../../core/stores/OrganizationDataStore";
import {Button, ButtonType} from "../../components/common/button/Button";
import {PageTitle} from "../../components/common/page-title/PageTitle";
import {Subheader} from "../../components/common/subheader/Subheader";
import {DownloadAllData} from "../../components/download-all-data/DownloadAllData";
import {Loading} from "../../components/loading/Loading";
import {OrganizationCard} from "../../components/organization-card/OrganizationCard";
import {OrganizationModal} from "../../components/organization-modal/OrganizationModal";
import useUserPreferences from "../../hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import styles from "./Organizations.module.scss";

enum OrganizationTags {
  Favorites = "favorites",
  All = "all",
}

type OrganizationsProps = {};

export const Organizations: FunctionComponent<OrganizationsProps> = observer(({}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const organizationStore = OrganizationDataStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();
  const [selectedTag, setSelectedTag] = useState<OrganizationTags | null>(null);
  const [organizationsOnDisplay, setOrganizationsOnDisplay] = useState<Models.OrganizationShort[]>([]);
  const [organizationsNotOnDisplay, setOrganizationsNotOnDisplay] = useState<Models.OrganizationShort[]>([]);
  const [isCreateOpen, setIsCreateOpen] = useState(false);
  const [isDownloadDataOpen, setIsDownloadDataOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");

  const organizationsQuery = useQuery({
    queryKey: ["Organizations-fetchData"],
    queryFn: () => {
      appStore.hideAllMessages();

      return organizationStore.organizationsShort({
        accessToken: authenticationStore.state.accessToken!,
      });
    },
  });

  const favorites = useMemo(
    () =>
      organizationsQuery.data?.organizations
        ?.filter((organization) => userPreferences.data.favorites?.organizationIds.includes(organization.id))
        .map((organization) => organization.id) ?? [],
    [userPreferences.data.favorites?.organizationIds, organizationsQuery.data]
  );

  useEffect(() => {
    if (!selectedTag && organizationsQuery.data?.organizations) {
      if (favorites.length > 0) {
        setSelectedTag(OrganizationTags.Favorites);
        appStore.sendAnalyticTrack(SegmentKey.AllOrganizationsDashboardOpened, {
          filter: OrganizationTags.Favorites,
        });
      } else {
        setSelectedTag(OrganizationTags.All);
        appStore.sendAnalyticTrack(SegmentKey.AllOrganizationsDashboardOpened, {
          filter: OrganizationTags.All,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites, organizationsQuery.data?.organizations, selectedTag]);

  useEffect(() => {
    if (!selectedTag || !organizationsQuery.data) return;

    if (!organizationsQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("FetchOrganizationsPageData-errorMessage"), organizationsQuery.data.correlationId);
      return;
    }

    let organizations = _.cloneDeep(organizationsQuery.data.organizations);

    if (selectedTag === OrganizationTags.Favorites) {
      organizations = organizations.filter((organization) => favorites.includes(organization.id));
      setSearchValue("");
    }

    let sortedOrganizations = organizations.sort((a, b) => a.name.localeCompare(b.name));

    sortedOrganizations = sortedOrganizations.filter((org) => org.name.toLowerCase().includes(searchValue.toLowerCase()));

    setOrganizationsOnDisplay(sortedOrganizations.splice(0, 6));
    setOrganizationsNotOnDisplay(sortedOrganizations);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationsQuery.data, selectedTag, searchValue]);

  useEffect(() => {
    if (selectedTag === OrganizationTags.Favorites)
      setOrganizationsOnDisplay(organizationsOnDisplay.filter((organization) => favorites.includes(organization.id)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites]);

  const loadMoreData = () => {
    const organizationsRemaining = _.cloneDeep(organizationsNotOnDisplay);
    const organizationsToAdd = organizationsRemaining.splice(0, 6);

    setOrganizationsOnDisplay([...organizationsOnDisplay, ...organizationsToAdd]);
    setOrganizationsNotOnDisplay(organizationsRemaining);
  };

  const handleFavoriteChange = (orgId: string, shouldShowRemoveFromFavoritesModal?: boolean) => {
    WebHelper.updateFavoriteOrganizations(
      orgId,
      userPreferences.data.favorites?.organizationIds ?? [],
      (updatedFavorites: {organizationIds: string[]}) => {
        setUserPreferences({
          favorites: updatedFavorites,
          shouldShowRemoveFromFavoritesModal: shouldShowRemoveFromFavoritesModal ?? userPreferences.data.shouldShowRemoveFromFavoritesModal,
        });

        const favoriteOrgs =
          organizationsQuery.data?.organizations?.filter((organization) => updatedFavorites.organizationIds.includes(organization.id)) ??
          [];

        if (selectedTag === OrganizationTags.Favorites && favoriteOrgs.length === 0) {
          setSelectedTag(OrganizationTags.All);
        }
      }
    );
  };

  return (
    <>
      <Subheader
        breadcrumbItems={[
          {id: "allOrganizations", name: WebHelper.formatMessage("Organizations-allOrganizationsPath"), url: "/organizations"},
        ]}
      />
      <div className={styles.container}>
        <PageTitle
          title={WebHelper.formatMessage("Organizations-allOrganizationsTitle")}
          marginBottom={16}
          actions={[
            authenticationStore.isUserGlobal &&
              authenticationStore.permissionLevel(Models.UserScope.Global) === Models.UserAccessLevel.Admin && (
                <>
                  <Button
                    shape="round"
                    type={ButtonType.Primary}
                    onClick={() => {
                      setIsDownloadDataOpen(true);
                      appStore.sendAnalyticTrack(SegmentKey.DownloadAllOrganizationsDataDrawerOpened);
                    }}>
                    {WebHelper.formatMessage("Organizations-downloadData")}
                  </Button>
                  <Button shape="round" type={ButtonType.Primary} onClick={() => setIsCreateOpen(true)}>
                    {WebHelper.formatMessage("Organizations-createOrganizationButtonText")}
                  </Button>
                </>
              ),
          ]}
        />
        <div className={styles.content}>
          {organizationsQuery.isPending && <Loading />}
          {!organizationsQuery.isPending && (
            <>
              <div className={styles.filtersWrapper}>
                <div className={styles.filters}>
                  <CheckableTag
                    key={"favorites"}
                    className={favorites.length > 0 ? "" : styles.disabledTag}
                    checked={selectedTag === OrganizationTags.Favorites}
                    onChange={() => {
                      if (favorites.length > 0) {
                        setSelectedTag(OrganizationTags.Favorites);
                        appStore.sendAnalyticTrack(SegmentKey.AllOrganizationsFilterChanged, {
                          filter: OrganizationTags.Favorites,
                        });
                      }
                    }}>
                    {WebHelper.formatMessage("Organizations-favoriteTagText")}
                  </CheckableTag>
                  <CheckableTag
                    key={"all"}
                    checked={selectedTag === OrganizationTags.All}
                    onChange={() => {
                      setSelectedTag(OrganizationTags.All);
                      appStore.sendAnalyticTrack(SegmentKey.AllOrganizationsFilterChanged, {
                        filter: OrganizationTags.All,
                      });
                    }}>
                    {WebHelper.formatMessage("Organizations-allTagText")}
                  </CheckableTag>
                </div>
                {selectedTag === OrganizationTags.All && (
                  <Input
                    className={styles.search}
                    size="small"
                    placeholder={WebHelper.formatMessage("Organizations-searchButtonText")}
                    prefix={<SearchOutlined />}
                    onChange={(event) => setSearchValue(event.target.value)}
                  />
                )}
              </div>

              <div id="scrollableDiv" className={styles.organizationCardsWrapper}>
                <InfiniteScroll
                  dataLength={organizationsOnDisplay.length}
                  next={loadMoreData}
                  hasMore={organizationsNotOnDisplay.length > 0}
                  loader={<Skeleton paragraph={{rows: 3}} active />}
                  endMessage={<Divider plain>{WebHelper.formatMessage("Organizations-organizationsListEndMessage")}</Divider>}
                  scrollableTarget="scrollableDiv">
                  <Row className={styles.row} gutter={[16, 16]}>
                    {organizationsOnDisplay.map((organization) => (
                      <Col key={organization.id} xs={24} sm={24} md={12}>
                        <OrganizationCard
                          organization={organization}
                          userPreferences={userPreferences}
                          onFavoriteChange={handleFavoriteChange}
                          searchValue={searchValue}
                        />
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              </div>
            </>
          )}
        </div>
      </div>
      <Drawer
        className={styles.drawer}
        open={isDownloadDataOpen}
        width={WebHelper.drawerWidth}
        destroyOnClose
        onClose={() => setIsDownloadDataOpen(false)}
        title={WebHelper.formatMessage("Organizations-downloadData")}>
        <DownloadAllData />
      </Drawer>

      <OrganizationModal
        data={{mode: "create"}}
        onClose={() => setIsCreateOpen(false)}
        onSave={() => {
          setIsCreateOpen(false);
        }}
        open={isCreateOpen}
      />
    </>
  );
});
