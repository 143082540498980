import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {BasicWorker} from "./BasicWorker";
import {ControllerSettings, DeviceStatus} from "./Device";

export class DeviceShort extends BaseModel {
  id: string;
  device_type_id: string;
  department_id: string | null;
  mcu_id: string;
  system_serial_number: string;
  device_tag: string;
  assignable: boolean;
  checked_out_status: string;
  site_tz_location: string;
  worker_id: string;
  worker_first_name: string;
  worker_last_name: string;
  worker_employee_id: string;
  worker_assignable: boolean;
  device_config_pending: boolean;
  last_session_start_time: Date | null;
  most_recent_gateway_event_id: string;
  most_recent_gateway_event_type: string;
  most_recent_gateway_event_at: Date | null;
  status: DeviceStatus;
  firmware_version: string;
  item_number_revision: string;
  button1_name: ControllerSettings;
  button2_name: ControllerSettings;
  button3_name: ControllerSettings;
  archived_at: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  assigned_worker: BasicWorker | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      assignable: observable,
      site_tz_location: observable,
      worker_id: observable,
      worker_first_name: observable,
      worker_last_name: observable,
      worker_employee_id: observable,
      worker_assignable: observable,
      device_config_pending: observable,
      item_number_revision: observable,
      device_type_id: observable,
      department_id: observable,
      mcu_id: observable,
      system_serial_number: observable,
      device_tag: observable,
      status: observable,
      last_session_start_time: observable,
      most_recent_gateway_event_at: observable,
      most_recent_gateway_event_id: observable,
      most_recent_gateway_event_type: observable,
      firmware_version: observable,
      button1_name: observable,
      button2_name: observable,
      button3_name: observable,
      archived_at: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: DeviceShort, json: any) {
    if (json.archived_at) object.archived_at = new Date(json.archived_at);
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.most_recent_gateway_event_at) object.most_recent_gateway_event_at = new Date(json.most_recent_gateway_event_at);
    if (json.last_session_start_time) object.last_session_start_time = new Date(json.last_session_start_time);
    if (object.worker_id)
      object.assigned_worker = {
        id: object.worker_id,
        first_name: object.worker_first_name,
        last_name: object.worker_last_name,
        employee_id: object.worker_employee_id,
        get fullName(): string {
          return `${object.worker_first_name} ${object.worker_last_name}`;
        },
        get displayName(): string {
          return object.worker_first_name || object.worker_last_name ? this.fullName : object.worker_employee_id;
        },
      };
  }
}
