import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useState} from "react";
import {matchPath, useLocation} from "react-router-dom";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {Subheader, SubheaderBreadcrumbItem} from "../common/subheader/Subheader";

type EntityDetailsSubheaderProps = {
  oneOrgOneSite?: boolean;
  oneSiteOneDepartment?: boolean;
};

export const EntityDetailsSubheader: FunctionComponent<EntityDetailsSubheaderProps> = observer(({oneOrgOneSite, oneSiteOneDepartment}) => {
  const {isUserGlobal} = AuthenticationDataStore.getInstance();
  const location = useLocation();

  const generateBreadcrumbItems = useCallback((): SubheaderBreadcrumbItem[] => {
    const result: SubheaderBreadcrumbItem[] = [];

    const matchOrganizationDetails = matchPath("/organizations/:orgId", location.pathname);
    const matchSiteDetails =
      matchPath("/organizations/:orgId/sites/:siteId", location.pathname) ||
      matchPath("/organizations/:orgId/sites/:siteId/groups/:groupId", location.pathname);
    const matchDepartmentDetails = matchPath("/organizations/:orgId/sites/:siteId/departments/:departmentId", location.pathname);

    if (isUserGlobal) {
      result.push({id: "allOrganizations", name: WebHelper.formatMessage("Organizations-allOrganizationsPath"), url: "/organizations"});
    }

    if (!_.isNil(matchOrganizationDetails)) {
      const {orgId} = matchOrganizationDetails.params;
      result.push({
        id: orgId!,
        type: Models.EntityType.Organization,
        url: WebHelper.getBreadcrumbItemUrl(location, orgId!),
      });
    } else if (!_.isNil(matchSiteDetails)) {
      const {orgId, siteId} = matchSiteDetails.params;
      if (oneOrgOneSite) {
        result.push({
          id: siteId!,
          type: Models.EntityType.Site,
          url: WebHelper.getBreadcrumbItemUrl(location, siteId!),
          secondId: orgId!,
          secondType: Models.EntityType.Organization,
        });
      } else {
        result.push({
          id: orgId!,
          type: Models.EntityType.Organization,
          url: WebHelper.getBreadcrumbItemUrl(location, orgId!),
        });
        result.push({
          id: siteId!,
          type: Models.EntityType.Site,
          url: WebHelper.getBreadcrumbItemUrl(location, siteId!),
        });
      }
    } else if (!_.isNil(matchDepartmentDetails)) {
      const {orgId, siteId, departmentId} = matchDepartmentDetails.params;
      if (oneOrgOneSite && oneSiteOneDepartment) {
        result.push({
          id: siteId!,
          type: Models.EntityType.Site,
          url: WebHelper.getBreadcrumbItemUrl(location, departmentId!),
        });
      } else if (oneOrgOneSite) {
        result.push({
          id: siteId!,
          type: Models.EntityType.Site,
          url: WebHelper.getBreadcrumbItemUrl(location, siteId!),
          secondId: orgId!,
          secondType: Models.EntityType.Organization,
        });
        result.push({
          id: departmentId!,
          type: Models.EntityType.Department,
          url: WebHelper.getBreadcrumbItemUrl(location, departmentId!),
        });
      } else if (oneSiteOneDepartment) {
        result.push({
          id: orgId!,
          type: Models.EntityType.Organization,
          url: WebHelper.getBreadcrumbItemUrl(location, orgId!),
        });
        result.push({
          id: departmentId!,
          type: Models.EntityType.Department,
          url: WebHelper.getBreadcrumbItemUrl(location, departmentId!),
          secondId: siteId!,
          secondType: Models.EntityType.Site,
        });
      } else {
        result.push({
          id: orgId!,
          type: Models.EntityType.Organization,
          url: WebHelper.getBreadcrumbItemUrl(location, orgId!),
        });
        result.push({id: siteId!, type: Models.EntityType.Site, url: WebHelper.getBreadcrumbItemUrl(location, siteId!)});
        result.push({
          id: departmentId!,
          type: Models.EntityType.Department,
          url: WebHelper.getBreadcrumbItemUrl(location, departmentId!),
        });
      }
    }
    return result;
  }, [isUserGlobal, location]);

  const [breadcrumbItems, setBreadcrumbItems] = useState<SubheaderBreadcrumbItem[]>(generateBreadcrumbItems());

  useEffect(() => {
    setBreadcrumbItems(generateBreadcrumbItems());
  }, [generateBreadcrumbItems]);

  return <Subheader breadcrumbItems={breadcrumbItems} />;
});
