import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";

export class WorkerReportGroupNested extends BaseModel {
  id: string;
  name: string;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
    });
  }
}
