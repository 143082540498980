import {useMutation} from "@tanstack/react-query";
import {Form, Modal, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";
import {useNavigate} from "react-router-dom";

import * as Models from "../../../core/models";
import {UserScope} from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {OrganizationDataStore} from "../../../core/stores/OrganizationDataStore";
import {SiteDataStore} from "../../../core/stores/SiteDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {LinkButton} from "../common/link-button/LinkButton";
import {ConfirmationModal, ConfirmationModalSizes} from "../confirmation-modal/ConfirmationModal";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";
import {OrganizationSiteForm, OrganizationSiteFormFields} from "./organization-site-edit-form/OrganizationSiteForm";
import styles from "./OrganizationSiteModal.module.scss";

type OrganizationSiteEditModalProps = {
  data: {organization: Models.Organization; site: Models.Site};
  onClose: () => void;
  onSave: () => void;
  onDelete?: () => void;
  open: boolean;
};

export const OrganizationSiteEditModal: FunctionComponent<OrganizationSiteEditModalProps> = observer(
  ({data, onClose, onSave, onDelete, open}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const organizationStore = OrganizationDataStore.getInstance();
    const siteStore = SiteDataStore.getInstance();
    const navigate = useNavigate();

    const [form] = Form.useForm<OrganizationSiteFormFields>();

    const [isFormDisabled, setIsFormDisabled] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);

    const {mutate: updateOrganizationMutate, isPending: updateOrganizationIsLoading} = useMutation({
      mutationFn: (mutationParams: {formValues: OrganizationSiteFormFields; organization: Models.Organization}) => {
        const {orgName, orgShortName, orgAdress, orgIconUrl} = mutationParams.formValues;

        return organizationStore.updateOrganization({
          accessToken: authenticationStore.state.accessToken!,
          id: mutationParams.organization.id,
          name: orgName,
          short_name: orgShortName,
          address: orgAdress,
          icon_url: orgIconUrl,
        });
      },
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("OrganizationSiteModal-updateOrganizationError"), response.correlationId);
          return;
        }

        onSave();
      },
    });

    const {mutate: updateSiteMutate, isPending: updateSiteIsLoading} = useMutation({
      mutationFn: (mutationParams: {formValues: OrganizationSiteFormFields; site: Models.Site}) => {
        const {siteName, siteAdress, siteTz_location} = mutationParams.formValues;
        const {id, organization_id} = mutationParams.site;

        return siteStore.updateSite({
          accessToken: authenticationStore.state.accessToken!,
          id,
          name: siteName,
          address: siteAdress,
          tz_location: siteTz_location,
          organization_id,
        });
      },
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("OrganizationSiteModal-updateSiteError"), response.correlationId);
          return;
        }

        onSave();
        form.resetFields();
      },
    });

    const handleCancel = () => {
      if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
      else {
        setShowDiscardModal(false);
        onClose();
        form.resetFields();
      }
    };

    const handleOnFieldsChange = () => {
      if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
        setIsFormDisabled(true);
      } else {
        setIsFormDisabled(false);
      }
    };

    const deleteSite = useMutation({
      mutationFn: (site: Models.Site) => {
        return siteStore.deleteSite({
          accessToken: authenticationStore.state.accessToken!,
          id: site.id,
        });
      },
      onSuccess: async (response) => {
        if (response.success) {
          if (onDelete) await onDelete();
          navigate(location.pathname.substring(0, location.pathname.indexOf("/sites")));
        } else WebHelper.showErrorMessage(WebHelper.formatMessage("OrganizationSiteModal-deleteError"), response.correlationId);
      },
    });

    const handleDeleteSite = () => {
      if (deleteSite.isPending || !data.site) return;
      deleteSite.mutate(data.site);
    };

    const mutationLoading = updateSiteIsLoading || updateOrganizationIsLoading;

    const handleOk = async () => {
      form
        .validateFields()
        .then((formValues) => {
          updateOrganizationMutate({organization: data.organization, formValues});
          updateSiteMutate({site: data.site, formValues});
        })
        // This catch prevents AntD from throwing an error to the console when form validations fail
        .catch(() => {});
    };

    const title = WebHelper.formatMessage("OrganizationSiteModal-editTitle");

    return (
      <>
        <Modal
          centered
          open={open}
          title={title}
          destroyOnClose={true}
          onOk={handleOk}
          okText={WebHelper.formatMessage("Common-save")}
          okButtonProps={{shape: "round", loading: mutationLoading, disabled: mutationLoading || isFormDisabled}}
          cancelButtonProps={{shape: "round", disabled: mutationLoading}}
          onCancel={handleCancel}
          footer={[
            <div
              className={styles.footer}
              style={
                !(
                  authenticationStore.isUserGlobal &&
                  authenticationStore.permissionLevel(UserScope.Organization) === Models.UserAccessLevel.Admin
                )
                  ? {justifyContent: "flex-end"}
                  : {}
              }
              key="footer">
              {authenticationStore.isUserGlobal &&
                authenticationStore.permissionLevel(UserScope.Organization) === Models.UserAccessLevel.Admin && (
                  <LinkButton key="delete" onClick={() => setShowDeleteModal(true)}>
                    {WebHelper.formatMessage("OrganizationSiteModal-deleteButtonText")}
                  </LinkButton>
                )}
              <div className={styles.buttonsWrapper}>
                <Button key="back" shape="round" disabled={mutationLoading} onClick={handleCancel}>
                  {WebHelper.formatMessage("Common-cancel")}
                </Button>
                <Button
                  key="submit"
                  type={ButtonType.Primary}
                  shape="round"
                  disabled={mutationLoading || isFormDisabled}
                  loading={mutationLoading}
                  onClick={handleOk}>
                  {WebHelper.formatMessage("Common-save")}
                </Button>
              </div>
            </div>,
          ]}>
          <OrganizationSiteForm
            form={form}
            loading={mutationLoading}
            onFieldsChange={handleOnFieldsChange}
            initialValues={{organization: data.organization, site: data.site}}
          />
        </Modal>
        <ConfirmationModal
          showConfirmationModal={showDeleteModal}
          loading={deleteSite.isPending}
          title={WebHelper.formatMessage("OrganizationSiteModal-deleteAlertTitle")}
          message={<Typography.Text>{WebHelper.formatMessage("OrganizationSiteModal-deleteAlertMessage")}</Typography.Text>}
          onOk={handleDeleteSite}
          onCancel={() => setShowDeleteModal(false)}
          okButtonText={WebHelper.formatMessage("Common-delete")}
          referenceConfirmationValue={WebHelper.formatMessage("Common-deleteConfirmationValue")}
          width={ConfirmationModalSizes.Small}
        />
        <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
      </>
    );
  }
);
