import generatePicker from "antd/es/date-picker/generatePicker";
import en_US from "antd/lib/locale/en_US";
import es_ES from "antd/lib/locale/es_ES";
import dateFnsGenerateConfig from "antd/node_modules/rc-picker/lib/generate/dateFns";
import "antd/es/date-picker/style/index";

if (en_US.DatePicker) {
  en_US.DatePicker.lang.fieldQuarterFormat = "Q";
  en_US.DatePicker.lang.cellQuarterFormat = "Q";
}

if (es_ES.DatePicker) {
  es_ES.DatePicker.lang.fieldQuarterFormat = "Q";
  es_ES.DatePicker.lang.cellQuarterFormat = "Q";
}

const DatePicker = generatePicker<Date>(dateFnsGenerateConfig);

export {DatePicker};
