import {FilterOutlined, ToolOutlined} from "@ant-design/icons";
import {Button, Tooltip, Drawer} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {useFlags} from "launchdarkly-react-client-sdk";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";
import {useNavigate} from "react-router-dom";

import devicePendingConfigIcon from "../../../../assets/images/common/Device_PendingConfig_Icon.svg";
import deviceUpdateConfigIcon from "../../../../assets/images/common/Device_UpdateConfig_Icon.svg";
import * as Models from "../../../../core/models";
import {DeviceManagementTabKeys as TabKeys} from "../../../../core/models/UserPreferences";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {WebHelper} from "../../../utils/WebHelper";
import {LinkButton} from "../../common/link-button/LinkButton";
import {Table} from "../../common/table/Table";
import {ConnectedColumnRender} from "../connected-column-render/ConnectedColumnRender";
import styles from "./DeviceManagementDevicesTable.module.scss";
import {OverridePendingUpdateConfirmationModal} from "./pending-update-detail/override-pending-update-button/OverridePendingUpdateConfirmationModal";
import {PendingUpdateDetail} from "./pending-update-detail/PendingUpdateDetail";
import {UpdateDeviceConfigurationModal} from "./update-device-configuration/UpdateDeviceConfigurationModal";

export type DeviceManagementDevicesTableProps = {
  devices: Models.DeviceShort[];
  onUpdate: () => void;
  loading: boolean;
  activeTabKey: string;
  setIsEditingFilter: (open: boolean) => void;
  tableSettings: Models.DeviceManagementDevicesTableSettings;
  updatePreferences: (updatedSettings: {
    updatedDeviceManagementDevicesTableSettings?: Partial<Models.DeviceManagementDevicesTableSettings>;
    updatedGatewayTableSettings?: Partial<Models.GatewayTableSettings>;
    updatedDeviceManagementActiveTabKey?: TabKeys;
  }) => void;
  includeArchived: boolean;
};

type ObtainStringFieldsKeys<Obj> = {
  [Prop in keyof Obj]: Obj[Prop] extends string ? Prop : never;
}[keyof Obj];

export const DeviceManagementDevicesTable: FunctionComponent<DeviceManagementDevicesTableProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const flags = useFlags();

  const navigate = useNavigate();

  const [selectedDevice, setSelectedDevice] = useState<Models.DeviceShort | null>(null);
  const [isPendingUpdateDrawerOpen, setIsPendingUpdateDrawerOpen] = useState(false);
  const [isUpdateDeviceConfigurationModalOpen, setIsUpdateDeviceConfigurationModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  const {pageSize, sortColumn, sortOrder, connectedFilteredValue, hardwareRevFilteredValue, firmwareRevFilteredValue, configFilteredValue} =
    props.tableSettings;

  const filterOptionsForStringField = (key: ObtainStringFieldsKeys<Models.DeviceShort>) => {
    return _.uniqWith(
      props.devices
        .filter((device) => !_.isNil(device[key]) && device[key].length > 0)
        .map((device) => {
          return {
            text: device[key],
            value: device[key],
          };
        }),
      _.isEqual
    ).sort((a, b) => a.text.localeCompare(b.text));
  };

  const connectedColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DevicesTable-connectedColumnTitle"),
    dataIndex: "status",
    align: "left",
    render: (status, record) => (
      <ConnectedColumnRender
        mostRecentGatewayEventAt={record.most_recent_gateway_event_at}
        siteTimezone={record.site_tz_location}
        status={status}
      />
    ),
    onFilterDropdownOpenChange(open) {
      props.setIsEditingFilter(open);
    },
    filters: [
      {value: Models.DeviceStatus.Connected, text: WebHelper.formatMessage("DevicesTable-connectedLabel")},
      {value: Models.DeviceStatus.Disconnected, text: WebHelper.formatMessage("DevicesTable-disconnectedLabel")},
      {value: Models.DeviceStatus.NeverSeen, text: WebHelper.formatMessage("DevicesTable-neverSeenLabel")},
    ],
    filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
    sortOrder: sortColumn === "status" ? sortOrder : undefined,
    sorter: (a: Models.DeviceShort, b: Models.DeviceShort) => {
      const connectionState = (device: Models.DeviceShort) =>
        device.status === Models.DeviceStatus.Connected ? 3 : device.status === Models.DeviceStatus.Disconnected ? 2 : 1;

      const statusComparison = connectionState(a) - connectionState(b);
      if (statusComparison !== 0) {
        return statusComparison;
      }

      if (a.status === Models.DeviceStatus.Disconnected && b.status === Models.DeviceStatus.Disconnected) {
        const aDate = a.most_recent_gateway_event_at ? new Date(a.most_recent_gateway_event_at) : new Date(0);
        const bDate = b.most_recent_gateway_event_at ? new Date(b.most_recent_gateway_event_at) : new Date(0);

        return bDate.getTime() - aDate.getTime();
      }

      return 0;
    },
    filteredValue: connectedFilteredValue,
    onFilter: (value, record) => record.status === value,
    filterSearch: true,
  };

  const systemSerialNumberColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DevicesTable-systemSerialNumberColumnTitle"),
    dataIndex: "system_serial_number",
    sorter: (a, b) => a.system_serial_number.localeCompare(b.system_serial_number),
    sortOrder: sortColumn === "system_serial_number" ? sortOrder : undefined,
    render: (system_serial_number, record) => (
      <LinkButton onClick={() => navigate(`/device_management/devices/${record.id}`)}>{system_serial_number}</LinkButton>
    ),
  };

  const hardwareRevColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DeviceManagementDevicesTable-hardwareRevColumnTitle"),
    dataIndex: "item_number_revision",
    filters: filterOptionsForStringField("item_number_revision"),
    filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
    filteredValue: hardwareRevFilteredValue,
    onFilterDropdownOpenChange(open) {
      props.setIsEditingFilter(open);
    },
    onFilter: (value, record) => record.item_number_revision === value,
    filterSearch: true,
  };

  const firmwareRevColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DeviceManagementDevicesTable-firmwareRevColumnTitle"),
    key: "firmware_version",
    render: (__, {firmware_version}) =>
      !_.isEmpty(firmware_version) ? firmware_version : WebHelper.formatMessage("Device-formattedConfigFirmwareVersionUnknown"),
    filters: filterOptionsForStringField("firmware_version"),
    filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
    filteredValue: firmwareRevFilteredValue,
    onFilterDropdownOpenChange(open) {
      props.setIsEditingFilter(open);
    },
    onFilter: (value, record) => record.firmware_version === value,
    filterSearch: true,
  };

  const updateConfigColumn: ColumnType<Models.DeviceShort> = {
    title: flags.device_config_modification
      ? WebHelper.formatMessage("DeviceManagementDevicesTable-updateConfigColumnTitle")
      : WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate"),
    dataIndex: "config",
    key: "config",
    render: (_, record) => (
      <Button
        className={styles.configIcon}
        type="text"
        onClick={() => {
          setSelectedDevice(record);
          if (record.device_config_pending) {
            setIsPendingUpdateDrawerOpen(true);
          } else {
            setIsUpdateDeviceConfigurationModalOpen(true);
          }
        }}
        icon={
          record.device_config_pending ? (
            <Tooltip title={WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate")}>
              <img src={devicePendingConfigIcon} alt={WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate")} />
            </Tooltip>
          ) : flags.device_config_modification ? (
            <Tooltip title={WebHelper.formatMessage("DeviceManagementDevicesTable-updateConfig")}>
              <img src={deviceUpdateConfigIcon} alt={WebHelper.formatMessage("DeviceManagementDevicesTable-updateConfig")} />
            </Tooltip>
          ) : null
        }
      />
    ),
    filters: [
      {value: false, text: WebHelper.formatMessage("DeviceManagementDevicesTable-activeConfig")},
      {value: true, text: WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate")},
    ],
    filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
    onFilterDropdownOpenChange(open) {
      props.setIsEditingFilter(open);
    },
    filteredValue: configFilteredValue,
    onFilter: (value, record) => (record.device_config_pending ?? false) === value,
  };

  const buttonOneColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DeviceManagementDevicesTable-button1ColumnTitle"),
    key: "button1",
    sortOrder: sortColumn === "button1" ? sortOrder : undefined,
    sorter: (a, b) => (a.button1_name ?? "").localeCompare(b.button1_name ?? ""),
    render: (_, record) =>
      record.button1_name ? WebHelper.getControllerSettingsName(record.button1_name) : WebHelper.formatMessage("Common-unassignedLabel"),
  };

  const buttonTwoColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DeviceManagementDevicesTable-button2ColumnTitle"),
    key: "button2",
    sortOrder: sortColumn === "button2" ? sortOrder : undefined,
    sorter: (a, b) => (a.button2_name ?? "").localeCompare(b.button2_name ?? ""),
    render: (_, record) =>
      record.button2_name ? WebHelper.getControllerSettingsName(record.button2_name) : WebHelper.formatMessage("Common-unassignedLabel"),
  };

  const buttonThreeColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DeviceManagementDevicesTable-button3ColumnTitle"),
    key: "button3",
    sortOrder: sortColumn === "button3" ? sortOrder : undefined,
    sorter: (a, b) => (a.button3_name ?? "").localeCompare(b.button3_name ?? ""),
    render: (_, record) =>
      record.button3_name ? WebHelper.getControllerSettingsName(record.button3_name) : WebHelper.formatMessage("Common-unassignedLabel"),
  };

  const archivedColumn: ColumnType<Models.DeviceShort> = {
    title: WebHelper.formatMessage("DeviceManagementDevicesTable-archivedColumnTitle"),
    key: "archived",
    sortOrder: sortColumn === "archived" ? sortOrder : undefined,
    sorter: (a, b) => (a.archived_at ? 1 : 0) - (b.archived_at ? 1 : 0),
    render: (_, record) => (record.archived_at ? <ToolOutlined /> : null),
  };

  const renderedColumns: () => ColumnsType<Models.DeviceShort> = () => {
    switch (props.activeTabKey) {
      case TabKeys.DeviceConfig:
        return [
          connectedColumn,
          systemSerialNumberColumn,
          hardwareRevColumn,
          firmwareRevColumn,
          buttonOneColumn,
          buttonTwoColumn,
          buttonThreeColumn,
          updateConfigColumn,
          ...((props.includeArchived ? [archivedColumn] : []) as ColumnType<Models.DeviceShort>[]),
        ];
      default:
        return [systemSerialNumberColumn];
    }
  };

  return (
    <>
      <Table
        onChange={(pagination, filters, sorter, extra) => {
          appStore.sendAnalyticTrack(SegmentKey.DiagnosticsDevicesTableSettingsChanged, {
            pagination,
            filters,
            sorter,
            action: extra.action,
          });
          if (extra.action === "filter") {
            // Below checks for undefined are because if the filter is undefined, the column is not currently rendered
            // and thus its value should not be updated
            props.updatePreferences({
              updatedDeviceManagementDevicesTableSettings: {
                ...(filters.status !== undefined ? {connectedFilteredValue: filters.status} : {}),
                ...(filters.system_serial_number !== undefined ? {systemSerialNumberFilteredValue: filters.system_serial_number} : {}),
                ...(filters.item_number_revision !== undefined ? {hardwareRevFilteredValue: filters.item_number_revision} : {}),
                ...(filters.firmware_version !== undefined ? {firmwareRevFilteredValue: filters.firmware_version} : {}),
                ...(filters.config !== undefined ? {configFilteredValue: filters.config} : {}),
              },
            });
          }
          if (extra.action === "sort" && !Array.isArray(sorter)) {
            props.updatePreferences({
              updatedDeviceManagementDevicesTableSettings: {
                sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
                sortOrder: sorter.order,
              },
            });
          }
        }}
        columns={renderedColumns()}
        loading={props.loading}
        dataSource={props.devices}
        pagination={{
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (_, size) => props.updatePreferences({updatedDeviceManagementDevicesTableSettings: {pageSize: size}}),
        }}
      />

      {selectedDevice && (
        <UpdateDeviceConfigurationModal
          deviceId={selectedDevice.id}
          open={isUpdateDeviceConfigurationModalOpen}
          onOk={() => {
            setSelectedDevice(null);
            setIsUpdateDeviceConfigurationModalOpen(false);
            props.onUpdate();
          }}
          onClose={() => {
            setSelectedDevice(null);
            setIsUpdateDeviceConfigurationModalOpen(false);
          }}
        />
      )}

      <Drawer
        open={!_.isNil(selectedDevice) && isPendingUpdateDrawerOpen}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("PendingUpdateDetail-drawerTitle")}
        destroyOnClose
        onClose={() => {
          setSelectedDevice(null);
          setIsPendingUpdateDrawerOpen(false);
        }}>
        {selectedDevice && <PendingUpdateDetail deviceId={selectedDevice.id} onOverride={() => setIsConfirmationModalOpen(true)} />}
      </Drawer>

      <OverridePendingUpdateConfirmationModal
        open={isConfirmationModalOpen}
        onOk={() => {
          setIsConfirmationModalOpen(false);
          setIsUpdateDeviceConfigurationModalOpen(true);
        }}
        onCancel={() => {
          setIsConfirmationModalOpen(false);
        }}
      />
    </>
  );
});
