import {RcFile} from "antd/lib/upload";
import React, {FunctionComponent} from "react";

import {WebHelper} from "../../../../../utils/WebHelper";
import {CSVDragger} from "../../../../csv-dragger/CSVDragger";

type CSVDeviceAllocationProps = {
  siteName: string;
  departmentName: string;
  fileName: string | null;
  onRemoveFile: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onUpload: (file: RcFile) => void;
};

export const CSVDeviceAllocation: FunctionComponent<CSVDeviceAllocationProps> = ({
  siteName,
  departmentName,
  fileName,
  onRemoveFile,
  onUpload,
}) => {
  return (
    <CSVDragger
      descriptionMessage={WebHelper.formatMessage("CSVDeviceAllocation-description", {siteName, departmentName})}
      columnsMessage={WebHelper.formatMessage("CSVDeviceAllocation-columns")}
      fileName={fileName}
      onRemoveFile={onRemoveFile}
      onUpload={onUpload}
    />
  );
};
