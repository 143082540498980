import {DeleteOutlined} from "@ant-design/icons";
import {Button, Tooltip} from "antd";
import {ColumnsType} from "antd/lib/table";
import workerEnrolledStatusIcon from "assets/images/common/Worker_Enrolled_Status_Icon.svg";
import workerUnenrolledStatusIcon from "assets/images/common/Worker_Unenrolled_Status_Icon.svg";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useEffect} from "react";
import Highlighter from "react-highlight-words";

import * as Models from "../../../../../../core/models";
import {WorkerReportGroupWorkersTableSettings} from "../../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import useUserPreferences from "../../../../../hooks/useUserPreferences";
import {TableSettings} from "../../../../../utils/TableUtils";
import {WebHelper} from "../../../../../utils/WebHelper";
import {LinkButton} from "../../../../common/link-button/LinkButton";
import {Table} from "../../../../common/table/Table";
import styles from "./WorkerReportGroupWorkersTable.module.scss";

const defaultSettings: WorkerReportGroupWorkersTableSettings = {
  pageSize: 10,
  sortColumn: "worker",
  sortOrder: "ascend",
};

type WorkerReportGroupWorkersTableProps = {
  orgID: string;
  siteID: string;
  workers: Models.WorkerInWorkerReportGroup[];
  loading: boolean;
  onRemove: (worker: Models.WorkerInWorkerReportGroup) => void;
  filterValue: string;
  setWorkerId: (workerId: string) => void;
  setTableSettings: (settings: TableSettings) => void;
  onTableChange: (action: string, newSettings?: {quickFilter?: Models.WorkersTagsKey; tableSettings?: TableSettings}) => void;
};

export const WorkerReportGroupWorkersTable: FunctionComponent<WorkerReportGroupWorkersTableProps> = observer(
  ({orgID, siteID, workers, loading, onRemove, filterValue, setWorkerId, setTableSettings, onTableChange}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();

    const [userPreferences, setUserPreferences] = useUserPreferences();

    const workerReportGroupWorkersTabTableSettings: Models.WorkerReportGroupWorkersTableSettings = useMemo(
      () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.workerReportGroupWorkers ?? {})}),
      [userPreferences]
    );

    const initialTableSettings = {
      pagination: {
        current: 1,
        position: ["bottomCenter"],
        pagination: workerReportGroupWorkersTabTableSettings.pageSize,
        showSizeChanger: true,
      },
      sorter: {columnKey: workerReportGroupWorkersTabTableSettings.sortColumn, order: workerReportGroupWorkersTabTableSettings.sortOrder},
      filters: {},
    };

    useEffect(() => {
      setTableSettings(initialTableSettings);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {pageSize, sortColumn, sortOrder} = workerReportGroupWorkersTabTableSettings;

    let columns: ColumnsType<Models.WorkerInWorkerReportGroup> = [
      {
        title: WebHelper.formatMessage("WorkerReportGroupWorkersTable-worker"),
        key: "worker",
        sorter: (a, b) => a.displayName.localeCompare(b.displayName),
        sortOrder: sortColumn === "worker" ? sortOrder : undefined,
        render: (_, record) => (
          <LinkButton onClick={() => setWorkerId(record.id)}>
            <Highlighter
              highlightClassName={styles.highlightText}
              searchWords={[filterValue ?? ""]}
              autoEscape
              textToHighlight={record.displayName}
            />
          </LinkButton>
        ),
      },
      {
        title: WebHelper.formatMessage("WorkerReportGroupWorkersTable-department"),
        dataIndex: "department_name",
        sorter: (a, b) => a.department_name.localeCompare(b.department_name),
        sortOrder: sortColumn === "department_name" ? sortOrder : undefined,
      },
      {
        title: WebHelper.formatMessage("WorkerReportGroupWorkersTable-status"),
        key: "status",
        width: "5%",
        dataIndex: "assignable",
        render: (_, record) =>
          record.assignable ? (
            <Tooltip title={WebHelper.formatMessage("Common-enrolledTooltip")}>
              <img src={workerEnrolledStatusIcon} alt={WebHelper.formatMessage("Common-enrolledTooltip")} />
            </Tooltip>
          ) : (
            <Tooltip title={WebHelper.formatMessage("Common-unenrolledTooltip")}>
              <img src={workerUnenrolledStatusIcon} alt={WebHelper.formatMessage("Common-unenrolledTooltip")} />
            </Tooltip>
          ),
      },
      {
        title: WebHelper.formatMessage("WorkerReportGroupWorkersTable-remove"),
        key: "remove",
        width: "5%",
        render: (_, record) => (
          <Button
            icon={<DeleteOutlined />}
            type="text"
            onClick={() => onRemove(record)}
            disabled={
              !(
                authenticationStore.permissionLevel(Models.UserScope.Site, siteID) === Models.UserAccessLevel.Admin ||
                authenticationStore.permissionLevel(Models.UserScope.Site, siteID) === Models.UserAccessLevel.Manager
              )
            }
          />
        ),
      },
    ];

    if (
      authenticationStore.permissionLevel(Models.UserScope.Site, siteID) !== Models.UserAccessLevel.Admin &&
      authenticationStore.permissionLevel(Models.UserScope.Site, siteID) !== Models.UserAccessLevel.Manager
    ) {
      columns = columns.filter((column) => column.key !== "remove");
    }

    const handleSettingsChange = (updatedSettings: Partial<Models.DepartmentWorkersTableSettings>) =>
      setUserPreferences({tableSettings: {workerReportGroupWorkers: {...workerReportGroupWorkersTabTableSettings, ...updatedSettings}}});

    return (
      <Table
        columns={columns}
        dataSource={workers}
        rowKey={(record) => record.id}
        loading={loading}
        onChange={(pagination, filters, sorter, extra) => {
          setTableSettings({pagination: pagination, sorter: sorter, filters: filters});
          onTableChange(extra.action, {tableSettings: {pagination: pagination, sorter: sorter, filters: filters}});
          if (extra.action === "sort" && !Array.isArray(sorter)) {
            handleSettingsChange({
              sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
              sortOrder: sorter.order,
            });
          }
        }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (_, size) =>
            handleSettingsChange({
              pageSize: size,
            }),
        }}
      />
    );
  }
);
