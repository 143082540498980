import {Empty, Typography} from "antd";
import {differenceInDays, differenceInWeeks} from "date-fns";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useEffect, useState} from "react";
import useUserPreferences from "web/hooks/useUserPreferences";
import {WebHelper, lbsToKgs} from "web/utils/WebHelper";

import targetIcon from "../../../../../assets/images/common/Target_Icon.svg";
import targetIconBlock from "../../../../../assets/images/common/Target_Icon_Block.svg";
import targetIconDown from "../../../../../assets/images/common/Target_Icon_Down.svg";
import targetIconUnset from "../../../../../assets/images/common/Target_Icon_Unset.svg";
import targetIconUp from "../../../../../assets/images/common/Target_Icon_Up.svg";
import * as Models from "../../../../../core/models";
import {SiteSummaryReportHeader} from "../site-summary-report-header/SiteSummaryReportHeader";
import styles from "./ActiveIncativeWorkersSitePage.module.scss";

const BATCH_SIZE = 15;

export type ActiveInactiveWorkersSitePageProps = {
  endDate: string;
  index: number;
  departmentIndex: number;
  setPages: (pages: number, index: number) => void;
  targetUtilization: boolean;
  data: Models.AnalyticsDashboardWorkerData | undefined;
  department: Models.DepartmentInSite;
  siteId: string;
  orgId: string;
  weigthOffloaded: boolean;
  safeliftScore: boolean;
};

const sortDataByName = (data: any[]) => {
  data.sort((a, b) =>
    a[WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-nameColumnTitle")] >
    b[WebHelper.formatMessage("ActiveInactiveWorkersReportDropdown-nameColumnTitle")]
      ? 1
      : -1
  );
};

const ActiveInactiveWorkersSitePage: FunctionComponent<ActiveInactiveWorkersSitePageProps> = ({
  endDate,
  setPages,
  index,
  departmentIndex,
  targetUtilization,
  data,
  department,
  weigthOffloaded,
  safeliftScore,
}) => {
  const [userPreferences] = useUserPreferences();
  const [activeWorkerBatches, setActiveWorkerBatches] = useState<{[key: string]: string | number}[][]>([]);
  const [inactiveWorkerBatches, setInactiveWorkerBatches] = useState<{[key: string]: string | number}[][]>([]);
  const [workersActive, setWorkersActive] = useState<Models.AnalyticsDashboardWorker[]>([]);
  const [workersInactive, setWorkersInctive] = useState<Models.AnalyticsDashboardWorker[]>([]);

  const flags = useFlags();

  useEffect(() => {
    const activeBatches = [];

    const inactiveBatches = [];

    if (!data || !data.worker_data) {
      setPages(2, departmentIndex);
      return;
    }

    const active: Models.AnalyticsDashboardWorker[] = data.worker_data?.filter(
      (analyticsDashboardWorker: Models.AnalyticsDashboardWorker) => analyticsDashboardWorker.active
    );
    if (active) {
      sortDataByName(active);
      for (let i = 0; i < active.length; i += BATCH_SIZE) {
        activeBatches.push(active.slice(i, i + BATCH_SIZE));
      }
    }

    const inactive: Models.AnalyticsDashboardWorker[] = data.worker_data.filter(
      (analyticsDashboardWorker) => analyticsDashboardWorker.inactive
    );
    if (inactive) {
      sortDataByName(inactive);
      for (let i = 0; i < inactive.length; i += BATCH_SIZE) {
        inactiveBatches.push(inactive.slice(i, i + BATCH_SIZE));
      }
    }
    setPages(
      (inactive.length > 0 ? Math.ceil(inactive.length / BATCH_SIZE) : 1) + (active.length > 0 ? Math.ceil(active.length / BATCH_SIZE) : 1),
      departmentIndex
    );

    setWorkersActive(active);
    setWorkersInctive(inactive);
  }, [data, setPages, departmentIndex]);

  const generateBatches = () => {
    const activeBatches = [];
    const inactiveBatches = [];
    const [activeWorkerData, inactiveWorkerData] = generateWorkerData();

    if (activeWorkerData) {
      sortDataByName(activeWorkerData);
      for (let i = 0; i < activeWorkerData.length; i += BATCH_SIZE) {
        activeBatches.push(activeWorkerData.slice(i, i + BATCH_SIZE));
      }
    }

    if (inactiveWorkerData) {
      sortDataByName(inactiveWorkerData);
      for (let i = 0; i < workersInactive.length; i += BATCH_SIZE) {
        inactiveBatches.push(inactiveWorkerData.slice(i, i + BATCH_SIZE));
      }
    }

    return [activeBatches, inactiveBatches];
  };

  useEffect(() => {
    const [activeBatches, inactiveBatches] = generateBatches();
    if (activeBatches) setActiveWorkerBatches(activeBatches);
    setInactiveWorkerBatches(inactiveBatches);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workersActive, workersInactive]);

  const columns = () => {
    let columnNumber = 6;
    if (weigthOffloaded) {
      columnNumber++;
    }
    if (safeliftScore) {
      columnNumber++;
    }
    if (flags.target_utilization_hours && targetUtilization) {
      columnNumber++;
    }
    return columnNumber;
  };

  const generateWorkerData = (): [{[key: string]: number | string}[] | undefined, {[key: string]: number | string}[] | undefined] => {
    const activeWorkerData: {[key: string]: number | string}[] | undefined = workersActive.map((worker) => {
      const weeksSinceFirstUse = worker.worker.first_session_start_at
        ? differenceInWeeks(new Date(), worker.worker.first_session_start_at)
        : "";

      // Calculate total weight offloaded
      const totalWeightOffloaded = worker.time_series_data
        .reduce((acc, data) => {
          return acc + data.weight_offloaded_lbs;
        }, 0)
        .toFixed(0);

      const score = () => {
        let totalSafeLifts = 0;
        let totalLifts = 0;

        worker.time_series_data.forEach((data) => {
          totalSafeLifts += data.safe_lifts;
          totalLifts += data.total_lifts;
        });

        return totalLifts > 0 ? Math.round((totalSafeLifts / totalLifts) * 100).toLocaleString() : "-";
      };

      const workerInfo = worker.worker;

      const report = {
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-nameColumnTitle")]: workerInfo.fullName,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-employeeIDTitle")]: workerInfo.employee_id,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-deviceTagColumnTitle")]: workerInfo.device_tag,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-weeksSinceFirstUseColumnTitle")]: weeksSinceFirstUse,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-daysActiveLast7DaysColumnTitle")]: worker.days_active_first_7_intervals,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-hoursLast7DaysColumnTitle")]: worker.hours_active_first_7_intervals,
      };

      if (flags.target_utilization_hours && targetUtilization) {
        report[WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-relativeToTargetColumnTitle")] = workerInfo.on_target;
        if (workerInfo.on_target !== Models.OnTarget.Exempt && workerInfo.on_target !== Models.OnTarget.Unset)
          report[WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-hoursLast7DaysColumnTitle")] =
            `${worker.hours_active_first_7_intervals} / ${workerInfo.lower_bound}`;
      }

      if (weigthOffloaded) {
        report[WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-weightOffloaded")] = totalWeightOffloaded;
      }
      if (safeliftScore) {
        report[WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-safeliftScore")] = score();
      }

      return report;
    });

    const inactiveWorkerData: {[key: string]: number | string}[] | undefined = workersInactive.map((worker) => {
      const daysSinceLastUse = worker.worker.most_recent_session_start_at
        ? differenceInDays(new Date(), worker.worker.most_recent_session_start_at)
        : "";

      const weeksSinceFirstUse = worker.worker.first_session_start_at
        ? differenceInWeeks(new Date(), worker.worker.first_session_start_at)
        : "";

      const workerInfo = worker.worker;

      return {
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-nameColumnTitle")]: workerInfo.fullName,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-employeeIDTitle")]: workerInfo.employee_id,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-deviceTagColumnTitle")]: workerInfo.device_tag,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-weeksSinceFirstUseColumnTitle")]: weeksSinceFirstUse,
        [WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-daysSinceLastUsageColumnTitle")]: daysSinceLastUse,
      };
    });

    return [activeWorkerData, inactiveWorkerData];
  };

  const formatNumber = (num: number, decimals = 0) =>
    num.toLocaleString("en-US", {style: "decimal", maximumFractionDigits: decimals}) == "NaN"
      ? 0
      : num.toLocaleString("en-US", {style: "decimal", maximumFractionDigits: decimals});

  return (
    <>
      {!data || !data.worker_data || activeWorkerBatches.length === 0 ? (
        <div id={index.toString()} className={styles.wrapper}>
          <div className={styles.departmentsPageWrapper}>
            <SiteSummaryReportHeader endDate={endDate} />
            <Typography.Title level={5}> {department.name} </Typography.Title>
            <Typography.Text strong> {WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-activeWorkers")} </Typography.Text>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      ) : (
        <>
          {activeWorkerBatches.map((batch, i) => (
            <div id={(index + i).toString()} className={styles.wrapper}>
              <div className={styles.departmentsPageWrapper}>
                <SiteSummaryReportHeader endDate={endDate} />
                <Typography.Title level={5}> {department.name} </Typography.Title>
                <Typography.Text strong> {WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-activeWorkers")} </Typography.Text>
                <div
                  className={`${styles.tableTitlesActive} ${columns() === 7 ? "" : columns() === 6 ? styles.sixColumns : columns() === 8 ? styles.eightColumns : styles.nineColumns}`}>
                  {Object.keys(batch[0]).map((key, j) => {
                    return (
                      <Typography.Text className={styles.cellText} key={`active-title-${i}${j}`}>
                        {key}
                      </Typography.Text>
                    );
                  })}
                </div>
                {batch.map((row, k) => (
                  <div
                    className={`${styles.tableRowActive} ${columns() === 7 ? "" : columns() === 6 ? styles.sixColumns : columns() === 8 ? styles.eightColumns : styles.nineColumns}`}
                    key={`active-row-${k}${i}`}>
                    {Object.values(row).map((value, j) => {
                      let renderValue: any = value;
                      if (j === 6 && flags.target_utilization_hours && targetUtilization) {
                        const icon =
                          value === Models.OnTarget.Meets
                            ? targetIcon
                            : value === Models.OnTarget.Overused
                              ? targetIconUp
                              : value === Models.OnTarget.Below
                                ? targetIconDown
                                : value === Models.OnTarget.Unset
                                  ? targetIconUnset
                                  : targetIconBlock;
                        renderValue = <img alt="" src={icon} />;
                      }
                      if (j === 5 && flags.target_utilization_hours && targetUtilization) {
                        //hours last 7 days column
                        const onTarget = batch[k][WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-relativeToTargetColumnTitle")];
                        const status =
                          onTarget === Models.OnTarget.Meets
                            ? styles.up
                            : onTarget === Models.OnTarget.Overused
                              ? styles.medium
                              : onTarget === Models.OnTarget.Below
                                ? styles.down
                                : "";
                        renderValue = (
                          <>
                            <span className={`${styles.circleSolid} ${status}`} />
                            {`${value}`}
                          </>
                        );
                      }

                      if (
                        (j === 7 && flags.target_utilization_hours && targetUtilization && weigthOffloaded) ||
                        (j === 6 && !targetUtilization && weigthOffloaded)
                      ) {
                        //weight offloaded
                        const numericValue = typeof value === "string" ? parseFloat(value) : value;
                        renderValue = (
                          <>
                            {formatNumber(userPreferences.data.metricMeasurementUnits ? lbsToKgs(numericValue) : numericValue)}{" "}
                            {userPreferences.data.metricMeasurementUnits
                              ? WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-kilogramsUnit")
                              : WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-poundsUnit")}
                          </>
                        );
                      }
                      return (
                        <Typography.Text className={styles.cellText} key={`active-rowValue-${i}${k}${j}`}>
                          {renderValue}
                        </Typography.Text>
                      );
                    })}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
      {!data || !data.worker_data || inactiveWorkerBatches.length === 0 ? (
        <div id={(index + 1).toString()} className={styles.wrapper}>
          <div className={styles.departmentsPageWrapper}>
            <SiteSummaryReportHeader endDate={endDate} />
            <Typography.Title level={5}> {department.name} </Typography.Title>
            <Typography.Text strong> {WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-inactiveWorkers")} </Typography.Text>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
          </div>
        </div>
      ) : (
        <>
          {inactiveWorkerBatches.map((batch, i) => (
            <div id={(index + (activeWorkerBatches.length > 0 ? activeWorkerBatches.length : 1) + i).toString()} className={styles.wrapper}>
              <div className={styles.departmentsPageWrapper}>
                <SiteSummaryReportHeader endDate={endDate} />
                <Typography.Title level={5}> {department.name} </Typography.Title>
                <Typography.Text strong> {WebHelper.formatMessage("ActiveInactiveSiteSummaryPage-inactiveWorkers")} </Typography.Text>
                <div className={styles.tableTitlesInactive}>
                  {Object.keys(batch[0]).map((key, j) => (
                    <Typography.Text className={styles.cellText} key={`inactive-title-${i}${j}`}>
                      {key}
                    </Typography.Text>
                  ))}
                </div>
                {batch.map((row, k) => (
                  <div className={styles.tableRowInactive} key={`inactive-row-${i}${k}`}>
                    {Object.values(row).map((value, j) => (
                      <Typography.Text className={styles.cellText} key={`inactive-rowValue-${i}${k}${j}`}>
                        {value}
                      </Typography.Text>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default ActiveInactiveWorkersSitePage;
