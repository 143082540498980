import EventEmitter from "eventemitter3";
import * as _ from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";

import {WorkerReportGroupAPIClient} from "../apiclient/workerreportgroup/WorkerReportGroupAPIClient";
import * as WorkerReportGroupTypes from "../apiclient/workerreportgroup/WorkerReportGroupAPIClient.types";
import * as Models from "../models";

export type WorkerReportGroupsState = {
  loadingWorkerReportGroups: boolean;
  loadingWorkerReportGroup: boolean;
  workerReportGroups?: Models.WorkerReportGroup[];
};

export type WorkerReportGroupEvent = {
  "worker-report-group-created": [Models.WorkerReportGroup | Models.WorkerReportGroup[]];
  "worker-report-group-updated": [Models.WorkerReportGroup];
  "worker-report-group-deleted": [string];
};

export class WorkerReportGroupDataStore {
  private static instance: WorkerReportGroupDataStore | undefined;

  private initialState: WorkerReportGroupsState = {
    loadingWorkerReportGroups: false,
    loadingWorkerReportGroup: false,
  };

  state = _.cloneDeep(this.initialState);

  eventEmitter: EventEmitter<WorkerReportGroupEvent>;

  private constructor() {
    makeObservable<WorkerReportGroupDataStore>(this, {
      state: observable,
      workerReportGroups: action,
      workerReportGroup: action,
      updateWorkerReportGroup: action,
      deleteWorkerReportGroup: action,
      createWorkerReportGroup: action,
      modifyWorkerReportGroupWorkers: action,
    });
  }

  static getInstance(): WorkerReportGroupDataStore {
    if (!WorkerReportGroupDataStore.instance) WorkerReportGroupDataStore.instance = new WorkerReportGroupDataStore();

    return WorkerReportGroupDataStore.instance;
  }

  initialize() {
    this.eventEmitter = new EventEmitter();
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
    this.eventEmitter?.removeAllListeners();
  }

  async workerReportGroups(
    request: WorkerReportGroupTypes.WorkerReportGroupsRequest
  ): Promise<WorkerReportGroupTypes.WorkerReportGroupsResponse> {
    this.state.loadingWorkerReportGroups = true;

    const response = await WorkerReportGroupAPIClient.workerReportGroups(request);

    runInAction(() => {
      this.state.loadingWorkerReportGroups = false;
      if (response.success && !_.isNil(response.workerReportGroups)) this.state.workerReportGroups = response.workerReportGroups;
    });

    return response;
  }

  async workerReportGroup(
    request: WorkerReportGroupTypes.WorkerReportGroupRequest
  ): Promise<WorkerReportGroupTypes.WorkerReportGroupResponse> {
    this.state.loadingWorkerReportGroup = true;

    const response = await WorkerReportGroupAPIClient.workerReportGroup(request);

    runInAction(() => {
      this.state.loadingWorkerReportGroup = false;
    });

    return response;
  }

  async updateWorkerReportGroup(
    request: WorkerReportGroupTypes.UpdateWorkerReportGroupRequest
  ): Promise<WorkerReportGroupTypes.UpdateWorkerReportGroupResponse> {
    const response = await WorkerReportGroupAPIClient.updateWorkerReportGroup(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-report-group-updated", response.workerReportGroup);
      }
    });

    return response;
  }

  async deleteWorkerReportGroup(
    request: WorkerReportGroupTypes.DeleteWorkerReportGroupRequest
  ): Promise<WorkerReportGroupTypes.DeleteWorkerReportGroupResponse> {
    const response = await WorkerReportGroupAPIClient.deleteWorkerReportGroup(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-report-group-deleted", request.id);
      }
    });

    return response;
  }

  async createWorkerReportGroup(
    request: WorkerReportGroupTypes.CreateWorkerReportGroupRequest
  ): Promise<WorkerReportGroupTypes.CreateWorkerReportGroupResponse> {
    const response = await WorkerReportGroupAPIClient.createWorkerReportGroup(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-report-group-created", response.workerReportGroup);
      }
    });

    return response;
  }

  async modifyWorkerReportGroupWorkers(
    request: WorkerReportGroupTypes.ModifyWorkerReportGroupWorkersRequest
  ): Promise<WorkerReportGroupTypes.ModifyWorkerReportGroupWorkersResponse> {
    const response = await WorkerReportGroupAPIClient.modifyWorkerReportGroupWorkers(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-report-group-updated", response.workerReportGroup);
      }
    });

    return response;
  }
}
