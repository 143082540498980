import {Form, Input, Select, Typography} from "antd";
import {FormInstance} from "antd/es/form/Form";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {AppConfig} from "../../../AppConfig";
import {WebHelper} from "../../../utils/WebHelper";

export type SiteDepartmentFormFields = {
  orgName?: string;
  orgShortName?: string;
  orgAdress?: string;
  orgIconUrl?: string;
  siteName: string;
  address: string;
  tz_location: string;
  departmentName: string;
};

type SiteDepartmentFormProps = {
  form: FormInstance<SiteDepartmentFormFields>;
  initialValues: {
    organization?: Models.Organization;
    site: Models.Site;
    department: Models.Department;
  };
  loading: boolean;
  onFieldsChange: () => void;
};

export const SiteDepartmentForm: FunctionComponent<SiteDepartmentFormProps> = observer(({form, initialValues, loading, onFieldsChange}) => {
  const {
    organizationForm: organizationInputValidation,
    siteForm: siteInputValidation,
    departmentForm: departmentInputValidation,
  } = AppConfig.Modules.InputValidation;
  const timezoneOptions = Intl.supportedValuesOf("timeZone").map((tz) => ({key: tz, label: tz, value: tz}));

  const {organization, site, department} = initialValues;

  return (
    <Form
      form={form}
      labelWrap={true}
      labelCol={{span: 8}}
      wrapperCol={{span: 20}}
      disabled={loading}
      onFieldsChange={onFieldsChange}
      initialValues={{
        orgName: organization?.name,
        orgShortName: organization?.short_name,
        orgAdress: organization?.address,
        orgIconUrl: organization?.icon_url,
        siteName: site.name,
        address: site.address,
        tz_location: site.tz_location,
        departmentName: department.name,
      }}>
      {organization && (
        <>
          <Typography.Title level={5}>{WebHelper.formatMessage("EntityDetail-title-organization")}</Typography.Title>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-name")}</Typography.Text>}
            name="orgName"
            rules={WebHelper.formatInputValidationRules(organizationInputValidation.name.rules)}>
            <Input />
          </Form.Item>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-shortName")}</Typography.Text>}
            name="orgShortName"
            rules={WebHelper.formatInputValidationRules(organizationInputValidation.shortName.rules)}>
            <Input />
          </Form.Item>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-address")}</Typography.Text>}
            name="orgAdress"
            rules={WebHelper.formatInputValidationRules(organizationInputValidation.address.rules)}>
            <Input />
          </Form.Item>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-iconUrl")}</Typography.Text>}
            name="orgIconUrl"
            rules={WebHelper.formatInputValidationRules(organizationInputValidation.iconUrl.rules)}>
            <Input />
          </Form.Item>
        </>
      )}
      <Typography.Title level={5}>{WebHelper.formatMessage("EntityDetail-title-site")}</Typography.Title>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-siteName")}</Typography.Text>}
        name="siteName"
        rules={WebHelper.formatInputValidationRules(siteInputValidation.name.rules)}>
        <Input />
      </Form.Item>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-address")}</Typography.Text>}
        name="address"
        rules={WebHelper.formatInputValidationRules(siteInputValidation.address.rules)}>
        <Input />
      </Form.Item>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-timezone")}</Typography.Text>}
        name="tz_location"
        rules={WebHelper.formatInputValidationRules(siteInputValidation.timezone.rules)}>
        <Select showSearch optionFilterProp="label" optionLabelProp="label" options={timezoneOptions} />
      </Form.Item>
      <Typography.Title level={5}>{WebHelper.formatMessage("EntityDetail-title-department")}</Typography.Title>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-departmentName")}</Typography.Text>}
        name="departmentName"
        rules={WebHelper.formatInputValidationRules(departmentInputValidation.name.rules)}>
        <Input />
      </Form.Item>
    </Form>
  );
});
