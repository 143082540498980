import EventEmitter from "eventemitter3";
import * as _ from "lodash";
import {makeObservable, runInAction, observable, action} from "mobx";

import {DepartmentAPIClient} from "../apiclient/department/DepartmentAPIClient";
import * as DepartmentTypes from "../apiclient/department/DepartmentAPIClient.types";
import * as Models from "../models";

export type DepartmentsState = {
  loadingDepartments: boolean;
  loadingDepartment: boolean;
  departments?: Models.Department[];
};

export type DepartmentEvent = {
  "department-created": [Models.Department];
  "department-updated": [Models.Department];
  "department-deleted": [string];
};

export class DepartmentDataStore {
  private static instance: DepartmentDataStore | undefined;

  private initialState: DepartmentsState = {
    loadingDepartments: false,
    loadingDepartment: false,
  };

  state = _.cloneDeep(this.initialState);

  eventEmitter: EventEmitter<DepartmentEvent>;

  private constructor() {
    makeObservable<DepartmentDataStore>(this, {
      state: observable,
      departments: action,
      department: action,
      createDepartment: action,
      updateDepartment: action,
      updateDepartmentTargetUtilization: action,
      deleteDepartment: action,
      summaryReport: action,
      allWorkersReport: action,
    });
  }

  static getInstance(): DepartmentDataStore {
    if (!DepartmentDataStore.instance) DepartmentDataStore.instance = new DepartmentDataStore();

    return DepartmentDataStore.instance;
  }

  initialize() {
    this.eventEmitter = new EventEmitter();
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
    this.eventEmitter?.removeAllListeners();
  }

  async departments(request: DepartmentTypes.DepartmentsRequest): Promise<DepartmentTypes.DepartmentsResponse> {
    this.state.loadingDepartments = true;

    const response = await DepartmentAPIClient.departments(request);

    runInAction(() => {
      this.state.loadingDepartments = false;
      if (response.success && !_.isNil(response.departments)) this.state.departments = response.departments;
    });

    return response;
  }

  async department(request: DepartmentTypes.DepartmentRequest): Promise<DepartmentTypes.DepartmentResponse> {
    this.state.loadingDepartment = true;

    const response = await DepartmentAPIClient.department(request);

    runInAction(() => {
      this.state.loadingDepartment = false;
      if (response.success && !_.isNil(response.department)) this.state.departments = [response.department];
    });

    return response;
  }

  async createDepartment(request: DepartmentTypes.CreateDepartmentRequest): Promise<DepartmentTypes.CreateDepartmentResponse> {
    const response = await DepartmentAPIClient.createDepartment(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("department-created", response.department);
        this.state.departments = [response.department];
      }
    });

    return response;
  }

  async deleteDepartment(request: DepartmentTypes.DeleteDepartmentRequest): Promise<DepartmentTypes.DeleteDepartmentResponse> {
    const response = await DepartmentAPIClient.deleteDepartment(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("department-deleted", request.id);
      }
    });

    return response;
  }

  async updateDepartment(request: DepartmentTypes.UpdateDepartmentRequest): Promise<DepartmentTypes.UpdateDepartmentResponse> {
    const response = await DepartmentAPIClient.updateDepartment(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("department-updated", response.department);
        this.state.departments = [response.department];
      }
    });

    return response;
  }

  async updateDepartmentTargetUtilization(
    request: DepartmentTypes.UpdateDepartmentTargetUtilizationRequest
  ): Promise<DepartmentTypes.UpdateDepartmentTargetUtilizationResponse> {
    return await DepartmentAPIClient.updateDepartmentTargetUtilization(request);
  }

  async summaryReport(request: DepartmentTypes.SummaryReportRequest): Promise<DepartmentTypes.SummaryReportResponse> {
    return await DepartmentAPIClient.summaryReport(request);
  }

  async allWorkersReport(request: DepartmentTypes.AllWorkersReportRequest): Promise<DepartmentTypes.AllWorkersReportResponse> {
    return await DepartmentAPIClient.allWorkersReport(request);
  }

  async demoReport(request: DepartmentTypes.DemoReportRequest): Promise<DepartmentTypes.DemoReportResponse> {
    return await DepartmentAPIClient.demoReport(request);
  }

  async departmentsDropdown(
    request: DepartmentTypes.DepartmentsDropdownRequest
  ): Promise<DepartmentTypes.DepartmentsDeviceDropdownResponse> {
    return await DepartmentAPIClient.departmentsDropdown(request);
  }
}
