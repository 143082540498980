import "../assets/stylesheets/main.scss";
import "@aws-amplify/ui-react/styles.css";

import {CognitoUserAmplify} from "@aws-amplify/ui";
import {useQuery} from "@tanstack/react-query";
import {ConfigProvider, message} from "antd";
import type {ThemeConfig} from "antd";
import {AliasToken} from "antd/lib/theme/interface";
import constants from "assets/stylesheets/constants";
import {Auth} from "aws-amplify";
import {useLDClient} from "launchdarkly-react-client-sdk";
import * as _ from "lodash";
import {observer} from "mobx-react-lite";
import * as React from "react";
import {FunctionComponent, useCallback, useEffect, useRef, useState} from "react";

import {Locale} from "../core/locales/Locale";
import * as Models from "../core/models";
import {AuthenticationDataStore} from "../core/stores/AuthenticationDataStore";
import {DataStore} from "../core/stores/DataStore";
import {AppRouting} from "./AppRouting";
import {Loading} from "./components/loading/Loading";
import {AppStore} from "./stores/AppStore";
import {WebHelper} from "./utils/WebHelper";

export type AppProps = {
  config?: any;
  locale?: Locale;
  user?: CognitoUserAmplify;
};

const themeEditorToken: Partial<AliasToken> = {
  colorPrimaryBg: "#6e7475",
  colorPrimaryBgHover: "#626769",
  colorPrimaryBorder: "#565b5c",
  colorPrimaryBorderHover: "#4a4e4f",
  colorPrimaryHover: "#3e4142",
  colorPrimary: constants.colors.antdPrimaryBlack,
  colorPrimaryActive: "#3e4142",
  colorPrimaryTextHover: "#3e4142",
  colorPrimaryText: "#323435",
  colorPrimaryTextActive: "#0e0f0f",
  colorLink: "#4a4e4f",
  colorLinkActive: "#0e0f0f",
};

const config: ThemeConfig = {
  token: {...themeEditorToken},
  components: {
    Tabs: {horizontalItemPadding: "6px 0"},
    Layout: {bodyBg: "white"},
    Table: {bodySortBg: "@table-bg", headerSortActiveBg: "@table-header-bg"},
  },
};

export const App: FunctionComponent<AppProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const dataStore = DataStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const ldClient = useLDClient();

  const [currentUser, setCurrentUser] = useState<Models.User>();
  const [deactivatedUser, setDeactivatedUser] = useState<boolean>(false);
  const [isOnline, setOnline] = useState(true);

  window.addEventListener("online", () => {
    setOnline(true);
  });

  window.addEventListener("offline", () => {
    setOnline(false);
  });

  useEffect(() => {
    if (!isOnline) {
      appStore.showMessage(WebHelper.formatMessage("App-internetError"), "error", 10);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnline]);

  const loadAuthData = useCallback(async () => {
    if (props.user) {
      const session = props.user.getSignInUserSession();
      if (session) {
        const {
          attributes: {sub, email, given_name, family_name} = {
            sub: undefined,
            email: undefined,
            given_name: undefined,
            family_name: undefined,
          },
        } = props.user;

        ldClient?.identify({
          kind: "user",
          key: sub,
          email,
          name: !given_name && !family_name ? undefined : `${given_name ?? ""} ${family_name ?? ""}`,
          custom: {
            orgID: currentUser ? currentUser.organization_id ?? Models.UserScope.Global : null,
            siteIDS: currentUser
              ? currentUser.organization_id
                ? currentUser.permissions?.filter((p) => p.scope === Models.UserScope.Site).map((p) => p.resource_id)
                : Models.UserScope.Global
              : null,
          },
        });
        authenticationStore.setAccessToken(session, sub);
      }
    }
  }, [authenticationStore, currentUser, ldClient, props.user]);

  useEffect(() => {
    loadAuthData();
  }, [loadAuthData]);

  const fetchMeQuery = useQuery({
    queryKey: ["App-FetchMe", dataStore.isInitialized, props.user?.attributes?.sub],
    queryFn: () => authenticationStore.fetchMe({accessToken: authenticationStore.state.accessToken!, sub: props.user?.attributes?.sub}),
    enabled: dataStore.isInitialized && !!props.user?.attributes?.sub,
  });

  useEffect(() => {
    if (!fetchMeQuery.data) return;

    if (fetchMeQuery.data.success && fetchMeQuery.data.user && fetchMeQuery.data.user.sub === props.user?.attributes?.sub) {
      if (fetchMeQuery.data.user.active) setCurrentUser(fetchMeQuery.data.user);
      else setDeactivatedUser(true);
    }
    if (!fetchMeQuery.data.success) {
      if (fetchMeQuery.data.error.code === "NOT_AUTHORIZED_ERROR") {
        appStore.reset();
        Auth.signOut();
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("App-fetchMeError"), fetchMeQuery.data.correlationId);
    }
  }, [appStore, fetchMeQuery.data, props.user?.attributes?.sub]);

  const antd_locale = dataStore.currentLocale ? AppStore.Intl.antd_locales[dataStore.currentLocale.code] : undefined;

  const messageContainerRef = useRef<HTMLDivElement>(null);
  const prevConfigRef = useRef<any>();

  useState(() => {
    message.config({
      getContainer: () => messageContainerRef.current || document.body,
    });
  });

  if (!_.isNil(props.locale) && (!dataStore.currentLocale || props.locale.code !== dataStore.currentLocale.code))
    dataStore.setLocale(props.locale);
  if (!_.isNil(props.config) && !_.isEqual(props.config, prevConfigRef.current)) appStore.setConfig(props.config);

  prevConfigRef.current = props.config;

  useEffect(() => {
    if (!appStore.state.initialized) appStore.initialize();
  }, [appStore, appStore.state.initialized]);

  return (
    <ConfigProvider locale={antd_locale} theme={config}>
      {!appStore.state.initialized || fetchMeQuery.isPending ? (
        <Loading />
      ) : (
        <AppRouting
          currentUser={currentUser}
          deactivatedUser={deactivatedUser}
          messageContainerRef={messageContainerRef}
          onSignOut={() => setCurrentUser(undefined)}
        />
      )}
    </ConfigProvider>
  );
});
