import * as _ from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";

import {UserAPIClient} from "../apiclient/user/UserAPIClient";
import * as UserTypes from "../apiclient/user/UserAPIClient.types";
import * as Models from "../models";

export type UsersState = {
  loadingUsers: boolean;
  users?: Models.User[];
};

export class UserDataStore {
  private static instance: UserDataStore | undefined;

  private initialState: UsersState = {
    loadingUsers: false,
  };

  state = _.cloneDeep(this.initialState);

  private constructor() {
    makeObservable<UserDataStore>(this, {
      state: observable,
      reset: action,
      users: action,
      changeUserPermission: action,
      changeUserActive: action,
    });
  }

  static getInstance(): UserDataStore {
    if (!UserDataStore.instance) UserDataStore.instance = new UserDataStore();

    return UserDataStore.instance;
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
  }

  async users(request: UserTypes.UsersRequest): Promise<UserTypes.UsersResponse> {
    this.state.loadingUsers = true;

    const response = await UserAPIClient.users(request);

    runInAction(() => {
      this.state.loadingUsers = false;
      if (response.success && !_.isNil(response.users)) this.state.users = response.users;
    });

    return response;
  }

  async changeUserPermission(request: UserTypes.ChangeUserPermissionRequest): Promise<UserTypes.ChangeUserPermissionResponse> {
    const response = await UserAPIClient.changeUserPermission(request);

    return response;
  }
  async changeUserPermissionMultiple(
    request: UserTypes.ChangeUserPermissionMultipleRequest
  ): Promise<UserTypes.ChangeUserPermissionResponse> {
    const response = await UserAPIClient.changeUserPermissionMultiple(request);

    return response;
  }

  async changeUserActive(request: UserTypes.ChangeUserActiveRequest): Promise<UserTypes.ChangeUserActiveResponse> {
    const response = await UserAPIClient.changeUserActive(request);

    return response;
  }
}
