import {Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

type PasswordValidationItemProps = {
  text: string;
  isValid: boolean;
};

export const PasswordValidationItem: FunctionComponent<PasswordValidationItemProps> = observer(({text, isValid}) => {
  return (
    <Typography.Text type={isValid ? "success" : "danger"}>
      {isValid ? "✓" : "✖"} {text}
    </Typography.Text>
  );
});
