import {useMutation, useQuery} from "@tanstack/react-query";
import {Empty, Form, Modal, Select, Typography} from "antd";
import React, {useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {WorkerReportGroupDataStore} from "../../../../../core/stores/WorkerReportGroupDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {DiscardChangesModal} from "../../../discard-changes-modal/DiscardChangesModal";
import styles from "./CreateWorkersReportGroupModal.module.scss";
import {WorkersReportGroupForm, WorkersReportGroupFormFields} from "./workers-report-group-form/WorkersReportGroupForm";

type CreateWorkersReportGroupModalProps = {
  workersReportGroups: Models.WorkerReportGroup[];
  siteID: string;
  open: boolean;
  onClose: () => void;
  onCreate: () => void;
};

export const CreateWorkersReportGroupModal: React.FC<CreateWorkersReportGroupModalProps> = ({
  workersReportGroups,
  siteID,
  open,
  onClose,
  onCreate,
}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const workerStore = WorkerDataStore.getInstance();
  const workerReportGroupStore = WorkerReportGroupDataStore.getInstance();

  const [form] = Form.useForm<WorkersReportGroupFormFields>();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [selectedWorkers, setSelectedWorkers] = useState([]);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const {data: workersQueryData, isPending: workersQueryIsLoading} = useQuery({
    queryKey: ["CreateWorkersReportGroupModal-fetchWorkersData"],
    queryFn: () =>
      workerStore.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        site_id: siteID,
      }),
  });

  const createWorkerReportGroupMutation = useMutation({
    mutationFn: (groupName: string) =>
      workerReportGroupStore.createWorkerReportGroup({
        accessToken: authenticationStore.state.accessToken!,
        site_id: siteID,
        name: groupName,
        worker_ids: selectedWorkers,
      }),
    onSuccess: (response) => {
      if (!response.success)
        WebHelper.showErrorMessage(WebHelper.formatMessage("CreateWorkersReportGroupModal-createError"), response.correlationId);
      else {
        appStore.sendAnalyticTrack(SegmentKey.CreateReportGroup, {
          siteID: response.workerReportGroup.site.id,
          orgID: response.workerReportGroup.organization.id,
          groupID: response.workerReportGroup.id,
        });

        if (response.workerReportGroup.workers) {
          appStore.sendAnalyticTrack(SegmentKey.AddWorkerFromGroupBulk, {
            siteID: response.workerReportGroup.site.id,
            orgID: response.workerReportGroup.organization.id,
            groupID: response.workerReportGroup.id,
            quantity: response.workerReportGroup.workers.length,
            entryPoint: SegmentEntryPoint.CREATE_GROUP,
          });
        }

        appStore.showMessage(WebHelper.formatMessage("CreateWorkersReportGroupModal-successMessage"), "success");
        onCreate();
        onClose();
        resetValues();
      }
    },
  });

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsFormDisabled(true);
    } else {
      setIsFormDisabled(false);
    }
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then((formValues) => {
        createWorkerReportGroupMutation.mutate(formValues.name);
      })
      // This catch prevents AntD from throwing an error to the console when form validations fail
      .catch(() => {});
  };

  const resetValues = () => {
    form.resetFields();
    setSelectedWorkers([]);
    setIsFormDisabled(true);
  };

  const handleDiscard = () => {
    setShowDiscardModal(false);
    onClose();
    resetValues();
  };

  const handleCancel = () => {
    if (form.isFieldsTouched() || selectedWorkers.length > 0) {
      setShowDiscardModal(true);
    } else {
      onClose();
      resetValues();
    }
  };

  const loading = workersQueryIsLoading || createWorkerReportGroupMutation.isPending;

  return (
    <>
      <Modal
        centered
        destroyOnClose
        title={WebHelper.formatMessage("CreateWorkerReportGroupModal-title")}
        open={open}
        okText={WebHelper.formatMessage("Common-create")}
        okButtonProps={{shape: "round", disabled: isFormDisabled, loading: createWorkerReportGroupMutation.isPending}}
        onOk={handleOk}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        cancelButtonProps={{shape: "round", disabled: createWorkerReportGroupMutation.isPending}}
        onCancel={handleCancel}>
        <WorkersReportGroupForm
          form={form}
          loading={loading}
          onFieldsChange={handleOnFieldsChange}
          workersReportGroups={workersReportGroups}
        />

        <div className={styles.selectWrapper}>
          <Typography.Text>{WebHelper.formatMessage("CreateWorkerReportGroupModal-selectWorkersLabel")}</Typography.Text>
          <Select
            value={selectedWorkers}
            className={styles.select}
            mode="multiple"
            allowClear
            loading={workersQueryIsLoading}
            disabled={loading}
            options={
              workersQueryData?.workers
                ? workersQueryData?.workers.map(({id, displayName}) => ({key: id, label: displayName, value: id}))
                : []
            }
            optionFilterProp="label"
            placeholder={WebHelper.formatMessage("Common-select")}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={WebHelper.formatMessage("CreateWorkerReportGroupModal-noWorkersText")}
              />
            }
            onChange={(values) => setSelectedWorkers(values)}
            filterSort={(a, b) => a.label.localeCompare(b.label)}
          />
        </div>
      </Modal>
      <DiscardChangesModal onDiscard={handleDiscard} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
    </>
  );
};
