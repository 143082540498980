import {useMutation, useQuery} from "@tanstack/react-query";
import {Form, Modal} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../../core/stores/DeviceDataStore";
import {AppStore} from "../../../../stores/AppStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {DiscardChangesModal} from "../../../discard-changes-modal/DiscardChangesModal";
import {Loading} from "../../../loading/Loading";
import {UpdateDeviceConfigurationForm} from "./update-device-configuration-form/UpdateDeviceConfigurationForm";

export type UpdateDeviceConfigFormFields = {
  firmware_version_id: string;
  button_1_controller_setting_id: string;
  button_2_controller_setting_id: string;
  button_3_controller_setting_id: string;
};

export type UpdateDeviceConfigurationModalProps = {
  deviceId: string;
  open: boolean;
  onOk: () => void;
  onClose: () => void;
};

export const UpdateDeviceConfigurationModal: FunctionComponent<UpdateDeviceConfigurationModalProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [device, setDevice] = useState<Models.Device | null>();

  const [form] = Form.useForm<UpdateDeviceConfigFormFields>();

  const deviceQuery = useQuery({
    queryKey: ["DepartmentDetail-fetchDeviceData"],
    queryFn: () => {
      return deviceStore.device({
        accessToken: authenticationStore.state.accessToken!,
        id: props.deviceId,
      });
    },
  });

  useEffect(() => {
    if (!deviceQuery.data) return;

    if (!deviceQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDeviceManagementDeviceDetailPageData-errorMessage"),
        deviceQuery.data.correlationId
      );
      return;
    }
    setDevice(deviceQuery.data.device);
  }, [deviceQuery.data]);

  const updateDeviceConfigurationMutation = useMutation({
    mutationFn: (formValues: UpdateDeviceConfigFormFields) =>
      deviceStore.updateDeviceConfiguration({
        accessToken: authenticationStore.state.accessToken!,
        id: device!.id,
        override_pending: true,
        firmware_version_id: formValues.firmware_version_id,
        button_1_controller_setting_id: formValues.button_1_controller_setting_id,
        button_2_controller_setting_id: formValues.button_2_controller_setting_id,
        button_3_controller_setting_id: formValues.button_3_controller_setting_id,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("UpdateDeviceConfigurationModal-updateError"), response.correlationId);
        return;
      }

      appStore.showMessage(WebHelper.formatMessage("UpdateDeviceConfigurationModal-updateSuccess"), "success");

      props.onOk();
      form.resetFields();
    },
  });

  const handleOk = async () => {
    form
      .validateFields()
      .then((formValues) => {
        updateDeviceConfigurationMutation.mutate(formValues);
      })
      // This catch prevents AntD from throwing an error to the console when form validations fail
      .catch(() => {});
  };

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsFormDisabled(true);
    } else {
      setIsFormDisabled(false);
    }
  };

  const handleCancel = () => {
    if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
    else {
      setShowDiscardModal(false);
      setIsFormDisabled(true);
      props.onClose();
      form.resetFields();
    }
  };

  return (
    <>
      <Modal
        centered
        zIndex={1001}
        destroyOnClose={true}
        open={props.open}
        afterClose={() => form.resetFields()}
        title={WebHelper.formatMessage("UpdateDeviceConfigurationModal-title")}
        okText={WebHelper.formatMessage("UpdateDeviceConfigurationModal-scheduleUpdateButtonText")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          shape: "round",
          loading: updateDeviceConfigurationMutation.isPending,
          disabled: isFormDisabled || updateDeviceConfigurationMutation.isPending,
        }}
        cancelButtonProps={{
          shape: "round",
          disabled: updateDeviceConfigurationMutation.isPending,
        }}>
        {deviceQuery.isFetching ? (
          <Loading />
        ) : (
          device && <UpdateDeviceConfigurationForm device={device} form={form} loading={false} onFieldsChange={handleOnFieldsChange} />
        )}
      </Modal>
      <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
    </>
  );
});
