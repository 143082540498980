import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {DepartmentNested} from "./DepartmentNested";
import {OrganizationNested} from "./OrganizationNested";
import {SiteNested} from "./SiteNested";

export enum GatewayStatus {
  Up = "up",
  Down = "down",
  Intermittent = "intermittent",
  NeverSeen = "never_seen",
}

export class Gateway extends BaseModel {
  id: string;
  department_id: string | null;
  department: DepartmentNested | null;
  site: SiteNested | null;
  organization: OrganizationNested | null;
  serial_number: string;
  status: GatewayStatus;
  most_recent_gateway_event_at: Date | null;
  current_firmware_version: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      department_id: observable,
      department: observable,
      site: observable,
      organization: observable,
      serial_number: observable,
      status: observable,
      most_recent_gateway_event_at: observable,
      current_firmware_version: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: Gateway, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.most_recent_gateway_event_at) object.most_recent_gateway_event_at = new Date(json.most_recent_gateway_event_at);
    if (json.department) object.department = DepartmentNested.fromJSON(json.department);
    if (json.site) object.site = SiteNested.fromJSON(json.site);
    if (json.organization) object.organization = OrganizationNested.fromJSON(json.organization);
  }
}
