import * as _ from "lodash";

import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import * as Types from "./DeviceAPIClient.types";
import {DeviceManagementSelectOptions} from "./DeviceAPIClient.types";

export class DeviceAPIClient extends BaseAPIClient {
  static async devices(request: Types.DevicesRequest, options: RequestOptions = {}): Promise<Types.DevicesResponse> {
    const {
      accessToken: {token},
      department_id,
      site_id,
      organization_id,
      unallocated,
      system_serial_number_substring,
      device_tag_substring,
    } = request;

    const orgId = organization_id === DeviceManagementSelectOptions.Unallocated ? "null" : organization_id;
    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `devices/`,
      headers: {token},
      request,
      options,
      params: {
        ...(department_id ? {department_id} : {}),
        ...(site_id ? {site_id} : {}),
        ...(organization_id ? {organization_id: orgId} : {}),
        ...(unallocated ? {unallocated: "true"} : {}),
        ...(system_serial_number_substring ? {system_serial_number_substring: system_serial_number_substring} : {}),
        ...(device_tag_substring ? {device_tag_substring: device_tag_substring} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devices: data.map((device: Models.Device) => Models.Device.fromJSON(device)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async devicesShort(request: Types.DevicesRequest, options: RequestOptions = {}): Promise<Types.DevicesShortResponse> {
    const {
      accessToken: {token},
      department_id,
      site_id,
      organization_id,
      unallocated,
      system_serial_number_substring,
      device_tag_substring,
    } = request;

    const orgId = organization_id === DeviceManagementSelectOptions.Unallocated ? "null" : organization_id;
    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `devices/short`,
      headers: {token},
      request,
      options,
      params: {
        ...(department_id ? {department_id} : {}),
        ...(site_id ? {site_id} : {}),
        ...(organization_id ? {organization_id: orgId} : {}),
        ...(unallocated ? {unallocated: "true"} : {}),
        ...(system_serial_number_substring ? {system_serial_number_substring: system_serial_number_substring} : {}),
        ...(device_tag_substring ? {device_tag_substring: device_tag_substring} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devices: data.map((device: Models.DeviceShort) => Models.DeviceShort.fromJSON(device)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async device(request: Types.DeviceRequest, options: RequestOptions = {}): Promise<Types.DeviceResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `devices/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          device: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async createDevice(request: Types.CreateDeviceRequest, options: RequestOptions = {}): Promise<Types.CreateDeviceResponse> {
    const {department_id, mcu_id, system_serial_number, device_tag, device_type_id, status, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/`,
      headers: {token},
      request,
      options,
      data: {department_id, mcu_id, system_serial_number, device_tag, device_type_id, status},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          device: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async deleteDevice(request: Types.DeleteDeviceRequest, options: RequestOptions = {}): Promise<Types.DeleteDeviceResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `delete`,
      url: `devices/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      return {
        success: true,
      };
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateDevice(request: Types.UpdateDeviceRequest, options: RequestOptions = {}): Promise<Types.UpdateDeviceResponse> {
    const {id, department_id, mcu_id, system_serial_number, device_tag, device_type_id, assignable, archived_at, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `devices/${id}`,
      headers: {token},
      request,
      options,
      data: {id, department_id, mcu_id, system_serial_number, device_tag, device_type_id, assignable, archived_at},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          device: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async updateDeviceConfiguration(
    request: Types.UpdateDeviceConfigurationRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateDeviceConfigurationResponse> {
    const {
      id,
      override_pending,
      firmware_version_id,
      button_1_controller_setting_id,
      button_2_controller_setting_id,
      button_3_controller_setting_id,
      accessToken,
    } = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/${id}/config`,
      headers: {token},
      request,
      options,
      data: {
        id,
        override_pending,
        firmware_version_id,
        button_1_controller_setting_id,
        button_2_controller_setting_id,
        button_3_controller_setting_id,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          device: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async allocateDeviceToDepartment(
    request: Types.AllocateDeviceToDepartmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.AllocateDeviceToDepartmentResponse> {
    const {id, department_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/${id}/department`,
      headers: {token},
      request,
      options,
      data: {department_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          device: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async allocateDevicesToDepartment(
    request: Types.AllocateDevicesToDepartmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.AllocateDevicesToDepartmentResponse> {
    const {
      device_ids,
      department_id,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/allocate`,
      headers: {token},
      request,
      options,
      data: {department_id, device_ids},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devices: data.map((device: Models.Device) => Models.Device.fromJSON(device)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async assignWorkerToDevice(
    request: Types.AssignWorkerToDeviceRequest,
    options: RequestOptions = {}
  ): Promise<Types.AssignWorkerToDeviceResponse> {
    const {worker_id, device_id, assignable, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/${device_id}/worker`,
      headers: {token},
      request,
      options,
      data: {worker_id, assignable},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          device: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async bulkCreateDevices(
    request: Types.BulkCreateDevicesRequest,
    options: RequestOptions = {}
  ): Promise<Types.BulkCreateDevicesResponse> {
    const {
      device_info,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/create`,
      headers: {token},
      request,
      options,
      data: {device_info},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devices: data.map((device: Models.Device) => Models.Device.fromJSON(device)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async archiveDevice(request: Types.ArchiveDeviceRequest, options: RequestOptions = {}): Promise<Types.ArchiveDeviceResponse> {
    const {device_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/${device_id}/archive`,
      headers: {token},
      request,
      options,
      data: {},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          device: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async deviceGroups(request: Types.DeviceGroupsRequest, options: RequestOptions = {}): Promise<Types.DeviceGroupsResponse> {
    const {organization_ids, site_ids, device_ids, department_ids, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `device_types/device_groupings`,
      headers: {token},
      request,
      options,
      data: {organization_ids, site_ids, device_ids, department_ids},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devicesGroups: Models.DeviceGroup.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async deviceSearchTree(
    request: Types.DeviceSearchTreeRequest,
    options: RequestOptions = {}
  ): Promise<Types.DeviceSearchTreeResponse> {
    const {token} = request.accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `device_types/search_tree`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          organizations: data.organization_ids,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async bulkUpdate(request: Types.DeviceBulkUpdateRequest, options: RequestOptions = {}): Promise<Types.DeviceBulkUpdateResponse> {
    const {firmware_groups, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `device_types/bulk_update`,
      headers: {token},
      request,
      options,
      data: {firmware_groups},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devices: undefined,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async deviceCheck(request: Types.DeviceCheckRequest, options: RequestOptions = {}): Promise<Types.DeviceCheckResponse> {
    const {devices_serial_numbers, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/check_serial_number`,
      headers: {token},
      request,
      options,
      data: {devices_serial_numbers},
    });
    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devicesGroups: Models.DeviceGroup.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else {
      const {response} = requestResponse.rawResponse;
      if (response?.status === 400 && !_.isNil(response.data)) {
        return {
          success: false,
          error: {code: "UNMARSHALING_BODY_ERROR", message: ""},
          devicesGroups: response.data ?? [],
        };
      }
      return this.getApiCreateErrorResponse(requestResponse);
    }
  }

  static async createDeviceBulk(
    request: Types.CreateDeviceBulkRequest,
    options: RequestOptions = {}
  ): Promise<Types.CreateDeviceBulkResponse> {
    const {department_id, groups_devices, override_pending, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `devices/bulk_serial_number`,
      headers: {token},
      request,
      options,
      data: {department_id, groups_devices, override_pending},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          devices: Models.Device.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }
}
