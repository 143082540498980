import {DeleteOutlined, InboxOutlined} from "@ant-design/icons";
import {Button, Space, Typography, Upload} from "antd";
import {RcFile} from "antd/lib/upload";
import React, {FunctionComponent} from "react";

import {WebHelper} from "../../utils/WebHelper";
import styles from "./CSVDragger.module.scss";

type CSVDraggerProps = {
  descriptionMessage: string;
  columnsMessage: string;
  fileName: string | null;
  onRemoveFile: (e: React.MouseEvent<HTMLSpanElement>) => void;
  onUpload: (file: RcFile) => void;
};

export const CSVDragger: FunctionComponent<CSVDraggerProps> = ({descriptionMessage, columnsMessage, fileName, onRemoveFile, onUpload}) => {
  return (
    <Space size={16} direction="vertical" className={styles.content}>
      <>
        <Typography.Text>
          <div
            className={styles.description}
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(descriptionMessage),
            }}
          />
        </Typography.Text>
        <Typography.Text>{columnsMessage}</Typography.Text>
      </>
      <Upload.Dragger
        className={styles.dragger}
        accept={".csv"}
        beforeUpload={(file) => onUpload(file)}
        maxCount={1}
        showUploadList={false}
        disabled={!!fileName}>
        {!fileName ? (
          <Space size={20} direction="vertical">
            <InboxOutlined className={styles.uploadIcon} />
            <Typography.Text>{WebHelper.formatMessage("Common-draggerText")}</Typography.Text>
          </Space>
        ) : (
          <div className={styles.uploadItem}>
            <Typography.Text>{fileName}</Typography.Text>
            <Button className={styles.deleteButton} shape="round" type="text" icon={<DeleteOutlined onClick={(e) => onRemoveFile(e)} />} />
          </div>
        )}
      </Upload.Dragger>
    </Space>
  );
};
