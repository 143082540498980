import {Locale} from "./Locale";

export const en_US: Locale = {
  code: "en-US",
  "Common-date": "Date",
  "Common-dateFormatterWithoutTimeZone": "MMMM Do, YYYY [at] h:mmA",
  "Common-dateFormatterWithTimeZone": "MMMM Do, YYYY [at] h:mmA z",
  "Common-day": "day",
  "Common-days": "days",
  "Common-error": "Error",
  "Common-genericError": "Sorry, something went wrong.",
  "Common-hour": "hr",
  "Common-hours": "hrs",
  "Common-miles": "miles",
  "Common-minute": "min",
  "Common-minutes": "mins",
  "Common-month": "month",
  "Common-months": "months",
  "Common-more": "more",
  "Common-networkError": "Could not connect to server.",
  "Common-ok": "Ok",
  "Common-retry": "Retry",
  "Common-second": "sec",
  "Common-seconds": "secs",
  "Common-test": "Test",
  "Common-time": "time",
  "Common-today": "Today",
  "Common-tomorrow": "Tomorrow",
  "Common-week": "week",
  "Common-weeks": "weeks",
  "Common-year": "year",
  "Common-years": "years",
  "Common-yesterday": "Yesterday",
  "Validation-emailInvalidError": "Invalid email",
  "Validation-emptyFieldError": "This field cannot be empty",
};
