export enum SegmentEntryPoint {
  ACTIVE_INACTIVE_TABLE = "Active/Inactive Table",
  ALL_WORKERS_REPORT = "All workers report",
  BULK_ADD_WORKERS = "Bulk Add Workers",
  CHANGE_DEVICE_STATUS_MODAL = "Change Device Status Modal",
  CREATE_GROUP = "Create Group",
  CREATE_WORKER_MODAL = "Create Worker Modal",
  DELETE_WORKER_BUTTON = "Delete Worker Button",
  DEVICE_TABLE = "Device Table",
  EDIT_DEVICE_MODAL = "Edit Device Modal",
  EDIT_WORKER_MODAL = "Edit Worker Modal",
  WORKER_TAB = "Worker Tab",
  WORKER_TABLE = "Worker Table",
}

export enum SegmentResourceType {
  DEVICE = "Device",
  WORKER = "Worker",
  DEPARTMENT = "Department",
  WORKER_GROUP = "Worker Group",
}

export enum SegmentFileType {
  PDF = "PDF",
  XLSX = "XLSX",
}

export const SEGMENT_NOT_APPLICABLE_VALUE = "Not Applicable";
