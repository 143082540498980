import {useMutation} from "@tanstack/react-query";
import React from "react";

import * as Models from "../../core/models";
import {AuthenticationDataStore} from "../../core/stores/AuthenticationDataStore";

export default function useUserPreferences(): [
  {data: Models.UserPreferences; isLoading: boolean},
  (updatedPreferences: Models.UserPreferences) => void,
] {
  const authStore = AuthenticationDataStore.getInstance();
  const {user, accessToken} = authStore.state;

  const [userPreferences, setUserPreferences] = React.useState<Models.UserPreferences>(user?.getPreferences() ?? {});

  const {mutate: updateMyUserPreferencesMutate, isPending: updateMyUserPreferencesIsLoading} = useMutation({
    mutationFn: (updatedPreferences: Models.UserPreferences) => {
      return authStore.updateMyUserPreferences({
        accessToken: accessToken!,
        updatedPreferences,
      });
    },
  });

  function handleUpdateUserPreferences(updatedPreferences: Models.UserPreferences) {
    setUserPreferences({...userPreferences, ...updatedPreferences});
    updateMyUserPreferencesMutate(updatedPreferences);
  }

  return [{data: userPreferences, isLoading: updateMyUserPreferencesIsLoading}, handleUpdateUserPreferences];
}
