import {Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";
import styles from "./ConnectedStatus.module.scss";

type ConnectedStatusProps = {
  status: Models.DeviceStatus;
};

export const ConnectedStatus: FunctionComponent<ConnectedStatusProps> = observer(({status}) => {
  return <>{mapDeviceStatus(status)}</>;
});

function mapDeviceStatus(status: Models.DeviceStatus) {
  switch (status) {
    case Models.DeviceStatus.Connected:
      return (
        <Typography.Text className={styles.wrapper}>
          <span className={`${styles.circleSolid} ${styles.connected}`} />
          {WebHelper.formatMessage("DeviceManagementDeviceDetail-ConnectedStatus-connected")}
        </Typography.Text>
      );
    case Models.DeviceStatus.Disconnected:
      return (
        <Typography.Text className={styles.wrapper}>
          <span className={`${styles.circleSolid} ${styles.disconnected}`} />
          {WebHelper.formatMessage("DeviceManagementDeviceDetail-ConnectedStatus-disconnected")}
        </Typography.Text>
      );
    case Models.DeviceStatus.NeverSeen:
      return (
        <Typography.Text className={styles.wrapper}>
          <span className={`${styles.circleSolid} ${styles.neverSeen}`} />
          {WebHelper.formatMessage("DeviceManagementDeviceDetail-ConnectedStatus-neverSeen")}
        </Typography.Text>
      );
    default:
      return null;
  }
}
