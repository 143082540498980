import {Button as AntdButton} from "antd";
import React, {CSSProperties, FunctionComponent} from "react";

import styles from "./Button.module.scss";

type ButtonShape = "round" | "circle" | "default" | undefined;

type HtmlType = "button" | "submit" | "reset" | undefined;

export enum ButtonType {
  Default = "default",
  Primary = "primary",
}

type ButtonProps = {
  type?: ButtonType;
  shape?: ButtonShape;
  onClick?: () => void;
  loading?: boolean;
  disabled?: boolean;
  style?: CSSProperties;
  danger?: boolean;
  htmlType?: HtmlType;
  icon?: React.ReactNode;
  children?: React.ReactNode;
};

export const Button: FunctionComponent<ButtonProps> = ({
  type,
  style,
  shape,
  onClick,
  loading,
  disabled,
  danger,
  htmlType,
  icon,
  children,
}) => {
  return (
    <AntdButton
      className={
        loading
          ? type === ButtonType.Primary
            ? styles.loadingPrimary
            : styles.loadingDefault
          : disabled
            ? type === ButtonType.Primary
              ? styles.disabledPrimary
              : styles.disabledDefault
            : danger
              ? type === ButtonType.Primary
                ? styles.dangerPrimary
                : styles.dangerDefault
              : type === ButtonType.Primary
                ? styles.primaryButton
                : styles.defaultButton
      }
      style={style}
      shape={shape}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      htmlType={htmlType}
      icon={icon}>
      {children}
    </AntdButton>
  );
};
