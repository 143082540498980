import {Table as TableAntd, TableProps as TablePropsAntd} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import styles from "./Table.module.scss";

interface TableProps<T> extends TablePropsAntd<T> {
  rowsSingleColor?: boolean;
}

export const Table: FunctionComponent<TableProps<any>> = observer(
  ({pagination, onChange, loading, columns, dataSource, rowsSingleColor = false, summary}) => {
    return (
      <TableAntd
        rowKey={(record) => record.id}
        className={styles.table}
        columns={columns}
        loading={loading}
        dataSource={dataSource}
        onChange={onChange}
        pagination={{...pagination, position: ["bottomCenter"]}}
        rowClassName={rowsSingleColor ? styles.rowLight : getRowClassName}
        getPopupContainer={(trigger) => trigger}
        summary={summary}
      />
    );
  }
);

function getRowClassName(_: any, index: number) {
  const isEven = index % 2 === 0;

  if (isEven) return styles.rowLight;
  return styles.rowDark;
}
