import {SearchOutlined} from "@ant-design/icons";
import {Input} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import React, {FunctionComponent, useMemo} from "react";

import * as Models from "../../../../../core/models";
import {EntityType} from "../../../../../core/models";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./WorkersFilters.module.scss";

type WorkersFiltersProps = {
  selectedTag: Models.WorkersTagsKey;
  setSelectedTag: (tag: Models.WorkersTagsKey) => void;
  setSearchValue: (searchValue: string) => void;
  entityType: Models.EntityType;
  onFilterChange: (action: string, newSettings?: {quickFilter?: Models.WorkersTagsKey; tableSettings?: TableSettings}) => void;
};

export const WorkersFilters: FunctionComponent<WorkersFiltersProps> = ({
  selectedTag,
  setSelectedTag,
  setSearchValue,
  entityType,
  onFilterChange,
}) => {
  const filters = useMemo(() => {
    switch (entityType) {
      case Models.EntityType.Site:
        return (
          <>
            <CheckableTag
              key={Models.WorkersTagsKey.Assigned}
              checked={selectedTag === Models.WorkersTagsKey.Assigned}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Assigned);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Assigned});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-assignedTagText")}
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.Waitlist}
              checked={selectedTag === Models.WorkersTagsKey.Waitlist}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Waitlist);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Waitlist});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-waitlistTagText")}
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.Unenrolled}
              checked={selectedTag === Models.WorkersTagsKey.Unenrolled}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Unenrolled);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Unenrolled});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-unenrolledTagText")}
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.All}
              checked={selectedTag === Models.WorkersTagsKey.All}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.All);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.All});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-allTagText")}
            </CheckableTag>
          </>
        );
      case EntityType.WorkerReportGroup:
        return (
          <>
            <CheckableTag
              key={Models.WorkersTagsKey.Enrolled}
              checked={selectedTag === Models.WorkersTagsKey.Enrolled}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Enrolled);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Enrolled});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-enrolledTagText")}
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.Unenrolled}
              checked={selectedTag === Models.WorkersTagsKey.Unenrolled}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.Unenrolled);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.Unenrolled});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-unenrolledTagText")}
            </CheckableTag>
            <CheckableTag
              key={Models.WorkersTagsKey.All}
              checked={selectedTag === Models.WorkersTagsKey.All}
              onChange={(checked) => {
                if (checked) {
                  setSelectedTag(Models.WorkersTagsKey.All);
                  onFilterChange("quickFilter", {quickFilter: Models.WorkersTagsKey.All});
                }
              }}>
              {WebHelper.formatMessage("WorkersFilters-allTagText")}
            </CheckableTag>
          </>
        );
      default:
        return;
    }
  }, [entityType, selectedTag, setSelectedTag, onFilterChange]);

  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.filters}>{filters}</div>
      <Input
        className={styles.search}
        size="small"
        placeholder={WebHelper.formatMessage("WorkersFilters-searchButtonText")}
        prefix={<SearchOutlined />}
        onChange={(event) => setSearchValue(event.target.value)}
        allowClear
      />
    </div>
  );
};
