import {Typography} from "antd";
import React, {FunctionComponent} from "react";

import {WebHelper} from "../../../web/utils/WebHelper";
import styles from "./UnenrollmentReasonsGlossary.module.scss";

interface UnenrollmentReasonsGlossaryProps {}

interface UnenrollmentGlossary {
  [section: string]: {
    label: string;
    description: string;
  }[];
}

export const UnenrollmentReasonsGlossary: FunctionComponent<UnenrollmentReasonsGlossaryProps> = () => {
  const unenrollmentGlossary: UnenrollmentGlossary = {
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitleUnknown")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelUnknown"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionUnknown"),
      },
    ],
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitleTerminatedQuit")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelTerminatedQuit"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionTerminatedQuit"),
      },
    ],
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitleDiscomfort")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelBack"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionBack"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelHeat"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionHeat"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelConstriction"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionConstriction"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelGeneral"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionGeneral"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelSuitHeavyShoulderPressure"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionSuitHeavyShoulderPressure"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelShoulderStrapIrritationPointPressure"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionShoulderStrapIrritationPointPressure"),
      },
    ],
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitleConvenience")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelDoesntNeedIt"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionDoesntNeedIt"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelTimeHabit"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionTimeHabit"),
      },
    ],
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitlePersonal")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelPreexistingCondition"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionPreexistingCondition"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelOnLeave"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionOnLeave"),
      },
    ],
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitleSuitLimitations")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelLimitsOverheadReaching"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionLimitsOverheadReaching"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelThighWrapsDropping"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionThighWrapsDropping"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelExtraHeightWidthOfSuitOnBack"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionExtraHeightWidthOfSuitOnBack"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelAssistanceTimingWrongJerky"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionAssistanceTimingWrongJerky"),
      },
    ],
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitleJob")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelJobFunctionChange"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionJobFunctionChange"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelPerformanceProductivityConcerns"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionPerformanceProductivityConcerns"),
      },
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelJobIsNotGoodUseCase"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionJobIsNotGoodUseCase"),
      },
    ],
    [WebHelper.formatMessage("UnenrollmentReasonsGlossary-subTitleOther")]: [
      {
        label: WebHelper.formatMessage("UnenrollmentReasonsGlossary-labelOther"),
        description: WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionOther"),
      },
    ],
  };

  return (
    <div className={styles.glossaryWrapper}>
      <div className={styles.glossaryTitle}>{WebHelper.formatMessage("UnenrollmentReasonsGlossary-title")}</div>
      <div className={styles.rowItem}>
        <div>
          <Typography.Text strong>{WebHelper.formatMessage("UnenrollmentReasonsGlossary-exitReason")}</Typography.Text>
        </div>
        <div>
          <Typography.Text strong>{WebHelper.formatMessage("UnenrollmentReasonsGlossary-description")}</Typography.Text>
        </div>
      </div>
      {Object.entries(unenrollmentGlossary).map(([section, items]) => (
        <div className={styles.sectionWrapper}>
          <div className={styles.sectionTitle}>
            <Typography.Text strong>{section}</Typography.Text>
          </div>
          <div>
            {items.map((item, i) => (
              <div className={styles.rowItem} key={i}>
                <div>
                  <Typography.Text>{item.label}</Typography.Text>
                </div>
                <div>
                  <Typography.Text>{item.description}</Typography.Text>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};
