import {DownOutlined, UpOutlined} from "@ant-design/icons";
import {Button, Divider, Typography} from "antd";
import React, {FunctionComponent, useEffect, useRef, useState} from "react";

import styles from "./Collapsible.module.scss";

type CollapsibleProps = {
  open: boolean;
  onToggle: () => void;
  label: string;
  children?: React.ReactNode;
  collapsibleChildren: React.ReactNode;
};

export const Collapsible: FunctionComponent<CollapsibleProps> = ({open, onToggle, label, children, collapsibleChildren}) => {
  const ref = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(open && ref.current ? ref.current.getBoundingClientRect().height : 0);

  useEffect(() => {
    if (open) setHeight(ref.current ? ref.current.getBoundingClientRect().height : 0);
    else setHeight(0);
  }, [open, collapsibleChildren]);

  return (
    <div>
      {children}
      <div className={styles.collapsibleChildrenWrapper} style={{height}}>
        <div ref={ref}>
          <div className={children ? styles.collapsibleChildren : ""}>{collapsibleChildren}</div>
        </div>
      </div>
      {collapsibleChildren && (
        <div>
          <div className={styles.labelWrapper}>
            <Divider className={styles.divider} />
            <Button type="text" onClick={onToggle}>
              <Typography.Text type="secondary">
                {label}
                {open ? <UpOutlined className={styles.toggleButtonArrow} /> : <DownOutlined className={styles.toggleButtonArrow} />}
              </Typography.Text>
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};
