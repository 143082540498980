import {useQuery} from "@tanstack/react-query";
import {Card, Drawer, Space, Typography} from "antd";
import {addDays, startOfDay} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";

import * as Models from "../../../../../core/models";
import {AnalyticsDataStore} from "../../../../../core/stores/AnalyticsDataStore";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {Loading} from "../../../loading/Loading";
import styles from "./DashboardListsDrawers.module.scss";

export enum WorkerStatus {
  Active = "active",
  Inactive = "inactive",
  Waitlist = "waitlist",
}

type DashboardWorkersListsDrawerProps = {
  entity: {type: Models.EntityType; id: string; timezone: string | undefined};
  status: WorkerStatus;
  onClose: () => void;
  open: boolean;
};

export const DashboardWorkersListsDrawer: FunctionComponent<DashboardWorkersListsDrawerProps> = ({entity, status, onClose, open}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const analyticsStore = AnalyticsDataStore.getInstance();
  const workerStore = WorkerDataStore.getInstance();

  const [workers, setWorkers] = useState<Models.WorkerShort[]>([]);

  const analyticsDashboardWorkersQuery = useQuery({
    queryKey: ["DashboardWorkersListsDrawer-fetchAnalyticsDashboardWorkersData", entity],
    queryFn: () => {
      const end_time = zonedTimeToUtc(startOfDay(addDays(new Date(), 1)), "UTC").toISOString();

      return analyticsStore.analyticsDashboardWorkerData({
        accessToken: authenticationStore.state.accessToken!,
        end_time,
        interval_type: Models.AnalyticsInterval.Day,
        interval_count: 8,
        resource_type: entity.type,
        resource_id: entity.id,
      });
    },
  });

  const workersShortQuery = useQuery({
    queryKey: ["DashboardWorkersListsDrawer-fetchWorkersData", entity.id],
    queryFn: () => {
      return workerStore.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        [`${entity.type}_id`]: entity.id,
      });
    },
    enabled: !!entity.id,
  });

  useEffect(() => {
    if (!workersShortQuery.data) return;

    if (!workersShortQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("DashboardWorkersListsDrawer-fetchWorkersError"),
        workersShortQuery.data.correlationId
      );
      return;
    }

    if (!analyticsDashboardWorkersQuery.data) return;

    if (!analyticsDashboardWorkersQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("DashboardWorkersListsDrawer-fetchWorkersError"),
        analyticsDashboardWorkersQuery.data.correlationId
      );
      return;
    }

    const sortedWaitlistWorkers = workersShortQuery.data?.workers
      ? workersShortQuery.data?.workers
          .sort((a, b) => a.displayName.localeCompare(b.displayName))
          .filter((worker) => worker.assignable === true && !worker.device_id)
      : [];
    const unfilteredSortedWorkers = analyticsDashboardWorkersQuery.data.data.worker_data
      ? analyticsDashboardWorkersQuery.data.data.worker_data.sort((a, b) => a.worker.displayName.localeCompare(b.worker.displayName))
      : [];

    switch (status) {
      case WorkerStatus.Waitlist:
        setWorkers(sortedWaitlistWorkers);
        break;
      case WorkerStatus.Active:
        setWorkers(
          unfilteredSortedWorkers
            .filter((analyticsDashboardWorker) => analyticsDashboardWorker.active)
            .map((analyticsDashboardWorker) => analyticsDashboardWorker.worker)
        );
        break;
      case WorkerStatus.Inactive:
        setWorkers(
          unfilteredSortedWorkers
            .filter((analyticsDashboardWorker) => analyticsDashboardWorker.inactive)
            .map((analyticsDashboardWorker) => analyticsDashboardWorker.worker)
        );
        break;
    }
  }, [status, analyticsDashboardWorkersQuery.data, workersShortQuery.data]);

  const drawerTitle = useMemo(() => {
    switch (status) {
      case WorkerStatus.Active:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-titleActive");
      case WorkerStatus.Inactive:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-titleInactive");
      case WorkerStatus.Waitlist:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-titleWaitlist");
    }
  }, [status]);

  const workersCountTitle = useMemo(() => {
    switch (status) {
      case WorkerStatus.Active:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCountTitleActive");
      case WorkerStatus.Inactive:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCountTitleInactive");
      case WorkerStatus.Waitlist:
        return WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCountTitleWaitlist");
    }
  }, [status]);

  const loading = analyticsDashboardWorkersQuery.isFetching;

  return (
    <Drawer open={open} width={WebHelper.drawerWidth} destroyOnClose title={drawerTitle} onClose={onClose}>
      <Space className={styles.cardsWrapper} direction="vertical" size={16}>
        {loading && <Loading />}
        {!loading && (
          <>
            <Card className={styles.card}>
              <Space size={30}>
                <Typography.Text strong>{workersCountTitle}</Typography.Text>
                <Typography.Text>
                  {WebHelper.formatMessage("DashboardWorkersListsDrawer-workersCount", {workers: workers.length})}
                </Typography.Text>
              </Space>
            </Card>

            {workers.length > 0 && (
              <Card className={styles.card}>
                <Space direction="vertical" size={16}>
                  <Typography.Text strong>{WebHelper.formatMessage("DashboardWorkersListsDrawer-workerNameTitle")}</Typography.Text>
                  {workers.map((worker) => (
                    <Typography.Text key={worker.id}>{worker.displayName}</Typography.Text>
                  ))}
                </Space>
              </Card>
            )}
          </>
        )}
      </Space>
    </Drawer>
  );
};
