import {BaseModel} from "./BaseModel";
import {DepartmentInSite} from "./DepartmentInSite";

export class SiteInOrganization extends BaseModel {
  id: string;
  name: string;
  tz_location: string;
  departments: DepartmentInSite[] | null;
  // the following fields are calculated
  numberOfDepartments?: number;
  numberOfDevices?: number;
}
