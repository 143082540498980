import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import {DeviceManagementSelectOptions} from "../device/DeviceAPIClient.types";
import * as Types from "./GatewayAPIClient.types";

export class GatewayAPIClient extends BaseAPIClient {
  static async gateways(request: Types.GatewaysRequest, options: RequestOptions = {}): Promise<Types.GatewaysResponse> {
    const {
      accessToken: {token},
      department_id,
      site_id,
      organization_id,
      serial_number_substring,
    } = request;

    const orgId = organization_id === DeviceManagementSelectOptions.Unallocated ? "null" : organization_id;
    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `gateways/`,
      headers: {token},
      request,
      options,
      params: {
        ...(department_id ? {department_id} : {}),
        ...(site_id ? {site_id} : {}),
        ...(organization_id ? {organization_id: orgId} : {}),
        ...(serial_number_substring ? {serial_number_substring: serial_number_substring} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          gateways: data.map((gateway: Models.Gateway) => Models.Gateway.fromJSON(gateway)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async gateway(request: Types.GatewayRequest, options: RequestOptions = {}): Promise<Types.GatewayResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `gateways/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          gateway: Models.Gateway.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async createGateway(request: Types.CreateGatewayRequest, options: RequestOptions = {}): Promise<Types.CreateGatewayResponse> {
    const {department_id, serial_number, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `gateways/`,
      headers: {token},
      request,
      options,
      data: {department_id, serial_number},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          gateway: Models.Gateway.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async deleteGateway(request: Types.DeleteGatewayRequest, options: RequestOptions = {}): Promise<Types.DeleteGatewayResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `delete`,
      url: `gateways/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      return {
        success: true,
      };
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateGateway(request: Types.UpdateGatewayRequest, options: RequestOptions = {}): Promise<Types.UpdateGatewayResponse> {
    const {id, department_id, serial_number, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `gateways/${id}`,
      headers: {token},
      request,
      options,
      data: {department_id, serial_number, id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          gateway: Models.Gateway.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async allocateGatewayToDepartment(
    request: Types.AllocateGatewayToDepartmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.AllocateGatewayToDepartmentResponse> {
    const {id, department_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `gateways/${id}/department`,
      headers: {token},
      request,
      options,
      data: {department_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          gateway: Models.Gateway.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }
}
