import {useMutation} from "@tanstack/react-query";
import {Modal, Space, Typography} from "antd";
import line from "assets/images/common/Line.svg";
import stepsFinished from "assets/images/common/Steps-Finished.svg";
import stepsFirst from "assets/images/common/Steps-First.svg";
import stepsSecondProgress from "assets/images/common/Steps-Second-Progress.svg";
import stepsSecondWaiting from "assets/images/common/Steps-Second-Waiting.svg";
import stepsThreeProgress from "assets/images/common/Steps-Three-Progress.svg";
import stepsThreeWaiting from "assets/images/common/Steps-Three-Waiting.svg";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../core/stores/DeviceDataStore";
import {AppStore} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";
import styles from "./BulkCreateDevicesModal.module.scss";
import {AllocationError, BulkCreateStepOne, CreateErrorType} from "./steps/bulk-create-step-one/BulkCreateStepOne";
import {BulkCreateStepThree} from "./steps/bulk-create-step-three/BulkCreateStepThree";
import {BulkCreateStepTwo} from "./steps/bulk-create-step-two/BulkCreateStepTwo";

type BulkCreateDevicesModalProps = {
  onClose: () => void;
  open: boolean;
};

export const BulkCreateDevicesModal: FunctionComponent<BulkCreateDevicesModalProps> = observer(({onClose, open}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(Models.CreateBulkDevicesSteps.first);
  const [deviceGroups, setDeviceGroups] = useState<Models.DeviceGroupChecker>();
  const [firmwareVersionIDS, setFirmwareVersionIDS] = useState<string[]>([]);
  const [buttonConfigs, setButtonConfigs] = useState<string[][]>([]);
  const [creationError, setCreationError] = useState<AllocationError | null>(null);
  const [devicesToProcess, setDevicesToProcess] = useState<{mcu_id: string; system_serial_number: string}[]>([]);
  const [departmentIdToAllocate, setDepartmentIdToAllocate] = useState<string | null>(null);
  const [buttonSettingsOptions, setButtonSettingsOptions] = useState<{key: string; label: string; value: string}[][]>([]);

  const resetForm = () => {
    setCurrentStep(Models.CreateBulkDevicesSteps.first);
    setDeviceGroups(undefined);
    setFirmwareVersionIDS([]);
    setButtonConfigs([]);
    setCreationError(null);
  };

  const bulkCreate = useMutation({
    mutationFn: () => {
      const groups = firmwareVersionIDS.map((id, index) => ({
        firmware_version_id: id,
        device_type_id: deviceGroups!.firmware_groups[index].device_types[0].id,
        devices_info: deviceGroups!.firmware_groups[index].device_types[0].devices.map((device) => ({
          mcu_id: device.McuID,
          system_serial_number: device.SerialNumber,
        })),
        button_1_controller_setting_id: buttonConfigs[index]?.[0],
        button_2_controller_setting_id: buttonConfigs[index]?.[1],
        button_3_controller_setting_id: buttonConfigs[index]?.[2],
        override_pending: true,
      }));

      return deviceStore.createDeviceBulk({
        accessToken: authenticationStore.state.accessToken!,
        department_id: departmentIdToAllocate !== "none" ? departmentIdToAllocate : null,
        override_pending: false,
        groups_devices: groups,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("BulkCreateDevicesModal-errorMessage"), response.correlationId);
        return;
      }

      appStore.showMessage(WebHelper.formatMessage("BulkCreateDevicesModal-successMessage"), "success");
      onClose();
      resetForm();
    },
  });

  const deviceCheck = useMutation({
    mutationFn: () => {
      return deviceStore.deviceCheck({
        accessToken: authenticationStore.state.accessToken!,
        devices_serial_numbers: devicesToProcess,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        setCreationError({type: CreateErrorType.SerialNumbersWithErrors, devicesWithErrors: response.devicesGroups});
        return;
      }

      setDeviceGroups(response.devicesGroups);
      setCurrentStep(Models.CreateBulkDevicesSteps.second);
      setButtonConfigs(Array.from({length: response.devicesGroups.firmware_groups.length}, () => new Array(3).fill(null)));
    },
  });

  const handleCancel = () => {
    if (currentStep !== Models.CreateBulkDevicesSteps.first && !isDiscardChangesModalVisible) {
      setIsDiscardChangesModalVisible(true);
    } else {
      setIsDiscardChangesModalVisible(false);
      onClose();
      resetForm();
    }
  };

  const handleNext = async () => {
    if (currentStep === Models.CreateBulkDevicesSteps.first && !creationError) {
      deviceCheck.mutate();
    } else if (currentStep === Models.CreateBulkDevicesSteps.second) {
      setCurrentStep(Models.CreateBulkDevicesSteps.three);
    }
  };

  const handleSave = async () => {
    bulkCreate.mutate();
  };

  const handleBack = () => {
    if (currentStep === Models.CreateBulkDevicesSteps.second) {
      setCurrentStep(Models.CreateBulkDevicesSteps.first);
    } else if (currentStep === Models.CreateBulkDevicesSteps.three) {
      setCurrentStep(Models.CreateBulkDevicesSteps.second);
    }
  };

  const modalFooter = (
    <>
      {
        <Button shape="round" disabled={bulkCreate.isPending} onClick={handleCancel}>
          {WebHelper.formatMessage("Common-cancel")}
        </Button>
      }

      {currentStep !== Models.CreateBulkDevicesSteps.first && (
        <Button shape="round" type={ButtonType.Default} disabled={bulkCreate.isPending} onClick={handleBack}>
          {WebHelper.formatMessage("Common-back")}
        </Button>
      )}
      {currentStep !== Models.CreateBulkDevicesSteps.three && (
        <Button
          disabled={
            !_.isEmpty(creationError) ||
            _.isEmpty(devicesToProcess) ||
            (currentStep === Models.CreateBulkDevicesSteps.second &&
              buttonConfigs.some((config) => config.some((id) => _.isNil(id) || id === "")))
          }
          type={ButtonType.Primary}
          shape="round"
          loading={deviceCheck.isPending}
          onClick={handleNext}>
          {WebHelper.formatMessage("Common-next")}
        </Button>
      )}
      {currentStep === Models.CreateBulkDevicesSteps.three && (
        <Button type={ButtonType.Primary} shape="round" loading={bulkCreate.isPending} onClick={handleSave}>
          {WebHelper.formatMessage("Common-confirm")}
        </Button>
      )}
    </>
  );

  return (
    <>
      <Modal
        open={open}
        centered
        destroyOnClose
        title={WebHelper.formatMessage("BulkCreateDevicesModal-modalTitle")}
        onCancel={handleCancel}
        onOk={handleNext}
        className={styles.modal}
        width={"fit-content"}
        footer={modalFooter}>
        <Space className={styles.stepsWrapper}>
          <Space className={styles.stepWrapper}>
            <Space>
              <img src={currentStep === Models.CreateBulkDevicesSteps.first ? stepsFirst : stepsFinished} alt="" />
              <Typography.Text className={styles.stepTitle}>
                {currentStep === Models.CreateBulkDevicesSteps.first
                  ? WebHelper.formatMessage("BulkCreateDevicesModal-inProgress")
                  : WebHelper.formatMessage("BulkCreateDevicesModal-finished")}
              </Typography.Text>
            </Space>
            <Typography
              className={[
                styles.stepTitle,
                currentStep !== Models.CreateBulkDevicesSteps.first ? styles.waitingText : "",
                styles.reasonText,
              ].join(" ")}>
              {WebHelper.formatMessage("BulkCreateDevicesModal-selectDevices")}
            </Typography>
          </Space>
          <img src={line} alt="" className={styles.lineSeparator} />
          <Space className={styles.stepWrapper}>
            <Space>
              <img
                src={
                  currentStep === Models.CreateBulkDevicesSteps.first
                    ? stepsSecondWaiting
                    : currentStep === Models.CreateBulkDevicesSteps.second
                      ? stepsSecondProgress
                      : stepsFinished
                }
                alt=""
              />
              <Typography.Text
                className={[currentStep === Models.CreateBulkDevicesSteps.first ? styles.waitingText : "", styles.stepTitle].join(" ")}>
                {currentStep === Models.CreateBulkDevicesSteps.first
                  ? WebHelper.formatMessage("BulkCreateDevicesModal-waiting")
                  : currentStep === Models.CreateBulkDevicesSteps.second
                    ? WebHelper.formatMessage("BulkCreateDevicesModal-inProgress")
                    : WebHelper.formatMessage("BulkCreateDevicesModal-finished")}
              </Typography.Text>
            </Space>
            <Typography.Text
              className={`${currentStep !== Models.CreateBulkDevicesSteps.second ? styles.waitingText : ""} ${styles.reasonText}`}>
              {WebHelper.formatMessage("BulkCreateDevicesModal-configuration")}
            </Typography.Text>
          </Space>
          <img src={line} alt="" className={styles.lineSeparator} />
          <Space className={styles.stepWrapper}>
            <Space>
              <img src={currentStep !== Models.CreateBulkDevicesSteps.three ? stepsThreeWaiting : stepsThreeProgress} alt="" />
              <Typography.Text className={currentStep !== Models.CreateBulkDevicesSteps.three ? styles.waitingText : ""}>
                {currentStep !== Models.CreateBulkDevicesSteps.three
                  ? WebHelper.formatMessage("BulkCreateDevicesModal-waiting")
                  : WebHelper.formatMessage("BulkCreateDevicesModal-inProgress")}
              </Typography.Text>
            </Space>
            <Typography.Text
              className={`${currentStep !== Models.CreateBulkDevicesSteps.three ? styles.waitingText : ""} ${styles.reasonText}`}>
              {WebHelper.formatMessage("BulkCreateDevicesModal-confirmation")}
            </Typography.Text>
          </Space>
        </Space>
        <div className={styles.content}>
          {currentStep === Models.CreateBulkDevicesSteps.first && (
            <BulkCreateStepOne
              updateDevicesToProcess={setDevicesToProcess}
              updateDevicesError={setCreationError}
              devicesError={creationError}
            />
          )}
          {currentStep === Models.CreateBulkDevicesSteps.second && deviceGroups && (
            <BulkCreateStepTwo
              deviceGroups={deviceGroups}
              firmwareVersionIDS={firmwareVersionIDS}
              updateFirmwareVersionIDS={setFirmwareVersionIDS}
              updateButtonConfigs={setButtonConfigs}
              buttonConfigs={buttonConfigs}
              updateButtonSettingsOptions={setButtonSettingsOptions}
              buttonSettingsOptions={buttonSettingsOptions}
            />
          )}
          {currentStep === Models.CreateBulkDevicesSteps.three && deviceGroups && (
            <BulkCreateStepThree updateDepartmentId={setDepartmentIdToAllocate} />
          )}
        </div>
      </Modal>
      <DiscardChangesModal
        onDiscard={handleCancel}
        onClose={() => setIsDiscardChangesModalVisible(false)}
        open={isDiscardChangesModalVisible}
      />
    </>
  );
});
