import {Modal, Space} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import {WebHelper} from "../../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../../common/button/Button";

type OverridePendingUpdateConfirmationModalProps = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

export const OverridePendingUpdateConfirmationModal: FunctionComponent<OverridePendingUpdateConfirmationModalProps> = observer(
  ({open, onOk, onCancel}) => {
    return (
      <Modal
        centered
        zIndex={1001}
        destroyOnClose={true}
        open={open}
        title={WebHelper.formatMessage("OverridePendingUpdateConfirmationModal-title")}
        onCancel={onCancel}
        footer={
          <Space>
            <Button shape="round" onClick={onCancel}>
              {WebHelper.formatMessage("Common-cancel")}
            </Button>
            <Button type={ButtonType.Primary} shape="round" onClick={onOk}>
              {WebHelper.formatMessage("Common-confirm")}
            </Button>
          </Space>
        }>
        {WebHelper.formatMessage("OverridePendingUpdateConfirmationModal-message")}
      </Modal>
    );
  }
);
