import {useMutation, useQuery} from "@tanstack/react-query";
import {Modal, Radio, Select, Space, Typography} from "antd";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useState} from "react";

import * as Models from "../../../../../core/models";
import {UnenrollWorkerMainReason, UnenrollWorkerOtherReason} from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../../core/stores/DeviceDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint, SegmentResourceType} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";
import {UnenrollmentReasons} from "../../../unenrollment-reasons/UnenrollmentReasons";
import styles from "./WorkerNoDevice.module.scss";

enum RadioOptions {
  LeaveUnassigned = "leaveUnassigned",
  AssignNewDevice = "assignNewDevice",
  UnenrollWorker = "unenrollWorker",
}

export type WorkerNoDeviceProps = {
  worker: Models.BasicWorker;
  departmentId: string;
  onOk: () => void;
  open: boolean;
  onClose: () => void;
  entryPoint: SegmentEntryPoint;
};

export const WorkerNoDevice: FunctionComponent<WorkerNoDeviceProps> = observer(
  ({worker, departmentId, onOk, open, onClose, entryPoint}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();
    const deviceStore = DeviceDataStore.getInstance();
    const workerStore = WorkerDataStore.getInstance();

    const [selectedOption, setSelectedOption] = useState<RadioOptions>(RadioOptions.LeaveUnassigned);
    const [selectedUnenrollWorkerReason, setSelectedUnenrollWorkerReason] = useState<Models.UnenrollWorkerMainReason>(
      Models.UnenrollWorkerMainReason.Unknown
    );
    const [deviceIdToAssign, setDeviceIdToAssign] = useState<string>();
    const [availableDevices, setAvailableDevices] = useState<Models.DeviceShort[]>([]);
    const [unenrollmentReasons, setUnenrollmentReasons] = useState<CheckboxValueType[]>([]);
    const [otherUnenrollmentReason, setOtherUnenrollmentReason] = useState<string | undefined>();
    const [otherUnenrollmentReasonSecondary, setOtherUnenrollmentReasonSecondary] = useState<string | undefined>();
    const [currentStep, setCurrentStep] = useState(Models.UnenrollmentStepsFlow.zero);
    const [selectedOtherMainReason, setSelectedOtherMainReason] = useState<UnenrollWorkerOtherReason | undefined>(undefined);
    const [glossaryPreviousStep, setGlossaryPreviousStep] = useState(Models.UnenrollmentStepsFlow.first);

    const {isFetching: devicesQueryIsFetching, refetch: devicesQueryRefetch} = useQuery({
      queryKey: ["WorkerNoDevice-fetchData"],
      queryFn: () => deviceStore.devicesShort({accessToken: authenticationStore.state.accessToken!, department_id: departmentId}),
      enabled: false,
    });

    const handleOpenGlossary = () => {
      setGlossaryPreviousStep(currentStep);
      setCurrentStep(Models.UnenrollmentStepsFlow.glossary);
    };

    const unenrollWorkerMutation = useMutation({
      mutationFn: () =>
        workerStore.assignDeviceToWorker({
          accessToken: authenticationStore.state.accessToken!,
          worker_id: worker.id,
          assignable: false,
          primary_unenrollment_reason:
            selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other
              ? otherUnenrollmentReason ?? selectedOtherMainReason
              : selectedUnenrollWorkerReason,
          unenrollment_reasons:
            selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other
              ? otherUnenrollmentReasonSecondary
                ? (unenrollmentReasons as string[]).concat(otherUnenrollmentReasonSecondary)
                : (unenrollmentReasons as string[])
              : undefined,
        }),
    });

    const assignWorkerToDeviceMutation = useMutation({
      mutationFn: (deviceId: string) =>
        deviceStore.assignWorkerToDevice({
          accessToken: authenticationStore.state.accessToken!,
          device_id: deviceId,
          worker_id: worker.id,
          assignable: true,
        }),
    });

    const fetchAvailableDevices = useCallback(async () => {
      const response = await devicesQueryRefetch();
      if (response.data?.success) {
        const availableDevicesResponse = response.data.devices.filter(
          (device) => device.assignable && _.isNil(device.assigned_worker) && !device.archived_at
        );
        setAvailableDevices(availableDevicesResponse);
        if (_.isNil(availableDevicesResponse.find((device) => device.id === deviceIdToAssign))) setDeviceIdToAssign(undefined);
      }
    }, [devicesQueryRefetch, deviceIdToAssign]);

    const handleOk = useCallback(async () => {
      if (selectedOption === RadioOptions.AssignNewDevice && deviceIdToAssign) {
        assignWorkerToDeviceMutation.mutate(deviceIdToAssign, {
          onSuccess: (assignResponse) => {
            if (assignResponse.success) {
              appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceAssignment, {
                departmentID: assignResponse.device?.department?.id,
                orgID: assignResponse.device?.organization?.id,
                siteID: assignResponse.device.site?.id,
                resourceType: SegmentResourceType.DEVICE,
                resourceID: assignResponse.device.id,
                endAssigned: !!assignResponse.device.assigned_worker,
                startAssigned: true,
                entryPoint: SegmentEntryPoint.EDIT_DEVICE_MODAL,
              });

              onClose();
              onOk();
              appStore.showMessage(
                WebHelper.formatMessage("WorkerNoDevice-assignNewDeviceSuccessMessage", {
                  workerName: worker.displayName,
                  deviceTag: assignResponse.device.device_tag,
                }),
                "success"
              );
            } else WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerNoDevice-assignErrorMessage"), assignResponse.correlationId);
            return;
          },
        });
      } else if (selectedOption === RadioOptions.UnenrollWorker) {
        unenrollWorkerMutation.mutate(undefined, {
          onSuccess: (assignResponse) => {
            if (!assignResponse.success) {
              WebHelper.showErrorMessage(
                WebHelper.formatMessage("UnenrollmentReason-unenrollmentReasonError"),
                assignResponse.correlationId
              );
              return;
            } else {
              appStore.sendAnalyticTrack(SegmentKey.ChangeWorkerEnrollmentStatus, {
                departmentID: assignResponse.worker.department?.id,
                siteID: assignResponse.worker.site?.id,
                orgID: assignResponse.worker.organization?.id,
                endAssignable: assignResponse.worker.assignable,
                entryPoint,
              });

              appStore.showMessage(
                WebHelper.formatMessage("WorkerNoDevice-unenrollWorkerSuccessMessage", {workerName: assignResponse.worker.displayName}),
                "success"
              );
              onClose();
            }
          },
        });
      } else if (selectedOption === RadioOptions.LeaveUnassigned) {
        appStore.showMessage(
          WebHelper.formatMessage("WorkerNoDevice-leaveUnassignedSuccessMessage", {workerName: worker.displayName}),
          "success"
        );
        onClose();
      }
    }, [
      selectedOption,
      deviceIdToAssign,
      assignWorkerToDeviceMutation,
      onClose,
      onOk,
      appStore,
      worker.displayName,
      unenrollWorkerMutation,
      entryPoint,
    ]);

    const handleNext = () => {
      if (currentStep === Models.UnenrollmentStepsFlow.zero) {
        setCurrentStep(Models.UnenrollmentStepsFlow.first);
      } else if (currentStep === Models.UnenrollmentStepsFlow.first) setCurrentStep(Models.UnenrollmentStepsFlow.second);
    };

    const handleBack = () => {
      if (currentStep === Models.UnenrollmentStepsFlow.first) {
        setCurrentStep(Models.UnenrollmentStepsFlow.zero);
      }
      if (currentStep === Models.UnenrollmentStepsFlow.second) {
        setCurrentStep(Models.UnenrollmentStepsFlow.first);
      }
      if (currentStep === Models.UnenrollmentStepsFlow.glossary) {
        setCurrentStep(glossaryPreviousStep);
        setGlossaryPreviousStep(Models.UnenrollmentStepsFlow.first);
      }
    };

    return (
      <Modal
        centered
        open={open}
        styles={{body: currentStep === Models.UnenrollmentStepsFlow.zero ? {} : {height: "70vh", overflowY: "scroll"}}}
        destroyOnClose={true}
        title={WebHelper.formatMessage("WorkerNoDevice-title")}
        footer={
          <>
            {currentStep !== Models.UnenrollmentStepsFlow.glossary && (
              <Button shape="round" disabled={assignWorkerToDeviceMutation.isPending} onClick={onClose}>
                {WebHelper.formatMessage("Common-cancel")}
              </Button>
            )}
            {selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other &&
              currentStep !== Models.UnenrollmentStepsFlow.zero && (
                <Button
                  shape="round"
                  type={currentStep === Models.UnenrollmentStepsFlow.glossary ? ButtonType.Primary : ButtonType.Default}
                  disabled={assignWorkerToDeviceMutation.isPending}
                  onClick={handleBack}>
                  {WebHelper.formatMessage("Common-back")}
                </Button>
              )}
            {currentStep !== Models.UnenrollmentStepsFlow.second &&
              currentStep !== Models.UnenrollmentStepsFlow.glossary &&
              selectedUnenrollWorkerReason === Models.UnenrollWorkerMainReason.Other && (
                <Button
                  disabled={
                    currentStep === Models.UnenrollmentStepsFlow.first && !selectedOtherMainReason && !otherUnenrollmentReason?.trim()
                  }
                  type={ButtonType.Primary}
                  shape="round"
                  loading={assignWorkerToDeviceMutation.isPending}
                  onClick={handleNext}>
                  {WebHelper.formatMessage("Common-next")}
                </Button>
              )}
            {(currentStep === Models.UnenrollmentStepsFlow.second ||
              selectedUnenrollWorkerReason !== Models.UnenrollWorkerMainReason.Other) && (
              <Button type={ButtonType.Primary} shape="round" loading={assignWorkerToDeviceMutation.isPending} onClick={handleOk}>
                {WebHelper.formatMessage("Common-save")}
              </Button>
            )}
          </>
        }
        onCancel={onClose}>
        <Space className={styles.wrapper}>
          {currentStep === Models.UnenrollmentStepsFlow.zero && (
            <>
              <div
                className={styles.subtitle}
                dangerouslySetInnerHTML={{
                  __html: WebHelper.parseMarkdown(WebHelper.formatMessage("WorkerNoDevice-firstSubtitle", {name: worker?.displayName})),
                }}
              />

              <Typography.Text className={styles.subtitle}>{WebHelper.formatMessage("WorkerNoDevice-secondSubtitle")}</Typography.Text>

              <Radio.Group
                className={styles.radioGroup}
                value={selectedOption}
                disabled={assignWorkerToDeviceMutation.isPending || unenrollWorkerMutation.isPending}
                onChange={(event) => {
                  setSelectedOption(event.target.value);
                  setDeviceIdToAssign(undefined);
                  setSelectedUnenrollWorkerReason(Models.UnenrollWorkerMainReason.Unknown);
                  setOtherUnenrollmentReason(undefined);
                  setUnenrollmentReasons([]);
                }}>
                <Space direction="vertical" className={styles.radioWrapper}>
                  <Radio value={RadioOptions.LeaveUnassigned} className={styles.optionWrapper}>
                    <Typography.Text>{WebHelper.formatMessage("WorkerNoDevice-leaveUnassigned")}</Typography.Text>
                  </Radio>

                  <Radio value={RadioOptions.AssignNewDevice} className={styles.optionWrapper}>
                    <Typography.Text>{WebHelper.formatMessage("WorkerNoDevice-assignNewDevice")}</Typography.Text>
                  </Radio>

                  {selectedOption === RadioOptions.AssignNewDevice && (
                    <div className={styles.selectWrapper}>
                      <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("WorkerNoDevice-device")}</Typography.Text>
                      <Select
                        value={deviceIdToAssign}
                        disabled={
                          assignWorkerToDeviceMutation.isPending ||
                          unenrollWorkerMutation.isPending ||
                          selectedOption !== RadioOptions.AssignNewDevice
                        }
                        className={styles.optionSelector}
                        optionFilterProp="label"
                        optionLabelProp="label"
                        onSelect={setDeviceIdToAssign}
                        showSearch
                        onDropdownVisibleChange={(open) => {
                          if (open) fetchAvailableDevices();
                        }}
                        dropdownRender={(node) => (devicesQueryIsFetching ? WebHelper.LoadingSpin : node)}
                        placeholder={WebHelper.formatMessage("WorkerNoDevice-assignNewDevice-placeholder")}
                        onClick={(event: any) => {
                          event.preventDefault();
                          event.stopPropagation();
                        }}
                        options={availableDevices.map(({id, device_tag}) => ({
                          key: id,
                          label: device_tag,
                          value: id,
                        }))}
                      />
                    </div>
                  )}

                  <Radio value={RadioOptions.UnenrollWorker} className={styles.optionWrapper} data-cy="unenrollReasonOption">
                    <Typography.Text>{WebHelper.formatMessage("WorkerNoDevice-unenrollWorker")}</Typography.Text>
                  </Radio>

                  {selectedOption === RadioOptions.UnenrollWorker && (
                    <>
                      <div className={styles.selectWrapper}>
                        <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("WorkerNoDevice-reason")}</Typography.Text>
                        <Select
                          value={selectedUnenrollWorkerReason}
                          defaultValue={Models.UnenrollWorkerMainReason.Unknown}
                          disabled={
                            assignWorkerToDeviceMutation.isPending ||
                            unenrollWorkerMutation.isPending ||
                            selectedOption !== RadioOptions.UnenrollWorker
                          }
                          className={styles.optionSelector}
                          optionFilterProp="label"
                          optionLabelProp="label"
                          onSelect={setSelectedUnenrollWorkerReason}
                          onClick={(event: any) => {
                            event.preventDefault();
                            event.stopPropagation();
                          }}
                          options={Object.values(Models.UnenrollWorkerMainReason).map((option) => {
                            return {
                              key: option,
                              value: option,
                              label: WebHelper.getUnenrollWorkerMainReasonLabel(option),
                            };
                          })}
                          data-cy="unenrollReasonSelector"
                        />
                      </div>
                    </>
                  )}
                </Space>
              </Radio.Group>
            </>
          )}
          {selectedUnenrollWorkerReason === UnenrollWorkerMainReason.Other && (
            <UnenrollmentReasons
              worker={worker}
              setGlossaryStep={handleOpenGlossary}
              disabled={unenrollWorkerMutation.isPending}
              currentStep={currentStep}
              onCheckedValuesChange={setUnenrollmentReasons}
              onOtherReasonValueChange={
                currentStep === Models.UnenrollmentStepsFlow.first ? setOtherUnenrollmentReason : setOtherUnenrollmentReasonSecondary
              }
              otherReasonValue={
                currentStep === Models.UnenrollmentStepsFlow.first ? otherUnenrollmentReason : otherUnenrollmentReasonSecondary
              }
              mainReason={selectedOtherMainReason}
              onChangeOtherMainReason={setSelectedOtherMainReason}
              otherMainReasonValue={selectedOtherMainReason}
            />
          )}
        </Space>
      </Modal>
    );
  }
);
