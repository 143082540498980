import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {SiteInOrganization} from "./SiteInOrganization";

export class Organization extends BaseModel {
  id: string;
  name: string;
  address: string;
  icon_url: string;
  short_name: string;
  sites: SiteInOrganization[] | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  // the following fields are calculated
  numberOfSites?: number;
  numberOfDepartments?: number;
  numberOfDevices?: number;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
      address: observable,
      icon_url: observable,
      short_name: observable,
      sites: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: Organization, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.sites) {
      object.sites = json.sites.map((site: SiteInOrganization) => SiteInOrganization.fromJSON(site));
    }
  }
}
