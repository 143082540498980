import {InfoCircleFilled} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Modal, Radio, Typography, Table} from "antd";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {OrganizationDataStore} from "../../../../../core/stores/OrganizationDataStore";
import {UserDataStore} from "../../../../../core/stores/UserDataStore";
import {DiscardChangesModal} from "../../../../components/discard-changes-modal/DiscardChangesModal";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./EditCustomPermissionsModal.module.scss";

type EditCustomPermissionsModalProps = {
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
  user: Models.User;
};

type TableDataModel = {id: string; name: string; scope: Models.UserScope; permission: Models.UserAccessLevel | null};

export const EditCustomPermissionsModal: FunctionComponent<EditCustomPermissionsModalProps> = observer(({open, onOk, onCancel, user}) => {
  const organizationStore = OrganizationDataStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const userStore = UserDataStore.getInstance();

  const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);
  const [data, setData] = useState<TableDataModel[] | undefined>();

  const organizationQuery = useQuery({
    queryKey: ["EditCustomPermissionsModal-fetchOrganizationData", user],
    queryFn: () => {
      if (user.organization_id) {
        return organizationStore.organization({
          accessToken: authenticationStore.state.accessToken!,
          id: user.organization_id,
        });
      } else return;
    },
    enabled: !!user.organization_id,
  });

  const changeUserAccessLevelMultipleMutation = useMutation({
    mutationFn: ({newPermissions}: {newPermissions: Models.UserPermission[]}) => {
      return userStore.changeUserPermissionMultiple({
        accessToken: authenticationStore.state.accessToken!,
        permissions: newPermissions,
      });
    },
    onSuccess: (response) => {
      if (response.success) {
        onOk();
      } else {
        WebHelper.showErrorMessage(WebHelper.formatMessage("UserPermissions-changeAccessLevelError"), response.correlationId);
      }
    },
  });

  useEffect(() => {
    if (!organizationQuery.data) return;

    if (!organizationQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("EditCustomPermissionsModal-errorOrganizationMessage"),
        organizationQuery.data.correlationId
      );
      return;
    }

    const {organization} = organizationQuery.data;

    const isCustomAccessLevel = user.accessLevel === Models.UserAccessLevel.Custom;

    const orgPermission = user.permissions?.find((permission) => permission.resource_id === organization.id);

    const dataTable: TableDataModel[] = [
      {
        id: organization.id,
        name: organization.name,
        scope: Models.UserScope.Organization,
        permission: isCustomAccessLevel ? orgPermission?.level ?? null : null,
      },
    ].concat(
      (organization.sites || []).reduce<TableDataModel[]>((acc, site) => {
        const sitePermission = user.permissions?.find((permission) => permission.resource_id === site.id);

        acc.push({
          id: site.id,
          name: site.name,
          scope: Models.UserScope.Site,
          permission: isCustomAccessLevel ? sitePermission?.level ?? null : null,
        });

        const departmentItems = site.departments?.map((department) => {
          const departmentPermission = user.permissions?.find((permission) => permission.resource_id === department.id);

          return {
            id: department.id,
            name: department.name,
            scope: Models.UserScope.Department,
            permission: isCustomAccessLevel ? departmentPermission?.level ?? null : null,
          };
        });

        if (departmentItems) acc = acc.concat(departmentItems);

        return acc;
      }, [])
    );
    setData(dataTable);
  }, [organizationQuery.data, user.accessLevel, user.permissions]);

  const findParent = (index: number) => {
    if (data) {
      const currentScope = data[index].scope;
      const parentScope = currentScope === Models.UserScope.Department ? Models.UserScope.Site : Models.UserScope.Organization;
      for (let i = index - 1; i >= 0; i--) {
        if (data[i].scope === parentScope) {
          return data[i];
        }
      }
    }
    return null;
  };

  const findOrg = (index: number) => {
    if (data) {
      const parentScope = Models.UserScope.Organization;
      for (let i = index - 1; i >= 0; i--) {
        if (data[i].scope === parentScope) {
          return data[i];
        }
      }
    }
    return null;
  };

  const handleCancel = () => {
    if (changeUserAccessLevelMultipleMutation.isPending) return;

    if (!isDiscardChangesModalVisible) setIsDiscardChangesModalVisible(true);
    else {
      setIsDiscardChangesModalVisible(false);
      onCancel();
    }
  };

  const handleRadioChange = (id: string, newPermission: Models.UserAccessLevel) => {
    let elementScope: Models.UserScope;
    let parentFound = false;

    const newData = data?.map((item: TableDataModel) => {
      if (item.id === id) {
        elementScope = item.scope;
        parentFound = true;
        return {...item, permission: newPermission};
      } else if (parentFound) {
        if (elementScope !== item.scope && elementScope !== Models.UserScope.Department) {
          return {
            ...item,
            permission:
              newPermission === Models.UserAccessLevel.None
                ? Models.UserAccessLevel.None
                : newPermission === Models.UserAccessLevel.Admin
                  ? Models.UserAccessLevel.Admin
                  : null,
          };
        } else parentFound = false;
      }
      return item;
    });
    setData(newData);
  };

  return (
    <>
      <Modal
        centered
        zIndex={1001}
        destroyOnClose={true}
        open={open}
        title={WebHelper.formatMessage("EditCustomPermissionsModal-title")}
        okText={WebHelper.formatMessage("Common-save")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={() => {
          if (data) {
            const permissions = data.map((item: any) => ({
              user_id: user.id,
              scope: item.scope,
              resource_id: item.id,
              level: item.permission,
            }));
            changeUserAccessLevelMultipleMutation.mutate({newPermissions: permissions});
          }
        }}
        onCancel={handleCancel}
        okButtonProps={{
          shape: "round",
          disabled: !data?.every((element) => element.permission !== null),
          loading: changeUserAccessLevelMultipleMutation.isPending,
        }}
        cancelButtonProps={{
          shape: "round",
          disabled: changeUserAccessLevelMultipleMutation.isPending,
        }}>
        <div className={styles.container}>
          <Typography.Text>{WebHelper.formatMessage("EditCustomPermissionsModal-subtitle")}</Typography.Text>
          <div className={styles.tableContainer}>
            <Table
              loading={organizationQuery.isPending}
              dataSource={data}
              pagination={false}
              rowClassName={(record) =>
                `${record.scope === Models.UserScope.Organization ? styles.orgRow : record.scope === Models.UserScope.Site ? styles.siteRow : ""}`
              }
              columns={[
                {
                  dataIndex: "name",
                },
                {
                  title: WebHelper.formatMessage("EditCustomPermissionsModal-admin"),
                  key: "admin",
                  render: (__, record, index) => {
                    const recordParent = findParent(index);
                    return (
                      <Radio
                        checked={record.permission === Models.UserAccessLevel.Admin}
                        disabled={
                          record.scope !== Models.UserScope.Organization
                            ? !_.isNil(recordParent?.permission)
                              ? recordParent?.permission === Models.UserAccessLevel.None
                              : true
                            : false
                        }
                        onChange={() => handleRadioChange(record.id, Models.UserAccessLevel.Admin)}></Radio>
                    );
                  },
                },
                {
                  title: WebHelper.formatMessage("EditCustomPermissionsModal-manager"),
                  key: "manager",
                  render: (__, record, index) => {
                    const recordParent = findParent(index);
                    return (
                      <Radio
                        disabled={
                          record.scope !== Models.UserScope.Organization
                            ? !_.isNil(recordParent?.permission)
                              ? recordParent?.permission === Models.UserAccessLevel.Admin ||
                                recordParent?.permission === Models.UserAccessLevel.None
                              : true
                            : false
                        }
                        checked={record.permission === Models.UserAccessLevel.Manager}
                        onChange={() => handleRadioChange(record.id, Models.UserAccessLevel.Manager)}></Radio>
                    );
                  },
                },
                {
                  title: WebHelper.formatMessage("EditCustomPermissionsModal-readonly"),
                  key: "readonly",
                  render: (__, record, index) => {
                    const recordParent = findParent(index);
                    return (
                      <Radio
                        disabled={
                          record.scope !== Models.UserScope.Organization
                            ? !_.isNil(recordParent?.permission)
                              ? recordParent?.permission === Models.UserAccessLevel.Admin ||
                                recordParent?.permission === Models.UserAccessLevel.Manager ||
                                recordParent?.permission === Models.UserAccessLevel.None
                              : true
                            : false
                        }
                        checked={record.permission === Models.UserAccessLevel.ReadOnly}
                        onChange={() => handleRadioChange(record.id, Models.UserAccessLevel.ReadOnly)}></Radio>
                    );
                  },
                },
                {
                  title: WebHelper.formatMessage("EditCustomPermissionsModal-none"),
                  key: "none",
                  render: (__, record, index) => {
                    const recordParent = findParent(index);
                    const recordOrg = findOrg(index);
                    return (
                      <Radio
                        disabled={
                          record.scope === Models.UserScope.Organization ||
                          recordParent?.permission === Models.UserAccessLevel.Admin ||
                          recordOrg?.permission === Models.UserAccessLevel.Admin
                        }
                        checked={record.permission === Models.UserAccessLevel.None}
                        onChange={() => handleRadioChange(record.id, Models.UserAccessLevel.None)}></Radio>
                    );
                  },
                },
              ]}
            />
          </div>
          {!data?.every((element) => element.permission !== null) && (
            <div className={styles.infoMessage}>
              <InfoCircleFilled />
              <Typography.Text>{WebHelper.formatMessage("EditCustomPermissionsModal-infoMessage")}</Typography.Text>
            </div>
          )}
        </div>
      </Modal>
      <DiscardChangesModal
        onDiscard={handleCancel}
        onClose={() => setIsDiscardChangesModalVisible(false)}
        open={isDiscardChangesModalVisible}
      />
    </>
  );
});
