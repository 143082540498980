import {CognitoUserSession} from "amazon-cognito-identity-js";
import {Auth} from "aws-amplify";
import axios, {AxiosError, AxiosInstance, AxiosPromise, AxiosRequestConfig, AxiosResponse, Canceler, ResponseType} from "axios";

import {AppConfig} from "../AppConfig";
import * as Errors from "../Errors";
import * as Models from "../models";
import {CoreHelper} from "../utils/CoreHelper";
import {
  FailedFetchResponse,
  FailedCreateResponse,
  FailedUpdateResponse,
  BackendVersionRequest,
  BackendVersionResponse,
  AuthenticatedRequest,
} from "./BaseAPIClient.types";

export type RequestMethod = "head" | "get" | "delete" | "patch" | "post" | "put";

export type RequestConfig<TRequest, TRequestData = {}> = {
  url: string;
  request: TRequest;
  requestMethod: RequestMethod;
  params?: Record<string, string>;
  data?: TRequestData;
  timeout?: number;
  headers?: GetHeadersOptions;
  options?: RequestOptions;
  responseType?: ResponseType;
};

export type RequestOptions = {
  cancel?: Canceler;
  onUploadProgress?: (loaded: number, total: number) => void;
  onDownloadProgress?: (loaded: number, total: number) => void;
};

export type BaseSuccessfulResponse<TData> = {
  success: true;
  rawResponse: AxiosResponse<TData>;
};
export type BaseFailedResponse = {
  success: false;
  rawResponse: AxiosError;
};
export type BaseResponse<TRequest> =
  | BaseSuccessfulResponse<any>
  | (BaseFailedResponse & {
      request: TRequest;
    });

export type ConfigureClientOptions = {
  userAgent: string;
  shouldRefreshTokens: () => boolean;
  onRefreshTokens: (session: CognitoUserSession, sub?: string) => Models.AccessToken;
};

export type GetHeadersOptions = {
  token?: string;
};

export class BaseAPIClient {
  static client: AxiosInstance;
  static options: ConfigureClientOptions;

  static configureClient(options: ConfigureClientOptions) {
    this.options = options;
    this.client = axios.create({
      baseURL: AppConfig.Settings.Server.apiClient.baseUrl,
      timeout: AppConfig.Settings.Server.apiClient.timeout,
      headers: {
        "X-User-Agent": options.userAgent,
      },
    });
  }

  static getHeaders(options: GetHeadersOptions): Record<string, string> {
    const result: Record<string, string> = {};

    if (options.token) result["Authorization"] = `Bearer ${options.token}`;

    return result;
  }

  static async request<TRequest>(config: RequestConfig<TRequest>): Promise<BaseResponse<TRequest>> {
    const {options = {}, url, request, requestMethod, data} = config;

    const axiosConfig: AxiosRequestConfig = {};

    const controller = new AbortController();
    axiosConfig.signal = controller.signal;
    options.cancel = () => controller.abort();

    if (options.onDownloadProgress) {
      const {onDownloadProgress} = options;

      axiosConfig.onDownloadProgress = (event) => {
        if (event.total && event.total > 0) {
          onDownloadProgress(event.loaded, event.total);
        } else {
          onDownloadProgress(event.loaded, 0);
        }
      };
    }

    if (options.onUploadProgress) {
      const {onUploadProgress} = options;

      axiosConfig.onUploadProgress = (event) => {
        if (event.total && event.total > 0) {
          onUploadProgress(event.loaded, event.total);
        } else {
          onUploadProgress(event.loaded, 0);
        }
      };
    }

    axiosConfig["apiClientConfig" as keyof typeof axiosConfig] = config;

    if ((request as TRequest & AuthenticatedRequest).accessToken && this.options.shouldRefreshTokens()) {
      // If session is expired, calls Amplify and refreshes token
      const session = await Auth.currentSession();
      if (session.getAccessToken().getJwtToken() !== (request as TRequest & AuthenticatedRequest).accessToken.token) {
        const {attributes}: {attributes: {email: string; email_verified: boolean; sub: string}; username: string} =
          await Auth.currentAuthenticatedUser();
        const newAccessToken = this.options.onRefreshTokens(session, attributes.sub);

        // Refresh token for current request
        (request as TRequest & AuthenticatedRequest).accessToken = newAccessToken;
        config.headers && (config.headers.token = newAccessToken.token);
      }
    }

    if (config.timeout) axiosConfig.timeout = config.timeout;
    if (config.headers) axiosConfig.headers = this.getHeaders(config.headers || {});
    if (config.params) axiosConfig.params = config.params;
    if (config.responseType) axiosConfig.responseType = config.responseType;

    let axiosPromise: AxiosPromise;

    switch (requestMethod) {
      case "delete":
        axiosPromise = this.client.delete(url, axiosConfig);
        break;
      case "head":
        axiosPromise = this.client.head(url, axiosConfig);
        break;
      case "patch":
        axiosPromise = this.client.patch(url, data, axiosConfig);
        break;
      case "post":
        axiosPromise = this.client.post(url, data, axiosConfig);
        break;
      case "put":
        axiosPromise = this.client.put(url, data, axiosConfig);
        break;
      case "get":
      default:
        axiosPromise = this.client.get(url, axiosConfig);
        break;
    }

    return axiosPromise
      .then<BaseResponse<TRequest>>((rawResponse) => ({
        success: true,
        rawResponse,
        request,
      }))
      .catch((error) => ({success: false as const, rawResponse: error, request}));
  }

  static isNetworkError(error: AxiosError): boolean {
    const {response, code} = error;

    return response === undefined && code === "ECONNABORTED";
  }

  static genericError(): Errors.GenericError {
    return {code: "GENERIC_ERROR", message: CoreHelper.formatMessage("Common-genericError")};
  }

  static networkError(): Errors.NetworkError {
    return {code: "NETWORK_ERROR", message: CoreHelper.formatMessage("Common-networkError")};
  }

  protected static getErrorResponse(response: BaseSuccessfulResponse<any> | BaseFailedResponse): {
    success: false;
    error: Errors.GenericError | Errors.NetworkError;
  } {
    return {
      success: false,
      error: !response.success && this.isNetworkError(response.rawResponse) ? this.networkError() : this.genericError(),
    };
  }

  protected static getApiFetchErrorResponse(requestResponse: BaseFailedResponse): FailedFetchResponse {
    const {response} = requestResponse.rawResponse;
    if (response?.data) {
      const {data, status, headers} = response as any;
      const error = data.errors?.[0];
      const message = error?.message ?? data.message ?? undefined;

      switch (status) {
        case 400:
          switch (error.code) {
            case "3002":
              return {success: false, error: {code: "INVALID_ID_ERROR", message}, correlationId: headers["x-correlation-id"]};
          }
          break;
        case 403:
          return {success: false, error: {code: "NOT_AUTHORIZED_ERROR", message}, correlationId: headers["x-correlation-id"]};
        case 404:
          return {success: false, error: {code: "NOT_FOUND_ERROR", message}, correlationId: headers["x-correlation-id"]};
        case 405:
          return {success: false, error: {code: "UNSUPPORTED_METHOD_ERROR", message}, correlationId: headers["x-correlation-id"]};
        case 500:
          return {success: false, error: {code: "INTERNAL_SERVER_ERROR", message}, correlationId: headers["x-correlation-id"]};
      }
    }

    return {
      success: false,
      error: !requestResponse.success && this.isNetworkError(requestResponse.rawResponse) ? this.networkError() : this.genericError(),
    };
  }

  protected static getApiCreateErrorResponse(requestResponse: BaseFailedResponse): FailedCreateResponse {
    const {response} = requestResponse.rawResponse;
    if (response?.data) {
      const {data, status, headers} = response as any;
      const error = data.errors[0];

      switch (status) {
        case 400:
          switch (error.code) {
            case "3003":
              return {
                success: false,
                error: {code: "UNMARSHALING_BODY_ERROR", message: error.message},
                correlationId: headers["x-correlation-id"],
              };
            case "3004":
              const errors: Errors.ValidationError[] = [];
              data.errors.forEach((e: {code: string; error: string; details: string}) => {
                errors.push({code: "VALIDATION_ERROR", message: e.details});
              });
              return {success: false, error: errors, correlationId: headers["x-correlation-id"]};
          }
          break;
        case 403:
          return {
            success: false,
            error: {code: "NOT_AUTHORIZED_ERROR", message: error.message},
            correlationId: headers["x-correlation-id"],
          };
        case 405:
          return {
            success: false,
            error: {code: "UNSUPPORTED_METHOD_ERROR", message: error.message},
            correlationId: headers["x-correlation-id"],
          };
        case 500:
          return {
            success: false,
            error: {code: "INTERNAL_SERVER_ERROR", message: error.message},
            correlationId: headers["x-correlation-id"],
          };
      }
    }
    return {
      success: false,
      error: !requestResponse.success && this.isNetworkError(requestResponse.rawResponse) ? this.networkError() : this.genericError(),
    };
  }

  protected static getApiUpdateErrorResponse(requestResponse: BaseFailedResponse): FailedUpdateResponse {
    const {response} = requestResponse.rawResponse;
    if (response?.data) {
      const {data, status, headers} = response as any;
      const error = data.errors[0];
      switch (status) {
        case 400:
          switch (error.code) {
            case "3002":
              return {
                success: false,
                error: {code: "INVALID_ID_ERROR", message: error.message},
                correlationId: headers["x-correlation-id"],
              };
            case "3003":
              return {
                success: false,
                error: {code: "UNMARSHALING_BODY_ERROR", message: error.message},
                correlationId: headers["x-correlation-id"],
              };
            case "3004":
              const errors: Errors.ValidationError[] = [];
              data.errors.forEach((e: any) => {
                errors.push({code: "VALIDATION_ERROR", message: e.details});
              });
              return {success: false, error: errors, correlationId: headers["x-correlation-id"]};
          }
          break;
        case 403:
          return {
            success: false,
            error: {code: "NOT_AUTHORIZED_ERROR", message: error.message},
            correlationId: headers["x-correlation-id"],
          };
        case 404:
          return {success: false, error: {code: "NOT_FOUND_ERROR", message: error.message}, correlationId: headers["x-correlation-id"]};
        case 405:
          return {
            success: false,
            error: {code: "UNSUPPORTED_METHOD_ERROR", message: error.message},
            correlationId: headers["x-correlation-id"],
          };
        case 500:
          return {
            success: false,
            error: {code: "INTERNAL_SERVER_ERROR", message: error.message, details: error.details},
            correlationId: headers["x-correlation-id"],
          };
      }
    }
    return {
      success: false,
      error: !requestResponse.success && this.isNetworkError(requestResponse.rawResponse) ? this.networkError() : this.genericError(),
    };
  }

  static async backendVersion(request: BackendVersionRequest, options: RequestOptions = {}): Promise<BackendVersionResponse> {
    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `version`,
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          backendVersion: Models.BackendVersion.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }
}
