import EventEmitter from "eventemitter3";
import * as _ from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";

import {WorkerAPIClient} from "../apiclient/worker/WorkerAPIClient";
import * as WorkerTypes from "../apiclient/worker/WorkerAPIClient.types";
import * as Models from "../models";

export type WorkersState = {
  loadingWorkers: boolean;
  loadingWorker: boolean;
  workers?: Models.Worker[];
  workersShort?: Models.WorkerShort[];
};

export type WorkerEvent = {
  "worker-created": [Models.Worker | Models.Worker[]];
  "worker-updated": [Models.Worker];
  "worker-deleted": [string];
};

export class WorkerDataStore {
  private static instance: WorkerDataStore | undefined;

  private initialState: WorkersState = {
    loadingWorkers: false,
    loadingWorker: false,
  };

  state = _.cloneDeep(this.initialState);

  eventEmitter: EventEmitter<WorkerEvent>;

  private constructor() {
    makeObservable<WorkerDataStore>(this, {
      state: observable,
      workers: action,
      worker: action,
      createWorker: action,
      updateWorker: action,
      deleteWorker: action,
      allocateWorkerToDepartment: action,
      assignDeviceToWorker: action,
    });
  }

  static getInstance(): WorkerDataStore {
    if (!WorkerDataStore.instance) WorkerDataStore.instance = new WorkerDataStore();

    return WorkerDataStore.instance;
  }

  initialize() {
    this.eventEmitter = new EventEmitter();
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
    this.eventEmitter?.removeAllListeners();
  }

  async workers(request: WorkerTypes.WorkersRequest): Promise<WorkerTypes.WorkersResponse> {
    this.state.loadingWorkers = true;

    const response = await WorkerAPIClient.workers(request);

    runInAction(() => {
      this.state.loadingWorkers = false;
      if (response.success && !_.isNil(response.workers)) this.state.workers = response.workers;
    });

    return response;
  }

  async workersShort(request: WorkerTypes.WorkersRequest): Promise<WorkerTypes.WorkersShortResponse> {
    this.state.loadingWorkers = true;

    const response = await WorkerAPIClient.workersShort(request);

    runInAction(() => {
      this.state.loadingWorkers = false;
      if (response.success && !_.isNil(response.workers)) this.state.workersShort = response.workers;
    });

    return response;
  }

  async worker(request: WorkerTypes.WorkerRequest): Promise<WorkerTypes.WorkerResponse> {
    this.state.loadingWorker = true;

    const response = await WorkerAPIClient.worker(request);

    runInAction(() => {
      this.state.loadingWorker = false;
    });

    return response;
  }

  async createWorker(request: WorkerTypes.CreateWorkerRequest): Promise<WorkerTypes.CreateWorkerResponse> {
    const response = await WorkerAPIClient.createWorker(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-created", response.worker);
      }
    });

    return response;
  }

  async deleteWorker(request: WorkerTypes.DeleteWorkerRequest): Promise<WorkerTypes.DeleteWorkerResponse> {
    const response = await WorkerAPIClient.deleteWorker(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-deleted", request.id);
      }
    });

    return response;
  }

  async updateWorker(request: WorkerTypes.UpdateWorkerRequest): Promise<WorkerTypes.UpdateWorkerResponse> {
    const response = await WorkerAPIClient.updateWorker(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-updated", response.worker);
      }
    });

    return response;
  }

  async createWorkers(request: WorkerTypes.CreateWorkersRequest): Promise<WorkerTypes.CreateWorkersResponse> {
    const response = await WorkerAPIClient.createWorkers(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-created", response.workers);
      }
    });

    return response;
  }

  async allocateWorkerToDepartment(
    request: WorkerTypes.AllocateWorkerToDepartmentRequest
  ): Promise<WorkerTypes.AllocateWorkerToDepartmentResponse> {
    const response = await WorkerAPIClient.allocateWorkerToDepartment(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-updated", response.worker);
      }
    });

    return response;
  }

  async assignDeviceToWorker(request: WorkerTypes.AssignDeviceToWorkerRequest): Promise<WorkerTypes.AssignDeviceToWorkerResponse> {
    const response = await WorkerAPIClient.assignDeviceToWorker(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("worker-updated", response.worker);
      }
    });

    return response;
  }

  async reportWorkerIndividual(request: WorkerTypes.ReportWorkerIndividualRequest): Promise<WorkerTypes.ReportWorkerIndividualResponse> {
    const response = await WorkerAPIClient.reportWorkerIndividual(request);

    return response;
  }

  async updateWorkerReportGroups(
    request: WorkerTypes.UpdateWorkerReportGroupsRequest
  ): Promise<WorkerTypes.UpdateWorkerReportGroupsResponse> {
    const response = await WorkerAPIClient.updateWorkerReportGroups(request);

    return response;
  }
}
