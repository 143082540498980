import {makeObservable, observable} from "mobx";

import {BaseModel} from "./BaseModel";

export class BasicWorker extends BaseModel {
  id: string;
  employee_id: string;
  first_name: string;
  last_name: string;
  exempt_target?: boolean;
  target_hours_start_date?: Date | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      employee_id: observable,
      first_name: observable,
      last_name: observable,
      exempt_target: observable,
      target_hours_start_date: observable,
    });
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get displayName(): string {
    return this.first_name || this.last_name ? this.fullName : this.employee_id;
  }
}
