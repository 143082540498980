import {useMutation, useQuery} from "@tanstack/react-query";
import {Drawer} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../core/models";
import {GatewayModule} from "../../../core/modules/GatewayModule";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {ConfirmationModal} from "../confirmation-modal/ConfirmationModal";
import {Loading} from "../loading/Loading";
import {AllocateGateway} from "./allocate-gateway/AllocateGateway";
import styles from "./DepartmentGatewaysDetail.module.scss";
import {GatewayItem} from "./gateway-item/GatewayItem";

export type DepartmentGatewaysDetailProps = {
  department: Models.Department;
  onClose: () => void;
  onUpdate?: () => void;
  open: boolean;
};

export const DepartmentGatewaysDetail: FunctionComponent<DepartmentGatewaysDetailProps> = observer((props) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [showDeallocateModal, setShowDeallocateModal] = useState(false);
  const [deallocatingGateway, setDeallocatingGateway] = useState<Models.Gateway | null>(null);
  const [gateways, setGateways] = useState<Models.Gateway[]>([]);

  useEffect(() => {
    if (props.open)
      appStore.sendAnalyticTrack(SegmentKey.DepartmentGatewaysDetailOpened, {
        orgID: props.department.organization.id,
        siteID: props.department.site.id,
        departmentID: props.department.id,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.open]);

  const gatewaysQuery = useQuery({
    queryKey: ["gateways", props.department.id],
    queryFn: () => {
      return GatewayModule.gateways({
        accessToken: authenticationStore.state.accessToken!,
        department_id: props.department.id,
      });
    },
    enabled: !!props.department.id,
  });

  useEffect(() => {
    if (!gatewaysQuery.data) return;

    if (gatewaysQuery.data.success) setGateways(gatewaysQuery.data.gateways);
    else
      WebHelper.showErrorMessage(WebHelper.formatMessage("DepartmentGatewaysDetail-fetchGatewaysError"), gatewaysQuery.data.correlationId);
  }, [gatewaysQuery.data]);

  const {mutate: deallocateGatewayMutate, isPending: deallocateGatewayIsLoading} = useMutation({
    mutationFn: (deallocatingGatewayId: string) =>
      GatewayModule.allocateGatewayToDepartment({
        accessToken: authenticationStore.state.accessToken!,
        id: deallocatingGatewayId,
        department_id: null,
      }),
    onSuccess: async (response) => {
      if (response.success) {
        await handleUpdate();
        setShowDeallocateModal(false);
        setDeallocatingGateway(null);
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("DepartmentGatewaysDetail-deallocateError"), response.correlationId);
    },
  });

  const handleDeallocateCancel = () => {
    if (deallocateGatewayIsLoading) return;

    setShowDeallocateModal(false);
  };

  const handleOpenDeallocate = (gateway: Models.Gateway) => {
    setShowDeallocateModal(true);
    setDeallocatingGateway(gateway);
  };

  const handleUpdate = async () => {
    if (props.onUpdate) {
      props.onUpdate();
    }
    await gatewaysQuery.refetch();
  };

  const handleDeallocateOk = async () => {
    if (deallocateGatewayIsLoading || !deallocatingGateway) return;
    deallocateGatewayMutate(deallocatingGateway.id);
  };

  if (gatewaysQuery.isPending) return <Loading />;

  return (
    <Drawer
      open={props.open}
      width={WebHelper.drawerWidth}
      title={WebHelper.formatMessage("DepartmentGatewaysDetail-title")}
      extra={
        (authenticationStore.permissionLevel(Models.UserScope.Department, props.department.id) === Models.UserAccessLevel.Admin ||
          authenticationStore.permissionLevel(Models.UserScope.Department, props.department.id) === Models.UserAccessLevel.Manager) &&
        authenticationStore.isUserGlobal && <AllocateGateway department={props.department} onAllocate={handleUpdate} />
      }
      destroyOnClose
      onClose={props.onClose}>
      <div className={styles.gatewaysList}>
        {gateways.map((gateway) => (
          <GatewayItem key={gateway.id} gateway={gateway} onDeallocate={() => handleOpenDeallocate(gateway)} />
        ))}
      </div>

      <ConfirmationModal
        showConfirmationModal={showDeallocateModal}
        loading={deallocateGatewayIsLoading}
        title={WebHelper.formatMessage("DepartmentGatewaysDetail-deallocateAlertTitle")}
        message={
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(
                WebHelper.formatMessage("DepartmentGatewaysDetail-deallocateAlertMessage", {
                  gatewaySerialNumber: deallocatingGateway?.serial_number,
                  departmentName: deallocatingGateway?.department?.name,
                })
              ),
            }}
          />
        }
        onOk={handleDeallocateOk}
        onCancel={handleDeallocateCancel}
        okButtonText={WebHelper.formatMessage("DepartmentGatewaysDetail-deallocate")}
        referenceConfirmationValue={WebHelper.formatMessage("Common-deallocateConfirmationValue")}
      />
    </Drawer>
  );
});
