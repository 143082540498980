import {InfoCircleOutlined, SearchOutlined} from "@ant-design/icons";
import {Input, Switch, Tooltip, Typography} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import React, {FunctionComponent} from "react";
import useUserPreferences from "web/hooks/useUserPreferences";

import * as Models from "../../../../../core/models";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./DeviceLiveCheckoutFilters.module.scss";

type DeviceLiveCheckoutFiltersProps = {
  selectedTag: Models.DevicesLiveCheckoutTagsKey;
  setSelectedTag: (tag: Models.DevicesLiveCheckoutTagsKey) => void;
  setSearchValue: (searchValue: string) => void;
  setShowOnlyAssigned: (show: boolean) => void;
  onFilterChange: (action: string, newSettings?: {quickFilter?: Models.DevicesLiveCheckoutTagsKey; tableSettings?: TableSettings}) => void;
};

export const DeviceLiveCheckoutFilters: FunctionComponent<DeviceLiveCheckoutFiltersProps> = ({
  selectedTag,
  setSelectedTag,
  setSearchValue,
  setShowOnlyAssigned,
  onFilterChange,
}) => {
  const [userPreferences] = useUserPreferences();

  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.filters}>
        <CheckableTag
          key={Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed}
          checked={selectedTag === Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed});
            }
          }}>
          {WebHelper.formatMessage("DeviceLiveCheckoutFilters-checkedOutRecentlyTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesLiveCheckoutTagsKey.DeviceNotCheckedOut}
          checked={selectedTag === Models.DevicesLiveCheckoutTagsKey.DeviceNotCheckedOut}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesLiveCheckoutTagsKey.DeviceNotCheckedOut);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesLiveCheckoutTagsKey.DeviceNotCheckedOut});
            }
          }}>
          {WebHelper.formatMessage("DeviceLiveCheckoutFilters-notCheckedOutTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesLiveCheckoutTagsKey.DeviceIdle}
          checked={selectedTag === Models.DevicesLiveCheckoutTagsKey.DeviceIdle}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesLiveCheckoutTagsKey.DeviceIdle);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesLiveCheckoutTagsKey.DeviceIdle});
            }
          }}>
          {WebHelper.formatMessage("DeviceLiveCheckoutFilters-idleTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesLiveCheckoutTagsKey.All}
          checked={selectedTag === Models.DevicesLiveCheckoutTagsKey.All}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesLiveCheckoutTagsKey.All);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesLiveCheckoutTagsKey.All});
            }
          }}>
          {WebHelper.formatMessage("DeviceLiveCheckoutFilters-allTagText")}
        </CheckableTag>
        <Tooltip
          placement="bottomLeft"
          title={
            <div className={styles.tooltipTextBlock}>
              <div className={styles.tooltiptext}>
                <Typography.Text strong>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-checkedOutRecentlyTagText")}</Typography.Text>
                <Typography.Text>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-checkedOutRecentlyDescription")}</Typography.Text>
              </div>
              <div className={styles.tooltiptext}>
                <Typography.Text strong>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-notCheckedOutTagText")}</Typography.Text>
                <Typography.Text>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-notCheckedOutDescription")}</Typography.Text>
              </div>
              <div className={styles.tooltiptext}>
                <Typography.Text strong>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-idleTagText")}</Typography.Text>
                <Typography.Text>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-idleDescription")}</Typography.Text>
              </div>
              <div className={styles.tooltiptext}>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-bottomDisclaimer")}</div>
            </div>
          }>
          <InfoCircleOutlined />
        </Tooltip>
      </div>
      <Input
        className={styles.search}
        size="small"
        placeholder={WebHelper.formatMessage("DeviceLiveCheckoutFilters-searchButtonText")}
        prefix={<SearchOutlined />}
        onChange={(event) => setSearchValue(event.target.value)}
        allowClear
      />
      <div className={styles.seeOnlyAssignedToggle}>
        <Typography.Text>{WebHelper.formatMessage("DeviceLiveCheckoutFilters-seeOnlyAssigned")}</Typography.Text>
        <Switch defaultChecked={userPreferences.data.LiveCheckedOutDevicesTabShowOnlyAssigned} onChange={setShowOnlyAssigned} />
      </div>
    </div>
  );
};
