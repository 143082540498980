import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";

export class OrganizationShort extends BaseModel {
  id: string;
  name: string;
  address: string;
  icon_url: string;
  short_name: string;
  email_domain: string;
  site_count: number;
  department_count: number;
  device_count: number;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
      address: observable,
      icon_url: observable,
      short_name: observable,
      email_domain: observable,
      site_count: observable,
      department_count: observable,
      device_count: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: OrganizationShort, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
  }
}
