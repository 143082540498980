import {FileTextOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Space} from "antd";
import {addDays, startOfDay} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useMemo, useState, useEffect} from "react";
import {GatewayAlertBlock} from "web/components/gateway-alert-block/GatewayAlertBlock";

import * as Models from "../../../../core/models";
import {AnalyticsInterval} from "../../../../core/models";
import {AnalyticsDataStore} from "../../../../core/stores/AnalyticsDataStore";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {WorkerReportGroupDataStore} from "../../../../core/stores/WorkerReportGroupDataStore";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {WebHelper} from "../../../utils/WebHelper";
import {Button} from "../../common/button/Button";
import {ReportType} from "../../report-download/ReportDownload";
import {DemoReportDrawer} from "../../report-drawers/DemoReportDrawer";
import {SiteSummaryReportDrawer} from "../../report-drawers/SiteSummaryReportDrawer";
import {SummaryReportDrawer} from "../../report-drawers/SummaryReportDrawer";
import {DashboardCardWrapper} from "./dashboard-card-wrapper/DashboardCardWrapper";
import {DashboardChildrenCardsSection} from "./dashboard-children-cards-section/DashboardChildrenCardsSection";
import {DashboardDailyUsageBreakdownSection} from "./dashboard-daily-usage-breakdown-section/DashboardDailyUsageBreakdownSection";
import {DashboardDevicesListsDrawer, DeviceStatus} from "./dashboard-lists-drawers/DashboardDevicesListsDrawer";
import {DashboardWorkersListsDrawer, WorkerStatus} from "./dashboard-lists-drawers/DashboardWorkersListsDrawer";
import {DashboardWorkersMetric} from "./dashboard-workers-metric/DashboardWorkersMetric";
import {DashboardWorkersSection} from "./dashboard-workers-section/DashboardWorkersSection";
import styles from "./DashboardTabContent.module.scss";

type DashboardTabContentProps = {
  organization?: Models.Organization;
  site?: Models.Site;
  allSitesHaveOneDepartment?: boolean;
  department?: Models.Department;
  setTimeZone?: (timezone: string) => void;
};

export const DashboardTabContent: FunctionComponent<DashboardTabContentProps> = ({
  organization,
  site,
  allSitesHaveOneDepartment,
  department,
  setTimeZone,
}) => {
  const flags = useFlags();
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const analyticsStore = AnalyticsDataStore.getInstance();
  const workerReportGroupsStore = WorkerReportGroupDataStore.getInstance();

  const [devicesListsDrawerStatus, setDevicesListsDrawerStatus] = useState<
    {open: true; entity: {type: Models.EntityType; id: string}} | {open: false}
  >({
    open: false,
  });
  const [workersListsDrawerStatus, setWorkersListsDrawerStatus] = useState<
    {open: true; entity: {type: Models.EntityType; id: string; timezone: string | undefined}} | {open: false}
  >({
    open: false,
  });
  const [devicesStatus, setDevicesStatus] = useState(DeviceStatus.Assigned);
  const [workersStatus, setWorkersStatus] = useState(WorkerStatus.Active);
  const [isReportDrawerVisible, setIsReportDrawerVisible] = useState(false);
  const [reportType, setReportType] = useState<ReportType>();
  const [workerReportGroups, setWorkerReportGroups] = useState<Models.WorkerReportGroup[]>([]);

  useEffect(() => {
    if (department) {
      appStore.sendAnalyticTrack(SegmentKey.DepartmentDashboardOpened, {
        orgID: department.organization.id,
        siteID: department.site.id,
        departmentID: department.id,
      });
    }
    if (site) {
      appStore.sendAnalyticTrack(SegmentKey.SiteDashboardOpened, {
        orgID: site.organization_id,
        siteID: site.id,
      });
    }
    if (organization) {
      appStore.sendAnalyticTrack(SegmentKey.OrganizationDashboardOpened, {orgID: organization.id});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const workerReportGroupsQuery = useQuery({
    queryKey: ["DashboardTabContent-fetchReportGroupsData", site?.id],
    queryFn: () =>
      workerReportGroupsStore.workerReportGroups({
        accessToken: authenticationStore.state.accessToken!,
        site_id: site!.id,
      }),
    enabled: !!site,
  });

  useEffect(() => {
    const response = workerReportGroupsQuery.data;
    if (!response) return;
    if (!response.success)
      WebHelper.showErrorMessage(WebHelper.formatMessage("DashboardTabContent-fetchWorkerReportGroupsDataError"), response.correlationId);
    else {
      const sortedWorkerReportGroups = response.workerReportGroups.sort((a, b) => a.name.localeCompare(b.name));
      setWorkerReportGroups(sortedWorkerReportGroups);
    }
  }, [workerReportGroupsQuery.data]);

  const analyticsDashboardWorkersQuery = useQuery({
    queryKey: ["DashboardTabContent-fetchAnalyticsDashboardWorkersData", department?.id],
    queryFn: () => {
      const end_time = zonedTimeToUtc(startOfDay(addDays(new Date(), 1)), "UTC").toISOString();

      return analyticsStore.analyticsDashboardWorkerData({
        accessToken: authenticationStore.state.accessToken!,
        end_time,
        interval_type: AnalyticsInterval.Day,
        interval_count: 8,
        resource_type: Models.EntityType.Department,
        resource_id: department!.id,
      });
    },
    enabled: !!department,
  });

  const sites = useMemo(() => {
    return organization?.sites?.slice().sort((a, b) => a.name.localeCompare(b.name));
  }, [organization?.sites]);

  const departments = useMemo(() => {
    return site?.departments?.slice().sort((a, b) => a.name.localeCompare(b.name));
  }, [site?.departments]);

  const selectedResourceType = useMemo(
    () => (department ? Models.EntityType.Department : site ? Models.EntityType.Site : Models.EntityType.Organization),
    [department, site]
  );

  const selectedResourceID = useMemo(
    () => (department ? department.id : site ? site.id : organization?.id ?? ""),
    [department, organization?.id, site]
  );

  const selectedResourceName = useMemo(
    () => (department ? department.name : site ? site.name : organization?.name ?? ""),
    [department, organization?.name, site]
  );

  const selectedResourceTimezone = useMemo(
    () => (department ? department.site.tz_location : site ? site.tz_location : undefined),
    [department, site]
  );

  const ReportButtons = useMemo(() => {
    return department ? (
      allSitesHaveOneDepartment ? (
        <Space size={8}>
          {flags.site_summary_report && (
            <Space size={8}>
              <Button
                shape="round"
                icon={<FileTextOutlined />}
                onClick={() => {
                  setReportType(ReportType.SiteSummary);
                  setIsReportDrawerVisible(true);
                }}>
                {WebHelper.formatMessage("DashboardTabContent-siteSummaryReport")}
              </Button>
            </Space>
          )}
          {flags.summary_report && (
            <Button
              shape="round"
              icon={<FileTextOutlined />}
              onClick={() => {
                setReportType(ReportType.Summary);
                setIsReportDrawerVisible(true);
              }}>
              {WebHelper.formatMessage("DashboardTabContent-summaryReport")}
            </Button>
          )}
          {flags.demo_report && (
            <Button
              shape="round"
              icon={<FileTextOutlined />}
              onClick={() => {
                setReportType(ReportType.Demo);
                setIsReportDrawerVisible(true);
              }}>
              {WebHelper.formatMessage("DashboardTabContent-demoReport")}
            </Button>
          )}
        </Space>
      ) : (
        <Space size={8}>
          {flags.summary_report && department && (
            <Button
              shape="round"
              icon={<FileTextOutlined />}
              onClick={() => {
                setReportType(ReportType.Summary);
                setIsReportDrawerVisible(true);
              }}>
              {WebHelper.formatMessage("DashboardTabContent-summaryReport")}
            </Button>
          )}
          {flags.demo_report && department && (
            <Button
              shape="round"
              icon={<FileTextOutlined />}
              onClick={() => {
                setReportType(ReportType.Demo);
                setIsReportDrawerVisible(true);
              }}>
              {WebHelper.formatMessage("DashboardTabContent-demoReport")}
            </Button>
          )}
        </Space>
      )
    ) : site ? (
      flags.site_summary_report && (
        <Space size={8}>
          <Button
            shape="round"
            icon={<FileTextOutlined />}
            onClick={() => {
              setReportType(ReportType.Summary);
              setIsReportDrawerVisible(true);
            }}>
            {WebHelper.formatMessage("DashboardTabContent-summaryReport")}
          </Button>
        </Space>
      )
    ) : null;
  }, [flags.site_summary_report, flags.summary_report, flags.demo_report, site, department, allSitesHaveOneDepartment]);

  const ReportDrawer = useMemo(() => {
    switch (reportType) {
      case ReportType.SiteSummary:
        return (
          site && (
            <SiteSummaryReportDrawer
              siteId={site.id}
              workerReportGroups={workerReportGroups}
              onClose={() => setIsReportDrawerVisible(false)}
              open={isReportDrawerVisible}
              tz_location={site.tz_location}
            />
          )
        );
      case ReportType.Summary:
        return department ? (
          <SummaryReportDrawer
            departmentID={department.id}
            onClose={() => setIsReportDrawerVisible(false)}
            open={isReportDrawerVisible}
            tz_location={department.site.tz_location}
          />
        ) : (
          site && (
            <SiteSummaryReportDrawer
              siteId={site.id}
              workerReportGroups={workerReportGroups}
              onClose={() => setIsReportDrawerVisible(false)}
              open={isReportDrawerVisible}
              tz_location={site.tz_location}
            />
          )
        );
      case ReportType.Demo:
        return (
          department && (
            <DemoReportDrawer
              departmentID={department.id}
              onClose={() => setIsReportDrawerVisible(false)}
              open={isReportDrawerVisible}
              tz_location={department.site.tz_location}
            />
          )
        );
      default:
        return;
    }
  }, [department, isReportDrawerVisible, reportType, site, workerReportGroups]);

  const handleListsDrawerOpen = (
    key: string,
    drawerType: "devices" | "workers",
    entityType: Models.EntityType,
    id: string,
    timezone: string | undefined
  ) => {
    switch (drawerType) {
      case "devices":
        setDevicesListsDrawerStatus({open: true, entity: {type: entityType, id}});
        switch (key) {
          case "assigned_devices":
            setDevicesStatus(DeviceStatus.Assigned);
            break;
          case "available_devices":
            setDevicesStatus(DeviceStatus.Available);
            break;
          case "out_of_service_devices":
            setDevicesStatus(DeviceStatus.OutOfService);
            break;
          default:
            setDevicesStatus(DeviceStatus.Assigned);
            break;
        }
        return;
      case "workers":
        setWorkersListsDrawerStatus({open: true, entity: {type: entityType, id, timezone}});
        switch (key) {
          case "active_workers":
            setWorkersStatus(WorkerStatus.Active);
            break;
          case "inactive_workers":
            setWorkersStatus(WorkerStatus.Inactive);
            break;
          case "waitlist_workers":
            setWorkersStatus(WorkerStatus.Waitlist);
            break;
          default:
            setWorkersStatus(WorkerStatus.Active);
            break;
        }
        return;
    }
  };

  const handleOpenChildSection = (segmentName: SegmentKey, props: {orgID: string; departmentID?: string; siteID?: string}) => {
    appStore.sendAnalyticTrack(segmentName, props);
  };

  return (
    <>
      <Space className={styles.content} size={16} direction="vertical">
        <GatewayAlertBlock />
        {(department || site) && ReportButtons}
        <DashboardCardWrapper
          entityId={selectedResourceID}
          entityType={selectedResourceType}
          timeZone={selectedResourceTimezone}
          setTimeZone={setTimeZone}
          name={selectedResourceName}
          onListsDrawerOpen={handleListsDrawerOpen}
        />
        {site && (
          <>
            {!allSitesHaveOneDepartment && (
              <DashboardChildrenCardsSection
                onOpen={() => handleOpenChildSection(SegmentKey.DepartmentsSectionOpened, {orgID: site?.organization_id, siteID: site?.id})}
                children={{entities: departments ?? [], type: "departments", siteTimezone: site.tz_location}}
                onListsDrawerOpen={handleListsDrawerOpen}
              />
            )}
            <DashboardChildrenCardsSection
              onOpen={() => handleOpenChildSection(SegmentKey.GroupsSectionOpened, {orgID: site?.organization_id, siteID: site?.id})}
              loading={workerReportGroupsQuery.isPending}
              children={{entities: workerReportGroups, type: "groups", siteTimezone: site.tz_location}}
              onListsDrawerOpen={handleListsDrawerOpen}
            />
          </>
        )}
        {department && (
          <>
            <DashboardWorkersSection
              data={analyticsDashboardWorkersQuery.data?.data}
              loading={analyticsDashboardWorkersQuery.isFetching}
              onChange={analyticsDashboardWorkersQuery.refetch}
              department={department}
            />
            {analyticsDashboardWorkersQuery.data?.data && (
              <DashboardWorkersMetric
                data={
                  analyticsDashboardWorkersQuery.data?.data?.worker_data
                    ? analyticsDashboardWorkersQuery.data?.data.worker_data.map((data) => {
                        //Dashboard workers metrics need the last 7 days excluding today, the data in this component includes today so we need to remove it without mutating the data.
                        const dataExcludingToday: Models.AnalyticsDashboardWorker = new Models.AnalyticsDashboardWorker();

                        dataExcludingToday.time_series_data = data.time_series_data.slice(0, -1);
                        dataExcludingToday.worker = data.worker;

                        return dataExcludingToday;
                      })
                    : null
                }
                department={department}
                onWorkerUpdate={analyticsDashboardWorkersQuery.refetch}
              />
            )}
            <DashboardDailyUsageBreakdownSection department={department} />
          </>
        )}
        {organization && (
          <DashboardChildrenCardsSection
            onOpen={() => handleOpenChildSection(SegmentKey.SitesSectionOpened, {orgID: organization.id})}
            children={{entities: sites ?? [], type: "sites"}}
            onListsDrawerOpen={handleListsDrawerOpen}
          />
        )}
      </Space>
      {devicesListsDrawerStatus.open && (
        <DashboardDevicesListsDrawer
          open={devicesListsDrawerStatus.open}
          entity={devicesListsDrawerStatus.entity}
          status={devicesStatus}
          onClose={() => setDevicesListsDrawerStatus({open: false})}
        />
      )}
      {workersListsDrawerStatus.open && (
        <DashboardWorkersListsDrawer
          open={workersListsDrawerStatus.open}
          entity={workersListsDrawerStatus.entity}
          status={workersStatus}
          onClose={() => setWorkersListsDrawerStatus({open: false})}
        />
      )}
      {ReportDrawer}
    </>
  );
};
