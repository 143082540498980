import {useMutation} from "@tanstack/react-query";
import {Form, Modal, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import * as Models from "../../../core/models";
import {UserScope} from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {OrganizationDataStore} from "../../../core/stores/OrganizationDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {LinkButton} from "../common/link-button/LinkButton";
import {ConfirmationModal, ConfirmationModalSizes} from "../confirmation-modal/ConfirmationModal";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";
import {OrganizationForm, OrganizationFormFields} from "./organization-form/OrganizationForm";
import styles from "./OrganizationModal.module.scss";

type OrganizationModalProps = {
  data: {mode: "create"} | {mode: "edit"; organization: Models.Organization};
  onClose: () => void;
  onSave: () => void;
  onDelete?: () => void;
  open: boolean;
};

export const OrganizationModal: FunctionComponent<OrganizationModalProps> = observer(({data, onClose, onSave, onDelete, open}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const organizationStore = OrganizationDataStore.getInstance();
  const navigate = useNavigate();

  const [form] = Form.useForm<OrganizationFormFields>();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const {mutate: updateOrganizationMutate, isPending: updateOrganizationIsLoading} = useMutation({
    mutationFn: (mutationParams: {formValues: OrganizationFormFields; organization: Models.Organization}) => {
      const {name, short_name, address, icon_url} = mutationParams.formValues;

      return organizationStore.updateOrganization({
        accessToken: authenticationStore.state.accessToken!,
        id: mutationParams.organization.id,
        name,
        short_name,
        address,
        icon_url,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("OrganizationModal-updateError"), response.correlationId);
        return;
      }

      onSave();
    },
  });

  const {mutate: createOrganizationMutate, isPending: createOrganizationIsLoading} = useMutation({
    mutationFn: (mutationParams: {formValues: OrganizationFormFields}) => {
      const {name, short_name, address, icon_url} = mutationParams.formValues;
      return organizationStore.createOrganization({
        accessToken: authenticationStore.state.accessToken!,
        name,
        short_name,
        address,
        icon_url,
      });
    },
    onSuccess: (data) => {
      if (!data.success) WebHelper.showErrorMessage(WebHelper.formatMessage("OrganizationModal-createError"), data.correlationId);
      else {
        const {id} = data.organization;
        appStore.sendAnalyticTrack(SegmentKey.AddedNewOrganization, {organizationID: id});
        form.resetFields();
        onSave();
        navigate(`/organizations/${id}`);
      }
    },
  });

  const handleCancel = () => {
    if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
    else {
      setShowDiscardModal(false);
      onClose();
      form.resetFields();
    }
  };

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsFormDisabled(true);
    } else {
      setIsFormDisabled(false);
    }
  };

  const deleteOrganization = useMutation({
    mutationFn: (organization: Models.Organization) => {
      return organizationStore.deleteOrganization({
        accessToken: authenticationStore.state.accessToken!,
        id: organization.id,
      });
    },
    onSuccess: (response) => {
      if (response.success) {
        navigate("/organizations/");
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("OrganizationModal-deleteError"), response.correlationId);
    },
  });

  const handleDeleteOk = () => {
    if (data.mode !== "edit") return;
    if (deleteOrganization.isPending || !data.organization) return;
    deleteOrganization.mutate(data.organization);
  };

  const handleOk = async () => {
    form
      .validateFields()
      .then((formValues) => {
        switch (data.mode) {
          case "create":
            createOrganizationMutate({formValues});
            break;
          case "edit":
            updateOrganizationMutate({organization: data.organization, formValues});
        }
      })
      // This catch prevents AntD from throwing an error to the console when form validations fail
      .catch(() => {});
  };

  const title = useMemo(() => {
    switch (data.mode) {
      case "create":
        return WebHelper.formatMessage("OrganizationModal-createTitle");
      case "edit":
        return WebHelper.formatMessage("OrganizationModal-editTitle");
    }
  }, [data.mode]);

  const mutationLoading = updateOrganizationIsLoading || createOrganizationIsLoading;

  return (
    <>
      <Modal
        centered
        open={open}
        title={title}
        destroyOnClose={true}
        onOk={handleOk}
        okText={WebHelper.formatMessage("Common-save")}
        okButtonProps={{shape: "round", loading: mutationLoading, disabled: mutationLoading || isFormDisabled}}
        cancelButtonProps={{shape: "round", disabled: mutationLoading}}
        onCancel={handleCancel}
        footer={
          data.mode === "edit"
            ? [
                <div
                  className={styles.footer}
                  style={
                    !(
                      authenticationStore.isUserGlobal &&
                      authenticationStore.permissionLevel(UserScope.Organization) === Models.UserAccessLevel.Admin
                    )
                      ? {justifyContent: "flex-end"}
                      : {}
                  }
                  key="footer">
                  {authenticationStore.isUserGlobal &&
                    authenticationStore.permissionLevel(UserScope.Organization) === Models.UserAccessLevel.Admin && (
                      <LinkButton key="delete" onClick={() => setShowDeleteModal(true)}>
                        {WebHelper.formatMessage("OrganizationModal-deleteButtonText")}
                      </LinkButton>
                    )}
                  <div className={styles.buttonsWrapper}>
                    <Button key="back" shape="round" disabled={mutationLoading} onClick={handleCancel}>
                      {WebHelper.formatMessage("Common-cancel")}
                    </Button>
                    <Button
                      key="submit"
                      type={ButtonType.Primary}
                      shape="round"
                      disabled={mutationLoading || isFormDisabled}
                      loading={mutationLoading}
                      onClick={handleOk}>
                      {WebHelper.formatMessage("Common-save")}
                    </Button>
                  </div>
                </div>,
              ]
            : undefined
        }>
        <OrganizationForm
          form={form}
          loading={mutationLoading}
          onFieldsChange={handleOnFieldsChange}
          initialValues={data.mode === "edit" ? data.organization : undefined}
        />
      </Modal>
      <ConfirmationModal
        showConfirmationModal={showDeleteModal}
        loading={deleteOrganization.isPending}
        title={WebHelper.formatMessage("OrganizationDetail-deleteAlertTitle")}
        message={<Typography.Text>{WebHelper.formatMessage("OrganizationDetail-deleteAlertMessage")}</Typography.Text>}
        onOk={handleDeleteOk}
        onCancel={() => setShowDeleteModal(false)}
        okButtonText={WebHelper.formatMessage("Common-delete")}
        referenceConfirmationValue={WebHelper.formatMessage("Common-deleteConfirmationValue")}
        width={ConfirmationModalSizes.Small}
      />
      <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
    </>
  );
});
