import {CognitoUserSession} from "amazon-cognito-identity-js";
import {fromUnixTime, addHours, isAfter} from "date-fns";
import EventEmitter from "eventemitter3";
import * as _ from "lodash";
import {action, computed, makeObservable, observable, runInAction} from "mobx";

import * as BaseTypes from "../apiclient/BaseAPIClient.types";
import {UserAPIClient} from "../apiclient/user/UserAPIClient";
import * as UserTypes from "../apiclient/user/UserAPIClient.types";
import * as Models from "../models";

export type AuthenticationState = {
  user?: Models.User;
  accessToken?: Models.AccessToken;
  loadingUser: boolean;
};

export type AuthenticationEvent = {
  "my-user-fetched": [Models.User] | undefined;
  "my-user-updated": [Models.User];
};

export class AuthenticationDataStore {
  private static instance: AuthenticationDataStore | undefined;

  private initialState: AuthenticationState = {
    loadingUser: false,
  };

  state: AuthenticationState = _.cloneDeep(this.initialState);

  eventEmitter: EventEmitter<AuthenticationEvent>;

  private constructor() {
    makeObservable<AuthenticationDataStore>(this, {
      state: observable,
      setAccessToken: action,
      permissionLevel: action,
      isAccessTokenExpired: computed,
      isUserAdmin: computed,
      isUserGlobal: computed,
      initialize: action,
      reset: action,
      fetchMe: action,
      updateMyUserProfile: action,
      updateMyUserPreferences: action,
    });
  }

  static getInstance(): AuthenticationDataStore {
    if (!AuthenticationDataStore.instance) AuthenticationDataStore.instance = new AuthenticationDataStore();

    return AuthenticationDataStore.instance;
  }

  setAccessToken(session: CognitoUserSession, sub?: string) {
    const newToken: Models.AccessToken = {
      userId: sub ?? "",
      token: session.getAccessToken().getJwtToken(),
      refreshToken: session.getRefreshToken().getToken(),
      expiresAt: fromUnixTime(session.getAccessToken().getExpiration()),
    };

    runInAction(() => {
      this.state.accessToken = newToken;
    });

    return newToken;
  }

  get isAccessTokenExpired(): boolean {
    const {accessToken} = this.state;

    return !accessToken || !accessToken.expiresAt || isAfter(addHours(new Date(), 1), accessToken.expiresAt);
  }

  get isUserAdmin(): boolean {
    return (
      !_.isNil(this.state.user) &&
      !_.isNil(this.state.user.permissions) &&
      this.state.user.permissions.length > 0 &&
      this.state.user.permissions.some((permission) => permission.level === Models.UserAccessLevel.Admin)
    );
  }

  permissionLevel(scope: Models.UserScope, entityId?: string): Models.UserAccessLevel {
    let level = Models.UserAccessLevel.None;
    if (!_.isNil(this.state.user) && !_.isNil(this.state.user.permissions)) {
      if (this.isUserGlobal) {
        level = this.state.user?.permissions[0].level;
      } else {
        level =
          this.state.user.permissions.find((permission) => permission.resource_id === entityId && permission.scope === scope)?.level ??
          level;
      }
    }
    return level;
  }

  get isUserGlobal(): boolean {
    return (
      !_.isNil(this.state.user) &&
      !_.isNil(this.state.user.permissions) &&
      this.state.user.permissions.length > 0 &&
      this.state.user.permissions[0].scope === Models.UserScope.Global
    );
  }

  initialize() {
    this.eventEmitter = new EventEmitter();
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
    this.eventEmitter?.removeAllListeners();
  }

  async fetchMe(request: UserTypes.FetchMeRequest): Promise<UserTypes.FetchMeResponse> {
    this.state.loadingUser = true;

    const response = await UserAPIClient.fetchMe(request);

    runInAction(() => {
      this.state.loadingUser = false;
      if (response.success && !_.isNil(response.user)) {
        this.state.user = response.user;
      }
    });

    return response;
  }

  async updateMyUserProfile(request: UserTypes.UpdateMyUserProfileRequest): Promise<UserTypes.UpdateMyUserProfileResponse> {
    const response = await UserAPIClient.updateMyUserProfile(request);

    runInAction(() => {
      if (response.success && !_.isNil(response.user)) this.state.user = response.user;
    });

    return response;
  }

  async updateUserSubscription(request: UserTypes.UpdateUserSubscriptionRequest): Promise<UserTypes.UpdateUserSubscriptionResponse> {
    const response = await UserAPIClient.updateUserSubscription(request);

    runInAction(() => {
      if (response.success && !_.isNil(response.user)) this.state.user = response.user;
    });

    return response;
  }

  async updateMyUserPreferences(
    request: BaseTypes.AuthenticatedRequest & {updatedPreferences: Models.UserPreferences}
  ): Promise<UserTypes.UpdateUserResponse> {
    const {id, sub, active, email, organization_id} = this.state.user!;
    const {accessToken, updatedPreferences} = request;

    const customMerge = (objValue: any, srcValue: any) => {
      if (Array.isArray(objValue) && Array.isArray(srcValue)) {
        return srcValue;
      }

      return undefined;
    };

    const preferences = _.mergeWith(this.state.user?.getPreferences() ?? {}, updatedPreferences, customMerge);

    //if the date comes null, it is intended, so it should be preserve its value
    if (updatedPreferences.analyticsSettings?.endDate == null && preferences.analyticsSettings)
      preferences.analyticsSettings.endDate = null;
    if (updatedPreferences.analyticsSettings?.startDate == null && preferences.analyticsSettings)
      preferences.analyticsSettings.startDate = null;

    if (updatedPreferences.favorites?.organizationIds && preferences.favorites?.organizationIds)
      preferences.favorites.organizationIds = updatedPreferences.favorites.organizationIds;

    const response = await UserAPIClient.updateUser({
      accessToken,
      id,
      sub,
      active,
      email,
      organization_id,
      preferences: JSON.stringify(preferences),
    });

    runInAction(() => {
      if (response.success && !_.isNil(response.user)) {
        this.state.user = response.user;
        this.eventEmitter.emit("my-user-updated", response.user);
      }
    });

    return response;
  }
}
