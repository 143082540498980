export type User = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  createdDate: Date;
};

export const userDefault: User = {
  id: "u12sd-hjs04",
  firstName: "Rob",
  lastName: "Scott",
  email: "test@orangeloops.com",
  password: "1234567",
  createdDate: new Date("2019-05-31T14:07:00"),
};

export const UserData: {users: User[]} = {
  users: [userDefault],
};
