import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import * as Models from "../core/models";
import {AuthenticationDataStore} from "../core/stores/AuthenticationDataStore";
import {AppConfig} from "./AppConfig";
import {AppLayout} from "./AppLayout";
import {DeviceManagement} from "./pages/device-management/DeviceManagement";
import {OrganizationsRouting} from "./pages/organizations/OrganizationsRouting";
import Unsubscribe from "./pages/unsubscribe/Unsubscribe";
import {UserPermissions} from "./pages/user-permissions/UserPermissions";
import {WebHelper} from "./utils/WebHelper";

type AppRoutingProps = {
  currentUser: Models.User | undefined;
  deactivatedUser: boolean;
  messageContainerRef: React.RefObject<HTMLDivElement>;
  onSignOut: () => void;
};

export const AppRouting: FunctionComponent<AppRoutingProps> = observer(({currentUser, deactivatedUser, messageContainerRef, onSignOut}) => {
  const {isUserAdmin, isUserGlobal} = AuthenticationDataStore.getInstance();

  const RequireAuthentication = ({children}: {children: JSX.Element}) => {
    return currentUser ? children : null;
  };

  return (
    <WebHelper.Router basename={AppConfig.Modules.App.router.basename}>
      <Routes>
        <Route
          path="/"
          element={
            <AppLayout
              currentUser={currentUser}
              deactivatedUser={deactivatedUser}
              messageContainerRef={messageContainerRef}
              onSignOut={onSignOut}
            />
          }>
          <Route
            index
            element={
              <RequireAuthentication>
                <Navigate to={WebHelper.homePagePath()} />
              </RequireAuthentication>
            }
          />
          {isUserAdmin ? (
            <Route
              path="user_permissions"
              element={
                <RequireAuthentication>
                  <UserPermissions />
                </RequireAuthentication>
              }
            />
          ) : null}
          {isUserGlobal ? (
            <Route
              path="device_management/*"
              element={
                <RequireAuthentication>
                  <DeviceManagement />
                </RequireAuthentication>
              }
            />
          ) : null}
          <Route
            path="unsubscribe"
            element={
              <RequireAuthentication>
                <Unsubscribe />
              </RequireAuthentication>
            }
          />
          <Route
            path="organizations/*"
            element={
              <RequireAuthentication>
                <OrganizationsRouting />
              </RequireAuthentication>
            }
          />

          <Route path="*" element={<Navigate to="/" />} />
        </Route>
      </Routes>
    </WebHelper.Router>
  );
});
