import {Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, ReactNode} from "react";

import styles from "./PageTitle.module.scss";

export type PageTitleProps = {
  actions?: ReactNode[];
  icon?: ReactNode;
  marginBottom?: number;
  title: string;
};

export const PageTitle: FunctionComponent<PageTitleProps> = observer(({actions, icon, marginBottom = 8, title}) => {
  return (
    <div style={{marginBottom}} className={styles.wrapper}>
      <Space>
        {icon}
        <Typography.Title level={3} className={styles.title}>
          {title}
        </Typography.Title>
      </Space>
      <Space className={styles.actions}>
        {actions && actions.map((action, index) => <React.Fragment key={index}>{action}</React.Fragment>)}
      </Space>
    </div>
  );
});
