import {makeObservable, observable} from "mobx";

import {BaseModel} from "./BaseModel";

export class BackendVersion extends BaseModel {
  git_commit: string;
  git_tags: string;

  constructor() {
    super();
    makeObservable(this, {
      git_commit: observable,
      git_tags: observable,
    });
  }
}
