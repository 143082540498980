import {useMutation, useQuery} from "@tanstack/react-query";
import {Modal, Space, Typography} from "antd";
import line from "assets/images/common/Line.svg";
import stepsFinished from "assets/images/common/Steps-Finished.svg";
import stepsFirst from "assets/images/common/Steps-First.svg";
import stepsSecondProgress from "assets/images/common/Steps-Second-Progress.svg";
import stepsSecondWaiting from "assets/images/common/Steps-Second-Waiting.svg";
import stepsThreeProgress from "assets/images/common/Steps-Three-Progress.svg";
import stepsThreeWaiting from "assets/images/common/Steps-Three-Waiting.svg";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../core/stores/DeviceDataStore";
import {AppStore} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";
import styles from "./BulkUpdateDevicesModal.module.scss";
import {BulkUpdateStepOne} from "./steps/bulk-update-step-one/BulkUpdateStepOne";
import {BulkUpdateStepThree} from "./steps/bulk-update-step-three/BulkUpdateStepThree";
import {BulkUpdateStepTwo, DeviceLocationData} from "./steps/bulk-update-step-two/BulkUpdateStepTwo";

type BulkUpdateDevicesModalProps = {
  onClose: () => void;
  open: boolean;
};

export const BulkUpdateDevicesModal: FunctionComponent<BulkUpdateDevicesModalProps> = observer(({onClose, open}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const [deviceLocationData, setDeviceLocationData] = useState<DeviceLocationData[]>([]);
  const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);
  const [currentStep, setCurrentStep] = useState(Models.UpdateBulkDevicesSteps.first);
  const [deviceGroups, setDeviceGroups] = useState<Models.DeviceGroup>();
  const [firmwareVersionIDS, setFirmwareVersionIDS] = useState<string[]>([]);
  const [buttonConfigs, setButtonConfigs] = useState<string[][]>([]);

  const resetForm = () => {
    setCurrentStep(Models.UpdateBulkDevicesSteps.first);
    setDeviceGroups(undefined);
    setFirmwareVersionIDS([]);
    setButtonConfigs([]);
  };

  const deviceGroupsQuery = useQuery({
    queryKey: ["BulkUpdateDevices-fetchDeviceGroups", deviceLocationData],
    queryFn: () => {
      const organization_ids: string[] = [];
      const site_ids: string[] = [];
      const department_ids: string[] = [];
      const device_ids: string[] = [];

      deviceLocationData.forEach((elemento) => {
        if (elemento.type === "org") {
          organization_ids.push(...elemento.pathIds);
        } else if (elemento.type === "site") {
          if (elemento.pathIds.length >= 2) {
            site_ids.push(elemento.pathIds[1]);
          }
        } else if (elemento.type === "department") {
          if (elemento.pathIds.length >= 3) {
            department_ids.push(elemento.pathIds[2]);
          }
        } else if (elemento.type === "device") {
          if (elemento.pathIds.length >= 4) {
            device_ids.push(elemento.pathIds[3]);
          }
        }
      });

      return deviceStore.deviceGroups({
        accessToken: authenticationStore.state.accessToken!,
        organization_ids,
        department_ids,
        site_ids,
        device_ids,
      });
    },
    enabled: false,
  });

  const bulkUpdate = useMutation({
    mutationFn: () => {
      const groups = firmwareVersionIDS.map((id, index) => {
        const device_ids: string[] = [];

        if (deviceGroups?.firmware_groups[index]) {
          deviceGroups.firmware_groups[index].device_types.forEach((deviceType) => {
            device_ids.push(...deviceType.devices);
          });
        }

        return {
          firmware_version_id: id,
          device_ids: device_ids ?? [],
          button_1_controller_setting_id: buttonConfigs[index]?.[0],
          button_2_controller_setting_id: buttonConfigs[index]?.[1],
          button_3_controller_setting_id: buttonConfigs[index]?.[2],
          override_pending: true,
        };
      });

      return deviceStore.bulkUpdate({
        accessToken: authenticationStore.state.accessToken!,
        firmware_groups: groups,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("BulkUpdateDevicesModal-errorMessage"), response.correlationId);
        return;
      }

      appStore.showMessage(WebHelper.formatMessage("BulkUpdateDevicesModal-successMessage"), "success");
      onClose();
      resetForm();
    },
  });

  const handleCancel = () => {
    if (currentStep !== Models.UpdateBulkDevicesSteps.first && !isDiscardChangesModalVisible) {
      setIsDiscardChangesModalVisible(true);
    } else {
      setIsDiscardChangesModalVisible(false);
      onClose();
      resetForm();
    }
  };

  const handleNext = () => {
    if (currentStep === Models.UpdateBulkDevicesSteps.first) setCurrentStep(Models.UpdateBulkDevicesSteps.second);
    else if (currentStep === Models.UpdateBulkDevicesSteps.second) {
      deviceGroupsQuery.refetch().then((response) => {
        if (response?.data?.success) {
          setDeviceGroups(response.data.devicesGroups);
          setCurrentStep(Models.UpdateBulkDevicesSteps.three);
          setButtonConfigs(Array.from({length: response.data.devicesGroups.firmware_groups.length}, () => new Array(3).fill(null)));
        }
      });
    }
  };

  const handleSave = async () => {
    bulkUpdate.mutate();
  };

  const handleBack = () => {
    if (currentStep === Models.UpdateBulkDevicesSteps.second) {
      setCurrentStep(Models.UpdateBulkDevicesSteps.first);
    } else if (currentStep === Models.UpdateBulkDevicesSteps.three) {
      setCurrentStep(Models.UpdateBulkDevicesSteps.second);
    }
  };

  const modalFooter = (
    <>
      {
        <Button shape="round" disabled={bulkUpdate.isPending} onClick={onClose}>
          {WebHelper.formatMessage("Common-cancel")}
        </Button>
      }

      {currentStep !== Models.UpdateBulkDevicesSteps.first && (
        <Button shape="round" type={ButtonType.Default} disabled={bulkUpdate.isPending} onClick={handleBack}>
          {WebHelper.formatMessage("Common-back")}
        </Button>
      )}
      {currentStep !== Models.UpdateBulkDevicesSteps.three && (
        <Button
          disabled={_.isEmpty(deviceLocationData)}
          type={ButtonType.Primary}
          shape="round"
          loading={deviceGroupsQuery.isFetching}
          onClick={handleNext}>
          {WebHelper.formatMessage("Common-next")}
        </Button>
      )}
      {currentStep === Models.UpdateBulkDevicesSteps.three && (
        <Button
          type={ButtonType.Primary}
          shape="round"
          disabled={buttonConfigs.some((config) => config.some((id) => _.isNil(id)))}
          loading={bulkUpdate.isPending}
          onClick={handleSave}>
          {WebHelper.formatMessage("Common-confirm")}
        </Button>
      )}
    </>
  );

  return (
    <>
      <Modal
        open={open}
        centered
        destroyOnClose
        title={WebHelper.formatMessage("BulkUpdateDevicesModal-modalTitle")}
        onCancel={handleCancel}
        onOk={handleNext}
        className={styles.modal}
        width={"fit-content"}
        footer={modalFooter}>
        <Space className={styles.stepsWrapper}>
          <Space className={styles.stepWrapper}>
            <Space>
              <img src={currentStep === Models.UpdateBulkDevicesSteps.first ? stepsFirst : stepsFinished} alt="" />
              <Typography.Text className={styles.stepTitle}>
                {currentStep === Models.UpdateBulkDevicesSteps.first
                  ? WebHelper.formatMessage("BulkUpdateDevicesModal-inProgress")
                  : WebHelper.formatMessage("BulkUpdateDevicesModal-finished")}
              </Typography.Text>
            </Space>
            <Typography
              className={[
                styles.stepTitle,
                currentStep !== Models.UpdateBulkDevicesSteps.first ? styles.waitingText : "",
                styles.reasonText,
              ].join(" ")}>
              {WebHelper.formatMessage("BulkUpdateDevicesModal-selectDevices")}
            </Typography>
          </Space>
          <img src={line} alt="" className={styles.lineSeparator} />
          <Space className={styles.stepWrapper}>
            <Space>
              <img
                src={
                  currentStep === Models.UpdateBulkDevicesSteps.first
                    ? stepsSecondWaiting
                    : currentStep === Models.UpdateBulkDevicesSteps.second
                      ? stepsSecondProgress
                      : stepsFinished
                }
                alt=""
              />
              <Typography.Text
                className={[currentStep === Models.UpdateBulkDevicesSteps.first ? styles.waitingText : "", styles.stepTitle].join(" ")}>
                {currentStep === Models.UpdateBulkDevicesSteps.first
                  ? WebHelper.formatMessage("BulkUpdateDevicesModal-waiting")
                  : currentStep === Models.UpdateBulkDevicesSteps.second
                    ? WebHelper.formatMessage("BulkUpdateDevicesModal-inProgress")
                    : WebHelper.formatMessage("BulkUpdateDevicesModal-finished")}
              </Typography.Text>
            </Space>
            <Typography.Text
              className={`${currentStep !== Models.UpdateBulkDevicesSteps.second ? styles.waitingText : ""} ${styles.reasonText}`}>
              {WebHelper.formatMessage("BulkUpdateDevicesModal-confirmation")}
            </Typography.Text>
          </Space>
          <img src={line} alt="" className={styles.lineSeparator} />
          <Space className={styles.stepWrapper}>
            <Space>
              <img src={currentStep !== Models.UpdateBulkDevicesSteps.three ? stepsThreeWaiting : stepsThreeProgress} alt="" />
              <Typography.Text className={currentStep !== Models.UpdateBulkDevicesSteps.three ? styles.waitingText : ""}>
                {currentStep !== Models.UpdateBulkDevicesSteps.three
                  ? WebHelper.formatMessage("BulkUpdateDevicesModal-waiting")
                  : WebHelper.formatMessage("BulkUpdateDevicesModal-inProgress")}
              </Typography.Text>
            </Space>
            <Typography.Text
              className={`${currentStep !== Models.UpdateBulkDevicesSteps.three ? styles.waitingText : ""} ${styles.reasonText}`}>
              {WebHelper.formatMessage("BulkUpdateDevicesModal-configuration")}
            </Typography.Text>
          </Space>
        </Space>
        <div className={styles.content}>
          {currentStep === Models.UpdateBulkDevicesSteps.first && (
            <BulkUpdateStepOne deviceLocationData={deviceLocationData} updateLocationData={setDeviceLocationData} />
          )}
          {currentStep === Models.UpdateBulkDevicesSteps.second && (
            <BulkUpdateStepTwo deviceLocationData={deviceLocationData} updateLocationData={setDeviceLocationData} />
          )}
          {currentStep === Models.UpdateBulkDevicesSteps.three && deviceGroups && (
            <BulkUpdateStepThree
              deviceLocationData={deviceLocationData}
              deviceGroups={deviceGroups}
              firmwareVersionIDS={firmwareVersionIDS}
              updateFirmwareVersionIDS={setFirmwareVersionIDS}
              updateButtonConfigs={setButtonConfigs}
              buttonConfigs={buttonConfigs}
            />
          )}
        </div>
      </Modal>
      <DiscardChangesModal
        onDiscard={handleCancel}
        onClose={() => setIsDiscardChangesModalVisible(false)}
        open={isDiscardChangesModalVisible}
      />
    </>
  );
});
