import {Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo} from "react";

import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";
import {UsageChart} from "../../usage-chart/UsageChart";
import styles from "./DashboardCardUsageChart.module.scss";

type DashboardCardUsageChartProps = {
  utilizationHistory: Omit<Models.AnalyticsDashboardHistory, "active_devices">[];
  entityType: Models.EntityType;
  enrolledWorkersCount: number;
};

export const DashboardCardUsageChart: FunctionComponent<DashboardCardUsageChartProps> = observer(
  ({utilizationHistory, entityType, enrolledWorkersCount}) => {
    const title = useMemo(() => {
      switch (entityType) {
        case Models.EntityType.WorkerReportGroup:
          return WebHelper.formatMessage("DashboardCardUsageChart-workerReportGroupsTitle");
        default:
          return WebHelper.formatMessage("DashboardCardUsageChart-defaultTitle");
      }
    }, [entityType]);

    return (
      <Space className={styles.wrapper} direction="vertical">
        <Typography.Text>{title}</Typography.Text>
        <UsageChart
          utilizationHistory={utilizationHistory}
          height={300}
          entityType={entityType}
          enrolledWorkersCount={enrolledWorkersCount}
        />
      </Space>
    );
  }
);
