import {InfoCircleFilled, FileTextOutlined, SyncOutlined} from "@ant-design/icons";
import {useMutation} from "@tanstack/react-query";
import {Card, Checkbox, Select, Space, Switch, Typography} from "antd";
import {SiteDataStore} from "core/stores/SiteDataStore";
import {addDays, differenceInDays, endOfDay, format, isAfter} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import React, {FunctionComponent, useMemo, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {AppStore} from "../../../../stores/AppStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";
import {DatePicker} from "../../../date-picker/DatePicker";
import styles from "./SiteSummaryReportDrawerContent.module.scss";

type SiteSummaryReportDrawerContentProps = {
  siteId: string;
  onChange: () => void;
  workerReportGroups: Models.WorkerReportGroup[];
  siteConfig: Models.SiteConfiguration | null;
  selectedWorkerReportGroups: Models.WorkerReportGroup[] | null;
  setSelectedWorkerReportGroups: (groups: Models.WorkerReportGroup[]) => void;
  loadingDownload: boolean;
  loadingPreview: boolean;
  onDownload: (startDateFormatted: string, endDateFormatted: string) => void;
  onPreview: (endDateFormatted: string) => void;
  tz_location?: string;
};

export const SiteSummaryReportDrawerContent: FunctionComponent<SiteSummaryReportDrawerContentProps> = ({
  siteId,
  onChange,
  workerReportGroups,
  siteConfig,
  selectedWorkerReportGroups,
  setSelectedWorkerReportGroups,
  loadingDownload,
  loadingPreview,
  onDownload,
  onPreview,
  tz_location,
}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const siteStore = SiteDataStore.getInstance();

  const [endDate, setEndDate] = useState<Date>(addDays(endOfDay(new Date()), -1));
  const [includeWorkerReportGroups, setIncludeWorkerReportGroups] = useState<boolean>(true);
  const [leaderboard, setLeaderboard] = useState<boolean>(siteConfig ? siteConfig.leaderboard_report_included : false);
  const [activeInactiveWorkersList, setActiveInactiveWorkersList] = useState<boolean>(
    siteConfig ? siteConfig.active_list_report_included : false
  );
  const [weigthOffloaded, setWeigthOffloaded] = useState<boolean>(siteConfig ? siteConfig.weight_offloaded_report_included : false);
  const [safeliftScore, setSafeliftScore] = useState<boolean>(siteConfig ? siteConfig.safelift_score_report_included : false);

  const updateSiteConfigurationMutation = useMutation({
    mutationFn: async () => {
      let workerGroupIds: {id: string}[] = [];
      workerGroupIds = selectedWorkerReportGroups ? selectedWorkerReportGroups.map((workerGroup) => ({id: workerGroup.id})) : [];
      const workerGroupsFromSiteConfig =
        siteConfig && siteConfig.groups ? siteConfig.groups.map((workerGroup) => ({id: workerGroup.id})) : [];
      const workerGroupsChanged = siteConfig
        ? !(
            workerGroupIds &&
            workerGroupsFromSiteConfig &&
            workerGroupsFromSiteConfig.length === workerGroupIds?.length &&
            workerGroupsFromSiteConfig.every((group, index) => group.id === workerGroupIds[index].id)
          )
        : false;

      return siteStore.updateSiteConfiguration({
        accessToken: authenticationStore.state.accessToken!,
        id: siteId,
        site_configuration_id: siteConfig ? siteConfig.id : null,
        weekday_report: Models.Weekday.Sunday, // not yet implemented, so this is the default value
        time_report: "07:00:00", // not yet implemented, so this is the default value
        active_list_report_included: activeInactiveWorkersList,
        leaderboard_report_included: leaderboard,
        weight_offloaded_report_included: weigthOffloaded && activeInactiveWorkersList,
        safelift_score_report_included: safeliftScore && activeInactiveWorkersList,
        override_groups: workerGroupsChanged,
        report_groups: workerGroupsChanged ? (workerGroupIds.length === 0 ? [{}] : workerGroupIds) : [],
      });
    },
    onSuccess: async (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("SiteSummaryReportDrawer-updateError"), response.correlationId);
      } else {
        appStore.showMessage(WebHelper.formatMessage("SiteSummaryReportDrawer-updateSuccess"), "success");
        onChange();
      }
    },
  });

  const options = workerReportGroups?.map((group) => {
    return {value: group.id, label: group.name};
  });

  const {endDateFormatted} = useMemo<{endDateFormatted: string}>(() => {
    const formatDate = (date: Date) =>
      tz_location
        ? zonedTimeToUtc(date, "UTC").toISOString()
        : format(date, WebHelper.formatMessage("Common-dateTimeFormatWithoutTimezone"));

    const endDateFormatted = formatDate(endDate);

    return {endDateFormatted};
  }, [endDate, tz_location]);

  const handleSwitchChange = (value: boolean) => {
    setIncludeWorkerReportGroups(value);
    if (!value) setSelectedWorkerReportGroups([]);
  };

  const handleSelectChange = (value: string[]) => {
    setSelectedWorkerReportGroups(workerReportGroups.filter((group) => value.includes(group.id)));
  };

  return (
    <Card>
      <Space className={styles.cardContent} direction="vertical" size="large">
        <Typography.Text>
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(WebHelper.formatMessage("SiteSummaryReportDrawer-contentMessage")),
            }}
          />
        </Typography.Text>
        <Space size="large">
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-reportEndDate")}</Typography.Text>
          <DatePicker
            style={{width: "100%"}}
            allowClear={false}
            disabled={loadingDownload || loadingPreview}
            value={endDate}
            onChange={(date) => date && setEndDate(endOfDay(date))}
            disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
            cellRender={(current) => {
              if (typeof current === "string" || typeof current === "number") {
                current = new Date(current);
              }
              const isLastSevenDays = differenceInDays(endDate, current) > 0 && differenceInDays(endDate, current) <= 6;

              return <div className={`ant-picker-cell-inner ${isLastSevenDays && styles.selectedDay}`}>{current.getDate()}</div>;
            }}
          />
        </Space>
        <Space className={styles.grayWrapper} size="middle" direction="vertical">
          {authenticationStore.isUserAdmin && (
            <Space className={styles.switchWrapper} align="center" size="middle" direction="horizontal">
              <Typography.Text className={styles.switchLabel}>
                {WebHelper.formatMessage("SiteSummaryReportDrawer-includeWorkerReportGroups")}
              </Typography.Text>
              <Switch checked={includeWorkerReportGroups} onChange={(value) => handleSwitchChange(value)} disabled={loadingPreview} />
            </Space>
          )}
          {includeWorkerReportGroups && (
            <Space className={styles.selectWrapper} align="center" size="middle" direction="horizontal">
              <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("SiteSummaryReportDrawer-select")}</Typography.Text>
              <Select
                options={options}
                onChange={(value) => handleSelectChange(value)}
                defaultValue={siteConfig && siteConfig.groups ? siteConfig.groups.map((group) => group.id) : undefined}
                className={styles.groupName}
                placeholder="Worker groups"
                mode="multiple"
                disabled={loadingPreview}
                allowClear
              />
            </Space>
          )}
          {authenticationStore.isUserAdmin && (
            <>
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-featuresToIncludeMessage")}</Typography.Text>
              <Space className={styles.checkboxWrapper} align="center" size="middle" direction="horizontal">
                <Checkbox checked={leaderboard} onChange={(e) => setLeaderboard(e.target.checked)} />
                <Typography.Text className={styles.checkboxLabel}>
                  {WebHelper.formatMessage("SiteSummaryReportDrawer-leaderboardCheckbox")}
                </Typography.Text>
              </Space>
              <Space className={styles.checkboxWrapper} align="center" size="middle" direction="horizontal">
                <Checkbox checked={activeInactiveWorkersList} onChange={(e) => setActiveInactiveWorkersList(e.target.checked)} />
                <Typography.Text className={styles.checkboxLabel}>
                  {WebHelper.formatMessage("SiteSummaryReportDrawer-activeInactiveWorkersCheckbox")}
                </Typography.Text>
              </Space>
              <Space className={styles.subCheckboxWrapper} align="center" size="middle" direction="horizontal">
                <Checkbox
                  disabled={!activeInactiveWorkersList}
                  checked={weigthOffloaded && activeInactiveWorkersList}
                  onChange={(e) => setWeigthOffloaded(e.target.checked)}
                />
                <Typography.Text>
                  {WebHelper.formatMessage("SiteSummaryReportDrawer-displayWorkersWeightOffloadedCheckbox")}
                </Typography.Text>
              </Space>
              <Space className={styles.subCheckboxWrapper} align="center" size="middle" direction="horizontal">
                <Checkbox
                  disabled={!activeInactiveWorkersList}
                  checked={safeliftScore && activeInactiveWorkersList}
                  onChange={(e) => setSafeliftScore(e.target.checked)}
                />
                <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-safeliftScoreCheckbox")}</Typography.Text>
              </Space>
            </>
          )}
          <Space className={styles.infoWrapper} align="center" size="middle" direction="horizontal">
            <InfoCircleFilled />
            <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-onlyAdminsMessage")}</Typography.Text>
          </Space>
          {authenticationStore.isUserAdmin && (
            <Space
              className={styles.updateConfigurationWrapper}
              align="center"
              size="middle"
              direction="horizontal"
              onClick={() => updateSiteConfigurationMutation.mutate()}>
              <Button style={{border: "none", width: "100%"}} loading={updateSiteConfigurationMutation.isPending}>
                <SyncOutlined />
                <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportDrawer-updateConfigButton")}</Typography.Text>
              </Button>
            </Space>
          )}
        </Space>
        <Space className={styles.buttonWrapper} align="center" size="middle">
          <Button
            shape="round"
            type={ButtonType.Primary}
            icon={<FileTextOutlined />}
            loading={loadingPreview}
            disabled={loadingDownload || updateSiteConfigurationMutation.isPending}
            onClick={() => onPreview(endDateFormatted)}>
            {WebHelper.formatMessage("ReportDrawerContent-generateReportButtonText")}
          </Button>
        </Space>
      </Space>
    </Card>
  );
};
