import {Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo} from "react";
import {useNavigate} from "react-router-dom";

import notFoundErrorImage from "../../../assets/images/common/Error_Screen_NotFoundError_Image.svg";
import internalServerErrorImage from "../../../assets/images/common/Error_Screen_ServerError_Image.svg";
import {EntityType} from "../../../core/models";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import styles from "./ErrorScreen.module.scss";

type ErrorScreenProps = {
  errorType: string;
  entityType: EntityType;
  correlationID?: string;
};

export const ErrorScreen: FunctionComponent<ErrorScreenProps> = observer((props) => {
  const navigate = useNavigate();

  const content = useMemo(() => {
    switch (props.errorType) {
      case "NOT_FOUND_ERROR":
        return (
          <Space direction="vertical" size={24} align="center">
            <img src={notFoundErrorImage} alt="not found error" />
            <Space direction="vertical" size={0} align="center">
              <Typography.Text className={styles.title}>{WebHelper.formatMessage("ErrorScreen-notFoundErrorText")}</Typography.Text>
              <Typography.Text className={styles.subtitle}>
                {WebHelper.formatMessage("ErrorScreen-notFoundErrorDescription", {entityType: props.entityType})}
              </Typography.Text>
            </Space>
          </Space>
        );
      case "INTERNAL_SERVER_ERROR":
        return (
          <Space direction="vertical" size={24} align="center">
            <img src={internalServerErrorImage} alt="internal server error" />
            <Space direction="vertical" size={0} align="center">
              <Typography.Text className={styles.title}>{WebHelper.formatMessage("ErrorScreen-internalServerErrorText")}</Typography.Text>
              <Typography.Text className={styles.subtitle}>
                {WebHelper.formatMessage("ErrorScreen-internalServerErrorDescription")}
              </Typography.Text>
            </Space>
          </Space>
        );
      default:
        return (
          <Space direction="vertical" size={24} align="center">
            <img src={notFoundErrorImage} alt="not found error" />
            <Typography.Text className={styles.title}>{WebHelper.formatMessage("ErrorScreen-defaultErrorText")}</Typography.Text>
          </Space>
        );
    }
  }, [props.entityType, props.errorType]);

  const backButton = useMemo(() => {
    switch (props.errorType) {
      case "NOT_FOUND_ERROR":
        return (
          <Button type={ButtonType.Primary} shape="round" onClick={() => navigate("/organizations")}>
            {WebHelper.formatMessage("ErrorScreen-backHomeButton")}
          </Button>
        );
      case "INTERNAL_SERVER_ERROR":
        return (
          <Button type={ButtonType.Primary} shape="round" onClick={() => location.reload()}>
            {WebHelper.formatMessage("ErrorScreen-reloadPageButton")}
          </Button>
        );
      default:
        return (
          <Button type={ButtonType.Primary} shape="round" onClick={() => navigate("/organizations")}>
            {WebHelper.formatMessage("ErrorScreen-backHomeButton")}
          </Button>
        );
    }
  }, [props.errorType, navigate]);

  return (
    <Space direction="vertical" size={12} align="center">
      <>
        {content}

        <Typography.Text type="secondary">
          {props.correlationID && WebHelper.formatMessage("ErrorScreen-correlationId", {correlationID: props.correlationID})}
        </Typography.Text>

        {backButton}
      </>
    </Space>
  );
});
