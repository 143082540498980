import {Typography} from "antd";
import {LDFlagSet} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../../core/models";
import {WebHelper} from "../../../../utils/WebHelper";
import {SiteSummaryReportGroupCard} from "../site-summary-report-group-card/SiteSummaryReportGroupCard";
import {SiteSummaryReportHeader} from "../site-summary-report-header/SiteSummaryReportHeader";
import styles from "./SiteSummaryReportGroupsPage.module.scss";

type SiteSummaryReportGroupsPageProps = {
  endDate: string;
  flags: LDFlagSet;
  targetUtilization: boolean;
  reportGroups: Models.WorkerReportGroup[];
  reportGroupsAnalytics: {id: string; data: Models.AnalyticsDashboardCard}[];
};

export const SiteSummaryReportGroupsPage: FunctionComponent<SiteSummaryReportGroupsPageProps> = ({
  endDate,
  reportGroups,
  reportGroupsAnalytics,
  flags,
  targetUtilization,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.groupsPageWrapper}>
        <SiteSummaryReportHeader endDate={endDate} />
        <div className={styles.groupsTitle}>
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-workerReportGroups")}</Typography.Text>
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-workerStatusCounts")}</Typography.Text>
        </div>
        <div
          className={`${flags.target_utilization_hours && targetUtilization ? styles.targetGroupsContainer : styles.nonTargetGroupsContainer}`}>
          {reportGroups.map((group) => (
            <SiteSummaryReportGroupCard
              flags={flags}
              targetUtilization={targetUtilization}
              reportGroup={group}
              reportGroupData={reportGroupsAnalytics.find((data) => data.id === group.id)?.data}
              key={group.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
