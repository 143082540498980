import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";
import {Navigate, Route, Routes} from "react-router-dom";

import {DeviceManagementDeviceDetail} from "./device-management-device-detail/DeviceManagementDeviceDetail";
import {DeviceManagementDevices} from "./device-management-devices/DeviceManagementDevices";

export const DeviceManagement: FunctionComponent = observer(() => {
  return (
    <>
      <Routes>
        <Route path="devices">
          <Route index element={<DeviceManagementDevices />} />
          <Route path=":deviceID">
            <Route index element={<DeviceManagementDeviceDetail />} />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/device_management/devices" />} />
      </Routes>
    </>
  );
});
