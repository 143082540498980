import {Card, Space, Typography} from "antd";
import React, {FunctionComponent} from "react";
import {WebHelper} from "web/utils/WebHelper";

import * as Models from "../../../../core/models";
import {GatewaysTableLastSeenColumnRender} from "../gateways-table-last-seen-column-render/GatewaysTableLastSeenColumnRender";
import styles from "./GatewayDetails.module.scss";

type GatewayDetailsProps = {
  gateway: Models.Gateway;
};

export const GatewayDetails: FunctionComponent<GatewayDetailsProps> = ({gateway}) => {
  return (
    <Space className={styles.cardsWrapper} direction="vertical" size={16}>
      <Card className={styles.card}>
        <Space className={styles.cardContent} direction="vertical" size={16}>
          <Typography.Text strong>
            {WebHelper.formatMessage("GatewayDetails-gatewaySerialNumber", {serialNumber: gateway.serial_number})}
          </Typography.Text>
          <Space direction="vertical" size={16}>
            <Space>
              <Typography.Text>{WebHelper.formatMessage("GatewayDetails-lastTimeConnectedLabel")}</Typography.Text>
              <GatewaysTableLastSeenColumnRender
                mostRecentGatewayEventAt={gateway.most_recent_gateway_event_at}
                siteTimezone={gateway.site?.tz_location}
                status={gateway.status}
              />
            </Space>
            <Space>
              <Typography.Text>{WebHelper.formatMessage("GatewayDetails-gatewayFirmwareLabel")}</Typography.Text>
              {gateway.current_firmware_version}
            </Space>
          </Space>
          <Typography.Text strong>{WebHelper.formatMessage("GatewayDetails-location")}</Typography.Text>
          <Space direction="vertical" size={16}>
            <Space>
              <Typography.Text>{WebHelper.formatMessage("GatewayDetails-organizationLabel")}</Typography.Text>
              {gateway.organization?.name}
            </Space>
            <Space>
              <Typography.Text>{WebHelper.formatMessage("GatewayDetails-siteLabel")}</Typography.Text>
              {gateway.site?.name}
            </Space>
            <Space>
              <Typography.Text>{WebHelper.formatMessage("GatewayDetails-departmentLabel")}</Typography.Text>
              {gateway.department?.name}
            </Space>
          </Space>
        </Space>
      </Card>
    </Space>
  );
};
