import {useMutation} from "@tanstack/react-query";
import {Form, Modal, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useState} from "react";
import {useNavigate} from "react-router-dom";

import * as Models from "../../../core/models";
import {UserScope} from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {SiteDataStore} from "../../../core/stores/SiteDataStore";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {LinkButton} from "../common/link-button/LinkButton";
import {ConfirmationModal, ConfirmationModalSizes} from "../confirmation-modal/ConfirmationModal";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";
import {SiteForm, SiteFormFields} from "./site-form/SiteForm";
import styles from "./SiteModal.module.scss";

type SiteModalProps = {
  data: {mode: "create"; orgID: string} | {mode: "edit"; site: Models.Site};
  onClose: () => void;
  onDelete?: () => void;
  onSave: () => void;
  open: boolean;
};

export const SiteModal: FunctionComponent<SiteModalProps> = observer(({data, onClose, onDelete, onSave, open}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const siteStore = SiteDataStore.getInstance();

  const navigate = useNavigate();
  const [form] = Form.useForm<SiteFormFields>();
  const [isFormDisabled, setIsFormDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showDiscardModal, setShowDiscardModal] = useState(false);

  const {mutate: updateSiteMutate, isPending: updateSiteIsLoading} = useMutation({
    mutationFn: (mutationParams: {formValues: SiteFormFields; site: Models.Site}) => {
      const {name, address, tz_location} = mutationParams.formValues;
      const {id, organization_id} = mutationParams.site;

      return siteStore.updateSite({
        accessToken: authenticationStore.state.accessToken!,
        id,
        name,
        address,
        tz_location,
        organization_id,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("SiteModal-updateError"), response.correlationId);
        return;
      }

      onSave();
    },
  });

  const {mutate: createSiteMutate, isPending: createSiteIsLoading} = useMutation({
    mutationFn: (mutationParams: {formValues: SiteFormFields; orgID: string}) => {
      const {name, address, tz_location} = mutationParams.formValues;

      return siteStore.createSite({
        accessToken: authenticationStore.state.accessToken!,
        name,
        address,
        tz_location,
        organization_id: mutationParams.orgID,
      });
    },
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("SiteModal-createError"), response.correlationId);
        return;
      }

      appStore.sendAnalyticTrack(SegmentKey.AddedNewSite, {orgID: response.site.organization_id, siteID: response.site.id});
      form.resetFields();
      authenticationStore
        .fetchMe({
          accessToken: authenticationStore.state.accessToken!,
          sub: authenticationStore.state.user!.sub!,
        })
        .then(() => {
          // The following lines need to be in this specific order so that
          // the navigate hook does not stop the execution of the onSave method
          navigate(`/organizations/${response.site.organization_id}/sites/${response.site.id}`);
          onSave();
        });

      // --- End ---
    },
  });

  const deleteSite = useMutation({
    mutationFn: (site: Models.Site) => {
      return siteStore.deleteSite({
        accessToken: authenticationStore.state.accessToken!,
        id: site.id,
      });
    },
    onSuccess: (response) => {
      if (response.success) {
        // The following lines need to be in this specific order so that
        // the navigate hook does not stop the execution of the onDelete method
        navigate(location.pathname.substring(0, location.pathname.indexOf("/sites")));
        if (onDelete) onDelete();
        // --- End ---
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("SiteDetail-deleteError"), response.correlationId);
    },
  });

  const mutationLoading = updateSiteIsLoading || createSiteIsLoading;

  const title = useMemo(() => {
    switch (data.mode) {
      case "create":
        return WebHelper.formatMessage("SiteModal-createTitle");
      case "edit":
        return WebHelper.formatMessage("SiteModal-editTitle");
    }
  }, [data.mode]);

  const handleOk = async () => {
    form
      .validateFields()
      .then((formValues) => {
        switch (data.mode) {
          case "create":
            createSiteMutate({orgID: data.orgID, formValues});
            break;
          case "edit":
            updateSiteMutate({site: data.site, formValues});
        }
      })
      // This catch prevents AntD from throwing an error to the console when form validations fail
      .catch(() => {});
  };

  const handleCancel = () => {
    if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
    else {
      setShowDiscardModal(false);
      onClose();
      form.resetFields();
    }
  };

  const handleDeleteOk = () => {
    if (data.mode !== "edit") return;
    if (deleteSite.isPending || !data.site.id) return;
    deleteSite.mutate(data.site);
  };

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsFormDisabled(true);
    } else {
      setIsFormDisabled(false);
    }
  };

  return (
    <>
      <Modal
        centered
        open={open}
        title={title}
        destroyOnClose={true}
        afterClose={() => form.resetFields()}
        onOk={handleOk}
        okText={WebHelper.formatMessage("Common-save")}
        okButtonProps={{shape: "round", loading: mutationLoading, disabled: mutationLoading || isFormDisabled}}
        cancelButtonProps={{shape: "round", disabled: mutationLoading}}
        onCancel={handleCancel}
        footer={
          data.mode === "edit"
            ? [
                <div
                  className={styles.footer}
                  key="footer"
                  style={
                    !(authenticationStore.permissionLevel(UserScope.Organization) === Models.UserAccessLevel.Admin)
                      ? {justifyContent: "flex-end"}
                      : {}
                  }>
                  {authenticationStore.permissionLevel(UserScope.Site) === Models.UserAccessLevel.Admin && (
                    <LinkButton key="delete" onClick={() => setShowDeleteModal(true)}>
                      {WebHelper.formatMessage("SiteModal-deleteButtonText")}
                    </LinkButton>
                  )}
                  <div className={styles.buttonsWrapper}>
                    <Button key="back" shape="round" disabled={mutationLoading} onClick={handleCancel}>
                      {WebHelper.formatMessage("Common-cancel")}
                    </Button>
                    <Button
                      key="submit"
                      type={ButtonType.Primary}
                      shape="round"
                      disabled={mutationLoading || isFormDisabled}
                      loading={mutationLoading}
                      onClick={handleOk}>
                      {WebHelper.formatMessage("Common-save")}
                    </Button>
                  </div>
                </div>,
              ]
            : undefined
        }>
        <SiteForm
          form={form}
          initialValues={data.mode === "edit" ? data.site : undefined}
          loading={mutationLoading}
          onFieldsChange={handleOnFieldsChange}
        />
      </Modal>
      <ConfirmationModal
        showConfirmationModal={showDeleteModal}
        loading={deleteSite.isPending}
        title={WebHelper.formatMessage("SiteDetail-deleteAlertTitle")}
        message={<Typography.Text>{WebHelper.formatMessage("SiteDetail-deleteAlertMessage")}</Typography.Text>}
        onOk={handleDeleteOk}
        onCancel={() => setShowDeleteModal(false)}
        okButtonText={WebHelper.formatMessage("Common-delete")}
        referenceConfirmationValue={WebHelper.formatMessage("Common-deleteConfirmationValue")}
        width={ConfirmationModalSizes.Small}
      />
      <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
    </>
  );
});
