import React, {useEffect, useState} from "react";

export const useAvailableHeight = (
  parentRef: React.RefObject<HTMLDivElement>,
  dynamicHeightSiblingRef: React.RefObject<HTMLDivElement>
): number => {
  const [availableHeight, setAvailableHeight] = useState(
    parentRef.current && dynamicHeightSiblingRef.current
      ? parentRef.current.getBoundingClientRect().height - dynamicHeightSiblingRef.current.getBoundingClientRect().height
      : 0
  );

  useEffect(() => {
    if (!dynamicHeightSiblingRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      const parentHeight = parentRef.current ? parentRef.current.getBoundingClientRect().height : 0;
      const dynamicHeightSiblingHeight = dynamicHeightSiblingRef.current
        ? dynamicHeightSiblingRef.current.getBoundingClientRect().height
        : 0;

      setAvailableHeight(parentHeight - dynamicHeightSiblingHeight);
    });
    resizeObserver.observe(dynamicHeightSiblingRef.current);
    return () => resizeObserver.disconnect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return availableHeight;
};
