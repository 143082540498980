import {PlusOutlined} from "@ant-design/icons";
import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {Drawer, Dropdown, Space} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {GatewayAlertBlock} from "web/components/gateway-alert-block/GatewayAlertBlock";
import {AppStore, SegmentKey} from "web/stores/AppStore";

import * as Models from "../../../../core/models";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../core/stores/DeviceDataStore";
import {TableSettings} from "../../../utils/TableUtils";
import {WebHelper} from "../../../utils/WebHelper";
import {Button} from "../../common/button/Button";
import {DeviceDetail} from "../../device-detail/DeviceDetail";
import {ChangeDeviceStatusModal} from "./change-device-status-modal/ChangeDeviceStatusModal";
import {DepartmentDevicesTable} from "./department-devices-table/DepartmentDevicesTable";
import {DeviceAllocationModal, DeviceAllocationMode} from "./device-allocation-modal/DeviceAllocationModal";
import {DevicesFilters} from "./devices-filters/DevicesFilters";

type DevicesTabContentProps = {
  department: Models.Department;
};

export const DevicesTabContent: FunctionComponent<DevicesTabContentProps> = ({department}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const navigate = useNavigate();
  const flags = useFlags();

  const [visibleDevice, setVisibleDevice] = useState<Models.DeviceShort>();
  const [devices, setDevices] = useState<Models.DeviceShort[]>([]);
  const [devicesFiltered, setDevicesFiltered] = useState<Models.DeviceShort[]>([]);
  const [selectedTag, setSelectedTag] = useState<Models.DevicesTagsKey>(Models.DevicesTagsKey.All);
  const [tableSettings, setTableSettings] = useState<TableSettings>();
  const [deviceAllocationMode, setDeviceAllocationMode] = useState<DeviceAllocationMode | false>(false);
  const [searchValue, setSearchValue] = useState("");
  const [showChangeDeviceStatusModal, setShowChangeDeviceStatusModal] = useState(false);

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.DevicesTabOpened, {
      orgID: department.organization.id,
      siteID: department.site.id,
      departmentID: department.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const devicesQuery = useQuery({
    queryKey: ["DepartmentDetail-fetchDevicesData", department.id],
    queryFn: () => {
      return deviceStore.devicesShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: department.id,
      });
    },
    enabled: !!department.id,
    placeholderData: keepPreviousData,
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (!devicesQuery.data) return;

    if (devicesQuery.data.success) setDevices(devicesQuery.data.devices.filter((device) => !device.archived_at));
    else WebHelper.showErrorMessage(WebHelper.formatMessage("WorkersTabContent-fetchWorkersDataError"), devicesQuery.data.correlationId);
  }, [devicesQuery.data]);

  useEffect(() => {
    if (!devices) return;
    switch (selectedTag) {
      case Models.DevicesTagsKey.All:
        setDevicesFiltered(devices.filter((device) => device.device_tag.toLowerCase().includes(searchValue.toLowerCase())));
        break;
      case Models.DevicesTagsKey.Assigned:
        setDevicesFiltered(
          devices.filter((device) => device.worker_assignable && device.device_tag.toLowerCase().includes(searchValue.toLowerCase()))
        );
        break;
      case Models.DevicesTagsKey.Available:
        setDevicesFiltered(
          devices.filter(
            (device) =>
              !device.worker_assignable && device.assignable && device.device_tag.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        break;
      case Models.DevicesTagsKey.OutOfService:
        setDevicesFiltered(
          devices.filter((device) => !device.assignable && device.device_tag.toLowerCase().includes(searchValue.toLowerCase()))
        );
        break;
      case Models.DevicesTagsKey.Connected:
        setDevicesFiltered(
          devices.filter(
            (device) =>
              device.status === Models.DeviceStatus.Connected && device.device_tag.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        break;
      case Models.DevicesTagsKey.Disconnected:
        setDevicesFiltered(
          devices.filter(
            (device) =>
              (device.status === Models.DeviceStatus.Disconnected || device.status === Models.DeviceStatus.NeverSeen) &&
              device.device_tag.toLowerCase().includes(searchValue.toLowerCase())
          )
        );
        break;
    }
  }, [selectedTag, devices, searchValue]);

  const handleTagChange = (tag: Models.DevicesTagsKey) => {
    setSelectedTag(tag);
  };

  const handleChangeWorkerStatusModalOpen = (device: Models.DeviceShort) => {
    setVisibleDevice(device);
    setShowChangeDeviceStatusModal(true);
  };

  const handleDeviceStatusChange = () => {
    devicesQuery.refetch();
    setShowChangeDeviceStatusModal(false);
    setVisibleDevice(undefined);
  };

  const handleDeviceStatusChangeCancel = () => {
    setShowChangeDeviceStatusModal(false);
    setVisibleDevice(undefined);
  };

  const createDevicesOptions = [
    {
      key: "manually",
      label: WebHelper.formatMessage("Common-createOptionsManually"),
      onClick: () => {
        setDeviceAllocationMode(DeviceAllocationMode.Manual);
        appStore.sendAnalyticTrack(SegmentKey.AllocateDeviceOpened, {
          orgID: department.organization.id,
          siteID: department.site.id,
          departmentID: department.id,
          mode: DeviceAllocationMode.Manual,
        });
      },
    },
    {
      key: "uploadCSV",
      label: WebHelper.formatMessage("Common-createOptionsUploadCSV"),
      onClick: () => {
        setDeviceAllocationMode(DeviceAllocationMode.CSV);
        appStore.sendAnalyticTrack(SegmentKey.AllocateDeviceOpened, {
          orgID: department.organization.id,
          siteID: department.site.id,
          departmentID: department.id,
          mode: DeviceAllocationMode.CSV,
        });
      },
    },
  ];

  const handleDeviceAllocationOnOk = () => {
    setDeviceAllocationMode(false);
    devicesQuery.refetch();
  };

  const handleDeviceAllocationCancel = () => {
    setDeviceAllocationMode(false);
  };

  const requestDrawerCloseRef = useRef<() => boolean>();
  const handleDrawerClose = () => {
    setVisibleDevice(undefined);
    if (requestDrawerCloseRef.current) requestDrawerCloseRef.current();
  };

  const handleSettingsChange = (action: string, newSettings?: {quickFilter?: Models.DevicesTagsKey; tableSettings?: TableSettings}) => {
    if (action) {
      appStore.sendAnalyticTrack(SegmentKey.DepartmentDevicesTableSettingsChanged, {
        orgID: department.organization.id,
        siteID: department.site.id,
        departmentID: department.id,
        pagination: newSettings?.tableSettings ? newSettings?.tableSettings?.pagination : tableSettings?.pagination,
        sorter: newSettings?.tableSettings ? newSettings?.tableSettings?.sorter : tableSettings?.sorter,
        filters: newSettings?.tableSettings ? newSettings?.tableSettings?.filters : tableSettings?.filters,
        quickFilter: newSettings?.quickFilter ?? selectedTag,
        action: action,
      });
    }
  };

  return (
    <>
      <GatewayAlertBlock />
      {(authenticationStore.permissionLevel(Models.UserScope.Department, department.id) === Models.UserAccessLevel.Admin ||
        authenticationStore.permissionLevel(Models.UserScope.Department, department.id) === Models.UserAccessLevel.Manager) &&
        authenticationStore.isUserGlobal && (
          <Space size={8}>
            <Dropdown menu={{items: createDevicesOptions}} placement="bottom" trigger={["click"]}>
              <Button shape="round" icon={<PlusOutlined />}>
                {WebHelper.formatMessage("DevicesTabContent-allocateDeviceButton")}
              </Button>
            </Dropdown>
            {flags.device_usage_reassignment_button && (
              <Button shape="round" onClick={() => navigate(`${location.pathname}/device_usage_reassignment`)}>
                {WebHelper.formatMessage("DevicesTabContent-deviceUsageReassignmentButton")}
              </Button>
            )}
          </Space>
        )}
      <DevicesFilters
        selectedTag={selectedTag}
        setSelectedTag={handleTagChange}
        setSearchValue={setSearchValue}
        onFilterChange={handleSettingsChange}
      />
      <DepartmentDevicesTable
        devices={devicesFiltered}
        department={department}
        loading={!devicesQuery.isFetched}
        selectDevice={setVisibleDevice}
        filterValue={searchValue}
        refetchDevices={devicesQuery.refetch}
        onOpenChangeWorkerStatusModal={handleChangeWorkerStatusModalOpen}
        setTableSettings={setTableSettings}
        onTableChange={handleSettingsChange}
      />
      <ChangeDeviceStatusModal
        device={visibleDevice}
        open={showChangeDeviceStatusModal}
        onOk={handleDeviceStatusChange}
        onCancel={handleDeviceStatusChangeCancel}
      />
      <DeviceAllocationModal
        mode={deviceAllocationMode}
        department={department}
        onOk={handleDeviceAllocationOnOk}
        onCancel={handleDeviceAllocationCancel}
      />
      <Drawer
        open={!_.isNil(visibleDevice) && !showChangeDeviceStatusModal}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("DeviceDetail-drawerTitle")}
        destroyOnClose
        onClose={handleDrawerClose}>
        {!_.isNil(visibleDevice) && (
          <DeviceDetail deviceId={visibleDevice.id} onClose={() => setVisibleDevice(undefined)} onActionPerformed={devicesQuery.refetch} />
        )}
      </Drawer>
    </>
  );
};
