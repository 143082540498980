interface String {
  replaceAll: (searchvalue: string, newvalue: string) => string;
  trimAll: () => string;
}

interface Map<K, V> {
  toJson: () => object;
}

String.prototype.replaceAll = function (search: string, replacement: string) {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const target = this;

  return target.replace(new RegExp(search, "g"), replacement);
};

String.prototype.trimAll = function () {
  // eslint-disable-next-line @typescript-eslint/no-this-alias
  const target = this;

  return target.replaceAll(" ", "");
};

Map.prototype.toJson = function () {
  return Array.from(this.entries());
};

// https://github.com/microsoft/TypeScript/issues/49231
declare namespace Intl {
  type Key = "calendar" | "collation" | "currency" | "numberingSystem" | "timeZone" | "unit";

  function supportedValuesOf(input: Key): string[];
}
