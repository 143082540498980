import {Typography} from "antd";
import {addDays} from "date-fns";
import React, {FunctionComponent} from "react";

import companyIcon from "../../../../../assets/images/common/Verve_Logo_SafetyOrange.png";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./SiteSummaryReportHeader.module.scss";

type SiteSummaryReportHeaderProps = {
  endDate: string;
};

export const SiteSummaryReportHeader: FunctionComponent<SiteSummaryReportHeaderProps> = ({endDate}) => {
  const formattedEndDate = new Date(endDate).toLocaleDateString("en-US", {year: "numeric", month: "short", day: "numeric"});
  const formattedStartDate = addDays(new Date(endDate), -6).toLocaleDateString("en-US", {year: "numeric", month: "short", day: "numeric"});

  return (
    <div className={styles.header}>
      <div className={styles.title}>
        <Typography.Title level={3}>{WebHelper.formatMessage("SiteSummaryReportPDF-weeklySummaryReport")}</Typography.Title>
        <Typography.Text>
          {WebHelper.formatMessage("SiteSummaryReportPDF-headerDates", {startDate: formattedStartDate, endDate: formattedEndDate})}
        </Typography.Text>
      </div>
      <img className={styles.companyIcon} src={companyIcon} alt={"Company Logo"} />
    </div>
  );
};
