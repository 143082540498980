import * as _ from "lodash";
import {action, makeObservable, observable} from "mobx";

import {AnalyticsAPIClient} from "../apiclient/analytics/AnalyticsAPIClient";
import * as AnalyticsTypes from "../apiclient/analytics/AnalyticsAPIClient.types";

export type AnalyticsState = {
  loadingAnalytics: boolean;
};

export class AnalyticsDataStore {
  private static instance: AnalyticsDataStore | undefined;

  private initialState: AnalyticsState = {
    loadingAnalytics: false,
  };

  state = _.cloneDeep(this.initialState);

  private constructor() {
    makeObservable<AnalyticsDataStore>(this, {
      state: observable,
      analyticsHistory: action,
      analyticsDashboard: action,
      analyticsDashboardCard: action,
      analyticsTimeSeriesData: action,
      analyticsDashboardWorkerData: action,
    });
  }

  static getInstance(): AnalyticsDataStore {
    if (!AnalyticsDataStore.instance) AnalyticsDataStore.instance = new AnalyticsDataStore();

    return AnalyticsDataStore.instance;
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
  }

  async analyticsHistory(request: AnalyticsTypes.AnalyticsHistoryRequest): Promise<AnalyticsTypes.AnalyticsHistoryResponse> {
    return await AnalyticsAPIClient.analyticsHistory(request);
  }

  async analyticsDashboard(request: AnalyticsTypes.AnalyticsDashboardRequest): Promise<AnalyticsTypes.AnalyticsDashboardResponse> {
    return await AnalyticsAPIClient.analyticsDashboard(request);
  }

  async analyticsDashboardCard(
    request: AnalyticsTypes.AnalyticsDashboardCardRequest
  ): Promise<AnalyticsTypes.AnalyticsDashboardCardResponse> {
    return await AnalyticsAPIClient.analyticsDashboardCard(request);
  }

  async analyticsTimeSeriesData(
    request: AnalyticsTypes.AnalyticsTimeSeriesDataRequest
  ): Promise<AnalyticsTypes.AnalyticsTimeSeriesDataResponse> {
    return await AnalyticsAPIClient.analyticsTimeSeriesData(request);
  }

  async analyticsMultiTimeSeriesData(
    request: AnalyticsTypes.AnalyticsMultiTimeSeriesDataRequest
  ): Promise<AnalyticsTypes.AnalyticsMultiTimeSeriesDataResponse> {
    return await AnalyticsAPIClient.analyticsMultiTimeSeriesData(request);
  }

  async analyticsDashboardWorkerData(
    request: AnalyticsTypes.AnalyticsDashboardWorkerDataRequest
  ): Promise<AnalyticsTypes.AnalyticsDashboardWorkerDataResponse> {
    return await AnalyticsAPIClient.analyticsDashboardWorkerData(request);
  }

  async workerSafeliftReport(request: AnalyticsTypes.WorkerSafeliftReportRequest): Promise<AnalyticsTypes.WorkerSafeliftReportResponse> {
    return await AnalyticsAPIClient.WorkerSafeliftReport(request);
  }

  async analyticsDevicesData(request: AnalyticsTypes.AnalyticsDevicesDataRequest): Promise<AnalyticsTypes.AnalyticsDevicesDataResponse> {
    return await AnalyticsAPIClient.analyticsDevicesData(request);
  }
}
