import {observable, makeObservable} from "mobx";

import {AnalyticsTimeSeries} from "./AnalyticsTimeSeriesData";
import {BaseModel} from "./BaseModel";

export type AnalyticsDashboardCardDeviceStatus = {
  current_assigned_count: number;
  current_device_count: number;
  current_out_of_service_count: number;
  current_unassigned_count: number;
};

export type AnalyticsDashboardCardWorkerStatus = {
  current_enrolled_worker_count: number;
  current_waitlist_count: number;
  interval_active_count: number;
  interval_inactive_count: number;
  meets_target_count: number;
  above_target_count: number;
  below_target_count: number;
  exempt_target_count: number;
  unset_target_count: number;
  unenrolled_target_count: number;
};

export class AnalyticsDashboardCard extends BaseModel {
  all_time_weight_offloaded_lbs: number;
  time_series_data: AnalyticsTimeSeries[];
  worker_status: AnalyticsDashboardCardWorkerStatus;
  device_status?: AnalyticsDashboardCardDeviceStatus;

  constructor() {
    super();
    makeObservable(this, {
      device_status: observable,
      worker_status: observable,
      time_series_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDashboardCard, json: any) {
    if (json.time_series_data) {
      object.time_series_data = json.time_series_data.map((timeSeries: AnalyticsTimeSeries) => {
        timeSeries.start_time = new Date(timeSeries.start_time);
        timeSeries.end_time = new Date(timeSeries.end_time);

        return timeSeries;
      });
    }
  }
}
