import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import * as Types from "./DeviceTypeAPIClient.types";

export class DeviceTypeAPIClient extends BaseAPIClient {
  static async deviceTypes(request: Types.DeviceTypesRequest, options: RequestOptions = {}): Promise<Types.DeviceTypesResponse> {
    const {token} = request.accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `device_types/`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          deviceTypes: data.map((deviceType: Models.DeviceType) => Models.DeviceType.fromJSON(deviceType)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async deviceType(request: Types.DeviceTypeRequest, options: RequestOptions = {}): Promise<Types.DeviceTypeResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `device_types/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          deviceType: Models.DeviceType.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }
}
