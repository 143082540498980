import * as _ from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";

import {SessionAPIClient} from "../apiclient/session/SessionAPIClient";
import * as SessionTypes from "../apiclient/session/SessionAPIClient.types";
import * as Models from "../models";

export type SessionsState = {
  loadingSessions: boolean;
  sessions?: Models.Session[];
};

export class SessionDataStore {
  private static instance: SessionDataStore | undefined;

  private initialState: SessionsState = {
    loadingSessions: false,
  };

  state = _.cloneDeep(this.initialState);

  private constructor() {
    makeObservable<SessionDataStore>(this, {
      state: observable,
      sessions: action,
      updateSessionAssignment: action,
      sessionLog: action,
    });
  }

  static getInstance(): SessionDataStore {
    if (!SessionDataStore.instance) SessionDataStore.instance = new SessionDataStore();

    return SessionDataStore.instance;
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
  }

  async sessions(request: SessionTypes.SessionsRequest): Promise<SessionTypes.SessionsResponse> {
    this.state.loadingSessions = true;

    const response = await SessionAPIClient.sessions(request);

    runInAction(() => {
      this.state.loadingSessions = false;
      if (response.success && !_.isNil(response.sessions)) this.state.sessions = response.sessions;
    });

    return response;
  }

  async updateSessionAssignment(
    request: SessionTypes.UpdateSessionAssignmentRequest
  ): Promise<SessionTypes.UpdateSessionAssignmentResponse> {
    return await SessionAPIClient.updateSessionAssignment(request);
  }

  async sessionLog(request: SessionTypes.SessionLogRequest): Promise<SessionTypes.SessionLogResponse> {
    return await SessionAPIClient.sessionLog(request);
  }

  async assignSessions(request: SessionTypes.AssignSessionsRequest): Promise<SessionTypes.AssignSessionsResponse> {
    return await SessionAPIClient.assignSessions(request);
  }
}
