import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import * as Types from "./OrganizationAPIClient.types";

export class OrganizationAPIClient extends BaseAPIClient {
  static async organizations(request: Types.OrganizationsRequest, options: RequestOptions = {}): Promise<Types.OrganizationsResponse> {
    const {token} = request.accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `organizations/`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          organizations: data.map((organization: Models.Organization) => Models.Organization.fromJSON(organization)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async organizationsShort(
    request: Types.OrganizationsRequest,
    options: RequestOptions = {}
  ): Promise<Types.OrganizationsShortResponse> {
    const {token} = request.accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `organizations/short`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          organizations: data.map((organization: Models.OrganizationShort) => Models.OrganizationShort.fromJSON(organization)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async organization(request: Types.OrganizationRequest, options: RequestOptions = {}): Promise<Types.OrganizationResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `organizations/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          organization: Models.Organization.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async createOrganization(
    request: Types.CreateOrganizationRequest,
    options: RequestOptions = {}
  ): Promise<Types.CreateOrganizationResponse> {
    const {name, short_name, address, icon_url, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `organizations/`,
      headers: {token},
      request,
      options,
      data: {name, short_name, address, icon_url},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          organization: Models.Organization.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async deleteOrganization(
    request: Types.DeleteOrganizationRequest,
    options: RequestOptions = {}
  ): Promise<Types.DeleteOrganizationResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `delete`,
      url: `organizations/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      return {
        success: true,
      };
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateOrganization(
    request: Types.UpdateOrganizationRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateOrganizationResponse> {
    const {id, name, short_name, address, icon_url, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `organizations/${id}`,
      headers: {token},
      request,
      options,
      data: {name, short_name, address, icon_url, id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          organization: Models.Organization.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }
}
