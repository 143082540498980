import {useQuery} from "@tanstack/react-query";
import {Empty, Select, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {ControllerSettingsType} from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {FirmwareVersionsDataStore} from "../../../../../core/stores/FirmwareVersionsDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./BulkCreateStepTwo.module.scss";

export type BulkCreateStepTwoProps = {
  deviceGroups: Models.DeviceGroupChecker;
  firmwareVersionIDS: string[];
  updateFirmwareVersionIDS: (ids: string[]) => void;
  buttonSettingsOptions: {key: string; label: string; value: string}[][];
  updateButtonSettingsOptions: React.Dispatch<React.SetStateAction<{key: string; label: string; value: string}[][]>>;
  buttonConfigs: string[][];
  updateButtonConfigs: (ids: string[][]) => void;
};

export const BulkCreateStepTwo: FunctionComponent<BulkCreateStepTwoProps> = observer(
  ({
    deviceGroups,
    firmwareVersionIDS,
    updateFirmwareVersionIDS,
    buttonConfigs,
    updateButtonConfigs,
    buttonSettingsOptions,
    updateButtonSettingsOptions,
  }) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const firmwareVersionsStore = FirmwareVersionsDataStore.getInstance();

    const [currentIndexChanged, setCurrentIndexChanged] = useState<number>();

    const loadButtonSettingsOptions = (controllerSettings: ControllerSettingsType[] | null, index: number) => {
      if (!controllerSettings) {
        const newSettings = {...buttonSettingsOptions};
        newSettings[index] = [];
        updateButtonSettingsOptions(newSettings);
        return;
      }
      const options = controllerSettings.map((controllerSettings: Models.ControllerSettingsType) => ({
        key: controllerSettings.id,
        label: WebHelper.getControllerSettingsName(controllerSettings.name),
        value: controllerSettings.id,
      }));
      options.sort((a, b) => a.label.localeCompare(b.label, undefined, {numeric: true}));

      const newSettings = {...buttonSettingsOptions};
      newSettings[index] = options;
      updateButtonSettingsOptions(newSettings);
    };

    const {data: firmwareVersionQueryData, isFetching: firmwareVersionQueryIsFetching} = useQuery({
      queryKey: ["BulkCreateStepTwo-fetchFirmwareVersionData", firmwareVersionIDS, currentIndexChanged],
      queryFn: () => {
        return firmwareVersionsStore.firmwareVersion({
          accessToken: authenticationStore.state.accessToken!,
          id: firmwareVersionIDS[currentIndexChanged!],
        });
      },
      enabled: currentIndexChanged !== undefined && !!firmwareVersionIDS[currentIndexChanged],
    });

    useEffect(() => {
      const response = firmwareVersionQueryData;
      if (!response?.success) return;
      if (currentIndexChanged !== undefined) loadButtonSettingsOptions(response.firmwareVersion.controller_settings, currentIndexChanged);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentIndexChanged, firmwareVersionQueryData]);

    return (
      <Space direction="vertical">
        <Typography.Text>{WebHelper.formatMessage("BulkCreateStepTwo-groupsMessage")}</Typography.Text>

        {deviceGroups.firmware_groups.map((group, index) => (
          <>
            <Typography.Text strong>{WebHelper.formatMessage("BulkCreateStepTwo-groupName", {count: index + 1})}</Typography.Text>
            <div>
              <Typography.Text>
                {WebHelper.formatMessage("BulkCreateStepTwo-groupCount", {count: group.group_device_count})}
              </Typography.Text>

              {group.device_types.map((type, typeIndex) => {
                return (
                  <Typography.Text>
                    {WebHelper.formatMessage("BulkCreateStepTwo-typeCount", {count: type.count, name: type.name})}
                    {group.device_types.length > 1 ? (typeIndex !== group.device_types.length - 1 ? ", " : "") : ""}
                  </Typography.Text>
                );
              })}
            </div>
            <div className={styles.selectWrapper}>
              <Typography.Text className={styles.selectLabel}>
                {WebHelper.formatMessage("BulkCreateStepTwo-firmwareVersion")}
              </Typography.Text>
              <Select
                className={styles.select}
                showSearch
                optionFilterProp="label"
                optionLabelProp="label"
                value={firmwareVersionIDS[index]}
                options={group.available_firmware_versions.map((version) => ({
                  label: version.name,
                  value: version.id,
                }))}
                onChange={(value) => {
                  const newIds = [...firmwareVersionIDS];
                  newIds[index] = value;
                  updateFirmwareVersionIDS(newIds);
                  const buttonConfigCurrent = ["", "", ""];
                  const newButtonConfigs = buttonConfigs;
                  newButtonConfigs[index] = buttonConfigCurrent;
                  updateButtonConfigs(newButtonConfigs);
                  setCurrentIndexChanged(index);
                }}
                notFoundContent={
                  <Empty
                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                    description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noFirmwareVersions")}
                  />
                }
              />
            </div>
            <Typography.Text>{WebHelper.formatMessage("BulkCreateStepTwo-selectSettingsMessage")}</Typography.Text>
            <div className={styles.selectWrapper}>
              <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("BulkCreateStepTwo-button1")}</Typography.Text>
              <Select
                showSearch
                className={styles.select}
                optionFilterProp="label"
                optionLabelProp="label"
                value={buttonConfigs[index][0]}
                options={buttonSettingsOptions[index]}
                disabled={!firmwareVersionIDS[index] || firmwareVersionQueryIsFetching}
                loading={firmwareVersionQueryIsFetching}
                onChange={(value) => {
                  const newIds = [...buttonConfigs];
                  newIds[index][0] = value;
                  updateButtonConfigs(newIds);
                }}
                notFoundContent={
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={WebHelper.formatMessage("BulkCreateStepTwo-noButtonSettings")} />
                }
              />
            </div>
            <div className={styles.selectWrapper}>
              <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("BulkCreateStepTwo-button2")}</Typography.Text>
              <Select
                showSearch
                className={styles.select}
                optionFilterProp="label"
                optionLabelProp="label"
                value={buttonConfigs[index][1]}
                options={buttonSettingsOptions[index]}
                disabled={!firmwareVersionIDS[index] || firmwareVersionQueryIsFetching}
                loading={firmwareVersionQueryIsFetching}
                onChange={(value) => {
                  const newIds = [...buttonConfigs];
                  newIds[index][1] = value;
                  updateButtonConfigs(newIds);
                }}
                notFoundContent={
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={WebHelper.formatMessage("BulkCreateStepTwo-noButtonSettings")} />
                }
              />
            </div>
            <div className={styles.selectWrapper}>
              <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("BulkCreateStepTwo-button3")}</Typography.Text>
              <Select
                showSearch
                className={styles.select}
                optionFilterProp="label"
                optionLabelProp="label"
                value={buttonConfigs[index][2]}
                options={buttonSettingsOptions[index]}
                disabled={!firmwareVersionIDS[index] || firmwareVersionQueryIsFetching}
                loading={firmwareVersionQueryIsFetching}
                onChange={(value) => {
                  const newIds = [...buttonConfigs];
                  newIds[index][2] = value;
                  updateButtonConfigs(newIds);
                }}
                notFoundContent={
                  <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={WebHelper.formatMessage("BulkCreateStepTwo-noButtonSettings")} />
                }
              />
            </div>
          </>
        ))}
      </Space>
    );
  }
);
