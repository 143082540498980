export enum UnenrollWorkerMainReason {
  Unknown = "unknown",
  Terminated = "terminated",
  Other = "other",
}

export enum UnenrollWorkerOtherReason {
  Back = "back",
  Heat = "heat",
  Constriction = "constriction",
  General = "general",
  NoNeed = "no_need",
  TimeHabit = "time_habit",
  LimitsOverheadReaching = "limits_overhead_reaching",
  ThighWrapsDropping = "thigh_wraps_dropping",
  ExtraHeightWidth = "extra_height_width",
  AssistanceTimeWrong = "assistance_time_wrong",
  JobFunctionChange = "job_function_change",
  PerformanceConcern = "performance_concern",
  OnLeave = "on_leave",
  BadUseCase = "bad_use_case",
  HeavyShoulderPressure = "heavy_shoulder_pressure",
  ShoulderPointPressure = "shoulder_point_pressure",
  PreExistingCondition = "preexisting_condition",
}

export enum UnenrollmentStepsFlow {
  zero = "zero",
  first = "first",
  second = "second",
  glossary = "glossary",
}
