import {CloseCircleFilled, InboxOutlined} from "@ant-design/icons";
import {Radio, Space, Typography, Upload} from "antd";
import {RcFile} from "antd/lib/upload";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import {parse} from "papaparse";
import React, {FunctionComponent, useCallback, useState} from "react";

import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./BulkCreateStepOne.module.scss";

enum RadioOptions {
  csv = "csv",
}

export enum SerialNumberErrorType {
  NotFound,
  AlreadyAllocatedToCurrent,
  AlreadyAllocatedToOther,
}

export type DeviceError = {
  DeviceID: string;
  McuID: string;
  SystemSerialNumber: string;
};

export enum CreateErrorType {
  EmptyRows,
  NoRows,
  MissingColumn,
  SerialNumbersWithErrors,
}

export type AllocationError =
  | {
      type: CreateErrorType.EmptyRows | CreateErrorType.MissingColumn | CreateErrorType.NoRows;
    }
  | {
      type: CreateErrorType.SerialNumbersWithErrors;
      devicesWithErrors?: DeviceError[] | {};
    };

export type BulkCreateStepOneProps = {
  updateDevicesToProcess: React.Dispatch<React.SetStateAction<{mcu_id: string; system_serial_number: string}[]>>;
  updateDevicesError: React.Dispatch<React.SetStateAction<AllocationError | null>>;
  devicesError: AllocationError | null;
};

export const BulkCreateStepOne: FunctionComponent<BulkCreateStepOneProps> = observer(
  ({updateDevicesToProcess, updateDevicesError, devicesError}) => {
    const [selectedRadioOption, setSelectedRadioOption] = useState<RadioOptions>(RadioOptions.csv);

    const onUpload = useCallback(
      (file: RcFile) => {
        parse<{system_serial_number: string; mcu_id: string}>(file, {
          header: true,
          skipEmptyLines: true,
          transformHeader: (headerText) => {
            if (headerText === WebHelper.formatMessage("BulkCreateStepOne-serialNumberColumnName")) {
              return "system_serial_number";
            } else if (headerText === WebHelper.formatMessage("BulkCreateStepOne-mcuIDColumnName")) return "mcu_id";
            return headerText;
          },
          complete: ({data, meta}) => {
            if (data.length === 0) {
              updateDevicesError({type: CreateErrorType.NoRows});
            } else if (!meta.fields?.find((field) => field === "system_serial_number")) {
              updateDevicesError({type: CreateErrorType.MissingColumn});
            } else if (!meta.fields?.find((field) => field === "mcu_id")) {
              updateDevicesError({type: CreateErrorType.MissingColumn});
            } else if (data.find((row) => !row.system_serial_number)) {
              updateDevicesError({type: CreateErrorType.EmptyRows});
            } else {
              updateDevicesError(null);
              updateDevicesToProcess(data);
            }
          },
        });

        return false;
      },
      [updateDevicesError, updateDevicesToProcess]
    );

    return (
      <Space className={styles.wrapper}>
        <Typography.Text>{WebHelper.formatMessage("BulkCreateStepOne-selectDevices")}</Typography.Text>
        <Radio.Group
          className={styles.radioGroup}
          value={selectedRadioOption}
          onChange={(event) => {
            setSelectedRadioOption(event.target.value);
          }}>
          <Space direction="vertical" className={styles.radioWrapper}>
            <Radio value={RadioOptions.csv} className={styles.optionWrapper}>
              <Typography.Text>{WebHelper.formatMessage("BulkCreateStepOne-csvRadioText")}</Typography.Text>
            </Radio>
          </Space>
        </Radio.Group>

        <div className={styles.draggerWrapper}>
          <Upload.Dragger accept={".csv"} beforeUpload={onUpload} maxCount={1} showUploadList={{showRemoveIcon: false}}>
            <div className={styles.draggerContent}>
              <InboxOutlined className={styles.uploadIcon} />
              <Typography.Text>{WebHelper.formatMessage("Common-draggerText")}</Typography.Text>
              {devicesError && (
                <Space className={styles.errorWrapper}>
                  <div>
                    {devicesError.type === CreateErrorType.SerialNumbersWithErrors ? (
                      <div className={styles.errorWithDevices}>
                        <Typography.Text className={styles.errorTitle}>
                          <CloseCircleFilled className={styles.errorIcon} />
                          {WebHelper.formatMessage("BulkCreateStepOne-uploadFailDevicesTitle")}
                        </Typography.Text>
                        {!_.isNil(devicesError.devicesWithErrors) && _.isArray(devicesError.devicesWithErrors) && (
                          <Typography.Text>
                            {devicesError.devicesWithErrors.map((device) => device.SystemSerialNumber).join("-")}
                          </Typography.Text>
                        )}
                      </div>
                    ) : (
                      <Typography.Text className={styles.errorTitle}>
                        <CloseCircleFilled className={styles.errorIcon} />
                        {WebHelper.formatMessage("BulkCreateStepOne-uploadFailTitle")}
                      </Typography.Text>
                    )}
                  </div>
                  {devicesError.type !== CreateErrorType.SerialNumbersWithErrors && (
                    <Typography.Text>{WebHelper.formatMessage("BulkCreateStepOne-uploadFailText")}</Typography.Text>
                  )}
                </Space>
              )}
            </div>
          </Upload.Dragger>
        </div>
      </Space>
    );
  }
);
