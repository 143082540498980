import {DownloadOutlined, ExpandOutlined} from "@ant-design/icons";
import {Card, Space, Typography} from "antd";
import {addDays, differenceInDays, endOfDay, format, isAfter, startOfDay} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import React, {FunctionComponent, useMemo, useState} from "react";

import {WebHelper} from "../../../utils/WebHelper";
import {Button, ButtonType} from "../../common/button/Button";
import {DatePicker} from "../../date-picker/DatePicker";
import styles from "./ReportDrawerContent.module.scss";

type ReportDrawerContentProps = {
  loadingDownload: boolean;
  loadingPreview: boolean;
  message: string;
  onDownload: (startDateFormatted: string, endDateFormatted: string) => void;
  onPreview: (startDateFormatted: string, endDateFormatted: string) => void;
  tz_location?: string;
};

export const ReportDrawerContent: FunctionComponent<ReportDrawerContentProps> = ({
  loadingDownload,
  loadingPreview,
  message,
  onDownload,
  onPreview,
  tz_location,
}) => {
  const [endDate, setEndDate] = useState<Date>(addDays(endOfDay(new Date()), -1));

  const {startDateFormatted, endDateFormatted} = useMemo<{startDateFormatted: string; endDateFormatted: string}>(() => {
    const formatDate = (date: Date) =>
      tz_location
        ? zonedTimeToUtc(date, "UTC").toISOString()
        : format(date, WebHelper.formatMessage("Common-dateTimeFormatWithoutTimezone"));

    const startDateFormatted = formatDate(addDays(startOfDay(endDate), -6));
    const endDateFormatted = formatDate(endDate);

    return {startDateFormatted, endDateFormatted};
  }, [endDate, tz_location]);

  return (
    <Card>
      <Space className={styles.cardContent} direction="vertical" size="large">
        <Typography.Text>
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(message),
            }}
          />
        </Typography.Text>
        <Space size="large">
          <Typography.Text>{WebHelper.formatMessage("ReportDrawerContent-reportEndDate")}</Typography.Text>
          <DatePicker
            allowClear={false}
            disabled={loadingDownload || loadingPreview}
            value={endDate}
            onChange={(date) => date && setEndDate(endOfDay(date))}
            disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
            dateRender={(current) => {
              const isLastSevenDays = differenceInDays(endDate, current) > 0 && differenceInDays(endDate, current) <= 6;

              return <div className={`ant-picker-cell-inner ${isLastSevenDays && styles.selectedDay}`}>{current.getDate()}</div>;
            }}
          />
        </Space>
        <Space className={styles.actionButtons} align="center" size="middle">
          <Button
            shape="round"
            icon={<DownloadOutlined />}
            loading={loadingDownload}
            disabled={loadingPreview}
            onClick={() => onDownload(startDateFormatted, endDateFormatted)}>
            {WebHelper.formatMessage("ReportDrawerContent-downloadButtonText")}
          </Button>
          <Button
            shape="round"
            type={ButtonType.Primary}
            icon={<ExpandOutlined />}
            loading={loadingPreview}
            disabled={loadingDownload}
            onClick={() => onPreview(startDateFormatted, endDateFormatted)}>
            {WebHelper.formatMessage("ReportDrawerContent-previewButtonText")}
          </Button>
        </Space>
      </Space>
    </Card>
  );
};
