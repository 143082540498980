import {useQuery} from "@tanstack/react-query";
import {Drawer} from "antd";
import {AnalyticsDataStore} from "core/stores/AnalyticsDataStore";
import {WorkerDataStore} from "core/stores/WorkerDataStore";
import {WorkerReportGroupDataStore} from "core/stores/WorkerReportGroupDataStore";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {AppStore, SegmentKey} from "web/stores/AppStore";
import {SegmentEntryPoint, SegmentResourceType} from "web/utils/SegmentHelper";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {WorkerSafeliftReportDrawerContent} from "./worker-safelift-report-drawer-content/WorkerSafeliftReportDrawerContent";
import {WorkerSafeliftReportPreviewModal} from "./worker-safelift-report-preview-modal/WorkerSafeliftReportPreviewModal";

type AllWorkersReportDrawerProps = {
  departmentID: string;
  siteID: string;
  onClose: () => void;
  open: boolean;
  tz_location?: string;
};

type MultipleWorkersEntity = Models.EntityType.Department | Models.EntityType.WorkerReportGroup;

export const AllWorkersReportDrawer: FunctionComponent<AllWorkersReportDrawerProps> = ({
  departmentID,
  siteID,
  onClose,
  open,
  tz_location,
}) => {
  const analyticsStore = AnalyticsDataStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const workerStore = WorkerDataStore.getInstance();
  const workerReportGroupStore = WorkerReportGroupDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const {user: currentUser} = authenticationStore.state;

  const {siteId, departmentId, orgId} = useParams();

  const [endTime, setEndTime] = useState<string>("");
  const [showInactiveWorkers, setShowInactiveWorkers] = useState(false);

  const [isReportPreviewModalVisible, setIsReportPreviewModalVisible] = useState(false);
  const [workerSafeliftReports, setWorkerSafeliftReports] = useState<Models.WorkerSafeliftReport[]>([]);
  const [selectedEntity, setSelectedEntity] = useState<{type: MultipleWorkersEntity; id: string}>({
    type: Models.EntityType.Department,
    id: departmentID,
  });

  const departmentWorkers = useQuery({
    queryKey: ["AllWorkersReportDrawer-departmentWorkers", departmentID],
    queryFn: () =>
      workerStore.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: departmentID,
      }),
    enabled: !!departmentID,
  });

  const multipleWorkerSafeliftReport = useQuery({
    queryKey: ["AllWorkersReportDrawer-fetchReportWorkers", selectedEntity, endTime],
    queryFn: () =>
      selectedEntity &&
      analyticsStore.workerSafeliftReport({
        accessToken: authenticationStore.state.accessToken!,
        resource_type: selectedEntity.type,
        resource_id: selectedEntity.id,
        include_all_enrolled_workers: showInactiveWorkers,
        report_bounds: {
          end_time: endTime,
          offset_minutes: -new Date().getTimezoneOffset(),
        },
      }),
    enabled: !!selectedEntity && endTime !== "",
    staleTime: 0,
  });

  useEffect(() => {
    if (multipleWorkerSafeliftReport.isSuccess && multipleWorkerSafeliftReport.data?.workerReportData) {
      setWorkerSafeliftReports(
        multipleWorkerSafeliftReport.data?.workerReportData.filter((workerReport) =>
          departmentWorkers.data?.workers?.find((worker) => worker.id == workerReport.worker_id)
        )
      );
    }

    if (multipleWorkerSafeliftReport.isError) WebHelper.showErrorMessage(WebHelper.formatMessage("ReportDrawer-fetchReportError"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multipleWorkerSafeliftReport.isSuccess, multipleWorkerSafeliftReport.isError, multipleWorkerSafeliftReport.data]);

  const workerReportGroupsQuery = useQuery({
    queryKey: ["AllWorkersReportDrawers-fetchWorkerReportGroups", siteID],
    queryFn: () =>
      workerReportGroupStore.workerReportGroups({
        accessToken: authenticationStore.state.accessToken!,
        site_id: siteID,
      }),
    enabled: !!siteID,
  });

  useEffect(() => {
    if (!workerReportGroupsQuery.data) return;

    if (!workerReportGroupsQuery.data?.success)
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError"),
        workerReportGroupsQuery.data?.correlationId
      );
  }, [workerReportGroupsQuery.data]);

  const handleRefetch = async () => {
    const refetchData = await multipleWorkerSafeliftReport.refetch();

    const bulkWorkerSegmentEventData = {
      departmentID: departmentId,
      siteID: siteId,
      orgID: orgId,
      userOrg: currentUser?.organization_name,
      endDateWeekday: new Date(endTime).toLocaleDateString("en-US", {weekday: "long"}),
      endDate: new Date(endTime).toLocaleDateString("en-US", {day: "2-digit", month: "2-digit", year: "numeric"}),
      resourceType:
        selectedEntity.type == Models.EntityType.WorkerReportGroup ? SegmentResourceType.WORKER_GROUP : SegmentResourceType.DEPARTMENT,
    };
    if (selectedEntity.type == Models.EntityType.WorkerReportGroup) bulkWorkerSegmentEventData["groupID"] = selectedEntity.id;

    if (refetchData.isSuccess) {
      setIsReportPreviewModalVisible(true);
      appStore.sendAnalyticTrack(SegmentKey.ViewSafeliftReportBulk, bulkWorkerSegmentEventData);
    }
  };

  const handleOnPreview = async (startDateFormatted: string) => {
    await setEndTime(startDateFormatted);
    await setWorkerSafeliftReports([]);
    handleRefetch();
  };

  const handleShowInactiveWorkersSwitch = async (inactiveWorkers: boolean) => {
    await setShowInactiveWorkers(inactiveWorkers);
    await setWorkerSafeliftReports([]);
    handleRefetch();
  };

  const handleCancel = () => {
    setShowInactiveWorkers(false);
    setIsReportPreviewModalVisible(false);
  };

  return (
    <>
      <Drawer
        open={open}
        width={WebHelper.drawerWidth}
        destroyOnClose
        title={WebHelper.formatMessage("AllWorkersReportDrawer-drawerTitle")}
        onClose={onClose}>
        <WorkerSafeliftReportDrawerContent
          loadingDownload={multipleWorkerSafeliftReport.isFetching}
          loadingPreview={multipleWorkerSafeliftReport.isFetching}
          message={WebHelper.formatMessage("AllWorkersReportDrawer-contentMessage")}
          onPreview={handleOnPreview}
          reportGroups={workerReportGroupsQuery.data?.workerReportGroups}
          setSelectedEntity={setSelectedEntity}
          tz_location={tz_location}
        />
      </Drawer>
      <WorkerSafeliftReportPreviewModal
        open={isReportPreviewModalVisible}
        workerSafeliftReports={workerSafeliftReports}
        onCancel={handleCancel}
        entryPoint={SegmentEntryPoint.ALL_WORKERS_REPORT}
        endTime={endTime}
        groupId={selectedEntity.type == Models.EntityType.WorkerReportGroup ? selectedEntity.id : undefined}
        mode={"multiple"}
        onShowInactive={handleShowInactiveWorkersSwitch}
        inactiveWorkers={showInactiveWorkers}
        isRefetching={multipleWorkerSafeliftReport.isRefetching}
      />
    </>
  );
};
