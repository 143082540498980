import * as Models from "../../models";
import {
  AuthenticatedRequest,
  SuccessfulResponse,
  FailedFetchResponse,
  FailedCreateResponse,
  FailedUpdateResponse,
} from "../BaseAPIClient.types";
import {AllocateEntitiesToDepartmentRequest, AllocateEntityToDepartmentRequest} from "../department/DepartmentAPIClient.types";

export type DevicesRequest = AuthenticatedRequest & {
  department_id?: string;
  site_id?: string;
  organization_id?: string;
  unallocated?: true;
  system_serial_number_substring?: string;
  device_tag_substring?: string;
};

export type DeviceRequest = AuthenticatedRequest & {
  id: string;
};

export type SuccessfulDevicesResponse = SuccessfulResponse & {
  devices: Models.Device[];
};

export type FailedDevicesResponse = FailedFetchResponse & {
  devices?: undefined;
};

export type DevicesResponse = SuccessfulDevicesResponse | FailedDevicesResponse;

export type SuccessfulDevicesShortResponse = SuccessfulResponse & {
  devices: Models.DeviceShort[];
};

export type DevicesShortResponse = SuccessfulDevicesShortResponse | FailedDevicesResponse;

export type SuccessfulDeviceResponse = SuccessfulResponse & {
  device: Models.Device;
};

export type FailedDeviceResponse = FailedFetchResponse & {
  device?: undefined;
};

export type DeviceResponse = SuccessfulDeviceResponse | FailedDeviceResponse;

export type CreateDeviceRequest = AuthenticatedRequest & {
  department_id: string;
  mcu_id: string;
  system_serial_number: string;
  device_tag: string;
  device_type_id: string;
  status: string;
};

export type FailedCreateDeviceResponse = FailedCreateResponse & {
  device?: undefined;
};

export type CreateDeviceResponse = SuccessfulDeviceResponse | FailedCreateDeviceResponse;

export type DeleteDeviceRequest = AuthenticatedRequest & {
  id: string;
};

export type DeleteDeviceResponse = SuccessfulResponse | FailedFetchResponse;

export type UpdateDeviceRequest = AuthenticatedRequest & {
  id: string;
  department_id: string | null;
  mcu_id: string;
  system_serial_number: string;
  device_tag: string;
  device_type_id: string;
  assignable: boolean;
  archived_at: Date | null;
};

export type FailedUpdateDeviceResponse = FailedUpdateResponse & {
  device?: undefined;
};

export type UpdateDeviceResponse = SuccessfulDeviceResponse | FailedUpdateDeviceResponse;

export type UpdateDeviceConfigurationRequest = AuthenticatedRequest & {
  id: string;
  override_pending: boolean;
  firmware_version_id: string;
  button_1_controller_setting_id: string;
  button_2_controller_setting_id: string;
  button_3_controller_setting_id: string;
};

export type FailedUpdateDeviceConfigurationResponse = FailedUpdateResponse & {
  device?: undefined;
};

export type UpdateDeviceConfigurationResponse = SuccessfulDeviceResponse | FailedUpdateDeviceConfigurationResponse;

export type AllocateDeviceToDepartmentRequest = AuthenticatedRequest & AllocateEntityToDepartmentRequest;

export type AllocateDevicesToDepartmentRequest = AuthenticatedRequest & AllocateEntitiesToDepartmentRequest;

export type AllocateDeviceToDepartmentResponse = SuccessfulDeviceResponse | FailedUpdateDeviceResponse;

export type AllocateDevicesToDepartmentResponse = SuccessfulDevicesResponse | FailedDevicesResponse;

export type AssignWorkerToDeviceRequest = AuthenticatedRequest & {
  worker_id: string | null;
  device_id: string;
  assignable: boolean;
};

export type AssignWorkerToDeviceResponse = SuccessfulDeviceResponse | FailedUpdateDeviceResponse;

export enum DeviceManagementSelectOptions {
  Unallocated = "unallocated",
}

// Bulk Create Devices
export type BulkCreateDevicesRequest = AuthenticatedRequest & {
  device_info: {
    system_serial_number: string;
    mcu_id: string;
    device_tag: string;
    device_type_id: string;
    firmware_version_id: string;
    button_1_controller_setting_id: string;
    button_2_controller_setting_id: string;
    button_3_controller_setting_id: string;
  }[];
};
export type FailedBulkCreateDevicesResponse = FailedCreateResponse & {
  devices?: undefined;
};
export type SuccessfulBulkCreateDevicesResponse = SuccessfulResponse & {
  devices: Models.Device[];
};
export type BulkCreateDevicesResponse = SuccessfulBulkCreateDevicesResponse | FailedBulkCreateDevicesResponse;

//Archive Device
export type ArchiveDeviceRequest = AuthenticatedRequest & {
  device_id: string;
};

export type ArchiveDeviceResponse = SuccessfulDeviceResponse | FailedUpdateDeviceResponse;

//Devices Groups
export type DeviceGroupsRequest = AuthenticatedRequest & {
  organization_ids: string[];
  site_ids: string[];
  department_ids: string[];
  device_ids: string[];
};

export type SuccessfulDeviceGroupsResponse = SuccessfulResponse & {
  devicesGroups: Models.DeviceGroup;
};

export type FailedDeviceGroupsResponse = FailedFetchResponse & {
  devicesGroups?: undefined;
};

export type DeviceGroupsResponse = SuccessfulDeviceGroupsResponse | FailedDeviceGroupsResponse;

//BUlK UPDATE
export type DeviceBulkUpdateRequest = AuthenticatedRequest & {
  firmware_groups: {
    firmware_version_id: string;
    device_ids: string[];
    button_1_controller_setting_id: string;
    button_2_controller_setting_id: string;
    button_3_controller_setting_id: string;
    override_pending: boolean;
  }[];
};

export type SuccessfulDeviceBulkUpdateResponse = SuccessfulResponse & {
  devices?: Models.DeviceGroup;
};

export type FailedDeviceBulkUpdateResponse = FailedFetchResponse & {
  devices?: undefined;
};

export type DeviceBulkUpdateResponse = SuccessfulDeviceBulkUpdateResponse | FailedDeviceBulkUpdateResponse;

//Device Search Tree
export type DeviceSearchTreeRequest = AuthenticatedRequest;

export type SuccessfulDeviceSearchTreeResponse = SuccessfulResponse & {
  organizations: {
    id: string;
    name: string;
    site_ids: {
      id: string;
      name: string;
      department_ids: {
        id: string;
        name: string;
        device_ids: {
          id: string;
          name: string;
        }[];
      }[];
    }[];
  }[];
};

export type FailedDeviceSearchTreeResponse = FailedFetchResponse & {
  organizations?: undefined;
};

export type DeviceSearchTreeResponse = SuccessfulDeviceSearchTreeResponse | FailedDeviceSearchTreeResponse;

//Device check
export type DeviceCheckRequest = AuthenticatedRequest & {
  devices_serial_numbers: {
    mcu_id: string;
    system_serial_number: string;
  }[];
};

export type SuccessfulDeviceCheckResponse = SuccessfulResponse & {
  devicesGroups: Models.DeviceGroupChecker;
};

export type FailedDeviceCheckResponse = FailedCreateResponse & {
  devicesGroups?: {DeviceID: string; McuID: string; SystemSerialNumber: string}[] | {};
};
export type DeviceCheckResponse = SuccessfulDeviceCheckResponse | FailedDeviceCheckResponse;

//Device bulk create
export type CreateDeviceBulkRequest = AuthenticatedRequest & {
  department_id: string | null;
  override_pending: boolean;
  groups_devices: {
    firmware_version_id: string;
    device_type_id: string;
    devices_info: {
      mcu_id: string;
      system_serial_number: string;
    }[];
    button_1_controller_setting_id: string;
    button_2_controller_setting_id: string;
    button_3_controller_setting_id: string;
  }[];
};

export type SuccessfulCreateDeviceBulkResponse = SuccessfulResponse & {
  devices: Models.Device;
};

export type FailedCreateDeviceBulkResponse = FailedCreateResponse & {
  devices?: undefined;
};

export type CreateDeviceBulkResponse = SuccessfulCreateDeviceBulkResponse | FailedCreateDeviceBulkResponse;
