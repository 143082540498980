import {Drawer} from "antd";
import {ColumnsType} from "antd/lib/table";
import {isSameDay} from "date-fns";
import {utcToZonedTime} from "date-fns-tz";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useState, useEffect} from "react";
import Highlighter from "react-highlight-words";

import * as Models from "../../../../../core/models";
import {DepartmentDevicesTabTableSettings} from "../../../../../core/models";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import {LinkButton} from "../../../common/link-button/LinkButton";
import {Table} from "../../../common/table/Table";
import {ConnectedColumnRender} from "../../../devices-table/connected-column-render/ConnectedColumnRender";
import {WorkerDetail} from "../../../worker-detail/WorkerDetail";
import styles from "./LiveCheckedOutDevicesTable.module.scss";

const defaultSettings: DepartmentDevicesTabTableSettings = {
  pageSize: 10,
  sortColumn: "device_tag",
  sortOrder: "ascend",
};

export type LiveCheckedOutDevicesTableProps = {
  devices: Models.DeviceShort[];
  selectDevice: (device: Models.DeviceShort) => void;
  onOpenChangeWorkerStatusModal: (device: Models.DeviceShort) => void;
  loading: boolean;
  filterValue?: string;
  refetchDevices: () => void;
  department: Models.Department;
  selectedTag: Models.DevicesLiveCheckoutTagsKey;
  setTableSettings: (settings: TableSettings) => void;
  onTableChange: (action: string, newSettings?: {quickFilter?: Models.DevicesLiveCheckoutTagsKey; tableSettings?: TableSettings}) => void;
};

export const LiveCheckedOutDevicesTable: FunctionComponent<LiveCheckedOutDevicesTableProps> = observer((props) => {
  const [visibleWorker, setVisibleWorker] = useState<Models.Worker | Models.BasicWorker>();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const liveCheckedOutDevicesTabTableSettings: Models.LiveCheckedOutDevicesTabTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.liveCheckedOutDevicesTab ?? {})}),
    [userPreferences]
  );

  const initialTableSettings = {
    pagination: {current: 1, position: ["bottomCenter"], pagination: liveCheckedOutDevicesTabTableSettings.pageSize, showSizeChanger: true},
    sorter: {columnKey: liveCheckedOutDevicesTabTableSettings.sortColumn, order: liveCheckedOutDevicesTabTableSettings.sortOrder},
    filters: {},
  };

  useEffect(() => {
    props.setTableSettings(initialTableSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDrawerClose = () => {
    setVisibleWorker(undefined);
  };

  const handleDeleteOk = () => {
    handleDrawerClose();
    props.refetchDevices();
  };

  const {pageSize, sortColumn, sortOrder} = liveCheckedOutDevicesTabTableSettings;

  const getCheckedOutDisplayText = (record: Models.DeviceShort) =>
    record.checked_out_status == Models.DevicesLiveCheckoutTagsKey.DeviceIdle
      ? WebHelper.formatMessage("DeviceLiveCheckoutFilters-idleTagText")
      : record.checked_out_status == Models.DevicesLiveCheckoutTagsKey.DeviceNotCheckedOut
        ? WebHelper.formatMessage("DeviceLiveCheckoutFilters-notCheckedOutTagText")
        : WebHelper.formatMessage("DeviceLiveCheckoutFilters-checkedOutRecentlyTagText");

  const columns: ColumnsType<Models.DeviceShort> = [
    {
      title: WebHelper.formatMessage("LiveCheckedOutDeviceTable-deviceTagColumnTitle"),
      key: "device_tag",
      sorter: (a, b) => a.device_tag.localeCompare(b.device_tag),
      sortOrder: sortColumn === "device_tag" ? sortOrder : undefined,
      render: (_, record) => (
        <LinkButton onClick={() => props.selectDevice(record)}>
          <Highlighter
            highlightClassName={styles.highlightText}
            searchWords={[props.filterValue ?? ""]}
            autoEscape
            textToHighlight={record.device_tag}
          />
        </LinkButton>
      ),
      width: "16%",
    },
    {
      title: WebHelper.formatMessage("LiveCheckedOutDeviceTable-workerColumnTitle"),
      key: "assigned_worker",
      render: (_, record) => (
        <LinkButton onClick={() => setVisibleWorker(record.assigned_worker ?? undefined)}>
          <Highlighter
            highlightClassName={styles.highlightText}
            searchWords={[props.filterValue ?? ""]}
            autoEscape
            textToHighlight={record.assigned_worker?.displayName || ""}
          />
        </LinkButton>
      ),
      sorter: (a, b) => {
        const aValue = renderAssignedWorker(a);
        const bValue = renderAssignedWorker(b);
        return aValue.localeCompare(bValue);
      },
      sortOrder: sortColumn === "assigned_worker" ? sortOrder : undefined,
      width: "25%",
    },
    ...(props.selectedTag == Models.DevicesLiveCheckoutTagsKey.All ||
    props.selectedTag == Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed
      ? [
          {
            title: WebHelper.formatMessage("LiveCheckedOutDeviceTable-dataUploadedTodayColumnTitle"),
            key: "data_uploaded_today",
            align: "center" as any,
            sorter: (a: Models.DeviceShort, b: Models.DeviceShort) => {
              const aValue =
                a.checked_out_status === Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed ||
                (a.checked_out_status === Models.DevicesLiveCheckoutTagsKey.DeviceCheckedOut &&
                  a.last_session_start_time &&
                  isSameDay(utcToZonedTime(new Date(), a.site_tz_location), a.last_session_start_time));
              const bValue =
                b.checked_out_status === Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed ||
                (b.checked_out_status === Models.DevicesLiveCheckoutTagsKey.DeviceCheckedOut &&
                  b.last_session_start_time &&
                  isSameDay(utcToZonedTime(new Date(), b.site_tz_location), b.last_session_start_time));
              return aValue && !bValue ? -1 : !aValue && bValue ? 1 : 0;
            },
            sortOrder: sortColumn === "data_uploaded_today" ? sortOrder : undefined,

            render: (_: any, record: Models.DeviceShort) =>
              record.checked_out_status === Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed ||
              (record.checked_out_status === Models.DevicesLiveCheckoutTagsKey.DeviceCheckedOut &&
                record.last_session_start_time &&
                isSameDay(utcToZonedTime(new Date(), record.site_tz_location), record.last_session_start_time)) ? (
                <span className={styles.greenCircle} />
              ) : (
                ""
              ),
          },
        ]
      : []),
    ...(props.selectedTag == Models.DevicesLiveCheckoutTagsKey.All
      ? [
          {
            title: WebHelper.formatMessage("LiveCheckedOutDeviceTable-checkedOutColumnTitle"),
            key: "checked_out_status",
            sorter: (a: Models.DeviceShort, b: Models.DeviceShort) => {
              const aText = getCheckedOutDisplayText(a);
              const bText = getCheckedOutDisplayText(b);
              return aText > bText ? 1 : aText < bText ? -1 : 0;
            },
            sortOrder: sortColumn === "checked_out_status" ? sortOrder : undefined,
            render: (_: any, record: Models.DeviceShort) => getCheckedOutDisplayText(record),
            width: "20%",
          },
        ]
      : []),
    {
      title: WebHelper.formatMessage("LiveCheckedOutDeviceTable-lastSeenColumnTitle"),
      key: "most_recent_gateway_event_at",
      render: (_, record) => (
        <ConnectedColumnRender
          mostRecentGatewayEventAt={record.most_recent_gateway_event_at}
          siteTimezone={record.site_tz_location}
          status={record.status}
        />
      ),
      sorter: (a: Models.DeviceShort, b: Models.DeviceShort) => {
        const connectionState = (device: Models.DeviceShort) =>
          device.status === Models.DeviceStatus.Connected ? 3 : device.status === Models.DeviceStatus.Disconnected ? 2 : 1;

        const statusComparison = connectionState(a) - connectionState(b);
        if (statusComparison !== 0) {
          return statusComparison;
        }

        if (a.status === Models.DeviceStatus.Disconnected && b.status === Models.DeviceStatus.Disconnected) {
          const aDate = a.most_recent_gateway_event_at ? new Date(a.most_recent_gateway_event_at) : new Date(0);
          const bDate = b.most_recent_gateway_event_at ? new Date(b.most_recent_gateway_event_at) : new Date(0);

          return bDate.getTime() - aDate.getTime();
        }

        return 0;
      },
      sortOrder: sortColumn === "most_recent_gateway_event_at" ? sortOrder : undefined,
      width: "20%",
    },
  ];

  const handleSettingsChange = (updatedSettings: Partial<Models.LiveCheckedOutDevicesTabTableSettings>) =>
    setUserPreferences({tableSettings: {liveCheckedOutDevicesTab: {...liveCheckedOutDevicesTabTableSettings, ...updatedSettings}}});

  return (
    <>
      <Table
        columns={columns}
        loading={props.loading}
        dataSource={props.devices}
        onChange={(pagination, filters, sorter, extra) => {
          props.setTableSettings({pagination: pagination, sorter: sorter, filters: filters});
          props.onTableChange(extra.action, {tableSettings: {pagination: pagination, sorter: sorter, filters: filters}});
          if (extra.action === "sort" && !Array.isArray(sorter)) {
            handleSettingsChange({
              sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
              sortOrder: sorter.order,
            });
          }
        }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (_, size) =>
            handleSettingsChange({
              pageSize: size,
            }),
        }}
      />
      <Drawer
        open={visibleWorker !== undefined}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("WorkerDetail-drawerTitle")}
        destroyOnClose
        onClose={handleDrawerClose}>
        {visibleWorker && <WorkerDetail workerId={visibleWorker.id} onDelete={handleDeleteOk} onEdit={props.refetchDevices} />}
      </Drawer>
    </>
  );
});

function renderAssignedWorker(record: Models.DeviceShort) {
  return record.worker_first_name || record.worker_last_name
    ? `${record.worker_first_name} ${record.worker_last_name}`
    : record.worker_employee_id ?? WebHelper.formatMessage("Common-unassignedLabel");
}
