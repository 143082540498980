import * as _ from "lodash";
import {makeObservable, runInAction, observable, action} from "mobx";

import {DeviceTypeAPIClient} from "../apiclient/devicetype/DeviceTypeAPIClient";
import * as DeviceTypeTypes from "../apiclient/devicetype/DeviceTypeAPIClient.types";

export type DeviceTypesState = {
  loadingDeviceTypes: boolean;
  loadingDeviceType: boolean;
};

export class DeviceTypeDataStore {
  private static instance: DeviceTypeDataStore | undefined;

  private initialState: DeviceTypesState = {
    loadingDeviceTypes: false,
    loadingDeviceType: false,
  };

  state = _.cloneDeep(this.initialState);

  private constructor() {
    makeObservable<DeviceTypeDataStore>(this, {
      state: observable,
      deviceTypes: action,
      deviceType: action,
    });
  }

  static getInstance(): DeviceTypeDataStore {
    if (!DeviceTypeDataStore.instance) DeviceTypeDataStore.instance = new DeviceTypeDataStore();

    return DeviceTypeDataStore.instance;
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
  }

  async deviceTypes(request: DeviceTypeTypes.DeviceTypesRequest): Promise<DeviceTypeTypes.DeviceTypesResponse> {
    this.state.loadingDeviceTypes = true;

    const response = await DeviceTypeAPIClient.deviceTypes(request);

    runInAction(() => {
      this.state.loadingDeviceTypes = false;
    });

    return response;
  }

  async deviceType(request: DeviceTypeTypes.DeviceTypeRequest): Promise<DeviceTypeTypes.DeviceTypeResponse> {
    this.state.loadingDeviceType = true;

    const response = await DeviceTypeAPIClient.deviceType(request);

    runInAction(() => {
      this.state.loadingDeviceType = false;
    });

    return response;
  }
}
