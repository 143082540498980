import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import * as Types from "./WorkerAPIClient.types";

export class WorkerAPIClient extends BaseAPIClient {
  static async workers(request: Types.WorkersRequest, options: RequestOptions = {}): Promise<Types.WorkersResponse> {
    const {
      accessToken: {token},
      department_id,
      site_id,
      organization_id,
    } = request;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `workers/`,
      headers: {token},
      request,
      options,
      params: {
        ...(department_id ? {department_id} : {}),
        ...(site_id ? {site_id} : {}),
        ...(organization_id ? {organization_id} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          workers: data.map((worker: Models.Worker) => Models.Worker.fromJSON(worker)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async workersShort(request: Types.WorkersRequest, options: RequestOptions = {}): Promise<Types.WorkersShortResponse> {
    const {
      accessToken: {token},
      department_id,
      site_id,
      organization_id,
      group_id,
    } = request;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `workers/short`,
      headers: {token},
      request,
      options,
      params: {
        ...(department_id ? {department_id} : {}),
        ...(site_id ? {site_id} : {}),
        ...(organization_id ? {organization_id} : {}),
        ...(group_id ? {group_id} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          workers: data.map((worker: Models.WorkerShort) => Models.WorkerShort.fromJSON(worker)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async worker(request: Types.WorkerRequest, options: RequestOptions = {}): Promise<Types.WorkerResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `workers/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          worker: Models.Worker.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async createWorker(request: Types.CreateWorkerRequest, options: RequestOptions = {}): Promise<Types.CreateWorkerResponse> {
    const {first_name, last_name, department_id, employee_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `workers/`,
      headers: {token},
      request,
      options,
      data: {first_name, last_name, department_id, employee_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          worker: Models.Worker.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiCreateErrorResponse(requestResponse);
  }

  static async deleteWorker(request: Types.DeleteWorkerRequest, options: RequestOptions = {}): Promise<Types.DeleteWorkerResponse> {
    const {id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `delete`,
      url: `workers/${id}`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      return {
        success: true,
      };
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateWorker(request: Types.UpdateWorkerRequest, options: RequestOptions = {}): Promise<Types.UpdateWorkerResponse> {
    const {
      id,
      first_name,
      last_name,
      department_id,
      employee_id,
      assignable,
      preferred_language,
      exempt_target,
      target_hours_start_date,
      accessToken,
    } = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `put`,
      url: `workers/${id}`,
      headers: {token},
      request,
      options,
      data: {
        first_name,
        last_name,
        department_id,
        employee_id,
        assignable,
        preferred_language,
        id,
        exempt_target,
        target_hours_start_date,
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          worker: Models.Worker.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async createWorkers(request: Types.CreateWorkersRequest, options: RequestOptions = {}): Promise<Types.CreateWorkersResponse> {
    const {
      department_id,
      worker_info,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `workers/create`,
      headers: {token},
      request,
      options,
      data: {department_id, worker_info},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          workers: data.map((worker: Models.Worker) => Models.Worker.fromJSON(worker)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async allocateWorkerToDepartment(
    request: Types.AllocateWorkerToDepartmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.AllocateWorkerToDepartmentResponse> {
    const {id, department_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `workers/${id}/department`,
      headers: {token},
      request,
      options,
      data: {department_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          worker: Models.Worker.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async assignDeviceToWorker(
    request: Types.AssignDeviceToWorkerRequest,
    options: RequestOptions = {}
  ): Promise<Types.AssignDeviceToWorkerResponse> {
    const {
      worker_id,
      device_id,
      assignable,
      primary_unenrollment_reason,
      unenrollment_reasons,
      accessToken: {token},
    } = request;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `workers/${worker_id}/device`,
      headers: {token},
      request,
      options,
      data: {device_id, assignable, primary_unenrollment_reason, unenrollment_reasons},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          worker: Models.Worker.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiUpdateErrorResponse(requestResponse);
  }

  static async reportWorkerIndividual(
    request: Types.ReportWorkerIndividualRequest,
    options: RequestOptions = {}
  ): Promise<Types.ReportWorkerIndividualResponse> {
    const {accessToken, worker_id, start_time, end_time} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      responseType: "blob",
      requestMethod: `post`,
      url: `workers/${worker_id}/lifts_report_v2`,
      headers: {token},
      request,
      options,
      data: {start_time, end_time},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          file: data,
        };
      }
    }
    return {
      success: false,
      error: this.genericError(),
    };
  }

  static async updateWorkerReportGroups(
    request: Types.UpdateWorkerReportGroupsRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateWorkerReportGroupsResponse> {
    const {accessToken, id, group_ids} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `workers/${id}/groups`,
      headers: {token},
      request,
      options,
      data: {group_ids},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          worker: Models.Worker.fromJSON(data),
        };
      }
    }
    return {
      success: false,
      error: this.genericError(),
    };
  }
}
