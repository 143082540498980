import {SearchOutlined} from "@ant-design/icons";
import {Button, Input, InputRef, Space} from "antd";
import {ColumnType} from "antd/lib/table";
import {FilterConfirmProps} from "antd/lib/table/interface";
import {compareAsc} from "date-fns";
import React, {useEffect, useRef, useState} from "react";
import Highlighter from "react-highlight-words";

import * as Models from "../../core/models";
import styles from "./TableUtils.module.scss";
import {WebHelper} from "./WebHelper";

export function getColumnSearchProps<T>(dataIndex: Extract<keyof T, string>, searchTextControlled?: string): ColumnType<T> {
  const [searchText, setSearchText] = useState(searchTextControlled ?? "");
  const searchInput = useRef<InputRef>(null);

  useEffect(() => setSearchText(searchTextControlled ?? ""), [searchTextControlled]);

  const handleSearch = (selectedKeys: string[], confirm: (param?: FilterConfirmProps) => void) => {
    confirm();
    setSearchText(selectedKeys[0]);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setSearchText("");
  };

  return {
    filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
      <div style={{padding: 8}}>
        <Input
          className={styles.searchInput}
          ref={searchInput}
          placeholder={WebHelper.formatMessage("SearchFilter-inputPlaceholder")}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys as string[], confirm)}
        />
        <Space>
          <Button
            className={styles.button}
            type="primary"
            onClick={() => handleSearch(selectedKeys as string[], confirm)}
            icon={<SearchOutlined />}
            size="small">
            {WebHelper.formatMessage("SearchFilter-searchButton")}
          </Button>
          <Button className={styles.button} onClick={() => clearFilters && handleReset(clearFilters)} size="small">
            {WebHelper.formatMessage("SearchFilter-resetButton")}
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => <SearchOutlined className={filtered ? styles.filteredSearchIcon : ""} />,
    onFilter: (value, record) => {
      const val = dataIndex === "assigned_device" ? mapAssignedDeviceToColumn(record["assigned_device"]) : record[dataIndex];
      return (
        typeof val === "string" &&
        val
          .toString()
          .toLowerCase()
          .includes((value as string).toLowerCase())
      );
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render:
      dataIndex === "assigned_device"
        ? (_, record) => (
            <Highlighter
              highlightClassName={styles.highlightText}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={mapAssignedDeviceToColumn(record["assigned_device"])}
            />
          )
        : (text) => (
            <Highlighter
              highlightClassName={styles.highlightText}
              searchWords={[searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ""}
            />
          ),
  };
}

export function mapAssignedDeviceToColumn(
  worker: Models.WorkerShort | Models.Worker | null,
  unassignedText = WebHelper.formatMessage("DepartmentWorkersTable-naLabel")
): string {
  return worker?.assigned_device?.device_tag ? worker.assigned_device.device_tag : unassignedText;
}

export function mapAssignableToColumn(assignable: boolean) {
  return assignable
    ? WebHelper.formatMessage("DepartmentWorkersTable-enrolledTrueLabel")
    : WebHelper.formatMessage("DepartmentWorkersTable-enrolledFalseLabel");
}

export function dateSorter(firstDate: Date | null, secondDate: Date | null) {
  if (firstDate && secondDate) {
    return compareAsc(firstDate, secondDate);
  } else if (firstDate) {
    return 1;
  } else {
    return -1;
  }
}

export type TableSettings = {
  pagination: {};
  sorter: {};
  filters: {};
};
