import {FileTextOutlined} from "@ant-design/icons";
import {Tooltip, Typography} from "antd";
import {ColumnsType} from "antd/lib/table";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";
import Highlighter from "react-highlight-words";

import * as Models from "../../../../../core/models";
import {DepartmentWorkersTabTableSettings} from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {mapAssignedDeviceToColumn, TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import {ChangeWorkerStatusButton} from "../../../change-worker-status-button/ChangeWorkerStatusButton";
import {LinkButton} from "../../../common/link-button/LinkButton";
import {Table} from "../../../common/table/Table";
import {IndividualWorkerReportDrawer} from "../../../report-drawers/IndividualWorkerReportDrawer";
import {ChangeDeviceModal} from "../change-device-modal/ChangeDeviceModal";
import {DeviceNoWorker} from "../device-no-worker/DeviceNoWorker";
import {EditableDevice} from "../editable-device/EditableDevice";
import styles from "./DepartmentWorkersTable.module.scss";

const defaultSettings: DepartmentWorkersTabTableSettings = {
  pageSize: 10,
  sortColumn: "worker",
  sortOrder: "ascend",
};

export type DepartmentWorkersTableProps = {
  workers: (Models.Worker | Models.WorkerShort)[];
  selectWorker: (worker: Models.Worker) => void;
  loading: boolean;
  filterValue?: string;
  refetchWorkers: () => void;
  onOpenChangeWorkerStatusModal: (worker: Models.Worker | Models.WorkerShort) => void;
  tz_location?: string;
  departmentId: string;
  siteId: string;
  selectedTag: Models.WorkersTagsKey;
  setTableSettings: (settings: TableSettings) => void;
  onTableChange: (action: string, newSettings?: {quickFilter?: Models.WorkersTagsKey; tableSettings?: TableSettings}) => void;
};

export const DepartmentWorkersTable: FunctionComponent<DepartmentWorkersTableProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();

  const [showChangeDeviceModal, setShowChangeDeviceModal] = useState(false);
  const [editingWorker, setEditingWorker] = useState<Models.Worker | Models.WorkerShort | null>(null);
  const [newDevice, setNewDevice] = useState<Models.DeviceShort | undefined>(undefined);
  const [showDeviceNoLongerWorker, setShowDeviceNoLongerWorker] = useState(false);
  const [reportDrawerWorkerData, setReportDrawerWorkerData] = useState<{id: string; displayName: string} | null>(null);

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const departmentWorkersTabTableSettings: Models.DepartmentWorkersTabTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.departmentWorkersTab ?? {})}),
    [userPreferences]
  );

  const initialTableSettings = {
    pagination: {current: 1, position: ["bottomCenter"], pagination: departmentWorkersTabTableSettings.pageSize, showSizeChanger: true},
    sorter: {columnKey: departmentWorkersTabTableSettings.sortColumn, order: departmentWorkersTabTableSettings.sortOrder},
    filters: {},
  };

  useEffect(() => {
    props.setTableSettings(initialTableSettings);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {pageSize, sortColumn, sortOrder} = departmentWorkersTabTableSettings;

  const handleChangeDevice = (worker: Models.Worker | Models.WorkerShort, device?: Models.DeviceShort) => {
    setShowChangeDeviceModal(true);
    setEditingWorker(worker);
    setNewDevice(device);
  };

  const columns: ColumnsType<Models.Worker> = [];

  if (props.workers.filter((w) => w.fullName).length !== 0)
    columns.push({
      title: WebHelper.formatMessage("DepartmentWorkersTable-nameColumnTitle"),
      key: "fullName",
      sorter: (a, b) => a.fullName.localeCompare(b.fullName),
      sortOrder: sortColumn === "fullName" ? sortOrder : undefined,
      render: (_, record) => (
        <LinkButton onClick={() => props.selectWorker(record)}>
          <Highlighter
            highlightClassName={styles.highlightText}
            searchWords={[props.filterValue ?? ""]}
            autoEscape
            textToHighlight={record.fullName}
          />
        </LinkButton>
      ),
      width: "30%",
    });

  if (props.workers.filter((w) => w.employee_id).length !== 0)
    columns.push({
      title: WebHelper.formatMessage("DepartmentWorkersTable-employeeIDColumnTitle"),
      key: "employee_id",
      sorter: (a, b) => a.employee_id.localeCompare(b.employee_id),
      sortOrder: sortColumn === "employee_id" ? sortOrder : undefined,
      render: (_, record) => (
        <LinkButton onClick={() => props.selectWorker(record)}>
          <Highlighter
            highlightClassName={styles.highlightText}
            searchWords={[props.filterValue ?? ""]}
            autoEscape
            textToHighlight={record.employee_id}
          />
        </LinkButton>
      ),
      width: "20%",
    });

  columns.push(
    {
      title: WebHelper.formatMessage("DepartmentWorkersTable-deviceColumnTitle"),
      key: "assigned_device",
      render: (_, record) =>
        authenticationStore.permissionLevel(Models.UserScope.Department, record.department.id) === Models.UserAccessLevel.Admin ||
        authenticationStore.permissionLevel(Models.UserScope.Department, record.department.id) === Models.UserAccessLevel.Manager ? (
          record.assignable ? (
            <EditableDevice worker={record} onSave={handleChangeDevice} />
          ) : (
            <Tooltip title={WebHelper.formatMessage("DepartmentWorkersTable-disabledDeviceColumnValue")}>
              <Typography.Text disabled>
                {mapAssignedDeviceToColumn(record, WebHelper.formatMessage("DepartmentWorkersTable-unassignedDeviceLabel"))}
              </Typography.Text>
            </Tooltip>
          )
        ) : (
          mapAssignedDeviceToColumn(record, WebHelper.formatMessage("DepartmentWorkersTable-unassignedDeviceLabel"))
        ),
      sorter: (a, b) =>
        mapAssignedDeviceToColumn(a, WebHelper.formatMessage("DepartmentWorkersTable-unassignedDeviceLabel")).localeCompare(
          mapAssignedDeviceToColumn(b, WebHelper.formatMessage("DepartmentWorkersTable-unassignedDeviceLabel"))
        ),
      sortOrder: sortColumn === "assigned_device" ? sortOrder : undefined,
      width: "30%",
    },
    {
      title: WebHelper.formatMessage("DepartmentWorkersTable-statusColumnTitle"),
      key: "status",
      width: "10%",
      render: (_, record) => (
        <ChangeWorkerStatusButton worker={record} onOpenChangeWorkerStatusModal={props.onOpenChangeWorkerStatusModal} />
      ),
    },
    {
      title: WebHelper.formatMessage("DepartmentWorkersTable-reportColumnTitle"),
      key: "report",
      render: (_, {id, displayName}) => (
        <FileTextOutlined
          onClick={() => {
            setReportDrawerWorkerData({id, displayName});
          }}
        />
      ),
      width: "10%",
    }
  );

  const handleSettingsChange = (updatedSettings: Partial<Models.DepartmentWorkersTableSettings>) =>
    setUserPreferences({tableSettings: {departmentWorkersTab: {...departmentWorkersTabTableSettings, ...updatedSettings}}});

  return (
    <>
      <Table
        columns={columns}
        loading={props.loading}
        dataSource={props.workers}
        onChange={(pagination, filters, sorter, extra) => {
          props.setTableSettings({pagination: pagination, sorter: sorter, filters: filters});
          props.onTableChange(extra.action, {tableSettings: {pagination: pagination, sorter: sorter, filters: filters}});
          if (extra.action === "sort" && !Array.isArray(sorter)) {
            handleSettingsChange({
              sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
              sortOrder: sorter.order,
            });
          }
        }}
        pagination={{
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (_, size) =>
            handleSettingsChange({
              pageSize: size,
            }),
        }}
      />
      {editingWorker && (
        <ChangeDeviceModal
          worker={editingWorker}
          open={showChangeDeviceModal}
          onClose={() => setShowChangeDeviceModal(false)}
          newDevice={newDevice}
          refetchWorkers={props.refetchWorkers}
          setShowDeviceNoLongerWorker={setShowDeviceNoLongerWorker}
          entryPoint={SegmentEntryPoint.WORKER_TABLE}
        />
      )}
      {showDeviceNoLongerWorker && editingWorker && editingWorker.assigned_device?.id && editingWorker.department?.id && (
        <DeviceNoWorker
          deviceId={editingWorker.assigned_device.id}
          deviceTag={editingWorker.assigned_device.device_tag}
          departmentId={editingWorker.department.id}
          onOk={() => {
            setShowDeviceNoLongerWorker(false);
            props.refetchWorkers();
          }}
          entryPoint={SegmentEntryPoint.WORKER_TABLE}
          onClose={() => setShowDeviceNoLongerWorker(false)}
          open={showDeviceNoLongerWorker}
        />
      )}
      <IndividualWorkerReportDrawer
        open={!!reportDrawerWorkerData}
        onClose={() => setReportDrawerWorkerData(null)}
        tz_location={props.tz_location}
        workerData={reportDrawerWorkerData}
        siteId={props.siteId}
        departmentId={props.departmentId}
      />
    </>
  );
});
