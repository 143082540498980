import {InfoCircleFilled} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Checkbox, Col, Row, Select, Space, Typography} from "antd";
import {endOfDay, isAfter, startOfDay} from "date-fns";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {DatePicker} from "../../../date-picker/DatePicker";
import {WorkerSelected} from "../../AllocateSelectedDataModal";
import styles from "./AllocateDataStepOne.module.scss";

export type AllocateDataStepOneProps = {
  device: Models.DeviceShort;
  departmentId: string;
  updateSelectedWorker: (selectedWorker: WorkerSelected) => void;
  startDate: Date | null;
  updateStartDate: (startDate: Date | null) => void;
  endDate: Date | null;
  updateEndDate: (endDate: Date | null) => void;
  singleDay: boolean;
  updateSingleDay: (singleDay: boolean) => void;
};

export const AllocateDataStepOne: FunctionComponent<AllocateDataStepOneProps> = observer(
  ({device, departmentId, updateSelectedWorker, startDate, updateStartDate, endDate, updateEndDate, singleDay, updateSingleDay}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const workerStore = WorkerDataStore.getInstance();

    const [workers, setWorkers] = useState<Models.WorkerShort[]>([]);
    const workersQuery = useQuery({
      queryKey: ["AllocateDataStepOne-fetchDepartmentWorkersData", departmentId],
      queryFn: () =>
        workerStore.workersShort({
          accessToken: authenticationStore.state.accessToken!,
          department_id: departmentId,
        }),
    });

    useEffect(() => {
      if (workersQuery.data?.success) {
        setWorkers(workersQuery.data.workers);
      }
    }, [workersQuery.data]);

    const handleSelectWorker = (value: any) => {
      const selectedOption = workers.find((worker) => worker.id === value);
      if (selectedOption) {
        updateSelectedWorker({
          id: selectedOption.id,
          label: selectedOption.first_name ? `${selectedOption.first_name}  ${selectedOption.last_name}` : selectedOption.employee_id,
        });
      }
    };

    return (
      <Space direction="vertical" size={16} className={styles.wrapper}>
        <Typography.Text>
          {WebHelper.formatMessage("AllocateSelectedData-selectText")} <span className={styles.deviceTag}>{device.device_tag}</span>.
        </Typography.Text>
        <Row>
          <Col lg={6} md={6}>
            <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("AllocateSelectedData-dateRange")} </Typography.Text>
          </Col>
          <Col lg={12} md={12}>
            <DatePicker
              allowClear={false}
              placeholder={WebHelper.formatMessage("AllocateSelectedData-selectStartDate")}
              value={startDate}
              onChange={(date) => (date ? updateStartDate(startOfDay(date)) : null)}
              disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
            />
          </Col>
          <Col lg={5} md={5} className={styles.checkboxContainer}>
            <Checkbox
              className={styles.checkbox}
              value={singleDay}
              onChange={(e) => {
                updateSingleDay(e.target.checked);
                updateEndDate(null);
              }}
            />
            <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("AllocateSelectedData-singleDay")}</Typography.Text>
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6}></Col>
          <Col lg={12} md={12}>
            <DatePicker
              allowClear={singleDay}
              placeholder={WebHelper.formatMessage("AllocateSelectedData-selectEndDate")}
              value={endDate}
              disabled={singleDay}
              onChange={(date) => (date ? updateEndDate(startOfDay(date)) : null)}
              disabledDate={(date) => isAfter(date, endOfDay(new Date())) || (startDate ? isAfter(startDate, date) : false)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={6} md={6}>
            <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("AllocateSelectedData-worker")} </Typography.Text>
          </Col>
          <Col lg={12} md={12}>
            <Select
              style={{width: "100%"}}
              optionFilterProp="label"
              onChange={(value, label) => (value && label ? handleSelectWorker(value) : null)}
              placeholder={WebHelper.formatMessage("AllocateSelectedData-selectWorker")}
              options={workers.map(({id, displayName, employee_id}) => ({key: id, label: displayName ?? employee_id, value: id}))}
              showSearch
              dropdownRender={(node) => (workersQuery.isFetching ? WebHelper.LoadingSpin : node)}
            />
          </Col>
        </Row>
        <Row>
          <Col lg={24} md={24} className={styles.infoContainer}>
            <div className={styles.infoMessage}>
              <InfoCircleFilled />
              <Typography.Text>{WebHelper.formatMessage("AllocateSelectedData-infoMeesage")}</Typography.Text>
            </div>
          </Col>
        </Row>
      </Space>
    );
  }
);
