import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import {DeviceUsageReassignment} from "web/components/device-usage-reassignment/DeviceUsageReassignment";

import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WorkerReportGroupDetail} from "../../components/entity-details/worker-report-groups-tab-content/worker-report-group-detail/WorkerReportGroupDetail";
import {EntityDetails} from "./entity-details/EntityDetails";
import {Organizations} from "./Organizations";

export const OrganizationsRouting: FunctionComponent = observer(() => {
  const {
    isUserGlobal,
    state: {user},
  } = AuthenticationDataStore.getInstance();

  if (!user) return null;

  return (
    <Routes>
      <Route index element={isUserGlobal ? <Organizations /> : <Navigate to={`/organizations/${user.organization_id}`} />} />
      <Route path=":orgId" element={<EntityDetails />} />
      <Route path=":orgId/sites/:siteId" element={<EntityDetails />} />
      <Route path=":orgId/sites/:siteId/groups/:groupId" element={<WorkerReportGroupDetail />} />
      <Route path=":orgId/sites/:siteId/departments/:departmentId" element={<EntityDetails />} />
      <Route path=":orgId/sites/:siteId/departments/:departmentId/device_usage_reassignment" element={<DeviceUsageReassignment />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
});
