import {Checkbox, Modal, Space} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import * as Models from "../../../core/models";
import {WebHelper} from "../../utils/WebHelper";

type RemoveFromFavoritesModalProps = {
  organization: Models.OrganizationShort;
  open: boolean;
  onRemove: (orgId: string, shouldShowRemoveFromFavoritesModal: boolean) => void;
  onCancel: () => void;
};

export const RemoveFromFavoritesModal: FunctionComponent<RemoveFromFavoritesModalProps> = observer(
  ({organization, open, onRemove, onCancel}) => {
    const [doNotShowMessageAgain, setDoNotShowMessageAgain] = useState(false);

    return (
      <Modal
        centered
        open={open}
        onOk={() => onRemove(organization.id, !doNotShowMessageAgain)}
        okButtonProps={{shape: "round"}}
        onCancel={() => {
          setDoNotShowMessageAgain(false);
          onCancel();
        }}
        cancelButtonProps={{shape: "round"}}
        title={WebHelper.formatMessage("Common-removeFromFavorites")}>
        <Space size={16} direction="vertical">
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(
                WebHelper.formatMessage("RemoveFromFavoritesModal-message", {organization: organization.name})
              ),
            }}
          />
          <Checkbox checked={doNotShowMessageAgain} onChange={(e) => setDoNotShowMessageAgain(e.target.checked)}>
            {WebHelper.formatMessage("RemoveFromFavoritesModal-doNotShowMessageAgainText")}
          </Checkbox>
        </Space>
      </Modal>
    );
  }
);
