import {useQuery} from "@tanstack/react-query";
import {Divider, Empty, Form, FormInstance, Select, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";

import * as Models from "../../../../../../core/models";
import {ControllerSettingsType} from "../../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import {DeviceTypeDataStore} from "../../../../../../core/stores/DeviceTypeDataStore";
import {FirmwareVersionsDataStore} from "../../../../../../core/stores/FirmwareVersionsDataStore";
import {AppConfig} from "../../../../../AppConfig";
import {WebHelper} from "../../../../../utils/WebHelper";
import {UpdateDeviceConfigFormFields} from "../UpdateDeviceConfigurationModal";
import styles from "./UpdateDeviceConfigurationForm.module.scss";

export type UpdateDeviceConfigurationFormProps = {
  device: Models.Device;
  form: FormInstance<UpdateDeviceConfigFormFields>;
  loading: boolean;
  onFieldsChange: () => void;
};

export const UpdateDeviceConfigurationForm: FunctionComponent<UpdateDeviceConfigurationFormProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceTypeStore = DeviceTypeDataStore.getInstance();
  const firmwareVersionsStore = FirmwareVersionsDataStore.getInstance();

  const {updateDeviceConfigForm: updateDeviceConfigInputValidation} = AppConfig.Modules.InputValidation;

  const [buttonSettingsOptions, setButtonSettingsOptions] = useState<{key: string; label: string; value: string}[]>([]);
  const [formFirmwareVersionID, setFormFirmwareVersionID] = useState(props.device.config?.firmware_version?.id ?? "");

  const deviceTypeQuery = useQuery({
    queryKey: ["UpdateDeviceConfigurationForm-fetchDeviceTypeData", props.device.device_type_id],
    queryFn: () => {
      return deviceTypeStore.deviceType({accessToken: authenticationStore.state.accessToken!, id: props.device.device_type_id});
    },
    enabled: !!props.device.device_type_id,
  });

  const loadButtonSettingsOptions = useCallback((controllerSettings: ControllerSettingsType[]) => {
    const options = controllerSettings.map((controllerSettings: Models.ControllerSettingsType) => ({
      key: controllerSettings.id,
      label: WebHelper.getControllerSettingsName(controllerSettings.name),
      value: controllerSettings.id,
    }));

    options.unshift({
      key: "",
      label: WebHelper.formatMessage("Common-unassignedLabel"),
      value: "",
    });
    options.sort((a, b) => a.label.localeCompare(b.label, undefined, {numeric: true}));

    setButtonSettingsOptions(options);
  }, []);

  const firmwareVersionQuery = useQuery({
    queryKey: ["UpdateDeviceConfigurationForm-fetchFirmwareVersionData", formFirmwareVersionID],
    queryFn: async () => {
      return firmwareVersionsStore.firmwareVersion({
        accessToken: authenticationStore.state.accessToken!,
        id: formFirmwareVersionID,
      });
    },
    enabled: !!formFirmwareVersionID,
  });

  useEffect(() => {
    const response = firmwareVersionQuery?.data;
    if (!response) return;
    if (!response.success || !response.firmwareVersion.controller_settings) return;
    loadButtonSettingsOptions(response.firmwareVersion.controller_settings);
  }, [firmwareVersionQuery?.data, loadButtonSettingsOptions]);

  const firmwareVersionOptions = useMemo(() => {
    if (!deviceTypeQuery.data?.deviceType?.firmware_versions) return [];

    const options = deviceTypeQuery.data.deviceType.firmware_versions.map(({id, version, short_hash}) => ({
      key: id,
      label: `${version} (${short_hash})`,
      value: id,
    }));

    options.unshift({
      key: "",
      label: WebHelper.formatMessage("Common-unassignedLabel"),
      value: "",
    });

    return options;
  }, [deviceTypeQuery.data?.deviceType]);

  useEffect(() => {
    props.form.setFieldsValue({
      firmware_version_id: props.device.config?.firmware_version?.id,
      button_1_controller_setting_id: props.device.config?.button_1_controller_setting?.id,
      button_2_controller_setting_id: props.device.config?.button_2_controller_setting?.id,
      button_3_controller_setting_id: props.device.config?.button_3_controller_setting?.id,
    });
  }, [props.device, props.form]);

  return (
    <Space className={styles.formWrapper} size={24} direction="vertical">
      <Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-description")}</Typography.Text>
      <Form
        form={props.form}
        preserve={false}
        labelWrap={true}
        labelCol={{span: 7}}
        wrapperCol={{span: 17}}
        disabled={props.loading}
        onFieldsChange={props.onFieldsChange}
        unselectable="off">
        <Form.Item
          colon={false}
          label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-systemSerialNumberLabel")}</Typography.Text>}>
          <Typography.Text>{props.device.system_serial_number}</Typography.Text>
        </Form.Item>
        <Form.Item
          colon={false}
          label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-hardwareRevLabel")}</Typography.Text>}>
          <Typography.Text>{props.device.item_number_revision}</Typography.Text>
        </Form.Item>
        <Form.Item
          colon={false}
          label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-firmwareVersionLabel")}</Typography.Text>}
          name="firmware_version_id"
          initialValue={formFirmwareVersionID}
          rules={WebHelper.formatInputValidationRules(updateDeviceConfigInputValidation.firmware_version_id.rules)}>
          <Select
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            options={firmwareVersionOptions}
            disabled={deviceTypeQuery.isPending}
            loading={deviceTypeQuery.isPending}
            onChange={(value) => {
              setFormFirmwareVersionID(value);
              props.form.setFieldsValue({
                button_1_controller_setting_id: "",
                button_2_controller_setting_id: "",
                button_3_controller_setting_id: "",
              });
            }}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noFirmwareVersions")}
              />
            }
          />
        </Form.Item>
        <Divider />
        <div className={styles.buttonSettingsTitle}>
          <Typography.Text strong>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonSettingsSubtitle")}</Typography.Text>
        </div>
        <Form.Item
          colon={false}
          label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonLabel", {number: "1"})}</Typography.Text>}
          name="button_1_controller_setting_id"
          initialValue={props.device.config?.button_1_controller_setting?.id ?? ""}
          rules={WebHelper.formatInputValidationRules(updateDeviceConfigInputValidation.button_1_controller_setting_id.rules)}>
          <Select
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            options={buttonSettingsOptions}
            disabled={!formFirmwareVersionID || firmwareVersionQuery.isPending}
            loading={firmwareVersionQuery.isPending}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noButtonSettings")}
              />
            }
          />
        </Form.Item>
        <Form.Item
          colon={false}
          label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonLabel", {number: "2"})}</Typography.Text>}
          name="button_2_controller_setting_id"
          initialValue={props.device.config?.button_2_controller_setting?.id ?? ""}
          rules={WebHelper.formatInputValidationRules(updateDeviceConfigInputValidation.button_2_controller_setting_id.rules)}>
          <Select
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            options={buttonSettingsOptions}
            disabled={!formFirmwareVersionID || firmwareVersionQuery.isPending}
            loading={firmwareVersionQuery.isPending}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noButtonSettings")}
              />
            }
          />
        </Form.Item>
        <Form.Item
          colon={false}
          label={<Typography.Text>{WebHelper.formatMessage("UpdateDeviceConfigurationForm-buttonLabel", {number: "3"})}</Typography.Text>}
          name="button_3_controller_setting_id"
          initialValue={props.device.config?.button_3_controller_setting?.id ?? ""}
          rules={WebHelper.formatInputValidationRules(updateDeviceConfigInputValidation.button_3_controller_setting_id.rules)}>
          <Select
            showSearch
            optionFilterProp="label"
            optionLabelProp="label"
            options={buttonSettingsOptions}
            disabled={!formFirmwareVersionID || firmwareVersionQuery.isPending}
            loading={firmwareVersionQuery.isPending}
            notFoundContent={
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={WebHelper.formatMessage("UpdateDeviceConfigurationForm-noButtonSettings")}
              />
            }
          />
        </Form.Item>
      </Form>
    </Space>
  );
});
