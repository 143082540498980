import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {OnTarget} from "./WorkerShort";

type OneWeekSummaryMetrics = {
  total_lifts: number;
  safe_lifts: number;
  safe_lift_score: number;
  usage_seconds: number;
  hours_used: number;
  weight_offloaded_lbs: number;
  excessive_forward_lifts: number;
  prolonged_bend_lifts: number;
  twisted_lifts: number;
  side_bend_lifts: number;
  safe_lift_score_change: number;
  twisted_lift_score_change: number;
  side_bend_lift_score_change: number;
  excessive_forward_lift_score_change: number;
  prolonged_bend_lift_score_change: number;
  safe_lift_ranking: number;
  weight_offloaded_ranking: number;
  on_target: OnTarget;
  lower_bound: number | null;
  upper_bound: number | null;
};

type OverallSummaryMetrics = {
  total_lifts: number;
  safe_lifts: number;
  safe_lift_score: number;
  total_usage_seconds: number;
  total_hours_used: number;
  total_weight_offloaded_lbs: number;
  first_date_active: Date | null;
  total_days_used: number;
  safe_lift_score_change: number;
  twisted_lift_score_change: number;
  side_bend_lift_score_change: number;
  excessive_forward_lift_score_change: number;
  prolonged_bend_lift_score_change: number;
};

type DailyData = {
  start_time: Date;
  end_time: Date;
  offset_minutes: number;
  time_zone: string;
  usage_sec: number;
  weight_offloaded_lbs: number;
  total_lifts: number;
  safe_lifts: number;
  safe_lift_score: number;
  excessive_forward_lifts: number;
  prolonged_bend_lifts: number;
  twisted_lifts: number;
  side_bend_lifts: number;
};

type WeeklyData = {
  start_time: Date;
  end_time: Date;
  offset_minutes: number;
  time_zone: string;
  usage_sec: number;
  weight_offloaded_lbs: number;
  total_lifts: number;
  safe_lifts: number;
  safe_lift_score: number;
  excessive_forward_lifts: number;
  prolonged_bend_lifts: number;
  twisted_lifts: number;
  side_bend_lifts: number;
};

export class WorkerSafeliftReport extends BaseModel {
  worker_id: string;
  worker_first_name: string;
  worker_last_name: string;
  worker_employee_id: string;
  worker_preferred_language: string;
  device_serial_number: string;
  device_tag: string;
  start_time: Date;
  end_time: Date;
  offset_minutes: number;
  time_zone: string;
  department_average_safe_lift_score_24_weeks: number;
  one_week_summary_metrics: OneWeekSummaryMetrics;
  overall_summary_metrics: OverallSummaryMetrics;
  daily_data: DailyData[];
  weekly_data: WeeklyData[];

  constructor() {
    super();
    makeObservable(this, {
      worker_id: observable,
      worker_first_name: observable,
      worker_last_name: observable,
      worker_employee_id: observable,
      worker_preferred_language: observable,
      device_serial_number: observable,
      device_tag: observable,
      start_time: observable,
      end_time: observable,
      offset_minutes: observable,
      time_zone: observable,
      department_average_safe_lift_score_24_weeks: observable,
      one_week_summary_metrics: observable,
      overall_summary_metrics: observable,
      daily_data: observable,
    });
  }

  get fullName(): string {
    return this.worker_first_name && this.worker_last_name ? `${this.worker_first_name} ${this.worker_last_name}` : this.worker_employee_id;
  }

  static fixObjectFromJSON(object: WorkerSafeliftReport, json: any) {
    if (json.start_time) object.start_time = new Date(json.start_time);
    if (json.end_time) object.end_time = new Date(json.end_time);
    if (json.overall_summary_metrics?.first_date_active)
      object.overall_summary_metrics.first_date_active = json.overall_summary_metrics?.first_date_active
        ? new Date(json.overall_summary_metrics.first_date_active)
        : null;
    if (json.daily_data?.start_time)
      object.daily_data.forEach((dayData, i) => {
        dayData.start_time = new Date(json.daily_data[i].start_time);
        dayData.end_time = new Date(json.daily_data[i].end_time);
      });
    if (json.weekly_data?.start_time)
      object.weekly_data.forEach((weekData, i) => {
        weekData.start_time = new Date(json.weekly_data[i].start_time);
        weekData.end_time = new Date(json.weekly_data[i].end_time);
      });
  }
}
