import {DownloadOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Dropdown} from "antd";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import {WorkerDataStore} from "core/stores/WorkerDataStore";
import {WorkerReportGroupDataStore} from "core/stores/WorkerReportGroupDataStore";
import {addDays, differenceInDays, format, secondsToHours, startOfDay} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import _ from "lodash";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useRef, useState} from "react";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";
import {AppConfig} from "web/AppConfig";
import {GatewayAlertBlock} from "web/components/gateway-alert-block/GatewayAlertBlock";

import * as Models from "../../../../core/models";
import {AnalyticsVariableToChart} from "../../../../core/models";
import {AnalyticsDataStore} from "../../../../core/stores/AnalyticsDataStore";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {useAvailableHeight} from "../../../hooks/useAvailableHeight";
import useUserPreferences from "../../../hooks/useUserPreferences";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {SEGMENT_NOT_APPLICABLE_VALUE} from "../../../utils/SegmentHelper";
import {lbsToKgs, WebHelper} from "../../../utils/WebHelper";
import {AnalyticsChart} from "../../analytics-chart/AnalyticsChart";
import {Button, ButtonType} from "../../common/button/Button";
import {AnalyticsFilterOptions, AnalyticsSettings} from "./analytics-settings/AnalyticsSettings";
import styles from "./AnalyticsTabContent.module.scss";

type AnalyticsTabContentProps = {
  organization?: Models.Organization;
  site?: Models.Site;
  department?: Models.Department;
  setTimeZone?: (timezone: string) => void;
};

const defaultSettings: Models.AnalyticsSettings = {
  variableToChartSelected: Models.AnalyticsVariableToChart.HoursUsed,
  intervalSelected: Models.AnalyticsInterval.Day,
  startDate: addDays(new Date(), -6),
  endDate: new Date(),
  displayMaxData: false,
  checkedMostRecent: false,
  dataType: Models.ChartDataType.Raw,
  workerStatusTypesSelected: [Models.WorkerStatusType.Active, Models.WorkerStatusType.Inactive],
  safetyTypesSelected: [Models.ChartMovementType.Safe, Models.ChartMovementType.Risky],
  movementTypesSelected: [
    Models.ChartMovementType.ExcessiveForwardBending,
    Models.ChartMovementType.ProlongedBending,
    Models.ChartMovementType.Twisting,
    Models.ChartMovementType.SideBending,
  ],
};

export const AnalyticsTabContent: FunctionComponent<AnalyticsTabContentProps> = ({organization, site, department, setTimeZone}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const analyticStore = AnalyticsDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();
  const chartRef = useRef<ChartJSOrUndefined<"bar", number[], string> | undefined>(null);

  const refTabContent = useRef<HTMLDivElement>(null);
  const refAnalyticsSettings = useRef<HTMLDivElement>(null);

  const [filterValue, setFilterValue] = useState<string[] | undefined>([AnalyticsFilterOptions.AllWorkers]);
  const [dataType, setDataType] = useState<Models.ChartDataType>(
    userPreferences.data.analyticsSettings?.dataType || defaultSettings.dataType
  );
  const [movementTypesSelected, setMovementTypesSelected] = useState<Models.ChartMovementType[]>(
    userPreferences.data.analyticsSettings?.movementTypesSelected || defaultSettings.movementTypesSelected
  );
  const [safetyTypesSelected, setSafetyTypesSelected] = useState<Models.ChartMovementType[]>(
    userPreferences.data.analyticsSettings?.safetyTypesSelected || defaultSettings.safetyTypesSelected
  );
  const [workerStatusTypesSelected, setWorkerStatusTypesSelected] = useState<Models.WorkerStatusType[]>(
    userPreferences.data.analyticsSettings?.workerStatusTypesSelected || defaultSettings.workerStatusTypesSelected
  );
  const [selectedWorkerGroupId, setSelectedWorkerGroupId] = useState<string | null>();
  const [selectedWorkerId, setSelectedWorkerId] = useState<string | null>();
  const [data, setData] = useState<Models.AnalyticsTimeSeries[]>([]);

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.AnalyticsOpened, {
      orgID: organization ? organization.id : site ? site.organization_id : undefined,
      siteID: site ? site.id : undefined,
      departmentID: department ? department.id : undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const chartHeight = useAvailableHeight(refTabContent, refAnalyticsSettings);

  const workerStore = WorkerDataStore.getInstance();
  const workerReportGroupStore = WorkerReportGroupDataStore.getInstance();

  const SelectedWorkerGroupOrWorkerNameQuery = useQuery({
    queryKey: ["WorkerGroupOrWorkerName", selectedWorkerId, selectedWorkerGroupId],
    queryFn: async () => {
      let name = null;

      if (selectedWorkerId) {
        name = await workerStore.worker({id: selectedWorkerId, accessToken: authenticationStore.state.accessToken!}).then((data: any) => {
          if (data.success) return data.worker.fullName;
        });
      } else if (selectedWorkerGroupId) {
        name = await workerReportGroupStore
          .workerReportGroup({id: selectedWorkerGroupId, accessToken: authenticationStore.state.accessToken!})
          .then((data: any) => {
            if (data.success) return data.workerReportGroup.name;
          });
      }
      return name;
    },
    enabled: false,
  });

  useEffect(() => {
    if (!!site && !department && selectedWorkerId && !selectedWorkerGroupId) {
      setFilterValue([AnalyticsFilterOptions.AllWorkers]);
      setSelectedWorkerGroupId(null);
      setSelectedWorkerId(null);
    }
  }, [site, department, selectedWorkerGroupId, selectedWorkerId]);

  const analyticsSettings: Models.AnalyticsSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.analyticsSettings ?? {})}),
    [userPreferences]
  );

  const handleSelectedMovementsChange = (checkedValues: CheckboxValueType[]) => {
    setMovementTypesSelected(checkedValues as Models.ChartMovementType[]);
  };

  const handleSelectedSafetyTypes = (checkedValues: CheckboxValueType[]) => {
    setSafetyTypesSelected(checkedValues as Models.ChartMovementType[]);
  };

  const handleSelectedWorkersChange = (checkedValues: CheckboxValueType[]) => {
    setWorkerStatusTypesSelected(checkedValues as Models.WorkerStatusType[]);
  };

  const saveFilterSelection = async (values: string[] | undefined) => {
    const selectedOption = values?.[0];
    const workerReportGroupId = values?.[1];
    let workerId = values?.[2];

    setFilterValue(values);

    switch (selectedOption) {
      case AnalyticsFilterOptions.AllWorkers:
        setSelectedWorkerGroupId(null);
        setSelectedWorkerId(null);
        break;
      case AnalyticsFilterOptions.WorkerReportGroups:
        setSelectedWorkerGroupId(workerReportGroupId ?? null);
        setSelectedWorkerId(workerId ?? null);
        break;
      case AnalyticsFilterOptions.IndividualWorkers:
        workerId = values?.[1];
        setSelectedWorkerGroupId(null);
        setSelectedWorkerId(workerId ?? null);
        break;
    }
  };

  const getSelectedResourceType = useCallback((): Models.EntityType => {
    return selectedWorkerId
      ? Models.EntityType.Worker
      : selectedWorkerGroupId
        ? Models.EntityType.WorkerReportGroup
        : department
          ? Models.EntityType.Department
          : site
            ? Models.EntityType.Site
            : Models.EntityType.Organization;
  }, [selectedWorkerId, department, site, selectedWorkerGroupId]);

  const getSelectedResourceID = useCallback((): string | null => {
    return selectedWorkerId
      ? selectedWorkerId
      : selectedWorkerGroupId
        ? selectedWorkerGroupId
        : department
          ? department.id
          : site
            ? site.id
            : organization
              ? organization.id
              : null;
  }, [selectedWorkerId, department, site, organization, selectedWorkerGroupId]);

  const analyticsQuery = useQuery({
    queryKey: [
      "AnalyticsTabContent-fetchData",
      organization,
      site,
      department,
      selectedWorkerId,
      selectedWorkerGroupId,
      analyticsSettings.startDate,
      analyticsSettings.endDate,
      analyticsSettings.intervalSelected,
      analyticsSettings.displayMaxData,
      authenticationStore.state.accessToken,
    ],
    queryFn: () => {
      const resourceId = getSelectedResourceID();
      const {startDate, endDate, intervalSelected, displayMaxData} = analyticsSettings ?? {
        startDate: defaultSettings.startDate,
        endDate: defaultSettings.endDate,
        intervalSelected: defaultSettings.intervalSelected,
      };

      let intervalCount = 10;
      if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Day)
        intervalCount = differenceInDays(endDate, startOfDay(startDate)) + 1;
      if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Week)
        intervalCount = Math.round(differenceInDays(endDate, startDate) / 7);
      if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Month)
        intervalCount = Math.round(differenceInDays(endDate, startDate) / 30);
      if (startDate && endDate && intervalSelected === Models.AnalyticsInterval.Quarter)
        intervalCount = Math.round(differenceInDays(endDate, startDate) / 90);
      if (analyticsSettings.displayMaxData) intervalCount = AppConfig.Components.AnalyticsChart.maxBarsToDisplay;
      if (resourceId && (startDate || displayMaxData) && endDate) {
        const endTime = zonedTimeToUtc(startOfDay(addDays(endDate, 1)), "UTC").toISOString();

        return analyticStore.analyticsTimeSeriesData({
          accessToken: authenticationStore.state.accessToken!,
          organization_id: !site && organization ? organization.id : undefined,
          site_id: !department && site ? site.id : undefined,
          department_id: department ? department.id : undefined,
          group_id: selectedWorkerGroupId ?? undefined,
          worker_id: selectedWorkerId ?? undefined,
          end_time: endTime,
          interval_type: intervalSelected,
          interval_count: intervalCount,
          offset_minutes: -new Date().getTimezoneOffset(),
        });
      } else return null;
    },

    enabled: !!department || !!organization || !!site || !!selectedWorkerId,
  });

  useEffect(() => {
    if (!analyticsQuery.data) return;

    if (!analyticsQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("AnalyticsTabContent-errorMessage"), analyticsQuery.data.correlationId);
      return;
    } else {
      appStore.sendAnalyticTrack(SegmentKey.ViewAnalyticsPage, {
        orgID: organization?.id,
        siteID: getSelectedResourceType() === Models.EntityType.Organization ? SEGMENT_NOT_APPLICABLE_VALUE : site?.id,
        departmentID: getSelectedResourceType() === Models.EntityType.Department ? department?.id : SEGMENT_NOT_APPLICABLE_VALUE,
        resourceType: getSelectedResourceType(),
        resourceID: getSelectedResourceID(),
        startDate: analyticsSettings.startDate ? WebHelper.formatDateNoTime(analyticsSettings.startDate) : "",
        endDate: analyticsSettings.endDate ? WebHelper.formatDateNoTime(analyticsSettings.endDate) : "",
        endDateWeekday: analyticsSettings.endDate ? format(analyticsSettings.endDate, "EEEE") : "",
        variable: analyticsSettings.variableToChartSelected,
        interval: analyticsSettings.intervalSelected,
        data:
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts ||
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown ||
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? dataType
            : SEGMENT_NOT_APPLICABLE_VALUE,
        checkbox:
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? workerStatusTypesSelected
            : analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts
              ? movementTypesSelected
              : analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown
                ? movementTypesSelected
                : SEGMENT_NOT_APPLICABLE_VALUE,
      });

      setData(analyticsQuery.data.data.time_series_data);
      if (setTimeZone) {
        setTimeZone(analyticsQuery.data.data.time_series_data[0].time_zone);
      }
    }
  }, [
    analyticsQuery.data,
    analyticsSettings.endDate,
    analyticsSettings.intervalSelected,
    analyticsSettings.startDate,
    analyticsSettings.variableToChartSelected,
    appStore,
    dataType,
    department?.id,
    getSelectedResourceID,
    getSelectedResourceType,
    movementTypesSelected,
    organization?.id,
    setTimeZone,
    site?.id,
    safetyTypesSelected,
    workerStatusTypesSelected,
  ]);

  const chartVariable = useMemo(() => {
    switch (analyticsSettings.variableToChartSelected) {
      case Models.AnalyticsVariableToChart.HoursUsed:
        return "usage_sec";
      case Models.AnalyticsVariableToChart.WeightOffloaded:
        return "weight_offloaded_lbs";
      case Models.AnalyticsVariableToChart.Lifts:
      case Models.AnalyticsVariableToChart.MovementBreakdown:
        return "total_lifts";
      case Models.AnalyticsVariableToChart.WorkerActiveStatus:
        return "active_workers";
      default:
        return "";
    }
  }, [analyticsSettings.variableToChartSelected]);

  const totalText = useMemo(() => {
    const totalValue = data.reduce((accumulator, currentValue) => accumulator + currentValue[chartVariable], 0);
    switch (analyticsSettings.variableToChartSelected) {
      case Models.AnalyticsVariableToChart.HoursUsed:
        return WebHelper.formatMessage("AnalyticsTabContent-totalValueMessage", {
          variable: WebHelper.formatMessage("AnalyticsSettings-hoursUsed"),
          unit: WebHelper.formatMessage("AnalyticsTabContent-hoursUnit"),
          value: secondsToHours(totalValue).toLocaleString(),
        });
      case Models.AnalyticsVariableToChart.WeightOffloaded:
        return WebHelper.formatMessage("AnalyticsTabContent-totalValueMessage", {
          variable: WebHelper.formatMessage("AnalyticsSettings-weightOffloaded"),
          unit: userPreferences.data.metricMeasurementUnits
            ? WebHelper.formatMessage("AnalyticsTabContent-kilogramsUnit")
            : WebHelper.formatMessage("AnalyticsTabContent-poundsUnit"),
          value: (userPreferences.data.metricMeasurementUnits ? lbsToKgs(totalValue) : totalValue).toLocaleString("en-US", {
            style: "decimal",
            maximumFractionDigits: 0,
          }),
        });
      case Models.AnalyticsVariableToChart.Lifts:
      case Models.AnalyticsVariableToChart.MovementBreakdown:
        return WebHelper.formatMessage("AnalyticsTabContent-totalValueMessage", {
          variable: WebHelper.formatMessage("AnalyticsSettings-lifts"),
          unit: WebHelper.formatMessage("AnalyticsTabContent-liftsUnit"),
          value: totalValue.toLocaleString(),
        });
      case Models.AnalyticsVariableToChart.WorkerActiveStatus:
        const totalPercentage = data.reduce((accumulator, currentValue) => accumulator + currentValue.activeWorkersPercentage, 0);
        const averageValue = data.length > 0 ? _.round(totalValue / data.length, 2) : 0;
        const averagePercentage = data.length > 0 ? _.round(totalPercentage / data.length, 2) : 0;

        return WebHelper.formatMessage("AnalyticsTabContent-averageMessage", {
          unit: WebHelper.formatMessage("AnalyticsTabContent-activeWorkers"),
          interval:
            analyticsSettings.intervalSelected === Models.AnalyticsInterval.Day
              ? WebHelper.formatMessage("AnalyticsTabContent-day")
              : analyticsSettings.intervalSelected === Models.AnalyticsInterval.Month
                ? WebHelper.formatMessage("AnalyticsTabContent-month")
                : analyticsSettings.intervalSelected === Models.AnalyticsInterval.Quarter
                  ? WebHelper.formatMessage("AnalyticsTabContent-quarter")
                  : WebHelper.formatMessage("AnalyticsTabContent-week"),
          value: `${averageValue} (${averagePercentage}%)`,
        });
      default:
        return "";
    }
  }, [analyticsSettings.variableToChartSelected, analyticsSettings.intervalSelected, chartVariable, data, userPreferences]);

  const buildDownloadFileName = useCallback(
    (levelName: string, extension: "png" | "csv", resourceName?: string) => {
      let levelText = levelName;

      //if levelname ==  name, no worker or group is selected
      let resourceText = resourceName && resourceName != levelName ? `:${resourceName}` : "";

      if (resourceName) {
        //AnalyticsData-workerGroupOrWorkerName.csv
        if (site && !department) {
          resourceText = resourceName;
          levelText = "";
        }
      }

      return `AnalyticsData-${levelText}${resourceText}.${extension}`;
    },
    [department, site]
  );

  const handleSettingsChange = useCallback(
    (
      updatedSettings?: Partial<Models.AnalyticsSettings>,
      dataSelected?: Models.ChartDataType,
      checkedValues?: CheckboxValueType[],
      checkedMostRecent?: boolean
    ) => {
      setData([]);
      let newAnalyticsSettings = {...analyticsSettings, ...updatedSettings};

      const extendNewAnalytics = (obj: any) => {
        newAnalyticsSettings = {
          ...newAnalyticsSettings,
          ...obj,
        };
      };
      if (dataSelected) {
        setDataType(dataSelected);
        extendNewAnalytics({dataType: dataSelected});
      }

      if (checkedMostRecent !== undefined) {
        extendNewAnalytics({checkedMostRecent: checkedMostRecent, endDate: updatedSettings?.endDate ?? null});
      }

      switch (analyticsSettings.variableToChartSelected) {
        case Models.AnalyticsVariableToChart.Lifts:
          handleSelectedSafetyTypes(checkedValues ? checkedValues : safetyTypesSelected);
          extendNewAnalytics({safetyTypesSelected: checkedValues as Models.ChartMovementType[]});
          break;
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          handleSelectedMovementsChange(checkedValues ? checkedValues : movementTypesSelected ?? []);
          extendNewAnalytics({movementTypesSelected: checkedValues as Models.ChartMovementType[]});
          break;
        case Models.AnalyticsVariableToChart.WorkerActiveStatus:
          handleSelectedWorkersChange(checkedValues ? checkedValues : workerStatusTypesSelected ?? []);
          extendNewAnalytics({workerStatusTypesSelected: checkedValues as Models.WorkerStatusType[]});
          break;
        default:
          break;
      }

      setUserPreferences({analyticsSettings: newAnalyticsSettings});

      const newSettings = {...analyticsSettings, ...updatedSettings};

      appStore.sendAnalyticTrack(SegmentKey.ViewAnalyticsPage, {
        orgID: organization?.id,
        siteID: getSelectedResourceType() === Models.EntityType.Organization ? SEGMENT_NOT_APPLICABLE_VALUE : site?.id,
        departmentID: getSelectedResourceType() === Models.EntityType.Department ? department?.id : SEGMENT_NOT_APPLICABLE_VALUE,
        resourceType: getSelectedResourceType(),
        resourceID: getSelectedResourceID(),
        startDate: newSettings.startDate ? WebHelper.formatDateNoTime(newSettings.startDate) : "",
        endDate: newSettings.endDate ? WebHelper.formatDateNoTime(newSettings.endDate) : "",
        endDateWeekday: newSettings.endDate ? format(newSettings.endDate, "EEEE") : "",
        variable: newSettings.variableToChartSelected,
        interval: newSettings.intervalSelected,
        data:
          newSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts ||
          newSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown ||
          newSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? dataSelected
            : SEGMENT_NOT_APPLICABLE_VALUE,
        checkbox:
          newSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? checkedValues
            : newSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts
              ? checkedValues
              : newSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown
                ? checkedValues
                : SEGMENT_NOT_APPLICABLE_VALUE,
      });
    },
    [
      analyticsSettings,
      setUserPreferences,
      appStore,
      organization?.id,
      getSelectedResourceType,
      site?.id,
      department?.id,
      getSelectedResourceID,
      safetyTypesSelected,
      movementTypesSelected,
      workerStatusTypesSelected,
    ]
  );

  const handleDownloadData = useCallback(async () => {
    const resourceType = getSelectedResourceType();
    const resourceID = getSelectedResourceID();
    const levelName = department ? department.name : site ? site.name : organization ? organization.name : "";
    let resourceName = levelName;

    appStore.sendAnalyticTrack(SegmentKey.DownloadAnalyticsData, {
      orgID: organization?.id,
      siteID: getSelectedResourceType() === Models.EntityType.Organization ? SEGMENT_NOT_APPLICABLE_VALUE : site?.id,
      departmentID: getSelectedResourceType() === Models.EntityType.Department ? department?.id : SEGMENT_NOT_APPLICABLE_VALUE,
      resourceType: getSelectedResourceType(),
      resourceID: getSelectedResourceID(),
      startDate: analyticsSettings.startDate ? WebHelper.formatDateNoTime(analyticsSettings.startDate) : "",
      endDate: analyticsSettings.endDate ? WebHelper.formatDateNoTime(analyticsSettings.endDate) : "",
      endDateWeekday: analyticsSettings.endDate ? format(analyticsSettings.endDate, "EEEE") : "",
      interval: analyticsSettings.intervalSelected,
    });

    if (selectedWorkerGroupId || selectedWorkerId) {
      resourceName = (await SelectedWorkerGroupOrWorkerNameQuery.refetch()).data;
    }

    const weightOffloadedColumnTitle = `${WebHelper.formatMessage("Analytics-csvColumn-weightOffloaded")} (${WebHelper.formatMessage(
      userPreferences.data.metricMeasurementUnits ? "AnalyticsTabContent-kilogramsUnit" : "AnalyticsTabContent-poundsUnit"
    )})`;

    const headers = [
      [
        WebHelper.formatMessage("Analytics-csvColumn-resourceType"),
        WebHelper.formatMessage("Analytics-csvColumn-resourceID"),
        WebHelper.formatMessage("Analytics-csvColumn-resourceName"),
        WebHelper.formatMessage("Analytics-csvColumn-startDate"),
        WebHelper.formatMessage("Analytics-csvColumn-startTime"),
        WebHelper.formatMessage("Analytics-csvColumn-endDate"),
        WebHelper.formatMessage("Analytics-csvColumn-endTime"),
        WebHelper.formatMessage("Analytics-csvColumn-hoursUsed"),
        WebHelper.formatMessage("Analytics-csvColumn-totalLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-riskyLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-safeLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-safeLiftsPercentage"),
        WebHelper.formatMessage("Analytics-csvColumn-riskyLiftsPercentage"),
        weightOffloadedColumnTitle,
        WebHelper.formatMessage("Analytics-csvColumn-excessiveForwardLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-prolongedBendLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-twistedLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-sideBendLifts"),
        WebHelper.formatMessage("Analytics-csvColumn-assignedWorkers"),
        WebHelper.formatMessage("Analytics-csvColumn-activeWorkers"),
        WebHelper.formatMessage("Analytics-csvColumn-activeWorkersPercentage"),
        WebHelper.formatMessage("Analytics-csvColumn-inactiveWorkers"),
        WebHelper.formatMessage("Analytics-csvColumn-inactiveWorkersPercentage"),
      ],
    ];

    const timezone = department ? department.site.tz_location : site ? site.tz_location : WebHelper.getLocalTimezoneFromBrowser();
    const dataRows = data.map((item) => {
      return [
        resourceType,
        resourceID,
        resourceName,
        WebHelper.formatDateNoTime(item.start_time, timezone),
        WebHelper.formatTimeOnly(item.start_time, timezone),
        WebHelper.formatDateNoTime(addDays(item.end_time, -1), timezone),
        WebHelper.formatTimeOnly(item.end_time, timezone),
        (item.usage_sec / 3600).toFixed(2),
        item.total_lifts,
        item.riskyLifts,
        item.safe_lifts,
        item.safeLiftsPercentage,
        item.riskyLiftsPercentage,
        userPreferences.data.metricMeasurementUnits ? lbsToKgs(item.weight_offloaded_lbs) : item.weight_offloaded_lbs,
        item.excessive_forward_lifts,
        item.prolonged_bend_lifts,
        item.twisted_lifts,
        item.side_bend_lifts,
        item.assigned_workers,
        item.active_workers,
        item.activeWorkersPercentage,
        item.inactiveWorkers,
        item.inactiveWorkersPercentage,
      ];
    });

    const csvContent = _.concat(headers, dataRows)
      .map((row) => row.join(","))
      .join("\n");

    WebHelper.downloadFile(csvContent, buildDownloadFileName(levelName, "csv", resourceName), "application/csv");
  }, [
    getSelectedResourceType,
    getSelectedResourceID,
    department,
    site,
    organization,
    appStore,
    analyticsSettings.startDate,
    analyticsSettings.endDate,
    analyticsSettings.intervalSelected,
    selectedWorkerGroupId,
    selectedWorkerId,
    data,
    userPreferences,
    buildDownloadFileName,
    SelectedWorkerGroupOrWorkerNameQuery,
  ]);

  const handleDownloadImage = async () => {
    if (chartRef.current) {
      appStore.sendAnalyticTrack(SegmentKey.DownloadAnalyticsGraph, {
        orgID: organization?.id,
        siteID: getSelectedResourceType() === Models.EntityType.Organization ? SEGMENT_NOT_APPLICABLE_VALUE : site?.id,
        departmentID: getSelectedResourceType() === Models.EntityType.Department ? department?.id : SEGMENT_NOT_APPLICABLE_VALUE,
        resourceType: getSelectedResourceType(),
        resourceID: getSelectedResourceID(),
        startDate: analyticsSettings.startDate ? WebHelper.formatDateNoTime(analyticsSettings.startDate) : "",
        endDate: analyticsSettings.endDate ? WebHelper.formatDateNoTime(analyticsSettings.endDate) : "",
        endDateWeekday: analyticsSettings.endDate ? format(analyticsSettings.endDate, "EEEE") : "",
        variable: analyticsSettings.variableToChartSelected,
        interval: analyticsSettings.intervalSelected,
        data:
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts ||
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown ||
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? dataType
            : SEGMENT_NOT_APPLICABLE_VALUE,
        checkbox:
          analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.WorkerActiveStatus
            ? workerStatusTypesSelected
            : analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.Lifts
              ? movementTypesSelected
              : analyticsSettings.variableToChartSelected === AnalyticsVariableToChart.MovementBreakdown
                ? movementTypesSelected
                : SEGMENT_NOT_APPLICABLE_VALUE,
      });

      const a = document.createElement("a");
      const levelName = department ? department.name : site ? site.name : organization ? organization.name : "";
      let resourceName = levelName;

      if (selectedWorkerGroupId || selectedWorkerId) {
        resourceName = (await SelectedWorkerGroupOrWorkerNameQuery.refetch()).data;
      }

      a.href = chartRef.current.toBase64Image();
      a.download = await buildDownloadFileName(levelName, "png", resourceName);
      a.click();
    }
  };

  const downloadOptions = [
    {
      label: WebHelper.formatMessage("AnalyticsTabContent-asCsv"),
      key: "csv",
      onClick: () => handleDownloadData(),
    },
    {
      label: WebHelper.formatMessage("AnalyticsTabContent-asImage"),
      key: "image",
      onClick: () => handleDownloadImage(),
    },
  ];

  return (
    <div className={styles.tabContent} ref={refTabContent}>
      <GatewayAlertBlock />
      <div ref={refAnalyticsSettings}>
        <AnalyticsSettings
          site={site}
          department={department}
          onSettingsChange={handleSettingsChange}
          dataSelected={dataType}
          variableToChartSelected={analyticsSettings.variableToChartSelected}
          intervalSelected={analyticsSettings.intervalSelected}
          movementTypesSelected={movementTypesSelected}
          safetyTypesSelected={safetyTypesSelected}
          workerStatusTypesSelected={workerStatusTypesSelected}
          endDate={analyticsSettings.endDate}
          startDate={analyticsSettings.startDate}
          displayMaxData={analyticsSettings.displayMaxData}
          saveFilterSelection={saveFilterSelection}
          filterValue={filterValue}
        />
        <div className={styles.chartHeader}>
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(totalText),
            }}
            className={styles.totalText}
          />
          <Dropdown menu={{items: downloadOptions}} placement="bottom" trigger={["click"]}>
            <Button type={ButtonType.Primary} shape="round" icon={<DownloadOutlined />} disabled={analyticsQuery.isPending}>
              {WebHelper.formatMessage("AnalyticsSubheader-downloadDataButton")}
            </Button>
          </Dropdown>
        </div>
      </div>
      <div style={{height: chartHeight}}>
        <AnalyticsChart
          data={data}
          variableSelected={analyticsSettings.variableToChartSelected}
          intervalSelected={analyticsSettings.intervalSelected}
          timeZone={site ? site.tz_location : department ? department.site.tz_location : ""}
          selectedMovements={movementTypesSelected}
          safetyTypesSelected={safetyTypesSelected}
          selectedWorkerStatuses={workerStatusTypesSelected}
          dataType={dataType}
          endDate={analyticsSettings.endDate}
          startDate={analyticsSettings.startDate}
          chartRef={chartRef}
          displayMaxData={analyticsSettings.displayMaxData}
          metricMeasurementUnits={userPreferences.data.metricMeasurementUnits}
        />
      </div>
    </div>
  );
};
