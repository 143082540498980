import {StarFilled, StarOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Avatar, Button, Card, Tooltip, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo, useState} from "react";
import Highlighter from "react-highlight-words";
import {useNavigate} from "react-router-dom";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {OrganizationDataStore} from "../../../core/stores/OrganizationDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {DashboardCardWrapper} from "../entity-details/dashboard-tab-content/dashboard-card-wrapper/DashboardCardWrapper";
import {RemoveFromFavoritesModal} from "../remove-from-favorites-modal/RemoveFromFavoritesModal";
import styles from "./OrganizationCard.module.scss";

type OrganizationCardProps = {
  organization: Models.OrganizationShort;
  onFavoriteChange: (orgId: string, shouldShowRemoveFromFavoritesModal?: boolean) => void;
  userPreferences: {data: Models.UserPreferences; isLoading: boolean};
  searchValue: string;
};

export const OrganizationCard: FunctionComponent<OrganizationCardProps> = observer(
  ({organization, onFavoriteChange, userPreferences, searchValue}) => {
    const navigate = useNavigate();
    const authenticationStore = AuthenticationDataStore.getInstance();
    const organizationStore = OrganizationDataStore.getInstance();

    const [isConfirmationModalVisible, setIsConfirmationModalVisible] = useState(false);

    const shouldShowRemoveFromFavoritesModal: boolean = useMemo(
      () => userPreferences.data.shouldShowRemoveFromFavoritesModal ?? true,
      [userPreferences]
    );

    const organizationQuery = useQuery({
      queryKey: ["OrganizationCard-fetchOrganizationData", organization.id],
      queryFn: () => {
        return organizationStore.organization({
          accessToken: authenticationStore.state.accessToken!,
          id: organization.id!,
        });
      },
      enabled: !!organization.id && !!(organization.site_count === 1),
    });

    const isFavoriteOrg = organization.id ? userPreferences.data.favorites?.organizationIds.includes(organization.id) : false;

    const handleFavoriteChange = () => {
      if (isFavoriteOrg && shouldShowRemoveFromFavoritesModal) {
        setIsConfirmationModalVisible(true);
      } else {
        onFavoriteChange(organization.id);
      }
    };

    const handleRemoveFromFavorites = (orgId: string, shouldShowRemoveFromFavoritesModal: boolean) => {
      onFavoriteChange(orgId, shouldShowRemoveFromFavoritesModal);
      setIsConfirmationModalVisible(false);
    };

    return (
      <>
        <Card
          className={styles.wrapper}
          title={
            <div className={styles.header}>
              <Avatar size="large" shape="square" alt={organization.name} src={organization.icon_url} />
              <Button
                type="link"
                className={styles.titleButton}
                onClick={() =>
                  navigate(
                    organizationQuery.data?.organization && organizationQuery.data?.organization.sites
                      ? organizationQuery.data?.organization.sites[0].departments?.length === 1
                        ? `/organizations/${organization?.id}/sites/${organizationQuery.data?.organization.sites![0].id}/departments/${organizationQuery.data?.organization.sites![0].departments![0].id}`
                        : organizationQuery.data?.organization.sites?.length === 1
                          ? `/organizations/${organization?.id}/sites/${organizationQuery.data?.organization.sites![0].id}`
                          : `/organizations/${organization?.id}`
                      : `/organizations/${organization?.id}`
                  )
                }>
                <div className={styles.titleText} title={organization.name}>
                  {searchValue ? (
                    <Highlighter
                      highlightClassName={styles.highlightText}
                      searchWords={[searchValue]}
                      autoEscape
                      textToHighlight={organization.name}
                    />
                  ) : (
                    organization.name
                  )}
                </div>
              </Button>
            </div>
          }
          extra={
            <Tooltip
              title={
                isFavoriteOrg ? WebHelper.formatMessage("Common-removeFromFavorites") : WebHelper.formatMessage("Common-addToFavorites")
              }
              placement="bottom">
              <Button
                className={styles.favoriteButton}
                type="text"
                shape="round"
                loading={userPreferences.isLoading}
                disabled={userPreferences.isLoading}
                onClick={handleFavoriteChange}
                icon={isFavoriteOrg ? <StarFilled className={styles.favoriteStar} /> : <StarOutlined className={styles.favoriteStar} />}
              />
            </Tooltip>
          }>
          <div className={styles.cardSubheader}>
            <Typography.Text>
              {WebHelper.formatMessage("OrganizationCard-siteDepartmentAndDeviceCount", {
                sites: organization.site_count,
                departments: organization.department_count,
                devices: organization.device_count,
              })}
            </Typography.Text>
          </div>
          <div className={styles.content}>
            <DashboardCardWrapper
              entityId={organization.id}
              entityType={Models.EntityType.Organization}
              timeZone={undefined}
              name={organization.name}
              bordered={false}
            />
          </div>
        </Card>
        <RemoveFromFavoritesModal
          organization={organization}
          open={isConfirmationModalVisible}
          onRemove={handleRemoveFromFavorites}
          onCancel={() => setIsConfirmationModalVisible(false)}
        />
      </>
    );
  }
);
