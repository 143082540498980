import {useMutation} from "@tanstack/react-query";
import {Form, Modal, Typography} from "antd";
import {OrganizationDataStore} from "core/stores/OrganizationDataStore";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";
import {useNavigate} from "react-router-dom";

import * as Models from "../../../core/models";
import {UserScope} from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {DepartmentDataStore} from "../../../core/stores/DepartmentDataStore";
import {SiteDataStore} from "../../../core/stores/SiteDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {Button, ButtonType} from "../common/button/Button";
import {LinkButton} from "../common/link-button/LinkButton";
import {ConfirmationModal, ConfirmationModalSizes} from "../confirmation-modal/ConfirmationModal";
import {DiscardChangesModal} from "../discard-changes-modal/DiscardChangesModal";
import {SiteDepartmentForm, SiteDepartmentFormFields} from "./site-department-form/SiteDepartmentForm";
import styles from "./SiteDepartmentEditModal.module.scss";

type SiteDepartmentEditModalProps = {
  organization?: Models.Organization;
  data: {site: Models.Site; department: Models.Department};
  onClose: () => void;
  onDelete?: () => void;
  onSave: () => void;
  open: boolean;
};

export const SiteDepartmentEditModal: FunctionComponent<SiteDepartmentEditModalProps> = observer(
  ({organization, data, onClose, onDelete, onSave, open}) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const siteStore = SiteDataStore.getInstance();
    const departmentStore = DepartmentDataStore.getInstance();
    const organizationStore = OrganizationDataStore.getInstance();

    const navigate = useNavigate();
    const [form] = Form.useForm<SiteDepartmentFormFields>();
    const [isFormDisabled, setIsFormDisabled] = useState(true);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showDiscardModal, setShowDiscardModal] = useState(false);

    const {mutate: updateOrganizationMutate, isPending: updateOrganizationIsLoading} = useMutation({
      mutationFn: (mutationParams: {formValues: SiteDepartmentFormFields; organization: Models.Organization}) => {
        const {orgName, orgShortName, orgAdress, orgIconUrl} = mutationParams.formValues;

        return organizationStore.updateOrganization({
          accessToken: authenticationStore.state.accessToken!,
          id: mutationParams.organization.id,
          name: orgName!,
          short_name: orgShortName,
          address: orgAdress,
          icon_url: orgIconUrl,
        });
      },
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("OrganizationSiteModal-updateOrganizationError"), response.correlationId);
          return;
        }

        onSave();
      },
    });

    const {mutate: updateSiteMutate, isPending: updateSiteIsLoading} = useMutation({
      mutationFn: (mutationParams: {formValues: SiteDepartmentFormFields; site: Models.Site}) => {
        const {siteName, address, tz_location} = mutationParams.formValues;
        const {id, organization_id} = mutationParams.site;

        return siteStore.updateSite({
          accessToken: authenticationStore.state.accessToken!,
          id,
          name: siteName,
          address,
          tz_location,
          organization_id,
        });
      },
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("SiteModal-updateError"), response.correlationId);
          return;
        }

        onSave();
      },
    });

    const {mutate: updateDepartmentMutate, isPending: updateDepartmentIsLoading} = useMutation({
      mutationFn: (mutationParams: {formValues: SiteDepartmentFormFields; department: Models.Department}) => {
        const {departmentName} = mutationParams.formValues;
        const {id, site} = mutationParams.department;
        return departmentStore.updateDepartment({
          accessToken: authenticationStore.state.accessToken!,
          id,
          name: departmentName,
          site_id: site.id,
        });
      },
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("DepartmentModal-updateError"), response.correlationId);
          return;
        }

        onSave();
      },
    });

    const deleteDepartment = useMutation({
      mutationFn: (department: Models.Department) => {
        return departmentStore.deleteDepartment({
          accessToken: authenticationStore.state.accessToken!,
          id: department.id,
        });
      },
      onSuccess: async (data) => {
        if (data.success) {
          if (onDelete) await onDelete();
          navigate(location.pathname.substring(0, location.pathname.indexOf("/departments")));
        } else WebHelper.showErrorMessage(WebHelper.formatMessage("DepartmentModal-deleteError"), data.correlationId);
      },
    });

    const mutationLoading = updateSiteIsLoading || updateDepartmentIsLoading || updateOrganizationIsLoading;

    const handleOk = async () => {
      form
        .validateFields()
        .then((formValues) => {
          updateSiteMutate({site: data.site, formValues});
          updateDepartmentMutate({department: data.department, formValues});
          {
            organization && updateOrganizationMutate({organization, formValues});
          }
        })
        // This catch prevents AntD from throwing an error to the console when form validations fail
        .catch(() => {});
    };

    const handleCancel = () => {
      if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
      else {
        setShowDiscardModal(false);
        onClose();
        form.resetFields();
      }
    };

    const handleDeleteOk = () => {
      if (deleteDepartment.isPending || !data.department.id) return;
      deleteDepartment.mutate(data.department);
    };

    const handleOnFieldsChange = () => {
      if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
        setIsFormDisabled(true);
      } else {
        setIsFormDisabled(false);
      }
    };

    return (
      <>
        <Modal
          centered
          open={open}
          title={
            organization
              ? WebHelper.formatMessage("SiteDepartmentModal-orgIncludedTitle")
              : WebHelper.formatMessage("SiteDepartmentModal-title")
          }
          destroyOnClose={true}
          onOk={handleOk}
          okText={WebHelper.formatMessage("Common-save")}
          okButtonProps={{shape: "round", loading: mutationLoading, disabled: mutationLoading || isFormDisabled}}
          cancelButtonProps={{shape: "round", disabled: mutationLoading}}
          onCancel={handleCancel}
          footer={[
            <div
              className={styles.footer}
              key="footer"
              style={
                !(authenticationStore.permissionLevel(UserScope.Organization) === Models.UserAccessLevel.Admin)
                  ? {justifyContent: "flex-end"}
                  : {}
              }>
              {authenticationStore.permissionLevel(UserScope.Site) === Models.UserAccessLevel.Admin && (
                <LinkButton key="delete" onClick={() => setShowDeleteModal(true)}>
                  {WebHelper.formatMessage("SiteDepartmentModal-deleteButtonText")}
                </LinkButton>
              )}
              <div>
                <Button key="back" shape="round" disabled={mutationLoading} onClick={handleCancel}>
                  {WebHelper.formatMessage("Common-cancel")}
                </Button>
                <Button
                  key="submit"
                  type={ButtonType.Primary}
                  shape="round"
                  disabled={mutationLoading || isFormDisabled}
                  loading={mutationLoading}
                  onClick={handleOk}>
                  {WebHelper.formatMessage("Common-save")}
                </Button>
              </div>
            </div>,
          ]}>
          <SiteDepartmentForm
            form={form}
            initialValues={{organization, site: data.site, department: data.department}}
            loading={mutationLoading}
            onFieldsChange={handleOnFieldsChange}
          />
        </Modal>
        <ConfirmationModal
          showConfirmationModal={showDeleteModal}
          loading={deleteDepartment.isPending}
          title={WebHelper.formatMessage("SiteDepartmentModal-deleteAlertTitle")}
          message={<Typography.Text>{WebHelper.formatMessage("SiteDepartmentModal-deleteAlertMessage")}</Typography.Text>}
          onOk={handleDeleteOk}
          onCancel={() => setShowDeleteModal(false)}
          okButtonText={WebHelper.formatMessage("Common-delete")}
          referenceConfirmationValue={WebHelper.formatMessage("Common-deleteConfirmationValue")}
          width={ConfirmationModalSizes.Small}
        />
        <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
      </>
    );
  }
);
