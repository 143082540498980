import {jsPDF} from "jspdf";
import {useFlags} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useCallback, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {WebHelper, convertDivToBase64} from "../../../../utils/WebHelper";
import ActiveInactiveWorkersSitePage from "../active-inactive-workers-site-page/ActiveInactiveWorkersSitePage";
import {SiteSummaryReportDepartmentsPage} from "../site-summary-report-departments-page/SiteSummaryReportDepartmentsPage";
import {SiteSummaryReportFirstPage} from "../site-summary-report-first-page/SiteSummaryReportFirstPage";
import {SiteSummaryReportGroupsPage} from "../site-summary-report-groups-page/SiteSummaryReportGroupsPage";
import {SiteSummaryLeaderboardPage} from "../site-summary-report-leaderboard/SiteSummaryLeaderboardPage";
import styles from "./SiteSummaryReportPDF.module.scss";

type SiteSummaryReportPDFProps = {
  endDate: string;
  loadingDownload: boolean;
  setLoadingDownload: (value: boolean) => void;
  site: Models.Site;
  workerReportGroups?: Models.WorkerReportGroup[];
  siteAnalyticsData?: Models.AnalyticsDashboardCard;
  siteDepartmentsData: {id: string; data: Models.AnalyticsDashboardCard}[];
  siteGroupsData: {id: string; data: Models.AnalyticsDashboardCard}[];
  targetUtilization: boolean;
  dataDepartments: {id: string; data: Models.AnalyticsDashboardWorkerData}[];
};

export const SiteSummaryReportPDF: FunctionComponent<SiteSummaryReportPDFProps> = ({
  endDate,
  loadingDownload,
  setLoadingDownload,
  site,
  workerReportGroups,
  siteAnalyticsData,
  siteDepartmentsData,
  siteGroupsData,
  targetUtilization,
  dataDepartments,
}) => {
  const flags = useFlags();
  const [perPageDepartments, setPerPageDepartments] = useState<Models.DepartmentInSite[][]>([]);
  const [perPageGroups, setPerPageGroups] = useState<Models.WorkerReportGroup[][]>([]);
  const [activeInactivePagesArray, setActiveInactivePagesArray] = useState([0]);
  const [activeInactivePages, setActiveInactivePages] = useState(0);

  useEffect(() => {
    if (site.departments && site.departments.length > 0) {
      const allDepartments = site.departments.map((department) => ({...department}));
      const separatedDepartments = [];
      if (allDepartments) {
        while (allDepartments.length > 0) {
          separatedDepartments.push(
            flags.target_utilization_hours && targetUtilization ? allDepartments?.splice(0, 2) : allDepartments?.splice(0, 3)
          );
        }
      }
      setPerPageDepartments(separatedDepartments);
    } else {
      setPerPageDepartments([]);
    }
  }, [site.departments, flags.target_utilization_hours, targetUtilization]);

  useEffect(() => {
    if (workerReportGroups) {
      const allGroups = workerReportGroups.map((group) => ({...group}));
      const separatedGroups = [];
      while (allGroups.length > 0) {
        separatedGroups.push(flags.target_utilization_hours && targetUtilization ? allGroups.splice(0, 6) : allGroups.splice(0, 9));
      }
      setPerPageGroups(separatedGroups);
    } else {
      setPerPageGroups([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerReportGroups, flags.target_utilization_hours, targetUtilization]);

  const handleActiveInactivePages = (pages: number, index: number) => {
    if (activeInactivePagesArray[index] !== pages) {
      const newActiveInactiveArray = [...activeInactivePagesArray];
      newActiveInactiveArray[index] = pages;
      setActiveInactivePagesArray(newActiveInactiveArray);
      setActiveInactivePages(newActiveInactiveArray.reduce((acc, curr) => acc + curr, 0));
    }
  };

  const totalPages = useCallback(() => {
    const leaderboardPages = site.departments && site.site_configuration?.leaderboard_report_included ? site.departments.length : 0;
    const pages = 1 + perPageDepartments.length + perPageGroups.length + (site.departments ? activeInactivePages : 0) + leaderboardPages;
    return pages;
  }, [perPageDepartments, perPageGroups, activeInactivePages, site.site_configuration, site.departments]);

  const generatePDF = async () => {
    const letterSheetSize = [612, 792]; //Letter
    const pdf = new jsPDF({
      orientation: "p", //portrait
      unit: "pt", //points
      format: letterSheetSize,
    });
    for (let i = 1; i <= totalPages(); i++) {
      const pageImgData = document.getElementById(i.toString());
      if (pageImgData) {
        const [base64Image, scaledHeight] = await convertDivToBase64(pageImgData, letterSheetSize);
        pdf.addImage(base64Image, "JPEG", 0, 0, letterSheetSize[0], scaledHeight);
        if (i < totalPages()) pdf.addPage();
      }
    }
    for (let i = 1; i <= pdf.getNumberOfPages(); i++) {
      const margin = 40;
      pdf.setPage(i);
      pdf.text(
        WebHelper.formatMessage("SiteSummaryReportPDF-footer", {currentPage: i, totalPages: pdf.getNumberOfPages()}),
        margin,
        letterSheetSize[1] - margin,
        {align: "left"}
      );
    }
    pdf.save(WebHelper.formatMessage("SiteSummaryReportPDF-fileName"));
    setLoadingDownload(false);
  };

  useEffect(() => {
    if (loadingDownload) {
      generatePDF();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingDownload]);

  return (
    <>
      <div id="1" className={styles.wrapper}>
        <SiteSummaryReportFirstPage
          endDate={endDate}
          siteName={site.name}
          targetUtilization={targetUtilization}
          data={siteAnalyticsData}
          flags={flags}
        />
      </div>
      {perPageDepartments.length > 0 &&
        perPageDepartments.map((pageDepartments, index) => (
          <div id={(index + 2).toString()} className={styles.wrapper}>
            <SiteSummaryReportDepartmentsPage
              endDate={endDate}
              flags={flags}
              targetUtilization={targetUtilization}
              departments={pageDepartments}
              departmentsAnayltics={siteDepartmentsData}
              key={index + 2}
            />
          </div>
        ))}
      {perPageGroups.length > 0 &&
        perPageGroups.map((pageGroups, index) => (
          <div id={(index + 2 + perPageDepartments.length).toString()} className={styles.wrapper}>
            <SiteSummaryReportGroupsPage
              endDate={endDate}
              flags={flags}
              targetUtilization={targetUtilization}
              reportGroups={pageGroups}
              reportGroupsAnalytics={siteGroupsData}
              key={index + 1 + perPageDepartments.length}
            />
          </div>
        ))}
      {site.site_configuration &&
        site.departments &&
        site.departments.map((department, index) => (
          <>
            {site.site_configuration!.leaderboard_report_included && (
              <div
                id={(
                  2 +
                  perPageDepartments.length +
                  perPageGroups.length +
                  (site.site_configuration!.active_list_report_included && index > 0
                    ? activeInactivePagesArray.slice(0, index).reduce((acc, curr) => acc + curr, 0)
                    : 0) +
                  index
                ).toString()}
                className={styles.wrapper}>
                <SiteSummaryLeaderboardPage
                  endDate={endDate}
                  flags={flags}
                  targetUtilization={targetUtilization}
                  department={department}
                  departmentsAnayltics={siteDepartmentsData}
                  currentPage={
                    2 +
                    perPageDepartments.length +
                    perPageGroups.length +
                    (site.site_configuration!.active_list_report_included && index > 0
                      ? activeInactivePagesArray.slice(0, index).reduce((acc, curr) => acc + curr, 0)
                      : 0) +
                    index
                  }
                  totalPages={totalPages()}
                  key={
                    2 +
                    perPageDepartments.length +
                    perPageGroups.length +
                    (site.site_configuration!.active_list_report_included && index > 0
                      ? activeInactivePagesArray.slice(0, index - 1).reduce((acc, curr) => acc + curr, 0)
                      : 0) +
                    index
                  }
                  siteId={site.id}
                  orgId={site.organization_id}
                  data={dataDepartments ? dataDepartments.find((data) => data.id === department.id)?.data : undefined}
                />
              </div>
            )}
            {site.site_configuration!.active_list_report_included && (
              <ActiveInactiveWorkersSitePage
                endDate={endDate}
                departmentIndex={index}
                index={
                  2 +
                  perPageDepartments.length +
                  perPageGroups.length +
                  (index > 0 ? activeInactivePagesArray.slice(0, index).reduce((acc, curr) => acc + curr, 0) : 0) +
                  (site.site_configuration!.leaderboard_report_included ? index + 1 : 0)
                }
                setPages={handleActiveInactivePages}
                targetUtilization={targetUtilization}
                department={department}
                data={dataDepartments ? dataDepartments.find((data) => data.id === department.id)?.data : undefined}
                key={index + 2}
                siteId={site.id}
                orgId={site.organization_id}
                weigthOffloaded={site.site_configuration!.weight_offloaded_report_included}
                safeliftScore={site.site_configuration!.safelift_score_report_included}
              />
            )}
          </>
        ))}
    </>
  );
};
