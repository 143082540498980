import EventEmitter from "eventemitter3";
import * as _ from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";

import {OrganizationAPIClient} from "../apiclient/organization/OrganizationAPIClient";
import * as OrganizationTypes from "../apiclient/organization/OrganizationAPIClient.types";
import * as Models from "../models";

export type OrganizationsState = {
  loadingOrganizations: boolean;
  loadingOrganization: boolean;
  organizations?: Models.Organization[];
};

export type OrganizationEvent = {
  "organization-created": [Models.Organization];
  "organization-updated": [Models.Organization];
  "organization-deleted": [string];
};

export class OrganizationDataStore {
  private static instance: OrganizationDataStore | undefined;

  private initialState: OrganizationsState = {
    loadingOrganizations: false,
    loadingOrganization: false,
  };

  state = _.cloneDeep(this.initialState);

  eventEmitter: EventEmitter<OrganizationEvent>;

  private constructor() {
    makeObservable<OrganizationDataStore>(this, {
      state: observable,
      organizations: action,
      organizationsShort: action,
      organization: action,
      createOrganization: action,
      updateOrganization: action,
      deleteOrganization: action,
    });
  }

  static getInstance(): OrganizationDataStore {
    if (!OrganizationDataStore.instance) OrganizationDataStore.instance = new OrganizationDataStore();

    return OrganizationDataStore.instance;
  }

  initialize() {
    this.eventEmitter = new EventEmitter();
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
    this.eventEmitter?.removeAllListeners();
  }

  async organizations(request: OrganizationTypes.OrganizationsRequest): Promise<OrganizationTypes.OrganizationsResponse> {
    this.state.loadingOrganizations = true;

    const response = await OrganizationAPIClient.organizations(request);

    runInAction(() => {
      this.state.loadingOrganizations = false;
      if (response.success && !_.isNil(response.organizations)) this.state.organizations = response.organizations;
    });

    return response;
  }

  async organizationsShort(request: OrganizationTypes.OrganizationsRequest): Promise<OrganizationTypes.OrganizationsShortResponse> {
    this.state.loadingOrganizations = true;

    const response = await OrganizationAPIClient.organizationsShort(request);

    runInAction(() => {
      this.state.loadingOrganizations = false;
    });

    return response;
  }

  async organization(request: OrganizationTypes.OrganizationRequest): Promise<OrganizationTypes.OrganizationResponse> {
    this.state.loadingOrganization = true;

    const response = await OrganizationAPIClient.organization(request);

    runInAction(() => {
      this.state.loadingOrganization = false;
      if (response.success && !_.isNil(response.organization)) this.state.organizations = [response.organization];
    });

    return response;
  }

  async createOrganization(request: OrganizationTypes.CreateOrganizationRequest): Promise<OrganizationTypes.CreateOrganizationResponse> {
    const response = await OrganizationAPIClient.createOrganization(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("organization-created", response.organization);
        this.state.organizations = [response.organization];
      }
    });

    return response;
  }

  async deleteOrganization(request: OrganizationTypes.DeleteOrganizationRequest): Promise<OrganizationTypes.DeleteOrganizationResponse> {
    const response = await OrganizationAPIClient.deleteOrganization(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("organization-deleted", request.id);
      }
    });

    return response;
  }

  async updateOrganization(request: OrganizationTypes.UpdateOrganizationRequest): Promise<OrganizationTypes.UpdateOrganizationResponse> {
    const response = await OrganizationAPIClient.updateOrganization(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("organization-updated", response.organization);
        this.state.organizations = [response.organization];
      }
    });

    return response;
  }
}
