import {LDFlagSet} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../../core/models";
import {SiteSummaryReportDepartmentCard} from "../site-summary-report-department-card/SiteSummaryReportDepartmentCard";
import {SiteSummaryReportHeader} from "../site-summary-report-header/SiteSummaryReportHeader";
import styles from "./SiteSummaryReportDepartmentsPage.module.scss";

type SiteSummaryReportDepartmentsPageProps = {
  endDate: string;
  departments: Models.DepartmentInSite[];
  departmentsAnayltics: {id: string; data: Models.AnalyticsDashboardCard}[];
  flags: LDFlagSet;
  targetUtilization: boolean;
};

export const SiteSummaryReportDepartmentsPage: FunctionComponent<SiteSummaryReportDepartmentsPageProps> = ({
  endDate,
  departments,
  departmentsAnayltics,
  flags,
  targetUtilization,
}) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.departmentsPageWrapper}>
        <SiteSummaryReportHeader endDate={endDate} />
        {departments.length > 0 &&
          departments.map((department) => (
            <SiteSummaryReportDepartmentCard
              department={department}
              departmentData={departmentsAnayltics.find((data) => data.id === department.id)?.data}
              flags={flags}
              targetUtilization={targetUtilization}
              key={department.id}
            />
          ))}
      </div>
    </div>
  );
};
