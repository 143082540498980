import {Typography} from "antd";
import {format} from "date-fns";
import {formatInTimeZone} from "date-fns-tz";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";
import styles from "./GatewaysTableLastSeenColumnRender.module.scss";

type GatewaysTableLastSeenColumnRenderProps = {
  mostRecentGatewayEventAt: Date | null;
  siteTimezone: string | undefined;
  status: Models.GatewayStatus;
};

export const GatewaysTableLastSeenColumnRender: FunctionComponent<GatewaysTableLastSeenColumnRenderProps> = observer(
  ({mostRecentGatewayEventAt, siteTimezone, status}) => {
    return <>{mapDeviceStatusToIcon(status, siteTimezone, mostRecentGatewayEventAt)}</>;
  }
);

const formatDate = (date: Date | null, siteTimezone: string | undefined, formatString: string) => {
  if (!date) return "";
  return siteTimezone ? formatInTimeZone(date, siteTimezone, formatString) : format(date, formatString);
};

function mapDeviceStatusToIcon(status: Models.GatewayStatus, siteTimezone: string | undefined, mostRecentGatewayEventAt: Date | null) {
  switch (status) {
    case Models.GatewayStatus.Up:
    case Models.GatewayStatus.Down:
    case Models.GatewayStatus.Intermittent:
      return (
        <Typography.Text className={styles.wrapper}>
          <span
            className={`${styles.circleSolid} ${
              status === Models.GatewayStatus.Up ? styles.up : status === Models.GatewayStatus.Down ? styles.down : styles.intermittent
            }`}
          />
          {mostRecentGatewayEventAt &&
            formatDate(mostRecentGatewayEventAt, siteTimezone, WebHelper.getFormatStringByDate(mostRecentGatewayEventAt))}
        </Typography.Text>
      );
      break;
    case Models.GatewayStatus.NeverSeen:
      return (
        <Typography.Text className={styles.wrapper}>
          <span className={`${styles.circleSolid} ${styles.neverSeen}`} />
          {WebHelper.formatMessage("GatewaysTableLastSeenColumnRender-neverSeen")}
        </Typography.Text>
      );
      break;
    default:
      return null;
      break;
  }
}
