import {PlusOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Space} from "antd";
import React, {useState, useEffect} from "react";

import * as Models from "../../../../core/models";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {WorkerReportGroupDataStore} from "../../../../core/stores/WorkerReportGroupDataStore";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {WebHelper} from "../../../utils/WebHelper";
import {Button} from "../../common/button/Button";
import {Loading} from "../../loading/Loading";
import {CreateWorkersReportGroupModal} from "./create-workers-report-group-modal/CreateWorkersReportGroupModal";
import {WorkerReportGroupsDisplay} from "./worker-report-groups-display/WorkerReportGroupsDisplay";
import styles from "./WorkerReportGroupsTabContent.module.scss";

type WorkerReportGroupsTabContentProps = {
  siteID: string;
  orgID?: string;
};

export const WorkerReportGroupsTabContent: React.FC<WorkerReportGroupsTabContentProps> = ({siteID, orgID}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const workerReportGroupStore = WorkerReportGroupDataStore.getInstance();

  const [showCreateWorkerReportGroup, setShowCreateWorkerReportGroup] = useState(false);

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.WorkerReportGroupTabOpened, {orgID: orgID, siteID: siteID});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const workerReportGroupsQuery = useQuery({
    queryKey: ["WorkerReportGroupsDisplay-fetchWorkerReportGroups", siteID],
    queryFn: () =>
      workerReportGroupStore.workerReportGroups({
        accessToken: authenticationStore.state.accessToken!,
        site_id: siteID,
      }),
    enabled: !!siteID,
  });

  useEffect(() => {
    if (workerReportGroupsQuery.data && !workerReportGroupsQuery.data.success)
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError"),
        workerReportGroupsQuery.data.correlationId
      );
  }, [workerReportGroupsQuery.data]);

  return (
    <Space className={styles.content} direction="vertical" size="large">
      {(authenticationStore.permissionLevel(Models.UserScope.Site, siteID) === Models.UserAccessLevel.Admin ||
        authenticationStore.permissionLevel(Models.UserScope.Site, siteID) === Models.UserAccessLevel.Manager) && (
        <Button shape="round" icon={<PlusOutlined />} onClick={() => setShowCreateWorkerReportGroup(true)}>
          {WebHelper.formatMessage("WorkerReportGroupsTabContent-createNewGroupButton")}
        </Button>
      )}
      {workerReportGroupsQuery.isFetching ? (
        <Loading />
      ) : !workerReportGroupsQuery.data?.workerReportGroups ? null : (
        <>
          <WorkerReportGroupsDisplay workerReportGroups={workerReportGroupsQuery.data.workerReportGroups} />
          <CreateWorkersReportGroupModal
            workersReportGroups={workerReportGroupsQuery.data.workerReportGroups}
            siteID={siteID}
            open={showCreateWorkerReportGroup}
            onClose={() => setShowCreateWorkerReportGroup(false)}
            onCreate={workerReportGroupsQuery.refetch}
          />
        </>
      )}
    </Space>
  );
};
