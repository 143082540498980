import {es_ES as baseCoreLocale} from "../../core/locales/es_ES";
import {Locale as CoreLocale} from "../../core/locales/Locale";
import {CoreHelper} from "../../core/utils/CoreHelper";
import {Locale} from "./Locale";

const uiLocale: Omit<Locale, keyof CoreLocale> = {
  "AboutUs-appVersion": "Versión de la aplicación: {version} ({currentCommitHash})",
  "AboutUs-backendVersion": "Versión del servidor: {version} ({currentCommitHash})",
  "AboutUs-copyright": "Copyright © {year}",
  "AboutUs-fetchError": "Error al intentar obtener la versión del servidor",
  "AboutUs-subtitle": "Acerca de Verve Logic",
  "AccountSettings-department": "Departamento:",
  "AccountSettings-email": "Correo electrónico:",
  "AccountSettings-firstName": "Nombre:",
  "AccountSettings-jobTitle": "Titulo profesional:",
  "AccountSettings-lastName": "Apellido:",
  "AccountSettings-organization": "Organización:",
  "AccountSettings-organizationGlobal": "GLOBAL",
  "AccountSettings-phoneNumberError":
    "El número de teléfono debe empezar con el signo de + y luego solo contener dígitos (incluyendo el código de país).",
  "AccountSettings-profileData": "Datos del perfil",
  "AccountSettings-subscribed": "Suscribir a email de administrador:",
  "AccountSettings-title": "Configuración de la cuenta",
  "AccountSettings-updateError": "Error al intentar actualizar la información del usuario",
  "AccountSettings-updateErrorPhoneNumber": "Número de teléfono inválido. Por favor, verifica nuevamente e inténtalo de nuevo.",
  "AccountSettings-workAddress": "Dirección de trabajo:",
  "AccountSettings-workPhone": "Teléfono de trabajo:",
  "ActiveInactiveSiteSummaryPage-activeWorkers": "Trabajadores Activos",
  "ActiveInactiveSiteSummaryPage-daysActiveLast7DaysColumnTitle": "Días Activos en los Últimos 7 Días",
  "ActiveInactiveSiteSummaryPage-daysSinceLastUsageColumnTitle": "Días Desde el Último Uso",
  "ActiveInactiveSiteSummaryPage-deviceTagColumnTitle": "Etiqueta del dispositivo",
  "ActiveInactiveSiteSummaryPage-employeeIDTitle": "ID empleado",
  "ActiveInactiveSiteSummaryPage-hoursLast7DaysColumnTitle": "Horas en los Últimos 7 Días",
  "ActiveInactiveSiteSummaryPage-inactiveWorkers": "Trabajadores Inactivos",
  "ActiveInactiveSiteSummaryPage-kilogramsUnit": "kg",
  "ActiveInactiveSiteSummaryPage-nameColumnTitle": "Nombre",
  "ActiveInactiveSiteSummaryPage-poundsUnit": "lb",
  "ActiveInactiveSiteSummaryPage-relativeToTargetColumnTitle": "Relativo al Objetivo",
  "ActiveInactiveSiteSummaryPage-safeliftScore": "Puntuación de Levantamientos Seguros",
  "ActiveInactiveSiteSummaryPage-weeksSinceFirstUseColumnTitle": "Semanas Desde el Primer Uso",
  "ActiveInactiveSiteSummaryPage-weightOffloaded": "Peso Descargado",
  "ActiveInactiveWorkersReportDropdown-WorkersActivityReport": "Informe de Actividad de los Trabajadores",
  "ActiveInactiveWorkersReportDropdown-activeWorkers": "Trabajadores Activos",
  "ActiveInactiveWorkersReportDropdown-asPDF": "Como PDF",
  "ActiveInactiveWorkersReportDropdown-asXLSX": "Como XLSX",
  "ActiveInactiveWorkersReportDropdown-deviceTagColumnTitle": "Etiqueta del dispositivo",
  "ActiveInactiveWorkersReportDropdown-daysActiveLast7DaysColumnTitle": "Días Activos en los Últimos 7 Días",
  "ActiveInactiveWorkersReportDropdown-daysSinceLastUsageColumnTitle": "Días Desde el Último Uso",
  "ActiveInactiveWorkersReportDropdown-downloadData": "Descargar Datos",
  "ActiveInactiveWorkersReportDropdown-employeeIDTitle": "ID empleado",
  "ActiveInactiveWorkersReportDropdown-hoursLast7DaysColumnTitle": "Horas en los Últimos 7 Días",
  "ActiveInactiveWorkersReportDropdown-inactiveWorkers": "Trabajadores Inactivos",
  "ActiveInactiveWorkersReportDropdown-nameColumnTitle": "Nombre",
  "ActiveInactiveWorkersReportDropdown-pageCount": "Página {currentPage} de {totalPages}",
  "ActiveInactiveWorkersReportDropdown-relativeToTargetColumnTitle": "Relativo al Objetivo",
  "ActiveInactiveWorkersReportDropdown-weeksSinceFirstUseColumnTitle": "Semanas Desde el Primer Uso",
  "AddDepartmentForm-title": "Agregar departamento",
  "AddOrganizationForm-title": "Agregar organización",
  "AddSiteForm-title": "Agregar sitio",
  "AddWorkersModal-errorMessage": "Error al intentar actualizar el grupo de trabajadores",
  "AddWorkersModal-noWorkersText": "No se encontraron trabajadores",
  "AddWorkersModal-selectWorkersLabel": "Seleccione los trabajadores que desea agregar al grupo:",
  "AddWorkersModal-successMessage": "Los trabajadores seleccionados fueron agregados exitosamente",
  "AddWorkersModal-title": "Agregar Trabajadores",
  "AddWorkersModal-workers": "Trabajadores:",
  "AllocateGateway-allocate": "Asignar",
  "AllocateGateway-allocateGatewayButton": "Asignar Gateway",
  "AllocateGateway-allocateGatewayLabel": "Asignar Gateway",
  "AllocateGateway-allocateGatewayModalMessage": "Seleccione el numero de serie del gateway que desea asignar a **{departmentName}**",
  "AllocateGateway-allocateGatewayModalTitle": "Asignar Gateway",
  "AllocateGateway-allocateGatewayToDepartmentError": "No se pudo asignar el gateway",
  "AllocateGateway-gatewaySerialNumberLabel": "# de Serie del Gateway",
  "AllocateSelectedData-confirmation": "Confirmación",
  "AllocateSelectedData-dateRange": "Rango de Fecha:",
  "AllocateSelectedData-finished": "Finalizado",
  "AllocateSelectedData-infoMeesage":
    "Las fechas son inclusivas. Los datos tanto del inicio como del final se asignarán al trabajador seleccionado.",
  "AllocateSelectedData-inProgress": "En Progreso",
  "AllocateSelectedData-modalTitle": "Asignar Datos Seleccionados",
  "AllocateSelectedData-selectStartDate": "Seleccionar fecha de inicio",
  "AllocateSelectedData-select": "Seleccione Rango de Fecha y Trabajador",
  "AllocateSelectedData-selectText":
    "Por favor seleccione el rango de fechas deseado y al trabajador para reasignar los datos del dispositivo.",
  "AllocateSelectedData-selectEndDate": "Seleccionar fecha de fin",
  "AllocateSelectedData-selectWorker": "Seleccionar Trabajador",
  "AllocateSelectedData-singleDay": "Único día",
  "AllocateSelectedData-updateError": "Error al intentar actualizar el trabajador asignado a la sesión",
  "AllocateSelectedData-updateSuccess": "Los datos del dispositivo {deviceTag} fue reasignada con éxito {workerName}.",
  "AllocateSelectedData-waiting": "Esperando",
  "AllocateSelectedData-confirmationText":
    "Por favor confirme si deseas asignar todos los datos del dispositivo **{deviceTag}** entre **{startDate}** y **{endDate}** a **{workerName}**",
  "AllocationFailed-alreadyAllocatedToCurrentDepartmentError":
    "**{serialNumber}**: El dispositivo ya está asignado a **{siteName}** - **{departmentName}**",
  "AllocationFailed-alreadyAllocatedToOtherDepartmentError": "**{serialNumber}**: El dispositivo ya está asignado a otro departamento",
  "AllocationFailed-emptyRowsError": "Filas con valores vacíos fueron detectadas",
  "AllocationFailed-firstText": "No se asignaron dispositivos a **{siteName}** - **{departmentName}**",
  "AllocationFailed-missingColumnError": "La columna {columnName} no fue encontrada en el archivo subido",
  "AllocationFailed-noRowsError": "No se encontraron Números de Serie en el archivo cargado",
  "AllocationFailed-secondText": "Los siguientes errores ocurrieron:",
  "AllocationFailed-serialNumberNotFoundError": "**{serialNumber}**: Número de Serie no encontrado",
  "AllocationFailed-subtitle": "Asignación fallida",
  "AllWorkersReportDrawer-contentMessage":
    "El reporte de trabajadores cubre un período de 7 días. Por favor seleccione la fecha de finalización deseada para el reporte.",
  "AllWorkersReportDrawer-drawerTitle": "Reporte de Trabajadores",
  "AllWorkersReportDrawer-fileName": "Reporte de Trabajadores",
  "Analytics-csvColumn-assignedWorkers": "Trabajadores Asignados",
  "Analytics-csvColumn-activeWorkers": "Trabajadores Activos",
  "Analytics-csvColumn-activeWorkersPercentage": "Porcentaje de trabajadores activos",
  "Analytics-csvColumn-endDate": "Fecha de fin",
  "Analytics-csvColumn-endTime": "Hora de fin",
  "Analytics-csvColumn-excessiveForwardLifts": "Levantamientos delanteros excesivos",
  "Analytics-csvColumn-hoursUsed": "Horas Usadas",
  "Analytics-csvColumn-inactiveWorkers": "Trabajadores Inactivos",
  "Analytics-csvColumn-inactiveWorkersPercentage": "Porcentaje de trabajadores inactivos",
  "Analytics-csvColumn-prolongedBendLifts": "Levantamientos con flexión prolongada",
  "Analytics-csvColumn-resourceID": "ID del recurso",
  "Analytics-csvColumn-resourceName": "Name del recurso",
  "Analytics-csvColumn-resourceType": "Tipo de recurso",
  "Analytics-csvColumn-riskyLifts": "Levantamientos Riesgosos",
  "Analytics-csvColumn-riskyLiftsPercentage": "Porcentaje de levantamientos riesgosos",
  "Analytics-csvColumn-safeLifts": "Levantamientos Seguros",
  "Analytics-csvColumn-safeLiftsPercentage": "Porcentaje de levantamientos seguros",
  "Analytics-csvColumn-sideBendLifts": "Levantamientos con flexión lateral",
  "Analytics-csvColumn-startDate": "Fecha de inicio",
  "Analytics-csvColumn-startTime": "Hora de inicio",
  "Analytics-csvColumn-totalLifts": "Levantamientos Totales",
  "Analytics-csvColumn-twistedLifts": "Levantamientos en giro",
  "Analytics-csvColumn-weightOffloaded": "Peso Descargado",
  "Analytics-fetchDataError": "Ocurrió un error al obtener la información",
  "Analytics-noOrgPlaceholderTitle": "Seleccione una organización en la parte superior de esta pantalla para empezar a ver analíticas",
  "AnalyticsChart-datasetLabel-activeWorkers": "Activos",
  "AnalyticsChart-datasetLabel-excessiveForwardBending": "Flexión delantera excesiva",
  "AnalyticsChart-datasetLabel-hoursUsed": "Horas Usadas",
  "AnalyticsChart-datasetLabel-inactiveWorkers": "Inactivos",
  "AnalyticsChart-datasetLabel-prolongedBending": "Flexión prolongada",
  "AnalyticsChart-datasetLabel-risky": "Riesgoso",
  "AnalyticsChart-datasetLabel-safe": "Seguro",
  "AnalyticsChart-datasetLabel-sideBending": "Flexión lateral",
  "AnalyticsChart-datasetLabel-target": "Objetivo",
  "AnalyticsChart-datasetLabel-twisting": "Giro",
  "AnalyticsChart-dataTypeSelector-label": "Datos",
  "AnalyticsChart-dataTypeSelector-percentage": "Porcentaje",
  "AnalyticsChart-dataTypeSelector-raw": "Bruto",
  "AnalyticsChart-hoursUnit": "Horas Usadas",
  "AnalyticsChart-hoursUsed": "Horas Usadas",
  "AnalyticsChart-liftsUnit": "Levantamientos",
  "AnalyticsChart-percentageOfLifts": "% de Levantamientos",
  "AnalyticsChart-percentageOfWorkers": "% de Trabajadores",
  "AnalyticsChart-movementBreakdown": "Desglose de movimientos",
  "AnalyticsChart-movementSelector": "Movimientos",
  "AnalyticsChart-poundsUnit": "lbs",
  "AnalyticsChart-tooltip-totalLifts": "Total: {total} levantamientos",
  "AnalyticsChart-tooltip-totalWorkers": "Asignados: {assigned} trabajadores",
  "AnalyticsChart-weightOffloaded": "Peso Descargado",
  "AnalyticsChart-weightOffloadedUnitLbs": "Peso Descargado (lbs)",
  "AnalyticsChart-weightOffloadedUnitKgs": "Peso Descargado (kgs)",
  "AnalyticsChart-workersUnit": "Trabajadores",
  "AnalyticsSettings-allWorkersLabel": "Todos los Trabajadores",
  "AnalyticsSettings-data": "Datos:",
  "AnalyticsSettings-dateRange": "Rango de Fechas:",
  "AnalyticsSettings-day": "Día",
  "AnalyticsSettings-displayInGraph": "Mostrar en Gráfica",
  "AnalyticsSettings-displayMaxData": "Mostrar maxima información",
  "AnalyticsSettings-displayMaxDataTooltip":
    "Representa gráficamente el alcance de los datos en hasta 30 intervalos (30 días o 30 semanas)",
  "AnalyticsSettings-emptyWorkerReportGroupsTooltip": "No se encontraron Grupos de Trabajadores",
  "AnalyticsSettings-emptyWorkersTooltip": "No se encontraron Trabajadores",
  "AnalyticsSettings-endDate": "Fecha de Fin:",
  "AnalyticsSettings-excessiveForwardBending": "Inclinación Excesiva",
  "AnalyticsSettings-filter": "Filtrar:",
  "AnalyticsSettings-hoursUsed": "Horas Usado",
  "AnalyticsSettings-individualWorkerLabel": "Trabajador Individual",
  "AnalyticsSettings-interval": "Intervalo:",
  "AnalyticsSettings-lifts": "Levantamientos",
  "AnalyticsSettings-loadingFilters": "Cargando filtros...",
  "AnalyticsSettings-month": "Mes",
  "AnalyticsSettings-mostRecent": "Más reciente",
  "AnalyticsSettings-movementBreakdown": "Desglose de movimientos",
  "AnalyticsSettings-movements": "Movimientos:",
  "AnalyticsSettings-percentage": "Porcentaje",
  "AnalyticsSettings-percentOfTarget": "Porcentaje del Objetivo",
  "AnalyticsSettings-prolongedBending": "Inclinación Prolongada",
  "AnalyticsSettings-quarter": "Trimestre",
  "AnalyticsSettings-raw": "Bruto",
  "AnalyticsSettings-risky": "Riesgoso",
  "AnalyticsSettings-safe": "Seguro",
  "AnalyticsSettings-selectEndDate": "Seleccionar fecha de fin",
  "AnalyticsSettings-selectStartDate": "Seleccionar fecha de inicio",
  "AnalyticsSettings-sideBending": "Inclinación Lateral",
  "AnalyticsSettings-timezoneMessage": "Las fechas/horas están en zona horaria *{timezone}*",
  "AnalyticsSettings-twisting": "Torsión",
  "AnalyticsSettings-variableToChart": "Variable a Graficar:",
  "AnalyticsSettings-week": "Semana",
  "AnalyticsSettings-weightOffloaded": "Peso Descargado",
  "AnalyticsSettings-workerActiveStatus": "Estado de Actividad del Trabajador",
  "AnalyticsSettings-workerReportGroupsLabel": "Grupos de Reportes de Trabajadores",
  "AnalyticsSettings-workers": "Trabajadores:",
  "AnalyticsSubheader-downloadDataButton": "Descargar Datos",
  "AnalyticsSubheader-loadingOrganizations": "Cargando organizaciones...",
  "AnalyticsSubheader-selectOrganization": "Elige una organización...",
  "AnalyticsSubheader-title": "Analíticas",
  "AnalyticsSubheader-titleSeparator": "/",
  "AnalyticsTabContent-activeWorkers": "Trabajadores Activos",
  "AnalyticsTabContent-asCsv": "Como CSV",
  "AnalyticsTabContent-asImage": "Como Imagen",
  "AnalyticsTabContent-averageMessage": "Porcentaje De {unit} Por {interval}: **{value}**",
  "AnalyticsTabContent-day": "Día",
  "AnalyticsTabContent-errorMessage": "Ocurrió un error al obtener la información",
  "AnalyticsTabContent-hideFiltersLabel": "Ocultar Filtros",
  "AnalyticsTabContent-hoursUnit": "horas",
  "AnalyticsTabContent-liftsUnit": "levantamientos",
  "AnalyticsTabContent-poundsUnit": "lbs",
  "AnalyticsTabContent-kilogramsUnit": "kgs",
  "AnalyticsTabContent-month": "Mes",
  "AnalyticsTabContent-quarter": "Trimestre",
  "AnalyticsTabContent-showAllFiltersLabel": "Mostrar Todos los Filtros",
  "AnalyticsTabContent-totalValueMessage": "Total {variable} by selected date range: **{value} {unit}**",
  "AnalyticsTabContent-week": "Semana",
  "App-accountSettings": "Configuración de la cuenta",
  "App-deactivatedUserSubtitle1": "Su cuenta ha sido desactivada.",
  "App-deactivatedUserSubtitle2": "Si cree que esto fue hecho por error por favor contacte a su administrador de sitio.",
  "App-deactivatedUserTitle": "Cuenta Desactivada",
  "App-deviceManagement": "Manejo de Dispositivos",
  "App-fetchMeError": "Ocurrió un error al obtener la información del usuario",
  "App-internetError": "No hay conexión a internet, refresque la página para intentarlo otra vez",
  "App-logOut": "Cerrar sesión",
  "App-menuItemAboutUs": "Acerca de",
  "App-menuItemAdmin": "Admin",
  "App-menuItemAnalytics": "Analíticas",
  "App-menuItemAssignment": "Asignaciones",
  "App-menuItemConfiguration": "Configuración",
  "App-menuItemDashboard": "Tablero",
  "App-menuItemDiagnostics": "Diagnósticos",
  "App-menuItemReportIssue": "Reportar Problema",
  "App-userPendingApprovalMessage1": "El administrador de su sitio aún no ha aprobado su cuenta, este paso puede tomar uno o dos días.",
  "App-userPendingApprovalMessage2": "Si tiene alguna pregunta, por favor envíe un correo electrónico al administrador de su sitio.",
  "App-userPermissions": "Permisos de usuarios",
  "BulkCreateDevicesModal-configuration": "Configuración",
  "BulkCreateDevicesModal-confirm": "Confirmar",
  "BulkCreateDevicesModal-confirmation": "Confirmación",
  "BulkCreateDevicesModal-errorMessage": "Ocurrio un error al intentar actualizar los dispositivos",
  "BulkCreateDevicesModal-finished": "Finalizado",
  "BulkCreateDevicesModal-inProgress": "En Progreso",
  "BulkCreateDevicesModal-modalTitle": "Creación Masiva de Dispositivos, Configuración y Asignación",
  "BulkCreateDevicesModal-next": "Siguiente",
  "BulkCreateDevicesModal-selectDevices": "Seleccionar Dispositivos",
  "BulkCreateDevicesModal-successMessage": "Creación masiva de dispositivos correctamente completada",
  "BulkCreateDevicesModal-waiting": "Esperando",
  "BulkCreateStepThree-department": "Departmento:",
  "BulkCreateStepThree-loadingOrganizations": "Cargando organizaciones...",
  "BulkCreateStepThree-selectDepartment": "Selecciona un único departamento para asignar los dispositivos.",
  "BulkCreateStepThree-selectOrganization": "Selecciona una organización...",
  "BulkCreateStepTwo-button1": "Botón 1:",
  "BulkCreateStepTwo-button2": "Botón 2:",
  "BulkCreateStepTwo-button3": "Botón 3:",
  "BulkCreateStepTwo-firmwareVersion": "Versión del Firmware:",
  "BulkCreateStepTwo-groupCount": "[{count} Dispositivos]: ",
  "BulkCreateStepTwo-groupName": "Grupo {count}",
  "BulkCreateStepTwo-groupsMessage": "Hemos identificado los siguientes grupos de dispositivos basados en su tipo:",
  "BulkCreateStepTwo-noButtonSettings": "No se encontraron configuraciones de botones",
  "BulkCreateStepTwo-selectSettingsMessage": "Por favor seleccione la configuracion que desea actualizar del dispositivo:",
  "BulkCreateStepTwo-typeCount": "{name} [{count} Dispositivos]",
  "BulkCreateStepOne-csvRadioText": "Sube una lista de SNs en un archivo CSV Columns: System Serial Number, Mcu ID",
  "BulkCreateStepOne-mcuIDColumnName": "Mcu ID",
  "BulkCreateStepOne-selectDevices": "Selecciona SNs de Dispositivos a crear",
  "BulkCreateStepOne-serialNumberColumnName": "Número de Serie del Sistema",
  "BulkCreateStepOne-uploadFailDevicesTitle": "No todos los dispositivos en el CSV pueden ser creados.",
  "BulkCreateStepOne-uploadFailTitle": "Error de Carga",
  "BulkCreateStepOne-uploadFailText": "El archivo CSV no está formateado correctamente, corríjalo e inténtelo de nuevo.",
  "BulkUpdateDevicesModal-configuration": "Configuración",
  "BulkUpdateDevicesModal-confirm": "Confirmar",
  "BulkUpdateDevicesModal-confirmation": "Confirmación",
  "BulkUpdateDevicesModal-finished": "Finalizado",
  "BulkUpdateDevicesModal-inProgress": "En Progreso",
  "BulkUpdateDevicesModal-modalTitle": "Actualización Masiva de Dispositivos",
  "BulkUpdateDevicesModal-next": "Siguiente",
  "BulkUpdateDevicesModal-selectDevices": "Seleccionar Dispositivos",
  "BulkUpdateDevicesModal-waiting": "Esperando",
  "BulkUpdateDevicesModal-successMessage": "La actualización de la configuracioón de los dispositivos fue correctamente programada.",
  "BulkUpdateDevicesModal-errorMessage": "Ocurrio un error al intentar actualizar los dispositivos",
  "BulkUpdateStepOne-allDevicesText": "Todos los dipsitivos de Organización/Sitio/Departamento",
  "BulkUpdateStepOne-allOrgs": "Todas las organizaciones",
  "BulkUpdateStepOne-devices": "Dispositivos:",
  "BulkUpdateStepOne-loadingOrganizations": "Cargando organizaciones...",
  "BulkUpdateStepOne-locations": "Ubicaciones:",
  "BulkUpdateStepOne-manualDevicesText":
    "Seleccionar Organización/Sitio/Departamento luego manualmente ingresar Números de Serie para actualizar",
  "BulkUpdateStepOne-noDevicesText": "No hay dispositivos en este departamento.",
  "BulkUpdateStepOne-selectDevices": "Seleccionar dispositivos para actualizar.",
  "BulkUpdateStepOne-selectOrganization": "Elige una organización...",
  "BulkUpdateStepThree-button1": "Botón 1:",
  "BulkUpdateStepThree-button2": "Botón 2:",
  "BulkUpdateStepThree-button3": "Botón 3:",
  "BulkUpdateStepThree-firmwareVersion": "Versión del Firmware:",
  "BulkUpdateStepThree-groupCount": "[{count} Dispositivos]: ",
  "BulkUpdateStepThree-groupName": "Grupo {count}",
  "BulkUpdateStepThree-groupsMessage": "Hemos identificado los siguientes grupos de dispositivos basados en su tipo:",
  "BulkUpdateStepThree-noButtonSettings": "No se encontraron configuraciones de botones",
  "BulkUpdateStepThree-selectSettingsMessage": "Por favor seleccione la configuracion que desea actualizar del dispositivo:",
  "BulkUpdateStepThree-typeCount": "{name} [{count} Dispositivos]",
  "BulkUpdateStepTwo-confirmUpdateAll": "Por favor, confirma que deseas actualizar TODOS los dispositivos en las siguientes lugares:",
  "ChangeDeviceModal-assignDevice": "Asignar Dispositivo",
  "ChangeDeviceModal-assignDeviceMessage": "¿Está seguro que desea asignar a **{workerName}** el dispositivo **{deviceTag}**?",
  "ChangeDeviceModal-assignDeviceSuccessMessage": "El dipositivo {deviceTag} fue asignado con éxito a {workerName}",
  "ChangeDeviceModal-assignError": "Ocurrió un error al intentar actualizar el trabajador",
  "ChangeDeviceModal-changeDevice": "Cambiar Dispositivo",
  "ChangeDeviceModal-changeDeviceMessage":
    "¿Está seguro que desea cambiar el dispositivo **{deviceTag}** a **{newDeviceTag}** para el trabajador **{workerName}**?",
  "ChangeDeviceModal-unassignDevice": "Desasignar Dispositivo",
  "ChangeDeviceModal-unassignDeviceMessage":
    "¿Está seguro que desea desasignar el dispositivo **{deviceTag}** del trabajador **{workerName}**?",
  "ChangeDeviceStatusModal-archive": "Archivar:",
  "ChangeDeviceStatusModal-archiveError": "Ocurrió un error al intentar archivar el dispositivo",
  "ChangeDeviceStatusModal-archived": "Archivado",
  "ChangeDeviceStatusModal-archivedTrueMessage":
    "Cambiar el dispositivo a **Archivado: Verdadero** hara que el dispositivo solo se pueda ver desde la sección de Gestión de Dispositivos.",
  "ChangeDeviceStatusModal-changeStatusError": "Ocurrió un error al intentar actualizar el estado",
  "ChangeDeviceStatusModal-descriptionToInService": "¿Está seguro que desea cambiar el estado de **{deviceTag}** a **En Servicio**?",
  "ChangeDeviceStatusModal-descriptionToOutService": "¿Está seguro que desea cambiar el estado de **{deviceTag}** a Fuera De Servicio?",
  "ChangeDeviceStatusModal-notArchived": "Cambiar el Estado del Dispositivo",
  "ChangeDeviceStatusModal-successMessageToInService": "El estado del dispositivo {deviceTag} fue cambiado a En Servicio exitosamente",
  "ChangeDeviceStatusModal-successMessageToOutService":
    "El estado del dispositivo {deviceTag} fue cambiado a Fuera de Servicio exitosamente",
  "ChangeDeviceStatusModal-successMessageToOutServiceAndArchived":
    "El estado del dispositivo {deviceTag} fue cambiado a Fuera de Servicio y Archivado exitosamente",
  "ChangeDeviceStatusModal-title": "Cambiar el Estado del Dispositivo",
  "ChangeWorkerModal-assignWorker": "Asignar Trabajador",
  "ChangeWorkerModal-assignWorkerMessage": "¿Está seguro que desea asignar a **{workerName}** el dispositivo **{deviceTag}**?",
  "ChangeWorkerModal-assignWorkerSuccessMessage": "El trabajador {workerName} fue asignado con exito a {deviceTag}",
  "ChangeWorkerModal-assignError": "Ocurrio un error al intentar actualizar el dispositivo",
  "ChangeWorkerModal-changeWorker": "Cambiar Trabajador",
  "ChangeWorkerModal-changeWorkerMessage":
    "¿Está seguro que desea cambiar el trabajador **{workerName}** a **{newWorkerName}** para el dispositivo **{deviceTag}**?",
  "ChangeWorkerModal-unassignWorker": "Desasignar Trabajador",
  "ChangeWorkerModal-unassignWorkerMessage":
    "¿Está seguro que desea desasignar el trabajador **{workerName}** del dispositivo **{deviceTag}**?",
  "ChangeWorkerStatusModal-changeStatusError": "Ocurrió un error al intentar actualizar el estado",
  "ChangeWorkerStatusModal-descriptionEnroll": "¿Está seguro que desea cambiar el estado de **{worker}** a **Enlistado**?",
  "ChangeWorkerStatusModal-descriptionUnenroll": "¿Está seguro que desea cambiar el estado de **{worker}** a **No Listado**?",
  "ChangeWorkerStatusModal-reasonsLabel": "Razón:",
  "ChangeWorkerStatusModal-successMessageEnrolled": "El estado del trabajador {worker} se cambió exitosamente a Enlistado",
  "ChangeWorkerStatusModal-successMessageUnenrolled": "El estado del trabajador {worker} se cambió exitosamente a No Listado",
  "ChangeWorkerStatusModal-title": "Cambiar el Estado del Trabajador",
  "ControllerSettings-heavyLiftHigh": "Levantamiento Pesado Alto",
  "ControllerSettings-heavyLiftLow": "Levantamiento Pesado Bajo",
  "ControllerSettings-heavyLiftMedium": "Levantamiento Pesado Medio",
  "ControllerSettings-squatLiftHigh": "Levantamiento Sentadilla Alto",
  "ControllerSettings-squatLiftMedium": "Levantamiento Sentadilla Medio",
  "ControllerSettings-squatLiftTransparent": "Levantamiento Sentadilla Transparente",
  "ControllerSettings-universalLiftHigh": "Levantamiento Universal Alto",
  "ControllerSettings-universalLiftMedium": "Levantamiento Universal Medio",
  "ControllerSettings-universalLiftTransparent": "Levantamiento Universal Transparente",
  "ControllerSettings-universalLowerHigh": "Universal Bajo Alto",
  "ControllerSettings-universalLowerMedium": "Universal Bajo Medio",
  "ControllerSettings-universalLowerTransparent": "Universal Bajo Transparente",
  "ControllerSettings-floorAssembly70": "Ensamblaje de Piso 70",
  "ControllerSettings-floorAssembly90": "Ensamblaje de Piso 90",
  "ControllerSettings-floorAssembly110": "Ensamblaje de Piso 110",
  "ControllerSettings-floorAssembly130": "Ensamblaje de Piso 130",
  "ControllerSettings-floorAssembly150": "Ensamblaje de Piso 150",
  "ControllerSettings-floorAssembly170": "Ensamblaje de Piso 170",
  "ControllerSettings-floorAssembly190": "Ensamblaje de Piso 190",
  "ControllerSettings-floorAssemblyTransparent": "Ensamblaje de Piso Transparent",
  "Common-accept": "Aceptar",
  "Common-add": "Agregar",
  "Common-addToFavorites": "Agregar A Favoritos",
  "Common-and": "y",
  "Common-area": "Área",
  "Common-at": "a las",
  "Common-back": "Regresar",
  "Common-below": "Debajo",
  "Common-between": "entre",
  "Common-cancel": "Cancelar",
  "Common-change": "Cambiar",
  "Common-close": "Cerrar",
  "Common-closeFormAlertMessage": "¿Está seguro que desea descartar los cambios sin guardar?.",
  "Common-closeFormAlertTitle": "Descartar Cambios",
  "Common-confirm": "Confirmar",
  "Common-confirmation": "Por favor escribe **{confirmationValue}** para confirmar:",
  "Common-copyErrorCode": "Copiar código de error",
  "Common-create": "Crear",
  "Common-created": "Creado",
  "Common-createOptionsManually": "Manualmente",
  "Common-createOptionsUploadCSV": "Cargar CSV",
  "Common-dateFormatMonthDay": "dd/MM",
  "Common-dateFormatMonthDayOnly": "MMM dd",
  "Common-dateFormatMonthDayYear": "MMM dd, yyyy",
  "Common-dateFormatMonthYear": "MMM yyyy",
  "Common-dateFormatQuarterYear": "QQQ yyyy",
  "Common-dateFormatTimeOnly": "hh:mm a",
  "Common-dateFormatWithTimezone": "d/M/yyyy h:mm a zz",
  "Common-dateFormatWithTimezoneNoTime": "d/M/yyyy",
  "Common-dateTimeFormatWithoutTimezone": "yyyy-MM-dd'T'H:mm:ss.SSS'Z'",
  "Common-dateTimeFormatWithoutYear": "MMM dd, hh:mm:ss",
  "Common-dayAbbreviation": "d",
  "Common-deallocateConfirmationValue": "desasignar",
  "Common-delete": "Eliminar",
  "Common-deleteConfirmationValue": "borrar",
  "Common-description": "Descripción",
  "Common-discard": "Descartar",
  "Common-draggerText": "Haga click o arrastre archivo a esta área para cargar",
  "Common-duration": "Duración",
  "Common-edit": "Editar",
  "Common-email": "Email",
  "Common-enrolledTooltip": "Enlistado",
  "Common-exempt": "Exento",
  "Common-extension": "Extensión",
  "Common-false": "Falso",
  "Common-fieldRequiredMessage": "Este campo es requerido",
  "Common-fieldUrlFormatMessage": "Ingrese una url válida",
  "Common-firstName": "Nombre",
  "Common-gatewayStatusDown": "Fuera de Línea",
  "Common-gatewayStatusIntermittent": "Intermitente",
  "Common-gatewayStatusNeverSeen": "Nunca Visto",
  "Common-gatewayStatusUp": "En Línea",
  "Common-hourAbbreviation": "h",
  "Common-info": "Información",
  "Common-lastName": "Apellido",
  "Common-level": "Nivel",
  "Common-loadingText": "Cargando...",
  "Common-meets": "Satisface",
  "Common-minuteAbbreviation": "m",
  "Common-name": "Nombre",
  "Common-next": "Siguiente",
  "Common-no": "No",
  "Common-none": "Ninguno",
  "Common-overused": "En exceso",
  "Common-remove": "Eliminar",
  "Common-removeConfirmationValue": "eliminar",
  "Common-removeFromFavorites": "Eliminar De Favoritos",
  "Common-restart": "Reiniciar",
  "Common-role": "Rol",
  "Common-save": "Guardar",
  "Common-search": "Buscar",
  "Common-secondAbbreviation": "s",
  "Common-select": "Seleccione",
  "Common-signal": "Señal",
  "Common-source": "Origen",
  "Common-status": "Status",
  "Common-title": "Título",
  "Common-to": "a",
  "Common-true": "Verdadero",
  "Common-tryAgain": "Volver a intentar",
  "Common-typeHere": "Escriba aquí",
  "Common-unassignedLabel": "--",
  "Common-unenrolledTooltip": "No Listado",
  "Common-unset": "Deactivada",
  "Common-warning": "Advertencia",
  "Common-yes": "Si",
  "Configuration-configurationPath": "Configuración",
  "Configuration-organizationsPath": "Organizaciones",
  "ConnectedColumnRender-connected": "Conectado",
  "ConnectedColumnRender-neverSeen": "Nunca visto",
  "CreateDeviceModal-createDeviceError": "Ocurrió un error al intentar crear el dispositivo",
  "CreateDeviceModal-createDeviceSuccess": "Dispositivo creado correctamente",
  "CreateDeviceModal-deviceTagLabel": "Etiqueta del Dispositivo",
  "CreateDeviceModal-deviceTagValidatorMessage": "La Etiqueta del Dispositivo solo debe tener letras en mayúsculas o números",
  "CreateDeviceModal-deviceTypeLabel": "Tipo del Dispositivo",
  "CreateDeviceModal-duplicateDeviceError":
    "Un dispositivo no archivado con el mismo Número de Serie del Sistema o MCU ID ya existe en el sistema",
  "CreateDeviceModal-fetchDeviceTypesError": "Ocurrió un error al intentar obtener los tipos de dispositivos disponibles",
  "CreateDeviceModal-mcuIDLabel": "MCU ID",
  "CreateDeviceModal-mcuIDValidatorMessage": "MCU ID debe ser un UUID válido",
  "CreateDeviceModal-modalTitle": "Crear Dispositivo",
  "CreateDeviceModal-okButtonText": "Crear",
  "CreateDeviceModal-systemSerialNumberLabel": "# de Serie del Sistema",
  "CreateDeviceModal-systemSerialNumberValidatorMessage":
    "El # de Serie del Sistema debe tener 13 caracteres e incluir solo números y letras en mayúsculas",
  "CreateWorkersReportGroupModal-createError": "Error al intentar crear el grupo",
  "CreateWorkersReportGroupModal-successMessage": "Grupo de Reporte de Trabajadores creado exitosamente",
  "CreateWorkerReportGroupModal-groupNameLabel": "Nombre del Grupo:",
  "CreateWorkerReportGroupModal-nameAlreadyExistsValidation": "Un grupo con el mismo nombre ya existe en el sitio {siteName}",
  "CreateWorkerReportGroupModal-nameLengthValidator":
    "Los nombres de los grupos están limitados a 40 caracteres. Por favor, acorte el nombre.",
  "CreateWorkerReportGroupModal-noWorkersText": "No se encontraron trabajadores",
  "CreateWorkerReportGroupModal-selectWorkersLabel": "Seleccione los trabajadores que desea agregar al grupo:",
  "CreateWorkerReportGroupModal-title": "Crear Grupo",
  "CreationFailed-duplicatedValuesError": "El archivo contiene valores duplicados",
  "CreationFailed-employeeIDAlreadyExistsError":
    "**{firstName} {lastName} ({employeeID})**: El ID de empleado ya existe dentro de **{siteName}**",
  "CreationFailed-emptyRowsError": "Filas con valores vacíos fueron detectadas",
  "CreationFailed-firstText": "No se crearon trabajadores en **{siteName}** - **{departmentName}**",
  "CreationFailed-missingColumnError":
    "Una o más de las columnas (Nombre, Apellido y/o ID de Empleado) no fueron encontradas en el archivo subido",
  "CreationFailed-nameAlreadyExistsError":
    "**{firstName} {lastName} ({employeeID})**: El nombre del trabajador ya existe dentro de **{siteName}**",
  "CreationFailed-nameAlreadyExistsErrorWithoutID":
    "**{firstName} {lastName}**: El nombre del trabajador ya existe dentro de **{siteName}**",
  "CreationFailed-noRowsError": "No se encontraron trabajadores en el archivo cargado",
  "CreationFailed-notEnoughInfoError": "{firstName} {lastName} ({employeeID}): Perfil Incompleto",
  "CreationFailed-secondText": "Los siguientes errores ocurrieron:",
  "CreationFailed-subtitle": "Creación Fallida",
  "CreationFailedOld-firstText": "No se crearon trabajadores en **{siteName}** - **{departmentName}**",
  "CreationSuccessful-creationManyText": "{numberOfWorkers} Trabajadores creados para **{siteName}** - **{departmentName}**",
  "CreationSuccessful-creationOneText": "1 Trabajador creado para **{siteName}** - **{departmentName}**",
  "CreationSuccessful-title": "Creación exitosa",
  "CSVDeviceAllocation-columns": "Columnas: System Serial Number",
  "CSVDeviceAllocation-description":
    "Por favor cargue un archivo CSV con los números de serie del sistema que desea asignar a **{siteName}** - **{departmentName}**.",
  "CSVDeviceAllocation-firstSubtitle":
    "Por favor cargue un archivo CSV con los números de serie del sistema que desea asignar a **{siteName}** - **{departmentName}**",
  "CSVDeviceAllocation-secondSubtitle": "Columnas: Número de Serie del Sistema",
  "CSVDeviceAllocation-serialNumberColumnName": "Número de Serie del Sistema",
  "CSVWorkerCreation-employeeID": "ID de Empleado",
  "CSVWorkerCreation-firstName": "Nombre",
  "CSVWorkerCreation-firstSubtitle":
    "Por favor cargue un archivo CSV con los trabajadores que desea crear en **{siteName}** - **{departmentName}**",
  "CSVWorkerCreation-lastName": "Apellido",
  "CSVWorkerCreation-secondSubtitle": "Columnas: Nombre, Apellido, ID de Empleado",
  "Dashboard-activeLabel": "Activo",
  "Dashboard-dashboardPath": "Tablero",
  "Dashboard-deviceStatusSubtitle": "Últimos 7 Días",
  "Dashboard-deviceStatusTitle": "Estado de Dispositivos",
  "Dashboard-fetchDataError": "Ocurrió un error al obtener los datos del dashboard",
  "Dashboard-inactiveLabel": "Inactivo",
  "Dashboard-outOfServiceLabel": "Fuera de Servicio",
  "Dashboard-sitesListEndMessage": "No hay más sitios para mostrar",
  "Dashboard-totalUsageTitle": "Horas Totales de Uso",
  "Dashboard-unassignedLabel": "No Asignado",
  "DashboardCardUsageChart-defaultTitle": "Horas Totales de Uso",
  "DashboardCardUsageChart-workerReportGroupsTitle": "Horas Promedio por Empleado con un Dispositivo",
  "DashboardCardWrapper-errorMessage": "Ocurrió un error al obtener la información",
  "DashboardCardWrapper-errorResultMessage": "Error al obtener los datos. Por favor refresque la página",
  "DashboardChildrenCardsSection-workerReportGroups":
    "{numberOfGroups, plural, =1 {Grupo de Reportes de Trabajadores} other {Grupos de Reportes de Trabajadores}}",
  "DashboardDevicesListsDrawer-devicesCount": "{devices} Dispositivos",
  "DashboardDevicesListsDrawer-devicesCountTitleAssigned": "Asignados:",
  "DashboardDevicesListsDrawer-devicesCountTitleAvailable": "Disponibles:",
  "DashboardDevicesListsDrawer-devicesCountTitleOutOfService": "Fuera de Servicio:",
  "DashboardDevicesListsDrawer-fetchDevicesError": "Error al intentar obtener la información de los Dispositivos",
  "DashboardDevicesListsDrawer-deviceTagTitle": "Etiqueta del Dispositivo",
  "DashboardDevicesListsDrawer-titleAssigned": "Dispositivos Asignados",
  "DashboardDevicesListsDrawer-titleAvailable": "Dispositivos Disponibles",
  "DashboardDevicesListsDrawer-titleOutOfService": "Dispositivos Fuera de Servicio",
  "DashboardDevicesListsDrawer-workerNameTitle": "Nombre del Trabajador",
  "DashboardItem-chartsClickText": "Clickear las gráficas navegará a las Analíticas del Sitio",
  "DashboardItem-errorResultMessage": "Error al obtener los datos del sitio",
  "DashboardSiteDetails-fetchDataError": "Error al intentar obtener la información del sitio",
  "DashboardTabContent-activeWorkers": "Trabajadores Activos",
  "DashboardTabContent-demoReport": "Reporte Demo",
  "DashboardTabContent-departments": "{numberOfDepartments, plural, =0 {Departamentos} =1 {Departamento} other {Departamentos}}",
  "DashboardTabContent-fetchWorkerReportGroupsDataError": "Error al intentar obtener la información de los Grupos de Trabajadores",
  "DashboardTabContent-inactiveWorkers": "Trabajadores Inactivos",
  "DashboardTabContent-sites": "{numberOfSites, plural, =0 {Sitios} =1 {Sitio} other {Sitios}}",
  "DashboardTabContent-siteSummaryReport": "Reporte Resumido de Sitio",
  "DashboardTabContent-summaryReport": "Reporte Resumido",
  "DashboardDailyUsageBreakdownSection-dailyTotalRowTitle": "Total Diario",
  "DashboardDailyUsageBreakdownSection-downloadCSV": "Descargar CSV",
  "DashboardDailyUsageBreakdownSection-endDateLabel": "Fecha Fin de la Semana",
  "DashboardDailyUsageBreakdownSection-title": "Desglose de Uso Diario",
  "DashboardDailyUsageBreakdownSection-weeklyTotal": "Total Semanal",
  "DashboardDailyUsageBreakdownSection-worker": "Trabajador",
  "DashboardWorkersListsDrawer-fetchWorkersError": "Error al intentar obtener la información de los Trabajadores",
  "DashboardWorkersListsDrawer-titleActive": "Trabajadores Activos",
  "DashboardWorkersListsDrawer-titleInactive": "Trabajadores Inactivos",
  "DashboardWorkersListsDrawer-titleWaitlist": "Lista de Espera de Trabajadores",
  "DashboardWorkersListsDrawer-workerNameTitle": "Nombre del Trabajador",
  "DashboardWorkersListsDrawer-workersCount": "{workers} Trabajadores",
  "DashboardWorkersListsDrawer-workersCountTitleActive": "Activos:",
  "DashboardWorkersListsDrawer-workersCountTitleInactive": "Inactivos:",
  "DashboardWorkersListsDrawer-workersCountTitleWaitlist": "Lista de Espera:",
  "DashboardWorkersSection-asPDF": "como PDF",
  "DashboardWorkersSection-asXLSX": "como XLSX",
  "DashboardWorkersTable-7DaysColumnTooltip": "Información desde {dateFrom} al {dateTo} (no incluye información de hoy not)",
  "DashboardWorkersTable-7HoursColumnTargetTooltip":
    "La información del objetivo de uso está basada en la configuración del departamento y asume una semana de 5 días.",
  "DashboardWorkersTable-7HoursColumnTooltip":
    "Información de uso de Horas desde {dateFrom} al {dateTo} (no incluye información de hoy not)",
  "DashboardWorkersTable-activeWorkersTitle": "Lista de Trabajadores Activos",
  "DashboardWorkersTable-assignedDeviceColumnTitle": "Etiqueta del Dispositivo",
  "DashboardWorkersTable-dataUploadedTodayColumnTitle": "Data Subida Hoy",
  "DashboardWorkersTable-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "DashboardWorkersTable-daysActiveLast7DaysColumnTitle": "Dias Activo Ultimos 7 Días",
  "DashboardWorkersTable-daysSinceLastUsage": "{days} días",
  "DashboardWorkersTable-disabledDeviceColumnValue": "El dispositivo no puede ser cambiado para trabajadores no listados",
  "DashboardWorkersTable-downloadData": "Descargar datos",
  "DashboardWorkersTable-employeeIDTitle": "ID de empleado",
  "DashboardWorkersTable-errorFetchingWorkers": "Error obteniendo trabajadores",
  "DashboardWorkersTable-hoursLast7DaysColumnTitle": "Horas Ultimos 7 Días",
  "DashboardWorkersTable-inactiveWorkersTitle": "Lista de Trabajadores Inactivos",
  "DashboardWorkersTable-nameColumnTitle": "Nombre",
  "DashboardWorkersTable-relativeToTargetColumnTitle": "Relativo al Objetivo",
  "DashboardWorkersTable-statusColumnTitle": "Estado",
  "DashboardWorkersTable-targetDeviceUtilization": "Objetivo de Utilización del Dispositivo",
  "DashboardWorkersTable-unassignedDeviceLabel": "--",
  "DashboardWorkersTable-weeksSinceFirstUseColumnTitle": "Semanas Desde el Primer Uso",
  "DashboardWorkersTable-workerActiveToday": "Trabajador Activo Hoy",
  "DashboardWorkersTable-workerColumnName": "Trabajador",
  "DashboardWorkersTable-WorkersActivityReport": "ReporteDeActividadDeEmpleados",
  "DashboardWorkersMetric-bottomFive": "Últimos 5",
  "DashboardWorkersMetric-departmentAverage": "Promedio del departamento",
  "DashboardWorkersMetric-leaderboardReport": "Reporte de tabla de clasificación",
  "DashboardWorkersMetric-poundsUnit": "lbs",
  "DashboardWorkersMetric-kilogramsUnit": "kgs",
  "DashboardWorkersMetric-safeLiftScore": "Puntuación de Levantamientos Seguros",
  "DashboardWorkersMetric-topWorkers": "Top {qty}",
  "DashboardWorkersMetric-bottomWorkers": "Últimos {qty}",
  "DashboardWorkersMetric-weightOffloaded": "Peso Descargado",
  "DashboardWorkersMetric-workerMetrics": "Métricas de Trabajadores",
  "DatePicker-dateFilterCurrentMoth": "Mes en curso",
  "DatePicker-dateFilterLastDays": "Ult. {daysDisplayed} días",
  "DatePicker-dateFilterToday": "Hoy",
  "DatePicker-dateFilterYesterday": "Ayer",
  "DateRange-dateFilterInputRequiredRule": "Debe seleccionar un rango de fechas",
  "DemoReportDrawer-contentMessage": "El reporte demo cubre un período de 7 días. Por favor seleccione la fecha final del reporte.",
  "DemoReportDrawer-drawerTitle": "Reporte Demo",
  "DemoReportDrawer-fileName": "Reporte Demo",
  "DepartmentDevicesTable-daysSinceLastUsedColumnTitle": "Última vez visto",
  "DepartmentDevicesTable-deviceTagColumnTitle": "Etiqueta del Dispositivo",
  "DepartmentDevicesTable-disabledWorkerColumnValue": "El trabajador no puede ser cambiado para un dispositivo Fuera de Servicio",
  "DepartmentDevicesTable-inServiceTooltip": "En Servicio",
  "DepartmentDevicesTable-outOfServiceTooltip": "Fuera de Servicio",
  "DepartmentDevicesTable-statusColumnTitle": "Estado",
  "DepartmentDevicesTable-workerColumnTitle": "Trabajador",
  "DepartmentDetail-allocateCSVButtonText": "Cargar CSV",
  "DepartmentDetail-allocateManualButtonText": "Asignar",
  "DepartmentDetail-deleteAlertMessage": "¿Está seguro que desea eliminar el departamento?",
  "DepartmentDetail-deleteAlertTitle": "Eliminar Departamento",
  "DepartmentDetail-deleteError": "No se pudo eliminar el departamento",
  "DepartmentDetail-devices": "Dispositivos",
  "DepartmentDetail-differentSiteError": "Este departamento pertenece a otro sitio",
  "DepartmentDetail-fetchDataError": "Error al intentar obtener la información del departamento",
  "DepartmentDetail-notFoundError": "No se encontró el departamento",
  "DepartmentDetail-updateError": "Error al intentar actualizar la información del departamento",
  "DepartmentDetail-workerCreationCSVButtonText": "Cargar CSV",
  "DepartmentDetail-workerCreationManualButtonText": "Agregar",
  "DepartmentDetail-workers": "Trabajadores",
  "DepartmentForm-createError": "Error al intentar crear el departamento",
  "DepartmentForm-nameInputLabel": "Nombre",
  "DepartmentGatewaysDetail-deallocate": "Desasignar",
  "DepartmentGatewaysDetail-deallocateAlertMessage":
    "¿Está seguro que desea desasignar el gateway **{gatewaySerialNumber}** del departamento **{departmentName}**?",
  "DepartmentGatewaysDetail-deallocateAlertTitle": "Desasignar gateway",
  "DepartmentGatewaysDetail-deallocateError": "No se pudo desasignar el gateway",
  "DepartmentGatewaysDetail-fetchGatewaysError": "Error al intentar obtener la información de los gateways",
  "DepartmentGatewaysDetail-gatewayItemFirmware": "Firmware del Gateway:",
  "DepartmentGatewaysDetail-gatewayItemLastTimeConnected": "Última vez conectado:",
  "DepartmentGatewaysDetail-gatewayItemStatus": "Estado:",
  "DepartmentGatewaysDetail-gatewayStatusDefault": "Desconocido",
  "DepartmentGatewaysDetail-title": "Gateway",
  "DepartmentModal-createError": "Error al intentar crear el departamento",
  "DepartmentModal-createTitle": "Crear Departamento",
  "DepartmentModal-deleteButtonText": "Eliminar Departamento",
  "DepartmentModal-deleteError": "No se pudo eliminar el departamento",
  "DepartmentModal-editTitle": "Editar Departmento",
  "DepartmentModal-updateError": "Error al intentar actualizar el departamento",
  "DepartmentsTable-devicesColumnTitle": "# Dispositivos",
  "DepartmentsTable-nameColumnTitle": "Nombre del departamento",
  "DepartmentWorkersTable-assignedDeviceColumnTitle": "Dispositivo Asignado",
  "DepartmentWorkersTable-daysSinceFirstUsageColumnTitle": "Días desde el primer uso",
  "DepartmentWorkersTable-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "DepartmentWorkersTable-deviceColumnTitle": "Dispositivo",
  "DepartmentWorkersTable-disabledDeviceColumnValue": "El dispositivo no puede ser cambiado para trabajadores no listados",
  "DepartmentWorkersTable-employeeIDColumnTitle": "ID del Empleado",
  "DepartmentWorkersTable-enrolledColumnTitle": "Enlistado",
  "DepartmentWorkersTable-enrolledFalseLabel": "No",
  "DepartmentWorkersTable-enrolledTrueLabel": "Si",
  "DepartmentWorkersTable-naLabel": "N/A",
  "DepartmentWorkersTable-nameColumnTitle": "Nombre",
  "DepartmentWorkersTable-report": "Reporte",
  "DepartmentWorkersTable-reportColumnTitle": "Reporte",
  "DepartmentWorkersTable-statusColumnTitle": "Estado",
  "DepartmentWorkersTable-totalHoursColumnTitle": "Horas Totales",
  "DepartmentWorkersTable-totalLiftsColumnTitle": "Levantamientos Totales",
  "DepartmentWorkersTable-unassignedDeviceLabel": "--",
  "DepartmentWorkersTable-idColumnTitle": "Id",
  "Device-formattedConfigFirmwareVersionUnknown": "desconocido",
  "DeviceAllocation-allocateButtonText": "Asignar",
  "DeviceAllocation-allocateDeviceToDepartmentError": "Los dispositivos no pudieron ser asignados",
  "DeviceAllocation-allocationManySuccessfulText": "{numberOfDevices} Dispositivos asignados a **{siteName} - {departmentName}**",
  "DeviceAllocation-allocationOneSuccessfulText": "1 Dispositivo asignado a **{siteName} - {departmentName}**",
  "DeviceAllocation-allocationSuccessfulTitle": "Asignación exitosa",
  "DeviceAllocation-contentText": "Por favor elija los números de serie que desea asignar a **{siteName}** - **{departmentName}**",
  "DeviceAllocation-noDevicesText": "No se encontraron dispositivos",
  "DeviceAllocation-title": "Asignación de Dispositivo",
  "DeviceAllocationModal-allocateDeviceToDepartmentError": "Los dispositivos no pudieron ser asignados",
  "DeviceAllocationModal-CVSDeviceAllocationButtonText": "Asignar",
  "DeviceAllocationModal-CSVDeviceAllocationSuccessfulMessage": "Archivo CSV cargado exitosamente",
  "DeviceAllocationModal-errorMessage": "Error al intentar obtener los dispositivos disponibles.",
  "DeviceAllocationModal-manualDeviceAllocationOkButtonText": "Asignar",
  "DeviceAllocationModal-manualDeviceAllocationSuccessfulMessage": "Los dispositivos fueron asignados exitosamente a {department}",
  "DeviceAllocationModal-title": "Asignar Dispositivo",
  "DeviceDetail-assignedWorker": "Trabajador:",
  "DeviceDetail-available": "Disponible",
  "DeviceDetail-dateFirstUsed": "Primer Uso:",
  "DeviceDetail-dateLastConnected": "Última Conexión:",
  "DeviceDetail-dateLastUsed": "Último Uso:",
  "DeviceDetail-deallocate": "Desasignar",
  "DeviceDetail-deallocateAlertMessage":
    "Desea remover **{deviceTag}** (# Serial **{serialNumber}**) de **{siteName} - {departmentName}**?",
  "DeviceDetail-deallocateAlertTitle": "Desasignación de Dispositivo",
  "DeviceDetail-deallocateDevice": "Desasignar Dispositivo",
  "DeviceDetail-deallocateDeviceMessage":
    "Está seguro de querer remover **{deviceTag}** (# de Serie del Sistema {systemSerialNumber}) de {departmentName}?",
  "DeviceDetail-deallocateError": "Error al intentar desasignar el dispositivo",
  "DeviceDetail-deallocateSuccessMessage": "El Dispositivo {deviceTag} fue desasignado de {departmentName} exitosamente.",
  "DeviceDetail-department": "Departamento:",
  "DeviceDetail-deviceProfile": "Perfil del Dispositivo",
  "DeviceDetail-deviceStatistics": "Estadistícas del Dispositivo",
  "DeviceDetail-drawerTitle": "Detalles del Dispositivo",
  "DeviceDetail-firmware": "Firmware:",
  "DeviceDetail-inService": "En Servicio",
  "DeviceDetail-none": "Ninguno",
  "DeviceDetail-organization": "Organización:",
  "DeviceDetail-outOfService": "Fuera de Servicio",
  "DeviceDetail-rev": "Revisión:",
  "DeviceDetail-site": "Sitio:",
  "DeviceDetail-status": "Estado:",
  "DeviceDetail-systemSerialNumber": "# de Serie del Sistema:",
  "DeviceDetail-tag": "Etiqueta del Dispositivo:",
  "DeviceDetail-title": "Detalles del Dispositivo",
  "DeviceDetail-totalHours": "Horas Totales:",
  "DeviceDetail-totalLifts": "Levantamientos Totales:",
  "DeviceDetail-updateError": "Error al intentar actualizar la información del dispositivo",
  "DeviceDetail-workerNoLongerHasDevice": "El trabajador ya no tiene un dispositivo",
  "DeviceDetailOld-assignedWorker": "Trabajador Asignado:",
  "DeviceDetailOld-dateLastConnected": "Última Conexión:",
  "DeviceDetailOld-deviceStatistics": "Estadistícas del Dispositivo",
  "DeviceEditModal-archived": "Archivado:",
  "DeviceEditModal-archiveDeviceSuccess": "El dispositivo fue Archivado exitosamente",
  "DeviceEditModal-archivedTrueMessage":
    "Cambiar el dispositivo a **Archivado: Verdadero** hara que el dispositivo solo se pueda ver desde la sección de Gestión de Dispositivos.",
  "DeviceEditModal-inService": "En Servicio",
  "DeviceEditModal-none": "Ninguno",
  "DeviceEditModal-outOfService": "Fuera de Servicio",
  "DeviceEditModal-status": "Estado:",
  "DeviceEditModal-systemSerialNumber": "# de Serie del Sistema:",
  "DeviceEditModal-title": "Editar Perfil del Dispositivo",
  "DeviceEditModal-updateDeviceSuccess": "Los detalles del dispositivo fueron actualizados exitosamente",
  "DeviceEditModal-updateError": "Error al intentar actualizar la información del dispositivo",
  "DeviceEditModal-worker": "Trabajador:",
  "DeviceManagementDevices-bulkUpdateDevices": "Actualización Masiva de Dispositivos",
  "DeviceManagementDevices-createDeviceButtonText": "Crear Dispositivo",
  "DeviceManagementDevices-deviceConfig": "Dispositivos",
  "DeviceManagementDevices-gateways": "Gateways",
  "DeviceManagementDevices-searchDevices": "Realiza una búsqueda para ver Dispositivos",
  "DeviceManagementDevices-searchGateways": "Realiza una búsqueda para ver Gateways",
  "DeviceManagementDevices-subheaderTitle": "Gestión de Dispositivos",
  "DeviceManagementDevices-title": "Gestión de Dispositivos",
  "DeviceManagementDevicesTable-activeConfig": "Configuración Activa",
  "DeviceManagementDevicesTable-archivedColumnTitle": "Archivado",
  "DeviceManagementDevicesTable-button1ColumnTitle": "Botón 1",
  "DeviceManagementDevicesTable-button2ColumnTitle": "Botón 2",
  "DeviceManagementDevicesTable-button3ColumnTitle": "Botón 3",
  "DeviceManagementDevicesTable-firmwareRevColumnTitle": "Firmware Rev",
  "DeviceManagementDevicesTable-hardwareRevColumnTitle": "Hardware Rev",
  "DeviceManagementDevicesTable-pendingUpdate": "Actualización Pendiente",
  "DeviceManagementDevicesTable-updateConfig": "Actualizar Configuración",
  "DeviceManagementDevicesTable-updateConfigColumnTitle": "Actualizar Configuración",
  "DeviceManagementSearchBar-deviceTag": "Etiqueta del Dispositivo:",
  "DeviceManagementSearchBar-deviceTagPlaceholder": "Buscar por Etiqueta del Dispositivo",
  "DeviceManagementSearchBar-filter": "Filtro:",
  "DeviceManagementSearchBar-loadingOrganizations": "Cargando organizaciones...",
  "DeviceManagementSearchBar-searchButtonTooltip": "Por favor utilice al menos uno de los filtros antes de realizar una búsqueda",
  "DeviceManagementSearchBar-selectOrganization": "Elige una organización...",
  "DeviceManagementSearchBar-serialNumber": "Número de serie:",
  "DeviceManagementSearchBar-serialNumberPlaceholder": "Buscar por Número de serie",
  "DeviceManagementSearchBar-unallocated": "Sin asignar",
  "DeviceManagementStats-connected": "Conectados: {connectedDevices}",
  "DeviceManagementStats-deviceConnectionStatusLabel": "Estado de Conexión del Dispositivo:",
  "DeviceManagementStats-disconnected": "Desconectados: {disconnectedDevices}",
  "DeviceManagementStats-down": "Fuera de Línea: {downGateways}",
  "DeviceManagementStats-includeArchivedLabel": "Incluir Archivados",
  "DeviceManagementStats-intermittent": "Intermitentes: {intermittentGateways}",
  "DeviceManagementStats-neverSeen": "Nunca Vistos: {neverSeenDevices}",
  "DeviceManagementStats-pendingConfigurationUpdatesLabel": "Actualizaciones de configuración pendientes:",
  "DeviceManagementStats-totalDevicesLabel": "Dispositivos Totales:",
  "DeviceManagementStats-totalGatewaysLabel": "Gateways Totales:",
  "DeviceManagementStats-up": "En Línea: {upGateways}",
  "DeviceNoWorker-assignErrorMessage": "No se pudo asignar el dispositivo al trabajador",
  "DeviceNoWorker-assignNewWorker": "Asignar dispositivo a un nuevo trabajador",
  "DeviceNoWorker-assignNewWorker-placeholder": "Seleccione trabajador",
  "DeviceNoWorker-assignSuccessMessage": "El dipsositivo {deviceTag} fue asignado exitosamente a {workerName}",
  "DeviceNoWorker-changeStatusErrorMessage": "Error al intentar actualizar el estado del dispositivo.",
  "DeviceNoWorker-changeStatusOutOfService": "Cambiar estado a 'Fuera de servicio'",
  "DeviceNoWorker-changeStatusSuccessMessage": "El estado del dispositvo {deviceTag} fue cambiado exitosamente a Fuera de Servicio",
  "DeviceNoWorker-firstSubtitle": "**{name}** ya no tiene un trabajador asignado.",
  "DeviceNoWorker-leaveUnassigned": "Dejar sin asignar",
  "DeviceNoWorker-leaveUnassignedSuccessMessage": "El estado del dipositivo {deviceTag} fue cambiado exitosamente a Sin Asignar",
  "DeviceNoWorker-secondSubtitle": "¿Qué desea hacer con este trabajador?",
  "DeviceNoWorker-title": "Estado del Dispositivo",
  "DeviceNoWorker-worker": "Trabajador:",
  "DevicesTabContent-allTagText": "Todos",
  "DevicesTabContent-allocateDeviceButton": "Asignar Dispositivo",
  "DevicesTabContent-assignedTagText": "Asignados",
  "DevicesTabContent-availableTagText": "Disponibles",
  "DevicesTabContent-connectedTagText": "Conectados",
  "DevicesTabContent-deviceUsageReassignmentButton": "Reasignación de Uso de Dispositivos",
  "DevicesTabContent-disconnectedTagText": "Desconectados",
  "DevicesTabContent-outOfServiceTagText": "Fuera de Servicio",
  "DevicesTabContent-searchButtonText": "Buscar Etiqueta del Dispositivo",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyTagText": "Retirado Recientemente",
  "DeviceLiveCheckoutFilters-notCheckedOutTagText": "No Retirado",
  "DeviceLiveCheckoutFilters-idleTagText": "Inactivo",
  "DeviceLiveCheckoutFilters-allTagText": "Todos",
  "DeviceLiveCheckoutFilters-checkedOutRecentlyDescription":
    "Desconectado en las últimas 12 horas y/o registró datos en las últimas 12 horas",
  "DeviceLiveCheckoutFilters-notCheckedOutDescription": "Actualmente conectado y sin datos registrados en las últimas 12 horas",
  "DeviceLiveCheckoutFilters-idleDescription": "Desconectado por más de 12 horas",
  "DeviceLiveCheckoutFilters-bottomDisclaimer": "*Solo mostrando dispositivos En Servicio",
  "DeviceLiveCheckoutFilters-searchButtonText": "Buscar por Trabajador o Etiqueta del Dispositivo",
  "DeviceLiveCheckoutFilters-seeOnlyAssigned": "Ver solo Dispositivos Asignados",
  "DeviceUsageReassignment-assignedDataSwitch": "Ver Tambien Datos Asignados",
  "DeviceUsageReassignment-deviceColumn": "Dispositivos",
  "DeviceUsageReassignment-endDate": "Fecha de fin de la Semana",
  "DeviceUsageReassignment-errorFetchingWorkers": "Error obteniendo trabajadores",
  "DeviceUsageReassignment-errorMessage": "Error al intentar obtener la información de los trabajadores.",
  "AllocateSelectedData-worker": "Trabajador:",
  "DeviceUsageReassignment-title": "Reasignación de Uso de Dispositivos",
  "DeviceUsageReassignment-worker": "Trabajador:",
  "LiveCheckedOutTabContent-allTagText": "Todos",
  "LiveCheckedOutTabContent-allocateDeviceButton": "Asignar Dispositivo",
  "LiveCheckedOutTabContent-assignedTagText": "Asignados",
  "LiveCheckedOutTabContent-availableTagText": "Disponibles",
  "LiveCheckedOutTabContent-connectedTagText": "Conectados",
  "LiveCheckedOutTabContent-disconnectedTagText": "Desconectados",
  "LiveCheckedOutTabContent-outOfServiceTagText": "Fuera de Servicio",
  "LiveCheckedOutTabContent-searchButtonText": "Buscar Etiqueta del Dispositivo",
  "DevicesTable-assignedWorkerColumnTitle": "Trabajador Asignado",
  "DevicesTable-assignedWorkerFilter-assigned": "Asignado",
  "DevicesTable-assignedWorkerFilter-available": "Disponible",
  "DevicesTable-assignedWorkerFilter-outOfService": "Fuera de servicio",
  "DevicesTable-connectedColumnTitle": "Última vez visto",
  "DevicesTable-connectedLabel": "Conectado",
  "DevicesTable-daysSinceFirstUsedColumnTitle": "Primera vez usado",
  "DevicesTable-daysSinceLastUsedColumnTitle": "Última vez usado",
  "DevicesTable-departmentColumnTitle": "Departamento",
  "DevicesTable-deviceTagColumnTitle": "Etiqueta del dispositivo",
  "DevicesTable-disconnectedLabel": "Desconectado",
  "DevicesTable-firmwareColumnTitle": "Firmware",
  "DevicesTable-neverSeenLabel": "Nunca Visto",
  "DevicesTable-organizationColumnTitle": "Organización",
  "DevicesTable-revColumnTitle": "Revisión",
  "DevicesTable-siteColumnTitle": "Sitio",
  "DevicesTable-systemSerialNumberColumnTitle": "# de serie del sistema",
  "DevicesTable-totalHoursColumnTitle": "Horas totales",
  "DevicesTable-totalLiftsColumnTitle": "Levantamientos totales",
  "DeviceStatusChart-activeDevices": "Activos",
  "DeviceStatusChart-allocatedDevices": "Asignados",
  "DeviceStatusChart-inactiveDevices": "Inactivos",
  "DeviceStatusChart-noDevices": "No hay dispositivos para mostrar",
  "DeviceStatusChart-outOfServiceDevices": "Fuera de Servicio",
  "DeviceStatusChart-unassignedDevices": "No Asignados",
  "DeviceStatusChartOld-activeDevices": "Dispositivos Activos",
  "DeviceStatusChartOld-allocatedDevices": "Dispositivos Asignados",
  "DeviceStatusChartOld-inactiveDevices": "Dispositivos Inactivos",
  "DeviceStatusChartOld-noDevices": "No hay dispositivos para mostrar",
  "DeviceStatusChartOld-outOfServiceDevices": "Dispositivos Fuera de Servicio",
  "DeviceStatusChartOld-unassignedDevices": "Dispositivos no Asignados",
  "DeviceManagementDeviceDetail-archivedAt": "Archivado el:",
  "DeviceManagementDeviceDetail-assignedWorker": "Trabajador Asignado:",
  "DeviceManagementDeviceDetail-button1": "Botón 1:",
  "DeviceManagementDeviceDetail-button2": "Botón 2:",
  "DeviceManagementDeviceDetail-button3": "Botón 3:",
  "DeviceManagementDeviceDetail-configurationTitle": "Configuración",
  "DeviceManagementDeviceDetail-diagnosticsPath": "Gestión de Dispositivos",
  "DeviceManagementDeviceDetail-ConnectedStatus-connected": "Conectado",
  "DeviceManagementDeviceDetail-ConnectedStatus-disconnected": "Desconectado",
  "DeviceManagementDeviceDetail-ConnectedStatus-neverSeen": "Nunca Visto",
  "DeviceManagementDeviceDetail-connectionStatus": "Estado de la Conexión:",
  "DeviceManagementDeviceDetail-customerInfoTitle": "Información del Cliente",
  "DeviceManagementDeviceDetail-dateFirstUsed": "Primer Uso:",
  "DeviceManagementDeviceDetail-dateLastConnected": "Última Conexión:",
  "DeviceManagementDeviceDetail-dateLastUsed": "Último Uso:",
  "DeviceManagementDeviceDetail-department": "Departamento:",
  "DeviceManagementDeviceDetail-deviceDetailsTitle": "Detalles",
  "DeviceManagementDeviceDetail-deviceStatisticsTitle": "Estadísticas",
  "DeviceManagementDeviceDetail-deviceTag": "Etiqueta del Dispositivo:",
  "DeviceManagementDeviceDetail-firmwareRev": "Firmware Rev:",
  "DeviceManagementDeviceDetail-inService": "En Servicio",
  "DeviceManagementDeviceDetail-hardwareRev": "Hardware Rev:",
  "DeviceManagementDeviceDetail-mcuId": "MCU ID:",
  "DeviceManagementDeviceDetail-organization": "Organización:",
  "DeviceManagementDeviceDetail-outOfService": "Fuera de Servicio",
  "DeviceManagementDeviceDetail-pendingUpdateTooltip": "Actualización pendiente: Mostrando detalles de la configuración pendiente.",
  "DeviceManagementDeviceDetail-revision": "Revisión:",
  "DeviceManagementDeviceDetail-sessions": "Sesiones",
  "DeviceManagementDeviceDetail-site": "Sitio:",
  "DeviceManagementDeviceDetail-status": "Estado",
  "DeviceManagementDeviceDetail-systemSerialNumber": "# de Serie del Sistema:",
  "DeviceManagementDeviceDetail-title": "Dispositivo {systemSerialNumber}",
  "DeviceManagementDeviceDetail-totalHours": "Horas Totales:",
  "DeviceManagementDeviceDetail-totalLifts": "Levantamientos Totales:",
  "DeviceManagementDeviceDetail-updatePending": "Actualización Pendiente:",
  "DeviceManagementDeviceDetail-workerErrorMessage": "Error al intentar obtener la información del trabajador",
  "DownloadAllData-columnNameActiveWorkers": "Trabajadores Activos",
  "DownloadAllData-columnNameActiveWorkersPercentage": "Porcentaje Trabajadores Activos",
  "DownloadAllData-columnNameAssignedWorkers": "Trabajadores Asignados",
  "DownloadAllData-columnNameEndDate": "Fecha de Fin",
  "DownloadAllData-columnNameEndTime": "Hora de Fin",
  "DownloadAllData-columnNameEnrolledWorkers": "Trabajadores Enlistados",
  "DownloadAllData-columnNameExcessiveForwardLifts": "Levantamientos Delanteros Excesivos",
  "DownloadAllData-columnNameInactiveWorkers": "Trabajadores Inactivos",
  "DownloadAllData-columnNameInactiveWorkersPercentage": "Porcentaje Trabajadores Inactivos",
  "DownloadAllData-columnNameProlongedBendLifts": "Levantamientos con Flexión Prolongada",
  "DownloadAllData-columnNameResourceId": "ID Recurso",
  "DownloadAllData-columnNameResourceName": "Nombre Recurso",
  "DownloadAllData-columnNameResourceType": "Tipo Recurso",
  "DownloadAllData-columnNameRiskyLifts": "Levantamientos Riesgosos",
  "DownloadAllData-columnNameRiskyLiftsPercentage": "Levantamientos Riesgosos Porcentaje",
  "DownloadAllData-columnNameSafeLifts": "Levantamientos Seguros Porcentaje",
  "DownloadAllData-columnNameSafeLiftsPercentage": "Levantamientos Seguros",
  "DownloadAllData-columnNameSideBendLifts": "Levantamientos con Flexión Lateral",
  "DownloadAllData-columnNameStartDate": "Fecha de Inicio",
  "DownloadAllData-columnNameStartTime": "Hora de Inicio",
  "DownloadAllData-columnNameTimeZone": "Uso Horario",
  "DownloadAllData-columnNameTotalLifts": "Levantamientos Totales",
  "DownloadAllData-columnNameTwistedLifts": "Levantamientos en giro",
  "DownloadAllData-columnNameHoursUsed": "Horas Usado",
  "DownloadAllData-columnNameWeightOffloaded": "Peso Descargado",
  "DownloadAllData-dateRange": "Rango de Fecha",
  "DownloadAllData-department": "Departmento",
  "DownloadAllData-download": "Descargar",
  "DownloadAllData-interval": "Intervalo",
  "DownloadAllData-organization": "Organización",
  "DownloadAllData-reportName": "Reporte Toda la Información",
  "DownloadAllData-scope": "Alcance",
  "DownloadAllData-site": "Sitio",
  "EditableDevice-fetchDevicesError": "Ocurrió un error al obtener la información de los dispositivos disponibles",
  "EditableWorker-fetchWorkersError": "Ocurrió un error al obtener la información de los trabajadores disponibles",
  "EditableWorker-none": "Ninguno",
  "EditCustomPermissionsModal-admin": "Admin",
  "EditCustomPermissionsModal-errorOrganizationMessage": "Ocurrió un error al obtener la información de la organización del usuario.",
  "EditCustomPermissionsModal-infoMessage": "Todas las filas deben tener una opción seleccionada para guardar la configuración.",
  "EditCustomPermissionsModal-manager": "Manager",
  "EditCustomPermissionsModal-none": "Ninguno",
  "EditCustomPermissionsModal-readonly": "Solo Lectura",
  "EditCustomPermissionsModal-subtitle":
    "Primero, selecciona la configuración de la organización, luego sitio(s) y por ultimo los departamentos.",
  "EditCustomPermissionsModal-title": "Editar Permisos Personalizados",
  "EditSessionAssignment-newWorker": "Nuevo trabajador:",
  "EditSessionAssignment-none": "Ninguno",
  "EditSessionAssignment-previousWorker": "Trabajador anterior:",
  "EditSessionAssignment-sessionID": "ID de sesión:",
  "EditSessionAssignment-startTime": "Hora de inicio:",
  "EditSessionAssignment-subtitle": "Por favor seleccione el trabajador al que desea asignar la siguiente sesión:",
  "EditSessionAssignment-systemSerialNumber": "# de serie del sistema:",
  "EditSessionAssignment-title": "Editar asignación de sesión",
  "EditSessionAssignment-updateError": "Error al intentar actualizar el trabajador asignado a la sesión",
  "EntityDetail-address": "Dirección:",
  "EntityDetail-assignedDevices": "# Dispositivos Asignados:",
  "EntityDetail-availableDevices": "# Dispositivos Disponibles:",
  "EntityDetail-devices": "# de Dispositivos:",
  "EntityDetail-disconnectedDevices": "# Dispositivos Desconectados:",
  "EntityDetail-gatewayStatus": "Estado del Gateways:",
  "EntityDetail-iconUrl": "Url del ícono:",
  "EntityDetail-name": "Nombre:",
  "EntityDetail-siteName": "Nombre del Sitio:",
  "EntityDetail-departmentName": "Nombre del Departamento:",
  "EntityDetail-noGateway": "Sin Gateway",
  "EntityDetail-outOfServiceDevices": "# Dispositivos Fuera de Servicio:",
  "EntityDetail-shortName": "Nombre corto:",
  "EntityDetail-timezone": "Zona horaria:",
  "EntityDetail-title": "Detalle {entity}",
  "EntityDetail-title-department": "Departamento",
  "EntityDetail-title-organization": "Organización",
  "EntityDetail-title-site": "Sitio",
  "EntityDetailsSider-createDepartmentButton": "Crear Departamento",
  "EntityDetailsSider-createSiteButton": "Crear Sitio",
  "EntityDetailsSider-departmentsTitle": "Departamentos",
  "EntityDetailsSider-sitesTitle": "Sitios",
  "EntityDetailsSiderCard-departmentAndDeviceCount": "{departments} Departamentos / {devices} Dispositivos",
  "EntityDetailsSiderCard-descriptionAddress": "Dirección: {address}",
  "EntityDetailsSiderCard-descriptionTimezone": "Zona Horaria: {tz}",
  "EntityDetailsSiderCard-deviceCount": "{devices} Dispositivos",
  "EntityDetailsSiderCard-gatewayStatus": "Estado de los Gateways",
  "EntityDetailsSiderCard-gatewayDateSinceOffline": "({date})",
  "EntityDetailsSiderCard-DepartmentAndDeviceCount": "{departments} Departamentos / {devices} Dispositivos",
  "EntityDetailsSiderCard-siteDepartmentAndDeviceCount": "{sites} Sitios / {departments} Departamentos / {devices} Dispositivos",
  "EntityDetailsSiderCard-siteDepartmentInformationOrg":
    "Como este Sitio tiene solo un Departamento, la información de ambos se muestra al nivel del Departamento.",
  "EntityDetailsSiderCard-siteDepartmentInformation":
    "Como la Organización tiene solo un Sitio y Departamento, la información de ambos se muestra al nivel del Departamento.",
  "EntityDetailsSiderCard-view": "Ver",
  "EntityDetails-analyticsTabName": "Analíticas",
  "EntityDetails-dashboardTabName": "Tablero",
  "EntityDetails-devicesTabName": "Dispositivos",
  "EntityDetails-navigationMenu": "Menú de Navegación",
  "EntityDetails-workerReportGroupsTabName": "Grupos de Reportes de Trabajadores",
  "EntityDetails-workersTabName": "Trabajadores",
  "EntityDetails-liveCheckedOutTabName": "Retiros en tiempo real",
  "ErrorScreen-backHomeButton": "Volver a Inicio",
  "ErrorScreen-correlationId": "(ID de correlación: {correlationID})",
  "ErrorScreen-defaultErrorText": "Error",
  "ErrorScreen-internalServerErrorDescription": "Hubo un error al cargar la página",
  "ErrorScreen-internalServerErrorText": "Error del Servidor",
  "ErrorScreen-notFoundErrorDescription": "La entidad no existe",
  "ErrorScreen-notFoundErrorText": "No Encontrado",
  "ErrorScreen-reloadPageButton": "Recargar Página",
  "FetchAllocateGatewayData-errorMessage": "Error al intentar obtener los gateways disponibles.",
  "FetchAnalyticsDashboardWorkersData-errorMessage": "Error al intentar obtener la información de los trabajadores.",
  "FetchDashboardDailyUsageBreakdownSectionWorkersData-errorMessage": "Error al intentar obtener la información de los trabajadores.",
  "FetchDepartmentDetailPageData-errorMessage": "Error al intentar obtener la información del departamento.",
  "FetchDeviceAllocationData-errorMessage": "Error al intentar obtener los dispositivos disponibles.",
  "FetchDeviceManagementDeviceDetailPageData-errorMessage": "Error al intentar obtener la información del dispositivo.",
  "FetchDeviceManagementDeviceDetailPageData-refreshErrorMessage":
    "La información de las sesiones no se puedo refrescar despues de la actualización.",
  "FetchDiagnosticsDevicesPageData-errorMessage": "Error al intentar obtener la información de los dispositivos.",
  "FetchOrganizationsPageData-errorMessage": "Error al intentar obtener las organizaciones.",
  "FetchStatistics-errorMessage": "Error al intentar obtener las estadísticas.",
  "FetchWorkersData-errorMessage": "Error al intentar obtener los datos requeridos.",
  "GatewayAlertBlock-intermittentGateway":
    "El Gateway {serialNumber} de {siteName}-{departmentName} tiene una conexión intermitente. El estado de la conexión y los datos de uso de los dispositivos en este gateway pueden ser inexactos",
  "GatewayAlertBlock-offlineGateway":
    "El Gateway {serialNumber} de {siteName}-{departmentName} ha estado fuera de línea desde {lastEventDate}. El estado de la conexión y los datos de uso de los dispositivos en este gateway pueden ser inexactos",
  "GatewayAlertBlock-offlineIntermitentGateways":
    "El Gateway {listsText} está teniendo problemas de conexión. El estado de la conexión y los datos de uso de los dispositivos en esos gateways pueden ser inexactos",
  "GatewayAlertBlock-offlineIntermitentGatewaysList": "[ {serialNumberList} ] de {siteName}-{departmentName}",
  "GatewayItem-deallocateGateway": "Desasignar Gateway",
  "GatewayDetails-departmentLabel": "Departamento:",
  "GatewayDetails-gatewayFirmwareLabel": "Firmware del Gateway:",
  "GatewayDetails-gatewaySerialNumber": "# de Serie de Gateway {serialNumber}",
  "GatewayDetails-lastTimeConnectedLabel": "Última vez conectado:",
  "GatewayDetails-location": "Ubicación",
  "GatewayDetails-organizationLabel": "Organización:",
  "GatewayDetails-siteLabel": "Sitio:",
  "GatewaysTable-gatewayDetailsDrawerTitle": "Gateway",
  "GatewaysTable-downLabel": "Fuera de Línea",
  "GatewaysTable-firmwareColumnTitle": "Firmware",
  "GatewaysTable-intermittentLabel": "Intermitente",
  "GatewaysTable-lastSeenColumnTitle": "Última vez visto",
  "GatewaysTable-neverSeenLabel": "Nunca Visto",
  "GatewaysTable-serialNumberColumnTitle": "# de Serie del Gateway",
  "GatewaysTable-upLabel": "En Línea",
  "GatewaysTableLastSeenColumnRender-neverSeen": "Nunca Visto",
  "IndividualWorkerReportDrawer-contentMessage":
    "El reporte de trabajador para **{worker}** cubre un período de 7 días. Por favor seleccione la fecha de finalización deseada para el reporte.",
  "LeaderboardDownloadDropdown-downloadAll": "Todos",
  "LeaderboardDownloadDropdown-downloadBottom": "Oportunidades de Entrenamiento",
  "LeaderboardDownloadDropdown-downloadData": "Descargar datos",
  "LeaderboardDownloadDropdown-downloadError": "Ocurrió un error al descargar el reporte",
  "LeaderboardDownloadDropdown-downloadTop": "Primeros de la tabla",
  "LeaderboardDownloadDropdown-title": "Tabla de líderes",
  "LeaderboardDownloadDropdown-workerMetrics": "Metricas de Trabajador",
  "LiveCheckedOutDeviceTable-dataUploadedTodayColumnTitle": "Datos cargados hoy",
  "LiveCheckedOutDeviceTable-deviceTagColumnTitle": "Etiqueta del dispositivo",
  "LiveCheckedOutDeviceTable-workerColumnTitle": "Trabajador",
  "LiveCheckedOutDeviceTable-checkedOutColumnTitle": "Retirado",
  "LiveCheckedOutDeviceTable-lastSeenColumnTitle": "Última vez visto",
  "IndividualWorkerReportDrawer-drawerTitle": "Reporte de Trabajador",
  "IndividualWorkerReportDrawer-fileName": "Reporte de Trabajador",
  "ManualDeviceAllocation-description": "Por favor seleccione los números de serie que desea asignar a **{site}** - **{department}**.",
  "ManualDeviceAllocation-devicesLabel": "Dispositivos:",
  "ManualWorkerCreation-bothNamesRequiredValidation": "Si Nombre o Apellido son ingresados, ambos deben serlo",
  "ManualWorkerCreation-contentText": "Por favor liste los trabajadores que quiere crear en **{siteName}** - **{departmentName}**",
  "ManualWorkerCreation-empIdAlreadyExistsValidation": "Un trabajador con este ID de Empleado ya existe en el sitio {siteName}",
  "ManualWorkerCreation-ifNoNameRequireEmpIdValidation": "Si tanto Nombre como Apellido son omitidos, ID de Empleado debe ser ingresado",
  "ManualWorkerCreation-nameAlreadyExistsValidation": "Un trabajador con este nombre ya existe en el sitio {siteName}",
  "ManualWorkerCreation-title": "Creación de trabajador",
  "Organizations-allOrganizationsPath": "Todas las Organizaciones",
  "Organizations-allOrganizationsTitle": "Todas las organizaciones",
  "Organizations-allTagText": "Todas",
  "Organizations-createOrganizationButtonText": "Crear Organización",
  "Organizations-downloadData": "Descargar Info",
  "Organizations-favoriteTagText": "Favoritas",
  "Organizations-organizationsListEndMessage": "No hay más organizaciones para mostrar",
  "Organizations-searchButtonText": "Buscar",
  "OrganizationCard-deviceStatusChartTitle": "Estado de Dispositivos",
  "OrganizationCard-oneDeviceText": "1 Dispositivo en Total",
  "OrganizationCard-siteDepartmentAndDeviceCount":
    "{sites} {sites, plural, =1 {Sitio} other {Sitios}} / {departments} {departments, plural, =1 {Departamento} other {Departamentos}} / {devices} {devices, plural, =1 {Dispositivo} other {Dispositivos}}",
  "OrganizationCard-totalDevicesText": "{devices} Dispositivos Totales",
  "OrganizationCard-usageChartTitle": "Horas de uso totales por semana",
  "OrganizationDetail-deleteAlertMessage": "¿Está seguro que desea eliminar la organización?",
  "OrganizationDetail-deleteAlertTitle": "Eliminar Organización",
  "OrganizationDetail-deleteError": "No se pudo eliminar la organización",
  "OrganizationDetail-fetchDataError": "Error al intentar obtener la información de la organización",
  "OrganizationDetail-notFoundError": "No se encontró la organización",
  "OrganizationDetail-tableTitle": "Lista de sitios",
  "OrganizationDetail-updateError": "Error al intentar actualizar la información de la organización",
  "OrganizationForm-addressInputLabel": "Dirección",
  "OrganizationForm-createError": "Error al intentar crear la organización",
  "OrganizationForm-iconUrlInputLabel": "Url del ícono",
  "OrganizationForm-nameInputLabel": "Nombre",
  "OrganizationForm-shortNameInputLabel": "Nombre corto",
  "OrganizationForm-shortNameInputPatternRuleMessage":
    "El Nombre Corto sólo puede contener letras minúsculas, números o - pero no como primer o último caracter",
  "OrganizationModal-createError": "Error al intentar crear la organización",
  "OrganizationModal-createTitle": "Crear Organización",
  "OrganizationModal-deleteButtonText": "Eliminar Organización",
  "OrganizationModal-deleteError": "No se pudo eliminar la organización",
  "OrganizationModal-editTitle": "Editar Organización",
  "OrganizationModal-updateError": "Error al intentar actualizar la organización",
  "OrganizationsTable-departmentsColumnTitle": "# Departamentos",
  "OrganizationsTable-devicesColumnTitle": "# Dispositivos",
  "OrganizationsTable-nameColumnTitle": "Nombre de la organización",
  "OrganizationsTable-sitesColumnTitle": "# Sitios",
  "OrganizationSiteModal-editTitle": "Editar Organización y Sitio",
  "OrganizationSiteModal-deleteAlertMessage": "¿Está seguro que desea eliminar el sitio?",
  "OrganizationSiteModal-deleteAlertTitle": "Eliminar Sitio",
  "OrganizationSiteModal-deleteButtonText": "Eliminar Sitio",
  "OrganizationSiteModal-deleteError": "Error al intentar borrar el sitio",
  "OrganizationSiteModal-updateOrganizationError": "Error al intentar actualizar la organización",
  "OrganizationSiteModal-updateSiteError": "Error al intentar actualizar el sitio",
  "OverridePendingUpdateConfirmationModal-message": "¿Está seguro que desea sobrescribir la actualización pendiente?",
  "OverridePendingUpdateConfirmationModal-title": "Sobrescribir Actualización Pendiente",
  "PendingUpdateDetail-buttonOne": "Botón 1:",
  "PendingUpdateDetail-buttonThree": "Botón 3:",
  "PendingUpdateDetail-buttonTwo": "Botón 2:",
  "PendingUpdateDetail-drawerSubtitle": "Actualización pendiente para el siguiente dispositivo:",
  "PendingUpdateDetail-drawerTitle": "Actualización Pendiente",
  "PendingUpdateDetail-firmware": "Firmware Rev:",
  "PendingUpdateDetail-hardwareRev": "Hardware Rev:",
  "PendingUpdateDetail-overridePendingUpdateButton": "Sobrescribir Actualización Pendiente",
  "PendingUpdateDetail-systemSerialNumber": "# de Serie del Sistema:",
  "RemoveFromFavoritesModal-doNotShowMessageAgainText": "No volver a mostrar este mensaje.",
  "RemoveFromFavoritesModal-message": "¿Está seguro que desea quitar **{organization}** de sus favoritos?",
  "RenameWorkerGroupModal-errorMessage": "Error al intentar actualizar la información del grupo de reporte de trabajadores",
  "RenameWorkerGroupModal-inputLabel": "Nombre del Grupo:",
  "RenameWorkerGroupModal-nameLengthValidator": "Los nombres de los grupos están limitados a 40 caracteres. Por favor, acorte el nombre.",
  "RenameWorkerGroupModal-rename": "Renombrar",
  "RenameWorkerGroupModal-successMessage": "El nombre del grupo fue cambiado exitosamente",
  "RenameWorkerGroupModal-title": "Renombrar Grupo",
  "ReportDownload-allButtonText": "Descargar Reporte De Trabajadores",
  "ReportDownload-allMessage":
    "El reporte de trabajadores cubre un período de 7 días. Por favor seleccione la fecha final del reporte que desea descargar.",
  "ReportDownload-allTitle": "Reporte de Trabajadores",
  "ReportDownload-demoButtonText": "Descargar Reporte Demo",
  "ReportDownload-demoMessage":
    "El reporte demo cubre un período de 7 días. Por favor seleccione la fecha final del reporte que desea descargar.",
  "ReportDownload-demoTitle": "Reporte Demo",
  "ReportDownload-department": "Departmento:",
  "ReportDownload-downloadError": "Ocurrió un error al descargar el reporte",
  "ReportDownload-individualButtonText": "Descargar Reporte Individual",
  "ReportDownload-individualMessage":
    "El reporte del trabajador cubre un período de 7 días. Por favor seleccione la fecha final del reporte que desea descargar.",
  "ReportDownload-individualTitle": "Reporte del Trabajador",
  "ReportDownload-noDepartmentError": "No hay departamentos en este sitio para descargar el reporte.",
  "ReportDownload-reportEndDate": "Fecha Fin de Reporte:",
  "ReportDownload-summaryButtonText": "Descargar Reporte Resumido",
  "ReportDownload-summaryMessage":
    "El reporte resumido del departamento cubre un período de 7 días. Por favor seleccione la fecha final del reporte que desea descargar.",
  "ReportDownload-summaryTitle": "Reporte Resumido",
  "ReportDownload-worker": "**Trabajador:** {displayName}",
  "ReportDrawer-fetchReportError": "Ocurrió un error al obtener los datos del reporte",
  "ReportDrawerContent-downloadButtonText": "Descargar",
  "ReportDrawerContent-previewButtonText": "Previsualizar",
  "ReportDrawerContent-generateReportButtonText": "Generar reporte",
  "ReportDrawerContent-reportEndDate": "Fecha de Fin del Reporte:",
  "ReportDrawerContent-filterReport": "Filtro de reporte:",
  "ReportDrawerContent-allWorkers": "Todos los trabajadores",
  "ReportDrawerContent-workerReportGroups": "Grupos",
  "ReportPreviewModal-title": "Previsualización",
  "ReportPreviewModal-searchButtonText": "Buscar",
  "ReportPreviewModal-download": "Descargar",
  "WorkerSafeliftReportPreviewModal-title": "Previsualización",
  "WorkerSafeliftReportPreviewModal-searchButtonText": "SeaBuscarrch",
  "WorkerSafeliftReportPreviewModal-download": "Descargar",
  "WorkerSafeliftReportPreviewModal-safeliftReport": "Reporte de levantamiento seguro",
  "WorkerSafeliftReportPreviewModal-allWorkers": "Todos los trabajadores",
  "WorkerSafeliftReportPreviewModal-generatingReport": "Generando reporte...",
  "WorkerSafeliftReportPreviewModal-individual": "Trabajador seleccionado",
  "WorkerSafeliftReportPreviewModal-all": "Descargar Todos los Activos e Inactivos",
  "WorkerSafeliftReportPreviewModal-allActive": "Descargar Todos los Activos",
  "WorkerSafeliftReportPreviewModal-downloadError": "Ocurrió un error al intentar descargar el reporte",
  "WorkerSafeliftReportPreviewModal-displayInactiveWorkers": "Mostrar Trabajadores Inactivos:",
  "WorkerSafeliftReportPreviewModal-allWorkersNoData": "Ningún trabajador de {departmentName} estuvo activo entre {startDate} y {endDate}",
  "WorkerSafeliftReportPreviewModal-groupNoData":
    "Ningún trabajador de {departmentName} - {groupName}  estuvo activo entre  {startDate} y {endDate}",
  "WorkerSafeliftReportPreviewModal-workerNoData": "{workerName} no estuvo activo entre {startDate} y {endDate}",
  "SearchFilter-inputPlaceholder": "Texto a buscar",
  "SearchFilter-resetButton": "Reiniciar",
  "SearchFilter-searchButton": "Buscar",
  "SessionLog-download": "Descargar",
  "SessionLog-duration": "Duración:",
  "SessionLog-entries": "Entradas:",
  "SessionLog-errors": "Errores",
  "SessionLog-fetchDataError": "Ocurrió un error al obtener la información de los logs",
  "SessionLog-lifts": "Levantamientos:",
  "SessionLog-log": "Log",
  "SessionLog-none": "Ninguno",
  "SessionLog-sessionID": "ID Sesión",
  "SessionLog-startTime": "Hora Inicio:",
  "SessionLog-systemSerialNumber": "# de Serie del Sistema:",
  "SessionLog-title": "JSON Logs",
  "SessionLog-worker": "Worker:",
  "SessionsTable-createdLabel": "Creado",
  "SessionsTable-duration": "Duración",
  "SessionsTable-entries": "Entradas",
  "SessionsTable-errors": "Errores",
  "SessionsTable-hourAbbreviation": "h",
  "SessionsTable-id": "Sesión ID",
  "SessionsTable-lifts": "Levantamientos",
  "SessionsTable-logs": "Logs",
  "SessionsTable-minuteAbbreviation": "m",
  "SessionsTable-none": "Ninguno",
  "SessionsTable-processedLabel": "Procesado",
  "SessionsTable-secondAbbreviation": "s",
  "SessionsTable-startTime": "Inicio",
  "SessionsTable-status": "Estado",
  "SessionsTable-uploadedLabel": "Cargado",
  "SessionsTable-uuid": "Sesión UUID",
  "SessionsTable-worker": "Trabajador",
  "SignIn-emailInputLabel": "Email",
  "SignIn-emailInputRequiredRuleMessage": "El campo email es requerido",
  "SignIn-errorMessage": "No se pudo iniciar sesión",
  "SignIn-passwordInputLabel": "Contraseña",
  "SignIn-passwordInputRequiredRuleMessage": "El campo contraseña es requerido",
  "SignIn-submit": "Inciar sesión",
  "SiteDepartmentModal-deleteAlertMessage": "¿Está seguro que desea eliminar el departamento?",
  "SiteDepartmentModal-deleteAlertTitle": "Eliminar Departamento",
  "SiteDepartmentModal-deleteButtonText": "Eliminar Departamento",
  "SiteDepartmentModal-title": "Editar Sitio y Departamento",
  "SiteDepartmentModal-orgIncludedTitle": "Editar Organización, Sitio y Departamento",
  "SiteDetail-deleteAlertMessage": "¿Está seguro que desea eliminar el sitio?",
  "SiteDetail-deleteAlertTitle": "Eliminar Sitio",
  "SiteDetail-deleteError": "No se pudo eliminar el sitio",
  "SiteDetail-differentOrganizationError": "Este sitio pertenece a otra organización",
  "SiteDetail-fetchDataError": "Error al intentar obtener la información del sitio",
  "SiteDetail-notFoundError": "No se encontró el sitio",
  "SiteDetail-tableTitle": "Lista de departamentos",
  "SiteDetail-updateError": "Error al intentar actualizar la información del sitio",
  "SiteForm-addressInputLabel": "Dirección",
  "SiteForm-createError": "Error al intentar crear el sitio",
  "SiteForm-nameInputLabel": "Nombre",
  "SiteForm-timezoneInputLabel": "Zona Horaria",
  "SiteModal-createError": "Error al intentar crear el sitio",
  "SiteModal-createTitle": "Crear Sitio",
  "SiteModal-deleteButtonText": "Eliminar Sitio",
  "SiteModal-editTitle": "Editar Sitio",
  "SiteModal-updateError": "Error al intentar actualizar el sitio",
  "SiteSummaryLeaderboardPage-departmentAverage": "Promedio del departamento",
  "SiteSummaryLeaderboardPage-kilogramsUnit": "kgs",
  "SiteSummaryLeaderboardPage-poundsUnit": "lbs",
  "SiteSummaryLeaderboardPage-safeLiftScore": "Puntuación de Levantamientos Seguros",
  "SiteSummaryLeaderboardPage-weightOffloaded": "Peso Descargado",
  "SiteSummaryReportDrawer-activeInactiveWorkersCheckbox": "Lista de trabajadores activos/inactivos",
  "SiteSummaryReportDrawer-contentMessage":
    "El reporte resumido del sitio cubre un período de 7 días. Por favor seleccione la fecha final del reporte.",
  "SiteSummaryReportDrawer-displayWorkersWeightOffloadedCheckbox": "Mostrar peso descargado de los trabajadores",
  "SiteSummaryReportDrawer-errorMessage": "Error al intentar obtener la información",
  "SiteSummaryReportDrawer-featuresToIncludeMessage": "Funcionalidades para incluir en el informe.",
  "SiteSummaryReportDrawer-includeWorkerReportGroups": "Incluir Grupos de Trabajadores:",
  "SiteSummaryReportDrawer-leaderboardCheckbox": "Tabla de clasificación",
  "SiteSummaryReportDrawer-onlyAdminsMessage": "Solo los Administradores pueden editar.",
  "SiteSummaryReportDrawer-reportEndDate": "Fecha de Fin del Reporte:",
  "SiteSummaryReportDrawer-safeliftScoreCheckbox": "Mostrar Puntuación de Levantamientos Seguros",
  "SiteSummaryReportDrawer-select": "Seleccionar",
  "SiteSummaryReportDrawer-updateConfigButton": "Actualizar configuración",
  "SiteSummaryReportDrawer-updateError": "Se produjo un error al intentar actualizar la configuración del informe del sitio.",
  "SiteSummaryReportDrawer-updateSuccess": "La configuración del informe del sitio se actualizo con éxito.",
  "SiteSummaryReportPDF-aboveTarget": "Encima del Objetivo",
  "SiteSummaryReportPDF-active": "Activos",
  "SiteSummaryReportPDF-activeWorker": "Trabajadores Activos",
  "SiteSummaryReportPDF-belowTarget": "Debajo del Objetivo",
  "SiteSummaryReportPDF-exempt": "Exentos/Desactivados",
  "SiteSummaryReportPDF-fileName": "Reporte Resumido del Sitio.pdf",
  "SiteSummaryReportPDF-footer": "Página {currentPage} de {totalPages}",
  "SiteSummaryReportPDF-headerDates": "{startDate} a {endDate}",
  "SiteSummaryReportPDF-inactive": "Inactivos",
  "SiteSummaryReportPDF-inactiveWorker": "Trabajadores Inactivos",
  "SiteSummaryReportPDF-kilogramsUnit": "kgs",
  "SiteSummaryReportPDF-onTarget": "En el Objetivo",
  "SiteSummaryReportPDF-point": "•",
  "SiteSummaryReportPDF-poundsUnit": "lbs",
  "SiteSummaryReportPDF-totalUsageHours": "Horas Totales de Uso",
  "SiteSummaryReportPDF-totalWeightOffloaded": "Peso Descargado Total: ",
  "SiteSummaryReportPDF-weeklySummaryReport": "Reporte Resumido Semanal",
  "SiteSummaryReportPDF-weightOffloadedTitle": "Peso Descargado",
  "SiteSummaryReportPDF-workerReportGroups": "Grupos de Reportes de Trabajadores",
  "SiteSummaryReportPDF-workerStatusCounts": "Recuento de Estados",
  "SiteSummaryReportPreviewModal-download": "Descargar",
  "SiteSummaryReportPreviewModal-title": "Previsualización",
  "SitesTable-departmentsColumnTitle": "# Departamentos",
  "SitesTable-devicesColumnTitle": "# Dispositivos",
  "SitesTable-nameColumnTitle": "Nombre del sitio",
  "SiteWorkersTable-assignedDeviceColumnTitle": "Dispositivo Asignado",
  "SiteWorkersTable-daysSinceLastUsageColumnTitle": "Días desde el último uso",
  "SiteWorkersTable-departmentColumnTitle": "Departmento",
  "SiteWorkersTable-employeeIDColumnTitle": "ID del Empleado",
  "SiteWorkersTable-naLabel": "N/A",
  "SiteWorkersTable-nameColumnTitle": "Nombre",
  "SiteWorkersTable-report": "Reporte",
  "StatusChart-activeWorkers": "Activos",
  "StatusChart-assignedDevices": "Asignados",
  "StatusChart-availableDevices": "Disponibles",
  "StatusChart-deviceStatusTitle": "Dispositivos",
  "StatusChart-inactiveWorkers": "Inactivos",
  "StatusChart-moreInformationText": "*Click aquí para mas información",
  "StatusChart-outOfServiceDevices": "Fuera de Servicio",
  "StatusChart-statusChartTitle":
    "Estado {entityType, plural, =0 {de la} other {del}} {entityType, plural, =0 {Organización} =1 {Sitio} =2 {Departamento} =3 {Grupo de Trabajadores} other {Organización}}",
  "StatusChart-waitlistWorkers": "Lista de Espera",
  "StatusChart-workerStatusTitle": "Trabajadores",
  "Subheader-actions-allWorkersReport": "Reporte de Trabajadores",
  "Subheader-actions-demoReport": "Reporte Demo",
  "Subheader-actions-summaryReport": "Reporte Resumido",
  "SummaryReportDrawer-contentMessage":
    "El reporte resumido del departamento cubre un período de 7 días. Por favor seleccione la fecha final del reporte.",
  "SummaryReportDrawer-drawerTitle": "Reporte Resumido",
  "SummaryReportDrawer-fileName": "Reporte Resumido",
  "TargetUtilizationHoursModal-adminMessage": "Solo Administradores pueden editar estos objetivos.",
  "TargetUtilizationHoursModal-afterWeek": "Luego Semana {week}:",
  "TargetUtilizationHoursModal-configurationErrorMessage": "Error al intentar actualizar la configuración",
  "TargetUtilizationHoursModal-configurationSuccessMessage": "El criterio del objetivo fue actualizado correctamente",
  "TargetUtilizationHoursModal-constantTarget": "Objetivo Constante",
  "TargetUtilizationHoursModal-constantTargetLabel": "Selecciona un objetivo fijo de horas por dia para todos los trabajadores:",
  "TargetUtilizationHoursModal-departmentConfiguration": "Los administradores configuraron el departamento en {configName}:",
  "TargetUtilizationHoursModal-departmentConfigurationConstantTarget":
    "Tus administradores han configuradio un objetivo constante de {horas} horas/dia para el departamento.",
  "TargetUtilizationHoursModal-departmentConfigurationNoTarget":
    "Tus administradores no han configuradio un objetivo de horas para este departamento",
  "TargetUtilizationHoursModal-departmentConfigurationOnboardingCalendar":
    "Los administradores configuraron el departamento en el calendario de onboarding:",
  "TargetUtilizationHoursModal-hoursDay": "hrs/día",
  "TargetUtilizationHoursModal-hoursPerDay": "Horas por Día",
  "TargetUtilizationHoursModal-infoMessage":
    "El Calculo del Objetivo asume una semana de trabajo de 5 días. Otras configuraciones no son soportadas por el momento.",
  "TargetUtilizationHoursModal-noTarget": "Sin Objetivo",
  "TargetUtilizationHoursModal-onboardingCalendar": "Calendario de Onboarding",
  "TargetUtilizationHoursModal-onboardingCalendarLabel":
    "Selecciona un objetivo de horas por día que cambia en base a 4 semanas del onboarding",
  "TargetUtilizationHoursModal-subtitle": "Seleccione como quiere que el departamente utilice el objetivo",
  "TargetUtilizationHoursModal-title": "Objetivo de Uso del Dispositivo",
  "TargetUtilizationHoursModal-week": "Semana {week}:",
  "UnenrollmentReason-other": "Otro",
  "UnenrollmentReason-subtitle": "Por favor seleccione todas las razones por las que **{workerIdentifier}** se da de baja del programa",
  "UnenrollmentReason-title": "Razón de baja",
  "UnenrollmentReason-unenrollmentReasonError": "Ocurrió un error al guardar la razón de baja.",
  "UnenrollmentReasons-finished": "Finalizado",
  "UnenrollmentReasons-inProgress": "En Progreso",
  "UnenrollmentReasons-openGlossary": "Abrir Glosario",
  "UnenrollmentReasons-openGlossaryExtraDesktop":
    "Pase el cursor para ver detalles de las razones individuales de desmatriculación o clickea",
  "UnenrollmentReasons-primaryReason": "Razón principal",
  "UnenrollmentReasons-sceondaryReasons": "Razones secundarias",
  "UnenrollmentReasons-subtitleFirstStep":
    "Por favor seleccione una **razón principal** por las que **{workerIdentifier}** se da de baja del programa.",
  "UnenrollmentReasons-subtitleSecondStep":
    "Por favor seleccione las **razones secundarias** que sean necesarias por las que **{workerIdentifier}** se da de baja del programa (opcional).",
  "UnenrollmentReasons-waiting": "Esperando",
  "UnenrollWorkerMainReason-other": "Otro",
  "UnenrollWorkerMainReason-terminated": "Terminado/Renunció",
  "UnenrollWorkerMainReason-unknown": "Desconocido",
  "UnenrollWorkerOtherReason-assistance_time_wrong": "Tiempo de asistencia incorrecto/espasmódico",
  "UnenrollWorkerOtherReason-back": "Espalda",
  "UnenrollWorkerOtherReason-constriction": "Constricción",
  "UnenrollWorkerOtherReason-convenience": "Conveniencia",
  "UnenrollWorkerOtherReason-discomfort": "Malestar",
  "UnenrollWorkerOtherReason-extra_height_width": "Altura/ancho extra del traje en la espalda",
  "UnenrollWorkerOtherReason-general": "General",
  "UnenrollWorkerOtherReason-heat": "Calor",
  "UnenrollWorkerOtherReason-heavy_shoulder_pressure": "Traje pesado/Presión en el hombro",
  "UnenrollWorkerOtherReason-job": "Trabajo",
  "UnenrollWorkerOtherReason-job_function_change": "Cambio de función de trabajo",
  "UnenrollWorkerOtherReason-limits_overhead_reaching": "Límites sobrepasan alcance",
  "UnenrollWorkerOtherReason-no_need": "No lo necesita",
  "UnenrollWorkerOtherReason-on_leave": "De vacaciones",
  "UnenrollWorkerOtherReason-performance_concern": "Preocupación rendimiento/productividad",
  "UnenrollWorkerOtherReason-personal": "Personal",
  "UnenrollWorkerOtherReason-preexisting_condition": "Condición preexistente",
  "UnenrollWorkerOtherReason-shoulder_point_pressure": "Irritación de la correa del hombro/punto de presión",
  "UnenrollWorkerOtherReason-suitLimitations": "Limitaciones del traje",
  "UnenrollWorkerOtherReason-thigh_wraps_dropping": "Envolturas de muslo caidas",
  "UnenrollWorkerOtherReason-time_habit": "Tiempo/Hábito",
  "UnenrollWorkerOtherReason-use_case": "El trabajo no es un buen caso de uso",
  "UnenrollmentReasonsGlossary-title": "Glosario de Razones para la Baja",
  "UnenrollmentReasonsGlossary-description": "Descripción",
  "UnenrollmentReasonsGlossary-descriptionTerminatedQuit": "El usuario dejó la empresa (renunció o fue despedido)",
  "UnenrollmentReasonsGlossary-descriptionUnknown": "Se perdió contacto con el usuario. No se sabe por qué dejó el programa.",
  "UnenrollmentReasonsGlossary-descriptionBack": "Molestias en la espalda (inferior o superior)",
  "UnenrollmentReasonsGlossary-descriptionSuitHeavyShoulderPressure":
    "Molestias generales en el hombro debido a la presión causada por el peso/tirón del traje",
  "UnenrollmentReasonsGlossary-descriptionShoulderStrapIrritationPointPressure":
    "Irritación específica/presión puntual causada por la correa del hombro rozando al usuario",
  "UnenrollmentReasonsGlossary-descriptionHeat": "El usuario siente demasiado calor en el traje",
  "UnenrollmentReasonsGlossary-descriptionConstriction":
    "El usuario se siente constreñido, ya sea en el pecho o simplemente por la aprensión de llevar algo a lo que no está acostumbrado",
  "UnenrollmentReasonsGlossary-descriptionGeneral":
    "El usuario encuentra el traje generalmente incómodo y no puede indicar un punto específico de molestia",
  "UnenrollmentReasonsGlossary-descriptionDoesntNeedIt":
    "El usuario siente que personalmente no se beneficia del traje (aunque su función laboral sigue siendo adecuada)",
  "UnenrollmentReasonsGlossary-descriptionTimeHabit":
    "El usuario siente que el traje ocupa demasiado tiempo y/o le resulta difícil adquirir el hábito de ponérselo",
  "UnenrollmentReasonsGlossary-descriptionPreexistingCondition":
    "El usuario tiene alguna condición preexistente que cree no se alinea bien con el traje (lesión en el hombro, lesión en la rodilla, etc.)",
  "UnenrollmentReasonsGlossary-descriptionOnLeave": "El usuario está de baja en el trabajo por un período prolongado",
  "UnenrollmentReasonsGlossary-descriptionLimitsOverheadReaching":
    "El traje limita el alcance por encima de la cabeza del usuario o lo hace incómodo.",
  "UnenrollmentReasonsGlossary-descriptionThighWrapsDropping":
    "El usuario necesita ajustar constantemente las envolturas del muslo y es demasiado inconveniente",
  "UnenrollmentReasonsGlossary-descriptionExtraHeightWidthOfSuitOnBack":
    "El grosor del traje impide que el usuario se mueva como lo haría normalmente (es decir, tiene que agacharse más en estantes bajos, no puede meterse en espacios pequeños)",
  "UnenrollmentReasonsGlossary-descriptionAssistanceTimingWrongJerky":
    "El usuario percibe que la asistencia del traje no está sincronizada con sus movimientos",
  "UnenrollmentReasonsGlossary-descriptionJobFunctionChange":
    "El usuario cambió de trabajo y su nuevo trabajo ya no requiere un exotraje (por ejemplo, conductor de montacargas)",
  "UnenrollmentReasonsGlossary-descriptionPerformanceProductivityConcerns":
    "El usuario percibe que el traje lo está retrasando y/o afectando negativamente sus métricas de productividad.",
  "UnenrollmentReasonsGlossary-descriptionJobIsNotGoodUseCase":
    "El usuario percibe que la función laboral (que originalmente se pensó que podría beneficiarse) no se beneficia del traje.",
  "UnenrollmentReasonsGlossary-descriptionOther":
    "No encaja en ninguna de las categorías anteriores o desea registrar información/descripción adicional",
  "UnenrollmentReasonsGlossary-exitReason": "Razón de salida",
  "UnenrollmentReasonsGlossary-labelTerminatedQuit": "Despedido/Renunció",
  "UnenrollmentReasonsGlossary-labelUnknown": "Desconocido",
  "UnenrollmentReasonsGlossary-labelBack": "Espalda",
  "UnenrollmentReasonsGlossary-labelSuitHeavyShoulderPressure": "Traje pesado/presión en el hombro",
  "UnenrollmentReasonsGlossary-labelShoulderStrapIrritationPointPressure": "Irritación por correa del hombro/presión puntual",
  "UnenrollmentReasonsGlossary-labelHeat": "Calor",
  "UnenrollmentReasonsGlossary-labelConstriction": "Constreñimiento",
  "UnenrollmentReasonsGlossary-labelGeneral": "General",
  "UnenrollmentReasonsGlossary-labelDoesntNeedIt": "No lo necesita",
  "UnenrollmentReasonsGlossary-labelTimeHabit": "Tiempo/Hábito",
  "UnenrollmentReasonsGlossary-labelPreexistingCondition": "Condición preexistente",
  "UnenrollmentReasonsGlossary-labelOnLeave": "De baja",
  "UnenrollmentReasonsGlossary-labelLimitsOverheadReaching": "Limita el alcance por encima de la cabeza",
  "UnenrollmentReasonsGlossary-labelThighWrapsDropping": "Caída de envolturas de muslo",
  "UnenrollmentReasonsGlossary-labelExtraHeightWidthOfSuitOnBack": "Altura/anchura adicional del traje en la espalda",
  "UnenrollmentReasonsGlossary-labelAssistanceTimingWrongJerky": "Asistencia descoordinada/brusca",
  "UnenrollmentReasonsGlossary-labelJobFunctionChange": "Cambio de Función Laboral",
  "UnenrollmentReasonsGlossary-labelPerformanceProductivityConcerns": "Preocupaciones de Rendimiento/Productividad",
  "UnenrollmentReasonsGlossary-labelJobIsNotGoodUseCase": "La función laboral no es un buen caso de uso",
  "UnenrollmentReasonsGlossary-labelOther": "Otro",
  "UnenrollmentReasonsGlossary-subTitleUnknown": "Desconocido",
  "UnenrollmentReasonsGlossary-subTitleTerminatedQuit": "Despido/Renuncia",
  "UnenrollmentReasonsGlossary-subTitleOther": "Otro",
  "UnenrollmentReasonsGlossary-subTitleDiscomfort": "Malestar",
  "UnenrollmentReasonsGlossary-subTitleConvenience": "Conveniencia",
  "UnenrollmentReasonsGlossary-subTitlePersonal": "Personal",
  "UnenrollmentReasonsGlossary-subTitleSuitLimitations": "Limitaciones del Traje",
  "UnenrollmentReasonsGlossary-subTitleJob": "Trabajo",
  "Unsubscribe-accountSettings": "Configuración de la cuenta",
  "Unsubscribe-subText": "Por favor, haz click en el boton de debajo para desuscribirte de los emails administrativos de Verve Logic.",
  "Unsubscribe-title": "Desuscribirse de los mails administrativos",
  "Unsubscribe-unsubscribe": "Desuscribirse",
  "Unsubscribe-unsubscribedSubText":
    "Te haz Desuscrito exitosamente de los mails administrativos de Verve Logic. Para re-suscribirte por favor edita tu",
  "UpdateDeviceConfigurationForm-buttonLabel": "Botón {number}:",
  "UpdateDeviceConfigurationForm-buttonSettingsSubtitle": "Configuración de Botones",
  "UpdateDeviceConfigurationForm-description": "Por favor seleccione la nueva configuración para el dispositivo:",
  "UpdateDeviceConfigurationForm-firmwareVersionLabel": "Versión del Firmware:",
  "UpdateDeviceConfigurationForm-hardwareRevLabel": "Hardware Rev:",
  "UpdateDeviceConfigurationForm-noButtonSettings": "No se encontraron configuraciones de botones",
  "UpdateDeviceConfigurationForm-noFirmwareVersions": "No se encontraron versiones de firmware",
  "UpdateDeviceConfigurationForm-systemSerialNumberLabel": "# de Serie del Sistema:",
  "UpdateDeviceConfigurationModal-scheduleUpdateButtonText": "Programar Actualización",
  "UpdateDeviceConfigurationModal-title": "Actualizar la Configuración del Dispositivo",
  "UpdateDeviceConfigurationModal-updateError": "Error al intentar actualizar la configuración del dispositivo",
  "UpdateDeviceConfigurationModal-updateSuccess": "Actualización de la Configuración del Dispositivo programada exitosamente.",
  "UsageChart-averageUsageHoursLabel": "Horas promedio",
  "UsageChart-totalUsageHoursLabel": "Horas totales de uso",
  "UserPermissions-accessLevel": "Nivel de Acceso",
  "UserPermissions-active": "Activo",
  "UserPermissions-admin": "Admin",
  "UserPermissions-changeAccessLevelError": "Ocurrió un error al intentar actualizar el nivel del acceso del usuario",
  "UserPermissions-changeAccessLevelText":
    "Por favor confirme que desea cambiar el nivel de acceso de **{givenName} {familyName}** de la organización **{organizationName}** de **{previousLevel}** a **{newLevel}**.",
  "UserPermissions-changeAccessLevelTitle": "Cambiar Nivel de Acceso",
  "UserPermissions-changeActiveError": "Ocurrió un error al intentar actualizar el usuario.",
  "UserPermissions-confirm": "Confirmar",
  "UserPermissions-custom": "Personalizado",
  "UserPermissions-deactivateUser": "Desactivar Usuario",
  "UserPermissions-deactivateUserText":
    "Por favor confirme que desea desactivar a **{givenName} {familyName}** como parte de la organización **{organizationName}**.",
  "UserPermissions-email": "Email",
  "UserPermissions-fetchDataError": "Error al intentar obtener la información de los usuarios",
  "UserPermissions-firstName": "Nombre",
  "UserPermissions-global": "Global",
  "UserPermissions-inactive": "Inactivo",
  "UserPermissions-lastName": "Apellido",
  "UserPermissions-manager": "Manager",
  "UserPermissions-needToBeAdminOrTrustedUserError": "Necesita ser un usuario administrador o intermedio",
  "UserPermissions-needToBeAnAdminError": "Necesita ser admin",
  "UserPermissions-none": "Ninguno",
  "UserPermissions-organization": "Organización",
  "UserPermissions-reactivateUser": "Reactivar Usuario",
  "UserPermissions-reactivateUserText":
    "Por favor confirme que desea reactivar a **{givenName} {familyName}** para unirse a la organización **{organizationName}**.",
  "UserPermissions-readOnly": "Solo Lectura",
  "UserPermissions-statusColumnTitle": "Estado",
  "UserPermissions-title": "Permisos de Usuarios",
  "Validation-confirmPasswordError": "Las contraseñas no coinciden",
  "WorkerCreationCSVModal-columns": "Columnas: First Name, Last Name, Employee ID",
  "WorkerCreationCSVModal-createWorkers": "Crear Trabajadores",
  "WorkerCreationCSVModal-description":
    "Por favor cargue el archivo CSV de trabajadores que desea crear en **{siteName}** - **{departmentName}**.",
  "WorkerCreationCSVModal-successMessage": "Archivo CSV cargado exitosamente",
  "WorkerCreationManualModal-createAnotherWorker": "Crear otro trabajador",
  "WorkerCreationManualModal-createError": "Error al intentar crear el trabajador",
  "WorkerCreationManualModal-createdSuccessMessage": "Trabajador creado exitosamente",
  "WorkerCreationManualModal-createWorkers": "Crear Trabajadores",
  "WorkerDetail-assignedDevice": "Dispositivo:",
  "WorkerDetail-cannotDeleteMessage":
    "Este trabajador no se puede eliminar ya que está asignado a un dispositivo y/o sesiones. Elimine estas asignaciones y vuelva a intentarlo.",
  "WorkerDetail-dateFirstUsage": "Fecha de Primer Uso:",
  "WorkerDetail-dateLastUsage": "Fecha de Último Uso:",
  "WorkerDetail-deleteAlertTitle": "Eliminar Trabajador",
  "WorkerDetail-deleteAlertMessage": "¿Está seguro que desea eliminar este trabajador?",
  "WorkerDetail-deleteError": "Error al intentar desasignar el dispositivo",
  "WorkerDetail-deleteWorker": "Eliminar trabajador",
  "WorkerDetail-deviceAssignment": "Dispositivo Asignado",
  "WorkerDetail-deviceAssignmentError":
    "Error al intentar actualizar el dispositivo asignado a este trabajador, los otros detalles fueron actualizados correctamente",
  "WorkerDetail-deviceNoLongerHasWorker": "El dispositivo ya no tiene un trabajador",
  "WorkerDetail-disabledDevice": "El dispositivo no puede ser cambiado para trabajadores no listados",
  "WorkerDetail-drawerTitle": "Detalles del trabajador",
  "WorkerDetail-empIdAlreadyExistsValidation": "Un trabajador con este ID de Empleado ya existe en el sitio {siteName}",
  "WorkerDetail-employeeId": "Id de empleado:",
  "WorkerDetail-enrolled": "Enlistado",
  "WorkerDetail-errorFetchingWorkers": "Error obteniendo trabajadores",
  "WorkerDetail-exemptFromUsageTarget": "Exento del Objetivo de Uso:",
  "WorkerDetail-firstName": "Nombre:",
  "WorkerDetail-fetchSessionsDataError": "Ocurrió un error al obtener la información",
  "WorkerDetail-ifFirstNameRequireLastNameValidation": "Si Nombre es ingresado, Apellido debe ser ingresado también",
  "WorkerDetail-ifLastNameRequireFirstNameValidation": "Si Apellido es ingresado, Nombre debe ser ingresado también",
  "WorkerDetail-ifNoNameRequireEmpIdValidation": "Si tanto Nombre como Apellido son omitidos, ID de Empleado debe ser ingresado",
  "WorkerDetail-lastName": "Apellido:",
  "WorkerDetail-logReasonWorkerUnenrolling": "Por favor registre la razón por la que se da de baja el trabajador:",
  "WorkerDetail-name": "Nombre:",
  "WorkerDetail-nameAlreadyExistsValidation": "Un trabajador con este nombre ya existe en el sitio {siteName}",
  "WorkerDetail-noneOption": "Ninguno",
  "WorkerDetail-preferredLanguage": "Lenguaje Preferido:",
  "WorkerDetail-reportGroups": "Grupo de Reporte:",
  "WorkerDetail-reportGroupsNumber": "{quantity} más...",
  "WorkerDetail-status": "Estado:",
  "WorkerDetail-targetHoursStartDate": "Fecha de Inicio del Objetivo de Uso:",
  "WorkerDetail-title": "Detalles del Trabajador",
  "WorkerDetail-totalHours": "Horas Totales:",
  "WorkerDetail-totalLifts": "Levantamientos Totales:",
  "WorkerDetail-unenrolled": "No listado",
  "WorkerDetail-updateError": "Error al intentar actualizar la información del trabajador",
  "WorkerDetail-usageTargetEnabled": "Habilitado",
  "WorkerDetail-usageTargetDisabled": "Deshabilitado",
  "WorkerDetail-workerProfile": "Perfil del Trabajador",
  "WorkerDetail-workerStatistics": "Estadistícas del Trabajador",
  "WorkerDetail-workerStatus": "Estado del Trabajador",
  "WorkerDetailOld-assignedDevice": "Dispositivo Asignado:",
  "WorkerDetailOld-dateFirstUsage": "Primer Uso:",
  "WorkerDetailOld-dateLastUsage": "Último Uso:",
  "WorkerDetailOld-employeeId": "Id de empleado:",
  "WorkerDetailOld-firstName": "Nombre:",
  "WorkerDetailOld-lastName": "Apellido:",
  "WorkerDetailOld-status": "Estado:",
  "WorkerDetailOld-totalHours": "Horas Totales:",
  "WorkerDetailOld-totalLifts": "Levantamientos Totales:",
  "WorkerDetailOld-workerStatistics": "Estadistícas del Trabajador",
  "WorkerDeviceAssignmentModal-changeDeviceError": "An error occurred while attempting to update the worker data",
  "WorkerDeviceAssignmentModal-device": "Dispositivo:",
  "WorkerDeviceAssignmentModal-subtitle": "Selecciona dispositivo para **{workerName}**",
  "WorkerDeviceAssignmentModal-updateSuccessMessage": "Trabajador actualizado exitosamente",
  "WorkersFilters-allTagText": "Todos",
  "WorkersFilters-assignedTagText": "Asignado",
  "WorkersFilters-enrolledTagText": "Enlistado",
  "WorkersFilters-searchButtonText": "Buscar Trabajadores",
  "WorkersFilters-unenrolledTagText": "No listado",
  "WorkersFilters-waitlistTagText": "Lista de espera",
  "WorkerForm-createError": "Error al intentar crear los trabajadores",
  "WorkerForm-deviceInputLabel": "Dispositivo",
  "WorkerForm-employeeIdInputLabel": "Id de empleado",
  "WorkerForm-exemptFromUsageTarget": "Exento del Objetivo de Uso",
  "WorkerForm-firstNameInputLabel": "Nombre",
  "WorkerForm-lastNameInputLabel": "Apellido",
  "WorkerForm-noneOption": "Ninguno",
  "WorkerForm-preferredLanguageInputLabel": "Lenguaje Preferido",
  "WorkerForm-targetHoursStartDate": "Fecha de Inicio del Objetivo de Uso",
  "WorkerForm-workerReportGroupsInputLabel": "Grupos",
  "WorkerEditModal-assignedDevice": "Dispositivo:",
  "WorkerEditModal-changeStatusError": "Ocurrió un error al intentar actualizar el estado",
  "WorkerEditModal-employeeId": "ID de empleado:",
  "WorkerEditModal-enrolled": "Enlistado",
  "WorkerEditModal-exemptFromUsageTarget": "Exento del Objetivo de Uso:",
  "WorkerEditModal-firstName": "Nombre:",
  "WorkerEditModal-lastName": "Apellido:",
  "WorkerEditModal-reason": "Razón:",
  "WorkerEditModal-status": "Estado:",
  "WorkerEditModal-targetHoursStartDate": "Fecha de Inicio del Objetivo de Uso:",
  "WorkerEditModal-title": "Editar Perfil de Trabajador",
  "WorkerEditModal-unenrolled": "No listado",
  "WorkerEditModal-updateError": "Error al intentar actualizar la información del trabajador",
  "WorkerEditModal-updateSuccessMessage": "Trabajador actualizado exitosamente",
  "WorkerEditModal-workerReportGroups": "Grupos:",
  "WorkerNoDevice-assignErrorMessage": "No se pudo asignar el dispositivo al trabajador",
  "WorkerNoDevice-assignNewDevice": "Asignar un nuevo dispositivo",
  "WorkerNoDevice-assignNewDevice-placeholder": "Seleccione",
  "WorkerNoDevice-assignNewDeviceSuccessMessage": "El trabajador {workerName} fue asignado exitosamente al dispositivo {deviceTag}",
  "WorkerNoDevice-assignSuccessMessage": "Asignación exitosa",
  "WorkerNoDevice-device": "Dispositivo:",
  "WorkerNoDevice-firstSubtitle": "**{name}** ya no tiene un dispositivo asignado.",
  "WorkerNoDevice-leaveUnassigned": "Dejar sin asignar",
  "WorkerNoDevice-leaveUnassignedSuccessMessage": "El trabajador {workerName} fue dejado sin un dispositivo asignado",
  "WorkerNoDevice-reason": "Razón:",
  "WorkerNoDevice-secondSubtitle": "¿Qué desea hacer con este trabajador?",
  "WorkerNoDevice-title": "Estado del Trabajador",
  "WorkerNoDevice-unenrollWorker": "Deslistar Trabajador",
  "WorkerNoDevice-unenrollWorkerSuccessMessage": "El trabajador {workerName} fue deslistado con éxito",
  "WorkerNoDeviceOld-unenrollWorker": "Deslistar Trabajador. Razón:",
  "WorkerPreferredLanguage-enUS": "Inglés - Estados Unidos",
  "WorkerPreferredLanguage-esMX": "Español - México",
  "WorkerPreferredLanguage-frCA": "Francés - Canadá",
  "WorkerPreferredLanguage-htHT": "Creole - Haiti",
  "WorkerPreferredLanguage-none": "Ninguno",
  "WorkerPreferredLanguage-ptBR": "Portugués - Brazil",
  "WorkerReportGroupCard-numberOfWorkers":
    "{numberOfWorkers} {numberOfWorkers, plural, =0 {trabajadores} =1 {trabajador} other {trabajadores}}",
  "WorkerReportGroupDetail-addWorkers": "Agregar Trabajadores",
  "WorkerReportGroupDetail-allOrganizations": "Todas las Organizaciones",
  "WorkerReportGroupDetail-deleteAlertMessage": "¿Está seguro que desea eliminar **{groupName}**?",
  "WorkerReportGroupDetail-deleteAlertTitle": "Eliminar Grupo",
  "WorkerReportGroupDetail-deleteError": "No se pudo eliminar el grupo de trabajadores",
  "WorkerReportGroupDetail-deleteSuccess": "{groupName} eliminado exitosamente",
  "WorkerGroupDetail-errorMessage": "Error al intentar obtener la información del trabajador",
  "WorkerReportGroupDetail-fetchDataError": "Error al intentar obtener la información del grupo de trabajadores",
  "WorkerReportGroupDetail-removeWorkerAlertMessage": "¿Está seguro que desea eliminar el trabajador **{workerName}** de este grupo?",
  "WorkerReportGroupDetail-removeWorkerError": "No se pudo eliminar el trabajador del grupo",
  "WorkerReportGroupDetail-removeWorkerModalTitle": "Eliminar Trabajador",
  "WorkerReportGroupDetail-removeWorkerSuccess": "El trabajador {workerName} fue eliminado del grupo exitosamente",
  "WorkerReportGroupDetail-rename": "Renombrar",
  "WorkerReportGroupsDisplay-emptyStateText": "No hay grupos creados todavía, haga click en Crear Grupo Nuevo para agregar uno nuevo",
  "WorkerReportGroupsDisplay-fetchWorkerReportGroupsDataError":
    "Error al intentar obtener la información de los grupos de reportes de trabajadores",
  "WorkerReportGroupsDisplay-resultAltText": "Ícono de Grupo de Usuarios",
  "WorkerReportGroupsTabContent-createNewGroupButton": "Crear Grupo Nuevo",
  "WorkerReportGroupWorkersTable-department": "Departmento",
  "WorkerReportGroupWorkersTable-remove": "Eliminar",
  "WorkerReportGroupWorkersTable-status": "Estado",
  "WorkerReportGroupWorkersTable-worker": "Trabajador",
  "WorkersTabContent-allWorkersReportButtonText": "Reporte de Trabajadores",
  "WorkersTabContent-createWorkersButtonText": "Crear Trabajadores",
  "WorkersTabContent-deleteSuccess": "Trabajador eliminado exitosamente",
  "WorkersTabContent-fetchWorkersDataError": "Error al intentar obtener la información de los trabajadores",
};

const coreLocale: Partial<CoreLocale> = {};

CoreHelper.mergeWith(baseCoreLocale, CoreHelper.mergeWith(coreLocale, uiLocale));
