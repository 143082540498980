import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {DepartmentNested} from "./DepartmentNested";
import {DeviceStatus} from "./Device";
import {OrganizationNested} from "./OrganizationNested";
import {SiteNested} from "./SiteNested";
import {WorkerReportGroupNested} from "./WorkerReportGroupNested";

export enum WorkerPreferredLanguage {
  en_US = "en_US",
  pt_BR = "pt_BR",
  fr_CA = "fr_CA",
  es_MX = "es_MX",
  ht_HT = "ht_HT",
}

export type WorkerAssignedDevice = {
  id: string;
  device_tag: string;
  system_serial_number: string;
  status: DeviceStatus;
  most_recent_gateway_event_at: Date | null;
};

export class Worker extends BaseModel {
  id: string;
  assignable: boolean;
  department: DepartmentNested;
  site: SiteNested | null;
  organization: OrganizationNested | null;
  first_name: string;
  last_name: string;
  employee_id: string;
  assigned_device: WorkerAssignedDevice | null;
  groups: WorkerReportGroupNested[] | null;
  preferred_language: WorkerPreferredLanguage | null;
  total_time_used_seconds: number;
  total_lifts: number;
  first_session_start_at: Date | null;
  most_recent_session_start_at: Date | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
  exempt_target: boolean;
  target_hours_start_date: Date | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      assignable: observable,
      organization: observable,
      department: observable,
      site: observable,
      first_name: observable,
      last_name: observable,
      employee_id: observable,
      assigned_device: observable,
      groups: observable,
      total_time_used_seconds: observable,
      total_lifts: observable,
      first_session_start_at: observable,
      most_recent_session_start_at: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
      exempt_target: observable,
      target_hours_start_date: observable,
    });
  }

  static fixObjectFromJSON(object: Worker, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.first_session_start_at) object.first_session_start_at = new Date(json.first_session_start_at);
    if (json.most_recent_session_start_at) object.most_recent_session_start_at = new Date(json.most_recent_session_start_at);
    if (json.assigned_device?.most_recent_gateway_event_at && object.assigned_device)
      object.assigned_device.most_recent_gateway_event_at = new Date(json.assigned_device.most_recent_gateway_event_at);
    if (json.groups) object.groups = object.groups ? object.groups.map((group) => WorkerReportGroupNested.fromJSON(group)) : null;
    if (json.site) object.site = SiteNested.fromJSON(json.site);
    if (json.department) object.department = DepartmentNested.fromJSON(json.department);
    if (json.organization) object.organization = OrganizationNested.fromJSON(json.organization);
    if (json.target_hours_start_date) object.target_hours_start_date = new Date(json.target_hours_start_date);
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get displayName(): string {
    return this.first_name || this.last_name ? this.fullName : this.employee_id;
  }

  get onWaitlist(): boolean {
    return this.assignable && !this.assigned_device && !this.deleted_at;
  }
}
