import * as Models from "../../models";
import {BaseAPIClient, RequestOptions} from "../BaseAPIClient";
import * as Types from "./SessionAPIClient.types";

export class SessionAPIClient extends BaseAPIClient {
  static async sessions(request: Types.SessionsRequest, options: RequestOptions = {}): Promise<Types.SessionsResponse> {
    const {
      accessToken: {token},
      device_id,
      worker_id,
    } = request;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `sessions/`,
      headers: {token},
      request,
      options,
      params: {
        ...(device_id ? {device_id} : {}),
        ...(worker_id ? {worker_id} : {}),
      },
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          sessions: data.map((session: Models.Session) => Models.Session.fromJSON(session)),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async updateSessionAssignment(
    request: Types.UpdateSessionAssignmentRequest,
    options: RequestOptions = {}
  ): Promise<Types.UpdateSessionAssignmentResponse> {
    const {
      accessToken: {token},
      session_id,
      worker_id,
    } = request;

    const requestResponse = await this.request({
      requestMethod: `post`,
      url: `sessions/${session_id}/worker`,
      headers: {token},
      request,
      options,
      data: {worker_id},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;

      if (data) {
        return {
          success: true,
          session: Models.Session.fromJSON(data),
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async sessionLog(request: Types.SessionLogRequest, options: RequestOptions = {}): Promise<Types.SessionLogResponse> {
    const {session_id, accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: `get`,
      url: `sessions/${session_id}/logs`,
      headers: {token},
      request,
      options,
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          log: data,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else return this.getApiFetchErrorResponse(requestResponse);
  }

  static async assignSessions(request: Types.AssignSessionsRequest, options: RequestOptions = {}): Promise<Types.AssignSessionsResponse> {
    const {worker_id, device_id, start_date, end_date} = request;
    const {accessToken} = request;
    const {token} = accessToken;

    const requestResponse = await this.request({
      requestMethod: "post",
      url: "sessions/assign_sessions",
      headers: {token},
      request,
      options,
      data: {worker_id, device_id, start_date, end_date},
    });

    if (requestResponse.success) {
      const {data} = requestResponse.rawResponse;
      if (data) {
        return {
          success: true,
          response: data,
        };
      } else {
        return {
          success: false,
          error: this.genericError(),
        };
      }
    } else {
      return this.getApiFetchErrorResponse(requestResponse);
    }
  }
}
