import {useQuery} from "@tanstack/react-query";
import {Card, Space, Typography} from "antd";
import overridePendingUpdateIcon from "assets/images/common/Override_Pending_Update_Icon.svg";
import _ from "lodash";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../../core/stores/DeviceDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button} from "../../../common/button/Button";
import {Loading} from "../../../loading/Loading";
import styles from "./PendingUpdateDetail.module.scss";

type PendingUpdateDetailProps = {
  deviceId: string;
  onOverride: () => void;
};

export const PendingUpdateDetail: FunctionComponent<PendingUpdateDetailProps> = ({deviceId, onOverride}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const [device, setDevice] = useState<Models.Device | null>();

  const deviceQuery = useQuery({
    queryKey: ["DepartmentDetail-fetchDeviceData"],
    queryFn: () => {
      return deviceStore.device({
        accessToken: authenticationStore.state.accessToken!,
        id: deviceId,
      });
    },
  });

  useEffect(() => {
    if (!deviceQuery.data) return;

    if (!deviceQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("FetchDeviceManagementDeviceDetailPageData-errorMessage"),
        deviceQuery.data.correlationId
      );
      return;
    }
    setDevice(deviceQuery.data.device);
  }, [deviceQuery.data]);

  if (deviceQuery.isPending) return <Loading />;
  if (!device) return <Loading />;

  return (
    <>
      <Typography.Text>{WebHelper.formatMessage("PendingUpdateDetail-drawerSubtitle")}</Typography.Text>
      <Card className={styles.card}>
        <Space size={8}>
          <Space className={styles.cardItemsTitles} direction="vertical" size={16}>
            <Typography.Text>{WebHelper.formatMessage("PendingUpdateDetail-systemSerialNumber")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("PendingUpdateDetail-hardwareRev")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("PendingUpdateDetail-firmware")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("PendingUpdateDetail-buttonOne")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("PendingUpdateDetail-buttonTwo")}</Typography.Text>
            <Typography.Text>{WebHelper.formatMessage("PendingUpdateDetail-buttonThree")}</Typography.Text>
          </Space>
          <Space direction="vertical" size={16} align="start">
            <Typography.Text>{device.system_serial_number}</Typography.Text>
            <Typography.Text>{device.item_number_revision}</Typography.Text>
            <Typography.Text>
              {!_.isEmpty(device.formatted_config_firmware_version)
                ? device.formatted_config_firmware_version
                : WebHelper.formatMessage("Device-formattedConfigFirmwareVersionUnknown")}
            </Typography.Text>
            <Typography.Text>
              {device.config?.button_1_controller_setting?.name
                ? WebHelper.getControllerSettingsName(device.config.button_1_controller_setting.name)
                : ""}
            </Typography.Text>
            <Typography.Text>
              {device.config?.button_2_controller_setting?.name
                ? WebHelper.getControllerSettingsName(device.config.button_2_controller_setting.name)
                : ""}
            </Typography.Text>
            <Typography.Text>
              {device.config?.button_3_controller_setting?.name
                ? WebHelper.getControllerSettingsName(device.config.button_3_controller_setting.name)
                : ""}
            </Typography.Text>
          </Space>
        </Space>
      </Card>
      <Button shape={"round"} onClick={onOverride}>
        <div className={styles.overrideButton}>
          <img src={overridePendingUpdateIcon} alt={WebHelper.formatMessage("PendingUpdateDetail-overridePendingUpdateButton")} />
          {WebHelper.formatMessage("PendingUpdateDetail-overridePendingUpdateButton")}
        </div>
      </Button>
    </>
  );
};
