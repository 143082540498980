import {keepPreviousData, useQuery} from "@tanstack/react-query";
import {Drawer} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import _ from "lodash";
import React, {FunctionComponent, useEffect, useRef, useState} from "react";
import {GatewayAlertBlock} from "web/components/gateway-alert-block/GatewayAlertBlock";
import useUserPreferences from "web/hooks/useUserPreferences";

import * as Models from "../../../../core/models";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../core/stores/DeviceDataStore";
import {AppStore, SegmentKey} from "../../../../web/stores/AppStore";
import {TableSettings} from "../../../utils/TableUtils";
import {WebHelper} from "../../../utils/WebHelper";
import {DeviceDetail} from "../../device-detail/DeviceDetail";
import {ChangeDeviceStatusModal} from "../devices-tab-content/change-device-status-modal/ChangeDeviceStatusModal";
import {DeviceAllocationModal, DeviceAllocationMode} from "../devices-tab-content/device-allocation-modal/DeviceAllocationModal";
import {DeviceLiveCheckoutFilters} from "./devices-filters/DeviceLiveCheckoutFilters";
import {LiveCheckedOutDevicesTable} from "./live-checked-out-devices-table/LiveCheckedOutDevicesTable";

type LiveCheckOutTabContentProps = {
  department: Models.Department;
};

export const LiveCheckOutTabContent: FunctionComponent<LiveCheckOutTabContentProps> = ({department}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const flags = useFlags();
  const [userPreferences, setUserPreferences] = useUserPreferences();

  const [visibleDevice, setVisibleDevice] = useState<Models.DeviceShort>();
  const [showOnlyAssigned, setShowOnlyAssigned] = useState<boolean>(userPreferences.data.LiveCheckedOutDevicesTabShowOnlyAssigned ?? false);
  const [devices, setDevices] = useState<Models.DeviceShort[]>([]);
  const [devicesFiltered, setDevicesFiltered] = useState<Models.DeviceShort[]>([]);
  const [selectedTag, setSelectedTag] = useState<Models.DevicesLiveCheckoutTagsKey>(Models.DevicesLiveCheckoutTagsKey.All);
  const [tableSettings, setTableSettings] = useState<TableSettings>();
  const [deviceAllocationMode, setDeviceAllocationMode] = useState<DeviceAllocationMode | false>(false);
  const [searchValue, setSearchValue] = useState("");
  const [showChangeDeviceStatusModal, setShowChangeDeviceStatusModal] = useState(false);

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.LiveCheckedOutTabOpened, {
      orgID: department.organization.id,
      siteID: department.site.id,
      departmentID: department.id,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const devicesQuery = useQuery({
    queryKey: ["DepartmentDetail-fetchDevicesData", department.id],
    queryFn: () => {
      return deviceStore.devicesShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: department.id,
      });
    },
    enabled: !!department.id,
    placeholderData: keepPreviousData,
    refetchInterval: 5000,
  });

  useEffect(() => {
    if (!devicesQuery.data) return;

    if (devicesQuery.data.success) setDevices(devicesQuery.data.devices.filter((device) => !device.archived_at));
    else WebHelper.showErrorMessage(WebHelper.formatMessage("WorkersTabContent-fetchWorkersDataError"), devicesQuery.data.correlationId);
  }, [devicesQuery.data]);

  useEffect(() => {
    if (!devices) return;
    switch (selectedTag) {
      case Models.DevicesLiveCheckoutTagsKey.All:
        setDevicesFiltered(
          devices.filter((device) => {
            const matchesSearch =
              device.device_tag.toLowerCase().includes(searchValue.toLowerCase()) ||
              device.assigned_worker?.displayName.toLowerCase().includes(searchValue.toLowerCase());

            if (showOnlyAssigned) return showOnlyAssigned == !!device.assigned_worker && matchesSearch;
            else return matchesSearch;
          })
        );
        break;
      case Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed || Models.DevicesLiveCheckoutTagsKey.DeviceCheckedOut:
        setDevicesFiltered(
          devices.filter((device) => {
            const matchesSearch =
              (device.checked_out_status == Models.DevicesLiveCheckoutTagsKey.DeviceRecentlyUsed ||
                device.checked_out_status == Models.DevicesLiveCheckoutTagsKey.DeviceCheckedOut) &&
              (device.device_tag.toLowerCase().includes(searchValue.toLowerCase()) ||
                device.assigned_worker?.displayName.toLowerCase().includes(searchValue.toLowerCase()));

            if (showOnlyAssigned) return showOnlyAssigned == !!device.assigned_worker && matchesSearch;
            else return matchesSearch;
          })
        );
        break;
      case Models.DevicesLiveCheckoutTagsKey.DeviceNotCheckedOut:
        setDevicesFiltered(
          devices.filter((device) => {
            const matchesSearch =
              device.checked_out_status == Models.DevicesLiveCheckoutTagsKey.DeviceNotCheckedOut &&
              (device.device_tag.toLowerCase().includes(searchValue.toLowerCase()) ||
                device.assigned_worker?.displayName.toLowerCase().includes(searchValue.toLowerCase()));

            if (showOnlyAssigned) return showOnlyAssigned == !!device.assigned_worker && matchesSearch;
            else return matchesSearch;
          })
        );
        break;
      case Models.DevicesLiveCheckoutTagsKey.DeviceIdle:
        setDevicesFiltered(
          devices.filter((device) => {
            const matchesSearch =
              device.checked_out_status == Models.DevicesLiveCheckoutTagsKey.DeviceIdle &&
              (device.device_tag.toLowerCase().includes(searchValue.toLowerCase()) ||
                device.assigned_worker?.displayName.toLowerCase().includes(searchValue.toLowerCase()));

            if (showOnlyAssigned) return showOnlyAssigned == !!device.assigned_worker && matchesSearch;
            else return matchesSearch;
          })
        );
        break;

      default:
        break;
    }
  }, [selectedTag, devices, searchValue, showOnlyAssigned]);

  const handleTagChange = (tag: Models.DevicesLiveCheckoutTagsKey) => {
    setUserPreferences({
      LiveCheckedOutDevicesTabSelectedTag: tag,
    });
    setSelectedTag(tag);
  };

  const handleShowOnlyAssigned = (showOnlyAssigned: boolean) => {
    setUserPreferences({
      LiveCheckedOutDevicesTabShowOnlyAssigned: showOnlyAssigned,
    });
    setShowOnlyAssigned(showOnlyAssigned);
  };

  const handleChangeWorkerStatusModalOpen = (device: Models.DeviceShort) => {
    setVisibleDevice(device);
    setShowChangeDeviceStatusModal(true);
  };

  const handleDeviceStatusChange = () => {
    devicesQuery.refetch();
    setShowChangeDeviceStatusModal(false);
    setVisibleDevice(undefined);
  };

  const handleDeviceStatusChangeCancel = () => {
    setShowChangeDeviceStatusModal(false);
    setVisibleDevice(undefined);
  };

  const handleDeviceAllocationOnOk = () => {
    setDeviceAllocationMode(false);
    devicesQuery.refetch();
  };

  const handleDeviceAllocationCancel = () => {
    setDeviceAllocationMode(false);
  };

  const requestDrawerCloseRef = useRef<() => boolean>();
  const handleDrawerClose = () => {
    setVisibleDevice(undefined);
    if (requestDrawerCloseRef.current) requestDrawerCloseRef.current();
  };

  const handleSettingsChange = (
    action: string,
    newSettings?: {quickFilter?: Models.DevicesLiveCheckoutTagsKey; tableSettings?: TableSettings}
  ) => {
    if (action) {
      appStore.sendAnalyticTrack(SegmentKey.CheckedOutViewTableSettingsChanged, {
        orgID: department.organization.id,
        siteID: department.site.id,
        departmentID: department.id,
        pagination: newSettings?.tableSettings ? newSettings?.tableSettings?.pagination : tableSettings?.pagination,
        sorter: newSettings?.tableSettings ? newSettings?.tableSettings?.sorter : tableSettings?.sorter,
        filters: newSettings?.tableSettings ? newSettings?.tableSettings?.filters : tableSettings?.filters,
        quickFilter: newSettings?.quickFilter ?? selectedTag,
        action: action,
      });
    }
  };

  return (
    <>
      {flags.live_checked_out && (
        <>
          <GatewayAlertBlock />
          <DeviceLiveCheckoutFilters
            selectedTag={selectedTag}
            setSelectedTag={handleTagChange}
            setSearchValue={setSearchValue}
            setShowOnlyAssigned={handleShowOnlyAssigned}
            onFilterChange={handleSettingsChange}
          />
          <LiveCheckedOutDevicesTable
            devices={devicesFiltered}
            department={department}
            loading={!devicesQuery.isFetched}
            selectDevice={setVisibleDevice}
            filterValue={searchValue}
            refetchDevices={devicesQuery.refetch}
            onOpenChangeWorkerStatusModal={handleChangeWorkerStatusModalOpen}
            selectedTag={selectedTag}
            setTableSettings={setTableSettings}
            onTableChange={handleSettingsChange}
          />
          <ChangeDeviceStatusModal
            device={visibleDevice}
            open={showChangeDeviceStatusModal}
            onOk={handleDeviceStatusChange}
            onCancel={handleDeviceStatusChangeCancel}
          />
          <DeviceAllocationModal
            mode={deviceAllocationMode}
            department={department}
            onOk={handleDeviceAllocationOnOk}
            onCancel={handleDeviceAllocationCancel}
          />
          <Drawer
            open={!_.isNil(visibleDevice) && !showChangeDeviceStatusModal}
            width={WebHelper.drawerWidth}
            title={WebHelper.formatMessage("DeviceDetail-drawerTitle")}
            destroyOnClose
            onClose={handleDrawerClose}>
            {!_.isNil(visibleDevice) && (
              <DeviceDetail
                deviceId={visibleDevice.id}
                onClose={() => setVisibleDevice(undefined)}
                onActionPerformed={devicesQuery.refetch}
              />
            )}
          </Drawer>
        </>
      )}
    </>
  );
};
