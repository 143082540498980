import {Collapse as CollapseAntd} from "antd";
import React, {FunctionComponent} from "react";

import styles from "./Collapse.module.scss";

const {Panel} = CollapseAntd;

type CollapseProps = {
  children?: React.ReactNode;
  header: React.ReactNode;
  isOpen?: boolean;
  disabled?: boolean;
  onChange?: (key: string | string[]) => void;
};

export const Collapse: FunctionComponent<CollapseProps> = ({children, header, isOpen, disabled, onChange}) => {
  return (
    <CollapseAntd
      className={styles.collapsible}
      bordered={false}
      defaultActiveKey={[isOpen ? "header_panel" : "no_active_key"]}
      collapsible={disabled ? "disabled" : undefined}
      onChange={onChange}>
      <Panel key={"header_panel"} header={header} showArrow={!disabled}>
        {children}
      </Panel>
    </CollapseAntd>
  );
};
