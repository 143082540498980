import * as Models from "../../../../core/models";

type SafeliftLocaleKeys = {
  "WorkerSafeliftReportPDF-kilogramsUnit": string;
  "WorkerSafeliftReportPDF-poundsUnit": string;
  "WorkerSafeliftReportPDF-change": string;
  "WorkerSafeliftReportPDF-changeInSafeliftScore": string;
  "WorkerSafeliftReportPDF-currentDevice": string;
  "WorkerSafeliftReportPDF-currentScore": string;
  "WorkerSafeliftReportPDF-dateRange": string;
  "WorkerSafeliftReportPDF-daysWorn": string;
  "WorkerSafeliftReportPDF-deptAvg": string;
  "WorkerSafeliftReportPDF-employee": string;
  "WorkerSafeliftReportPDF-excessiveForwardLifts": string;
  "WorkerSafeliftReportPDF-fridayShort": string;
  "WorkerSafeliftReportPDF-hoursWorn": string;
  "WorkerSafeliftReportPDF-mondayShort": string;
  "WorkerSafeliftReportPDF-negative": string;
  "WorkerSafeliftReportPDF-onLeaderboard": string;
  "WorkerSafeliftReportPDF-positive": string;
  "WorkerSafeliftReportPDF-poundsOffloaded": string;
  "WorkerSafeliftReportPDF-kilogramsOffloaded": string;
  "WorkerSafeliftReportPDF-prolongedBending": string;
  "WorkerSafeliftReportPDF-safeLifts": string;
  "WorkerSafeliftReportPDF-saturdayShort": string;
  "WorkerSafeliftReportPDF-sideBending": string;
  "WorkerSafeliftReportPDF-startDay": string;
  "WorkerSafeliftReportPDF-summaryMetrics": string;
  "WorkerSafeliftReportPDF-sundayShort": string;
  "WorkerSafeliftReportPDF-thursdayShort": string;
  "WorkerSafeliftReportPDF-totalDaysWorn": string;
  "WorkerSafeliftReportPDF-totalHoursWorn": string;
  "WorkerSafeliftReportPDF-totalPoundsOffloaded": string;
  "WorkerSafeliftReportPDF-totalKilogramsOffloaded": string;
  "WorkerSafeliftReportPDF-trend": string;
  "WorkerSafeliftReportPDF-tuesdayShort": string;
  "WorkerSafeliftReportPDF-twisting": string;
  "WorkerSafeliftReportPDF-wednesdayShort": string;
  "WorkerSafeliftReportPDF-weeksInProgram": string;
};

type WorkerSafeliftReportLocales = {
  [lang in Models.WorkerPreferredLanguage]: SafeliftLocaleKeys;
};

export const localizeSafeliftText = (lang: string, key: keyof SafeliftLocaleKeys, variables?: {[key: string]: string | number}): string => {
  const langEnum =
    Models.WorkerPreferredLanguage[lang as keyof typeof Models.WorkerPreferredLanguage] || Models.WorkerPreferredLanguage.en_US;

  const translation = workerSafeliftReportLocales[langEnum][key];

  return variables
    ? Object.keys(variables).reduce((text, varKey) => {
        return text.replace(`{${varKey}}`, variables[varKey].toString());
      }, translation)
    : translation;
};

const workerSafeliftReportLocales: WorkerSafeliftReportLocales = {
  [Models.WorkerPreferredLanguage.en_US]: {
    "WorkerSafeliftReportPDF-kilogramsUnit": " kgs",
    "WorkerSafeliftReportPDF-poundsUnit": " lbs",
    "WorkerSafeliftReportPDF-change": "Change",
    "WorkerSafeliftReportPDF-changeInSafeliftScore": "Change In Safelift Score",
    "WorkerSafeliftReportPDF-currentDevice": "Current Device:",
    "WorkerSafeliftReportPDF-currentScore": "Current Score",
    "WorkerSafeliftReportPDF-dateRange": "{startDate} to {endDate}",
    "WorkerSafeliftReportPDF-daysWorn": "Days Worn",
    "WorkerSafeliftReportPDF-deptAvg": "Dept. Avg",
    "WorkerSafeliftReportPDF-employee": "Employee #:",
    "WorkerSafeliftReportPDF-excessiveForwardLifts": "Excessive Forward Bending",
    "WorkerSafeliftReportPDF-fridayShort": "F",
    "WorkerSafeliftReportPDF-hoursWorn": "Hours Worn",
    "WorkerSafeliftReportPDF-kilogramsOffloaded": "Kilograms Offloaded",
    "WorkerSafeliftReportPDF-mondayShort": "M",
    "WorkerSafeliftReportPDF-negative": "Negative",
    "WorkerSafeliftReportPDF-onLeaderboard": "On Leaderboard",
    "WorkerSafeliftReportPDF-positive": "Positive",
    "WorkerSafeliftReportPDF-poundsOffloaded": "Pounds Offloaded",
    "WorkerSafeliftReportPDF-prolongedBending": "Prolonged Bending",
    "WorkerSafeliftReportPDF-safeLifts": "Safe Lifts",
    "WorkerSafeliftReportPDF-saturdayShort": "Sa",
    "WorkerSafeliftReportPDF-sideBending": "Side Bending",
    "WorkerSafeliftReportPDF-startDay": "Start Date was {date}",
    "WorkerSafeliftReportPDF-summaryMetrics": "Summary Metrics",
    "WorkerSafeliftReportPDF-sundayShort": "Su",
    "WorkerSafeliftReportPDF-thursdayShort": "Th",
    "WorkerSafeliftReportPDF-totalDaysWorn": "Total Days Worn",
    "WorkerSafeliftReportPDF-totalHoursWorn": "Total Hours Worn",
    "WorkerSafeliftReportPDF-totalKilogramsOffloaded": "Total Kilograms Offloaded",
    "WorkerSafeliftReportPDF-totalPoundsOffloaded": "Total Pounds Offloaded",
    "WorkerSafeliftReportPDF-trend": "Trend",
    "WorkerSafeliftReportPDF-tuesdayShort": "Tu",
    "WorkerSafeliftReportPDF-twisting": "Twisting",
    "WorkerSafeliftReportPDF-wednesdayShort": "W",
    "WorkerSafeliftReportPDF-weeksInProgram": "{weekQty} Week(s) in program",
  },
  [Models.WorkerPreferredLanguage.es_MX]: {
    "WorkerSafeliftReportPDF-kilogramsUnit": " kgs",
    "WorkerSafeliftReportPDF-poundsUnit": " lbs",
    "WorkerSafeliftReportPDF-change": "Cambio",
    "WorkerSafeliftReportPDF-changeInSafeliftScore": "Cambio en la Puntuación de Levantamiento Seguro",
    "WorkerSafeliftReportPDF-currentDevice": "Dispositivo Actual:",
    "WorkerSafeliftReportPDF-currentScore": "Puntuación Actual",
    "WorkerSafeliftReportPDF-dateRange": "{startDate} a {endDate}",
    "WorkerSafeliftReportPDF-daysWorn": "Días Utilizados",
    "WorkerSafeliftReportPDF-deptAvg": "Prom. Depto.",
    "WorkerSafeliftReportPDF-employee": "Empleado #:",
    "WorkerSafeliftReportPDF-excessiveForwardLifts": "Flexión Hacia Adelante Excesiva",
    "WorkerSafeliftReportPDF-fridayShort": "V",
    "WorkerSafeliftReportPDF-hoursWorn": "Horas Utilizadas",
    "WorkerSafeliftReportPDF-kilogramsOffloaded": "Kilogramos Descargados",
    "WorkerSafeliftReportPDF-mondayShort": "L",
    "WorkerSafeliftReportPDF-negative": "Negativo",
    "WorkerSafeliftReportPDF-onLeaderboard": "En la Clasificación",
    "WorkerSafeliftReportPDF-positive": "Positivo",
    "WorkerSafeliftReportPDF-poundsOffloaded": "Libras Descargadas",
    "WorkerSafeliftReportPDF-prolongedBending": "Flexión Prolongada",
    "WorkerSafeliftReportPDF-safeLifts": "Levantamientos Seguros",
    "WorkerSafeliftReportPDF-saturdayShort": "S",
    "WorkerSafeliftReportPDF-sideBending": "Flexión Lateral",
    "WorkerSafeliftReportPDF-startDay": "La fecha de inicio fue {date}",
    "WorkerSafeliftReportPDF-summaryMetrics": "Métricas Resumidas",
    "WorkerSafeliftReportPDF-sundayShort": "D",
    "WorkerSafeliftReportPDF-thursdayShort": "J",
    "WorkerSafeliftReportPDF-totalDaysWorn": "Total de Días Utilizados",
    "WorkerSafeliftReportPDF-totalHoursWorn": "Horas Totales Utilizadas",
    "WorkerSafeliftReportPDF-totalKilogramsOffloaded": "Kilogramos Totales Descargados",
    "WorkerSafeliftReportPDF-totalPoundsOffloaded": "Total de Libras Descargadas",
    "WorkerSafeliftReportPDF-trend": "Tendencia",
    "WorkerSafeliftReportPDF-tuesdayShort": "Ma",
    "WorkerSafeliftReportPDF-twisting": "Torsión",
    "WorkerSafeliftReportPDF-wednesdayShort": "Mi",
    "WorkerSafeliftReportPDF-weeksInProgram": "{weekQty} Semana(s) en el Programa",
  },
  [Models.WorkerPreferredLanguage.fr_CA]: {
    "WorkerSafeliftReportPDF-kilogramsUnit": " kgs",
    "WorkerSafeliftReportPDF-poundsUnit": " lbs",
    "WorkerSafeliftReportPDF-change": "Changement",
    "WorkerSafeliftReportPDF-changeInSafeliftScore": "Changement dans la Façon de Soulever avec Précaution, Score",
    "WorkerSafeliftReportPDF-currentDevice": "Dispositif actuel:",
    "WorkerSafeliftReportPDF-currentScore": "Score Actuel",
    "WorkerSafeliftReportPDF-dateRange": "Du {startDate} au {endDate}",
    "WorkerSafeliftReportPDF-daysWorn": "Jours d'utilisation",
    "WorkerSafeliftReportPDF-deptAvg": "Dept. Moy.",
    "WorkerSafeliftReportPDF-employee": "Employé #:",
    "WorkerSafeliftReportPDF-excessiveForwardLifts": "Fléchissement Avant, Excessif",
    "WorkerSafeliftReportPDF-fridayShort": "V",
    "WorkerSafeliftReportPDF-hoursWorn": "Heures d'utilisation",
    "WorkerSafeliftReportPDF-mondayShort": "L",
    "WorkerSafeliftReportPDF-negative": "Négatif",
    "WorkerSafeliftReportPDF-onLeaderboard": "Au Classement",
    "WorkerSafeliftReportPDF-positive": "Positif",
    "WorkerSafeliftReportPDF-poundsOffloaded": "Poids Sans Chargement",
    "WorkerSafeliftReportPDF-prolongedBending": "Fléchissement Prolongé",
    "WorkerSafeliftReportPDF-safeLifts": "Levées Sécuritaires",
    "WorkerSafeliftReportPDF-saturdayShort": "S",
    "WorkerSafeliftReportPDF-sideBending": "Fléchissement Latéral",
    "WorkerSafeliftReportPDF-startDay": "La date de début était le {date}",
    "WorkerSafeliftReportPDF-summaryMetrics": "Récapitulatif Métrique",
    "WorkerSafeliftReportPDF-sundayShort": "D",
    "WorkerSafeliftReportPDF-thursdayShort": "J",
    "WorkerSafeliftReportPDF-totalDaysWorn": "Jours Totales d'utilisation",
    "WorkerSafeliftReportPDF-totalHoursWorn": "Heures Totales d'utilisation",
    "WorkerSafeliftReportPDF-totalKilogramsOffloaded": "Kilogrammes Totals Déchargés",
    "WorkerSafeliftReportPDF-totalPoundsOffloaded": "Poids Total Sans Chargement",
    "WorkerSafeliftReportPDF-trend": "Tendance",
    "WorkerSafeliftReportPDF-tuesdayShort": "Ma",
    "WorkerSafeliftReportPDF-twisting": "Torsion",
    "WorkerSafeliftReportPDF-wednesdayShort": "Me",
    "WorkerSafeliftReportPDF-weeksInProgram": "{weekQty} Semaine(s) dans le Programme",
    "WorkerSafeliftReportPDF-kilogramsOffloaded": "Kilogrammes Déchargés",
  },
  [Models.WorkerPreferredLanguage.ht_HT]: {
    "WorkerSafeliftReportPDF-kilogramsUnit": " kgs",
    "WorkerSafeliftReportPDF-poundsUnit": " lbs",
    "WorkerSafeliftReportPDF-change": "Chanjman",
    "WorkerSafeliftReportPDF-changeInSafeliftScore": "Chanjman nan nòt Asansè Sekirite",
    "WorkerSafeliftReportPDF-currentDevice": "Aparèy Aktyèl:",
    "WorkerSafeliftReportPDF-currentScore": "Rezilta Aktyèl",
    "WorkerSafeliftReportPDF-dateRange": "{startDate} a {endDate}",
    "WorkerSafeliftReportPDF-daysWorn": "Jou Ekoule",
    "WorkerSafeliftReportPDF-deptAvg": "Dept. Mwa.",
    "WorkerSafeliftReportPDF-employee": "Anplwaye #:",
    "WorkerSafeliftReportPDF-excessiveForwardLifts": "Twò Koube an Avan",
    "WorkerSafeliftReportPDF-fridayShort": "V",
    "WorkerSafeliftReportPDF-hoursWorn": "Èdtan Ekoule",
    "WorkerSafeliftReportPDF-mondayShort": "L",
    "WorkerSafeliftReportPDF-negative": "Negatif",
    "WorkerSafeliftReportPDF-onLeaderboard": "nan klasman",
    "WorkerSafeliftReportPDF-positive": "Pozitif",
    "WorkerSafeliftReportPDF-poundsOffloaded": "Liv Dekaje",
    "WorkerSafeliftReportPDF-prolongedBending": "Koube Pwolonje",
    "WorkerSafeliftReportPDF-safeLifts": "Leve San Danje",
    "WorkerSafeliftReportPDF-saturdayShort": "S",
    "WorkerSafeliftReportPDF-sideBending": "Koube Sou Kote",
    "WorkerSafeliftReportPDF-startDay": "Dat kòmansman an se te {date}",
    "WorkerSafeliftReportPDF-summaryMetrics": "Rezime Metrik",
    "WorkerSafeliftReportPDF-sundayShort": "D",
    "WorkerSafeliftReportPDF-thursdayShort": "J",
    "WorkerSafeliftReportPDF-totalDaysWorn": "Total Jou Ekoule",
    "WorkerSafeliftReportPDF-totalHoursWorn": "Total Èdtan Pase",
    "WorkerSafeliftReportPDF-totalKilogramsOffloaded": "Total Kilogram Dechaje",
    "WorkerSafeliftReportPDF-totalPoundsOffloaded": "Total Liv Dekaje",
    "WorkerSafeliftReportPDF-trend": "Tandans",
    "WorkerSafeliftReportPDF-tuesdayShort": "Ma",
    "WorkerSafeliftReportPDF-twisting": "Defòmasyon",
    "WorkerSafeliftReportPDF-wednesdayShort": "Me",
    "WorkerSafeliftReportPDF-weeksInProgram": "{weekQty} Semèn nan Pwogram",
    "WorkerSafeliftReportPDF-kilogramsOffloaded": "Kilogram Dechaje",
  },
  [Models.WorkerPreferredLanguage.pt_BR]: {
    "WorkerSafeliftReportPDF-kilogramsUnit": " kgs",
    "WorkerSafeliftReportPDF-poundsUnit": " lbs",
    "WorkerSafeliftReportPDF-change": "Mudança",
    "WorkerSafeliftReportPDF-changeInSafeliftScore": "Mudança nos Levantadores Seguros",
    "WorkerSafeliftReportPDF-currentDevice": "Dispositivo Atual:",
    "WorkerSafeliftReportPDF-currentScore": "Contagem Atual",
    "WorkerSafeliftReportPDF-dateRange": "De {startDate} a {endDate}",
    "WorkerSafeliftReportPDF-daysWorn": "Dias de Uso",
    "WorkerSafeliftReportPDF-deptAvg": "Média do Depto.",
    "WorkerSafeliftReportPDF-employee": "Empregado #:",
    "WorkerSafeliftReportPDF-excessiveForwardLifts": "Inclinação Excessiva Para Frente",
    "WorkerSafeliftReportPDF-fridayShort": "Sex",
    "WorkerSafeliftReportPDF-hoursWorn": "Horas de Uso",
    "WorkerSafeliftReportPDF-mondayShort": "Seg",
    "WorkerSafeliftReportPDF-negative": "Negativo",
    "WorkerSafeliftReportPDF-onLeaderboard": "Na Tabela de Classificação",
    "WorkerSafeliftReportPDF-positive": "Positivo",
    "WorkerSafeliftReportPDF-poundsOffloaded": "Libras Descarregadas",
    "WorkerSafeliftReportPDF-prolongedBending": "Inclinação Prolongada",
    "WorkerSafeliftReportPDF-safeLifts": "Levantadores Seguros",
    "WorkerSafeliftReportPDF-saturdayShort": "Sáb",
    "WorkerSafeliftReportPDF-sideBending": "Inclinação Lateral",
    "WorkerSafeliftReportPDF-startDay": "A data de início foi {date}",
    "WorkerSafeliftReportPDF-summaryMetrics": "Métricas Resumidas",
    "WorkerSafeliftReportPDF-sundayShort": "Dom",
    "WorkerSafeliftReportPDF-thursdayShort": "Qui",
    "WorkerSafeliftReportPDF-totalDaysWorn": "Total de Dias de Uso",
    "WorkerSafeliftReportPDF-totalHoursWorn": "Total de Horas de Uso",
    "WorkerSafeliftReportPDF-totalKilogramsOffloaded": "Quilogramas Totais Descarregados",
    "WorkerSafeliftReportPDF-totalPoundsOffloaded": "Total de Libras Descarregadas",
    "WorkerSafeliftReportPDF-trend": "Tendência",
    "WorkerSafeliftReportPDF-tuesdayShort": "Ter",
    "WorkerSafeliftReportPDF-twisting": "Rotação",
    "WorkerSafeliftReportPDF-wednesdayShort": "Qua",
    "WorkerSafeliftReportPDF-weeksInProgram": "{weekQty} Semana(s) no Programa",
    "WorkerSafeliftReportPDF-kilogramsOffloaded": "Quilogramas Descarregados",
  },
};

export const getDateLocaleFromPreferredLanguage = (lang: string): string => {
  const locales = {
    [Models.WorkerPreferredLanguage.en_US]: "en-US",
    [Models.WorkerPreferredLanguage.pt_BR]: "pt-BR",
    [Models.WorkerPreferredLanguage.fr_CA]: "fr-CA",
    [Models.WorkerPreferredLanguage.es_MX]: "es-MX",
    // Haitian Creole doesn't have a specific locale, so you might use French or English as an approximation
    [Models.WorkerPreferredLanguage.ht_HT]: "fr-CA",
  };

  return locales[lang] || locales[Models.WorkerPreferredLanguage.en_US]; //defaults to english
};
