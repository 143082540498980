import {Switch, Typography} from "antd";
import devicePendingConfigIcon from "assets/images/common/Device_PendingConfig_Icon.svg";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./DeviceManagementStats.module.scss";

export type DeviceManagementStatsProps = {
  activeTab: Models.DeviceManagementTabKeys;
  devices: Models.DeviceShort[];
  gateways: Models.Gateway[];
  includeArchived: boolean;
  onIncludeArchivedChange: () => void;
};

export const DeviceManagementStats: FunctionComponent<DeviceManagementStatsProps> = observer(
  ({activeTab, devices, gateways, includeArchived, onIncludeArchivedChange}) => {
    const [totalDevices, setTotalDevices] = useState(devices.length);
    const [pendingConfigurationUpdates, setPendingConfigurationUpdates] = useState(0);
    const [connectedDevices, setConnectedDevices] = useState(0);
    const [disconnectedDevices, setDisconnectedDevices] = useState(0);
    const [neverSeenDevices, setNeverSeenDevices] = useState(0);

    const [totalGateways, setTotalGateways] = useState(gateways.length);
    const [upGateways, setUpGateways] = useState(0);
    const [downGateways, setDownGateways] = useState(0);
    const [intermittentGateways, setIntermittentGateways] = useState(0);
    const [neverSeenGateways, setNeverSeenGateways] = useState(0);

    useEffect(() => {
      setTotalDevices(devices.length);

      let pendingConfigurationUpdates = 0;
      let connectedDevices = 0;
      let disconnectedDevices = 0;
      let neverSeenDevices = 0;

      devices.forEach((device) => {
        pendingConfigurationUpdates = device.device_config_pending ? pendingConfigurationUpdates + 1 : pendingConfigurationUpdates;
        switch (device.status) {
          case Models.DeviceStatus.Connected:
            connectedDevices++;
            break;
          case Models.DeviceStatus.Disconnected:
            disconnectedDevices++;
            break;
          case Models.DeviceStatus.NeverSeen:
            neverSeenDevices++;
            break;
          default:
            break;
        }
      });

      setPendingConfigurationUpdates(pendingConfigurationUpdates);
      setConnectedDevices(connectedDevices);
      setDisconnectedDevices(disconnectedDevices);
      setNeverSeenDevices(neverSeenDevices);
    }, [devices]);

    useEffect(() => {
      setTotalGateways(gateways.length);

      let upGateways = 0;
      let downGateways = 0;
      let intermittentGateways = 0;
      let neverSeenGateways = 0;

      gateways.forEach((gateway) => {
        switch (gateway.status) {
          case Models.GatewayStatus.Up:
            upGateways++;
            break;
          case Models.GatewayStatus.Down:
            downGateways++;
            break;
          case Models.GatewayStatus.Intermittent:
            intermittentGateways++;
            break;
          case Models.GatewayStatus.NeverSeen:
            neverSeenGateways++;
            break;
        }
      });

      setUpGateways(upGateways);
      setDownGateways(downGateways);
      setIntermittentGateways(intermittentGateways);
      setNeverSeenGateways(neverSeenGateways);
    }, [gateways]);

    return (
      <div className={styles.wrapper}>
        {activeTab === Models.DeviceManagementTabKeys.DeviceConfig && (
          <>
            <div className={styles.statsItem}>
              <div className={styles.statsSubItem}>
                <div>
                  <Typography.Text className={styles.label} strong>
                    {WebHelper.formatMessage("DeviceManagementStats-totalDevicesLabel")}
                  </Typography.Text>
                  {totalDevices}
                </div>
                <div>
                  <Typography.Text className={styles.label}>
                    {WebHelper.formatMessage("DeviceManagementStats-includeArchivedLabel")}
                  </Typography.Text>
                  <Switch checked={includeArchived} onChange={onIncludeArchivedChange} />
                </div>
              </div>
              <div className={styles.statsSubItem}>
                <img
                  className={styles.pendingUpdateIcon}
                  src={devicePendingConfigIcon}
                  alt={WebHelper.formatMessage("DeviceManagementDevicesTable-pendingUpdate")}
                />
                <div>
                  <Typography.Text className={styles.label} strong>
                    {WebHelper.formatMessage("DeviceManagementStats-pendingConfigurationUpdatesLabel")}
                  </Typography.Text>
                  {pendingConfigurationUpdates}
                </div>
              </div>
            </div>
            <div className={styles.statsItem}>
              <div>
                <Typography.Text className={styles.label} strong>
                  {WebHelper.formatMessage("DeviceManagementStats-deviceConnectionStatusLabel")}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.connected}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-connected", {connectedDevices})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.disconnected}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-disconnected", {disconnectedDevices})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.neverSeen}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-neverSeen", {neverSeenDevices})}
                </Typography.Text>
              </div>
            </div>
          </>
        )}
        {activeTab === Models.DeviceManagementTabKeys.Gateways && (
          <>
            <div className={styles.statsItem}>
              <div>
                <Typography.Text className={styles.label} strong>
                  {WebHelper.formatMessage("DeviceManagementStats-totalGatewaysLabel")}
                </Typography.Text>
                {totalGateways}
              </div>
            </div>
            <div className={styles.statsItem}>
              <div>
                <Typography.Text className={styles.label} strong>
                  {WebHelper.formatMessage("DeviceManagementStats-deviceConnectionStatusLabel")}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.up}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-up", {upGateways})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.down}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-down", {downGateways})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.intermittent}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-intermittent", {intermittentGateways})}
                </Typography.Text>
              </div>
              <div>
                <Typography.Text className={styles.label}>
                  <span className={`${styles.circleSolid} ${styles.neverSeen}`} />
                  {WebHelper.formatMessage("DeviceManagementStats-neverSeen", {neverSeenDevices: neverSeenGateways})}
                </Typography.Text>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
);
