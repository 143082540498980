import {UserOutlined} from "@ant-design/icons";
import {Dropdown, Avatar, Layout} from "antd";
import {ItemType} from "antd/lib/menu/hooks/useItems";
import companyIcon from "assets/images/common/Verve_Logo_Green.svg";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";
import {Link} from "react-router-dom";

import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";
import styles from "./Header.module.scss";

const {Header: HeaderAntd} = Layout;

export type HeaderProps = {
  currentUser: Models.User | undefined;
  deactivatedUser: boolean;
  accountMenuItems: ItemType[];
};

export const Header: FunctionComponent<HeaderProps> = observer((props) => {
  return (
    <HeaderAntd className={styles.header}>
      <Link className={styles.companyIconContainer} to={WebHelper.homePagePath()}>
        <img className={styles.companyIcon} src={companyIcon} alt="Logo" />
      </Link>

      {(props.currentUser || props.deactivatedUser) && (
        <Dropdown trigger={["click"]} placement="bottomRight" menu={{items: props.accountMenuItems}}>
          <div className={styles.avatarContainer} data-cy="accountMenuButton">
            <Avatar className={styles.avatar} icon={<UserOutlined />} />
          </div>
        </Dropdown>
      )}
    </HeaderAntd>
  );
});
