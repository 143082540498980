import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";

export class DeviceGroup extends BaseModel {
  firmware_groups: {
    available_firmware_versions: {
      id: string;
      name: string;
    }[];
    device_types: {
      id: string;
      name: string;
      devices: string[];
      count: number;
    }[];
    group_device_count: number;
  }[];

  constructor() {
    super();
    makeObservable(this, {
      firmware_groups: observable,
    });
  }
}
