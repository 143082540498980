import {useMutation} from "@tanstack/react-query";
import {Col, Input, Modal, Row, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../../core/stores/AuthenticationDataStore";
import {WorkerReportGroupDataStore} from "../../../../../../core/stores/WorkerReportGroupDataStore";
import {AppStore} from "../../../../../stores/AppStore";
import {WebHelper} from "../../../../../utils/WebHelper";
import {DiscardChangesModal} from "../../../../discard-changes-modal/DiscardChangesModal";
import styles from "./RenameWorkerGroupModal.module.scss";

type RenameWorkerGroupModalProps = {
  open: boolean;
  onOk: (workerGroup: Models.WorkerReportGroup) => void;
  onCancel: () => void;
  workerReportGroup: Models.WorkerReportGroup;
};

export const RenameWorkerGroupModal: FunctionComponent<RenameWorkerGroupModalProps> = observer(
  ({open, onOk, onCancel, workerReportGroup}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();
    const workerReportGroupStore = WorkerReportGroupDataStore.getInstance();

    const [newName, setNewName] = useState("");
    const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);

    useEffect(() => {
      if (workerReportGroup) {
        setNewName(workerReportGroup.name);
      }
    }, [workerReportGroup]);

    const updateWorkerGroupMutation = useMutation({
      mutationFn: () =>
        workerReportGroupStore.updateWorkerReportGroup({
          accessToken: authenticationStore.state.accessToken!,
          id: workerReportGroup.id,
          name: newName,
          site_id: workerReportGroup.site.id,
        }),
      onSuccess: (response) => {
        if (response.success) {
          appStore.showMessage(WebHelper.formatMessage("RenameWorkerGroupModal-successMessage"), "success");
          onOk(response.workerReportGroup);
        } else WebHelper.showErrorMessage(WebHelper.formatMessage("RenameWorkerGroupModal-errorMessage"), response.correlationId);
      },
    });

    const handleCancel = () => {
      if (updateWorkerGroupMutation.isPending) return;

      if (!isDiscardChangesModalVisible && newName !== workerReportGroup.name) setIsDiscardChangesModalVisible(true);
      else {
        setNewName(workerReportGroup.name);
        setIsDiscardChangesModalVisible(false);
        onCancel();
      }
    };

    return (
      <>
        <Modal
          centered
          zIndex={1001}
          destroyOnClose={true}
          open={open}
          title={WebHelper.formatMessage("RenameWorkerGroupModal-title")}
          okText={WebHelper.formatMessage("RenameWorkerGroupModal-rename")}
          cancelText={WebHelper.formatMessage("Common-cancel")}
          onOk={() => updateWorkerGroupMutation.mutate()}
          onCancel={handleCancel}
          okButtonProps={{
            shape: "round",
            disabled: newName === workerReportGroup.name || !newName || newName.length > 40,
            loading: updateWorkerGroupMutation.isPending,
          }}
          cancelButtonProps={{shape: "round", disabled: updateWorkerGroupMutation.isPending}}>
          <Row className={styles.row}>
            <Col span={5}>
              <Typography.Text>{WebHelper.formatMessage("RenameWorkerGroupModal-inputLabel")}</Typography.Text>
            </Col>
            <Col span={19}>
              <Input
                disabled={updateWorkerGroupMutation.isPending}
                value={newName}
                onChange={(event) => setNewName(event.currentTarget.value)}
              />
            </Col>
            {newName.length > 40 && (
              <Typography.Text type="danger">{WebHelper.formatMessage("RenameWorkerGroupModal-nameLengthValidator")}</Typography.Text>
            )}
          </Row>
        </Modal>
        <DiscardChangesModal
          onDiscard={handleCancel}
          onClose={() => setIsDiscardChangesModalVisible(false)}
          open={isDiscardChangesModalVisible}
        />
      </>
    );
  }
);
