import {Typography, Checkbox, Input, Space, Radio, Tooltip} from "antd";
import type {RadioChangeEvent} from "antd";
import {CheckboxValueType} from "antd/es/checkbox/Group";
import React, {FunctionComponent, useMemo} from "react";

import line from "../../../assets/images/common/Line.svg";
import stepsFinished from "../../../assets/images/common/Steps-Finished.svg";
import stepsFirst from "../../../assets/images/common/Steps-First.svg";
import stepsSecondProgress from "../../../assets/images/common/Steps-Second-Progress.svg";
import stepsSecondWaiting from "../../../assets/images/common/Steps-Second-Waiting.svg";
import * as Models from "../../../core/models";
import {WebHelper} from "../../utils/WebHelper";
import {UnenrollmentReasonsGlossary} from "../unenrollment-reasons-glossary/UnenrollmentReasonsGlossary";
import {ReasonCheckbox} from "./reason-checkbox/ReasonCheckbox";
import {ReasonRadio} from "./reason-radio/ReasonRadio";
import styles from "./UnenrollmentReasons.module.scss";

export type UnenrollmentReasonsProps = {
  worker: Models.BasicWorker;
  currentStep: Models.UnenrollmentStepsFlow;
  onCheckedValuesChange: (checkedValues: CheckboxValueType[]) => void;
  onOtherReasonValueChange: (otherReasonValue: string | undefined) => void;
  otherReasonValue: string | undefined;
  onChangeOtherMainReason: (reason: Models.UnenrollWorkerOtherReason | undefined) => void;
  otherMainReasonValue: Models.UnenrollWorkerOtherReason | undefined;
  disabled: boolean;
  mainReason: string | undefined;
  setGlossaryStep: () => void;
};

export const UnenrollmentReasons: FunctionComponent<UnenrollmentReasonsProps> = ({
  worker,
  onCheckedValuesChange,
  onOtherReasonValueChange,
  otherReasonValue,
  onChangeOtherMainReason,
  otherMainReasonValue,
  disabled,
  currentStep,
  mainReason,
  setGlossaryStep,
}) => {
  const reasonOption = (value: Models.UnenrollWorkerOtherReason, tooltip?: string) => {
    return (
      <Tooltip placement="right" title={tooltip} mouseEnterDelay={2}>
        <div>
          {currentStep === Models.UnenrollmentStepsFlow.first ? (
            <ReasonRadio value={value} />
          ) : (
            <ReasonCheckbox disabled={value === mainReason} value={value} />
          )}
        </div>
      </Tooltip>
    );
  };

  const reasonOptions = useMemo(
    () => (
      <Space direction="vertical" size={26}>
        <Space direction="horizontal" align="start" size={40} className={styles.optionsRow}>
          <Space direction="vertical" className={styles.optionsColumn} key="UnenrollWorkerOtherReason-discomfort">
            <Typography.Title className={styles.checkboxOptionsTitle}>
              {WebHelper.formatMessage("UnenrollWorkerOtherReason-discomfort")}
            </Typography.Title>

            {reasonOption(Models.UnenrollWorkerOtherReason.Back, WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionBack"))}
            {reasonOption(Models.UnenrollWorkerOtherReason.Heat, WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionHeat"))}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.Constriction,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionConstriction")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.General,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionGeneral")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.HeavyShoulderPressure,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionSuitHeavyShoulderPressure")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.ShoulderPointPressure,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionShoulderStrapIrritationPointPressure")
            )}
          </Space>

          <Space direction="vertical" className={styles.optionsColumn} key="UnenrollWorkerOtherReason-suitLimitations">
            <Typography.Title className={styles.checkboxOptionsTitle}>
              {WebHelper.formatMessage("UnenrollWorkerOtherReason-suitLimitations")}
            </Typography.Title>
            {reasonOption(
              Models.UnenrollWorkerOtherReason.LimitsOverheadReaching,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionLimitsOverheadReaching")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.ThighWrapsDropping,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionThighWrapsDropping")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.ExtraHeightWidth,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionExtraHeightWidthOfSuitOnBack")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.AssistanceTimeWrong,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionAssistanceTimingWrongJerky")
            )}
          </Space>
        </Space>

        <Space direction="horizontal" align="start" size={40} className={styles.optionsRow}>
          <Space direction="vertical" className={styles.optionsColumn} key="UnenrollWorkerOtherReason-convenience">
            <Typography.Title className={styles.checkboxOptionsTitle}>
              {WebHelper.formatMessage("UnenrollWorkerOtherReason-convenience")}
            </Typography.Title>
            {reasonOption(
              Models.UnenrollWorkerOtherReason.NoNeed,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionDoesntNeedIt")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.TimeHabit,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionTimeHabit")
            )}
          </Space>

          <Space direction="vertical" className={styles.optionsColumn} key="UnenrollWorkerOtherReason-job">
            <Typography.Title className={styles.checkboxOptionsTitle}>
              {WebHelper.formatMessage("UnenrollWorkerOtherReason-job")}
            </Typography.Title>
            {reasonOption(
              Models.UnenrollWorkerOtherReason.JobFunctionChange,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionJobFunctionChange")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.PerformanceConcern,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionPerformanceProductivityConcerns")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.BadUseCase,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionJobIsNotGoodUseCase")
            )}
          </Space>
        </Space>

        <Space direction="horizontal" align="start" size={40} className={styles.optionsRow}>
          <Space direction="vertical" className={styles.optionsColumn} key="UnenrollWorkerOtherReason-personal">
            <Typography.Title className={styles.checkboxOptionsTitle}>
              {WebHelper.formatMessage("UnenrollWorkerOtherReason-personal")}
            </Typography.Title>
            {reasonOption(
              Models.UnenrollWorkerOtherReason.OnLeave,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionOnLeave")
            )}
            {reasonOption(
              Models.UnenrollWorkerOtherReason.PreExistingCondition,
              WebHelper.formatMessage("UnenrollmentReasonsGlossary-descriptionPreexistingCondition")
            )}
          </Space>

          <div>
            <Typography.Title className={styles.checkboxOptionsTitle}>
              {WebHelper.formatMessage("UnenrollmentReason-other")}
            </Typography.Title>
            <Input
              className={styles.otherReasonInput}
              disabled={disabled}
              placeholder={WebHelper.formatMessage("Common-typeHere")}
              value={otherReasonValue}
              onChange={(event) => {
                if (currentStep === Models.UnenrollmentStepsFlow.first) onChangeOtherMainReason(undefined);
                onOtherReasonValueChange(event.currentTarget.value);
              }}
            />
          </div>
        </Space>
      </Space>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentStep, disabled, onChangeOtherMainReason, onOtherReasonValueChange, otherReasonValue]
  );

  const onChangeMainReason = (e: RadioChangeEvent) => {
    onChangeOtherMainReason(e.target.value);
    onOtherReasonValueChange(undefined);
  };

  const workerIdentifierText = worker.fullName ?? worker.employee_id;

  return (
    <>
      {currentStep !== Models.UnenrollmentStepsFlow.zero && (
        <>
          {currentStep === Models.UnenrollmentStepsFlow.glossary ? (
            <UnenrollmentReasonsGlossary />
          ) : (
            <>
              <Space className={styles.stepsWrapper}>
                <Space className={styles.stepWrapper}>
                  <Space>
                    <img src={currentStep === Models.UnenrollmentStepsFlow.first ? stepsFirst : stepsFinished} alt="" />
                    <Typography.Text>
                      {currentStep === Models.UnenrollmentStepsFlow.first
                        ? WebHelper.formatMessage("UnenrollmentReasons-inProgress")
                        : WebHelper.formatMessage("UnenrollmentReasons-finished")}
                    </Typography.Text>
                  </Space>
                  <Typography
                    className={`${currentStep === Models.UnenrollmentStepsFlow.second ? styles.waitingText : ""} ${styles.reasonText}`}>
                    {WebHelper.formatMessage("UnenrollmentReasons-primaryReason")}
                  </Typography>
                </Space>
                <img src={line} alt="" className={styles.lineSeparator} />
                <Space className={styles.stepWrapper}>
                  <Space>
                    <img src={currentStep === Models.UnenrollmentStepsFlow.first ? stepsSecondWaiting : stepsSecondProgress} alt="" />
                    <Typography.Text className={currentStep === Models.UnenrollmentStepsFlow.first ? styles.waitingText : ""}>
                      {currentStep === Models.UnenrollmentStepsFlow.first
                        ? WebHelper.formatMessage("UnenrollmentReasons-waiting")
                        : WebHelper.formatMessage("UnenrollmentReasons-inProgress")}
                    </Typography.Text>
                  </Space>
                  <Typography.Text
                    className={`${currentStep === Models.UnenrollmentStepsFlow.first ? styles.waitingText : ""} ${styles.reasonText}`}>
                    {WebHelper.formatMessage("UnenrollmentReasons-sceondaryReasons")}
                  </Typography.Text>
                </Space>
              </Space>
              <Typography.Text>
                <div
                  className={styles.subtitle}
                  dangerouslySetInnerHTML={{
                    __html: WebHelper.parseMarkdown(
                      currentStep === Models.UnenrollmentStepsFlow.first
                        ? WebHelper.formatMessage("UnenrollmentReasons-subtitleFirstStep", {workerIdentifier: workerIdentifierText})
                        : WebHelper.formatMessage("UnenrollmentReasons-subtitleSecondStep", {workerIdentifier: workerIdentifierText})
                    ),
                  }}
                />
              </Typography.Text>
              <div className={styles.glossaryTextWrapper}>
                {window.innerWidth > 1200 ? WebHelper.formatMessage("UnenrollmentReasons-openGlossaryExtraDesktop") : ""}{" "}
                <Typography.Text className={styles.glossaryText} underline strong onClick={setGlossaryStep}>
                  {WebHelper.formatMessage("UnenrollmentReasons-openGlossary")}
                </Typography.Text>
              </div>
            </>
          )}
        </>
      )}

      {currentStep === Models.UnenrollmentStepsFlow.second && (
        <Checkbox.Group disabled={disabled} onChange={onCheckedValuesChange}>
          {reasonOptions}
        </Checkbox.Group>
      )}
      {currentStep === Models.UnenrollmentStepsFlow.first && (
        <Radio.Group disabled={disabled} value={otherMainReasonValue} onChange={onChangeMainReason}>
          {reasonOptions}
        </Radio.Group>
      )}
    </>
  );
};
