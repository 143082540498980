import EventEmitter from "eventemitter3";
import * as _ from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";

import {DeviceAPIClient} from "../apiclient/device/DeviceAPIClient";
import * as DeviceTypes from "../apiclient/device/DeviceAPIClient.types";
import * as Models from "../models";

export type DevicesState = {
  loadingDevices: boolean;
  loadingDevice: boolean;
  devices?: Models.Device[];
};

export type DeviceEvent = {
  "device-created": [Models.Device];
  "device-updated": [Models.Device];
  "device-configuration-updated": [Models.Device];
  "device-deleted": [string];
  "devices-created": [Models.Device[]];
};

export class DeviceDataStore {
  private static instance: DeviceDataStore | undefined;

  private initialState: DevicesState = {
    loadingDevices: false,
    loadingDevice: false,
  };

  state = _.cloneDeep(this.initialState);

  eventEmitter: EventEmitter<DeviceEvent>;

  private constructor() {
    makeObservable<DeviceDataStore>(this, {
      state: observable,
      devices: action,
      device: action,
      createDevice: action,
      updateDevice: action,
      deleteDevice: action,
      allocateDeviceToDepartment: action,
      allocateDevicesToDepartment: action,
      assignWorkerToDevice: action,
      bulkCreateDevices: action,
      archiveDevice: action,
    });
  }

  static getInstance(): DeviceDataStore {
    if (!DeviceDataStore.instance) DeviceDataStore.instance = new DeviceDataStore();

    return DeviceDataStore.instance;
  }

  initialize() {
    this.eventEmitter = new EventEmitter();
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
    this.eventEmitter?.removeAllListeners();
  }

  async devices(request: DeviceTypes.DevicesRequest): Promise<DeviceTypes.DevicesResponse> {
    this.state.loadingDevices = true;

    const response = await DeviceAPIClient.devices(request);

    runInAction(() => {
      this.state.loadingDevices = false;
      if (response.success && !_.isNil(response.devices)) this.state.devices = response.devices;
    });

    return response;
  }

  async devicesShort(request: DeviceTypes.DevicesRequest): Promise<DeviceTypes.DevicesShortResponse> {
    this.state.loadingDevices = true;

    const response = await DeviceAPIClient.devicesShort(request);

    runInAction(() => {
      this.state.loadingDevices = false;
    });

    return response;
  }

  async device(request: DeviceTypes.DeviceRequest): Promise<DeviceTypes.DeviceResponse> {
    this.state.loadingDevice = true;

    const response = await DeviceAPIClient.device(request);

    runInAction(() => {
      this.state.loadingDevice = false;
      if (response.success && !_.isNil(response.device)) this.state.devices = [response.device];
    });

    return response;
  }

  async createDevice(request: DeviceTypes.CreateDeviceRequest): Promise<DeviceTypes.CreateDeviceResponse> {
    const response = await DeviceAPIClient.createDevice(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("device-created", response.device);
      }
    });

    return response;
  }

  async deleteDevice(request: DeviceTypes.DeleteDeviceRequest): Promise<DeviceTypes.DeleteDeviceResponse> {
    const response = await DeviceAPIClient.deleteDevice(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("device-deleted", request.id);
      }
    });

    return response;
  }

  async updateDevice(request: DeviceTypes.UpdateDeviceRequest): Promise<DeviceTypes.UpdateDeviceResponse> {
    const response = await DeviceAPIClient.updateDevice(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("device-updated", response.device);
      }
    });

    return response;
  }

  async updateDeviceConfiguration(
    request: DeviceTypes.UpdateDeviceConfigurationRequest
  ): Promise<DeviceTypes.UpdateDeviceConfigurationResponse> {
    const response = await DeviceAPIClient.updateDeviceConfiguration(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("device-configuration-updated", response.device);
      }
    });

    return response;
  }

  async allocateDeviceToDepartment(
    request: DeviceTypes.AllocateDeviceToDepartmentRequest
  ): Promise<DeviceTypes.AllocateDeviceToDepartmentResponse> {
    const response = await DeviceAPIClient.allocateDeviceToDepartment(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("device-updated", response.device);
      }
    });

    return response;
  }

  async allocateDevicesToDepartment(
    request: DeviceTypes.AllocateDevicesToDepartmentRequest
  ): Promise<DeviceTypes.AllocateDevicesToDepartmentResponse> {
    const response = await DeviceAPIClient.allocateDevicesToDepartment(request);

    return response;
  }

  async assignWorkerToDevice(request: DeviceTypes.AssignWorkerToDeviceRequest): Promise<DeviceTypes.AssignWorkerToDeviceResponse> {
    const response = await DeviceAPIClient.assignWorkerToDevice(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("device-updated", response.device);
      }
    });

    return response;
  }

  async bulkCreateDevices(request: DeviceTypes.BulkCreateDevicesRequest): Promise<DeviceTypes.BulkCreateDevicesResponse> {
    const response = await DeviceAPIClient.bulkCreateDevices(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("devices-created", response.devices);
      }
    });

    return response;
  }

  async archiveDevice(request: DeviceTypes.ArchiveDeviceRequest): Promise<DeviceTypes.ArchiveDeviceResponse> {
    return await DeviceAPIClient.archiveDevice(request);
  }

  async deviceGroups(request: DeviceTypes.DeviceGroupsRequest): Promise<DeviceTypes.DeviceGroupsResponse> {
    return await DeviceAPIClient.deviceGroups(request);
  }

  async deviceSearchTree(request: DeviceTypes.DeviceSearchTreeRequest): Promise<DeviceTypes.DeviceSearchTreeResponse> {
    return await DeviceAPIClient.deviceSearchTree(request);
  }

  async bulkUpdate(request: DeviceTypes.DeviceBulkUpdateRequest): Promise<DeviceTypes.DeviceBulkUpdateResponse> {
    return await DeviceAPIClient.bulkUpdate(request);
  }

  async deviceCheck(request: DeviceTypes.DeviceCheckRequest): Promise<DeviceTypes.DeviceCheckResponse> {
    return await DeviceAPIClient.deviceCheck(request);
  }

  async createDeviceBulk(request: DeviceTypes.CreateDeviceBulkRequest): Promise<DeviceTypes.CreateDeviceBulkResponse> {
    return await DeviceAPIClient.createDeviceBulk(request);
  }
}
