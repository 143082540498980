import {useMutation} from "@tanstack/react-query";
import {Modal} from "antd";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../../core/stores/DeviceDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint, SegmentResourceType} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";

export type ChangeDeviceModalProps = {
  device: Models.DeviceShort;
  open: boolean;
  newWorker?: Models.WorkerShort;
  onClose: () => void;
  onChangeWorker: () => void;
  setShowWorkerNoLongerDevice: (showWorkerNoLongerDevice: boolean) => void;
};

export const ChangeWorkerModal: FunctionComponent<ChangeDeviceModalProps> = observer(
  ({device, open, newWorker, onClose, onChangeWorker, setShowWorkerNoLongerDevice}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();
    const deviceStore = DeviceDataStore.getInstance();

    const assignWorkerToDeviceMutation = useMutation({
      mutationFn: () =>
        deviceStore.assignWorkerToDevice({
          accessToken: authenticationStore.state.accessToken!,
          worker_id: newWorker?.id ?? null,
          assignable: true,
          device_id: device.id,
        }),
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("ChangeWorkerModal-assignError"), response.correlationId);
        } else {
          appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceAssignment, {
            departmentID: response.device?.department?.id,
            orgID: response.device?.organization?.id,
            siteID: response.device.site?.id,
            resourceType: SegmentResourceType.DEVICE,
            resourceID: response.device.id,
            endAssigned: !!response.device.assigned_worker,
            startAssigned: !!device.assigned_worker,
            entryPoint: SegmentEntryPoint.DEVICE_TABLE,
          });

          let message;
          if (!device?.assigned_worker) {
            message = WebHelper.formatMessage("ChangeWorkerModal-assignWorkerSuccessMessage", {
              workerName: newWorker!.displayName,
              deviceTag: device.device_tag,
            });
            appStore.showMessage(message, "success");
          }

          if (
            (_.isNil(response.device.assigned_worker) && device.assigned_worker) ||
            (!_.isNil(response.device.assigned_worker) &&
              !_.isNil(device.assigned_worker) &&
              response.device.assigned_worker.id !== device.assigned_worker.id)
          ) {
            setShowWorkerNoLongerDevice(true);
          }
          onClose();
          onChangeWorker();
        }
      },
    });

    const modalTitle = useMemo(() => {
      if (!device?.assigned_worker) {
        return WebHelper.formatMessage("ChangeWorkerModal-assignWorker");
      } else {
        if (!newWorker) return WebHelper.formatMessage("ChangeWorkerModal-unassignWorker");
        else return WebHelper.formatMessage("ChangeWorkerModal-changeWorker");
      }
    }, [device?.assigned_worker, newWorker]);

    const modalMessage = useMemo(() => {
      if (!device?.assigned_worker) {
        return WebHelper.formatMessage("ChangeWorkerModal-assignWorkerMessage", {
          workerName: newWorker!.displayName,
          deviceTag: device.device_tag,
        });
      } else {
        if (!newWorker)
          return WebHelper.formatMessage("ChangeWorkerModal-unassignWorkerMessage", {
            workerName: device.assigned_worker.displayName,
            deviceTag: device.device_tag,
          });
        else
          return WebHelper.formatMessage("ChangeWorkerModal-changeWorkerMessage", {
            workerName: device.assigned_worker.displayName,
            deviceTag: device.device_tag,
            newWorkerName: newWorker.displayName,
          });
      }
    }, [device.assigned_worker, device.device_tag, newWorker]);

    return (
      <Modal
        centered
        open={open}
        destroyOnClose={true}
        title={modalTitle}
        okText={WebHelper.formatMessage("Common-confirm")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={() => assignWorkerToDeviceMutation.mutate()}
        cancelButtonProps={{shape: "round", disabled: assignWorkerToDeviceMutation.isPending}}
        okButtonProps={{
          shape: "round",
          disabled: assignWorkerToDeviceMutation.isPending,
          loading: assignWorkerToDeviceMutation.isPending,
        }}
        onCancel={onClose}>
        {
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(modalMessage),
            }}
          />
        }
      </Modal>
    );
  }
);
