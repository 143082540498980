module.exports = {
  colors: {
    primaryColor: "#F04A1D",
    secondaryColor: "#E6E5E3",
    auxiliaryColor: "#C2FF7D",
    antdPrimaryBlack: "#323435", // $ol-secondaryColor-3
    antdSelectedItemBackground: "#dcdcdd", // $ol-secondaryColor-6
    singleColorChart: "#D8431AB2",
    singleColorChartLessOpacity: "#D8431A4C", // 30% opacity
    secondarySingleColorChart: "#50D10066",
    secondarySingleColorChartLessOpacity: "#50D10033", // 30% opacity
    chart: [
      "#38584BB2",
      "#0076A8B2",
      "#B7DB57B2",
      "#38584B",
      "#FD7F6F",
      "#808080",
      "#0076A8",
      "#B7DB57",
      "#FFCE54",
      "#DCDCDD",
      "#FFCE54B2",
      "#F58061B2",
    ],
    progressBar: {
      red: "#C03B17",
      green: "#55A000",
      yellow: "#FFCE54",
    },
  },
};
