import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js";
import {addDays, addMonths, endOfMonth, addQuarters, addWeeks, differenceInDays, format, startOfDay, startOfMonth} from "date-fns";
import {observer} from "mobx-react-lite";
import React, {ForwardedRef, FunctionComponent, useMemo, useState} from "react";
import {Bar} from "react-chartjs-2";
import {ChartJSOrUndefined} from "react-chartjs-2/dist/types";

import * as Models from "../../../core/models";
import {AppStore} from "../../stores/AppStore";
import {lbsToKgs, WebHelper} from "../../utils/WebHelper";
import styles from "./AnalyticsChart.module.scss";

const constants = require("assets/stylesheets/constants");

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const chartPlugin = {
  id: "whiteBackground",
  beforeDraw: (chart: ChartJS) => {
    const context = chart.canvas.getContext("2d");
    if (context) {
      context.save();
      context.globalCompositeOperation = "destination-over";
      context.fillStyle = "white";
      context.fillRect(0, 0, chart.width, chart.height);
      context.restore();
    }
  },
};

enum ChartDataType {
  Raw = "raw",
  Percentage = "percentage",
}

const maxIntervalNumber = 30;

export type AnalyticsChartProps = {
  data: Models.AnalyticsTimeSeries[];
  variableSelected: Models.AnalyticsVariableToChart;
  intervalSelected: Models.AnalyticsInterval;
  selectedMovements: Models.ChartMovementType[];
  safetyTypesSelected: Models.ChartMovementType[];
  selectedWorkerStatuses: Models.WorkerStatusType[];
  dataType: ChartDataType;
  startDate: Date | null;
  endDate: Date | null;
  displayMaxData: boolean;
  timeZone?: string;
  chartRef: ForwardedRef<ChartJSOrUndefined<"bar", number[], string>> | undefined;
  metricMeasurementUnits?: boolean;
};

export const AnalyticsChart: FunctionComponent<AnalyticsChartProps> = observer(
  ({
    data,
    variableSelected,
    intervalSelected,
    selectedMovements,
    selectedWorkerStatuses,
    safetyTypesSelected,
    dataType,
    timeZone,
    displayMaxData,
    startDate,
    endDate,
    chartRef,
    metricMeasurementUnits,
  }) => {
    const appStore = AppStore.getInstance();
    const [displayMaxDataIntervalsNumber, setDisplayMaxDataIntervalsNumber] = useState(maxIntervalNumber);
    const [firstNonZeroValueIndex, setFirstNonZeroValueIndex] = useState(0);

    const chartLabel = useMemo(() => {
      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.HoursUsed:
          return WebHelper.formatMessage("AnalyticsChart-hoursUsed");
        case Models.AnalyticsVariableToChart.WeightOffloaded:
          return WebHelper.formatMessage("AnalyticsChart-weightOffloaded");
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          return WebHelper.formatMessage("AnalyticsChart-movementBreakdown");
        default:
          return "";
      }
    }, [variableSelected]);

    const chartVariable = useMemo(() => {
      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.HoursUsed:
          return "usage_sec";
        case Models.AnalyticsVariableToChart.WeightOffloaded:
          return "weight_offloaded_lbs";
        default:
          return "";
      }
    }, [variableSelected]);

    const chartUnit = useMemo(() => {
      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.WorkerActiveStatus:
          if (dataType === ChartDataType.Percentage) return WebHelper.formatMessage("AnalyticsChart-percentageOfWorkers");
          else return WebHelper.formatMessage("AnalyticsChart-workersUnit");
        case Models.AnalyticsVariableToChart.Lifts:
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          if (dataType === ChartDataType.Percentage) return WebHelper.formatMessage("AnalyticsChart-percentageOfLifts");
          else return WebHelper.formatMessage("AnalyticsChart-liftsUnit");
        case Models.AnalyticsVariableToChart.HoursUsed:
          return WebHelper.formatMessage("AnalyticsChart-hoursUnit");
        case Models.AnalyticsVariableToChart.WeightOffloaded:
          return WebHelper.formatMessage(
            metricMeasurementUnits ? "AnalyticsChart-weightOffloadedUnitKgs" : "AnalyticsChart-weightOffloadedUnitLbs"
          );
        default:
          return "";
      }
    }, [variableSelected, dataType, metricMeasurementUnits]);

    const calculateMaxDataIntervalsNumber = (
      datasets: {
        label: string;
        data: any[];
        backgroundColor: any;
      }[]
    ) => {
      // for every object in dataset, takes the index of the first non zero value in data
      const firstValueIndexes = datasets.map((dataset) => {
        const firstNonZeroValueIndex = dataset.data.findIndex((value) => value !== 0);
        setFirstNonZeroValueIndex(firstNonZeroValueIndex === -1 ? maxIntervalNumber : firstNonZeroValueIndex);
        return firstNonZeroValueIndex === -1 ? maxIntervalNumber : firstNonZeroValueIndex;
      });
      const intervalsNumber = maxIntervalNumber - Math.min(...firstValueIndexes);

      // sets and returns the number of intervals to show in graph (max number minus the number of intervals that would be empty)
      setDisplayMaxDataIntervalsNumber(intervalsNumber);
      return intervalsNumber;
    };

    const sliceDatasets = (
      datasets: {
        label: string;
        data: any[];
        backgroundColor: any;
      }[]
    ) => {
      // calculates the number of intervals to show in graph and slices the data to fit that
      const intervalsNumber = calculateMaxDataIntervalsNumber(datasets);
      datasets.forEach((dataset) => {
        dataset.data = dataset.data.slice(-intervalsNumber);
      });
    };

    const datasets = useMemo(() => {
      const result = [];

      switch (variableSelected) {
        case Models.AnalyticsVariableToChart.WorkerActiveStatus: {
          if (selectedWorkerStatuses.includes(Models.WorkerStatusType.Active)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-activeWorkers"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.active_workers : analyticHistory.activeWorkersPercentage
              ),
              backgroundColor: constants.colors.chart[2],
            });
          }

          if (selectedWorkerStatuses.includes(Models.WorkerStatusType.Inactive)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-inactiveWorkers"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.inactiveWorkers : analyticHistory.inactiveWorkersPercentage
              ),
              backgroundColor: constants.colors.chart[10],
            });
          }

          if (displayMaxData) {
            sliceDatasets(result);
          }
          return result;
        }
        case Models.AnalyticsVariableToChart.Lifts:
          if (safetyTypesSelected.includes(Models.ChartMovementType.Risky)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-risky"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.riskyLifts : analyticHistory.riskyLiftsPercentage
              ),
              backgroundColor: constants.colors.chart[11],
            });
          }

          if (safetyTypesSelected.includes(Models.ChartMovementType.Safe)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-safe"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.safe_lifts : analyticHistory.safeLiftsPercentage
              ),
              backgroundColor: constants.colors.chart[1],
            });
          }

          if (displayMaxData) {
            sliceDatasets(result);
          }
          return result;
        case Models.AnalyticsVariableToChart.MovementBreakdown:
          if (selectedMovements.includes(Models.ChartMovementType.ExcessiveForwardBending)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-excessiveForwardBending"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.excessive_forward_lifts : analyticHistory.excessiveForwardLiftsPercentage
              ),
              backgroundColor: constants.colors.chart[0],
            });
          }

          if (selectedMovements.includes(Models.ChartMovementType.ProlongedBending)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-prolongedBending"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.prolonged_bend_lifts : analyticHistory.prolongedBendLiftsPercentage
              ),
              backgroundColor: constants.colors.chart[1],
            });
          }

          if (selectedMovements.includes(Models.ChartMovementType.Twisting)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-twisting"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.twisted_lifts : analyticHistory.twistedLiftsPercentage
              ),
              backgroundColor: constants.colors.chart[2],
            });
          }

          if (selectedMovements.includes(Models.ChartMovementType.SideBending)) {
            result.push({
              label: WebHelper.formatMessage("AnalyticsChart-datasetLabel-sideBending"),
              data: data.map((analyticHistory) =>
                dataType === ChartDataType.Raw ? analyticHistory.side_bend_lifts : analyticHistory.sideBendLiftsPercentage
              ),
              backgroundColor: constants.colors.chart[10],
            });
          }

          if (displayMaxData) {
            sliceDatasets(result);
          }
          return result;
        default:
          result.push({
            label: chartLabel,
            data: data.map((analyticHistory) => {
              switch (variableSelected) {
                case Models.AnalyticsVariableToChart.HoursUsed:
                  return analyticHistory[chartVariable] / 3600;
                case Models.AnalyticsVariableToChart.WeightOffloaded:
                  return metricMeasurementUnits ? lbsToKgs(analyticHistory[chartVariable]) : analyticHistory[chartVariable];
                default:
                  return analyticHistory[chartVariable];
              }
            }),
            backgroundColor: constants.colors.singleColorChart,
          });
          if (displayMaxData) {
            sliceDatasets(result);
          }
          return result;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
      variableSelected,
      selectedMovements,
      chartLabel,
      data,
      selectedWorkerStatuses,
      displayMaxData,
      dataType,
      chartVariable,
      safetyTypesSelected,
    ]);

    const labels = useMemo(() => {
      const labels = [];
      if ((startDate || displayMaxData) && endDate) {
        const numberOfIntervals = displayMaxData
          ? displayMaxDataIntervalsNumber
          : intervalSelected === Models.AnalyticsInterval.Week
            ? Math.round(differenceInDays(endDate, startDate!) / 7)
            : intervalSelected === Models.AnalyticsInterval.Month
              ? Math.round(differenceInDays(endDate, startDate!) / 30)
              : intervalSelected === Models.AnalyticsInterval.Quarter
                ? Math.round(differenceInDays(endDate, startDate!) / 90)
                : differenceInDays(endDate, startOfDay(startDate!)) + 1;
        const dateFormat =
          intervalSelected === Models.AnalyticsInterval.Month
            ? WebHelper.formatMessage("Common-dateFormatMonthYear")
            : intervalSelected === Models.AnalyticsInterval.Quarter
              ? WebHelper.formatMessage("Common-dateFormatQuarterYear")
              : intervalSelected === Models.AnalyticsInterval.Day
                ? WebHelper.formatMessage("Common-dateFormatMonthDayOnly")
                : appStore.isMobile()
                  ? WebHelper.formatMessage("Common-dateFormatMonthDay")
                  : WebHelper.formatMessage("Common-dateFormatMonthDayOnly");

        for (let i = 0; i < numberOfIntervals; i++) {
          const endIntervalDate =
            intervalSelected === Models.AnalyticsInterval.Week
              ? addWeeks(endDate, i * -1)
              : intervalSelected === Models.AnalyticsInterval.Month
                ? endOfMonth(addMonths(startOfMonth(endDate), i * -1))
                : addQuarters(endDate, i * -1);

          const startIntervalDate =
            intervalSelected === Models.AnalyticsInterval.Day
              ? addDays(endDate, i * -1)
              : intervalSelected === Models.AnalyticsInterval.Week
                ? addDays(addWeeks(endDate, i * -1 - 1), 1)
                : intervalSelected === Models.AnalyticsInterval.Month
                  ? startOfMonth(addMonths(startOfMonth(endDate), i * -1))
                  : addDays(addQuarters(endDate, i * -1 - 1), 1);
          const label =
            intervalSelected !== Models.AnalyticsInterval.Week
              ? format(startIntervalDate, dateFormat)
              : appStore.isMobile()
                ? `${format(startIntervalDate, dateFormat)}-${format(endIntervalDate, dateFormat)}`
                : `${format(startIntervalDate, dateFormat)} - ${format(endIntervalDate, dateFormat)}`;

          labels.unshift(label);
        }
      }
      return labels;
    }, [startDate, displayMaxData, endDate, displayMaxDataIntervalsNumber, intervalSelected, appStore]);

    return (
      <div className={styles.chartWrapper}>
        <div className={styles.chartContainer}>
          <Bar
            ref={chartRef}
            plugins={[chartPlugin]}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              interaction: {
                mode: "index",
                intersect: false,
                axis: "x",
              },
              plugins: {
                title: {
                  display: false,
                },
                legend: {
                  display:
                    variableSelected === Models.AnalyticsVariableToChart.Lifts ||
                    variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus ||
                    variableSelected === Models.AnalyticsVariableToChart.MovementBreakdown,
                },
                tooltip: {
                  callbacks: {
                    beforeBody: (tooltipItems): string | string[] => {
                      switch (variableSelected) {
                        case Models.AnalyticsVariableToChart.WorkerActiveStatus:
                          const dataIndexWorkers = displayMaxData
                            ? tooltipItems[0].dataIndex + firstNonZeroValueIndex
                            : tooltipItems[0].dataIndex;
                          const assigned = data[dataIndexWorkers].assigned_workers;
                          return WebHelper.formatMessage("AnalyticsChart-tooltip-totalWorkers", {assigned});
                        case Models.AnalyticsVariableToChart.Lifts:
                        case Models.AnalyticsVariableToChart.MovementBreakdown:
                          const dataIndexLifts = displayMaxData
                            ? tooltipItems[0].dataIndex + firstNonZeroValueIndex
                            : tooltipItems[0].dataIndex;
                          const total = data[dataIndexLifts].total_lifts;
                          return WebHelper.formatMessage("AnalyticsChart-tooltip-totalLifts", {total});
                        default:
                          return "";
                      }
                    },
                    label: (item) => `${item.dataset.label}: ${item.formattedValue} ${chartUnit}`,
                  },
                },
              },
              scales: {
                y: {
                  ticks: {
                    callback: function (value) {
                      return `${value}`;
                    },
                    font: {size: 14},
                  },
                  title: {
                    display: true,
                    text: chartUnit,
                  },
                  stacked:
                    variableSelected === Models.AnalyticsVariableToChart.Lifts ||
                    variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus,
                },
                x: {
                  stacked:
                    variableSelected === Models.AnalyticsVariableToChart.Lifts ||
                    variableSelected === Models.AnalyticsVariableToChart.WorkerActiveStatus,
                  ticks: {
                    autoSkip: false,
                    font: {size: 14},
                  },
                },
              },
            }}
            data={{
              labels: labels,
              datasets,
            }}
          />
        </div>
      </div>
    );
  }
);
