import {useQuery} from "@tanstack/react-query";
import {Cascader, Radio, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../../core/stores/DeviceDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {DeviceLocationData} from "../bulk-update-step-two/BulkUpdateStepTwo";
import styles from "./BulkUpdateStepOne.module.scss";

enum RadioOptions {
  AllDevices = "allDevices",
  ManualDevices = "manualDevices",
}

type CascaderOption = {
  value: string;
  label: string;
  children?: CascaderOption[];
  disabled?: boolean;
  isLeaf?: boolean;
  loading?: boolean;
};

export type BulkUpdateStepOneProps = {
  updateLocationData: (a: DeviceLocationData[]) => void;
  deviceLocationData: DeviceLocationData[];
};

export const BulkUpdateStepOne: FunctionComponent<BulkUpdateStepOneProps> = observer(({deviceLocationData, updateLocationData}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const [selectedRadioOption, setSelectedRadioOption] = useState<RadioOptions>(RadioOptions.AllDevices);
  const [cascaderOptions, setCascaderOptions] = useState<CascaderOption[]>([]);
  const [cascaderOptionsDevices, setCascaderOptionsDevices] = useState<CascaderOption[]>([]);

  const devicesQuery = useQuery({
    queryKey: ["BulkUpdateStepOne-fetchDevicesData"],
    queryFn: () => deviceStore.deviceSearchTree({accessToken: authenticationStore.state.accessToken!}),
  });

  useEffect(() => {
    if (!devicesQuery.data) return;
    if (devicesQuery.data.success && devicesQuery.data.success) {
      const deviceOptions = [
        ...devicesQuery.data.organizations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((org) => ({
            value: org.id,
            label: org.name,
            children: org.site_ids
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((site) => ({
                value: site.id,
                label: site.name,
                children: site.department_ids
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((department) => ({
                    value: department.id,
                    label: department.name,
                    children: department.device_ids.map((device) => ({value: device.id, label: device.name, isLeaf: true})),
                    isLeaf: false,
                  })),
              })),
          })),
      ];

      const options = [
        {value: "all", label: WebHelper.formatMessage("BulkUpdateStepOne-allOrgs")},
        ...devicesQuery.data.organizations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((org) => ({
            value: org.id,
            label: org.name,
            children: org.site_ids
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((site) => ({
                value: site.id,
                label: site.name,
                children: site.department_ids
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((department) => ({
                    value: department.id,
                    label: department.name,
                    isLeaf: true,
                  })),
              })),
          })),
      ];

      setCascaderOptionsDevices(deviceOptions);
      setCascaderOptions(options);
    }
  }, [devicesQuery.data, devicesQuery.isFetching, selectedRadioOption]);

  const handleChange = (values: any, selectedOptions: any) => {
    let allOrgs = false;

    values.forEach((value: any) => {
      if (value.includes("all")) allOrgs = true;
    });

    if (allOrgs && devicesQuery.data?.success) {
      const locationData: DeviceLocationData[] = [];
      devicesQuery.data.organizations.forEach((org) => {
        const data: DeviceLocationData = {pathNames: [], pathIds: [], type: ""};
        data.pathNames.push(org.name);
        data.pathIds.push(org.id);
        data.type = "org";
        locationData.push(data);
      });
      updateLocationData(locationData);
    } else {
      const locationData = selectedOptions.map((selectedOption: any) => {
        const data: DeviceLocationData = {pathNames: [], pathIds: [], type: ""};

        selectedOption.forEach((option: any) => {
          data.pathNames.push(option.label);
          data.pathIds.push(option.value);
        });
        //cascader, deep = type.
        data.type =
          selectedOption.length === 4
            ? "device"
            : selectedOption.length === 3
              ? "department"
              : selectedOption.length === 2
                ? "site"
                : "org";
        return data;
      });
      updateLocationData(locationData);
    }
  };

  return (
    <Space className={styles.wrapper}>
      <Typography.Text>{WebHelper.formatMessage("BulkUpdateStepOne-selectDevices")}</Typography.Text>
      <Radio.Group
        className={styles.radioGroup}
        value={selectedRadioOption}
        onChange={(event) => {
          setSelectedRadioOption(event.target.value);
          updateLocationData([]);
        }}>
        <Space direction="vertical" className={styles.radioWrapper}>
          <Radio value={RadioOptions.AllDevices} className={styles.optionWrapper}>
            <Typography.Text>{WebHelper.formatMessage("BulkUpdateStepOne-allDevicesText")}</Typography.Text>
          </Radio>

          {selectedRadioOption === RadioOptions.AllDevices && (
            <div className={styles.selectWrapper}>
              <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("BulkUpdateStepOne-locations")}</Typography.Text>

              <Cascader
                multiple
                placeholder={
                  devicesQuery.isPending
                    ? WebHelper.formatMessage("BulkUpdateStepOne-loadingOrganizations")
                    : WebHelper.formatMessage("BulkUpdateStepOne-selectOrganization")
                }
                maxTagCount="responsive"
                changeOnSelect
                loading={devicesQuery.isPending}
                disabled={devicesQuery.isPending}
                displayRender={(labels) =>
                  devicesQuery.isPending ? WebHelper.formatMessage("BulkUpdateStepOne-loadingOrganizations") : labels.join(" / ")
                }
                onChange={handleChange}
                className={styles.cascader}
                style={{width: "100%"}}
                options={cascaderOptions}
                popupClassName={styles.cascaderPopup}
                showSearch
              />
            </div>
          )}

          <Radio value={RadioOptions.ManualDevices} className={styles.optionWrapper}>
            <Typography.Text>{WebHelper.formatMessage("BulkUpdateStepOne-manualDevicesText")}</Typography.Text>
          </Radio>

          {selectedRadioOption === RadioOptions.ManualDevices && (
            <div className={styles.selectWrapper}>
              <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("BulkUpdateStepOne-devices")}</Typography.Text>

              <Cascader
                multiple
                showCheckedStrategy={Cascader.SHOW_CHILD}
                placeholder={
                  devicesQuery.isPending
                    ? WebHelper.formatMessage("BulkUpdateStepOne-loadingOrganizations")
                    : WebHelper.formatMessage("BulkUpdateStepOne-selectOrganization")
                }
                maxTagCount="responsive"
                loading={devicesQuery.isPending}
                disabled={devicesQuery.isPending}
                onChange={handleChange}
                className={styles.cascader}
                style={{width: "100%"}}
                options={cascaderOptionsDevices}
                popupClassName={styles.cascaderPopup}
                showSearch
              />
            </div>
          )}
        </Space>
      </Radio.Group>
    </Space>
  );
});
