import {Typography} from "antd";
import {format} from "date-fns";
import {LDFlagSet} from "launchdarkly-react-client-sdk";
import React, {FunctionComponent, useCallback} from "react";
import {Bar} from "react-chartjs-2";

import * as Models from "../../../../../core/models";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./SiteSummaryReportDepartmentCard.module.scss";

type SiteSummaryReportDepartmentCardProps = {
  department: Models.DepartmentInSite;
  departmentData?: Models.AnalyticsDashboardCard;
  flags: LDFlagSet;
  targetUtilization: boolean;
};

const constants = require("assets/stylesheets/constants");

export const SiteSummaryReportDepartmentCard: FunctionComponent<SiteSummaryReportDepartmentCardProps> = ({
  department,
  departmentData,
  flags,
  targetUtilization,
}) => {
  const utilizationHistory: () => Omit<Models.AnalyticsDashboardHistory, "active_devices">[] = useCallback(() => {
    if (departmentData) {
      const history = departmentData.time_series_data.map((d) => {
        return {
          interval_start_time: d.start_time,
          interval_end_time: d.end_time,
          total_usage_sec: d.usage_sec,
        };
      });
      return history;
    } else {
      return [];
    }
  }, [departmentData]);

  return (
    <div className={`${styles.departmentCard} ${flags.target_utilization_hours && targetUtilization ? styles.targetCard : ""}`}>
      <div className={`${styles.departmentGraphChart} ${flags.target_utilization_hours && targetUtilization ? styles.targetGraph : ""}`}>
        <div className={styles.departmentGraphTitle}>
          <Typography.Text>{department.name}</Typography.Text>
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-totalUsageHours")}</Typography.Text>
        </div>
        <Bar
          height={5}
          data={{
            labels: utilizationHistory().map(
              ({interval_start_time, interval_end_time}) =>
                `${format(interval_start_time, WebHelper.formatMessage("Common-dateFormatMonthDay"))}-${format(
                  interval_end_time,
                  WebHelper.formatMessage("Common-dateFormatMonthDay")
                )}`
            ),
            datasets: [
              {
                label: WebHelper.formatMessage("UsageChart-totalUsageHoursLabel"),
                data: utilizationHistory().map(({total_usage_sec}) => total_usage_sec / 3600),
                backgroundColor: utilizationHistory().map((item, index) =>
                  //last bar with less opacity
                  index === utilizationHistory().length - 1
                    ? constants.colors.singleColorChartLessOpacity
                    : constants.colors.singleColorChart
                ),
                maxBarThickness: 15,
              },
            ],
          }}
          options={{
            responsive: true,
            devicePixelRatio: 3,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
            },
            scales: {
              x: {
                ticks: {
                  display: true,
                  font: {size: 8},
                },
                grid: {
                  display: false,
                },
              },
              y: {
                ticks: {
                  maxTicksLimit: 5,
                  font: {size: 10},
                  padding: 5,
                },
                border: {
                  display: false,
                },
              },
            },
          }}
        />
      </div>
      {flags.target_utilization_hours && targetUtilization ? (
        <div className={`${styles.targetDepartmentWorkerStatus}`}>
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-workerStatusCounts")}</Typography.Text>
          <div className={styles.targetStatusLabelsWrapper}>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-active")}</Typography.Text>
              <Typography.Text>{departmentData?.worker_status.interval_active_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-onTarget")}
              </Typography.Text>
              <Typography.Text>{departmentData?.worker_status.meets_target_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-aboveTarget")}
              </Typography.Text>
              <Typography.Text>{departmentData?.worker_status.above_target_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-belowTarget")}
              </Typography.Text>
              <Typography.Text>{departmentData?.worker_status.below_target_count}</Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>
                {WebHelper.formatMessage("SiteSummaryReportPDF-point")} {WebHelper.formatMessage("SiteSummaryReportPDF-exempt")}
              </Typography.Text>
              <Typography.Text>
                {departmentData && departmentData.worker_status.exempt_target_count + departmentData.worker_status.unset_target_count}
              </Typography.Text>
            </div>
            <div className={styles.targetStatusLabel}>
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-inactive")}</Typography.Text>
              <Typography.Text>{departmentData?.worker_status.interval_inactive_count}</Typography.Text>
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.nonTargetDepartmentWorkerStatus}>
          <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-workerStatusCounts")}</Typography.Text>
          <div className={styles.nonTargetStatusLabelsWrapper}>
            <div className={styles.nonTargetStatusLabel}>
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-active")}</Typography.Text>
              <Typography.Text>{departmentData?.worker_status.interval_active_count}</Typography.Text>
            </div>
            <div className={styles.nonTargetStatusLabel}>
              <Typography.Text>{WebHelper.formatMessage("SiteSummaryReportPDF-inactive")}</Typography.Text>
              <Typography.Text>{departmentData?.worker_status.interval_inactive_count}</Typography.Text>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
