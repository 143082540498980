import {DownloadOutlined, LeftOutlined, RightOutlined, SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button, Dropdown, Input, Modal, Spin, Switch, Tabs} from "antd";
import {WorkerSafeliftReport} from "core/models";
import {AuthenticationDataStore} from "core/stores/AuthenticationDataStore";
import {DepartmentDataStore} from "core/stores/DepartmentDataStore";
import {WorkerReportGroupDataStore} from "core/stores/WorkerReportGroupDataStore";
import {addDays} from "date-fns";
import {jsPDF} from "jspdf";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {GatewayAlertBlock} from "web/components/gateway-alert-block/GatewayAlertBlock";
import {AppStore, SegmentKey} from "web/stores/AppStore";
import {SegmentEntryPoint, SegmentResourceType} from "web/utils/SegmentHelper";

import {WebHelper, convertDivToBase64} from "../../../utils/WebHelper";
import {WorkerSafeliftReportPDF} from "../worker-safelift-report-pdf/WorkerSafeliftReportPDF";
import styles from "./WorkerSafeliftReportPreviewModal.module.scss";

type WorkerSafeliftReportPreviewModalProps = {
  workerSafeliftReports: WorkerSafeliftReport[] | undefined;
  open: boolean;
  onCancel: () => void;
  entryPoint: SegmentEntryPoint;
  endTime: string;
  groupId?: string;
  mode: "multiple" | "individual";
  workerData?: {id: string; displayName: string};
  onShowInactive?: (showInactive: boolean) => void;
  inactiveWorkers?: boolean;
  isRefetching?: boolean;
};

const normalizeText = (text: string) => {
  return text ? text.trim().replace(" ", "").toLowerCase() : text;
};

const formatDate = (date: Date) =>
  date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });

export const WorkerSafeliftReportPreviewModal: FunctionComponent<WorkerSafeliftReportPreviewModalProps> = ({
  workerSafeliftReports,
  open,
  onCancel,
  entryPoint,
  endTime,
  groupId,
  mode,
  workerData,
  onShowInactive,
  inactiveWorkers,
  isRefetching,
}) => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentWorker, setCurrentWorker] = useState<WorkerSafeliftReport>();
  const [currentHiddenWorker, setCurrentHiddenWorker] = useState<WorkerSafeliftReport>();
  const [filteredReports, setFilteredReports] = useState<WorkerSafeliftReport[]>(workerSafeliftReports || []);
  const [generatedReports, setGeneratedReports] = useState(0);
  const [allWorkersMode, setAllWorkersMode] = useState(false);
  const [buttonOption, setButtonOption] = useState("");
  const [lastSegmentEventWorkerID, setLastSegmentEventWorkerID] = useState<string>();

  const appStore = AppStore.getInstance();
  const workerReportGroupStore = WorkerReportGroupDataStore.getInstance();
  const departmentStore = DepartmentDataStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const {user: currentUser} = authenticationStore.state;

  const {siteId, departmentId, orgId} = useParams();

  const departmentQuery = useQuery({
    queryKey: ["WorkerSafeliftReportPreviewModal-fetchDepartmentsData", departmentId],
    queryFn: () => {
      return departmentStore.department({
        accessToken: authenticationStore.state.accessToken!,
        id: departmentId!,
      });
    },
    enabled: !!departmentId,
  });

  const groupQuery = useQuery({
    queryKey: ["WorkerSafeliftReportPreviewModal-fetchGroupData", groupId],
    queryFn: () => {
      return workerReportGroupStore.workerReportGroup({
        accessToken: authenticationStore.state.accessToken!,
        id: groupId!,
      });
    },
    enabled: !!groupId,
  });

  const noDataMessage = () => {
    const departmentName = departmentQuery.data?.department?.name || "";
    const startDate = formatDate(addDays(new Date(endTime), -7)) || "";
    const endDate = formatDate(new Date(endTime));
    const groupName = groupQuery.data?.workerReportGroup?.name;
    const workerName = workerData?.displayName;

    let message = "";

    if (mode == "multiple") {
      if (!groupId) {
        message = WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-allWorkersNoData", {departmentName, endDate, startDate});
      } else {
        message = WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-groupNoData", {
          departmentName,
          groupName: groupName || "",
          endDate,
          startDate,
        });
      }
    } else {
      message = WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-workerNoData", {
        workerName: workerName || "",
        endDate,
        startDate,
      });
    }

    return message;
  };

  const commonWorkerSegmentEventData = {
    departmentID: departmentId,
    siteID: siteId,
    orgID: orgId,
    userOrg: currentUser?.organization_name,
    endDateWeekday: new Date(endTime).toLocaleDateString("en-US", {weekday: "long"}),
    endDate: new Date(endTime).toLocaleDateString("en-US", {day: "2-digit", month: "2-digit", year: "numeric"}),
  };

  const singleWorkerSegmentEventData = {
    ...commonWorkerSegmentEventData,
    workerID: currentWorker?.worker_id || "",
    entryPoint,
  };

  const bulkWorkerSegmentEventData = {
    ...commonWorkerSegmentEventData,
    resourceType: groupId ? SegmentResourceType.WORKER_GROUP : SegmentResourceType.DEPARTMENT,
    ...(groupId && {groupID: groupId}),
  };

  const sortWorkers = (workerA: WorkerSafeliftReport, workerB: WorkerSafeliftReport) => {
    if (workerA.worker_first_name && workerB.worker_first_name) {
      return workerA.worker_first_name.localeCompare(workerB.worker_first_name);
    }

    if (workerA.worker_first_name) {
      return -1;
    }
    if (workerB.worker_first_name) {
      return 1;
    }

    return workerA.worker_employee_id.localeCompare(workerB.worker_employee_id);
  };

  useEffect(() => {
    if (workerSafeliftReports) {
      workerSafeliftReports.sort((a, b) => sortWorkers(a, b));
      !currentWorker && setCurrentWorker(workerSafeliftReports[0]);
      !currentHiddenWorker && setCurrentHiddenWorker(workerSafeliftReports[0]);
      if (!searchValue) setFilteredReports(workerSafeliftReports);
      else {
        setFilteredReports(
          workerSafeliftReports.filter((item) => item && item.fullName && normalizeText(item.fullName).includes(normalizeText(searchValue)))
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workerSafeliftReports, searchValue]);

  useEffect(() => {
    if (open && currentWorker && entryPoint && endTime && lastSegmentEventWorkerID !== currentWorker.worker_id) {
      appStore.sendAnalyticTrack(SegmentKey.ViewSafeliftReportSingle, singleWorkerSegmentEventData);
      setLastSegmentEventWorkerID(currentWorker.worker_id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWorker, open]);

  useEffect(() => {
    if (inactiveWorkers) {
      setButtonOption(WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-all"));
    } else {
      setButtonOption(WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-allActive"));
    }
  }, [inactiveWorkers]);

  const downloadMenu = [
    {
      label: (
        <div
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            generatePDF("all");
          }}>
          {buttonOption}
        </div>
      ),
      key: "all",
    },
    {
      label: (
        <div
          role="presentation"
          onClick={(e) => {
            e.stopPropagation();
            generatePDF("individual");
          }}>
          {WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-individual")}
        </div>
      ),
      key: "individual",
    },
  ];

  const generatePDF = async (mode: "individual" | "all") => {
    if (!workerSafeliftReports) return;
    setIsLoading(true);
    const letterSheetSize = [612, 792]; //Letter
    const pdf = new jsPDF({
      orientation: "p", //portrait
      unit: "pt", //points
      format: letterSheetSize,
    });

    switch (mode) {
      case "individual":
        const imgData = document.getElementById("currentWorkerReport");
        if (imgData) {
          try {
            const [base64Image, scaledHeight] = await convertDivToBase64(imgData, letterSheetSize);
            pdf.addImage(base64Image, "JPEG", 0, 0, letterSheetSize[0], scaledHeight);
            pdf.save(`${WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-safeliftReport")}-${currentWorker?.fullName}.pdf`);
            appStore.sendAnalyticTrack(SegmentKey.DownloadSafeliftReportSingle, singleWorkerSegmentEventData);
          } catch (e) {
            console.error(e);
            WebHelper.showErrorMessage(WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadError"));
          } finally {
            setIsLoading(false);
          }
        }
        break;

      case "all":
        setAllWorkersMode(true);
        const hiddenDiv = document.getElementById("hiddenWrapper");
        if (hiddenDiv && workerSafeliftReports) {
          const renderPage = async () => {
            hiddenDiv.style.visibility = "visible";
            const workerImgData = document.getElementById("currentHiddenWorker");

            if (workerImgData) {
              const [base64Image, scaledHeight] = await convertDivToBase64(workerImgData, letterSheetSize);
              pdf.addImage(base64Image, "JPEG", 0, 0, letterSheetSize[0], scaledHeight);
            }
            hiddenDiv.style.visibility = "hidden";
          };

          const renderPageAsync = async (workerReport: WorkerSafeliftReport, index: number) => {
            return new Promise((resolve) => {
              setCurrentHiddenWorker(workerReport);
              setGeneratedReports(index);
              setTimeout(() => {
                if (index > 0) pdf.addPage();
                renderPage().then(resolve);
              }, 100); // Delay to let the component re-render
            });
          };

          for (let i = 0; i < workerSafeliftReports.length; i++) {
            await renderPageAsync(workerSafeliftReports[i], i);
          }

          try {
            pdf.save(
              `${WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-safeliftReport")}-${WebHelper.formatMessage(
                "WorkerSafeliftReportPreviewModal-allWorkers"
              )}.pdf`
            );
            appStore.sendAnalyticTrack(SegmentKey.DownloadSafeliftReportBulk, bulkWorkerSegmentEventData);
          } catch (e) {
            console.error(e);
            WebHelper.showErrorMessage(WebHelper.formatMessage("LeaderboardDownloadDropdown-downloadError"));
          } finally {
            setAllWorkersMode(false);
            setIsLoading(false);
            setGeneratedReports(0);
          }
        }
        break;

      default:
        break;
    }
  };

  const handleTabChange = (value: string) => {
    if (workerSafeliftReports) setCurrentWorker(workerSafeliftReports.find((worker) => worker.worker_id == value));
  };

  const downloadButtonInner = (
    <Button
      loading={isLoading}
      onClick={() => workerSafeliftReports && workerSafeliftReports.length == 1 && generatePDF("individual")}
      shape="round"
      type="primary"
      icon={<DownloadOutlined />}
      className={styles.downloadReportButton}>
      {workerSafeliftReports && !isLoading
        ? WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-download")
        : `${WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-generatingReport")} ${generatedReports}/${
            allWorkersMode ? workerSafeliftReports!.length : 1
          }`}
    </Button>
  );

  const dowloadButton = (
    <>
      {workerSafeliftReports && workerSafeliftReports.length > 1 ? (
        <Dropdown menu={{items: downloadMenu}} placement="bottomLeft">
          {downloadButtonInner}
        </Dropdown>
      ) : (
        downloadButtonInner
      )}
    </>
  );

  const handleCancel = () => {
    setCurrentWorker(undefined);
    setCurrentHiddenWorker(undefined);
    setLastSegmentEventWorkerID(undefined);
    onCancel();
  };

  const title = () => {
    const first = WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-title");
    const second = departmentQuery.data?.department?.name ? departmentQuery.data?.department?.name : "";
    const third =
      mode == "multiple"
        ? groupId
          ? groupQuery.data?.workerReportGroup?.name
          : WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-allWorkers")
        : workerData?.displayName;
    const toggle = onShowInactive ? <Switch defaultChecked={false} onChange={(value) => onShowInactive(value)} /> : "";

    return (
      <div className={styles.modalTitle}>
        {[first, second, third].join(" - ")}
        {onShowInactive && (
          <div className={styles.switchContainer}>
            {WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-displayInactiveWorkers")} {toggle}
          </div>
        )}
      </div>
    );
  };

  const handlePreviousTab = () => {
    if (workerSafeliftReports && currentWorker) {
      const currentIndex = workerSafeliftReports.findIndex((worker) => worker.worker_id === currentWorker.worker_id);
      const previousIndex = (currentIndex - 1 + workerSafeliftReports.length) % workerSafeliftReports.length;
      setCurrentWorker(workerSafeliftReports[previousIndex]);
    }
  };

  const handleNextTab = () => {
    if (workerSafeliftReports && currentWorker) {
      const currentIndex = workerSafeliftReports.findIndex((worker) => worker.worker_id === currentWorker.worker_id);
      const nextIndex = (currentIndex + 1) % workerSafeliftReports.length;
      setCurrentWorker(workerSafeliftReports[nextIndex]);
    }
  };

  return (
    <Modal className={styles.modal} zIndex={1001} open={open} destroyOnClose title={title()} centered footer={null} onCancel={handleCancel}>
      <div className={styles.wrapper}>
        {dowloadButton}
        {isRefetching ? (
          <div className={styles.loading}>
            <Spin />
          </div>
        ) : (
          <>
            {workerSafeliftReports && workerSafeliftReports.length > 1 && (
              <div className={styles.header}>
                <Input
                  className={styles.search}
                  size="small"
                  placeholder={WebHelper.formatMessage("WorkerSafeliftReportPreviewModal-searchButtonText")}
                  prefix={<SearchOutlined />}
                  onChange={(event) => setSearchValue(event.target.value)}
                  allowClear
                />
                {workerSafeliftReports && (
                  <Tabs
                    className={styles.tabs}
                    defaultActiveKey={currentWorker?.worker_id}
                    activeKey={currentWorker?.worker_id}
                    tabPosition={"top"}
                    onChange={(value) => handleTabChange(value)}
                    items={filteredReports.map((worker) => {
                      return {
                        label: worker?.fullName,
                        key: worker.worker_id,
                      };
                    })}
                  />
                )}
                <div className={styles.arrowButtons}>
                  <Button onClick={handlePreviousTab} icon={<LeftOutlined className={styles.arrows} />} />
                  <Button onClick={handleNextTab} icon={<RightOutlined className={styles.arrows} />} />
                </div>
              </div>
            )}
            <div className={styles.contentWrapper}>
              <GatewayAlertBlock />
              {!workerSafeliftReports || (workerSafeliftReports.length == 0 && <div>{noDataMessage()}</div>)}
              <div className={styles.reportWrapper} id="currentWorkerReport">
                {currentWorker && <WorkerSafeliftReportPDF reportData={currentWorker} />}
              </div>
              {workerSafeliftReports && workerSafeliftReports.length > 1 && (
                <div id="hiddenWrapper" className={styles.hiddenWrapper}>
                  {workerSafeliftReports && workerSafeliftReports.length > 1 && (
                    <div className={styles.reportWrapper} id="currentHiddenWorker">
                      {currentHiddenWorker && <WorkerSafeliftReportPDF reportData={currentHiddenWorker} />}
                    </div>
                  )}
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
