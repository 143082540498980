export enum AnalyticsVariableToChart {
  HoursUsed = "HoursUsed",
  Lifts = "Lifts",
  WeightOffloaded = "WeightOffloaded",
  MovementBreakdown = "MovementBreakdown",
  WorkerActiveStatus = "WorkerActiveStatus",
}

export enum AnalyticsInterval {
  Day = "day",
  Week = "week",
  Month = "month",
  Quarter = "quarter",
}

export enum ChartDataType {
  Raw = "raw",
  Percentage = "percentage",
}

export enum DisplayInGraphType {
  HoursUsed = "hours_used",
  Target = "target",
}

export enum WorkerStatusType {
  Active = "active",
  Inactive = "inactive",
}

export enum ChartMovementType {
  Safe = "safe",
  Risky = "risky",
  ExcessiveForwardBending = "excessive_forward_bending",
  ProlongedBending = "prolonged_bending",
  Twisting = "twisting",
  SideBending = "side_bending",
}

export type AnalyticsSettings = {
  variableToChartSelected: AnalyticsVariableToChart;
  intervalSelected: AnalyticsInterval;
  startDate: Date | null;
  endDate: Date | null;
  displayMaxData: boolean;
  checkedMostRecent: boolean;
  dataType: ChartDataType;
  workerStatusTypesSelected: WorkerStatusType[];
  movementTypesSelected: ChartMovementType[];
  safetyTypesSelected: ChartMovementType[];
};
