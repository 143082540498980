import {Space, Tag, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./BulkUpdateStepTwo.module.scss";

export interface DeviceLocationData {
  pathNames: string[];
  pathIds: string[];
  type: string;
}

export type BulkUpdateStepTwoProps = {
  deviceLocationData: DeviceLocationData[];
  updateLocationData: (a: DeviceLocationData[]) => void;
};

export const BulkUpdateStepTwo: FunctionComponent<BulkUpdateStepTwoProps> = observer(({deviceLocationData, updateLocationData}) => {
  return (
    <Space direction="vertical">
      <Typography.Text>{WebHelper.formatMessage("BulkUpdateStepTwo-confirmUpdateAll")}</Typography.Text>
      <Space direction="vertical" className={styles.locations}>
        {deviceLocationData.map((item) => (
          <Tag
            title={item.pathNames.join("-")}
            closable
            onClose={(e) => {
              e.preventDefault();
              updateLocationData(
                deviceLocationData.filter((i) => {
                  return i !== item;
                })
              );
            }}>
            {item.pathNames.map((name) => (name.length > 30 ? `${name.substring(0, 30)}...` : name)).join("-")}
          </Tag>
        ))}
      </Space>
    </Space>
  );
});
