import {observable, makeObservable} from "mobx";

import {BaseModel} from "./BaseModel";
import {EntityType} from "./EntityType";

export type AnalyticsDashboardDeviceStatus = {
  allocated_devices: number;
  active_devices: number;
  inactive_devices: number;
  unassigned_devices: number;
  out_of_service_devices: number;
};

export type AnalyticsDashboardHistory = {
  interval_start_time: Date;
  interval_end_time: Date;
  total_usage_sec: number;
  active_devices: number;
  total_weight_offloaded?: number;
};

export class AnalyticsDashboard extends BaseModel {
  resource_type: EntityType;
  resource_id: string;
  device_status: AnalyticsDashboardDeviceStatus;
  utilization_history: AnalyticsDashboardHistory[];

  constructor() {
    super();
    makeObservable(this, {
      resource_type: observable,
      resource_id: observable,
      device_status: observable,
      utilization_history: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDashboard, json: any) {
    if (json.utilization_history) {
      object.utilization_history = json.utilization_history.map((i: AnalyticsDashboardHistory) => {
        i.interval_start_time = new Date(i.interval_start_time);
        i.interval_end_time = new Date(i.interval_end_time);

        return i;
      });
    }
  }
}
