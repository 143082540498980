import {useQuery} from "@tanstack/react-query";
import {Drawer} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";

import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {DepartmentDataStore} from "../../../core/stores/DepartmentDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {ReportDrawerContent} from "./report-drawer-content/ReportDrawerContent";
import {ReportPreviewModal} from "./report-preview-modal/ReportPreviewModal";

type SummaryReportDrawerProps = {
  departmentID: string;
  onClose: () => void;
  open: boolean;
  tz_location?: string;
};

export const SummaryReportDrawer: FunctionComponent<SummaryReportDrawerProps> = ({departmentID, onClose, open, tz_location}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const departmentStore = DepartmentDataStore.getInstance();

  const [shouldFetchReportData, setShouldFetchReportData] = useState<{
    startDateFormatted: string;
    endDateFormatted: string;
    action: "download" | "preview";
  }>();
  const [isReportPreviewModalVisible, setIsReportPreviewModalVisible] = useState(false);

  const summaryReportQuery = useQuery({
    queryKey: ["SummaryReportDrawer-fetchSummaryReport", departmentID, shouldFetchReportData],
    queryFn: () =>
      shouldFetchReportData &&
      departmentStore.summaryReport({
        accessToken: authenticationStore.state.accessToken!,
        department_id: departmentID!,
        start_time: shouldFetchReportData.startDateFormatted,
        end_time: shouldFetchReportData.endDateFormatted,
      }),
    enabled: !!shouldFetchReportData,
  });

  useEffect(() => {
    if (!summaryReportQuery.data || !shouldFetchReportData?.action) return;

    if (!summaryReportQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("ReportDrawer-fetchReportError"), summaryReportQuery.data.correlationId);
      return;
    }

    switch (shouldFetchReportData.action) {
      case "download":
        WebHelper.downloadFile(summaryReportQuery.data.file, WebHelper.formatMessage("SummaryReportDrawer-fileName"), "application/pdf");
        break;
      case "preview":
        setIsReportPreviewModalVisible(true);
        break;
    }

    setShouldFetchReportData(undefined);
  }, [summaryReportQuery.data, shouldFetchReportData?.action]);

  const handleOnDownload = (startDateFormatted: string, endDateFormatted: string) => {
    setShouldFetchReportData({startDateFormatted, endDateFormatted, action: "download"});
  };

  const handleOnPreview = (startDateFormatted: string, endDateFormatted: string) => {
    setShouldFetchReportData({startDateFormatted, endDateFormatted, action: "preview"});
  };

  return (
    <>
      <Drawer
        open={open}
        width={WebHelper.drawerWidth}
        destroyOnClose
        title={WebHelper.formatMessage("SummaryReportDrawer-drawerTitle")}
        onClose={onClose}>
        <ReportDrawerContent
          loadingDownload={summaryReportQuery.isFetching && shouldFetchReportData?.action === "download"}
          loadingPreview={summaryReportQuery.isFetching && shouldFetchReportData?.action === "preview"}
          message={WebHelper.formatMessage("SummaryReportDrawer-contentMessage")}
          onDownload={handleOnDownload}
          onPreview={handleOnPreview}
          tz_location={tz_location}
        />
      </Drawer>
      <ReportPreviewModal
        open={isReportPreviewModalVisible}
        file={summaryReportQuery.data?.file}
        onCancel={() => setIsReportPreviewModalVisible(false)}
      />
    </>
  );
};
