import {Col, Result, Row} from "antd";
import React from "react";

import userGroupIcon from "../../../../../assets/images/common/User_Group_Icon.svg";
import * as Models from "../../../../../core/models";
import {AppStore} from "../../../../stores/AppStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {WorkerReportGroupCard} from "./worker-report-group-card/WorkerReportGroupCard";
import styles from "./WorkerReportGroupDisplay.module.scss";

type WorkerReportGroupsDisplayProps = {
  workerReportGroups: Models.WorkerReportGroup[];
};

export const WorkerReportGroupsDisplay: React.FC<WorkerReportGroupsDisplayProps> = ({workerReportGroups}) => {
  const appStore = AppStore.getInstance();

  const isMobile = appStore.isMobile();

  if (workerReportGroups.length === 0)
    return (
      <Result
        className={styles.result}
        icon={<img src={userGroupIcon} alt={WebHelper.formatMessage("WorkerReportGroupsDisplay-resultAltText")} />}
        subTitle={WebHelper.formatMessage("WorkerReportGroupsDisplay-emptyStateText")}
      />
    );

  return (
    <Row gutter={[16, 16]}>
      {workerReportGroups.map(({id, name, workers}) => (
        <Col key={id} span={isMobile ? 12 : 6}>
          <WorkerReportGroupCard id={id} name={name} numberOfWorkers={workers ? workers.length : 0} />
        </Col>
      ))}
    </Row>
  );
};
