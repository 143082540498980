import {isToday} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import _ from "lodash";
import {makeObservable, observable} from "mobx";

import {AnalyticsTimeSeries} from "./AnalyticsTimeSeriesData";
import {BaseModel} from "./BaseModel";
import {WorkerShort} from "./WorkerShort";

export class AnalyticsDashboardWorker extends BaseModel {
  worker: WorkerShort;
  time_series_data: AnalyticsTimeSeries[];

  constructor() {
    super();
    makeObservable(this, {
      worker: observable,
      time_series_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDashboardWorker, json: any) {
    if (json.worker_short) object.worker = WorkerShort.fromJSON(json.worker_short);
    if (json.time_series_data)
      object.time_series_data = json.time_series_data.map((timeSeries: AnalyticsTimeSeries) => {
        return AnalyticsTimeSeries.fromJSON(timeSeries);
      });
  }

  get days_active_last_7_days(): number {
    const daysActive = this.time_series_data.filter(
      (timeSeries) => timeSeries.usage_sec > 0 && !isToday(zonedTimeToUtc(timeSeries.start_time, this.worker.site_tz_location))
    );
    return daysActive.length;
  }

  get days_active_first_7_intervals(): number {
    const first7Records = this.time_series_data.slice(0, 7).filter((record) => record.usage_sec > 0);

    return first7Records.length;
  }

  get hours_active_first_7_intervals(): string {
    const first7Records = this.time_series_data.slice(0, 7).reduce((partialSum, record) => {
      return partialSum + record.usage_sec;
    }, 0);

    return (Math.round((first7Records / 3600) * 10) / 10).toFixed(1);
  }

  get hours_active_last_7_days(): string {
    const secondsActiveLast7Days = this.seconds_active_last_7_days;

    return (Math.round((secondsActiveLast7Days / 3600) * 10) / 10).toFixed(1);
  }

  get seconds_active_last_7_days(): number {
    return this.time_series_data.reduce((partialSum, record) => {
      if (!isToday(record.start_time)) return partialSum + record.usage_sec;
      else return partialSum;
    }, 0);
  }

  get seconds_active_last_7_days_including_today(): number {
    return this.time_series_data.reduce((partialSum, record) => {
      return partialSum + record.usage_sec;
    }, 0);
  }

  get data_uploaded_today(): boolean {
    const timeSeries = this.time_series_data.find((timeSeries) => isToday(timeSeries.start_time));

    if (!timeSeries) return false;

    return timeSeries.usage_sec > 0;
  }

  get active(): boolean {
    return (
      this.worker.assignable &&
      !_.isNil(this.worker.assigned_device) &&
      _.isNil(this.worker.deleted_at) &&
      this.time_series_data.filter((timeSeries) => timeSeries.usage_sec > 0).length > 0
    );
  }

  get inactive(): boolean {
    return (
      this.worker.assignable &&
      !_.isNil(this.worker.assigned_device) &&
      _.isNil(this.worker.deleted_at) &&
      this.time_series_data.filter((timeSeries) => timeSeries.usage_sec > 0).length === 0
    );
  }
}

export class AnalyticsDashboardWorkerData extends BaseModel {
  worker_data: AnalyticsDashboardWorker[];

  constructor() {
    super();
    makeObservable(this, {
      worker_data: observable,
    });
  }

  static fixObjectFromJSON(object: AnalyticsDashboardWorkerData, json: any) {
    if (json.worker_data) {
      object.worker_data = json.worker_data.map((worker: AnalyticsDashboardWorker) => AnalyticsDashboardWorker.fromJSON(worker));
    }
  }
}
