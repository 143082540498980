import {Modal} from "antd";
import React, {FunctionComponent, useEffect, useState} from "react";
import {pdfjs, Document, Page} from "react-pdf";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import {WebHelper} from "../../../utils/WebHelper";
import {Loading} from "../../loading/Loading";
import styles from "./ReportPreviewModal.module.scss";

type ReportPreviewModalProps = {
  file: string | undefined;
  open: boolean;
  onCancel: () => void;
};

//https://github.com/wojtekmaj/react-pdf?tab=readme-ov-file#configure-pdfjs-worker
pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

export const ReportPreviewModal: FunctionComponent<ReportPreviewModalProps> = ({file, open, onCancel}) => {
  const [reportURL, setReportURL] = useState<string>();
  const [numPages, setNumPages] = useState<number | null>(null);

  useEffect(() => {
    if (!file) return;

    const fileBlob = new Blob([file], {type: "application/pdf"});
    setReportURL(URL.createObjectURL(fileBlob));
  }, [file]);

  const onDocumentLoadSuccess = ({numPages}: {numPages: number}) => {
    setNumPages(numPages);
  };

  return (
    <Modal
      className={styles.modal}
      zIndex={1001}
      open={open}
      title={WebHelper.formatMessage("ReportPreviewModal-title")}
      centered
      footer={null}
      onCancel={onCancel}>
      <Document file={reportURL} loading={<Loading />} onLoadSuccess={onDocumentLoadSuccess}>
        {Array.from(new Array(numPages), (_, index) => (
          <Page className={styles.page} key={`page_${index + 1}`} pageNumber={index + 1} loading={<Loading />} renderTextLayer={false} />
        ))}
      </Document>
    </Modal>
  );
};
