import {useQuery} from "@tanstack/react-query";
import {Select} from "antd";
import _ from "lodash";
import React, {useState, useRef, useEffect, useMemo, useCallback} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {LinkButton} from "../../../common/link-button/LinkButton";
import styles from "./EditableWorker.module.scss";

type EditableWorkerProps = {
  device: Models.DeviceShort;
  onSave: (device: Models.DeviceShort, worker?: Models.WorkerShort) => void;
};

export const EditableWorker: React.FC<EditableWorkerProps> = ({onSave, device}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const workerStore = WorkerDataStore.getInstance();

  const [editing, setEditing] = useState(false);
  const [availableWorkers, setAvailableWorkers] = useState<Models.WorkerShort[]>([]);
  const selectRef = useRef<any>(null);

  const {isFetching: workersQueryIsFetching, refetch: workersQueryRefetch} = useQuery({
    queryKey: ["DeviceDetail-fetchWorkersData"],
    queryFn: () =>
      workerStore.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: device.department_id!,
      }),
    enabled: false,
  });

  const toggleEdit = () => {
    setEditing(!editing);
  };

  useEffect(() => {
    if (editing) {
      selectRef.current.focus();
    }
  }, [editing]);

  const handleSave = async (value: string) => {
    toggleEdit();
    if (device.assigned_worker?.id !== value) {
      const selectedWorker = availableWorkers.find((worker) => worker.id === value);
      onSave(device, selectedWorker);
    }
  };

  const fetchAvailableWorkers = useCallback(async () => {
    const response = await workersQueryRefetch();
    if (response.data?.success) {
      const availableWorkersResponse = response.data.workers.filter((worker) => worker.assignable && _.isNil(worker.device_id));
      setAvailableWorkers(availableWorkersResponse);
    } else WebHelper.showErrorMessage(WebHelper.formatMessage("EditableWorker-fetchWorkersError"), response.data?.correlationId);
  }, [workersQueryRefetch]);

  const assignedWorkerItemOptions = useMemo(() => {
    const options = [
      getSelectOption("", WebHelper.formatMessage("EditableWorker-none")),
      ...availableWorkers.map((worker) => getSelectOption(worker.id, worker.displayName ?? worker.employee_id)),
    ];
    if (device.assigned_worker) options.unshift(getSelectOption(device.assigned_worker.id, device.assigned_worker.displayName));
    return options;
  }, [availableWorkers, device.assigned_worker]);

  return editing ? (
    <Select
      defaultValue={device.assigned_worker?.id ?? ""}
      className={styles.select}
      loading={workersQueryIsFetching}
      onChange={handleSave}
      options={assignedWorkerItemOptions}
      optionFilterProp="label"
      ref={selectRef}
      onBlur={toggleEdit}
      showSearch
      onDropdownVisibleChange={(open) => {
        if (open) fetchAvailableWorkers();
      }}
      dropdownRender={(node) => (workersQueryIsFetching ? WebHelper.LoadingSpin : node)}
    />
  ) : (
    <LinkButton
      onClick={() => {
        toggleEdit();
      }}>
      {!_.isEmpty(device.assigned_worker?.displayName)
        ? device.assigned_worker?.displayName
        : WebHelper.formatMessage("Common-unassignedLabel")}
    </LinkButton>
  );
};

function getSelectOption(id: string | undefined, name: string) {
  return {key: id, label: name, value: id};
}
