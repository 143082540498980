import {useMutation} from "@tanstack/react-query";
import {Modal, Select, Space, Typography} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../../core/stores/DeviceDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {WorkerNoDevice} from "../worker-no-device/WorkerNoDevice";
import styles from "./ChangeDeviceStatusModal.module.scss";

export type ChangeDeviceStatusModalProps = {
  device?: Models.DeviceShort;
  open: boolean;
  onOk: () => void;
  onCancel: () => void;
};

export const ChangeDeviceStatusModal: FunctionComponent<ChangeDeviceStatusModalProps> = observer(({device, open, onOk, onCancel}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const appStore = AppStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const flags = useFlags();

  const [archivedValue, setArchivedValue] = useState<boolean>(!!device?.archived_at);
  const [showWorkerNoLongerDevice, setShowWorkerNoLongerDevice] = useState(false);

  const handleCancel = () => {
    setArchivedValue(false);
    onCancel();
  };

  const changeDeviceStatusMutation = useMutation({
    mutationFn: (device: Models.DeviceShort) =>
      deviceStore.assignWorkerToDevice({
        accessToken: authenticationStore.state.accessToken!,
        device_id: device.id,
        assignable: !device.assignable,
        worker_id: null,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("ChangeDeviceStatusModal-changeStatusError"), response.correlationId);
        return;
      }

      appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceStatus, {
        departmentID: response.device.department?.id,
        siteID: response.device.site?.id,
        orgID: response.device.organization?.id,
        endAssignable: response.device.assignable,
        entryPoint: SegmentEntryPoint.DEVICE_TABLE,
      });

      if (!response.device.assignable && archivedValue) {
        archiveDeviceMutation.mutate(response.device);
      } else {
        appStore.showMessage(
          response.device.assignable
            ? WebHelper.formatMessage("ChangeDeviceStatusModal-successMessageToInService", {deviceTag: response.device.device_tag})
            : WebHelper.formatMessage("ChangeDeviceStatusModal-successMessageToOutService", {
                deviceTag: response.device.device_tag,
              }),
          "success"
        );
        handleMutationCompletion();
      }
    },
  });

  const archiveDeviceMutation = useMutation({
    mutationFn: (device: Models.Device) =>
      deviceStore.archiveDevice({
        accessToken: authenticationStore.state.accessToken!,
        device_id: device.id,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("ChangeDeviceStatusModal-archiveError"), response.correlationId);
        return;
      }

      appStore.showMessage(
        WebHelper.formatMessage("ChangeDeviceStatusModal-successMessageToOutServiceAndArchived", {
          deviceTag: response.device.device_tag,
        }),
        "success"
      );
      handleMutationCompletion();
    },
  });

  const handleMutationCompletion = () => {
    device?.assigned_worker?.id && device.assignable ? setShowWorkerNoLongerDevice(true) : onOk();
    setArchivedValue(false);
  };

  return (
    <>
      <Modal
        centered
        open={open && !!device}
        destroyOnClose={true}
        title={WebHelper.formatMessage("ChangeDeviceStatusModal-title")}
        okText={device ? (device.status ? WebHelper.formatMessage("Common-save") : WebHelper.formatMessage("Common-confirm")) : null}
        onOk={() => (device ? changeDeviceStatusMutation.mutate(device) : null)}
        okButtonProps={{
          shape: "round",
          loading: changeDeviceStatusMutation.isPending || archiveDeviceMutation.isPending,
        }}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onCancel={handleCancel}
        cancelButtonProps={{
          shape: "round",
          disabled: changeDeviceStatusMutation.isPending || archiveDeviceMutation.isPending,
        }}>
        {device && (
          <Space className={styles.container} direction="vertical" size={24}>
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage(
                    device.assignable
                      ? "ChangeDeviceStatusModal-descriptionToOutService"
                      : "ChangeDeviceStatusModal-descriptionToInService",
                    {
                      deviceTag: device.device_tag,
                    }
                  )
                ),
              }}
            />
            {device.assignable &&
              flags.archive_devices &&
              authenticationStore.isUserGlobal &&
              authenticationStore.permissionLevel(Models.UserScope.Global) === Models.UserAccessLevel.Admin && (
                <>
                  <div className={styles.selectWrapper}>
                    <Typography.Text className={styles.archiveLabel}>
                      {WebHelper.formatMessage("ChangeDeviceStatusModal-archive")}
                    </Typography.Text>
                    <Select
                      className={styles.select}
                      disabled={changeDeviceStatusMutation.isPending || archiveDeviceMutation.isPending}
                      value={archivedValue}
                      onChange={setArchivedValue}
                      options={[
                        {key: true, label: WebHelper.formatMessage("Common-true"), value: true},
                        {key: false, label: WebHelper.formatMessage("Common-false"), value: false},
                      ]}
                    />
                  </div>
                  {archivedValue && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: WebHelper.parseMarkdown(WebHelper.formatMessage("ChangeDeviceStatusModal-archivedTrueMessage")),
                      }}
                    />
                  )}
                </>
              )}
          </Space>
        )}
      </Modal>
      {device && device.assigned_worker && (
        <WorkerNoDevice
          worker={device.assigned_worker}
          departmentId={device.department_id!}
          onOk={() => {
            setShowWorkerNoLongerDevice(false);
            onOk();
          }}
          open={showWorkerNoLongerDevice}
          onClose={() => {
            setShowWorkerNoLongerDevice(false);
            onOk();
          }}
          entryPoint={SegmentEntryPoint.CHANGE_DEVICE_STATUS_MODAL}
        />
      )}
    </>
  );
});
