import {Card, Typography} from "antd";
import React from "react";
import {useNavigate} from "react-router-dom";

import {WebHelper} from "../../../../../utils/WebHelper";
import styles from "./WorkerReportGroupCard.module.scss";

type WorkerReportGroupCardProps = {
  id: string;
  name: string;
  numberOfWorkers: number;
};

export const WorkerReportGroupCard: React.FC<WorkerReportGroupCardProps> = ({id, name, numberOfWorkers}) => {
  const navigate = useNavigate();

  return (
    <Card
      title={name}
      className={styles.card}
      onClick={() =>
        navigate(
          location.pathname.includes("/departments")
            ? `${location.pathname.substring(0, location.pathname.indexOf("/departments"))}/groups/${id}`
            : `${location.pathname}/groups/${id}`
        )
      }>
      <Typography.Text>{WebHelper.formatMessage("WorkerReportGroupCard-numberOfWorkers", {numberOfWorkers})}</Typography.Text>
    </Card>
  );
};
