import {useQuery} from "@tanstack/react-query";
import {Cascader, Space, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {OrganizationDataStore} from "../../../../../core/stores/OrganizationDataStore";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./BulkCreateStepThree.module.scss";

type Option = {
  value: string | null;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
};

export type BulkCreateStepThreeProps = {
  updateDepartmentId: (id: string) => void;
};

export const BulkCreateStepThree: FunctionComponent<BulkCreateStepThreeProps> = observer(({updateDepartmentId}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const organizationStore = OrganizationDataStore.getInstance();

  const [options, setOptions] = useState<Option[]>([]);
  const [orgIdToQuery, setOrgIdToQuery] = useState<string | null>();

  const organizationsQuery = useQuery({
    queryKey: ["DeviceManagementSearchBar-fetchOrganizationData"],
    queryFn: () => organizationStore.organizationsShort({accessToken: authenticationStore.state.accessToken!}),
  });

  const organizationQuery = useQuery({
    queryKey: ["DeviceManagementSearchBar-fetchOrganizationData", orgIdToQuery],
    queryFn: () => {
      if (orgIdToQuery) {
        return organizationStore.organization({
          accessToken: authenticationStore.state.accessToken!,
          id: orgIdToQuery,
        });
      } else return;
    },
    enabled: !!orgIdToQuery,
  });

  useEffect(() => {
    if (!organizationsQuery.data) return;
    if (organizationsQuery.data.success) {
      setOptions([
        {value: "none", label: WebHelper.formatMessage("Common-none"), isLeaf: true, children: []},
        ...organizationsQuery.data.organizations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((org) => ({
            value: org.id,
            label: org.name,
            children: [],
            disabled: org.site_count === 0,
            isLeaf: false,
          })),
      ]);
    }
  }, [organizationsQuery.data]);

  useEffect(() => {
    if (!organizationQuery.data || !organizationsQuery.data) return;
    if (organizationQuery.data.success && organizationsQuery.data.success) {
      setOptions([
        {value: "none", label: WebHelper.formatMessage("Common-none"), isLeaf: true, children: []},
        ...organizationsQuery.data.organizations
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((org) => ({
            value: org.id,
            label: org.name,
            disabled: org.site_count === 0,
            children: organizationQuery.data?.organization?.sites
              ?.sort((a, b) => a.name.localeCompare(b.name))
              .map((site) => ({
                value: site.id,
                label: site.name,
                disabled: !site.departments,
                children: site.departments
                  ?.sort((a, b) => a.name.localeCompare(b.name))
                  .map((department) => ({
                    value: department.id,
                    label: department.name,
                    isLeaf: true,
                    children: [],
                  })),
              })),
          })),
      ]);
    }
  }, [organizationQuery.data, organizationsQuery.data]);

  const loadOrganizationData = (selectedOptions: any[]) => {
    setOrgIdToQuery(selectedOptions[0].value);
  };

  return (
    <Space direction="vertical">
      <Typography.Text>{WebHelper.formatMessage("BulkCreateStepThree-selectDepartment")}</Typography.Text>
      <div className={styles.selectWrapper}>
        <Typography.Text className={styles.selectLabel}>{WebHelper.formatMessage("BulkCreateStepThree-department")}</Typography.Text>

        <Cascader
          placeholder={
            organizationsQuery.isPending
              ? WebHelper.formatMessage("BulkCreateStepThree-loadingOrganizations")
              : WebHelper.formatMessage("BulkCreateStepThree-selectOrganization")
          }
          loading={organizationsQuery.isPending || organizationQuery.isFetching}
          disabled={organizationsQuery.isPending}
          displayRender={(labels) =>
            organizationsQuery.isPending ? WebHelper.formatMessage("BulkCreateStepThree-loadingOrganizations") : labels.join(" / ")
          }
          defaultValue={["none"]}
          onChange={(values) => updateDepartmentId(values[2] as string)}
          className={styles.cascader}
          options={options}
          loadData={loadOrganizationData}
          allowClear={false}
        />
      </div>
    </Space>
  );
});
