import {Radio} from "antd";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";

export type ReasonRadioProps = {
  value: Models.UnenrollWorkerOtherReason;
};

export const ReasonRadio: FunctionComponent<ReasonRadioProps> = ({value}) => {
  return <Radio value={value}>{WebHelper.getUnenrollWorkerOtherReasonLabel(value)}</Radio>;
};
