import {SearchOutlined} from "@ant-design/icons";
import {Input} from "antd";
import CheckableTag from "antd/lib/tag/CheckableTag";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../../core/models";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./DevicesFilters.module.scss";

type DevicesFiltersProps = {
  selectedTag: Models.DevicesTagsKey;
  setSelectedTag: (tag: Models.DevicesTagsKey) => void;
  setSearchValue: (searchValue: string) => void;
  onFilterChange: (action: string, newSettings?: {quickFilter?: Models.DevicesTagsKey; tableSettings?: TableSettings}) => void;
};

export const DevicesFilters: FunctionComponent<DevicesFiltersProps> = ({selectedTag, setSelectedTag, setSearchValue, onFilterChange}) => {
  return (
    <div className={styles.filtersWrapper}>
      <div className={styles.filters}>
        <CheckableTag
          key={Models.DevicesTagsKey.Assigned}
          checked={selectedTag === Models.DevicesTagsKey.Assigned}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesTagsKey.Assigned);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Assigned});
            }
          }}>
          {WebHelper.formatMessage("DevicesTabContent-assignedTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesTagsKey.Available}
          checked={selectedTag === Models.DevicesTagsKey.Available}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesTagsKey.Available);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Available});
            }
          }}>
          {WebHelper.formatMessage("DevicesTabContent-availableTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesTagsKey.OutOfService}
          checked={selectedTag === Models.DevicesTagsKey.OutOfService}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesTagsKey.OutOfService);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.OutOfService});
            }
          }}>
          {WebHelper.formatMessage("DevicesTabContent-outOfServiceTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesTagsKey.Connected}
          checked={selectedTag === Models.DevicesTagsKey.Connected}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesTagsKey.Connected);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Connected});
            }
          }}>
          {WebHelper.formatMessage("DevicesTabContent-connectedTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesTagsKey.Disconnected}
          checked={selectedTag === Models.DevicesTagsKey.Disconnected}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesTagsKey.Disconnected);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.Disconnected});
            }
          }}>
          {WebHelper.formatMessage("DevicesTabContent-disconnectedTagText")}
        </CheckableTag>
        <CheckableTag
          key={Models.DevicesTagsKey.All}
          checked={selectedTag === Models.DevicesTagsKey.All}
          onChange={(checked) => {
            if (checked) {
              setSelectedTag(Models.DevicesTagsKey.All);
              onFilterChange("quickFilter", {quickFilter: Models.DevicesTagsKey.All});
            }
          }}>
          {WebHelper.formatMessage("DevicesTabContent-allTagText")}
        </CheckableTag>
      </div>
      <Input
        className={styles.search}
        size="small"
        placeholder={WebHelper.formatMessage("DevicesTabContent-searchButtonText")}
        prefix={<SearchOutlined />}
        onChange={(event) => setSearchValue(event.target.value)}
        allowClear
      />
    </div>
  );
};
