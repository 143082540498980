import {ArrowLeftOutlined, DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useMutation, useQuery} from "@tanstack/react-query";
import {Button as AntdButton, Drawer} from "antd";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerReportGroupDataStore} from "../../../../../core/stores/WorkerReportGroupDataStore";
import useUserPreferences from "../../../../hooks/useUserPreferences";
import {EntityDetailsTabKeys} from "../../../../pages/organizations/entity-details/EntityDetails";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {TableSettings} from "../../../../utils/TableUtils";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";
import {PageTitle} from "../../../common/page-title/PageTitle";
import {Subheader, SubheaderBreadcrumbItem} from "../../../common/subheader/Subheader";
import {ConfirmationModal} from "../../../confirmation-modal/ConfirmationModal";
import {Loading} from "../../../loading/Loading";
import {WorkerDetail} from "../../../worker-detail/WorkerDetail";
import {WorkersFilters} from "../../workers-tab-content/workers-filters/WorkersFilters";
import {AddWorkersModal} from "./add-workers-modal/AddWorkersModal";
import {RenameWorkerGroupModal} from "./rename-worker-group-modal/RenameWorkerGroupModal";
import {WorkerReportGroupWorkersTable} from "./worker-report-group-workers-table/WorkerReportGroupWorkersTable";
import styles from "./WorkerReportGroupDetail.module.scss";

type WorkerReportGroupDetailProps = {};

export const WorkerReportGroupDetail: FunctionComponent<WorkerReportGroupDetailProps> = observer(({}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const appStore = AppStore.getInstance();
  const workerReportGroupStore = WorkerReportGroupDataStore.getInstance();

  const [userPreferences, setUserPreferences] = useUserPreferences();

  const [workersFiltered, setWorkersFiltered] = useState<Models.WorkerInWorkerReportGroup[]>([]);
  const [searchValue, setSearchValue] = useState("");
  const [selectedTag, setSelectedTag] = useState<Models.WorkersTagsKey>(
    userPreferences.data.workerReportGroupDetailSelectedTag ?? Models.WorkersTagsKey.All
  );
  const [tableSettings, setTableSettings] = useState<TableSettings>();
  const [selectedWorker, setSelectedWorker] = useState<Models.WorkerInWorkerReportGroup | undefined>();
  const [workerReportGroup, setWorkerReportGroup] = useState<Models.WorkerReportGroup>();
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [isAddWorkersModalOpen, setIsAddWorkersModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isRemoveModalOpen, setIsRemoveModalOpen] = useState(false);
  const [visibleWorkerId, setVisibleWorkerId] = useState<string | null>();
  const breadcrumbs: SubheaderBreadcrumbItem[] = [];

  const location = useLocation();
  const navigate = useNavigate();

  const {groupId, orgId, siteId} = useParams();

  useEffect(() => {
    appStore.sendAnalyticTrack(SegmentKey.IndividualWorkerReportGroupPageOpened, {groupId: groupId, orgId: orgId, siteId: siteId});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const workerGroupQuery = useQuery({
    queryKey: ["WorkerGroupDetail-fetchWorkerGroup", groupId],
    queryFn: () => {
      return workerReportGroupStore.workerReportGroup({
        accessToken: authenticationStore.state.accessToken!,
        id: groupId!,
      });
    },
    enabled: !!groupId,
  });

  const handleDrawerClose = () => {
    setVisibleWorkerId(null);
  };

  const handleDeleteOk = () => {
    handleDrawerClose();
    workerGroupQuery.refetch();
  };

  useEffect(() => {
    if (!workerGroupQuery.data) return;

    if (!workerGroupQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerReportGroupDetail-fetchDataError"), workerGroupQuery.data.correlationId);
      return;
    } else {
      setWorkerReportGroup(workerGroupQuery.data.workerReportGroup);
    }
  }, [workerGroupQuery.data]);

  const handleBackButton = () => {
    navigate(location.pathname.substring(0, location.pathname.indexOf("/groups")), {
      state: {activeKey: EntityDetailsTabKeys.WorkerReportGroups},
    });
  };

  const deleteWorkerGroupMutation = useMutation({
    mutationFn: (workerReportGroup: Models.WorkerReportGroup) => {
      return workerReportGroupStore.deleteWorkerReportGroup({
        accessToken: authenticationStore.state.accessToken!,
        id: workerReportGroup.id,
      });
    },
    onSuccess: (response) => {
      if (response.success) {
        navigate(location.pathname.substring(0, location.pathname.indexOf("/groups")), {
          state: {activeKey: EntityDetailsTabKeys.WorkerReportGroups},
        });
        appStore.showMessage(
          WebHelper.formatMessage("WorkerReportGroupDetail-deleteSuccess", {groupName: workerReportGroup!.name}),
          "success"
        );
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerReportGroupDetail-deleteError"), response.correlationId);
    },
  });

  const removeWorkerFromGroupMutation = useMutation({
    mutationFn: (group: Models.WorkerReportGroup) => {
      return workerReportGroupStore.modifyWorkerReportGroupWorkers({
        accessToken: authenticationStore.state.accessToken!,
        id: group.id,
        worker_ids: group.workers?.filter((worker) => worker.id !== selectedWorker?.id).map((worker) => worker.id) ?? [],
      });
    },
    onSuccess: (response) => {
      if (response.success) {
        appStore.sendAnalyticTrack(SegmentKey.AddRemoveWorkerFromGroupSingle, {
          workerID: selectedWorker?.id,
          orgID: workerReportGroup?.organization.id,
          siteID: workerReportGroup?.site.id,
          entryPoint: SegmentEntryPoint.DELETE_WORKER_BUTTON,
        });

        appStore.showMessage(
          WebHelper.formatMessage("WorkerReportGroupDetail-removeWorkerSuccess", {workerName: selectedWorker!.displayName}),
          "success"
        );
        setIsRemoveModalOpen(false);
        setWorkerReportGroup(response.workerReportGroup);
      } else WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerReportGroupDetail-removeWorkerError"), response.correlationId);
    },
  });

  useEffect(() => {
    if (!workerReportGroup) return;
    const workers = workerReportGroup.workers;

    switch (selectedTag) {
      case Models.WorkersTagsKey.All:
        setWorkersFiltered(workers?.filter((worker) => worker.displayName.toLowerCase().includes(searchValue.toLowerCase())) ?? []);
        break;
      case Models.WorkersTagsKey.Enrolled:
        setWorkersFiltered(
          workers?.filter((worker) => worker.assignable && worker.displayName.toLowerCase().includes(searchValue.toLowerCase())) ?? []
        );
        break;
      case Models.WorkersTagsKey.Unenrolled:
        setWorkersFiltered(
          workers?.filter((worker) => !worker.assignable && worker.displayName.toLowerCase().includes(searchValue.toLowerCase())) ?? []
        );
        break;
    }
  }, [searchValue, selectedTag, workerReportGroup]);

  const handleRemoveWorkerFromGroup = (worker: Models.WorkerInWorkerReportGroup) => {
    setSelectedWorker(worker);
    setIsRemoveModalOpen(true);
  };

  const handleTagChange = (tag: Models.WorkersTagsKey) => {
    setUserPreferences({
      workerReportGroupDetailSelectedTag: tag,
    });
    setSelectedTag(tag);
  };

  const handleSettingsChange = (action: string, newSettings?: {quickFilter?: Models.WorkersTagsKey; tableSettings?: TableSettings}) => {
    if (action) {
      appStore.sendAnalyticTrack(SegmentKey.WorkerReportGroupWorkersTableSettingsChanged, {
        orgID: workerReportGroup?.organization.id,
        siteID: workerReportGroup?.site_id,
        pagination: newSettings?.tableSettings ? newSettings?.tableSettings?.pagination : tableSettings?.pagination,
        sorter: newSettings?.tableSettings ? newSettings?.tableSettings?.sorter : tableSettings?.sorter,
        filters: newSettings?.tableSettings ? newSettings?.tableSettings?.filters : tableSettings?.filters,
        quickFilter: newSettings?.quickFilter ?? selectedTag,
        action: action,
      });
    }
  };

  if (authenticationStore.isUserGlobal) {
    breadcrumbs.push({
      id: "organizations",
      url: "/organizations",
      name: WebHelper.formatMessage("WorkerReportGroupDetail-allOrganizations"),
    });
  }

  breadcrumbs.push(
    {
      id: "organization",
      url: `/organizations/${workerReportGroup?.organization.id}`,
      name: workerReportGroup?.organization.name,
    },
    {
      id: "site",
      url: `/organizations/${workerReportGroup?.organization.id}/sites/${workerReportGroup?.site.id}`,
      name: workerReportGroup?.site.name,
    },
    {
      id: "workerGroup",
      url: `/organizations/${workerReportGroup?.organization.id}/sites/${workerReportGroup?.site.id}/groups/${workerReportGroup?.id}`,
      name: workerReportGroup?.name,
    }
  );

  if (workerGroupQuery.isPending) return <Loading />;

  return (
    <>
      <Subheader breadcrumbItems={breadcrumbs} />
      <div className={styles.innerContentSpace}>
        <PageTitle
          title={workerReportGroup?.name ?? ""}
          icon={<AntdButton className={styles.backButton} onClick={handleBackButton} icon={<ArrowLeftOutlined />} />}
          actions={[
            workerReportGroup &&
              authenticationStore.permissionLevel(Models.UserScope.Site, workerReportGroup.site_id) === Models.UserAccessLevel.Admin && (
                <Button shape="round" icon={<DeleteOutlined />} onClick={() => setIsDeleteModalOpen(true)}>
                  {WebHelper.formatMessage("Common-delete")}
                </Button>
              ),
            workerReportGroup &&
              (authenticationStore.permissionLevel(Models.UserScope.Site, workerReportGroup.site_id) === Models.UserAccessLevel.Admin ||
                authenticationStore.permissionLevel(Models.UserScope.Site, workerReportGroup.site_id) ===
                  Models.UserAccessLevel.Manager) && (
                <>
                  <Button shape="round" icon={<EditOutlined />} onClick={() => setIsRenameModalOpen(true)}>
                    {WebHelper.formatMessage("WorkerReportGroupDetail-rename")}
                  </Button>
                  <Button shape="round" type={ButtonType.Primary} icon={<PlusOutlined />} onClick={() => setIsAddWorkersModalOpen(true)}>
                    {WebHelper.formatMessage("WorkerReportGroupDetail-addWorkers")}
                  </Button>
                </>
              ),
          ]}
        />
        <div>
          <WorkersFilters
            selectedTag={selectedTag}
            setSelectedTag={handleTagChange}
            setSearchValue={setSearchValue}
            entityType={Models.EntityType.WorkerReportGroup}
            onFilterChange={handleSettingsChange}
          />
          {workerReportGroup?.site_id && (
            <WorkerReportGroupWorkersTable
              orgID={workerReportGroup.organization.id}
              siteID={workerReportGroup.site_id}
              workers={workersFiltered}
              loading={workerGroupQuery.isFetching}
              onRemove={handleRemoveWorkerFromGroup}
              filterValue={searchValue}
              setWorkerId={setVisibleWorkerId}
              setTableSettings={setTableSettings}
              onTableChange={handleSettingsChange}
            />
          )}
        </div>
      </div>
      {workerReportGroup && (
        <RenameWorkerGroupModal
          open={isRenameModalOpen}
          onOk={(updatedWorkerReportGroup: Models.WorkerReportGroup) => {
            setWorkerReportGroup(updatedWorkerReportGroup);
            setIsRenameModalOpen(false);
          }}
          onCancel={() => setIsRenameModalOpen(false)}
          workerReportGroup={workerReportGroup}
        />
      )}
      {workerReportGroup && (
        <AddWorkersModal
          open={isAddWorkersModalOpen}
          onOk={(updatedWorkerReportGroup: Models.WorkerReportGroup) => {
            setWorkerReportGroup(updatedWorkerReportGroup);
            setIsAddWorkersModalOpen(false);
          }}
          onCancel={() => setIsAddWorkersModalOpen(false)}
          workerReportGroup={workerReportGroup}
        />
      )}
      {workerReportGroup && (
        <ConfirmationModal
          showConfirmationModal={isDeleteModalOpen}
          loading={deleteWorkerGroupMutation.isPending}
          title={WebHelper.formatMessage("WorkerReportGroupDetail-deleteAlertTitle")}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage("WorkerReportGroupDetail-deleteAlertMessage", {groupName: workerReportGroup.name})
                ),
              }}
            />
          }
          onOk={() => deleteWorkerGroupMutation.mutate(workerReportGroup)}
          onCancel={() => setIsDeleteModalOpen(false)}
          okButtonText={WebHelper.formatMessage("Common-delete")}
          referenceConfirmationValue={WebHelper.formatMessage("Common-deleteConfirmationValue")}
        />
      )}
      {selectedWorker && workerReportGroup && (
        <ConfirmationModal
          showConfirmationModal={isRemoveModalOpen}
          loading={removeWorkerFromGroupMutation.isPending}
          title={WebHelper.formatMessage("WorkerReportGroupDetail-removeWorkerModalTitle")}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: WebHelper.parseMarkdown(
                  WebHelper.formatMessage("WorkerReportGroupDetail-removeWorkerAlertMessage", {workerName: selectedWorker.displayName})
                ),
              }}
            />
          }
          onOk={() => removeWorkerFromGroupMutation.mutate(workerReportGroup)}
          onCancel={() => setIsRemoveModalOpen(false)}
          okButtonText={WebHelper.formatMessage("Common-remove")}
          referenceConfirmationValue={WebHelper.formatMessage("Common-removeConfirmationValue")}
        />
      )}
      <Drawer
        open={!_.isNil(visibleWorkerId)}
        width={WebHelper.drawerWidth}
        title={WebHelper.formatMessage("WorkerDetail-drawerTitle")}
        destroyOnClose
        onClose={handleDrawerClose}>
        {!_.isNil(visibleWorkerId) && (
          <WorkerDetail workerId={visibleWorkerId} onDelete={handleDeleteOk} onEdit={() => workerGroupQuery.refetch()} />
        )}
      </Drawer>
    </>
  );
});
