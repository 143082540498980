import {SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Cascader, Input, Tooltip, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useMemo, useState} from "react";

import {DeviceManagementSelectOptions} from "../../../../../core/apiclient/device/DeviceAPIClient.types";
import {DeviceManagementTabKeys as TabKeys} from "../../../../../core/models/UserPreferences";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DepartmentDataStore} from "../../../../../core/stores/DepartmentDataStore";
import {Button, ButtonType} from "../../../../components/common/button/Button";
import {WebHelper} from "../../../../utils/WebHelper";
import styles from "./DeviceManagementSearchBar.module.scss";

export type DeviceManagementSearchBarProps = {
  selectedOrganizationId: string | null;
  selectedSiteId: string | null;
  selectedDepartmentId: string | null;
  onChangeEntity: (values: string[] | undefined) => void;
  onSearch: () => void;
  onChangeSerialNumber: (serialNumber: string) => void;
  onChangeDeviceTag: (deviceTag: string) => void;
  serialNumberSearchValue: string;
  deviceTagSearchValue: string;
  activeTab: TabKeys;
  searchButtonDisabeld: boolean;
};

type Option = {
  value: string;
  label: string;
  children?: Option[];
  isLeaf?: boolean;
  loading?: boolean;
};

export const DeviceManagementSearchBar: FunctionComponent<DeviceManagementSearchBarProps> = observer(
  ({
    selectedOrganizationId,
    selectedSiteId,
    selectedDepartmentId,
    onChangeEntity,
    onSearch,
    onChangeDeviceTag,
    onChangeSerialNumber,
    serialNumberSearchValue,
    deviceTagSearchValue,
    activeTab,
    searchButtonDisabeld,
  }) => {
    const authenticationStore = AuthenticationDataStore.getInstance();
    const departmentStore = DepartmentDataStore.getInstance();

    const [options, setOptions] = useState<Option[]>([]);

    const dropdownDataQuery = useQuery({
      queryKey: ["DeviceManagementSearchBar-fetchDropdownData"],
      queryFn: () => departmentStore.departmentsDropdown({accessToken: authenticationStore.state.accessToken!}),
    });

    useEffect(() => {
      if (!dropdownDataQuery.data) return;
      if (dropdownDataQuery.data.success) {
        setOptions([
          {value: DeviceManagementSelectOptions.Unallocated, label: WebHelper.formatMessage("DeviceManagementSearchBar-unallocated")},
          ...dropdownDataQuery.data.organizations
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((org) => ({
              value: org.id,
              label: org.name,
              children: org.sites_info
                ?.sort((a, b) => a.name.localeCompare(b.name))
                .map((site) => ({
                  value: site.id,
                  label: site.name,
                  children: site.departments_info
                    ?.sort((a, b) => a.name.localeCompare(b.name))
                    .map((department) => ({
                      value: department.id,
                      label: department.name,
                      isLeaf: true,
                    })),
                })),
            })),
        ]);
      }
    }, [dropdownDataQuery.data]);

    const cascaderSelectedValues = useMemo(() => {
      const values = [];

      selectedOrganizationId && values.push(selectedOrganizationId);
      selectedSiteId && values.push(selectedSiteId);
      selectedDepartmentId && values.push(selectedDepartmentId);

      return values;
    }, [selectedDepartmentId, selectedOrganizationId, selectedSiteId]);

    return (
      <div className={styles.filtersWrapper}>
        <div className={styles.filterContainer}>
          <Typography.Text className={styles.label}>{WebHelper.formatMessage("DeviceManagementSearchBar-filter")}</Typography.Text>
          <Cascader
            placeholder={
              dropdownDataQuery.isPending
                ? WebHelper.formatMessage("DeviceManagementSearchBar-loadingOrganizations")
                : WebHelper.formatMessage("DeviceManagementSearchBar-selectOrganization")
            }
            changeOnSelect
            loading={dropdownDataQuery.isPending}
            disabled={dropdownDataQuery.isPending}
            value={cascaderSelectedValues}
            displayRender={(labels) =>
              dropdownDataQuery.isPending ? WebHelper.formatMessage("DeviceManagementSearchBar-loadingOrganizations") : labels.join(" / ")
            }
            onChange={(values) => {
              onChangeEntity(values as string[]);
            }}
            onBlur={() => onSearch()}
            className={styles.cascader}
            options={options}
            popupClassName={styles.cascaderPopup}
            showSearch
          />
        </div>
        <div className={styles.filterContainer}>
          <Typography.Text className={styles.label}>{WebHelper.formatMessage("DeviceManagementSearchBar-serialNumber")}</Typography.Text>
          <Input
            className={styles.filterInput}
            placeholder={WebHelper.formatMessage("DeviceManagementSearchBar-serialNumberPlaceholder")}
            allowClear
            value={serialNumberSearchValue}
            onChange={(e) => onChangeSerialNumber(e.target.value)}
            onPressEnter={() => onSearch()}
          />
        </div>
        {activeTab === TabKeys.DeviceConfig && (
          <div className={styles.filterContainer}>
            <Typography.Text className={styles.label}>{WebHelper.formatMessage("DeviceManagementSearchBar-deviceTag")}</Typography.Text>
            <Input
              className={styles.filterInput}
              placeholder={WebHelper.formatMessage("DeviceManagementSearchBar-deviceTagPlaceholder")}
              allowClear
              value={deviceTagSearchValue}
              onChange={(e) => onChangeDeviceTag(e.target.value)}
              onPressEnter={() => onSearch()}
            />
          </div>
        )}
        {!serialNumberSearchValue && !deviceTagSearchValue && !selectedOrganizationId ? (
          <Tooltip title={WebHelper.formatMessage("DeviceManagementSearchBar-searchButtonTooltip")}>
            <Button
              type={ButtonType.Primary}
              shape="round"
              icon={<SearchOutlined />}
              onClick={onSearch}
              disabled={(!serialNumberSearchValue && !deviceTagSearchValue && !selectedOrganizationId) || searchButtonDisabeld}>
              {WebHelper.formatMessage("Common-search")}
            </Button>
          </Tooltip>
        ) : (
          <Button
            type={ButtonType.Primary}
            shape="round"
            icon={<SearchOutlined />}
            onClick={onSearch}
            disabled={(!serialNumberSearchValue && !deviceTagSearchValue && !selectedOrganizationId) || searchButtonDisabeld}>
            {WebHelper.formatMessage("Common-search")}
          </Button>
        )}
      </div>
    );
  }
);
