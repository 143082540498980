import {Form, FormInstance, Input, Select, Typography} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {AppConfig} from "../../../AppConfig";
import {WebHelper} from "../../../utils/WebHelper";

export type SiteFormFields = {
  name: string;
  address: string;
  tz_location: string;
};

type SiteFormProps = {
  form: FormInstance<SiteFormFields>;
  initialValues?: Models.Site;
  loading: boolean;
  onFieldsChange: () => void;
};

export const SiteForm: FunctionComponent<SiteFormProps> = observer(({form, initialValues, loading, onFieldsChange}) => {
  const {siteForm: siteInputValidation} = AppConfig.Modules.InputValidation;
  const timezoneOptions = Intl.supportedValuesOf("timeZone").map((tz) => ({key: tz, label: tz, value: tz}));

  return (
    <Form
      form={form}
      labelWrap={true}
      labelCol={{span: 4}}
      wrapperCol={{span: 20}}
      disabled={loading}
      onFieldsChange={onFieldsChange}
      unselectable="off">
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-name")}</Typography.Text>}
        name="name"
        initialValue={initialValues?.name}
        rules={WebHelper.formatInputValidationRules(siteInputValidation.name.rules)}>
        <Input />
      </Form.Item>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-address")}</Typography.Text>}
        name="address"
        initialValue={initialValues?.address}
        rules={WebHelper.formatInputValidationRules(siteInputValidation.address.rules)}>
        <Input />
      </Form.Item>
      <Form.Item
        colon={false}
        label={<Typography.Text>{WebHelper.formatMessage("EntityDetail-timezone")}</Typography.Text>}
        name="tz_location"
        initialValue={initialValues?.tz_location}
        rules={WebHelper.formatInputValidationRules(siteInputValidation.timezone.rules)}>
        <Select showSearch optionFilterProp="label" optionLabelProp="label" options={timezoneOptions} />
      </Form.Item>
    </Form>
  );
});
