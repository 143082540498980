import {FilterOutlined} from "@ant-design/icons";
import {Drawer} from "antd";
import {ColumnsType} from "antd/lib/table";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import * as Models from "../../../core/models";
import {DeviceManagementTabKeys as TabKeys} from "../../../core/models/UserPreferences";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {WebHelper} from "../../utils/WebHelper";
import {LinkButton} from "../common/link-button/LinkButton";
import {Table} from "../common/table/Table";
import styles from "../devices-table/device-management-devices-table/DeviceManagementDevicesTable.module.scss";
import {GatewayDetails} from "./gateway-details/GatewayDetails";
import {GatewaysTableLastSeenColumnRender} from "./gateways-table-last-seen-column-render/GatewaysTableLastSeenColumnRender";

export type GatewaysTableProps = {
  gateways: Models.Gateway[];
  loading?: boolean;
  setIsEditingFilter: (open: boolean) => void;
  tableSettings: Models.GatewayTableSettings;
  updatePreferences: (updatedSettings: {
    updatedDeviceManagementDevicesTableSettings?: Partial<Models.DeviceManagementDevicesTableSettings>;
    updatedGatewayTableSettings?: Partial<Models.GatewayTableSettings>;
    updatedDeviceManagementActiveTabKey?: TabKeys;
  }) => void;
};

type ObtainStringFieldsKeys<Obj> = {
  [Prop in keyof Obj]: Obj[Prop] extends string ? Prop : never;
}[keyof Obj];

export const GatewaysTable: FunctionComponent<GatewaysTableProps> = observer((props) => {
  const appStore = AppStore.getInstance();

  const [selectedGateway, setSelectedGateway] = useState<Models.Gateway | null>(null);
  const [isDetailsDrawerOpen, setIsDetailsDrawerOpen] = useState(false);

  const {pageSize, sortColumn, sortOrder, connectedFilteredValue, firmwareFilteredValue} = props.tableSettings;

  const filterOptionsForStringField = (key: ObtainStringFieldsKeys<Models.Gateway>) => {
    return _.uniqWith(
      props.gateways
        .filter((gateway) => !_.isNil(gateway[key]) && gateway[key].length > 0)
        .map((gateway) => {
          return {
            text: gateway[key],
            value: gateway[key],
          };
        }),
      _.isEqual
    ).sort((a, b) => a.text.localeCompare(b.text));
  };

  const columns: ColumnsType<Models.Gateway> = [
    {
      title: WebHelper.formatMessage("GatewaysTable-serialNumberColumnTitle"),
      dataIndex: "serial_number",
      render: (serial_number, record) => (
        <LinkButton
          onClick={() => {
            setSelectedGateway(record);
            setIsDetailsDrawerOpen(true);
          }}>
          {serial_number}
        </LinkButton>
      ),
      sorter: (a, b) => a.serial_number.localeCompare(b.serial_number),
      sortOrder: sortColumn === "serial_number" ? sortOrder : undefined,
    },
    {
      title: WebHelper.formatMessage("GatewaysTable-lastSeenColumnTitle"),
      key: "last_seen",
      render: (_, record) => (
        <GatewaysTableLastSeenColumnRender
          mostRecentGatewayEventAt={record.most_recent_gateway_event_at}
          siteTimezone={record.site?.tz_location}
          status={record.status}
        />
      ),
      sortOrder: sortColumn === "last_seen" ? sortOrder : undefined,
      onFilterDropdownOpenChange(open) {
        props.setIsEditingFilter(open);
      },
      sorter: (a: Models.Gateway, b: Models.Gateway) => {
        const connectionState = (gateway: Models.Gateway) =>
          gateway.status === Models.GatewayStatus.Up
            ? 4
            : gateway.status === Models.GatewayStatus.Intermittent
              ? 3
              : gateway.status === Models.GatewayStatus.Down
                ? 2
                : 1;

        const statusComparison = connectionState(a) - connectionState(b);
        if (statusComparison !== 0) {
          return statusComparison;
        }

        if (a.status === Models.GatewayStatus.Down && b.status === Models.GatewayStatus.Down) {
          const aDate = a.most_recent_gateway_event_at ? new Date(a.most_recent_gateway_event_at) : new Date(0);
          const bDate = b.most_recent_gateway_event_at ? new Date(b.most_recent_gateway_event_at) : new Date(0);

          return bDate.getTime() - aDate.getTime();
        }

        return 0;
      },

      filters: [
        {value: Models.GatewayStatus.Up, text: WebHelper.formatMessage("GatewaysTable-upLabel")},
        {value: Models.GatewayStatus.Down, text: WebHelper.formatMessage("GatewaysTable-downLabel")},
        {value: Models.GatewayStatus.Intermittent, text: WebHelper.formatMessage("GatewaysTable-intermittentLabel")},
        {value: Models.GatewayStatus.NeverSeen, text: WebHelper.formatMessage("GatewaysTable-neverSeenLabel")},
      ],
      filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
      filteredValue: connectedFilteredValue,
      onFilter: (value, record) => record.status === value,
      filterSearch: true,
    },
    {
      title: WebHelper.formatMessage("GatewaysTable-firmwareColumnTitle"),
      dataIndex: "current_firmware_version",
      filters: filterOptionsForStringField("current_firmware_version"),
      filterIcon: (filtered) => <FilterOutlined className={filtered ? styles.filteredIcon : ""} />,
      onFilterDropdownOpenChange(open) {
        props.setIsEditingFilter(open);
      },
      filteredValue: firmwareFilteredValue,
      onFilter: (value, record) => record.current_firmware_version === value,
      filterSearch: true,
    },
  ];

  return (
    <>
      <Table
        onChange={(pagination, filters, sorter, extra) => {
          appStore.sendAnalyticTrack(SegmentKey.GatewaysTableSettingsChanged, {pagination, filters, sorter, action: extra.action});
          if (extra.action === "filter") {
            props.updatePreferences({
              updatedGatewayTableSettings: {
                connectedFilteredValue: filters.last_seen,
                firmwareFilteredValue: filters.current_firmware_version,
              },
            });
          }
          if (extra.action === "sort" && !Array.isArray(sorter)) {
            props.updatePreferences({
              updatedGatewayTableSettings: {
                sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
                sortOrder: sorter.order,
              },
            });
          }
        }}
        loading={props.loading ?? false}
        columns={columns}
        dataSource={props.gateways}
        pagination={{
          showSizeChanger: true,
          pageSize,
          onShowSizeChange: (_, size) => props.updatePreferences({updatedGatewayTableSettings: {pageSize: size}}),
        }}
      />

      {!_.isNil(selectedGateway) && (
        <Drawer
          open={isDetailsDrawerOpen}
          width={WebHelper.drawerWidth}
          title={WebHelper.formatMessage("GatewaysTable-gatewayDetailsDrawerTitle")}
          destroyOnClose
          onClose={() => {
            setSelectedGateway(null);
            setIsDetailsDrawerOpen(false);
          }}>
          <GatewayDetails gateway={selectedGateway} />
        </Drawer>
      )}
    </>
  );
});
