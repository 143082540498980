import {useMutation} from "@tanstack/react-query";
import {Modal} from "antd";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useMemo} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint, SegmentResourceType} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";

export type ChangeDeviceModalProps = {
  worker: Models.Worker | Models.WorkerShort;
  open: boolean;
  newDevice?: Models.DeviceShort;
  onClose: () => void;
  refetchWorkers: () => void;
  setShowDeviceNoLongerWorker: (showDeviceNoLongerWorker: boolean) => void;
  entryPoint: SegmentEntryPoint;
};

export const ChangeDeviceModal: FunctionComponent<ChangeDeviceModalProps> = observer(
  ({worker, open, newDevice, onClose, refetchWorkers, setShowDeviceNoLongerWorker, entryPoint}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();
    const workerStore = WorkerDataStore.getInstance();

    const assignDeviceToWorkerMutation = useMutation({
      mutationFn: () =>
        workerStore.assignDeviceToWorker({
          accessToken: authenticationStore.state.accessToken!,
          worker_id: worker.id,
          assignable: true,
          device_id: newDevice?.id ?? null,
        }),
      onSuccess: (response) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("ChangeDeviceModal-assignError"), response.correlationId);
        } else {
          appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceAssignment, {
            departmentID: response.worker.department.id,
            orgID: response.worker.organization?.id,
            siteID: response.worker.site?.id,
            resourceType: SegmentResourceType.WORKER,
            resourceID: response.worker.id,
            endAssigned: !!response.worker.assigned_device,
            startAssigned: !!worker.assigned_device,
            entryPoint,
          });

          let message;
          if (!worker?.assigned_device) {
            message = WebHelper.formatMessage("ChangeDeviceModal-assignDeviceSuccessMessage", {
              workerName: worker.displayName,
              deviceTag: newDevice!.device_tag,
            });
            appStore.showMessage(message, "success");
          }

          if (
            (_.isNil(response.worker.assigned_device) && worker.assigned_device) ||
            (!_.isNil(response.worker.assigned_device) &&
              !_.isNil(worker.assigned_device) &&
              response.worker.assigned_device.id !== worker.assigned_device.id)
          ) {
            setShowDeviceNoLongerWorker(true);
          }
          onClose();
          refetchWorkers();
        }
      },
    });

    const modalTitle = useMemo(() => {
      if (!worker?.assigned_device) {
        return WebHelper.formatMessage("ChangeDeviceModal-assignDevice");
      } else {
        if (!newDevice) return WebHelper.formatMessage("ChangeDeviceModal-unassignDevice");
        else return WebHelper.formatMessage("ChangeDeviceModal-changeDevice");
      }
    }, [worker?.assigned_device, newDevice]);

    const modalMessage = useMemo(() => {
      if (!worker?.assigned_device) {
        return WebHelper.formatMessage("ChangeDeviceModal-assignDeviceMessage", {
          workerName: worker.displayName,
          deviceTag: newDevice!.device_tag,
        });
      } else {
        if (!newDevice)
          return WebHelper.formatMessage("ChangeDeviceModal-unassignDeviceMessage", {
            workerName: worker.displayName,
            deviceTag: worker.assigned_device.device_tag,
          });
        else
          return WebHelper.formatMessage("ChangeDeviceModal-changeDeviceMessage", {
            workerName: worker.displayName,
            deviceTag: worker.assigned_device.device_tag,
            newDeviceTag: newDevice.device_tag,
          });
      }
    }, [worker?.assigned_device, worker?.displayName, newDevice]);

    return (
      <Modal
        centered
        open={open}
        destroyOnClose={true}
        title={modalTitle}
        okText={WebHelper.formatMessage("Common-confirm")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={() => assignDeviceToWorkerMutation.mutate()}
        cancelButtonProps={{shape: "round", disabled: assignDeviceToWorkerMutation.isPending}}
        okButtonProps={{
          shape: "round",
          disabled: assignDeviceToWorkerMutation.isPending,
          loading: assignDeviceToWorkerMutation.isPending,
        }}
        onCancel={onClose}>
        {
          <div
            dangerouslySetInnerHTML={{
              __html: WebHelper.parseMarkdown(modalMessage),
            }}
          />
        }
      </Modal>
    );
  }
);
