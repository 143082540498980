import {useMutation, useQuery} from "@tanstack/react-query";
import {Form, Modal, Select, Switch, Typography} from "antd";
import {useFlags} from "launchdarkly-react-client-sdk";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {SegmentEntryPoint, SegmentResourceType} from "web/utils/SegmentHelper";

import * as Models from "../../../../core/models";
import {AuthenticationDataStore} from "../../../../core/stores/AuthenticationDataStore";
import {DeviceDataStore} from "../../../../core/stores/DeviceDataStore";
import {WorkerDataStore} from "../../../../core/stores/WorkerDataStore";
import {AppStore, SegmentKey} from "../../../stores/AppStore";
import {WebHelper} from "../../../utils/WebHelper";
import {DiscardChangesModal} from "../../discard-changes-modal/DiscardChangesModal";
import {WorkerNoDevice} from "../../entity-details/devices-tab-content/worker-no-device/WorkerNoDevice";
import styles from "./DeviceEditModal.module.scss";

export type DeviceEditFormFields = {
  system_serial_number: string;
  assigned_worker_id: string;
  status: boolean;
};

export type DeviceEditModalProps = {
  device: Models.Device;
  open: boolean;
  onClose: () => void;
  onUpdate: (device: Models.Device) => void;
};

export const DeviceEditModal: FunctionComponent<DeviceEditModalProps> = observer(({device, open, onClose, onUpdate}) => {
  const appStore = AppStore.getInstance();
  const authenticationStore = AuthenticationDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();
  const workerStore = WorkerDataStore.getInstance();

  const flags = useFlags();

  const [assignableValue, setAssignableValue] = useState(device.assignable);
  const [archivedValue, setArchivedValue] = useState(!!device.archived_at);
  const [availableWorkers, setAvailableWorkers] = useState<Models.WorkerShort[]>([]);
  const [selectedWorkerId, setSelectedWorkerId] = useState<string | null>(device.assigned_worker?.id ?? "");
  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true);
  const [showDiscardModal, setShowDiscardModal] = useState(false);
  const [previousWorker, setPreviousWorker] = useState<Models.BasicWorker | null>(device.assigned_worker);
  const [showWorkerNoLongerDevice, setShowWorkerNoLongerDevice] = useState(false);
  const [currentDevice, setCurrentDevice] = useState<Models.Device>(device);

  const [form] = Form.useForm<DeviceEditFormFields>();

  const {isFetching: workersQueryIsFetching, refetch: workersQueryRefetch} = useQuery({
    queryKey: ["DeviceEditModal-fetchWorkersData"],
    queryFn: () =>
      workerStore.workersShort({
        accessToken: authenticationStore.state.accessToken!,
        department_id: currentDevice.department?.id,
      }),
    enabled: false,
  });

  useEffect(() => {
    //hotfix: device edit modal isn't cleaning up data correctly, so we "reinitialize" manually the sates
    setCurrentDevice(device);
    setAssignableValue(device.assignable);
    setArchivedValue(!!device.archived_at);
  }, [device, form]);

  const assignWorkerToDeviceMutation = useMutation({
    mutationFn: (assignedWorkerId: string) =>
      deviceStore.assignWorkerToDevice({
        accessToken: authenticationStore.state.accessToken!,
        device_id: currentDevice.id,
        assignable: assignableValue,
        worker_id: assignableValue ? (assignedWorkerId !== "" ? assignedWorkerId : null) : null,
      }),
  });

  const archiveDeviceMutation = useMutation({
    mutationFn: () =>
      deviceStore.archiveDevice({
        accessToken: authenticationStore.state.accessToken!,
        device_id: currentDevice.id,
      }),
    onSuccess: (response) => {
      if (!response.success) {
        WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceEditModal-updateError"), response.correlationId);
        return;
      }

      setCurrentDevice(response.device);
      if (onUpdate) onUpdate(response.device);
      appStore.showMessage(WebHelper.formatMessage("DeviceEditModal-archiveDeviceSuccess"), "success");
    },
  });

  const assignedWorkerItemOptions = useMemo(() => {
    const options = [
      getSelectOption("", WebHelper.formatMessage("DeviceEditModal-none")),
      ...availableWorkers.map((worker) => getSelectOption(worker.id, worker.displayName ?? worker.employee_id)),
    ];
    if (device.assigned_worker) options.unshift(getSelectOption(device.assigned_worker.id, device.assigned_worker.displayName));
    return options;
  }, [availableWorkers, device.assigned_worker]);

  const fetchAvailableWorkers = useCallback(async () => {
    const response = await workersQueryRefetch();
    if (response.data?.success) {
      const availableWorkersResponse = response.data.workers.filter((worker) => worker.assignable && _.isNil(worker.device_id));
      setAvailableWorkers(availableWorkersResponse);
      if (
        _.isNil(availableWorkersResponse.find((worker) => worker.id === selectedWorkerId)) &&
        selectedWorkerId !== device.assigned_worker?.id
      ) {
        form.setFieldsValue({assigned_worker_id: undefined});
        setSelectedWorkerId(null);
      }
    }
  }, [workersQueryRefetch, selectedWorkerId, device.assigned_worker?.id, form]);

  const handleOk = async () => {
    form
      .validateFields()
      .then(async (values) => {
        setPreviousWorker(currentDevice.assigned_worker);
        setCurrentDevice(currentDevice);
        assignWorkerToDeviceMutation.mutate(values["assigned_worker_id"], {
          onSuccess: (assignWorkerResponse) => {
            if (assignWorkerResponse.success) {
              appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceAssignment, {
                departmentID: assignWorkerResponse.device?.department?.id,
                orgID: assignWorkerResponse.device?.organization?.id,
                siteID: assignWorkerResponse.device.site?.id,
                resourceType: SegmentResourceType.DEVICE,
                resourceID: assignWorkerResponse.device.id,
                endAssigned: !!assignWorkerResponse.device.assigned_worker,
                startAssigned: !!device.assigned_worker,
                entryPoint: SegmentEntryPoint.EDIT_DEVICE_MODAL,
              });

              if (device.assignable !== assignableValue) {
                appStore.sendAnalyticTrack(SegmentKey.ChangeDeviceStatus, {
                  departmentID: assignWorkerResponse.device.department?.id,
                  siteID: assignWorkerResponse.device.site?.id,
                  orgID: assignWorkerResponse.device.organization?.id,
                  endAssignable: assignableValue,
                  entryPoint: SegmentEntryPoint.EDIT_DEVICE_MODAL,
                });
              }

              if (
                (_.isNil(assignWorkerResponse.device.assigned_worker) && currentDevice.assigned_worker) ||
                (!_.isNil(assignWorkerResponse.device.assigned_worker) &&
                  !_.isNil(currentDevice.assigned_worker) &&
                  assignWorkerResponse.device.assigned_worker.id !== currentDevice.assigned_worker.id)
              ) {
                setShowWorkerNoLongerDevice(true);
              }

              setCurrentDevice(assignWorkerResponse.device);
              if (onUpdate) onUpdate(assignWorkerResponse.device);
              if (!device.archived_at && archivedValue) {
                archiveDeviceMutation.mutate();
              } else {
                appStore.showMessage(WebHelper.formatMessage("DeviceEditModal-updateDeviceSuccess"), "success");
              }
            } else WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceEditModal-updateError"), assignWorkerResponse.correlationId);
          },
        });
      })
      //this catch prevents antd from throwing an error to console when doesn't pass form validations
      .catch(() => {});
  };

  const handleOnFieldsChange = () => {
    if (!form.isFieldsTouched() || !!form.getFieldsError().filter(({errors}) => errors.length).length) {
      setIsSaveButtonDisabled(true);
    } else {
      setIsSaveButtonDisabled(false);
    }
  };

  const handleCancel = () => {
    if (form.isFieldsTouched() && !showDiscardModal) setShowDiscardModal(true);
    else {
      setShowDiscardModal(false);
      setAssignableValue(device.assignable);
      onClose();
      form.resetFields();
    }
  };

  const updatingDevice = useMemo(() => assignWorkerToDeviceMutation.isPending, [assignWorkerToDeviceMutation.isPending]);

  return (
    <>
      <Modal
        centered
        open={open}
        destroyOnClose={true}
        title={WebHelper.formatMessage("DeviceEditModal-title")}
        okText={WebHelper.formatMessage("Common-save")}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onOk={handleOk}
        onCancel={handleCancel}
        afterClose={() => form.resetFields()}
        okButtonProps={{
          shape: "round",
          disabled: isSaveButtonDisabled,
          loading: updatingDevice,
        }}
        cancelButtonProps={{shape: "round", disabled: updatingDevice}}>
        <Form
          labelCol={{sm: {span: 4}, lg: {span: 6}}}
          labelWrap={true}
          form={form}
          disabled={updatingDevice}
          unselectable="off"
          onFieldsChange={handleOnFieldsChange}
          onFinish={handleOk}>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("DeviceEditModal-systemSerialNumber")}</Typography.Text>}
            name="system_serial_number"
            initialValue={device.system_serial_number}>
            <Typography.Text>{device.system_serial_number}</Typography.Text>
          </Form.Item>
          <Form.Item
            colon={false}
            label={<Typography.Text>{WebHelper.formatMessage("DeviceEditModal-worker")}</Typography.Text>}
            name="assigned_worker_id"
            initialValue={device.assigned_worker?.id ?? ""}>
            <Select
              disabled={!assignableValue}
              value={selectedWorkerId}
              onChange={setSelectedWorkerId}
              options={assignedWorkerItemOptions}
              optionFilterProp="label"
              showSearch
              onDropdownVisibleChange={(open) => {
                if (open) fetchAvailableWorkers();
              }}
              dropdownRender={(node) => (workersQueryIsFetching ? WebHelper.LoadingSpin : node)}
              data-cy="assignedWorkerSelect"
            />
          </Form.Item>

          <Form.Item colon={false} label={<Typography.Text>{WebHelper.formatMessage("DeviceEditModal-status")}</Typography.Text>}>
            <Form.Item name="assignable" noStyle initialValue={device.assignable}>
              <Switch
                defaultChecked={device.assignable}
                checked={assignableValue}
                onChange={(value) => {
                  setAssignableValue(value);
                  if (!value) {
                    form.setFieldsValue({assigned_worker_id: ""});
                    setSelectedWorkerId(null);
                  }
                }}
                data-cy="statusSwitch"
              />
            </Form.Item>
            <Typography.Text className={styles.statusText}>
              {assignableValue
                ? WebHelper.formatMessage("DeviceEditModal-inService")
                : WebHelper.formatMessage("DeviceEditModal-outOfService")}
            </Typography.Text>
          </Form.Item>

          {!assignableValue &&
            flags.archive_devices &&
            authenticationStore.isUserGlobal &&
            authenticationStore.permissionLevel(Models.UserScope.Global) === Models.UserAccessLevel.Admin && (
              <>
                <Form.Item
                  colon={false}
                  label={<Typography.Text>{WebHelper.formatMessage("DeviceEditModal-archived")}</Typography.Text>}
                  name="archived"
                  initialValue={archivedValue}>
                  <Select
                    value={archivedValue}
                    onChange={setArchivedValue}
                    options={[
                      {key: true, label: WebHelper.formatMessage("Common-true"), value: true},
                      {key: false, label: WebHelper.formatMessage("Common-false"), value: false},
                    ]}
                  />
                </Form.Item>
                {archivedValue && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(WebHelper.formatMessage("DeviceEditModal-archivedTrueMessage")),
                    }}
                  />
                )}
              </>
            )}
        </Form>
      </Modal>
      <DiscardChangesModal onDiscard={handleCancel} onClose={() => setShowDiscardModal(false)} open={showDiscardModal} />
      {showWorkerNoLongerDevice && device.department_id && previousWorker && (
        <WorkerNoDevice
          worker={previousWorker}
          departmentId={device.department_id}
          onOk={() => {
            setShowWorkerNoLongerDevice(false);
            onUpdate(device);
          }}
          onClose={() => setShowWorkerNoLongerDevice(false)}
          open={showWorkerNoLongerDevice}
          entryPoint={SegmentEntryPoint.EDIT_DEVICE_MODAL}
        />
      )}
    </>
  );
});

function getSelectOption(id: string, name: string) {
  return {key: id, label: name, value: id};
}
