import {useQuery} from "@tanstack/react-query";
import {Spin} from "antd";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useEffect, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {DepartmentDataStore} from "../../../../../core/stores/DepartmentDataStore";
import {DeviceDataStore} from "../../../../../core/stores/DeviceDataStore";
import {OrganizationDataStore} from "../../../../../core/stores/OrganizationDataStore";
import {SiteDataStore} from "../../../../../core/stores/SiteDataStore";

export type EntityNameProps = {
  id?: string;
  type?: Models.EntityType;
};

export const EntityName: FunctionComponent<EntityNameProps> = observer((props) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const departmentStore = DepartmentDataStore.getInstance();
  const organizationStore = OrganizationDataStore.getInstance();
  const siteStore = SiteDataStore.getInstance();
  const deviceStore = DeviceDataStore.getInstance();

  const [name, setName] = useState(props.id);
  const [loading, setLoading] = useState(true);

  const {refetch: deviceQueryRefetch} = useQuery({
    queryKey: ["EntityName-FetchDevice"],
    queryFn: () => deviceStore.device({accessToken: authenticationStore.state.accessToken!, id: props.id!}),
    enabled: false,
  });

  useEffect(() => {
    const getSiteFromOrganizations = (siteId?: string): Models.SiteInOrganization | undefined => {
      if (!siteId) return undefined;

      let site = undefined;
      organizationStore.state.organizations?.some((o) => {
        site = o.sites?.find((s) => s.id === siteId);

        return !_.isNil(site);
      });

      return site;
    };

    const getDepartmentFromOrganizations = (departmentId?: string): Models.DepartmentInSite | undefined => {
      if (!departmentId) return undefined;

      let department: Models.DepartmentInSite | undefined = undefined;
      organizationStore.state.organizations?.some((o) => {
        return o.sites?.some((s) => {
          department = s.departments?.find((d) => d.id === departmentId);

          return !_.isNil(department);
        });
      });

      return department;
    };

    switch (props.type) {
      case Models.EntityType.Organization: {
        if (
          !organizationStore.state.loadingOrganizations &&
          !organizationStore.state.loadingOrganization &&
          !_.isNil(organizationStore.state.organizations)
        ) {
          const organization = organizationStore.state.organizations.find((org) => org.id === props.id);
          if (!_.isNil(organization)) {
            setName(organization.name);
            setLoading(false);
          }
        }

        break;
      }
      case Models.EntityType.Site: {
        let site = null;
        if (!siteStore.state.loadingSites && !siteStore.state.loadingSite && !_.isNil(siteStore.state.sites)) {
          site = siteStore.state.sites.find((s) => s.id === props.id);
        }

        if (
          _.isNil(site) &&
          !organizationStore.state.loadingOrganizations &&
          !organizationStore.state.loadingOrganization &&
          !_.isNil(organizationStore.state.organizations)
        ) {
          site = getSiteFromOrganizations(props.id);
        }

        if (!_.isNil(site)) {
          setName(site.name);
          setLoading(false);
        }

        break;
      }
      case Models.EntityType.Department: {
        let department = null;
        if (
          !departmentStore.state.loadingDepartments &&
          !departmentStore.state.loadingDepartment &&
          !_.isNil(departmentStore.state.departments)
        ) {
          department = departmentStore.state.departments.find((dep) => dep.id === props.id);
        }

        if (
          _.isNil(department) &&
          !organizationStore.state.loadingOrganizations &&
          !organizationStore.state.loadingOrganization &&
          !_.isNil(organizationStore.state.organizations)
        ) {
          department = getDepartmentFromOrganizations(props.id);
        }

        if (!_.isNil(department)) {
          setName(department.name);
          setLoading(false);
        }

        break;
      }
      case Models.EntityType.Device: {
        if (!deviceStore.state.loadingDevices && !deviceStore.state.loadingDevice) {
          if (!_.isNil(deviceStore.state.devices)) {
            const device = deviceStore.state.devices.find((device) => device.id === props.id);
            if (!_.isNil(device)) setName(device.system_serial_number);
            setLoading(false);
          } else {
            deviceQueryRefetch().then(({data}) => {
              if (!data) return;
              if (data.success) setName(data.device.system_serial_number);
              setLoading(false);
            });
          }
        }

        break;
      }
      // `worker` and `gateway` are not needed so far, so for now we don't specify a logic for their names, we can just display the ID as entity name
    }
  }, [
    props.id,
    props.type,
    authenticationStore.state.accessToken,
    departmentStore.state.departments,
    departmentStore.state.loadingDepartments,
    departmentStore.state.loadingDepartment,
    deviceQueryRefetch,
    deviceStore.state.loadingDevices,
    deviceStore.state.devices,
    organizationStore.state.organizations,
    organizationStore.state.loadingOrganizations,
    organizationStore.state.loadingOrganization,
    siteStore.state.sites,
    deviceStore.state.loadingDevice,
    siteStore.state.loadingSites,
    siteStore.state.loadingSite,
  ]);

  if (!props.id) {
    return null;
  } else if (!props.type) {
    return <>{props.id}</>;
  }

  return loading ? <Spin size="small" /> : <>{name}</>;
});
