import {useMutation} from "@tanstack/react-query";
import {Checkbox, Form, Modal} from "antd";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {SegmentEntryPoint} from "../../../../utils/SegmentHelper";
import {WebHelper} from "../../../../utils/WebHelper";
import {Button, ButtonType} from "../../../common/button/Button";
import {DiscardChangesModal} from "../../../discard-changes-modal/DiscardChangesModal";
import {WorkerForm} from "./worker-form/WorkerForm";
import styles from "./WorkerCreationManualModal.module.scss";

export type WorkerFormFields = {
  first_name: string;
  last_name: string;
  employee_id: string;
  assigned_device_id: string;
  group_ids: string[];
  preferred_language: string;
  exempt_target?: boolean;
  targetHoursStartDate?: Date;
};

type WorkerCreationManualModalProps = {
  open: boolean;
  siteId: string;
  department: Models.Department;
  siteName: string;
  workers: Models.Worker[] | Models.WorkerShort[];
  onCancel?: () => void;
  onCreate: () => void;
};

export const WorkerCreationManualModal: FunctionComponent<WorkerCreationManualModalProps> = observer(
  ({open, workers, department, siteName, siteId, onCancel, onCreate}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();
    const workerStore = WorkerDataStore.getInstance();

    const [keepCreating, setKeepCreating] = useState(false);
    const [isCreateButtonDisabled, setIsCreateButtonDisabled] = useState(true);
    const [isDiscardChangesModalVisible, setIsDiscardChangesModalVisible] = useState(false);

    const [form] = Form.useForm<WorkerFormFields>();

    const handleCancel = () => {
      if (createWorkerIsLoading) return;
      if (!isDiscardChangesModalVisible && form.isFieldsTouched()) setIsDiscardChangesModalVisible(true);
      else {
        setIsDiscardChangesModalVisible(false);
        setKeepCreating(false);
        form.resetFields();
        if (onCancel) onCancel();
      }
    };

    const {mutate: createWorkerMutate, isPending: createWorkerIsLoading} = useMutation({
      mutationFn: (mutationParams: {formValues: WorkerFormFields}) => {
        const {first_name, last_name, employee_id, assigned_device_id, group_ids, preferred_language, exempt_target, targetHoursStartDate} =
          mutationParams.formValues;

        const target_hours_start_date = targetHoursStartDate ? targetHoursStartDate.toISOString().split("T")[0] : null;
        return workerStore.createWorkers({
          accessToken: authenticationStore.state.accessToken!,
          worker_info: [
            {
              first_name,
              last_name,
              employee_id,
              assigned_device_id,
              group_ids,
              preferred_language,
              exempt_target: exempt_target ?? false,
              target_hours_start_date: target_hours_start_date,
            },
          ],
          department_id: department.id,
        });
      },
      onSuccess: (response, params) => {
        if (!response.success) {
          WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerCreationManualModal-createError"), response.correlationId);
          return;
        }

        appStore.sendAnalyticTrack(SegmentKey.CreateWorkerManual, {
          departmentID: department.id,
          siteID: siteId,
          orgID: response.workers[0].organization?.id,
          groupAssigned: !!params.formValues.group_ids,
          deviceAssigned: !!params.formValues.assigned_device_id,
        });

        if (response.workers[0].groups) {
          appStore.sendAnalyticTrack(SegmentKey.AddRemoveWorkerFromGroupSingle, {
            workerID: response.workers[0].id,
            orgID: response.workers[0].organization?.id,
            siteID: siteId,
            departmentID: department.id,
            entryPoint: SegmentEntryPoint.CREATE_WORKER_MODAL,
          });
        }

        if (!keepCreating && onCancel) onCancel();
        form.resetFields();
        onCreate();
        appStore.showMessage(WebHelper.formatMessage("WorkerCreationManualModal-createdSuccessMessage"), "success");
      },
    });

    const handleOk = async () => {
      form
        .validateFields()
        .then((formValues) => {
          createWorkerMutate({formValues});
        })
        // This catch prevents AntD from throwing an error to the console when form validations fail
        .catch(() => {});
    };

    return (
      <>
        <Modal
          centered
          open={open}
          destroyOnClose={true}
          title={WebHelper.formatMessage("WorkerCreationManualModal-createWorkers")}
          okText={WebHelper.formatMessage("Common-create")}
          cancelText={WebHelper.formatMessage("Common-cancel")}
          onCancel={handleCancel}
          footer={[
            <div className={styles.footer} key="footer">
              <Checkbox checked={keepCreating} onChange={() => setKeepCreating(!keepCreating)}>
                {WebHelper.formatMessage("WorkerCreationManualModal-createAnotherWorker")}
              </Checkbox>
              <div className={styles.buttonsWrapper}>
                <Button key="back" shape="round" disabled={createWorkerIsLoading} onClick={handleCancel}>
                  {WebHelper.formatMessage("Common-cancel")}
                </Button>
                <Button
                  key="submit"
                  type={ButtonType.Primary}
                  shape="round"
                  disabled={createWorkerIsLoading || isCreateButtonDisabled}
                  loading={createWorkerIsLoading}
                  onClick={handleOk}>
                  {WebHelper.formatMessage("Common-create")}
                </Button>
              </div>
            </div>,
          ]}>
          <WorkerForm
            siteId={siteId}
            department={department}
            siteName={siteName}
            workers={workers}
            form={form}
            setIsCreateButtonDisabled={setIsCreateButtonDisabled}
            creationLoading={createWorkerIsLoading}
          />
        </Modal>
        <DiscardChangesModal
          onDiscard={handleCancel}
          onClose={() => setIsDiscardChangesModalVisible(false)}
          open={isDiscardChangesModalVisible}
        />
      </>
    );
  }
);
