import {useMutation} from "@tanstack/react-query";
import {Typography} from "antd";
import {AuthenticationDataStore} from "core/stores/AuthenticationDataStore";
import React, {useCallback, useState} from "react";
import {useNavigate} from "react-router-dom";
import {Button, ButtonType} from "web/components/common/button/Button";
import {AppStore} from "web/stores/AppStore";
import {WebHelper} from "web/utils/WebHelper";

import styles from "./Unsubscribe.module.scss";

const Unsubscribe = () => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const navigate = useNavigate();

  const {user: currentUser} = authenticationStore.state;

  const [unsubscribed, setUnsubscribed] = useState<boolean>(false);

  const handleUnsubscription = useMutation({
    mutationFn: () =>
      authenticationStore.updateUserSubscription({
        accessToken: authenticationStore.state.accessToken!,
        id: currentUser!.id,
        subscribe: false,
      }),
    onSuccess: (response) => {
      if (!response.success) WebHelper.showErrorMessage(WebHelper.formatMessage("AccountSettings-updateError"), response.correlationId);
      else {
        setUnsubscribed(true);
      }
    },
  });

  const handleAccountSettingsClick = useCallback(() => {
    appStore.showComponent("AccountSettings", {
      onClose: () => appStore.hideComponent("AccountSettings"),
      visible: true,
    });
  }, [appStore]);

  return (
    <section className={styles.wrapper}>
      <Typography.Title level={3}>{WebHelper.formatMessage("Unsubscribe-title")}</Typography.Title>
      {!unsubscribed ? (
        <>
          <Typography.Text className={styles.subText}>{WebHelper.formatMessage("Unsubscribe-subText")}</Typography.Text>
          <div className={styles.buttonsWrapper}>
            <Button
              shape="round"
              loading={handleUnsubscription.isPending}
              type={ButtonType.Primary}
              onClick={() => handleUnsubscription.mutate()}>
              {WebHelper.formatMessage("Unsubscribe-unsubscribe")}
            </Button>
            <Button shape="round" disabled={handleUnsubscription.isPending} onClick={() => navigate("/")}>
              {WebHelper.formatMessage("Common-cancel")}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography.Text className={styles.subText}>
            {WebHelper.formatMessage("Unsubscribe-unsubscribedSubText")}{" "}
            <span role="presentation" onClick={handleAccountSettingsClick} className={styles.accountSettingsLink}>
              {WebHelper.formatMessage("Unsubscribe-accountSettings")}
            </span>
          </Typography.Text>
        </>
      )}
    </section>
  );
};

export default Unsubscribe;
