import {Typography} from "antd";
import * as _ from "lodash";
import React, {FunctionComponent} from "react";

import * as Models from "../../../core/models";
import {WebHelper} from "../../utils/WebHelper";
import {LinkButton} from "../common/link-button/LinkButton";
import styles from "./GatewayInfo.module.scss";

type GatewayInfoProps = {
  gateways?: Models.Gateway[];
  setIsGatewaysDetailDrawerVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const GatewayInfo: FunctionComponent<GatewayInfoProps> = ({gateways, setIsGatewaysDetailDrawerVisible}) => {
  return (
    <div>
      <div className={styles.gatewayHeader}>
        <Typography.Text className={styles.gatewayTitle}>{WebHelper.formatMessage("EntityDetailsSiderCard-gatewayStatus")}</Typography.Text>
        <LinkButton darkBackground onClick={() => setIsGatewaysDetailDrawerVisible && setIsGatewaysDetailDrawerVisible(true)}>
          {WebHelper.formatMessage("EntityDetailsSiderCard-view")}
        </LinkButton>
      </div>
      {_.isEmpty(gateways) && (
        <Typography.Text className={styles.text}>{WebHelper.formatMessage("EntityDetail-noGateway")}</Typography.Text>
      )}
      {gateways?.map((gateway) => {
        switch (gateway.status) {
          case Models.GatewayStatus.Up:
            return (
              <div className={styles.gatewayStatusContainer} key={gateway.id}>
                <div className={`${styles.gatewayStatusIndicator} ${styles.up}`} />
                <Typography.Text className={styles.text}>{WebHelper.formatMessage("Common-gatewayStatusUp")}</Typography.Text>
              </div>
            );
          case Models.GatewayStatus.Down:
            return (
              <div className={styles.gatewayStatusContainer} key={gateway.id}>
                <div className={`${styles.gatewayStatusIndicator} ${styles.down}`} />
                <Typography.Text className={styles.text}>{WebHelper.formatMessage("Common-gatewayStatusDown")}</Typography.Text>
                {gateway.most_recent_gateway_event_at && (
                  <Typography.Text className={styles.dateText}>
                    {WebHelper.formatMessage("EntityDetailsSiderCard-gatewayDateSinceOffline", {
                      date: WebHelper.formatDate(gateway.most_recent_gateway_event_at, gateway.site?.tz_location),
                    })}
                  </Typography.Text>
                )}
              </div>
            );
          case Models.GatewayStatus.Intermittent:
            return (
              <div className={styles.gatewayStatusContainer} key={gateway.id}>
                <div className={`${styles.gatewayStatusIndicator} ${styles.intermittent}`} />
                <Typography.Text className={styles.text}>{WebHelper.formatMessage("Common-gatewayStatusIntermittent")}</Typography.Text>
              </div>
            );
          case Models.GatewayStatus.NeverSeen:
            return (
              <div className={styles.gatewayStatusContainer} key={gateway.id}>
                <div className={`${styles.gatewayStatusIndicator} ${styles.neverSeen}`} />
                <Typography.Text className={styles.text}>{WebHelper.formatMessage("Common-gatewayStatusNeverSeen")}</Typography.Text>
              </div>
            );
        }
      })}
    </div>
  );
};
