import {Checkbox} from "antd";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../core/models";
import {WebHelper} from "../../../utils/WebHelper";

export type ReasonCheckboxProps = {
  value: Models.UnenrollWorkerOtherReason;
  disabled?: boolean;
};

export const ReasonCheckbox: FunctionComponent<ReasonCheckboxProps> = ({value, disabled = false}) => {
  return (
    <Checkbox value={value} disabled={disabled}>
      {WebHelper.getUnenrollWorkerOtherReasonLabel(value)}
    </Checkbox>
  );
};
