import {useMutation} from "@tanstack/react-query";
import {Modal} from "antd";
import {RcFile} from "antd/lib/upload";
import {observer} from "mobx-react-lite";
import {parse} from "papaparse";
import React, {FunctionComponent, useCallback, useState} from "react";

import * as Models from "../../../../../core/models";
import {AuthenticationDataStore} from "../../../../../core/stores/AuthenticationDataStore";
import {WorkerDataStore} from "../../../../../core/stores/WorkerDataStore";
import {AppStore, SegmentKey} from "../../../../stores/AppStore";
import {WebHelper} from "../../../../utils/WebHelper";
import {CSVDragger} from "../../../csv-dragger/CSVDragger";
import {CreationError, CreationErrorType, ProfileError, ProfileErrorType, CreationFailed} from "./creation-failed/CreationFailed";

type WorkerCreationCSVModalProps = {
  open: boolean;
  siteId: string;
  departmentId: string;
  siteName: string;
  departmentName: string;
  workers: (Models.Worker | Models.WorkerShort)[];
  onCancel: () => void;
  onCreate: () => void;
};

export const WorkerCreationCSVModal: FunctionComponent<WorkerCreationCSVModalProps> = observer(
  ({open, siteId, departmentId, siteName, departmentName, workers, onCancel, onCreate}) => {
    const appStore = AppStore.getInstance();
    const authenticationStore = AuthenticationDataStore.getInstance();
    const workerStore = WorkerDataStore.getInstance();

    const [fileName, setFileName] = useState<string | null>(null);
    const [showCreationFailed, setShowCreationFailed] = useState(false);
    const [creationError, setCreationError] = useState<CreationError | null>(null);
    const [workersToProcess, setWorkersToProcess] = useState<Models.BasicWorker[]>([]);

    const cleanUpload = () => {
      setFileName(null);
      setShowCreationFailed(false);
      setCreationError(null);
      setWorkersToProcess([]);
    };

    const {mutate: createWorkersMutate, isPending: createWorkersIsLoading} = useMutation({
      mutationFn: (workers: Models.BasicWorker[]) => {
        return workerStore.createWorkers({
          accessToken: authenticationStore.state.accessToken!,
          worker_info: workers,
          department_id: departmentId,
        });
      },
      onSuccess: (data) => {
        if (!data.success) WebHelper.showErrorMessage(WebHelper.formatMessage("WorkerForm-createError"), data.correlationId);
        else {
          appStore.sendAnalyticTrack(SegmentKey.CreateWorkerBulk, {
            departmentID: departmentId,
            siteID: siteId,
            orgID: data.workers[0].organization?.id,
            numberOfWorkers: data.workers.length,
          });
          appStore.showMessage(WebHelper.formatMessage("WorkerCreationCSVModal-successMessage"), "success");
          onCreate();
          cleanUpload();
        }
      },
    });

    const onUpload = useCallback((file: RcFile) => {
      setFileName(file.name);
      parse<Models.BasicWorker>(file, {
        header: true,
        skipEmptyLines: true,
        transformHeader: (headerText) => {
          if (headerText === WebHelper.formatMessage("CSVWorkerCreation-firstName")) {
            return "first_name";
          } else if (headerText === WebHelper.formatMessage("CSVWorkerCreation-lastName")) {
            return "last_name";
          } else if (headerText === WebHelper.formatMessage("CSVWorkerCreation-employeeID")) {
            return "employee_id";
          }
          return headerText;
        },
        complete: ({data, meta}) => {
          if (data.length === 0) {
            setCreationError({type: CreationErrorType.NoRows});
          } else if (
            !meta.fields?.find((field) => field === "first_name") ||
            !meta.fields?.find((field) => field === "last_name") ||
            !meta.fields?.find((field) => field === "employee_id")
          ) {
            setCreationError({type: CreationErrorType.MissingColumn});
          } else if (data.find((row) => !row.first_name && !row.last_name && !row.employee_id)) {
            setCreationError({type: CreationErrorType.EmptyRows});
          } else {
            setWorkersToProcess(data);
          }
        },
      });

      return false;
    }, []);

    const onCreateButtonClicked = useCallback(() => {
      const workersToCreate: Models.BasicWorker[] = [];
      const workersWithErrors: ProfileError[] = [];

      workersToProcess.forEach((worker) => {
        if (
          !((worker.first_name && worker.last_name) || worker.employee_id) ||
          (!worker.first_name && worker.last_name) ||
          (worker.first_name && !worker.last_name)
        ) {
          workersWithErrors.push({type: ProfileErrorType.NotEnoughInfo, worker});
        }

        const existingWorker = workers.find((w: Models.Worker | Models.WorkerShort) => {
          return (
            (w.employee_id && worker.employee_id && w.employee_id === worker.employee_id) ||
            (w.first_name &&
              w.last_name &&
              worker.first_name &&
              worker.last_name &&
              w.first_name === worker.first_name &&
              w.last_name === worker.last_name)
          );
        });

        if (!existingWorker) {
          workersToCreate.push(worker);
          return;
        }

        if (existingWorker.site?.id === siteId) {
          if (
            worker.first_name &&
            worker.last_name &&
            existingWorker.first_name === worker.first_name &&
            existingWorker.last_name === worker.last_name
          ) {
            workersWithErrors.push({type: ProfileErrorType.NameAlreadyExist, worker});
          } else {
            workersWithErrors.push({type: ProfileErrorType.EmployeeIDAlreadyExists, worker});
          }
        }
      });

      if (workersWithErrors.length !== 0) {
        setCreationError({type: CreationErrorType.ProfileErrors, workersWithErrors});
        setShowCreationFailed(true);
        return;
      }

      if (creationError) {
        setShowCreationFailed(true);
        return;
      }

      createWorkersMutate(workersToCreate);
    }, [siteId, workers, workersToProcess, creationError, createWorkersMutate]);

    const handleCancel = () => {
      if (createWorkersIsLoading) return;
      cleanUpload();
      onCancel();
    };

    return (
      <Modal
        centered
        open={open}
        destroyOnClose={true}
        title={WebHelper.formatMessage("WorkerCreationCSVModal-createWorkers")}
        okButtonProps={{
          shape: "round",
          disabled: !fileName,
          loading: createWorkersIsLoading,
        }}
        okText={WebHelper.formatMessage(!showCreationFailed ? "Common-create" : "Common-tryAgain")}
        onOk={!showCreationFailed ? onCreateButtonClicked : cleanUpload}
        cancelButtonProps={{shape: "round", disabled: createWorkersIsLoading}}
        cancelText={WebHelper.formatMessage("Common-cancel")}
        onCancel={handleCancel}>
        {!showCreationFailed && (
          <CSVDragger
            descriptionMessage={WebHelper.formatMessage("WorkerCreationCSVModal-description", {siteName, departmentName})}
            columnsMessage={WebHelper.formatMessage("WorkerCreationCSVModal-columns")}
            fileName={fileName}
            onRemoveFile={(e) => {
              e.stopPropagation();
              setFileName(null);
              setWorkersToProcess([]);
            }}
            onUpload={onUpload}
          />
        )}
        {showCreationFailed && creationError && (
          <CreationFailed creationError={creationError} departmentName={departmentName} siteName={siteName} />
        )}
      </Modal>
    );
  }
);
