import EventEmitter from "eventemitter3";
import * as _ from "lodash";
import {action, makeObservable, observable, runInAction} from "mobx";

import {SiteAPIClient} from "../apiclient/site/SiteAPIClient";
import * as SiteTypes from "../apiclient/site/SiteAPIClient.types";
import * as Models from "../models";

export type SitesState = {
  loadingSites: boolean;
  loadingSite: boolean;
  sites?: Models.Site[];
};

export type SiteEvent = {
  "site-created": [Models.Site];
  "site-updated": [Models.Site];
  "site-deleted": [string];
};

export class SiteDataStore {
  private static instance: SiteDataStore | undefined;

  private initialState: SitesState = {
    loadingSites: false,
    loadingSite: false,
  };

  state = _.cloneDeep(this.initialState);

  eventEmitter: EventEmitter<SiteEvent>;

  private constructor() {
    makeObservable<SiteDataStore>(this, {
      state: observable,
      sites: action,
      site: action,
      createSite: action,
      updateSite: action,
      deleteSite: action,
    });
  }

  static getInstance(): SiteDataStore {
    if (!SiteDataStore.instance) SiteDataStore.instance = new SiteDataStore();

    return SiteDataStore.instance;
  }

  initialize() {
    this.eventEmitter = new EventEmitter();
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
    this.eventEmitter?.removeAllListeners();
  }

  async sites(request: SiteTypes.SitesRequest): Promise<SiteTypes.SitesResponse> {
    this.state.loadingSites = true;

    const response = await SiteAPIClient.sites(request);

    runInAction(() => {
      this.state.loadingSites = false;
      if (response.success && !_.isNil(response.sites)) this.state.sites = response.sites;
    });

    return response;
  }

  async site(request: SiteTypes.SiteRequest): Promise<SiteTypes.SiteResponse> {
    this.state.loadingSite = true;

    const response = await SiteAPIClient.site(request);

    runInAction(() => {
      this.state.loadingSite = false;
      if (response.success && !_.isNil(response.site)) this.state.sites = [response.site];
    });

    return response;
  }

  async createSite(request: SiteTypes.CreateSiteRequest): Promise<SiteTypes.CreateSiteResponse> {
    const response = await SiteAPIClient.createSite(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("site-created", response.site);
        this.state.sites = [response.site];
      }
    });

    return response;
  }

  async deleteSite(request: SiteTypes.DeleteSiteRequest): Promise<SiteTypes.DeleteSiteResponse> {
    const response = await SiteAPIClient.deleteSite(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("site-deleted", request.id);
      }
    });

    return response;
  }

  async updateSite(request: SiteTypes.UpdateSiteRequest): Promise<SiteTypes.UpdateSiteResponse> {
    const response = await SiteAPIClient.updateSite(request);

    runInAction(() => {
      if (response.success) {
        this.eventEmitter.emit("site-updated", response.site);
        this.state.sites = [response.site];
      }
    });

    return response;
  }

  async updateSiteConfiguration(request: SiteTypes.UpdateSiteConfigurationRequest): Promise<SiteTypes.UpdateSiteConfigurationResponse> {
    const response = await SiteAPIClient.updateSiteConfiguration(request);
    return response;
  }
}
