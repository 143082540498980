import {Locale} from "./Locale";

export const es_ES: Locale = {
  code: "es-ES",
  "Common-date": "Fecha",
  "Common-dateFormatterWithoutTimeZone": "D [de] MMMM, YYYY [a las] h:mmA",
  "Common-dateFormatterWithTimeZone": "D [de] MMMM, YYYY [a las] h:mmA z",
  "Common-day": "día",
  "Common-days": "días",
  "Common-error": "Error",
  "Common-genericError": "Lo sentimos, ha ocurrido un error.",
  "Common-hour": "hr",
  "Common-hours": "hrs",
  "Common-miles": "millas",
  "Common-minute": "min",
  "Common-minutes": "mins",
  "Common-month": "mes",
  "Common-months": "meses",
  "Common-more": "más",
  "Common-networkError": "No se pudo conectar con el servidor.",
  "Common-ok": "Ok",
  "Common-retry": "Reintentar",
  "Common-second": "seg",
  "Common-seconds": "segs",
  "Common-test": "Prueba",
  "Common-time": "Hora",
  "Common-today": "Hoy",
  "Common-tomorrow": "Mañana",
  "Common-week": "semana",
  "Common-weeks": "semanas",
  "Common-year": "año",
  "Common-years": "años",
  "Common-yesterday": "Ayer",
  "Validation-emailInvalidError": "Email inválido",
  "Validation-emptyFieldError": "Este campo no puede ser vacío",
};
