import {ArrowLeftOutlined, LeftOutlined, RightOutlined, SearchOutlined} from "@ant-design/icons";
import {useQuery} from "@tanstack/react-query";
import {Button as AntdButton, Button, Input, Space, Switch, Tooltip, Typography} from "antd";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {addDays, endOfDay, format, isAfter, startOfDay} from "date-fns";
import {zonedTimeToUtc} from "date-fns-tz";
import _ from "lodash";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from "react";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import useUserPreferences from "web/hooks/useUserPreferences";
import {WebHelper} from "web/utils/WebHelper";

import * as Models from "../../../core/models";
import {AnalyticsDataStore} from "../../../core/stores/AnalyticsDataStore";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {DepartmentDataStore} from "../../../core/stores/DepartmentDataStore";
import {EntityDetailsTabKeys} from "../../pages/organizations/entity-details/EntityDetails";
import {AppStore, SegmentKey} from "../../stores/AppStore";
import {AllocateSelectedDataModal} from "../allocate-selected-data-modal/AllocateSelectedDataModal";
import {LinkButton} from "../common/link-button/LinkButton";
import {PageTitle} from "../common/page-title/PageTitle";
import {Subheader, SubheaderBreadcrumbItem} from "../common/subheader/Subheader";
import {Table} from "../common/table/Table";
import {DatePicker} from "../date-picker/DatePicker";
import styles from "./DeviceUsageReassignment.module.scss";

const defaultSettings: Models.DeviceUsageReassignmentTableSettings = {
  pageSize: 10,
  sortColumn: "device",
  sortOrder: "ascend",
};

type DeviceUsageReassignmentProps = {};

export const DeviceUsageReassignment: FunctionComponent<DeviceUsageReassignmentProps> = observer(({}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const analyticsStore = AnalyticsDataStore.getInstance();
  const departmentStore = DepartmentDataStore.getInstance();
  const appStore = AppStore.getInstance();

  const breadcrumbs: SubheaderBreadcrumbItem[] = [];

  const location = useLocation();
  const navigate = useNavigate();
  const [userPreferences, setUserPreferences] = useUserPreferences();

  const {departmentId, orgId, siteId} = useParams();
  const [department, setDepartment] = useState<Models.Department>();
  const [endDate, setEndDate] = useState<Date | null>(addDays(endOfDay(new Date()), -1));
  const [showAssigned, setShowAssigned] = useState(false);
  const [devices, setDevices] = useState<Models.AnalyticsDevices[]>();
  const [openAllocateModal, setOpenAllocateModal] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState<Models.DeviceShort>();
  const [deviceFilterText, setDeviceFilterText] = useState<string>();
  const [devicesFiltered, setDevicesFiltered] = useState<Models.AnalyticsDevices[]>();

  const handleBackButton = () => {
    navigate(location.pathname.substring(0, location.pathname.indexOf("/device_usage_reassignment")), {
      state: {activeKey: EntityDetailsTabKeys.Devices},
    });
  };

  const handleSettingsChange = (updatedSettings: Partial<Models.DeviceUsageReassignmentTableSettings>) =>
    setUserPreferences({tableSettings: {deviceUsageReassignmentTable: {...deviceUsageReassignmentTable, ...updatedSettings}}});

  const deviceUsageReassignmentTable: Models.DeviceUsageReassignmentTableSettings = useMemo(
    () => ({...defaultSettings, ...(userPreferences.data.tableSettings?.deviceUsageReassignmentTable ?? {})}),
    [userPreferences]
  );

  const {pageSize, sortColumn, sortOrder} = deviceUsageReassignmentTable;

  const departmentQuery = useQuery({
    queryKey: ["DeviceUsageReassignment-fetchDepartmentsData", departmentId],
    queryFn: () => {
      return departmentStore.department({
        accessToken: authenticationStore.state.accessToken!,
        id: departmentId!,
      });
    },
    enabled: !!departmentId,
  });

  useEffect(() => {
    if (!departmentQuery.data) return;
    if (!departmentQuery.data.success) {
      WebHelper.showErrorMessage(
        WebHelper.formatMessage("DeviceUsageReassignment-errorFetchingWorkers"),
        departmentQuery.data.correlationId
      );
      return;
    }
    setDepartment(departmentQuery.data.department);
  }, [departmentQuery.data]);

  const analyticsDevicesQuery = useQuery({
    queryKey: ["DeviceUsageReassignment-fetchDevicesData", departmentId, endDate],
    queryFn: () => {
      const end_time = zonedTimeToUtc(startOfDay(addDays(endDate!, 1)), "UTC").toISOString();
      return analyticsStore.analyticsDevicesData({
        accessToken: authenticationStore.state.accessToken!,
        end_time,
        interval_type: Models.AnalyticsInterval.Day,
        interval_count: 7,
        resource_id: departmentId!,
      });
    },
    enabled: !!endDate,
  });

  useEffect(() => {
    if (!analyticsDevicesQuery.data) return;

    if (!analyticsDevicesQuery.data.success) {
      WebHelper.showErrorMessage(WebHelper.formatMessage("DeviceUsageReassignment-errorMessage"), analyticsDevicesQuery.data.correlationId);
      return;
    }

    showAssigned
      ? setDevices(
          analyticsDevicesQuery.data.data.department_devices_data?.filter((device) => {
            return device.time_devices_data.some((data) => data?.usage_hours > 0);
          })
        )
      : setDevices(
          analyticsDevicesQuery.data.data.department_devices_data?.filter((device) => {
            return device.time_devices_data.some((data) => data?.usage_hours > 0 && data.allocated_data === false);
          })
        );

    setDevicesFiltered(undefined);
  }, [analyticsDevicesQuery.data, showAssigned]);

  const days = useMemo(() => {
    const lastDay =
      endDate ?? zonedTimeToUtc(new Date(), department ? department.site.tz_location : WebHelper.getLocalTimezoneFromBrowser());

    const endDateFormatted = format(lastDay, WebHelper.formatMessage("Common-dateFormatMonthDayYear"));

    const days = [endDateFormatted];

    for (let i = 1; i < 7; i++) {
      days.unshift(format(addDays(lastDay, -i), WebHelper.formatMessage("Common-dateFormatMonthDayYear")));
    }

    return days;
  }, [endDate, department]);

  const handleResetDeviceSearch = () => {
    setDeviceFilterText(undefined);
    setDevicesFiltered(undefined);
  };

  const handleDeviceSearch = useCallback(() => {
    deviceFilterText
      ? setDevicesFiltered(devices?.filter((device) => device.device.device_tag.toLowerCase().includes(deviceFilterText.toLowerCase())))
      : setDevicesFiltered(undefined);

    appStore.analytics.track(SegmentKey.DeviceUsageReassignmentSettingsChanged, {
      departmentID: departmentId,
      siteID: siteId,
      orgID: orgId,
      action: "filter",
      filter: deviceFilterText,
    });
  }, [deviceFilterText, devices, appStore.analytics, siteId, orgId, departmentId]);

  const deviceColumnSearchDropdown = (
    <div style={{padding: 8}}>
      <Input value={deviceFilterText} onChange={(e) => setDeviceFilterText(e.target.value)} className={styles.searchInput} />
      <Space>
        <Button type="primary" onClick={handleDeviceSearch} icon={<SearchOutlined />} size="small">
          {WebHelper.formatMessage("Common-search")}
        </Button>
        <Button onClick={handleResetDeviceSearch} size="small">
          {WebHelper.formatMessage("Common-delete")}
        </Button>
      </Space>
    </div>
  );

  const deviceColumn: ColumnType<Models.AnalyticsDevices> = {
    title: WebHelper.formatMessage("DeviceUsageReassignment-deviceColumn"),
    key: "device",
    sorter: (a, b) => a.device.device_tag.localeCompare(b.device.device_tag),
    sortOrder: sortColumn === "device" ? sortOrder : undefined,
    render: (_, record) => (
      <LinkButton
        onClick={() => {
          setSelectedDevice(record.device);
          setOpenAllocateModal(true);
        }}>
        {record.device.device_tag}
      </LinkButton>
    ),
    filterIcon: <SearchOutlined className={!_.isNil(devicesFiltered) ? styles.searchActive : ""} />,
    filterDropdown: deviceColumnSearchDropdown,
  };

  const daysColumns: ColumnType<Models.AnalyticsDevices>[] = days.map((day, index) => {
    return {
      title: day,
      key: "day_${index}",
      sorter: (a, b) => {
        return a.time_devices_data[index]?.usage_hours - b.time_devices_data[index]?.usage_hours;
      },
      sortOrder: sortColumn === day ? sortOrder : undefined,
      render: (_, record) => {
        const usageInHours = record.time_devices_data[index]?.usage_hours;
        return record.time_devices_data[index]?.usage_hours > 0 &&
          (showAssigned || (!showAssigned && !record.time_devices_data[index].allocated_data)) ? (
          <Tooltip title={WebHelper.getDurationFormattedBySeconds(usageInHours * 60 * 60)}>
            <Typography.Text
              className={`${styles.numberDisplay} ${record.time_devices_data[index].allocated_data ? styles.allocatedData : record.time_devices_data[index].unallocated_data ? "" : styles.partiallyAllocatedData}`}>
              {usageInHours}
            </Typography.Text>
          </Tooltip>
        ) : (
          "-"
        );
      },
    };
  });

  const columns: ColumnsType<Models.AnalyticsDevices> = [
    deviceColumn,
    ...daysColumns,
    {
      title: (
        <Space className={styles.arrowButtons}>
          <Button
            key="leftButton"
            onClick={() => (endDate ? handleChangeEndDate(addDays(endDate, -1)) : "")}
            icon={<LeftOutlined className={styles.arrows} />}
            className={styles.button}
          />
          <Button
            key="rightButton"
            onClick={() =>
              endDate && !isAfter(addDays(endDate, +1), endOfDay(new Date())) ? handleChangeEndDate(addDays(endDate, +1)) : ""
            }
            icon={<RightOutlined className={styles.arrows} />}
            className={styles.button}
            disabled={endDate ? isAfter(addDays(endDate, +1), new Date()) : false}
          />
        </Space>
      ),
    },
  ];

  breadcrumbs.push(
    {
      id: departmentId!,
      type: Models.EntityType.Department,
      url: WebHelper.getBreadcrumbItemUrl(location, departmentId!),
    },
    {
      id: "deviceUsage",
      url: `/organizations/${orgId}/sites/${siteId}/departments/${departmentId}/device_usage_reassignment`,
      name: WebHelper.formatMessage("DeviceUsageReassignment-title"),
    }
  );

  const handleChangeEndDate = (newDate: Date) => {
    setEndDate(newDate);
    analyticsDevicesQuery.refetch();
  };

  const handleSwitchSeeAssigned = (value: boolean) => {
    setShowAssigned(value);
    analyticsDevicesQuery.refetch();
  };

  const loading = analyticsDevicesQuery.isPending || analyticsDevicesQuery.isFetching || analyticsDevicesQuery.isRefetching;

  return (
    <>
      <Subheader breadcrumbItems={breadcrumbs} />
      <div className={styles.innerContentSpace}>
        <PageTitle
          title={WebHelper.formatMessage("DeviceUsageReassignment-title")}
          icon={<AntdButton className={styles.backButton} onClick={handleBackButton} icon={<ArrowLeftOutlined />} />}
        />
        <Space className={styles.spaceContainer}>
          <div className={styles.filterItem}>
            <Typography.Text>{WebHelper.formatMessage("DeviceUsageReassignment-endDate")}</Typography.Text>
            <DatePicker
              allowClear={false}
              value={endDate}
              onChange={(date) => (date ? handleChangeEndDate(date) : null)}
              disabledDate={(date) => isAfter(date, endOfDay(new Date()))}
            />
          </div>
          <div className={styles.filterItem}>
            <Typography.Text>{WebHelper.formatMessage("DeviceUsageReassignment-assignedDataSwitch")}</Typography.Text>
            <Switch defaultChecked={showAssigned} onChange={(checked) => handleSwitchSeeAssigned(checked)} />
          </div>
        </Space>
        <Table
          columns={columns}
          loading={loading}
          dataSource={devicesFiltered ?? devices}
          onChange={(pagination, filters, sorter, extra) => {
            appStore.sendAnalyticTrack(SegmentKey.DeviceUsageReassignmentSettingsChanged, {
              departmentID: departmentId,
              siteID: siteId,
              orgID: orgId,
              pagination,
              filters,
              sorter,
              action: extra.action,
            });
            if (extra.action === "sort" && !Array.isArray(sorter)) {
              handleSettingsChange({
                sortColumn: sorter.field?.toString() ?? sorter.columnKey?.toString(),
                sortOrder: sorter.order,
              });
            }
          }}
          pagination={{
            showSizeChanger: true,
            pageSize: pageSize,
            onShowSizeChange: (_, size) =>
              handleSettingsChange({
                pageSize: size,
              }),
          }}
        />
      </div>
      {selectedDevice && (
        <AllocateSelectedDataModal
          open={openAllocateModal}
          onClose={() => setOpenAllocateModal(false)}
          onConfirm={() => analyticsDevicesQuery.refetch()}
          device={selectedDevice}
          departmentId={departmentId!}
        />
      )}
    </>
  );
});
