import {useQuery} from "@tanstack/react-query";
import {Drawer} from "antd";
import {AnalyticsDataStore} from "core/stores/AnalyticsDataStore";
import React, {FunctionComponent, useEffect, useState} from "react";
import {SegmentEntryPoint} from "web/utils/SegmentHelper";

import * as Models from "../../../core/models";
import {AuthenticationDataStore} from "../../../core/stores/AuthenticationDataStore";
import {WebHelper} from "../../utils/WebHelper";
import {WorkerSafeliftReportDrawerContent} from "./worker-safelift-report-drawer-content/WorkerSafeliftReportDrawerContent";
import {WorkerSafeliftReportPreviewModal} from "./worker-safelift-report-preview-modal/WorkerSafeliftReportPreviewModal";

type IndividualWorkerReportDrawerProps = {
  onClose: () => void;
  open: boolean;
  departmentId: string;
  siteId: string;
  tz_location?: string;
  workerData: {id: string; displayName: string} | null;
};

export const IndividualWorkerReportDrawer: FunctionComponent<IndividualWorkerReportDrawerProps> = ({
  onClose,
  open,
  departmentId,
  siteId,
  tz_location,
  workerData,
}) => {
  const authenticationStore = AuthenticationDataStore.getInstance();
  const [endTime, setEndTime] = useState<string>("");
  const analyticsStore = AnalyticsDataStore.getInstance();
  const [isReportPreviewModalVisible, setIsReportPreviewModalVisible] = useState(false);
  const [workerSafeliftReports, setWorkerSafeliftReports] = useState<Models.WorkerSafeliftReport[]>([]);

  const individualWorkerSafeliftReport = useQuery({
    queryKey: ["IndividualWorkerReportDrawer-fetchReportWorkerIndividual", workerData?.id, endTime],
    queryFn: () =>
      workerData &&
      analyticsStore.workerSafeliftReport({
        accessToken: authenticationStore.state.accessToken!,
        resource_type: Models.EntityType.Worker,
        resource_id: workerData.id,
        include_all_enrolled_workers: false,
        report_bounds: {
          end_time: endTime,
          offset_minutes: 0,
        },
      }),
    enabled: !!workerData && endTime !== "",
    staleTime: 0,
  });

  useEffect(() => {
    if (individualWorkerSafeliftReport.isSuccess) {
      if (individualWorkerSafeliftReport.data?.workerReportData)
        setWorkerSafeliftReports(individualWorkerSafeliftReport.data?.workerReportData);
    }
  }, [individualWorkerSafeliftReport.isSuccess, individualWorkerSafeliftReport.data]);

  useEffect(() => {
    if (individualWorkerSafeliftReport.isError) WebHelper.showErrorMessage(WebHelper.formatMessage("ReportDrawer-fetchReportError"));
  }, [individualWorkerSafeliftReport.isError]);

  const handleOnPreview = async (endDateFormatted: string) => {
    await setEndTime(endDateFormatted);
    const refetchData = await individualWorkerSafeliftReport.refetch();
    refetchData.isSuccess && setIsReportPreviewModalVisible(true);
  };

  const handleCancel = () => {
    setIsReportPreviewModalVisible(false);
  };

  return (
    <>
      <Drawer
        open={open}
        width={WebHelper.drawerWidth}
        destroyOnClose
        title={WebHelper.formatMessage("IndividualWorkerReportDrawer-drawerTitle")}
        onClose={onClose}>
        <WorkerSafeliftReportDrawerContent
          loadingDownload={individualWorkerSafeliftReport.isFetching}
          loadingPreview={individualWorkerSafeliftReport.isFetching}
          message={WebHelper.formatMessage("IndividualWorkerReportDrawer-contentMessage", {worker: workerData?.displayName ?? ""})}
          onPreview={handleOnPreview}
          tz_location={tz_location}
        />
      </Drawer>
      <WorkerSafeliftReportPreviewModal
        open={isReportPreviewModalVisible}
        workerSafeliftReports={workerSafeliftReports}
        onCancel={handleCancel}
        endTime={endTime}
        entryPoint={SegmentEntryPoint.WORKER_TABLE}
        mode="individual"
        workerData={workerData || undefined}
      />
    </>
  );
};
