import {Empty, Select, Space, Typography} from "antd";
import React, {FunctionComponent} from "react";

import * as Models from "../../../../../../core/models";
import {WebHelper} from "../../../../../utils/WebHelper";
import styles from "./ManualDeviceAllocation.module.scss";

type ManualDeviceAllocationProps = {
  department: Models.Department;
  availableDevices: Models.DeviceShort[];
  setDevicesIdsToAllocate: (devicesIds: string[]) => void;
  loading: boolean;
};

export const ManualDeviceAllocation: FunctionComponent<ManualDeviceAllocationProps> = ({
  department,
  availableDevices,
  setDevicesIdsToAllocate,
  loading,
}) => {
  return (
    <Space direction="vertical" className={styles.container}>
      <div
        dangerouslySetInnerHTML={{
          __html: WebHelper.parseMarkdown(
            WebHelper.formatMessage("ManualDeviceAllocation-description", {site: department.site.name, department: department.name})
          ),
        }}
      />
      <div className={styles.selectWrapper}>
        <Typography.Text className={styles.label}>{WebHelper.formatMessage("ManualDeviceAllocation-devicesLabel")}</Typography.Text>
        <Select
          className={styles.select}
          mode="multiple"
          allowClear
          loading={loading}
          disabled={loading}
          options={availableDevices.map(({id, system_serial_number}) => ({key: id, label: system_serial_number, value: id}))}
          optionFilterProp="label"
          notFoundContent={
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={WebHelper.formatMessage("DeviceAllocation-noDevicesText")} />
          }
          onChange={(values) => setDevicesIdsToAllocate(values)}
        />
      </div>
    </Space>
  );
};
