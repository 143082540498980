import {CloseCircleFilled} from "@ant-design/icons";
import {Space, Typography} from "antd";
import React, {FunctionComponent, useMemo} from "react";

import {WebHelper} from "../../../../../utils/WebHelper";
import styles from "./AllocationFailed.module.scss";

export enum SerialNumberErrorType {
  NotFound,
  AlreadyAllocatedToCurrent,
  AlreadyAllocatedToOther,
}

export type SerialNumberError = {
  type: SerialNumberErrorType;
  serialNumber: string;
};

export enum AllocationErrorType {
  EmptyRows,
  NoRows,
  MissingColumn,
  SerialNumbersWithErrors,
}

export type AllocationError =
  | {
      type: AllocationErrorType.EmptyRows | AllocationErrorType.MissingColumn | AllocationErrorType.NoRows;
    }
  | {
      type: AllocationErrorType.SerialNumbersWithErrors;
      serialNumbersWithErrors: SerialNumberError[];
    };

type AllocationFailedProps = {
  allocationError: AllocationError;
  departmentName: string;
  siteName: string;
};

export const AllocationFailed: FunctionComponent<AllocationFailedProps> = ({allocationError, departmentName, siteName}) => {
  const errorsList = useMemo(() => {
    switch (allocationError.type) {
      case AllocationErrorType.EmptyRows:
        return <Typography.Text>{WebHelper.formatMessage("AllocationFailed-emptyRowsError")}</Typography.Text>;
      case AllocationErrorType.NoRows:
        return <Typography.Text>{WebHelper.formatMessage("AllocationFailed-noRowsError")}</Typography.Text>;
      case AllocationErrorType.MissingColumn:
        return (
          <Typography.Text>
            {WebHelper.formatMessage("AllocationFailed-missingColumnError", {
              columnName: WebHelper.formatMessage("CSVDeviceAllocation-serialNumberColumnName"),
            })}
          </Typography.Text>
        );
      case AllocationErrorType.SerialNumbersWithErrors:
        return allocationError.serialNumbersWithErrors.map(({type, serialNumber}, index) => {
          switch (type) {
            case SerialNumberErrorType.NotFound:
              return (
                <Typography.Text key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(
                        WebHelper.formatMessage("AllocationFailed-serialNumberNotFoundError", {serialNumber})
                      ),
                    }}
                  />
                </Typography.Text>
              );
            case SerialNumberErrorType.AlreadyAllocatedToCurrent:
              return (
                <Typography.Text key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(
                        WebHelper.formatMessage("AllocationFailed-alreadyAllocatedToCurrentDepartmentError", {
                          departmentName,
                          siteName,
                          serialNumber,
                        })
                      ),
                    }}
                  />
                </Typography.Text>
              );
            case SerialNumberErrorType.AlreadyAllocatedToOther:
              return (
                <Typography.Text key={index}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: WebHelper.parseMarkdown(
                        WebHelper.formatMessage("AllocationFailed-alreadyAllocatedToOtherDepartmentError", {serialNumber})
                      ),
                    }}
                  />
                </Typography.Text>
              );
          }
        });
      default:
        return null;
    }
  }, [allocationError, departmentName, siteName]);

  return (
    <div className={styles.contentWrapper}>
      <Typography.Title level={5}>
        <Space size={16}>
          <CloseCircleFilled className={styles.errorIcon} />
          {WebHelper.formatMessage("AllocationFailed-subtitle")}
        </Space>
      </Typography.Title>
      <Typography.Text className={styles.text}>
        <div
          dangerouslySetInnerHTML={{
            __html: WebHelper.parseMarkdown(WebHelper.formatMessage("AllocationFailed-firstText", {siteName, departmentName})),
          }}
        />
      </Typography.Text>

      <Typography.Text className={styles.text}>{WebHelper.formatMessage("AllocationFailed-secondText")}</Typography.Text>

      {errorsList}
    </div>
  );
};
