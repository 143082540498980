import {Space, Typography} from "antd";
import {format} from "date-fns";
import {observer} from "mobx-react-lite";
import React, {FunctionComponent} from "react";

import {WebHelper} from "../../../../utils/WebHelper";

export type AllocateDataStepTwoProps = {
  deviceTag: string;
  startDate: Date;
  endDate: Date;
  workerName: string;
};

export const AllocateDataStepTwo: FunctionComponent<AllocateDataStepTwoProps> = observer(({deviceTag, startDate, endDate, workerName}) => {
  return (
    <Space direction="vertical">
      <Typography.Text>
        <div
          dangerouslySetInnerHTML={{
            __html: WebHelper.parseMarkdown(
              WebHelper.formatMessage("AllocateSelectedData-confirmationText", {
                workerName,
                deviceTag,
                startDate: format(startDate, WebHelper.formatMessage("Common-dateFormatMonthDayYear")),
                endDate: format(endDate, WebHelper.formatMessage("Common-dateFormatMonthDayYear")),
              })
            ),
          }}
        />
      </Typography.Text>
    </Space>
  );
});
