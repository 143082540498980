import {makeObservable, observable} from "mobx";

import {BaseModel} from "./BaseModel";

export class WorkerReportGroup extends BaseModel {
  id: string;
  site_id: string;
  name: string;
  organization: OrganizationInWorkerReportGroup;
  site: SiteInWorkerReportGroup;
  workers: WorkerInWorkerReportGroup[] | null;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      site_id: observable,
      name: observable,
      organization: observable,
      site: observable,
      workers: observable,
      created_at: observable,
      updated_at: observable,
      deleted_at: observable,
    });
  }

  static fixObjectFromJSON(object: WorkerReportGroup, json: any) {
    if (json.created_at) object.created_at = new Date(json.created_at);
    if (json.updated_at) object.updated_at = new Date(json.updated_at);
    if (json.deleted_at) object.deleted_at = new Date(json.deleted_at);
    if (json.site) object.site = SiteInWorkerReportGroup.fromJSON(json.site);
    if (json.organization) object.organization = OrganizationInWorkerReportGroup.fromJSON(json.organization);
    if (json.workers) object.workers = object.workers ? object.workers.map((worker) => WorkerInWorkerReportGroup.fromJSON(worker)) : null;
  }
}

export class WorkerInWorkerReportGroup extends BaseModel {
  id: string;
  first_name: string;
  last_name: string;
  employee_id: string;
  assignable: boolean;
  department_name: string;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      first_name: observable,
      last_name: observable,
      employee_id: observable,
      assignable: observable,
      department_name: observable,
    });
  }

  get fullName(): string {
    return `${this.first_name} ${this.last_name}`;
  }

  get displayName(): string {
    return this.first_name || this.last_name ? this.fullName : this.employee_id;
  }
}

export class OrganizationInWorkerReportGroup extends BaseModel {
  id: string;
  name: string;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
    });
  }
}

export class SiteInWorkerReportGroup extends BaseModel {
  id: string;
  name: string;

  constructor() {
    super();
    makeObservable(this, {
      id: observable,
      name: observable,
    });
  }
}
