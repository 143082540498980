import "./locales/en_US";
import "./locales/es_ES";

import {RuleObject} from "rc-field-form/lib/interface";

import {AppConfig as CoreAppConfig, AppConfigType as CoreAppConfigType} from "../core/AppConfig";
import {CoreHelper} from "../core/utils/CoreHelper";
import {LocaleKey, LocaleParams} from "./locales/Locale";

const constants = require("assets/stylesheets/constants");

export type LocaleValidationRule = Omit<RuleObject, "message"> & {
  message: {
    [K in LocaleKey]: {
      localeKey: K;
      localeVariables?: LocaleParams[K];
    };
  }[LocaleKey];
};

export type UIAppConfig = {
  Modules: {
    App: {
      router: {
        basename?: string;
      };
      colors: {
        primaryColor: string;
      };
      message: {
        duration: number;
      };
    };
    InputValidation: {
      accountSettings: {
        jobTitle: {
          rules: LocaleValidationRule[];
        };
        department: {
          rules: LocaleValidationRule[];
        };
        phoneNumber: {
          rules: LocaleValidationRule[];
        };
        address: {
          rules: LocaleValidationRule[];
        };
      };
      organizationForm: {
        name: {
          rules: LocaleValidationRule[];
        };
        shortName: {
          rules: LocaleValidationRule[];
        };
        address: {
          rules: LocaleValidationRule[];
        };
        iconUrl: {
          rules: LocaleValidationRule[];
        };
      };
      siteForm: {
        name: {
          rules: LocaleValidationRule[];
        };
        address: {
          rules: LocaleValidationRule[];
        };
        timezone: {
          rules: LocaleValidationRule[];
        };
      };
      departmentForm: {
        name: {
          rules: LocaleValidationRule[];
        };
      };
      deviceForm: {
        tag: {
          rules: LocaleValidationRule[];
        };
      };
      deviceCreationForm: {
        system_serial_number: {
          rules: LocaleValidationRule[];
        };
        mcu_id: {
          rules: LocaleValidationRule[];
        };
        device_tag: {
          rules: LocaleValidationRule[];
        };
        device_type: {
          rules: LocaleValidationRule[];
        };
        firmware_version_id: {
          rules: LocaleValidationRule[];
        };
        button_1_controller_setting_id: {
          rules: LocaleValidationRule[];
        };
        button_2_controller_setting_id: {
          rules: LocaleValidationRule[];
        };
        button_3_controller_setting_id: {
          rules: LocaleValidationRule[];
        };
      };
      workersReportGroupForm: {
        name: {
          rules: LocaleValidationRule[];
        };
      };
      updateDeviceConfigForm: {
        firmware_version_id: {
          rules: LocaleValidationRule[];
        };
        button_1_controller_setting_id: {
          rules: LocaleValidationRule[];
        };
        button_2_controller_setting_id: {
          rules: LocaleValidationRule[];
        };
        button_3_controller_setting_id: {
          rules: LocaleValidationRule[];
        };
      };
    };
  };
  Components: {
    AnalyticsChart: {
      maxBarsToDisplay: number;
    };
    DashboardTabContent: {
      cardsPerPage: number;
    };
  };
};

const UIAppConfig: UIAppConfig = {
  Modules: {
    App: {
      router: {
        basename: "",
      },
      colors: {
        primaryColor: constants.colors.primaryColor,
      },
      message: {
        duration: 5,
      },
    },
    InputValidation: {
      accountSettings: {
        jobTitle: {
          rules: [],
        },
        department: {
          rules: [],
        },
        phoneNumber: {
          rules: [{pattern: /^\+[0-9]{3}[0-9]+$/, message: {localeKey: "AccountSettings-phoneNumberError"}}],
        },
        address: {
          rules: [],
        },
      },
      organizationForm: {
        name: {
          rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}],
        },
        shortName: {
          rules: [
            {required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}},
            {pattern: /^[a-z0-9][a-z0-9\-]*[a-z0-9]$/, message: {localeKey: "OrganizationForm-shortNameInputPatternRuleMessage"}},
          ],
        },
        address: {
          rules: [],
        },
        iconUrl: {
          rules: [{type: "url", message: {localeKey: "Common-fieldUrlFormatMessage"}}],
        },
      },
      siteForm: {
        name: {
          rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}],
        },
        address: {
          rules: [],
        },
        timezone: {
          rules: [],
        },
      },
      departmentForm: {
        name: {
          rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}],
        },
      },
      deviceForm: {
        tag: {
          rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}],
        },
      },
      deviceCreationForm: {
        system_serial_number: {
          rules: [
            {
              required: true,
              whitespace: true,
              pattern: /^(?!\s)[A-Z0-9]{13}$/,
              len: 13,
              message: {localeKey: "CreateDeviceModal-systemSerialNumberValidatorMessage"},
            },
          ],
        },
        mcu_id: {
          rules: [
            {
              required: true,
              whitespace: true,
              pattern: /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/,
              message: {localeKey: "CreateDeviceModal-mcuIDValidatorMessage"},
            },
          ],
        },
        device_tag: {
          rules: [
            {
              required: true,
              whitespace: true,
              pattern: /^(?!\s)[A-Z0-9]+$/,
              message: {localeKey: "CreateDeviceModal-deviceTagValidatorMessage"},
            },
          ],
        },
        device_type: {
          rules: [{required: true, message: {localeKey: "Common-fieldRequiredMessage"}}],
        },
        firmware_version_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
        button_1_controller_setting_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
        button_2_controller_setting_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
        button_3_controller_setting_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
      },
      workersReportGroupForm: {
        name: {
          rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}],
        },
      },
      updateDeviceConfigForm: {
        firmware_version_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
        button_1_controller_setting_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
        button_2_controller_setting_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
        button_3_controller_setting_id: {rules: [{required: true, whitespace: true, message: {localeKey: "Common-fieldRequiredMessage"}}]},
      },
    },
  },
  Components: {
    AnalyticsChart: {
      maxBarsToDisplay: 30,
    },
    DashboardTabContent: {
      cardsPerPage: 4,
    },
  },
};

export type AppConfigType = CoreAppConfigType & UIAppConfig;
export const AppConfig: AppConfigType = CoreHelper.mergeWith(CoreAppConfig, UIAppConfig);
