import * as _ from "lodash";
import {makeObservable, runInAction, observable, action} from "mobx";

import {FirmwareVersionsAPIClient} from "../apiclient/firmwareversions/FirmwareVersionsAPIClient";
import * as FirmwareVersionsTypes from "../apiclient/firmwareversions/FirmwareVersionsAPIClient.types";

export type FirmwareVersionsState = {
  loadingFirmwareVersions: boolean;
  loadingFirmwareVersion: boolean;
};

export class FirmwareVersionsDataStore {
  private static instance: FirmwareVersionsDataStore | undefined;

  private initialState: FirmwareVersionsState = {
    loadingFirmwareVersions: false,
    loadingFirmwareVersion: false,
  };

  state = _.cloneDeep(this.initialState);

  private constructor() {
    makeObservable<FirmwareVersionsDataStore>(this, {
      state: observable,
      firmwareVersions: action,
      firmwareVersion: action,
    });
  }

  static getInstance(): FirmwareVersionsDataStore {
    if (!FirmwareVersionsDataStore.instance) FirmwareVersionsDataStore.instance = new FirmwareVersionsDataStore();

    return FirmwareVersionsDataStore.instance;
  }

  reset() {
    this.state = _.cloneDeep(this.initialState);
  }

  async firmwareVersions(request: FirmwareVersionsTypes.FirmwareVersionsRequest): Promise<FirmwareVersionsTypes.FirmwareVersionsResponse> {
    this.state.loadingFirmwareVersions = true;

    const response = await FirmwareVersionsAPIClient.firmwareVersions(request);

    runInAction(() => {
      this.state.loadingFirmwareVersions = false;
    });

    return response;
  }

  async firmwareVersion(request: FirmwareVersionsTypes.FirmwareVersionRequest): Promise<FirmwareVersionsTypes.FirmwareVersionResponse> {
    this.state.loadingFirmwareVersion = true;

    const response = await FirmwareVersionsAPIClient.firmwareVersion(request);

    runInAction(() => {
      this.state.loadingFirmwareVersion = false;
    });

    return response;
  }
}
