export enum CustomTranslationKeys {
  BackToSignIn = "BacktoSignIn",
  Email = "Email",
  EmailAlreadyExistsError = "EmailAlreadyExistsError",
  EmailDescriptiveText = "EmailDescriptiveText",
  FirstName = "FirstName",
  LastName = "LastName",
  Organization = "Organization",
  OrganizationNotFoundError = "OrganizationNotFoundError",
  Password = "Password",
  PasswordValidationLength = "PasswordValidationLength",
  PasswordValidationLowercase = "PasswordValidationLowercase",
  PasswordValidationNumber = "PasswordValidationNumber",
  PasswordValidationSpecialChar = "PasswordValidationSpecialChar",
  PasswordValidationUppercase = "PasswordValidationUppercase",
  SignInHeaderText = "SignInHeaderText",
  SignUpHeaderText = "SignUpHeaderText",
}

export const customTranslations = {
  en: {
    [CustomTranslationKeys.BackToSignIn]: "Back to Sign In",
    [CustomTranslationKeys.Email]: "Email",
    [CustomTranslationKeys.EmailAlreadyExistsError]: "An account with the given email already exists",
    [CustomTranslationKeys.EmailDescriptiveText]: "You will use your email address to sign in to the Verve Logic",
    [CustomTranslationKeys.FirstName]: "First Name",
    [CustomTranslationKeys.LastName]: "Last Name",
    [CustomTranslationKeys.Organization]: "Organization",
    [CustomTranslationKeys.OrganizationNotFoundError]: "The given Organization was not found",
    [CustomTranslationKeys.Password]: "Password",
    [CustomTranslationKeys.PasswordValidationLength]: "Password must contain at least 8 characters",
    [CustomTranslationKeys.PasswordValidationLowercase]: "Password must contain a lower case letter",
    [CustomTranslationKeys.PasswordValidationNumber]: "Password must contain a number",
    [CustomTranslationKeys.PasswordValidationSpecialChar]: "Password must contain a special character",
    [CustomTranslationKeys.PasswordValidationUppercase]: "Password must contain an upper case letter",
    [CustomTranslationKeys.SignInHeaderText]: "Sign In with your Email and Password",
    [CustomTranslationKeys.SignUpHeaderText]: "Sign Up for a New Account",
  },
  es: {
    [CustomTranslationKeys.BackToSignIn]: "Volver a inicio de sesión",
    [CustomTranslationKeys.Email]: "Email",
    [CustomTranslationKeys.EmailAlreadyExistsError]: "Una cuenta con el email ingresado ya existe",
    [CustomTranslationKeys.EmailDescriptiveText]: "Usará su email para iniciar sesión en Verve Logic",
    [CustomTranslationKeys.FirstName]: "Nombre",
    [CustomTranslationKeys.LastName]: "Apellido",
    [CustomTranslationKeys.Organization]: "Organización",
    [CustomTranslationKeys.OrganizationNotFoundError]: "La Organización ingresada no fue encontrada",
    [CustomTranslationKeys.Password]: "Contraseña",
    [CustomTranslationKeys.PasswordValidationLength]: "La contraseña debe contener al menos 8 letras",
    [CustomTranslationKeys.PasswordValidationLowercase]: "La contraseña debe contener una letra minúscula",
    [CustomTranslationKeys.PasswordValidationNumber]: "La contraseña debe contener un número",
    [CustomTranslationKeys.PasswordValidationSpecialChar]: "La contraseña debe contener un caracter especial",
    [CustomTranslationKeys.PasswordValidationUppercase]: "La contraseña debe contener una letra mayúscula",
    [CustomTranslationKeys.SignInHeaderText]: "Iniciar sesión con Email y Contraseña",
    [CustomTranslationKeys.SignUpHeaderText]: "Registrar una Cuenta Nueva",
    // Amplify's own translations are missing for the below keys
    "Cannot reset password for the user as there is no registered/verified email or phone_number":
      "No se puede resetear la contraseña para el usuario ya que no hay email o teléfono registrado/verificado",
    "It may take a minute to arrive.": "Puede demorar un momento en llegar.",
    "User does not exist.": "El usuario ingresado no existe.",
    "Username/client id combination not found.": "Combinación de nombre de usuario/id de cliente no encontrada",
    "We Emailed You": "Te enviamos un Email",
    "We Sent A Code": "Te enviamos un código",
    "Your code is on the way. To log in, enter the code we emailed to":
      "Tu código está en camino. Para iniciar sesión, ingresa el código que enviamos a",
    "Your code is on the way. To log in, enter the code we sent you. It may take a minute to arrive.":
      "Tu código está en camino. Para iniciar sesión, ingresa el código que te enviamos. Puede demorar un momento en llegar.",
    "Your code is on the way. To log in, enter the code we texted to":
      "Tu código está en camino. Para iniciar sesión, ingresa el código que enviamos a",
  },
};
